import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import ReactDatePicker from 'react-datepicker';

import { closeModal } from '^/actions/modals';
import RemoteUserSelect from '^/components/forms/RemoteUserSelect';
import { requestPeerFeedbackAndCloseModal, REQUEST_PEER_FEEDBACK } from '^/components/peer-feedback/actions';
import { REGARDING_OPTIONS } from '^/components/peer-feedback/constants';
import { FORMAT_DATE_PICKER, FORMAT_DATE_BACKEND } from '^/utils';
import { isEmployeeBubbleUser } from '^/utils/permissions';

const FORM_NAME = 'RequestFeedbackModalForm';

const VISIBILITY_OPTIONS = {
  MANAGER_AND_INDIVIDUAL: 'individual',
  MANAGER_ONLY: 'manager'
};

const UserSelectField = props => (
  <RemoteUserSelect
    onChange={props.input.onChange}
    selectId={props.selectId}
    placeholder={props.placeholder}
    optionFilters={props.optionFilters}
    multi={props.multi}
    required
  />
);

export const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper">
      <ReactDatePicker
        selected={props.input.value}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        fixedHeight
        required
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

export class RequestFeedbackModalForm extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      regarding: this.props.regarding
    };
    this.setRegardingState = this.setRegardingState.bind(this);
  }

  setRegardingState(event) {
    const regarding = event.target.value;
    this.setState({
      regarding
    });
    this.props.clearSelectedUser();
    if (regarding === REGARDING_OPTIONS.ME) {
      this.props.setVisibilityIndividual();
    }
  }

  render() {
    const { close, handleSubmit, isEmployee, selectedUserInProviders, loggedInUserInProviders } = this.props;
    const showIndividualOptions = this.state.regarding === REGARDING_OPTIONS.OTHERS;
    const loggedInUserInProvidersError = this.state.regarding === REGARDING_OPTIONS.ME && loggedInUserInProviders;

    return (
      <form onSubmit={handleSubmit} disabled={this.props.isSendingRequest}>
        <div className="modal-body padding-xx-large font-family-varela-round">
          <p className="font-size-14">
            Add a title & description to give your participants a better understanding of the feedback you'd
            like them to share. View examples of questions you can include{' '}
            <a
              href={
                `https://intercom.help/wethrive/en/articles/` +
                `9037168-requesting-feedback-example-questions-to-ask-your-peers`
              }
              target="_blank"
            >
            here
            </a>
            .
          </p>
          <div className="display-flex margin-top-xx-large">
            <div className="font-size-14 width-175px flex-shrink-0 input-line-height">
              Title:
            </div>
            <div className="flex-grow">
              <Field
                name="title"
                id="title"
                component="input"
                type="text"
                placeholder="Add title here..."
                required
              />
            </div>
          </div>
          <div className="display-flex margin-top-xx-large">
            <div className="font-size-14 width-175px flex-shrink-0 input-line-height">
              Description:
            </div>
            <div className="flex-grow position-relative">
              <Field
                className="min-height-130px"
                name="description"
                id="description"
                component="textarea"
                placeholder="Add description here..."
                required
              />
              <p className="position-absolute right-0 bottom-minus-20 font-size-12">Click and drag to resize</p>
            </div>
          </div>
          <div className="display-flex margin-top-xx-large">
            <div className="font-size-14 width-175px flex-shrink-0">
              Regarding:
            </div>
            <input
              className="checkbox-color-text"
              name="regarding"
              type="radio"
              value="me"
              id="me"
              disabled={isEmployee}
              onChange={this.setRegardingState}
              checked={this.state.regarding === REGARDING_OPTIONS.ME}
            />
            <label
              className="margin-left-small margin-bottom-small margin-top-small"
              htmlFor="me"
            >
              Me
            </label>
            {!isEmployee && (
              <div>
                <input
                className="checkbox-color-text margin-left-x-large"
                name="regarding"
                type="radio"
                value="others"
                id="others"
                disabled={isEmployee}
                onChange={this.setRegardingState}
                checked={this.state.regarding === REGARDING_OPTIONS.OTHERS}
                />
                <label
                  className="margin-left-small margin-bottom-small margin-top-small"
                  htmlFor="others"
                >
                  Others
                </label>
              </div>
            )}
          </div>
          {showIndividualOptions && (
            <div className="display-flex margin-top-xx-large">
              <div className="font-size-14 width-175px flex-shrink-0 input-line-height">
                Individual:
              </div>
              <div className="flex-grow">
                <Field
                  name="user"
                  id="user"
                  component={UserSelectField}
                  optionFilters={{'self_excluded': true}}
                  selectId="feedback-modal-form"
                  placeholder="Who do you want to request feedback for?
                  Type an individual or team name to start searching."
                />
              </div>
            </div>
          )}
          <div className="display-flex margin-top-xx-large">
            <div className="font-size-14 width-175px flex-shrink-0 input-line-height">
              Feedback providers:
            </div>
            <div className="flex-grow">
              {selectedUserInProviders && (
                <p className="error margin-bottom-base">
                  You cannot request users to provide feedback on themselves.
                </p>
              )}
              {loggedInUserInProvidersError && (
                <p className="error margin-bottom-base">
                  You cannot request yourself to provide feedback on yourself.
                </p>
              )}
              <Field
                name="providers"
                id="providers"
                component={UserSelectField}
                optionFilters={
                  showIndividualOptions ?
                    { 'peer_feedback_providers': true } :
                    { 'peer_feedback_providers_exclude_self': true }
                }
                selectId="request-feedback-modal-form"
                placeholder="Who do you want to request the feedback from?
                Type an individual or team name to start searching."
                multi
              />
            </div>
          </div>
          <div className="display-flex margin-top-xx-large">
            <div className="font-size-14 width-175px flex-shrink-0">
              Visibility:
            </div>
            <Field
              className="checkbox-color-text"
              name="visibility"
              type="radio"
              value={VISIBILITY_OPTIONS.MANAGER_AND_INDIVIDUAL}
              id="individual"
              component="input"
              disabled={!showIndividualOptions}
            />
            <label
              className="margin-left-small margin-bottom-small margin-top-small"
              htmlFor="individual"
            >
              Visible to manager and individual
            </label>
            {showIndividualOptions && (
              <div>
                <Field
                  className="checkbox-color-text margin-left-x-large"
                  name="visibility"
                  type="radio"
                  value={VISIBILITY_OPTIONS.MANAGER_ONLY}
                  id="manager"
                  component="input"
                />
                <label
                  className="margin-left-small margin-bottom-small margin-top-small"
                  htmlFor="manager"
                >
                  Visible to manager only
                </label>
              </div>
            )}

          </div>
          <div className="display-flex margin-top-xx-large">
            <div className="font-size-14 width-175px flex-shrink-0 input-line-height">
              Deadline:
            </div>
            <div className="flex-grow">
              <Field
                id="deadline"
                name="deadline"
                component={DatePickerField}
              />
            </div>
          </div>
          <div className="display-flex margin-top-xx-large font-size-14">
            This feedback is not anonymous - the receiver will know who sent this,
            so please think about wording and tone.
          </div>
          <div className="margin-top-xx-large text-align-right">
            <button
              className="pink-button"
              type="submit"
              disabled={this.props.isSendingRequest || selectedUserInProviders || loggedInUserInProvidersError}
            >
              Send feedback requests
            </button>
            <button
              className="pink-border-button margin-left-x-large"
              onClick={close}
              disabled={this.props.isSendingRequest}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export const FormifiedRequestFeedbackModal = reduxForm({
  form: FORM_NAME,
  initialValues: {
    visibility: VISIBILITY_OPTIONS.MANAGER_AND_INDIVIDUAL,
  }
})(RequestFeedbackModalForm);

const mapStateToProps = (state) => {
  const userId = state.loggedInUser.get('id');
  const formValues = formValueSelector(FORM_NAME)(state, 'user', 'providers');
  const selectedProviders = formValues.providers || [];
  const selectedUserInProviders = !!selectedProviders.find(
    provider => provider.value === (formValues.user || {}).value
  );
  const loggedInUserInProviders = !!selectedProviders.find(provider => provider.value === userId);
  return {
    isSendingRequest: isPending(state.responses, REQUEST_PEER_FEEDBACK),
    selectedUserInProviders,
    loggedInUserInProviders,
    isEmployee: isEmployeeBubbleUser(state.loggedInUser),
    userId
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  close: (event) => {
    event.preventDefault();
    dispatch(closeModal());
  },
  onSubmit: (data, user) => {
    const reloadPage = data.user ? props.reloadTeamListPage : props.reloadIndividualListPage;
    dispatch(requestPeerFeedbackAndCloseModal(
      Object.assign({}, data, {
        user: data.user ? data.user.value : user,
        providers: data.providers && data.providers.map(provider => provider.value),
        deadline: data.deadline.format(FORMAT_DATE_BACKEND),
      }),
      reloadPage
    ));
  },
  clearSelectedUser: () => dispatch(change(FORM_NAME, 'user', null)),
  setVisibilityIndividual: () => dispatch(change(FORM_NAME, 'visibility', VISIBILITY_OPTIONS.MANAGER_AND_INDIVIDUAL))
});

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    onSubmit: (data) => dispatchProps.onSubmit(data, stateProps.userId)
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FormifiedRequestFeedbackModal);
