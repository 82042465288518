import { isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { isEmployeeBubbleUser } from '^/utils/permissions';
import {
  updateSelectedSurveyType,
  updateSelectedSurveyComment,
  clearSelectedSurveys,
  createCustomReportAndCloseModal,
  CREATE_CUSTOM_REPORT,
  updateCustomReportAndToast
} from '^/components/custom-reports/actions';
import { SURVEY_TYPES, SURVEY_TYPES_BUTTON_LABEL_MAP, REPORT_TYPES } from '^/components/custom-reports/constants';
import SurveySelect from '^/components/custom-reports/SurveySelect';
import CustomReportBenchmarkSelector from './CustomReportBenchmarkSelector';

const SurveyTypeButton = props => (
  <button className={props.className} onClick={props.updateSelectedSurveyType}>
    {props.name}
  </button>
);

const mapDispatchToPropsSurveyTypeButton = (dispatch, props) => ({
  updateSelectedSurveyType: () => {
    if (props.reportType !== REPORT_TYPES.TREND_ANALYSIS_LINE) {
      dispatch(clearSelectedSurveys());
    }
    dispatch(updateSelectedSurveyType(props.surveyType));
  }
});

const ConnectedSurveyTypeButton = connect(undefined, mapDispatchToPropsSurveyTypeButton)(SurveyTypeButton);

const SurveyCommentInput = props => (
  <input
    className="half-width"
    type="text"
    value={props.value || ''}
    placeholder="Add comments..."
    onChange={props.updateComment}
  />
);

const mapDispatchToPropsCommentInput = (dispatch, props) => ({
  updateComment: (event) => dispatch(updateSelectedSurveyComment(
    props.surveyType,
    props.surveyId,
    event.target.value
  ))
});

const ConnectedSurveyCommentInput = connect(undefined, mapDispatchToPropsCommentInput)(SurveyCommentInput);

const getPillButtonActiveClasses = active => ({
  active,
  'opacity-7': !active
});

const reportTypeDescriptionMap = {
  [REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP]: (
    <p className="font-size-14">
      This report will allow you to compare your team’s scores from a particular{' '}
      survey to the rest of the organisation.{' '}
      If you look after a large group, you may be able to filter your team results further.
    </p>
  ),
  [REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP]: (
    <p className="font-size-14">
      Compare your team averages to the overall WeThrive average,{' '}
      industry and/or size benchmarks (if available).{' '}
      You will then have the option to filter your own results further.
    </p>
  ),
  [REPORT_TYPES.TREND_ANALYSIS_LINE]: (
    <div>
      <p className="font-size-14 margin-bottom-base">Surveys will appear on the graph in chronological order.</p>
      <p className="margin-bottom-large font-size-14">
        Please select surveys from the dropdown list below and when ready,{' '}
        add all selected surveys to the report by clicking the "Create chart" button.
      </p>
    </div>
  ),
  [REPORT_TYPES.TREND_ANALYSIS_HEATMAP]: (
    <p className="font-size-14">
      Please select surveys from the dropdown list below and when ready,{' '}
      add all selected surveys to the report by clicking the "Create chart" button.{' '}
      Only surveys of the same type can be viewed on the same chart.
    </p>
  )
};

const getSurveyTypeButtonName = (reportType, surveyType, allSelectedSurveys) => {
  const surveyTypeLabel = SURVEY_TYPES_BUTTON_LABEL_MAP[surveyType];

  if (reportType === REPORT_TYPES.TREND_ANALYSIS_LINE && allSelectedSurveys[surveyType]) {
    return `${surveyTypeLabel} (${allSelectedSurveys[surveyType].length})`;
  }

  return surveyTypeLabel;
};

const getSurveySelectId = (surveyType, reportType) =>
  reportType === REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP ?
  `${surveyType}-${reportType}` : surveyType;

const getReportTypeDescription = (reportType, isEmployee) => {
  if (isEmployee && reportType === REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP) {
    return (
      <p className="font-size-14">
        This report will allow you to compare your own scores from a particular survey to the rest of the organisation.
      </p>
    );
  }
  if (isEmployee && reportType === REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP) {
    return (
      <p className="font-size-14">
        Compare your own scores to the overall WeThrive average,{' '}
        industry and/or size benchmarks (if available).
      </p>
    );
  }
  return reportTypeDescriptionMap[reportType];
};

export class SurveySelectModal extends React.PureComponent {

  componentWillUnmount() {
    this.props.clearSelectedSurveys();
  }

  render() {
    const { selectedSurveyType, selectedSurveys, reportType, allSelectedSurveys, isEmployee } = this.props;

    return (
      <div className="modal-body survey-select-modal-body">

        { getReportTypeDescription(reportType, isEmployee) }

        <div className="display-flex align-items-center margin-top-xx-large">
          <div className="font-size-14 font-weight-bold width-150px">
            Survey type
          </div>
          <ConnectedSurveyTypeButton
            surveyType={SURVEY_TYPES.ENGAGEMENT}
            name={getSurveyTypeButtonName(reportType, SURVEY_TYPES.ENGAGEMENT, allSelectedSurveys)}
            className={classNames('pill-button', getPillButtonActiveClasses(
              selectedSurveyType === SURVEY_TYPES.ENGAGEMENT
            ))}
            reportType={reportType}
          />
          <ConnectedSurveyTypeButton
            surveyType={SURVEY_TYPES.MENTAL_HEALTH}
            name={getSurveyTypeButtonName(reportType, SURVEY_TYPES.MENTAL_HEALTH, allSelectedSurveys)}
            className={classNames('pill-button', 'margin-left-x-large', getPillButtonActiveClasses(
              selectedSurveyType === SURVEY_TYPES.MENTAL_HEALTH
            ))}
            reportType={reportType}
          />
          <ConnectedSurveyTypeButton
            surveyType={SURVEY_TYPES.CULTURE_AND_DIVERSITY}
            name={getSurveyTypeButtonName(reportType, SURVEY_TYPES.CULTURE_AND_DIVERSITY, allSelectedSurveys)}
            className={classNames('pill-button', 'margin-left-x-large', getPillButtonActiveClasses(
              selectedSurveyType === SURVEY_TYPES.CULTURE_AND_DIVERSITY
            ))}
            reportType={reportType}
          />
        </div>
        <div className="display-flex margin-top-xx-large">
          <div className="font-size-14 font-weight-bold width-150px flex-shrink-0 input-line-height">
            Add surveys
          </div>
          <div className="flex-grow">
            <SurveySelect
              selectId={getSurveySelectId(selectedSurveyType, reportType)}
              selectedSurveyType={selectedSurveyType}
              selectedSurveys={selectedSurveys}
              multi={
                [REPORT_TYPES.TREND_ANALYSIS_LINE, REPORT_TYPES.TREND_ANALYSIS_HEATMAP].includes(reportType)
              }
              reportType={reportType}
              isCreatingReport={this.props.isCreatingReport}
            />
          </div>
        </div>
        {reportType === REPORT_TYPES.TREND_ANALYSIS_LINE && (
          <div>
            <div className="font-size-14 font-weight-bold margin-top-xx-large">
              Surveys selected:
            </div>
            {(selectedSurveys || []).length ? selectedSurveys.map((survey) => (
              <div
                key={`survey-comment-${survey.value}`}
                className="display-flex align-items-center margin-top-x-large"
              >
                <div className="half-width padding-right-base line-height-16px">{survey.label}</div>
                <ConnectedSurveyCommentInput
                  value={survey.comment}
                  surveyId={survey.value}
                  surveyType={selectedSurveyType}
                />
              </div>
            )) : (
              <p className="margin-top-large">No surveys selected.</p>
            )}
          </div>
        )}
        {
          reportType === REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP &&
          (
            <CustomReportBenchmarkSelector
              surveyId={selectedSurveys && selectedSurveys.value}
              surveyType={selectedSurveyType}
              isDisabled={this.props.isCreatingReport}
            />
          )
        }
        <div className="margin-top-auto">
          <div className="margin-top-xx-large text-align-right">
            <button
              className="pink-button"
              onClick={this.props.isEditing ? this.props.updateReport : this.props.generateReport}
              disabled={this.props.isDisabled}
            >
              {this.props.isEditing ? 'Update' : 'Create'} chart
            </button>
            <button
              className="pink-border-button margin-left-x-large"
              onClick={this.props.closeModal}
              disabled={this.props.isCreatingReport}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function getHasSelectedSurveys(selectedSurveys, reportType, surveyType) {
  if (reportType === REPORT_TYPES.TREND_ANALYSIS_LINE) {
    return !!Object.values(selectedSurveys).filter(surveys => !!(surveys && surveys.length)).length;
  }
  if (reportType === REPORT_TYPES.TREND_ANALYSIS_HEATMAP) {
    return !!(selectedSurveys[surveyType] && selectedSurveys[surveyType].length);
  }
  return !!selectedSurveys[surveyType];
}

const requiresBenchmarks = (reportType, selectedBenchmarks) => (
  reportType === REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP &&
  !Object.values(selectedBenchmarks).length
);

const mapStateToProps = ({
  responses,
  selectedSurveys,
  selectedSurveyType,
  selectedBenchmarkingOptions,
  loggedInUser
}, props) => {
  const hasSelectedSurveys = getHasSelectedSurveys(selectedSurveys, props.reportType, selectedSurveyType);
  const selectedBenchmarks = selectedBenchmarkingOptions[selectedSurveyType];
  const isCreatingReport = isPending(responses, CREATE_CUSTOM_REPORT);
  const isEmployee = isEmployeeBubbleUser(loggedInUser);
  return {
    selectedSurveys: selectedSurveys[selectedSurveyType],
    allSelectedSurveys: selectedSurveys,
    selectedSurveyType,
    selectedBenchmarks,
    isDisabled: !hasSelectedSurveys ||
      requiresBenchmarks(props.reportType, selectedBenchmarks) ||
      isCreatingReport,
    isCreatingReport,
    isEmployee
  };
};

const formatSelectedSurveys = selectedSurveys => {
  if (Array.isArray(selectedSurveys)) {
    return selectedSurveys.map(selectedSurvey => ({
      survey_id: selectedSurvey.value,
      comment: selectedSurvey.comment
    }));
  }
  return [{
    survey_id: selectedSurveys.value
  }];
};

const getCreateUpdateReportData = (
  reportType,
  selectedSurveys,
  allSelectedSurveys,
  selectedBenchmarks
) => {
  const surveysToFormat = (
    reportType === REPORT_TYPES.TREND_ANALYSIS_LINE ?
    Object.values(allSelectedSurveys).reduce((accumulatedSurveys, surveys) => {
      return surveys && surveys.length ? accumulatedSurveys.concat(surveys) : accumulatedSurveys;
    }, [])
    : selectedSurveys
  );
  const reportData = {surveys: formatSelectedSurveys(surveysToFormat)};

  if (reportType === REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP) {
    reportData.benchmarks = selectedBenchmarks;
  }

  return reportData;
};

const mapDispatchToProps = (dispatch, props) => ({
  generateReportFunction: (
    selectedSurveyType,
    selectedSurveys,
    allSelectedSurveys,
    selectedBenchmarks
  ) => () => {
    const reportData = getCreateUpdateReportData(
      props.reportType, selectedSurveys, allSelectedSurveys, selectedBenchmarks
    );
    reportData.type = props.reportType;

    if (props.reportType !== REPORT_TYPES.TREND_ANALYSIS_LINE) {
      reportData.survey_type = selectedSurveyType;
    }

    dispatch(createCustomReportAndCloseModal(reportData));
  },
  updateReportFunction: (
    selectedSurveys,
    allSelectedSurveys,
    selectedBenchmarks
  ) => () => {
    const reportData = getCreateUpdateReportData(
      props.reportType, selectedSurveys, allSelectedSurveys, selectedBenchmarks
    );
    dispatch(updateCustomReportAndToast(reportData, props.unFiltereredReportId, props.reportId));
    dispatch(closeModal());
  },
  closeModal: () => dispatch(closeModal()),
  clearSelectedSurveys: () => dispatch(clearSelectedSurveys()),
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    generateReport: dispatchProps.generateReportFunction(
      stateProps.selectedSurveyType,
      stateProps.selectedSurveys,
      stateProps.allSelectedSurveys,
      stateProps.selectedBenchmarks
    ),
    updateReport: dispatchProps.updateReportFunction(
      stateProps.selectedSurveys,
      stateProps.allSelectedSurveys,
      stateProps.selectedBenchmarks
    ),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SurveySelectModal);
