import { Column, Row } from '@dabapps/roe';
import moment from 'moment';
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDatePicker from 'react-datepicker';
import { Field, reduxForm } from 'redux-form';

import { FORMAT_DATE_PICKER } from '^/utils';
import { OwnerSelectField } from '^/components/goals/EditGoalForm';

export const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper">
      <ReactDatePicker
        selected={props.input.value || moment().add(1, 'days')}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        required
        fixedHeight
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

function normalizeOwner(value) {
  if (value && value.id) {
    return value.id;
  }
  return value;
}

export function CreateGoalForm({handleSubmit, disabled, hideTitle}) {
  return (
    <form className="clearfix" onSubmit={handleSubmit}>
      {!hideTitle && (
        <h4 className="margin-bottom-base">Goal Details</h4>
      )}
      <Row className="margin-top-large line-height-32px">
        <Column md={2}>
          <label htmlFor="name" className="margin-none">Goal Name</label>
        </Column>
        <Column md={10}>
          <Field id="name" name="name" component="input" type="text" required />
        </Column>
      </Row>
      <Row className="margin-top-large line-height-32px">
        <Column md={2}>
          <label htmlFor="description" className="margin-none">Goal Description</label>
        </Column>
        <Column md={10}>
          <Field id="description" name="description" component="textarea" />
        </Column>
      </Row>
      <Row className="margin-top-large line-height-32px">
        <Column md={2}>
          <label htmlFor="due_date" className="margin-none">Due</label>
        </Column>
        <Column md={10}>
          <Field
            id="due_date"
            name="due_date"
            component={DatePickerField}
          />
        </Column>
      </Row>
      <Row className="margin-top-large line-height-32px">
        <Column md={2}>
          <label htmlFor="owner" className="margin-none">Owner</label>
        </Column>
        <Column md={10}>
          <Field
            id="owner"
            name="owner"
            component={OwnerSelectField}
            normalize={normalizeOwner}
          />
        </Column>
      </Row>
      <button
        className="btn middle orange pull-right margin-vertical-large"
        disabled={disabled}
      >
        Create goal
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'createGoalForm',
})(CreateGoalForm);
