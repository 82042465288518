import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { shouldRethrow } from '^/actions/actions';
import { toast } from 'react-toastify';
import { closeModal } from '^/actions/modals';

export const UPDATE_ADDITIONAL_FILTERS_LIST = 'UPDATE_ADDITIONAL_FILTERS_LIST';
export function updateAdditionalFiltersList(additionalFilters) {
  return {
    type: UPDATE_ADDITIONAL_FILTERS_LIST,
    additionalFilters
  };
}

export const UPDATE_ORGANISATION_FILTER_SETTINGS = makeAsyncActionSet('UPDATE_ORGANISATION_FILTER_SETTINGS');
export function updateOrganisationFilterSettings(data) {
  return (dispatch) => {
    return request(
        UPDATE_ORGANISATION_FILTER_SETTINGS,
      '/api/organisation/update-filter-terms/',
      'PUT',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export const updateOrganisationFilterSettingsAndToast = (data) => dispatch =>
updateOrganisationFilterSettings(data)(dispatch).then(() => {
  toast.dismiss();
  toast.success('The filters have been updated.');
}).catch(() => {
  toast.dismiss();
  toast.error('There was a problem updating the filters.');
});

export const UPDATE_ADDITIONAL_FILTER = makeAsyncActionSet('UPDATE_ADDITIONAL_FILTER');
export function updateAdditionalFilter(filterId, data) {
  return (dispatch) => {
    return request(
      UPDATE_ADDITIONAL_FILTER,
      `/api/organisation/${filterId}/edit-additional-filter/`,
      'PUT',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export const updateAdditionalFilterAndCloseModal = (filterId, data) => dispatch =>
updateAdditionalFilter(filterId, data)(dispatch).then(() => {
  dispatch(closeModal());
  toast.dismiss();
  toast.success('Filter updated.');
}).catch(() => {
  toast.dismiss();
  toast.error('There was a problem updating this filter.');
});

export const GET_ADDITIONAL_FILTER_OPTIONS = makeAsyncActionSet('GET_ADDITIONAL_FILTER_OPTIONS');
export function getAdditionalFilterOptions(filterId) {
  return (dispatch) => {
    return request(
      GET_ADDITIONAL_FILTER_OPTIONS,
      `/api/organisation/${filterId}/retrieve-filter-options/`,
      'GET'
    )(dispatch);
  };
}

export const UPDATE_ADDITIONAL_FILTER_OPTIONS = 'UPDATE_ADDITIONAL_FILTER_OPTIONS';
export function updateAdditionalFilterOptions(options) {
  return {
    type: UPDATE_ADDITIONAL_FILTER_OPTIONS,
    options
  };
}

export const UPDATE_ADDITIONAL_FILTER_OPTION = 'UPDATE_ADDITIONAL_FILTER_OPTION';
export function updateAdditionalFilterOption(index, label) {
  return {
    type: UPDATE_ADDITIONAL_FILTER_OPTION,
    index,
    label
  };
}

export const REMOVE_ADDITIONAL_FILTER_OPTION = 'REMOVE_ADDITIONAL_FILTER_OPTION';
export function removeAdditionalFilterOption(index, minimumNumberOfOptions) {
  return {
    type: REMOVE_ADDITIONAL_FILTER_OPTION,
    index,
    minimumNumberOfOptions
  };
}

export const ADD_ADDITIONAL_FILTER_OPTION = 'ADD_ADDITIONAL_FILTER_OPTION';
export function addAdditionalFilterOption() {
  return {
    type: ADD_ADDITIONAL_FILTER_OPTION
  };
}

export const CREATE_CUSTOM_FILTER = makeAsyncActionSet('CREATE_CUSTOM_FILTER');
export function createCustomFilter(data) {
  return (dispatch) => {
    return request(
      CREATE_CUSTOM_FILTER,
      `/api/organisation/create-custom-filter/`,
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export const createCustomFilterAndCloseModal = (data) => dispatch =>
  createCustomFilter(data)(dispatch).then(() => {
    dispatch(closeModal());
    toast.dismiss();
    toast.success('Filter created.');
  }).catch(() => {
    toast.dismiss();
    toast.error('There was a problem creating this filter.');
  });
