import { ModalBody } from '@dabapps/roe';

import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import RequestedFeedbackModalForm from './RequestedFeedbackModalForm';
import { loadItem } from '^/actions/items';
import Loading from '^/components/Loading';
import { hasFailed, isPending } from '^/consts/responseStates';

const FEEDBACK_REQUEST_PATH = 'people/peer-feedback-request';

export const FeedbackItem = ({
  label,
  data
}) => {
  return (
    <div className="display-flex">
      <p className="width-180px flex-shrink-0 font-size-14 font-family-varela-round">{label}</p>
      <p className="font-size-14 font-family-varela-round white-space-pre-line">{data}</p>
    </div>
  );
};

export class RequestedFeedbackModal extends Component {

  componentDidMount () {
    if (!this.props.feedbackRequest) {
      this.props.getPeerFeedbackRequest();
    }
  }

  render () {
    const {
      feedbackRequest,
      isLoading,
      isFailed,
      feedbackRequestId,
    } = this.props;

    if (isFailed) {
      return (
        <ModalBody className="padding-x-large">
          <p>There was an error loading the feedback request.</p>
        </ModalBody>
      );
    }

    if (isLoading || !feedbackRequest) {
      return (
        <ModalBody className="padding-x-large">
          <Loading />
        </ModalBody>
      );
    }

    return (
      <ModalBody className="padding-x-large">
        <p className="font-size-14 font-family-varela-round margin-bottom-xx-large">
          Note! If you close this modal without clicking 'Save & share', any changes you have made will be lost.
          Please make a record of your comments.
        </p>
        <div className="display-flex flex-column flex-gap-x-large">
          <FeedbackItem label="Title:" data={feedbackRequest.get('title')} />
          <FeedbackItem label="Description:" data={feedbackRequest.get('description')} />
          <FeedbackItem label="Feedback requested by:" data={feedbackRequest.get('created_by')} />
          <FeedbackItem label="Giving feedback on:" data={feedbackRequest.get('user')} />
          <FeedbackItem label="Visibility:" data={
            feedbackRequest.get('visible_to_user') ?
            'The individual and their manager will see this feedback.' :
            'The individual can\'t see this feedback but it can be viewed by their manager.'}
          />
          <FeedbackItem label="Deadline:" data={feedbackRequest.get('deadline')} />
        </div>
        <RequestedFeedbackModalForm feedbackRequestId={feedbackRequestId} />
      </ModalBody>
    );
  }
}

const mapStateToProps = (state, props) => {
  const response = state.legacyResponses.getIn(['loadItem', props.feedbackRequestId]);
  return {
    feedbackRequest: state.items.get(props.feedbackRequestId),
    isLoading: isPending(response),
    isFailed: hasFailed(response)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getPeerFeedbackRequest: () => dispatch(
      loadItem(FEEDBACK_REQUEST_PATH, props.feedbackRequestId, props.feedbackRequestId)
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestedFeedbackModal);
