import { anyPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import {
  addUsersToBulkAgendaAndToast,
  removeUsersFromBulkAgendaAndToast,
  MANAGE_BULK_AGENDA_USERS,
  CANCEL_BULK_AGENDA
} from '^/components/agendas/actions';

export function BulkAgendaSelectUserCheckbox({isDisabled, toggleSelected, isSelected}) {
  return (
    <input
      type="checkbox"
      className="checkbox-color-text"
      disabled={isDisabled}
      onChange={toggleSelected}
      checked={isSelected}
    />
  );
}

function mapStateToProps(state, props) {
  return {
    isSelected: state.bulkAgendaSelectedUserIds.includes(props.userId),
    isDisabled: anyPending(state.responses, [MANAGE_BULK_AGENDA_USERS, CANCEL_BULK_AGENDA])
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    toggleSelectedFunction: (isSelected) => () => {
      if (isSelected) {
        dispatch(removeUsersFromBulkAgendaAndToast(props.agendaTemplateId, [props.userId]));
      } else {
        dispatch(addUsersToBulkAgendaAndToast(props.agendaTemplateId, [props.userId]));
      }
    }
  };
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    toggleSelected: dispatchProps.toggleSelectedFunction(
      stateProps.isSelected
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BulkAgendaSelectUserCheckbox);
