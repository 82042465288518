import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { updateOrganisationFilterSettingsAndToast } from './actions';
import OrganisationFiltersForm from './OrganisationFiltersForm';
import OrganisationAdditionalFilters from './OrganisationAdditionalFilters';

export function OrganisationFilters({
  handleSubmit,
  organisationFilterTerms
}) {
  return (
    <div>
      <OrganisationFiltersForm
        onSubmit={handleSubmit}
        initialValues={{
          team: organisationFilterTerms.team,
          unit: organisationFilterTerms.unit,
          location: organisationFilterTerms.location,
          department: organisationFilterTerms.department,
          manager: organisationFilterTerms.manager
        }}
      />
      <OrganisationAdditionalFilters />
    </div>
  );
}

export function mapDispatchToProps(dispatch) {
  return {
    handleSubmit: (data) => {
      dispatch(updateOrganisationFilterSettingsAndToast(data));
    },
  };
}

export default connect(
  undefined,
  mapDispatchToProps
)(OrganisationFilters);
