import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { agendaItemCancelEdit } from '^/components/agendas/actions';

export function CreateUpdateAgendaItemForm({
  handleSubmit,
  closeForm,
  isDisabled,
  isEmptyItems,
  isCommentItems,
  pristine
}) {
  return (
    <form onSubmit={handleSubmit} className="margin-top-xx-large">
      <Field
        name="text"
        component="textarea"
        disabled={isDisabled}
        required
      />
      <button
        className="btn middle orange margin-top-x-large"
        disabled={isDisabled || pristine}
      >
        Save
      </button>
      {!isEmptyItems && !isCommentItems && (
        <button
          className="btn middle orange hollow margin-top-x-large margin-left-large"
          onClick={closeForm}
          disabled={isDisabled}
        >
          Cancel
        </button>
      )}
    </form>
  );
}

const CreateUpdateAgendaItemFormFormified = reduxForm({
  destroyOnUnmount: false,
})(CreateUpdateAgendaItemForm);

function mapDispatchToProps(dispatch, ownProps) {
  return {
    closeForm: event => {
      event.preventDefault();
      dispatch(agendaItemCancelEdit(ownProps.agendaItemId || ownProps.itemsName));
    }
  };
}

export default connect(undefined, mapDispatchToProps)(CreateUpdateAgendaItemFormFormified);
