import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { getCollection } from '^/actions/collections';

export const COPY_CUSTOM_QUESTION_INTO_SURVEY = makeAsyncActionSet('COPY_CUSTOM_QUESTION_INTO_SURVEY');
export function copyCustomQuestionIntoSurvey(surveyId, questionBeingCopied) {
  return (dispatch) => {
    return request(
      COPY_CUSTOM_QUESTION_INTO_SURVEY,
      `/api/survey/${surveyId}/copy-original-question-into-survey/${questionBeingCopied.get('id')}/`,
      'POST',
      undefined,
      {
        metaData: {
          questionBeingCopied
        }
      }
    )(dispatch);
  };
}

export function copyCustomQuestionIntoSurveyAndReloadList(surveyId, questionBeingCopied, options) {
  return (dispatch) => copyCustomQuestionIntoSurvey(surveyId, questionBeingCopied)(dispatch).then(() => {
    dispatch(getCollection('custom-questions', options));
  });
}

export const DELETE_SURVEY_CUSTOM_QUESTION = makeAsyncActionSet('DELETE_SURVEY_CUSTOM_QUESTION');
export function deleteSurveyCustomQuestion(surveyId, questionId) {
  return (dispatch) => {
    return request(
      DELETE_SURVEY_CUSTOM_QUESTION,
      `/api/survey/${surveyId}/delete-custom-question/${questionId}/`,
      'DELETE',
      undefined,
      {
        metaData: {
          deleteQuestionId: questionId
        }
      }
    )(dispatch);
  };
}

export function deleteSurveyCustomQuestionAndReloadList(surveyId, questionBeingCopied, options) {
  return (dispatch) => deleteSurveyCustomQuestion(surveyId, questionBeingCopied)(dispatch).then(() => {
    dispatch(getCollection('custom-questions', options));
  });
}

export const SET_CUSTOM_QUESTION_POSITION = makeAsyncActionSet('SET_CUSTOM_QUESTION_POSITION');
export function setCustomQuestionPosition(surveyId, questionId, newPosition) {
  return (dispatch) => {
    return request(
      SET_CUSTOM_QUESTION_POSITION,
      `/api/survey/${surveyId}/custom-questions/${questionId}/set-position/${newPosition}/`,
      'POST',
      undefined,
      {
        metaData: {
          questionId,
          newPosition
        }
      }
    )(dispatch);
  };
}
