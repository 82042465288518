import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Column, Row } from '@dabapps/roe';
import _ from 'underscore';

import {
  getSurveyEmailTemplate,
  resetResponse,
  UPDATE_SURVEY_EMAIL_TEMPLATE,
  getSurvey
} from '^/actions/actions';
import { updateSurveyEmailTemplateAndToastResponse } from '^/actions/actionSequences';
import Loading from '^/components/Loading';
import PureComponent from '^/components/PureComponent';
import { hasSucceeded, isPending } from '^/consts/responseStates';

import { ROE_TOTAL_COLUMS, EMAIL_TEMPLATE_NAMES, EMAIL_TEMPLATE_TYPES } from './constants';
import CustomEmailTemplateForm from './CustomEmailTemplateForm';
import CustomEmailTemplateField from './CustomEmailTemplateField';


const TWENTY_FOUR_HOURS = 24;
const SEVEN_DAYS = 7;


export class SurveyCustomEmailSettings extends PureComponent {
  constructor (props) {
    super(props);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount () {
    const { surveyId, templateType } = this.props;
    this.props.resetResponse(UPDATE_SURVEY_EMAIL_TEMPLATE);
    this.props.getSurveyEmailTemplate(surveyId, templateType);
  }

  onSubmitForm (data) {
    const { surveyId, templateType } = this.props;

    this.props.updateSurveyEmailTemplateAndToastResponse(surveyId, templateType, data);
  }

  render () {
    const {
      hasUpdatedSuccessfully,
      isUpdating,
      surveyId,
      templateType,
      updateResponse,
      templateSettings,
    } = this.props;

    return (
      <div>
        <div className="will table-will">
          <h4>Customise {EMAIL_TEMPLATE_NAMES[templateType].toLowerCase()} text</h4>
          <p>Enter your custom email settings below. These will be applied to just this survey.</p>
          {this.props.isLoading ? (
            <Loading />
          ) : (
            <Row className="margin-bottom-x-large">
              <Column md={ROE_TOTAL_COLUMS} lg={6}>
                <CustomEmailTemplateForm
                  form={`${surveyId}-${templateType}`}
                  templateType={templateType}
                  isUpdating={isUpdating}
                  onSubmit={this.onSubmitForm}
                  response={updateResponse}
                  initialValues={templateSettings}
                  footerExtras={
                    <a
                      href={`/surveys/${surveyId}/settings/`}
                      className={
                        'button default input-line-height margin-left-large padding-horizontal-large' +
                        'display-inline-block padding-vertical-none vertical-align-bottom'
                      }
                    >
                      {hasUpdatedSuccessfully ? 'Done' : 'Cancel'}
                    </a>
                  }
                >
                  {templateType === EMAIL_TEMPLATE_TYPES.SURVEY_WARMUP && (
                    <div>
                      <CustomEmailTemplateField
                        component="select"
                        disabled={isUpdating}
                        fieldName="days_before_survey_to_send"
                        label="Send"
                        response={updateResponse}
                      >
                        <option value="blank">Please select</option>
                        {_.times(SEVEN_DAYS, function(iteration) {
                          const numberOfDays = iteration + 1;
                          return <option value={numberOfDays}>{numberOfDays} days before survey</option>;
                        })}
                      </CustomEmailTemplateField>

                      <CustomEmailTemplateField
                        component="select"
                        disabled={isUpdating}
                        fieldName="hour_to_send_email"
                        label="...at"
                        response={updateResponse}
                      >
                        <option value="blank">Please select</option>
                        {_.times(TWENTY_FOUR_HOURS, function(hour) {
                          return <option value={hour}>{hour}:00</option>;
                        })}
                      </CustomEmailTemplateField>
                    </div>
                  )}
                </CustomEmailTemplateForm>
              </Column>
            </Row>
          )}
          <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
        </div>
      </div>
    );
  }
}

SurveyCustomEmailSettings.propTypes = {
  getSurveyEmailTemplate: React.PropTypes.func.isRequired,
  hasUpdatedSuccessfully: React.PropTypes.bool,
  isLoading: React.PropTypes.bool,
  isUpdating: React.PropTypes.bool,
  resetResponse: React.PropTypes.func.isRequired,
  surveyId: React.PropTypes.number.isRequired,
  templateType: React.PropTypes.string.isRequired,
  templateSettings: React.PropTypes.object,
  updateResponse: ImmutablePropTypes.map,
  updateSurveyEmailTemplateAndToastResponse: React.PropTypes.func.isRequired,
};

function mapStateToProps (state, props) {
  const templateSettings = state.surveyEmailTemplates.get(props.templateType);
  const updateResponse = state.legacyResponses.getIn(['updateSurveyEmailTemplate', props.templateType]);
  return {
    isLoading: isPending(state.legacyResponses.getIn(['getSurveyEmailTemplate', props.templateType])),
    templateSettings: templateSettings && templateSettings.toObject(),
    updateResponse,
    isUpdating: isPending(updateResponse),
    hasUpdatedSuccessfully: hasSucceeded(updateResponse),
  };
}

export default connect(
  mapStateToProps,
  { getSurveyEmailTemplate, resetResponse, updateSurveyEmailTemplateAndToastResponse, getSurvey }
)(SurveyCustomEmailSettings);
