import * as React from 'react';  // eslint-disable-line no-unused-vars

import ChadminList from '^/components/common/ChadminList';
import { REPORT_SHARES_LIST } from './constants';

export const config = [
  {
    name: 'survey',
    display_name: 'Survey name',
    type: 'text',
    sortable: true,
  },
  {
    name: 'filters_summary',
    display_name: 'Filters',
    type: 'text',
    sortable: true,
  },
  {
    name: 'generate_report_url',
    display_name: 'View report',
    type: 'custom',
    customItemHandler: (share) => (
      <a className="btn-icon orange margin-none text-center" href={share.generate_report_url}>
        <i className="far fa-tachometer-alt margin-none" aria-hidden="true"></i>
      </a>
    ),
    sortable: true,
  },
];

const ShareList = () => (
  <div className="will">
    <ChadminList
      collectionPath="reports/report-shares"
      collectionName={REPORT_SHARES_LIST}
      columns={config}
      hasInlineLabels
    />
  </div>
);

export default ShareList;
