import { Row, Column } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDatePicker from 'react-datepicker';
import { Field, reduxForm } from 'redux-form';

import RemoteUserSelect from '^/components/forms/RemoteUserSelect';
import { FORMAT_DATE_PICKER } from '^/utils';

function OwnerSelectField(props) {
  return (
    <RemoteUserSelect
      onChange={props.input.onChange}
      selectId={`edit-goal-add-action-owner`}
      optionFilters={{'bubble_users_only': true}}
      placeholder="User (type to search...)"
      initialValue={props.meta.initial}
    />
  );
}

function DatePickerField(props) {
  return (
    <div className="datepicker-with-icon-wrapper">
      <ReactDatePicker
        selected={props.input.value || props.meta.initial}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        fixedHeight
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
}

export function EditOkrAddActionForm({
  handleSubmit,
  removeAction,
}) {
  return (
    <div className="action-card margin-bottom-large">
      <button
        className="toggle-inclusion-icon"
        onClick={removeAction}
      >
        <i className="far fa-times"></i>
      </button>
      <form onSubmit={handleSubmit}>
        <div>
          <Row>
            <Column md={11}>
              <div className="text-gray margin-bottom-base">
                <span>
                  <span className="margin-right-small">
                    <i className="far fa-list"></i>
                  </span>
                  Key result
                </span>
              </div>
              <Field
                component="input"
                name="title"
                type="text"
                placeholder="Title"
                className="margin-bottom-base"
                required
              />
              <Field
                component="textarea"
                name="description"
                placeholder="Description"
              />
            </Column>
          </Row>
          <Row>
            <Column md={11}>
              <Column md={2} className="padding-none margin-top-large">
                <Field name="owner" component={OwnerSelectField} />
              </Column>
              <Column md={2} className="padding-none margin-top-large md-margin-left-large">
                <Field name="due_date" component={DatePickerField} />
              </Column>
            </Column>
          </Row>
        </div>
        <button className="btn middle orange margin-top-large">
          Save
        </button>
      </form>
    </div>
  );
}

export default reduxForm({
  form: 'EditOkrAddActionForm'
})(EditOkrAddActionForm);
