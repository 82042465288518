import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { connect } from 'react-redux';
import { resyncSurveyTeamMembersAndCloseModal } from '^/actions/actionSequences';
import { closeModal } from '../../../actions/modals';
import { Button, ModalBody, ModalFooter, SpacedGroup } from '@dabapps/roe';
import NotificationBody from '^/components/common/NotificationBody';


const Notification = () => (
  <NotificationBody
    title="People data re-sync in progress."
    text="Your re-sync is in progress and will complete shortly."
  />
);


export class ResyncSurveyTeamMemberModal extends PureComponent {

  constructor(props) {
    super(props);
    this.doRefresh = this.doRefresh.bind(this);
  }

  doRefresh() {
    this.props.resyncSurveyTeamMembersAndCloseModal(this.props.surveyId, Notification);
  }

  render() {
    return (
      <div>
        <ModalBody>
          <p>
            Re-syncing your people data will update the existing team, location, department and unit data for
            all persons from the current People table. Are you sure you wish to proceed?
          </p>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button className="btn gray middle" onClick={this.props.closeModal}>
              Cancel
            </Button>
            <Button className="btn orange middle" onClick={this.doRefresh}>
              Re-Sync
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </div>
    );
  }
}

ResyncSurveyTeamMemberModal.propTypes = {
  surveyId: React.PropTypes.number.isRequired
};


export default connect(null, { resyncSurveyTeamMembersAndCloseModal, closeModal })(ResyncSurveyTeamMemberModal);
