import { ModalRenderer } from '@dabapps/roe';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import ChadminList from '^/components/common/ChadminList';
import QueryParamsFilterSelect from '^/components/common/QueryParamsFilterSelect';
import AgendaSendBanner from './AgendaSendBanner';
import { agendaListConfig } from './config';
import { AGENDA_LIST_MANAGER, AGENDA_LIST_URL } from './constants';
import PendingAgendaCard from './PendingAgendaCard';

export const AgendaSelectCard = ({ title, description, image, url }) => {
  return (
    <div className="
      agenda-setup-card font-family-varela-round font-size-16 position-relative border-base
      padding-vertical-xx-large padding-horizontal-large text-align-center border-base border-radius-16
    ">
      <p className="margin-top-large font-size-16 font-family-varela-round">{title}</p>
      <div className="questionnaire-type-info">
        <i className="fas fa-info-circle text-pink position-absolute font-size-16"></i>
        <div className="font-size-12">{description}</div>
      </div>
      <div className="margin-bottom-small margin-top-small">
        <img
          src={image}
          alt="agenda"
          className="margin-vertical-small square-80"
        />
      </div>
      <div className="margin-top-auto">
        <a
          className="font-family-varela-round font-size-16 agenda-select-button"
          href={url}
        >
          Select
        </a>
      </div>
    </div>
  );
};

export const AgendaListManager = (props) => {
  const oneToOneOptions = [
    { value: props.loggedInUser.get('id'), label: 'My 1:1s' },
  ];
  return (
    <div>
      <div className="display-flex flex-wrap flex-gap-xx-large">
        {(props.userHasVisibleUsers || props.userHasManagers) && (
          <div className="background-white padding-xx-large border-radius-16">
            <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-xx-large">
              What would you like to do?
            </h4>
            <div className="display-flex flex-wrap flex-gap-xx-large">
              {props.userHasManagers && (
                <AgendaSelectCard
                  title="Request a 1:1 meeting"
                  description="Send a meeting request to your manager"
                  image="/static/images/agendas/request-individual.svg"
                  url="/agendas/request-one-to-one"
                />
              )}
              {props.userHasVisibleUsers && (
                <AgendaSelectCard
                  title="Set up individual 1:1"
                  description="Create a meeting agenda for one employee"
                  image="/static/images/agendas/set-up-individual.svg"
                  url="/agendas/create"
                />
              )}
              {props.userHasVisibleUsers && (
                <AgendaSelectCard
                  title="Set up multiple 1:1s"
                  description="
                    Create a meeting agenda for multiple employees
                    (you can still customise each agenda once created)
                  "
                  image="/static/images/agendas/set-up-multiple.svg"
                  url="/agendas/create-multiple"
                />
              )}
            </div>
          </div>
        )}
        <div className="
          background-white padding-xx-large border-radius-16 flex-grow
          padding-bottom-x-large third-width
        ">
          <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-x-large">
            Pending 1:1 requests:
          </h4>
          <div className="display-flex flex-column flex-gap-large">
            {props.actionableAgendas.length ? props.actionableAgendas.map(agenda => (
              <PendingAgendaCard agenda={agenda} key={agenda.id} collectionName={AGENDA_LIST_MANAGER} />
            )) : (
              <p>No notifications.</p>
            )}
          </div>
        </div>
      </div>
      <div className="
        background-white padding-xx-large border-radius-16 manager-agenda-list
        margin-top-xx-large margin-bottom-xxx-large
      ">
        <ChadminList
          collectionPath={AGENDA_LIST_URL}
          collectionName={AGENDA_LIST_MANAGER}
          columns={
            agendaListConfig(
              props.organisationTeamFilterTerm,
              true,
              props.disableSendButton
            )
          }
          ExtraControlsLeft={() => (
            <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-small">
              All 1:1s
            </h4>
          )}
          ExtraControlsRight={({setFilters}) => (
            <div className="display-flex margin-right-base">
              <div className="margin-right-base">
                <QueryParamsFilterSelect
                  filterName="employee__team_member__team_id"
                  name={props.organisationTeamFilterTerm}
                  options={props.teamOptions}
                  setFilters={setFilters}
                />
              </div>
              <div>
                <QueryParamsFilterSelect
                  filterName="manager_id"
                  name="1:1"
                  options={oneToOneOptions}
                  setFilters={setFilters}
                />
              </div>
            </div>
          )}
          searchable
          pullSearchRight
        />
        <AgendaSendBanner />
        <ToastContainer />
        <ModalRenderer modals={props.modals}/>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    actionableAgendas: (state.actionableAgendas || []).slice(0, 3),
    disableSendButton: state.selectedListItems.length > 0,
    modals: state.modals
  };
};

export default connect(mapStateToProps)(AgendaListManager);
