import Immutable, { Map } from 'immutable';
import * as actions from '^/actions/actions';
import * as itemActions from '^/actions/items';


export function sortBy(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SORT_BY:
      return state.set(action.listName, action.value);
    default:
      return state;
  }
}

export function pageSize(state = Map(), action) {
  switch (action.type) {
    case actions.SET_PAGE_SIZE:
      return state.set(action.listName, action.value);
    default:
      return state;
  }
}

export function sortByReversed(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SORT_BY_REVERSED:
      return state.set(action.listName, action.value);
    default:
      return state;
  }
}

export function filters(state = Map(), action) {
  switch (action.type) {
    case actions.SET_SELECTED_FILTER:
      return state.setIn([action.listName, action.filterKey], action.value);
    default:
      return state;
  }
}


export function editingItem(state = Map(), action) {
  switch (action.type) {
    case itemActions.TOGGLE_EDIT_ITEM:
      if (Immutable.is(state.get(action.model, Map()), action.item)) {
        return state.delete(action.model);
      }
      return state.set(action.model, action.item);
    default:
      return state;
  }
}

export function showCustomFilters(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_SHOW_CUSTOM_FILTERS:
      return !state;
    default:
      return state;
  }
}

export function showCompareAnalysis(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_SHOW_COMPARE_ANALYSIS:
      return !state;
    default:
      return state;
  }
}
