import composeReducers from '^/reducers/composeReducers';
import { Map, fromJS } from 'immutable';
import * as items from '^/actions/items';


function itemsReducer(state = Map(), action) {
  switch (action.type) {
    case items.CLEAR_ITEM:
      return state.set(action.payload, null);
    case items.LOAD_ITEM.REQUEST:
      return (action.meta.id !== state.getIn([action.meta.itemType, 'id']))
        ? state.set(action.meta.itemType, null)
        : state;
    case items.LOAD_ITEM.SUCCESS:
      return state.set(action.meta.itemType, fromJS(action.payload));
    case items.UPDATE_ITEM.SUCCESS:
      return (state.getIn([action.meta.collectionName, 'id']) === action.payload.id)
        ? state.set(action.meta.collectionName, fromJS(action.payload))
        : state;
    case items.GET_ITEM_OPTIONS.SUCCESS:
      return state.setIn(['options', action.meta.collectionName], fromJS(action.payload));
    default:
      return state;
  }
}

export default composeReducers([itemsReducer], Map());
