import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { DELETE_AGENDA, deleteAgendaAndToast } from '^/components/agendas/actions';

export const DeleteAgendaModal = ({
  deleteAgenda,
  close,
  isDisabled
}) => {
  return (
    <div>
      <ModalBody>
        <p>Are you sure you want to delete this agenda?</p>
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button onClick={close}>Cancel</Button>
          <Button disabled={isDisabled} onClick={deleteAgenda} type="danger">Delete</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isDisabled: isPending(state.responses, DELETE_AGENDA)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    close: () => {
      dispatch(closeModal());
    },
    deleteAgenda: () => {
      dispatch(deleteAgendaAndToast(props.agendaId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAgendaModal);
