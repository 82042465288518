import { isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Loading from '^/components/Loading';
import { getAdminDashboardSurveyData, GET_ADMIN_DASHBOARD_SURVEYS } from '^/components/dashboards/actions';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import { DEEI_SURVEY, ENGAGEMENT_SURVEY, MENTAL_HEALTH_SURVEY } from '^/components/dashboards/constants';

const getBorderColor = (score) => {
  if (score >= 80) {
    return 'border-green';
  } else if (score >= 50) {
    return 'border-orange';
  } else {
    return 'border-red';
  }
};

const surveyIconMap = {
  [MENTAL_HEALTH_SURVEY]: 'mental_health',
  [ENGAGEMENT_SURVEY]: 'engagement',
  [DEEI_SURVEY]: 'culture_and_diversity',
};

export const SurveyTile = ({ type, data }) => {
  if (!data) {
    return (
      <div className="widget-item-hollow margin-bottom-large admin-dashboard-latest-surveys-tile">
        <h4 className="width-140">{type}</h4>
        <p className="flex-grow margin-left-75px font-size-14">No results to display.</p>
      </div>
    );
  }

  const score = (data.score * 10);
  return (
    <a
      href={data.report_url}
      className={
        classNames(
          'widget-item-hollow',
          'margin-bottom-large',
          'admin-dashboard-latest-surveys-tile',
          'text-decoration-none',
          'font-color-main'
        )
      }>
      <h4 className="width-140">{type}</h4>
      <img src={`/static/images/surveys/${surveyIconMap[type]}.png`} alt="Latest surveys" />
      <p>{`${data.number_of_respondents} participants`}</p>
      <p>{data.date}</p>
      <div className={`score-circle ${getBorderColor(score)}`}>
        <p>{score.toFixed(0)}%</p>
      </div>
    </a>
  );
};

export class AdminLatestSurveysWidget extends React.PureComponent {
  componentDidMount() {
    this.props.getAdminDashboardSurveyData();
  }

  render() {

    const { data, isLoading } = this.props;

    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="Latest survey scores" image="/static/images/dashboards/surveys.svg" />
        {isLoading && <Loading />}
        {data &&
          Object.entries(data).map(survey => <SurveyTile key={survey[0]} type={survey[0]} data={survey[1]} />)
        }
        <div className="margin-top-auto text-center">
          <a
            className="widget-view-all-button"
            href="/surveys/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    data: state.homepageAdminDashboardLatestSurveys.toJS(),
    isLoading: isPending(state.responses, GET_ADMIN_DASHBOARD_SURVEYS),
  };
}

export default connect(mapStateToProps, { getAdminDashboardSurveyData })(AdminLatestSurveysWidget);
