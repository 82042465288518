import { fromJS } from 'immutable';

import {
  GET_ADDITIONAL_FILTER_OPTIONS,
  UPDATE_ADDITIONAL_FILTER_OPTIONS,
  UPDATE_ADDITIONAL_FILTER_OPTION,
  REMOVE_ADDITIONAL_FILTER_OPTION,
  ADD_ADDITIONAL_FILTER_OPTION,
  UPDATE_ADDITIONAL_FILTERS_LIST,
  UPDATE_ADDITIONAL_FILTER,
  CREATE_CUSTOM_FILTER
} from '../components/filters/actions';

export function additionalFiltersList(state = [], action) {
  switch (action.type) {
    case UPDATE_ADDITIONAL_FILTERS_LIST:
      return action.additionalFilters;
    case UPDATE_ADDITIONAL_FILTER.SUCCESS:
      return state.map(additionalFilter => {
        if (additionalFilter.id === action.payload.data.id) {
          return Object.assign({}, additionalFilter, action.payload.data);
        }
        return additionalFilter;
      });
    case CREATE_CUSTOM_FILTER.SUCCESS:
      return [...state, action.payload.data];
    default:
      return state;
  }
}

export function additionalFilterOptions(state = [], action) {
  switch (action.type) {
    case GET_ADDITIONAL_FILTER_OPTIONS.SUCCESS:
      return action.payload.data;
    case UPDATE_ADDITIONAL_FILTER_OPTIONS:
      return action.options;
    case UPDATE_ADDITIONAL_FILTER_OPTION:
      return fromJS(state).setIn([action.index, 'label'], action.label).toJS();
    case REMOVE_ADDITIONAL_FILTER_OPTION:
      if (state.length > action.minimumNumberOfOptions) {
        return state.filter((_optionText, index) => index !== action.index);
      }
      return state;
    case ADD_ADDITIONAL_FILTER_OPTION:
      return state.concat([{label: ''}]);
    default:
      return state;
  }
}
