export const ENGAGEMENT = 'ENGAGEMENT';
export const MENTAL_HEALTH = 'MENTAL_HEALTH';
export const PULSE = 'PULSE';

export const PERFORMANCE_REVIEW = 'PERFORMANCE_REVIEW';
export const PERFORMANCE_REVIEW_READABLE_NAME = 'Performance Review';
export const isPerformanceReviewTypeDisplay = type_display => type_display === PERFORMANCE_REVIEW_READABLE_NAME;

export const QUESTIONNAIRE_TYPE_TO_READABLE_NAME = {
  [ENGAGEMENT]: 'Engagement',
  [MENTAL_HEALTH]: 'Mental Health',
  [PULSE]: 'Pulse',
};
