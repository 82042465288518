import { fromJS, Map } from 'immutable';

import {
  GET_ORGANISATION_EMAIL_TEMPLATES,
  GET_SURVEY_EMAIL_TEMPLATE,
  UPDATE_ORGANISATION_EMAIL_TEMPLATE,
  UPDATE_SURVEY_EMAIL_TEMPLATE,
} from '^/actions/actions';

export function organisationEmailSettings (state = Map(), action) {
  switch (action.type) {
    case GET_ORGANISATION_EMAIL_TEMPLATES.SUCCESS:
      return fromJS(action.payload);
    case UPDATE_ORGANISATION_EMAIL_TEMPLATE.SUCCESS:
      const { template_type } = action.payload;
      return template_type ? state.set(template_type, Map(action.payload)) : state;
    default:
      return state;
  }
}

export function surveyEmailTemplates (state = Map(), action) {
  switch (action.type) {
    case GET_SURVEY_EMAIL_TEMPLATE.SUCCESS:
    case UPDATE_SURVEY_EMAIL_TEMPLATE.SUCCESS:
      return state.set(action.meta.source, Map(action.payload));
    default:
      return state;
  }
}
