import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';

import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import PureComponent from '^/components/PureComponent';

import { actionsListConfig } from './config';
import { ACTIONS_PATH } from './const';
import { getManagerOptions, GET_MANAGER_OPTIONS } from '^/actions/actions';
import { getQueryParamsFromLocation } from '^/utils';
import roles from '^/roles';
import { userMeetsRequiredRole } from '^/utils/permissions';
import { isPending } from '@dabapps/redux-requests';


function ExtraControlsLeft({isShowingAllActions, canRunExports}) {
  if (canRunExports) {
    return (
      <a
      href={`/goals/actions/export/${isShowingAllActions ? '?show_all=true' : ''}`}
      className="btn orange"
      >
        Export {isShowingAllActions ? 'all' : 'my'} actions
      </a>
    );
  }
  return (
    null
  );
}

export class ActionsList extends PureComponent {
  componentDidMount() {
    this.props.getManagerOptions();
  }

  render() {
    const { canRunExports, teamActionsOptions, isLoadingManagerOptions, isShowingAllActions } = this.props;

    return (
      <div className="action-list will">
        <ChadminList
          collectionPath={ACTIONS_PATH}
          collectionName={ACTIONS_PATH}
          defaultFilters={{ordering: '-created_date'}}
          className="actions-list"
          getColumns={() => actionsListConfig}
          ExtraControlsLeft={() => (
            <ExtraControlsLeft
              canRunExports={canRunExports}
              isShowingAllActions={isShowingAllActions}
            />
          )}
          ExtraControlsRight={() => (
            <div>
              <UriFilterSelect
                filterName="show_all"
                name="show_all"
                label="Show"
                options={[{value: '', label: 'My actions'}, {value: true, label:'All actions'}]}
                hideBlankOption
                hasInlineLabels
              />
              <UriFilterSelect
                filterName="visible_for_manager"
                name="visible_for_manager"
                label="Reports To"
                options={teamActionsOptions}
                disabled={isLoadingManagerOptions}
                hideBlankOption
                hasInlineLabels
              />
            </div>
          )}
          searchable
          hasInlineLabels
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const filtersFromLocation = getQueryParamsFromLocation();
  const isShowingAllActions = Boolean(filtersFromLocation.show_all);
  return {
    isShowingAllActions,
    teamActionsOptions: [
      {value: '', label: 'All team actions'},
      ...state.managerOptions.toJS()
    ],
    isLoadingManagerOptions: isPending(state.responses, GET_MANAGER_OPTIONS),
    canRunExports: userMeetsRequiredRole(state.loggedInUser, roles.MANAGER_USER),
  };
}

export default connect(mapStateToProps, { getManagerOptions })(ActionsList);
