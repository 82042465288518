import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { clearAllSelectedListItems } from '^/actions/actions';
import SelectedListItemsBanner from '../common/SelectedListItemsBanner';
import { bulkSendAgendasAndToast, BULK_SEND_AGENDAS } from './actions';

export function AgendaSendBanner(props) {
  return (
    <SelectedListItemsBanner
      selectedItemsCount={props.selectedAgendasCount}
      isDisabled={props.isDisabled}
      buttonLabel="Send agenda(s)"
      buttonTitle={'A PDF summary of the agenda will be emailed to both participants.'}
      bannerText="agenda(s) selected"
      onClickSend={props.sendAgendas}
      onClickCancel={props.clearSelectedAgendas}
    />
  );
}


function mapStateToProps({selectedListItems, responses}) {
  const selectedAgendasCount = selectedListItems.length;
  return {
    selectedAgendasCount,
    selectedAgendas: selectedListItems,
    isDisabled: !selectedAgendasCount || isPending(responses, BULK_SEND_AGENDAS)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSelectedAgendas: () => dispatch(clearAllSelectedListItems()),
    sendAgendasFunction: (selectedAgendas) => () => dispatch(bulkSendAgendasAndToast(selectedAgendas))
  };
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    sendAgendas: dispatchProps.sendAgendasFunction(stateProps.selectedAgendas),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AgendaSendBanner);
