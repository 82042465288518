import React from 'react';
import { connect } from 'react-redux';
import { readAndCompressImage } from 'browser-image-resizer';
import { beginScaleImage, completeScaleImage } from './actions';
import { toast } from 'react-toastify';

const GENERIC_FILE_PROCESS_ERROR = 'Sorry but there was a problem processing your file.';
const DEFAULT_OPTIONS = {
  quality: 0.8,
  maxWidth: 400,
  maxHeight: 200
};

export class ImageUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    this.resizeImage = this.resizeImage.bind(this);
    this.clearImage = this.clearImage.bind(this);
  }

  resizeImage(event) {
    this.props.beginScaleImage(this.props.id);

    const selectedImage = event.target.files[0];

    readAndCompressImage(selectedImage, DEFAULT_OPTIONS).then(scaledImage => {
      this.props.uploadImage(scaledImage).then((response) => {
        this.props.onChange(response[0].id);
        this.props.completeScaleImage(this.props.id);
      }).catch(() => {
        toast.error(GENERIC_FILE_PROCESS_ERROR);
        this.props.completeScaleImage(this.props.id);
      });
    }).catch(() => {
      toast.error(GENERIC_FILE_PROCESS_ERROR);
      this.props.completeScaleImage(this.props.id);
    });
  }

  clearImage() {
    this.props.onChange('');
  }

  render() {
    return (
      <div>
        {this.props.value ? (
          <div>
            <p>Image selected.</p>
            <button
              className="btn small orange"
              onClick={this.clearImage}
            >
              Clear image
            </button>
          </div>
        ) : (
          <div>
            {this.props.isImageProcessing && (
              <p>Processing image...</p>
            )}
            <label
              className="btn orange hollow middle"
            >
              Choose file...
              <input
                disabled={this.props.isImageProcessing}
                onChange={this.resizeImage}
                type="file"
                className="display-none"
              />
            </label>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isImageProcessing: state.isImageProcessing.get(props.id)
  };
}

export default connect(mapStateToProps, {
  beginScaleImage,
  completeScaleImage
})(ImageUpload);
