import { List } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { isPending as isPendingLegacy } from '^/consts/responseStates';
import Loading from '^/components/Loading';
import PeerFeedbackListItem from '^/components/peer-feedback/PeerFeedbackListItem';

export function PeerFeedbackListItems({items, fields, isLoading, listName}) {

  if (isLoading) {
    return (
      <div className="margin-top-large padding-vertical-large">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {items.count() ? items.map(item => (
        <PeerFeedbackListItem
          key={`feedback-item-${item.get('id')}`}
          listName={listName}
          item={item}
          fields={fields}
        />
      )) : (
        <p className="margin-vertical-large padding-left-x-large">None Found.</p>
      )}
    </div>
  );
}

export function mapStateToProps({collections, legacyResponses}, {collectionName}) {
  return {
    items: collections.getIn([collectionName, 'items'], List()),
    isLoading: isPendingLegacy(legacyResponses.getIn(['getCollection', collectionName]))
  };
}

export default connect(mapStateToProps)(PeerFeedbackListItems);
