import React from 'react'; // eslint-disable-line no-unused-vars

import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Column, Row } from '@dabapps/roe';
import { isPending } from '@dabapps/redux-requests';

import { updateGenericErrorMessages } from '^/actions/actions';
import {
  createCustomQuestionDisplayToastCloseModalAndLoadList,
  updateCustomQuestionAndCloseModal
} from '^/actions/actionSequences';
import FormError from '^/components/forms/FormError';
import { isPending as isPendingLegacy } from '^/consts/responseStates';
import { QUESTION_TYPES, QUESTION_TYPE_DISPLAY, MINIMUM_OPTIONS } from './constants';
import { getCustomQuestionForEdit, GET_CUSTOM_QUESTION_FOR_EDIT } from './actions';
import Loading from '^/components/Loading';

import CustomQuestionOptions from './CustomQuestionOptions';

const LABEL_COLUMNS = 2;
const INPUT_COLUMNS = 9;
const FORM_NAME = 'add-custom-question';

export class CustomQuestionForm extends React.PureComponent {
  componentDidMount() {
    if (this.props.editQuestionId) {
      this.props.getCustomQuestionForEdit(this.props.editQuestionId);
    }
  }

  render() {
    const {
      disabled,
      handleSubmit,
      response,
      selectedType,
      optionsErrors,
      initialValues
    } = this.props;

    if (this.props.isLoadingQuestionForEdit) {
      return <Loading />;
    }

    return (
      <form onSubmit={handleSubmit} disabled={disabled}>
        <div className="modal-body padding-vertical-none">
          <p>
            Choose question type and enter question text. Custom questions can be selected on a per-survey basis.
          </p>
          <Row className="padding-top-x-large">
            <Column xs={12} md={2}>
              <label className="input-line-height">
                Question title
              </label>
            </Column>
            <Column xs={12} md={9}>
              <Field
                name="name"
                type="text"
                component="input"
                placeholder="Enter question text"
                autoFocus
              />
              <FormError response={response} formKey="name" />
            </Column>
          </Row>
          <Row>
            <Column xs={12} md={2}>
              <label className="input-line-height">
                Question
              </label>
            </Column>
            <Column xs={12} md={9}>
              <Row className="margin-bottom-large">
                <Column xs={12} md={3} className="padding-right-none">
                  <label className="input-line-height">
                    <Field
                      name="type"
                      type="radio"
                      component="input"
                      value={QUESTION_TYPES.TEXT}
                      disabled={disabled}
                    />
                    <span className="vertical-align-middle padding-left-small padding-right-none">
                      {QUESTION_TYPE_DISPLAY[QUESTION_TYPES.TEXT]}
                    </span>
                  </label>
                </Column>
                <Column xs={12} md={3} className="padding-left-small padding-right-none">
                  <label className="input-line-height">
                    <Field
                      name="type"
                      type="radio"
                      component="input"
                      value={QUESTION_TYPES.SLIDER}
                      disabled={disabled}
                    />
                    <span className="vertical-align-middle padding-left-small padding-right-none">
                      {QUESTION_TYPE_DISPLAY[QUESTION_TYPES.SLIDER]} (0-5-10)
                    </span>
                  </label>
                </Column>
                <Column xs={12} md={3} className="padding-left-none">
                  <label className="input-line-height">
                    <Field
                      name="type"
                      type="radio"
                      component="input"
                      value={QUESTION_TYPES.RADIO}
                      disabled={disabled}
                    />
                    <span className="vertical-align-middle padding-left-small padding-right-none">
                      {QUESTION_TYPE_DISPLAY[QUESTION_TYPES.RADIO]}
                    </span>
                  </label>
                </Column>
                <Column xs={12} md={3} className="padding-left-none">
                  <label className="input-line-height no-wrap">
                    <Field
                      name="type"
                      type="radio"
                      component="input"
                      value={QUESTION_TYPES.MULTIPLE}
                      disabled={disabled}
                    />
                    <span className="vertical-align-middle padding-left-small">
                      {QUESTION_TYPE_DISPLAY[QUESTION_TYPES.MULTIPLE]}
                    </span>
                  </label>
                </Column>
              </Row>
              <FormError response={response} formKey="type" />
            </Column>
          </Row>
          {optionsErrors.map((message, index) => (
            <p key={`error-${index}`} className="margin-bottom-base error">{message}</p>
          ))}
          {[QUESTION_TYPES.RADIO, QUESTION_TYPES.MULTIPLE].includes(selectedType) && (
            <CustomQuestionOptions
              initialOptions={initialValues && initialValues.options}
              questionType={selectedType}
              minimumNumberOfOptions={MINIMUM_OPTIONS[selectedType]}
            />
          )}
          {selectedType === QUESTION_TYPES.SLIDER && (
            <div className="margin-bottom-large">
              <Row className="margin-top-small">
                <Column xs={12} md={LABEL_COLUMNS}>
                  <label className="input-line-height">'0' label</label>
                </Column>
                <Column xs={12} md={INPUT_COLUMNS}>
                  <Field
                    name="slider_low_score_label"
                    type="text"
                    component="input"
                    placeholder="Strongly disagree"
                    disabled={disabled}
                  />
                  <FormError response={response} formKey="slider_low_score_label" />
                </Column>
              </Row>
              <Row className="margin-top-small">
                <Column xs={12} md={LABEL_COLUMNS}>
                  <label className="input-line-height">'5' label</label>
                </Column>
                <Column xs={12} md={INPUT_COLUMNS}>
                  <Field
                    name="slider_mid_score_label"
                    type="text"
                    component="input"
                    placeholder="Neutral"
                    disabled={disabled}
                  />
                  <FormError response={response} formKey="slider_mid_score_label" />
                </Column>
              </Row>
              <Row className="margin-top-small">
                <Column xs={12} md={LABEL_COLUMNS}>
                  <label className="input-line-height margin-bottom-none">'10' label</label>
                </Column>
                <Column xs={12} md={INPUT_COLUMNS}>
                  <Field
                    name="slider_high_score_label"
                    type="text"
                    component="input"
                    placeholder="Strongly agree"
                    disabled={disabled}
                  />
                  <FormError response={response} formKey="slider_high_score_label" />
                </Column>
              </Row>
            </div>
          )}
        </div>
        <Row className="margin-vertical-large">
          <Column>
            <FormError response={response} formKey="non_field_errors" />
            <button className="btn middle orange" type="submit" disabled={disabled}>
              {this.props.editQuestionId ? 'Save' : 'Add'} custom question
            </button>
          </Column>
        </Row>
      </form>
    );
  }
}

CustomQuestionForm.propTypes = {
  surveyId: React.PropTypes.number,
  disabled: React.PropTypes.bool,
  form: React.PropTypes.string,
  selectedType: React.PropTypes.string,
  customQuestionOptions: React.PropTypes.array,
  optionsErrors: React.PropTypes.array,
  response: ImmutablePropTypes.map,
};


export function mapStateToProps(state, props) {
  const legacyResponse = state.legacyResponses.get('createCustomQuestion');
  return {
    disabled: isPendingLegacy(legacyResponse),
    form: FORM_NAME,
    selectedType: formValueSelector(FORM_NAME)(state, 'type'),
    customQuestionOptions: state.customQuestionOptions,
    optionsErrors: state.genericErrorMessages,
    response: legacyResponse,
    isLoadingQuestionForEdit: isPending(state.responses, GET_CUSTOM_QUESTION_FOR_EDIT),
    initialValues: props.editQuestionId && state.customQuestionForEdit,
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    getOnSubmitFunction: (options) => (data) => {
      const hasBlankOptions = options.filter(option => !option).length > 0;

      if (hasBlankOptions) {
        const errorMessages = [];
        errorMessages.push('Please ensure all options have been completed.');
        dispatch(updateGenericErrorMessages(errorMessages));
      } else {
        dispatch(updateGenericErrorMessages([]));

        if (props.editQuestionId) {
          dispatch(
            updateCustomQuestionAndCloseModal(
              props.surveyId,
              props.editQuestionId,
              Object.assign({}, data, { options })
            )
          );
        } else {
          dispatch(
            createCustomQuestionDisplayToastCloseModalAndLoadList(
              props.surveyId,
              Object.assign({}, data, { options })
            )
          );
        }
      }
    },
    getCustomQuestionForEdit: (questionId) => getCustomQuestionForEdit(questionId)(dispatch),
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    onSubmit: dispatchProps.getOnSubmitFunction(stateProps.customQuestionOptions)
  });
}

export const FormifiedCustomQuestionForm = reduxForm(
  {
    destroyOnUnmount: true,
    enableReinitialize: true,
    initialValues: { type: QUESTION_TYPES.TEXT, }
  }
)(CustomQuestionForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FormifiedCustomQuestionForm);
