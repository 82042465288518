import { anyPending } from '@dabapps/redux-requests';
import { Row, Column } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { toggleItemExpanded } from '^/actions/actions';
import Loading from '^/components/Loading';
import FilterSelect from '^/components/common/FilterSelect';
import {
  getCustomReportFilterOptions,
  updateSelectedFilters,
  GET_CUSTOM_REPORT_FILTER_OPTIONS,
  createFilteredCustomReportAndToast,
  CREATE_FILTERED_CUSTOM_REPORT,
} from '^/components/custom-reports/actions';
import CustomReportCollapseButton from '^/components/custom-reports/CustomReportCollapseButton';
import { stripFalsyValuesFromObject } from '^/utils';

const PRIMARY_FILTER_NAMES = ['team', 'location', 'department', 'unit', 'manager'];

export const getFiltersFromReport = ({
  team_filter, location_filter, department_filter, unit_filter, manager_filter, additional_filters, group_by
}) => ({
  team_filter,
  location_filter,
  department_filter,
  unit_filter,
  manager_filter,
  additional_filters: Object.keys(additional_filters).length && additional_filters,
  group_by
});

const normaliseIntId = value => value ? parseInt(value, 10) : null;

function getHasChangedFilterSelection(originalFilterSelection, report) {
  const hasChangedPrimaryFilters = !!PRIMARY_FILTER_NAMES.filter(filterName => {
    const filterKey = `${filterName}_filter`;
    return normaliseIntId(originalFilterSelection[filterKey]) !== normaliseIntId(report[filterKey]);
  }).length;
  const originalAdditionalFilterSelection = Object.values(originalFilterSelection.additional_filters || {});
  const additionalFilterSelection = Object.values(report.additional_filters);
  const hasChangedAdditionalFilters = !!additionalFilterSelection.filter(filterOptionId =>
    !originalAdditionalFilterSelection.includes(filterOptionId)
  ).length || originalAdditionalFilterSelection.length !== additionalFilterSelection.length;

  return hasChangedPrimaryFilters || hasChangedAdditionalFilters;
}

export class CustomReportFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formatAdditionalFilters = this.formatAdditionalFilters.bind(this);
    this.originalFilterSelection = getFiltersFromReport(this.props.report);
  }

  formatAdditionalFilters(filterId, filterValue) {
    return {
      additional_filters: stripFalsyValuesFromObject(Object.assign(
        {},
        this.props.report.additional_filters,
        { [filterId]: filterValue }
      ))
    };
  }

  render() {
    const hasChangedFilterSelection = getHasChangedFilterSelection(this.originalFilterSelection, this.props.report);

    return (
      <div className="border-radius-base border-light-grey padding-large margin-bottom-large">
        <div className="display-flex cursor-pointer" onClick={this.props.toggleExpanded}>
          <div className="font-size-18 font-weight-bold line-height-40px">Filters</div>
          <CustomReportCollapseButton isExpanded={this.props.isExpanded} />
        </div>
        <div className={classNames({
          'display-none': !this.props.isExpanded
        })}>
          {this.props.filterOptions ? (
            <div>
              <Row className="margin-top-x-large">
                {PRIMARY_FILTER_NAMES.map(filterName => (
                  <Column key={`${filterName}-filter-${this.props.reportId}`} xs={2}>
                    <FilterSelect
                      filterKeyName={`${filterName}_filter`}
                      displayName={this.props.organisationFilterTerms[filterName]}
                      options={this.props.filterOptions[`${filterName}s`]}
                      setFilters={this.props.updateSelectedFilters}
                      value={this.props.report[`${filterName}_filter`]}
                      disabled={this.props.isDisabled}
                      boldLabel
                    />
                  </Column>
                ))}
              </Row>
              <Row className="margin-top-large">
                {this.props.filterOptions.additional_filters.map((additionalFilter, index) => (
                  <Column xs={2} key={`${additionalFilter.id}-filter-${this.props.reportId}`} className={classNames({
                    'margin-top-large': index > 5
                  })}>
                    <FilterSelect
                      filterKeyName={additionalFilter.id}
                      displayName={additionalFilter.name}
                      options={additionalFilter.options}
                      formatFilters={this.formatAdditionalFilters}
                      setFilters={this.props.updateSelectedFilters}
                      value={this.props.report.additional_filters[additionalFilter.id]}
                      disabled={this.props.isDisabled}
                      boldLabel
                    />
                  </Column>
                ))}
              </Row>
              <button
                className="pink-border-button margin-top-x-large"
                onClick={this.props.getFilteredReport}
                disabled={this.props.isDisabled || !hasChangedFilterSelection}
                title={!hasChangedFilterSelection ? 'Filter selection has not changed' : undefined}
              >
                Apply filters
              </button>
            </div>
          ) : <Loading />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const unFiltereredReportId = props.report.unfiltered_report_id || props.report.id;
  return {
    isExpanded: state.expandedItems.includes(unFiltereredReportId),
    filterOptions: state.customReportFilterOptions[unFiltereredReportId],
    isDisabled: anyPending(state.responses, [GET_CUSTOM_REPORT_FILTER_OPTIONS, CREATE_FILTERED_CUSTOM_REPORT]),
  };
}

function mapDispatchToProps(dispatch, props) {
  const unFiltereredReportId = props.report.unfiltered_report_id || props.report.id;
  return {
    updateSelectedFilters: (newFilter) => {
      dispatch(updateSelectedFilters(props.report.id, newFilter));
      dispatch(getCustomReportFilterOptions(
        unFiltereredReportId,
        stripFalsyValuesFromObject(Object.assign(
          getFiltersFromReport(props.report),
          newFilter
        ))
      ));
    },
    getFilteredReport: () => dispatch(createFilteredCustomReportAndToast(
      unFiltereredReportId,
      stripFalsyValuesFromObject(getFiltersFromReport(props.report)),
      props.report.id
    )),
    toggleExpandedFunction: (isExpanded, filterOptions) => () => {
      if (!isExpanded && !filterOptions) {
        dispatch(getCustomReportFilterOptions(
          unFiltereredReportId,
          stripFalsyValuesFromObject(getFiltersFromReport(props.report))
        ));
      }
      dispatch(toggleItemExpanded(unFiltereredReportId));
    }
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    toggleExpanded: dispatchProps.toggleExpandedFunction(
      stateProps.isExpanded,
      stateProps.filterOptions
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomReportFilters);
