import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { connect } from 'react-redux';
import { sendConfirmationEmailAndToast, SEND_CONFIRMATION_EMAIL } from './actions';

export const AgendaSaveAsDraftModal = (props) => {
  return (
    <div>
      <ModalBody>
        <p>
          When saved as a draft, you can continue to edit your agenda.
        </p>
        <p>
          Please note that once saved as a draft, your employee will receive an email notification
          to let them know you have scheduled a 1:1 meeting with them.
        </p>
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button disabled={props.isDisabled} onClick={props.save}>Save draft & send invite</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isDisabled: isPending(state.responses, SEND_CONFIRMATION_EMAIL)
  };
};

function mapDispatchToProps(dispatch, props) {
  return {
    save: () => {
      dispatch(sendConfirmationEmailAndToast(props.agendaId));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaSaveAsDraftModal);
