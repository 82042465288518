import { List, fromJS } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { getCollection } from '^/actions/collections';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'underscore';
import { isPending } from '^/consts/responseStates';
import USER_ROLES from '^/roles';


export class UserSearch extends PureComponent {
  constructor (props) {
    super(props);

    const throttled = _.throttle((e) => {
      this.props.getCollection(
        'user',
        {
          searchString: e.target.value,
          additionalParams: {
            'type': USER_ROLES.MANAGER_USER
          }
        }
      );
    }, 500, {trailing: true});

    this.onInputKeyDown = e => {
      e.persist();
      return throttled(e);
    };

    this.state = {
      selected: []
    };
  }

  onChange(selected) {
    this.setState({
      selected
    });
  }

  render() {
    const options = this.props.users.map(user => {
      return {
        value: user.get('username'),
        label: user.get('name'),
      };
    });

    return (
      <div>
        <Select
          name="user-selection"
          options={options.toJS()}
          onInputKeyDown={this.onInputKeyDown}
          onChange={selected => this.onChange(selected)}
          value={this.state.selected}
          isLoading={this.props.isLoading}
          multi />
        <input className="hidden" name={this.props.fieldName} type="text" value={
          fromJS(this.state.selected).reduce((prev, curr) => prev + (prev ? ',' : '') + curr.get('value'), '')
        } />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    users: state.collections.getIn(['user', 'items'], List()),
    isLoading: isPending(state.legacyResponses.getIn(['getCollection', 'user']))
  };
}

export default connect(mapStateToProps, { getCollection })(UserSearch);
