import React from 'react'; // eslint-disable-line no-unused-vars

import ChadminList from '^/components/common/ChadminList';
import {
  PEER_FEEDBACK_INDIVIDUAL_LIST_URL,
  INDIVIDUAL_LIST_ITEM_HEADINGS,
  INDIVIDUAL_LIST_ITEM_FIELDS,
  LIST_OPTIONS
} from '^/components/peer-feedback/constants';
import PeerFeedbackListHeader from '^/components/peer-feedback/PeerFeedbackListHeader';
import PeerFeedbackListItems from '^/components/peer-feedback/PeerFeedbackListItems';
import PeerFeedbackListModalButtons from '^/components/peer-feedback/PeerFeedbackListModalButtons';
import { Filter } from '^/components/peer-feedback/PeerFeedbackTeamList';

export function PeerFeedbackIndividualList() {
  return (
    <div>
      <ChadminList
        className="border-base padding-large border-radius-16 border-radius-top-left-0"
        collectionPath={PEER_FEEDBACK_INDIVIDUAL_LIST_URL}
        collectionName={PEER_FEEDBACK_INDIVIDUAL_LIST_URL}
        ExtraControlsRight={({setFilters}) => (
          <div className="display-flex flex-wrap">
            <Filter
              options={[
                {value: 'read', label: 'Read'},
                {value: 'unread', label: 'Unread'}
              ]}
              filterName="status"
              name="status"
              setFilters={setFilters}
            />
          </div>
        )}
        ExtraControlsLeft={({reloadPage}) => (
          <PeerFeedbackListModalButtons reloadIndividualListPage={reloadPage} isIndividualList />
        )}
        searchable
        pullSearchRight
      >
        <div className="overflow-auto font-family-varela-round font-size-14">
          <div className="min-width-640">
            <PeerFeedbackListHeader
              headings={INDIVIDUAL_LIST_ITEM_HEADINGS}
              listName={LIST_OPTIONS.INDIVIDUAL}
              collectionName={PEER_FEEDBACK_INDIVIDUAL_LIST_URL}
            />
            <PeerFeedbackListItems
              collectionName={PEER_FEEDBACK_INDIVIDUAL_LIST_URL}
              listName={LIST_OPTIONS.INDIVIDUAL}
              fields={INDIVIDUAL_LIST_ITEM_FIELDS}
            />
          </div>
        </div>
      </ChadminList>
    </div>
  );
}

export default PeerFeedbackIndividualList;
