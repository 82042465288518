import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Button, ModalBody, ModalFooter, SpacedGroup } from '@dabapps/roe';
import PureComponent from '^/components/PureComponent';
import { REMINDER_ALL_MESSAGE, REMINDER_EMAIL_TEMPLATE_URLS, REMINDER_TYPES } from './constants';
import { closeModal } from '../../actions/modals';
import { sendBubbleRemindersAndToast } from '../../actions/actionSequences';
import { connect } from 'react-redux';

export class ConfirmReminderModal extends PureComponent {
  render() {
    const { reminderType, selectedUsers, sendReminder } = this.props;
    const selectedUserCount = selectedUsers.length;

    const {
      ACTIVATION,
      ACTION,
      LOGIN
    } = REMINDER_TYPES;

    const {
      ACTIVATION_EMAIL_TEMPLATE_URL,
      ACTION_EMAIL_TEMPLATE_URL,
      LOGIN_EMAIL_TEMPLATE_URL
    } = REMINDER_EMAIL_TEMPLATE_URLS;

    const REMINDER_TEMPLATE_URL = {
      [ACTIVATION]: ACTIVATION_EMAIL_TEMPLATE_URL,
      [ACTION]: ACTION_EMAIL_TEMPLATE_URL,
      [LOGIN]: LOGIN_EMAIL_TEMPLATE_URL
    };

    const getModalBodyMessage = () => {
      let message = selectedUserCount ?
      `
      Are you sure you want to send ${reminderType} reminders to ${selectedUserCount}
      user${selectedUserCount > 1 ? 's' : ''}?
      ` :
      `Are you sure you want to send ${reminderType} reminders to all ${REMINDER_ALL_MESSAGE[reminderType]}?`;

      if (reminderType === REMINDER_TYPES.ACTIVATION && selectedUserCount) {
        message += ' This reminder will only be sent to those who have not activated their logins yet.';
      }

      return <div>
        <p>{message}</p>
        <p className="margin-top-large">
          To view the email template before sending,
          click <a href={REMINDER_TEMPLATE_URL[reminderType]} target="_blank">here</a>
        </p>
      </div>;
    };

    return (
      <div>
        <ModalBody>
          { getModalBodyMessage() }
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
          <Button className="btn gray middle" onClick={this.props.closeModal}>
            Back
          </Button>
          <Button className="btn success middle" onClick={sendReminder}>
            Send
          </Button>
          </SpacedGroup>
        </ModalFooter>
      </div>
    );
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    sendReminder: () => {
      dispatch(sendBubbleRemindersAndToast(
        ownProps.selectedUsers,
        ownProps.reminderType
      ));
      dispatch(closeModal());
    }
  };
}

ConfirmReminderModal.propTypes = {
  reminderType: PropTypes.oneOf(Object.values(REMINDER_TYPES)),
  selectedUsers: PropTypes.array
};

export default connect(null, mapDispatchToProps)(ConfirmReminderModal);
