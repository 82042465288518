import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars

import roles from '^/roles';
import { userMeetsRequiredRole } from '^/utils/permissions';

export const DashboardTab = ({label, href, active}) => {
  return (
    <a
      href={href}
      className={classNames('dashboard-tab', {'active': active})}
    >
      {label}
    </a>
  );
};

export const DashboardTabs = ({ loggedInUser, activeTab }) => {
  if (
    !userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER)
  ) {
    return null;
  }
  return (
    <div className="dashboard-tabs">
      <DashboardTab label="Me" href="/my-dashboard" active={activeTab === 0} />
      <DashboardTab label="My team" href="/team-dashboard" active={activeTab === 1} />
      {userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN) &&
        <DashboardTab label="Exec dashboard" href="/exec-dashboard" active={activeTab === 2} />
      }
    </div>
  );
};

export default DashboardTabs;
