import { ModalBody } from '@dabapps/roe';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import { ADD_AGENDA_GOAL, agendaItemEditAction } from '^/components/agendas/actions';
import { AgendaGoalType } from '^/components/agendas/constants';
import CreateGoal from '^/components/goals/CreateGoal';
import AgendaGoalManager from '^/components/agendas/AgendaGoalManager';
import CreateAgendaGoalManagerForm from '^/components/agendas/CreateAgendaGoalManagerForm';

export function AgendaGoalsManager(props) {
  const { agendaId, goals, addGoal } = props;
  const displayCreate = Boolean(!goals.length || addGoal);
  const displayAddNew = Boolean(goals.length && !addGoal);
  return (
    <div>
      <h5 className="font-size-14 font-weight-bold">
        Add new goal
      </h5>
      {goals.map((goal) => (
        <AgendaGoalManager
          key={goal.id}
          agendaGoalId={goal.id}
          agendaId={agendaId}
          goal={goal}
          isDisabled={props.isDisabled}
        />
      ))}
      {displayCreate &&
        <CreateAgendaGoalManagerForm
          agendaId={agendaId}
          onSubmit={props.openAddGoalModal}
          isDisabled={props.isDisabled}
        />
      }
      {displayAddNew &&
        <button
          type="button"
          className="btn middle orange"
          onClick={props.addAgendaGoal}
          disabled={props.isDisabled}
        >
          Add new goal
        </button>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    addGoal: state.editingAgendaItemIds.includes(ADD_AGENDA_GOAL),
    modals: state.modals,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addAgendaGoal: () => dispatch(agendaItemEditAction(ADD_AGENDA_GOAL)),
    openAddGoalModal: (data) => dispatch(
      openSimpleModal({
        title: 'Add new goal',
        body: (
          <ModalBody>
            <CreateGoal
              initialValues={data}
              agendaId={ownProps.agendaId}
              agendaGoalType={AgendaGoalType.MANAGER}
            />
          </ModalBody>
        ),
        large: true,
      })
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaGoalsManager);
