import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

export const AdminListSearch = props => {
  const { handleSubmit, isLoading } = props;

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <span className="admin-list-search-container">
          <Field name="searchString" disabled={isLoading} component="input" type="text" />
          <button className="btn orange" onClick={handleSubmit}>Search</button>
        </span>
      </form>
    </div>
  );
};

AdminListSearch.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default reduxForm({
  form: 'searchForm'
})(AdminListSearch);
