import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';  // eslint-disable-line no-unused-vars

import CopyToClipboard from '^/components/common/CopyToClipboard';
import { SURVEY_STATES, SURVEY_STATES_DISPLAY, RESPONSE_STATES } from '^/components/survey/constants';
import { FORMAT_DATE } from '^/utils';
import { userMeetsRequiredRole } from '^/utils/permissions';
import roles from '^/roles';

export const mySurveyResponsesConfig = [
  {
    name: 'survey__name',
    display_name: 'Survey name',
    type: 'custom',
    customItemHandler: (response) => (
      <span>
        <span className="display-block">{response.survey_name}</span>
        <span className="display-block text-help">{response.type_display}</span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'survey__date_opened',
    display_name: 'Started',
    type: 'custom',
    customItemHandler: (response) => moment(response.date_opened).format(FORMAT_DATE),
    sortable: true,
  },
  {
    name: 'complete',
    display_name: 'Completed on',
    type: 'custom',
    customItemHandler: (response) => (
      response.complete ? moment(response.complete).format(FORMAT_DATE) : 'incomplete'
    ),
    sortable: true,
  },
  {
    name: 'actions',
    display_name: 'Actions',
    type: 'custom',
    customItemHandler: (response) => {
      const isSurveyClosed = response.survey_state === SURVEY_STATES.CLOSED;
      if (!response.complete) {
        return (
          <a
            href={isSurveyClosed ? undefined : `/questionnaire/${response.uuid}/`}
            title={isSurveyClosed ? 'This survey is now closed.' : undefined}
            disabled={isSurveyClosed}
          >
            Complete survey
          </a>
        );
      }
      return response.report_url ?
        (<a href={`${response.report_url}?is_my_survey_responses_list=true`}>View report</a>) :
        '--';
    },
    sortable: false,
  },
];

export function getSurveyDashboardLink(survey) {
  return `/reports/generate-unfiltered-manager-report/${survey.id}/bar-and-suggestions/`;
}

export function getSurveyHeatmapLink(survey) {
  return `/reports/generate-unfiltered-manager-report/${survey.id}/heatmap/`;
}

export const getOrganisationSurveysConfig = (loggedInUser, isExecutive) => [
  {
    name: 'name',
    display_name: 'Survey name',
    type: 'custom',
    customItemHandler: (survey) => (
      <span>
        <span className="display-block">{survey.name}</span>
        <span className="display-block text-help">{survey.type_display}</span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'auto_start_date',
    display_name: 'Start date',
    type: 'custom',
    customItemHandler: (survey) => moment(survey.date_started).format(FORMAT_DATE),
    sortable: true,
  },
  {
    name: 'date_closed_or_will_close',
    display_name: 'End date',
    type: 'custom',
    customItemHandler: (survey) => moment(survey.date_closed_or_will_close).format(FORMAT_DATE),
    sortable: true,
  },
  {
    name: 'state',
    display_name: 'Status',
    type: 'custom',
    customItemHandler: (survey) => SURVEY_STATES_DISPLAY[survey.state] || SURVEY_STATES_DISPLAY.CLOSED,
    sortable: true,
  },
  {
    name: 'completed',
    display_name: 'Completed',
    type: 'custom',
    customItemHandler: (survey) => `${survey.survey_members_completed} of ${survey.survey_members_total}`,
    sortable: false,
  },
  {
    name: 'auto_nudge_enabled',
    display_name: 'Auto nudge',
    type: 'custom',
    customItemHandler: (survey) => (
      <i
        title="1"
        className={classNames('far', {
          'fa-check': survey.auto_nudge_enabled,
          'fa-times': !survey.auto_nudge_enabled,
          'ico-check': survey.auto_nudge_enabled,
          'ico-times': !survey.auto_nudge_enabled
        })}
        aria-hidden={survey.auto_nudge_enabled}
      ></i>
    ),
    sortable: true,
  },
  {
    name: 'warmup_enabled',
    display_name: 'Warm-up',
    type: 'custom',
    customItemHandler: (survey) => (
      <i
        title="1"
        className={classNames('far', {
          'fa-check': survey.warmup_enabled,
          'fa-times': !survey.warmup_enabled,
          'ico-check': survey.warmup_enabled,
          'ico-times': !survey.warmup_enabled
        })}
        aria-hidden={survey.warmup_enabled}
      ></i>
    ),
    sortable: true,
  },
  {
    name: 'dashboard',
    display_name: 'Dashboard',
    type: 'custom',
    customItemHandler: (survey) => {
      const isDisabled = Boolean(
        survey.is_pulse_survey ||
        [SURVEY_STATES.OPEN, SURVEY_STATES.CLOSED].indexOf(survey.state) === -1
      );
      const name = 'Dashboard';
      const notAvailableMessage = survey.is_pulse_survey ?
        `${name} not available for Pulse surveys.` :
        `${name} not available until survey has started.`;
      const title = isDisabled ? notAvailableMessage : name;
      const href = isDisabled ? undefined : getSurveyDashboardLink(survey);
      const className = classNames('btn-icon orange margin-none text-center', { disabled: isDisabled });
      return (
        <a className={className} title={title} href={href}>
          <i className="far fa-tachometer-alt margin-none" aria-hidden="true"></i>
        </a>
      );
    },
    sortable: false,
  },
  {
    name: 'heatmap',
    display_name: 'Heatmap',
    type: 'custom',
    customItemHandler: (survey) => {
      const isDisabled = Boolean(
        !survey.has_heatmaps ||
        [SURVEY_STATES.OPEN, SURVEY_STATES.CLOSED].indexOf(survey.state) === -1
      );
      const name = 'Heatmap';
      const notAvailableMessage = !survey.has_heatmaps ?
        `${name} not available for ${survey.type_display} surveys.` :
        `${name} not available until survey has started.`;
      const title = isDisabled ? notAvailableMessage : name;
      const href = isDisabled ? undefined : getSurveyHeatmapLink(survey);
      const className = classNames('btn-icon orange margin-none text-center', { disabled: isDisabled });
      return (
        <a className={className} title={title} href={href}>
          <i className="far fa-chart-bar margin-none" aria-hidden="true"></i>
        </a>
      );
    },
    sortable: false,
  },
  {
    name: 'status',
    display_name: 'Status',
    type: 'custom',
    customItemHandler: (survey) => {
      const isDisabled = survey.state === SURVEY_STATES.EDIT || !survey.can_access_status;
      const name = 'Status';
      const notAvailableMessage = !survey.can_access_status ?
        'You are not allowed to access the status for this survey' :
        `${name} not available whilst still setting the survey.`;
      const title = isDisabled ? notAvailableMessage : name;
      const href = isDisabled ? undefined : `/surveys/${survey.id}/status/`;
      const className = classNames('btn-icon orange margin-none text-center', { disabled: isDisabled });
      return (
        <a className={className} title={title} href={href}>
          <i className="far fa-info margin-none" aria-hidden="true"></i>
        </a>
      );
    },
    sortable: false,
  },
  {
    name: 'settings',
    display_name: 'Settings',
    type: 'custom',
    customItemHandler: (survey) => {
      const isDisabled = !Boolean(
        !isExecutive &&
        (
          userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN) ||
          loggedInUser.get('id') === survey.created_by
        )
      );
      const title = isDisabled ? 'You are not allowed to access the settings this survey.' : 'Settings';
      const href = isDisabled ? undefined : `/surveys/${survey.id}/settings/`;
      const className = classNames('btn-icon orange margin-none text-center', { disabled: isDisabled });
      return (
        <a className={className} title={title} href={href}>
          <i className="far fa-cog margin-none" aria-hidden="true"></i>
        </a>
      );
    },
    sortable: false,
  },
];

export const getStatusUserListConfig = (
  organisationFilterTerms,
  isSurveyClosed,
  canEditSurvey,
  isAnonymousSurvey,
  teamCustomTerm,
  openDeleteModal,
  reloadPage,
  showQuestionnaireLinks
) => [
  {
    name: 'team_member__user__first_name',
    display_name: 'Name/Email',
    type: 'custom',
    customItemHandler: surveyTeamMember => (
      <span>
        <span className="display-block">{surveyTeamMember.display_name}</span>
        <span className="display-block text-help">{surveyTeamMember.email}</span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team',
    display_name: `${organisationFilterTerms.team}`,
    type: 'text',
    sortable: false
  },
  {
    name: 'location',
    display_name: `${organisationFilterTerms.location}`,
    type: 'text',
    sortable: false
  },
  {
    name: 'department',
    display_name: `${organisationFilterTerms.department}`,
    type: 'text',
    sortable: false
  },
  {
    name: 'unit',
    display_name: `${organisationFilterTerms.unit}`,
    type: 'text',
    sortable: false
  },
  {
    name: 'manager',
    display_name: `${organisationFilterTerms.manager}`,
    type: 'text',
    sortable: false
  },
  {
    name: 'state',
    display_name: 'Status',
    type: 'custom',
    sortable: false,
    customItemHandler: surveyTeamMember => {
      if (surveyTeamMember.is_copied_member) {
        return <span className="text-gray">Recent response included</span>;
      }

      return surveyTeamMember.state;
    }
  },
  {
    name: 'percent_survey_completed',
    display_name: 'Progress',
    type: 'custom',
    sortable: false,
    customItemHandler: surveyTeamMember => {
      if (surveyTeamMember.is_copied_member) {
        return <span className="text-gray">-</span>;
      }

      return surveyTeamMember.percent_survey_completed || '-';
    }
  },
  {
    name: 'last_nudged',
    display_name: 'Last Nudged',
    type: 'custom',
    sortable: false,
    customItemHandler: surveyTeamMember => {
      if (surveyTeamMember.is_copied_member) {
        return <span className="text-gray">-</span>;
      }

      return surveyTeamMember.last_nudged;
    }
  },
  ...(canEditSurvey ? [
    {
      name: 'nudge',
      display_name: 'Nudge',
      type: 'custom',
      customItemHandler: surveyTeamMember => {
        const canBeNudged = surveyTeamMember.is_active && !surveyTeamMember.is_copied_member;

        return (
          <a
            href={canBeNudged ? surveyTeamMember.nudge_link : undefined}
            className={classNames('btn-icon orange margin-none', surveyTeamMember.is_active ? '' : 'disabled')}
            title={
              canBeNudged
                ? 'Nudge'
                : 'Cannot nudge this person as they are inactive or were brought in from a previous survey.'
            }
            disabled={!canBeNudged}
          >
            <i className="far fa-paper-plane" aria-hidden="true" />
          </a>
        );
      },
      sortable: false
    },
    ...(showQuestionnaireLinks ? [
      {
        name: 'link',
        display_name: 'Link',
        type: 'custom',
        customItemHandler: surveyTeamMember => (
          <CopyToClipboard
            href={surveyTeamMember.questionnaire_link}
            className="btn-icon orange margin-none"
            title="Click to copy a weblink for this person’s survey"
            disabled={surveyTeamMember.is_copied_member}
          >
            <i className="far fa-link" aria-hidden="true" />
          </CopyToClipboard>
        ),
        sortable: false
      }
    ] : []),
    {
      name: 'delete',
      display_name: 'Delete',
      type: 'custom',
      customItemHandler: surveyTeamMember => {
        const canDelete = !isSurveyClosed;
        return (
          <a
            className={classNames('btn-icon margin-none orange', !canDelete ? ' disabled' : '')}
            onClick={() => canDelete ? openDeleteModal(surveyTeamMember, reloadPage) : null}
            title={isSurveyClosed ? 'You cannot delete members from a closed survey.' : null}
            disabled={!canDelete}
          >
            <i className="far fa-trash" aria-hidden="true"/>
          </a>
        );
      },
      sortable: false
    },
  ] : []),
  ...((isAnonymousSurvey) ? [] : [
    {
      name: 'report',
      display_name: 'Report',
      type: 'custom',
      customItemHandler: surveyTeamMember => {
        const isResponseComplete = surveyTeamMember.state === RESPONSE_STATES.COMPLETE;
        return (
          <a
          className={classNames('btn-icon margin-none orange', { disabled: !isResponseComplete })}
          href={isResponseComplete ? surveyTeamMember.report_link : null}
          title={
            isResponseComplete ?
              'View report' :
              'You cannot view an individual report until they have completed their response'
          }
          disabled={surveyTeamMember.is_copied_member}
        >
          <i className="far fa-clipboard-list" aria-hidden="true" />
        </a>
        );
      },
      sortable: false
    },
  ]),
];
