import React from 'react'; // eslint-disable-line no-unused-vars

import makeRsaAction from '^/middleware/makeRsaAction';
import { stripFalsyValuesFromObject } from '^/utils';
import { makeAsyncActionSet } from './actions';

export const ITEMS_PER_PAGE = 12;
const WHOLE_COLLECTION_PAGE_SIZE = 10000;

function flattenFilters(filters) {
  var flatFilters = Object.assign({}, filters);

  for (var key in filters) {
    if (typeof filters[key] === 'object') {
      for (var subKey in filters[key]) {
        if (filters[key][subKey]) {
          flatFilters[key + '_' + subKey] = filters[key][subKey];
        }
      }

      delete flatFilters[key];
    }
  }

  return flatFilters;
}

export function getCollectionSortBy(key, collection) {
  const { ordering, reverseOrdering = false } = collection;
  const orderingStripped = ordering && ordering.replace('-', '');
  const shouldReverseOrdering = orderingStripped === key ? !reverseOrdering : reverseOrdering;
  return {
    reverseOrdering: shouldReverseOrdering,
    ordering: shouldReverseOrdering ? `-${key}` : key
  };
}

export const GET_COLLECTION = makeAsyncActionSet('GET_COLLECTION');
export function _getCollection(
  collectionName,
  url,
  opts = {}
) {
  let {
    additionalParams,
    shouldAppend,
    searchString,
    page,
    filters,
    pageSize,
    method,
    ordering,
    reverseOrdering
  } = opts;

  shouldAppend = !!shouldAppend;
  page = page || 1;
  pageSize = pageSize || ITEMS_PER_PAGE;
  searchString = searchString || '';
  filters = stripFalsyValuesFromObject(flattenFilters(filters));

  const action = makeRsaAction(
    GET_COLLECTION,
    url,
    method || 'GET',
    Object.assign({}, additionalParams, {
      search: searchString,
      page: page,
      page_size: pageSize,
      ordering,
    }, filters),
    Object.assign({}, additionalParams, {
      collectionName: collectionName,
      search: searchString,
      page: page,
      shouldAppend: shouldAppend,
      filters: filters,
      source: collectionName,
      ordering,
      reverseOrdering,
      pageSize
    })
  );
  return action;
}

export const ADD_TO_COLLECTION = makeAsyncActionSet('ADD_TO_COLLECTION');
export function _addToCollection(collectionName, url, data) {
  return makeRsaAction(
    ADD_TO_COLLECTION,
    url,
    'POST',
    data,
    { collectionName, source: collectionName }
  );
}

export const DELETE_FROM_COLLECTION = makeAsyncActionSet('DELETE_FROM_COLLECTION');
export function _deleteFromCollection(collectionName, url, id) {
  return makeRsaAction(
    DELETE_FROM_COLLECTION,
    url,
    'DELETE',
    null,
    { collectionName: collectionName, id, source: collectionName }
  );
}

export const UPDATE_LOCAL_COLLECTION = 'UPDATE_LOCAL_COLLECTION';
export function updateCollection(collectionName, item) {
  return {
    type: UPDATE_LOCAL_COLLECTION,
    meta: {collectionName},
    payload: item
  };
}

export function getCollection(type, opts, name = type) {
  return _getCollection(name, `/${type}/`, opts);
}

export function getCollectionFromUrl(url, opts, name) {
  return _getCollection(name, url, opts);
}

export function addItem(type, data, name = type) {
  return _addToCollection(name, `/${type}/`, data);
}

export function deleteItem(type, id, name = type) {
  return _deleteFromCollection(name, `/${type}/${id}/`, id);
}

export function getAllCollection(type, opts, name) {
  return getCollection(type, Object.assign({}, opts, {pageSize: WHOLE_COLLECTION_PAGE_SIZE}), name);
}

export const CLEAR_COLLECTION = 'CLEAR_COLLECTION';
export function clearCollection(collectionName) {
  return { type: CLEAR_COLLECTION, payload: collectionName };
}
