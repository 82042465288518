import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import PureComponent from '^/components/PureComponent';

export default class FormError extends PureComponent {
  _renderError(error) {
    const { renderError } = this.props;
    return renderError ? renderError(error) : error;
  }

  render() {
    if (!this.props.error && (!this.props.response ||
      !this.props.response.getIn(['errors', this.props.formKey]) ||
      !this.props.response.get('state')
    )) {
      return null;
    }

    return (
      <p className="margin-top-small error">
        {this._renderError(this.props.error || this.props.response.getIn(['errors', this.props.formKey]))}
      </p>
    );
  }
}

FormError.propTypes = {
  error: React.PropTypes.string,
  formKey: React.PropTypes.string,
  response: ImmutablePropTypes.map,
};
