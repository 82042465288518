import { AgendaSectionType } from '^/components/agendas/constants';

// Engagement
const TWO_HIGHEST_ENGAGEMENT = 'TWO_HIGHEST_ENGAGEMENT';
const THREE_LOWEST_ENGAGEMENT = 'THREE_LOWEST_ENGAGEMENT';
const INCOMPLETE_GOALS_ONE_YEAR = 'INCOMPLETE_GOALS_ONE_YEAR';
const COMPLETE_GOALS_SIX_MONTHS = 'COMPLETE_GOALS_SIX_MONTHS';

// Custom items
const FEELING_ABOUT_WORK_OVERALL = 'FEELING_ABOUT_WORK_OVERALL';
const YOUR_KEY_PRIORITIES = 'YOUR_KEY_PRIORITIES';
const OBSTACLES_TO_DISCUSS = 'OBSTACLES_TO_DISCUSS';
const SUPPORT_TO_KEEP_PRODUCTIVE = 'SUPPORT_TO_KEEP_PRODUCTIVE';
const ACHIEVE_BY_NEXT_MEETING = 'ACHIEVE_BY_NEXT_MEETING';
const FEEDBACK_TO_SHARE_DIRECTLY = 'FEEDBACK_TO_SHARE_DIRECTLY';
const ONE_IMPROVEMENT_BY_NEXT_MEETING = 'ONE_IMPROVEMENT_BY_NEXT_MEETING';

// Performance
const SLIDER_RESPONSES_FIVE_OR_BELOW = 'SLIDER_RESPONSES_FIVE_OR_BELOW';
const RADIO_RESPONSES_STRONGLY_DISAGREE = 'RADIO_RESPONSES_STRONGLY_DISAGREE';
const GROWTH_IN_THE_NEXT_QUARTER = 'GROWTH_IN_THE_NEXT_QUARTER';
const ENGAGEMENT_SCORES_FIVE_OR_BELOW = 'ENGAGEMENT_SCORES_FIVE_OR_BELOW';
const ALL_GOALS_SIX_MONTHS = 'ALL_GOALS_SIX_MONTHS';
const ALL_OKRS_SIX_MONTHS = 'ALL_OKRS_SIX_MONTHS';
const PEER_FEEDBACK_SIX_MONTHS = 'PEER_FEEDBACK_SIX_MONTHS';

// Custom items
const GONE_WELL_SINCE_LAST_121 = 'GONE_WELL_SINCE_LAST_121';
const CHALLENGES_FACING_NOW = 'CHALLENGES_FACING_NOW';
const SKILL_TO_LEARN_ON_THE_JOB = 'SKILL_TO_LEARN_ON_THE_JOB';
const GOALS_FOR_NEXT_SIX_MONTHS = 'GOALS_FOR_NEXT_SIX_MONTHS';
const SUPPORT_AS_MANAGER = 'SUPPORT_AS_MANAGER';

// Goals and OKRs
const REVIEW_QUESTION_ACHIEVED_GOALS = 'REVIEW_QUESTION_ACHIEVED_GOALS';
const INCOMPLETE_GOALS_18_MONTHS = 'INCOMPLETE_GOALS_18_MONTHS';
const INCOMPLETE_OKRS_18_MONTHS = 'INCOMPLETE_OKRS_18_MONTHS';

// Custom items
const CHALLENGES_BLOCKING_GOALS = 'CHALLENGES_BLOCKING_GOALS';
const ONE_THING_TO_HELP_GOALS = 'ONE_THING_TO_HELP_GOALS';
const SKILLS_TO_WORK_ON_NOW = 'SKILLS_TO_WORK_ON_NOW';
const TRAINING_TO_GROW_SKILLS = 'TRAINING_TO_GROW_SKILLS';
const MANAGER_SUPPORT_GOALS = 'MANAGER_SUPPORT_GOALS';

// Mental health
const RATE_OVERALL_HAPPINESS = 'RATE_OVERALL_HAPPINESS';
const ENGAGEMENT_RESOURCES_BELOW_SIX = 'ENGAGEMENT_RESOURCES_BELOW_SIX';
const WELLBEING_ENGAGEMENT_AREAS = 'WELLBEING_ENGAGEMENT_AREAS';

// Custom items
const ANYTHING_WORRYING_YOU = 'ANYTHING_WORRYING_YOU';
const WORK_LIFE_BALANCE = 'WORK_LIFE_BALANCE';
const FELT_DURING_PAST_MONTH = 'FELT_DURING_PAST_MONTH';

export const getTemplateItemsMapping = (performanceReviewTerm = 'Performance Review') => ({
  [AgendaSectionType.CUSTOM_ITEMS]: [
    // Engagement
    {
      id: FEELING_ABOUT_WORK_OVERALL,
      text: 'How are you feeling about work overall right now?'
    },
    {
      id: YOUR_KEY_PRIORITIES,
      text: 'What are your key priorities?'
    },
    {
      id: OBSTACLES_TO_DISCUSS,
      text: 'Are there any obstacles or challenges that you’re facing that you’d like to discuss?'
    },
    {
      id: SUPPORT_TO_KEEP_PRODUCTIVE,
      text: 'Are there any areas you need additional support or resource to keep you productive?'
    },
    {
      id: ACHIEVE_BY_NEXT_MEETING,
      text: 'By our next meeting what would you like to have achieved?'
    },
    {
      id: FEEDBACK_TO_SHARE_DIRECTLY,
      text: 'Is there any feedback you’d like to share with me directly during this meeting?'
    },
    {
      id: ONE_IMPROVEMENT_BY_NEXT_MEETING,
      text: 'What one improvement could we make between now and the next time we meet?'
    },
    // Performance review
    {
      id: GONE_WELL_SINCE_LAST_121,
      text: 'What has gone well since our last 121?'
    },
    {
      id: CHALLENGES_FACING_NOW,
      text: 'What challenges or obstacles are you facing right now?'
    },
    {
      id: SKILL_TO_LEARN_ON_THE_JOB,
      text: 'Is there a skill that would like to learn on the job?'
    },
    {
      id: GOALS_FOR_NEXT_SIX_MONTHS,
      text: 'What are your goals for the next 6 months?'
    },
    {
      id: SUPPORT_AS_MANAGER,
      text: 'How can I as your manager better support you?'
    },
    // Goals/OKR progress
    {
      id: CHALLENGES_BLOCKING_GOALS,
      text: 'What challenges or obstacles are you facing that are preventing you from achieving your goals?'
    },
    {
      id: ONE_THING_TO_HELP_GOALS,
      text: 'What\'s one thing we could do today to help you achieve your goals/OKRs?'
    },
    {
      id: SKILLS_TO_WORK_ON_NOW,
      text: 'What skills would you like to work on most right now?'
    },
    {
      id: TRAINING_TO_GROW_SKILLS,
      text: 'Are there any events or training you\'d like to attend to help you grow your skills?'
    },
    {
      id: MANAGER_SUPPORT_GOALS,
      text: 'How can I better support you as your manager to achieve your goals and OKRs?'
    },
    // Mental health/wellbeing
    {
      id: ANYTHING_WORRYING_YOU,
      text: 'How is everything going? Is there anything you’d like to talk about that is worrying you?'
    },
    {
      id: WORK_LIFE_BALANCE,
      text: 'How do you feel about your work/life balance? What would you change, if anything?'
    },
    {
      id: FELT_DURING_PAST_MONTH,
      text: 'What one word describes how you’ve felt during the past month?'
    }
  ],
  [AgendaSectionType.PERFORMANCE_REVIEW]: [
    {
      id: SLIDER_RESPONSES_FIVE_OR_BELOW,
      text: `${performanceReviewTerm} slider scores of 50% or less`
    },
    {
      id: RADIO_RESPONSES_STRONGLY_DISAGREE,
      text: `WeThrive's recommended ${performanceReviewTerm.toLowerCase()} radio button questions
        with answer 'strongly disagree'`
    },
    {
      id: GROWTH_IN_THE_NEXT_QUARTER,
      text: `WeThrive's recommended ${performanceReviewTerm.toLowerCase()} question
        'What areas of growth would you like to focus on in the next quarter?'`
    },
    {
      id: REVIEW_QUESTION_ACHIEVED_GOALS,
      text: `WeThrive's recommended ${performanceReviewTerm.toLowerCase()} question
        'Have you achieved your goals this past quarter/year?'`
    },
    {
      id: RATE_OVERALL_HAPPINESS,
      text: `WeThrive's recommended ${performanceReviewTerm.toLowerCase()} question
        'How would you rate your overall happiness at our company?'`
    },
  ],
  [AgendaSectionType.ENGAGEMENT_SURVEY]: [
    {
      id: TWO_HIGHEST_ENGAGEMENT,
      text: '2 highest scoring engagement areas'
    },
    {
      id: THREE_LOWEST_ENGAGEMENT,
      text: '3 lowest scoring engagement areas'
    },
    {
      id: ENGAGEMENT_SCORES_FIVE_OR_BELOW,
      text: `Engagement survey scores of 50% or less for the
       Personal Performance, Knowledge, Skills, Resources and Competence areas`
    },
    {
      id: ENGAGEMENT_RESOURCES_BELOW_SIX,
      text: 'Engagement survey score lower than 60% for the Resources area'
    },
    {
      id: WELLBEING_ENGAGEMENT_AREAS,
      text: 'Engagement scores for wellbeing areas: Security, Control, Headspace, Free from Worry & Meaning'
    },
  ],
  [AgendaSectionType.EMPLOYEE_GOALS]: [
    {
      id: INCOMPLETE_GOALS_ONE_YEAR,
      text: 'Incomplete goals created in the last year'
    },
    {
      id: COMPLETE_GOALS_SIX_MONTHS,
      text: 'Complete goals created in the last 6 months'
    },
    {
      id: ALL_GOALS_SIX_MONTHS,
      text: 'All goals created in the last 6 months'
    },
    {
      id: INCOMPLETE_GOALS_18_MONTHS,
      text: 'Incomplete goals created in the last 18 months'
    },
  ],
  [AgendaSectionType.OKRS]: [
    {
      id: ALL_OKRS_SIX_MONTHS,
      text: 'All OKRs created in the last 6 months'
    },
    {
      id: INCOMPLETE_OKRS_18_MONTHS,
      text: 'Incomplete OKRs created in the last 18 months'
    },
  ],
  [AgendaSectionType.PEER_FEEDBACK]: [
    {
      id: PEER_FEEDBACK_SIX_MONTHS,
      text: 'Peer feedback received in the last 6 months'
    }
  ]
});
