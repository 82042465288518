export const QUESTION_TYPES = {
  TEXT: 'text',
  SLIDER: 'slider',
  RADIO: 'radio_button',
  MULTIPLE: 'multiple_choice',
};

export const QUESTION_TYPE_DISPLAY = {
  [QUESTION_TYPES.TEXT]: 'Free text',
  [QUESTION_TYPES.SLIDER]: 'Slider',
  [QUESTION_TYPES.RADIO]: 'Radio buttons',
  [QUESTION_TYPES.MULTIPLE]: 'Multiple choice',
};

export const MINIMUM_OPTIONS = {
  [QUESTION_TYPES.RADIO]: 2,
  [QUESTION_TYPES.MULTIPLE]: 1,
};

export const CUSTOM_QUESTIONS_LIST = 'CUSTOM_QUESTIONS_LIST';
