import { request, makeAsyncActionSet } from '@dabapps/redux-requests';

export const GET_TEAM_MEMBER_OPTIONS = makeAsyncActionSet('GET_TEAM_MEMBER_OPTIONS');
export function getTeamMemberOptions(selectId, optionFilters, searchString) {
  return (dispatch) => {
    return request(
      GET_TEAM_MEMBER_OPTIONS,
      `/api/people/options/team-members/`,
      'GET',
      Object.assign({}, optionFilters, { 'search': searchString, }),
      {
        metaData: {
          selectId
        }
      }
    )(dispatch);
  };
}

export const GET_USER_OPTIONS = makeAsyncActionSet('GET_USER_OPTIONS');
export function getUserOptions(selectId, optionFilters, searchString) {
  return (dispatch) => {
    return request(
      GET_USER_OPTIONS,
      `/api/people/options/users/`,
      'GET',
      Object.assign({}, optionFilters, { 'search': searchString, }),
      {
        metaData: {
          selectId
        }
      }
    )(dispatch);
  };
}
