import * as Sentry from '@sentry/browser';
import { fromJS } from 'immutable';
import React from 'react';  //eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import EasyPieChart from 'easy-pie-chart';

import store from '^/store';
import UserList from '^/components/users/UserList';
import ManagerList from '^/components/managers/ManagerList';
import ManagedUsersList from '^/components/managers/ManagedUsersList';
import UserSearch from '^/components/UserSearch';
import ActionDetail from '^/components/actions/ActionDetail';
import ActionEditor from '^/components/actions/ActionEditor';
import ActionExport from '^/components/actions/ActionExport';
import ActionsList from '^/components/actions/ActionsList';
import CreateGoal from '^/components/goals/CreateGoal';
import GoalsList from '^/components/goals/GoalsList';
import OkrsList from '^/components/okrs/OkrsList';
import OkrDetail from '^/components/okrs/OkrDetail';
import OkrEdit from '^/components/okrs/OkrEdit';
import PeerFeedbackHub from '^/components/peer-feedback/PeerFeedbackHub';
import OrganisationCustomEmailSettings from '^/components/settings/emails/OrganisationCustomEmailSettings';
import SurveyCustomEmailSettings from '^/components/settings/emails/SurveyCustomEmailSettings';
import ProfileSettingsModalRenderer from '^/components/settings/ProfileSettingsModalRenderer';
import IntegrationSettings from '^/components/settings/integrations/IntegrationSettings';
import SurveyCustomQuestions from '^/components/survey/custom-questions/SurveyCustomQuestions';
import SupportPanel from '^/components/support/SupportPanel';
import PersonalReviewList from '^/components/reviews/PersonalReviewList';
import MySurveyResponsesList from '^/components/survey/MySurveyResponsesList';
import OrganisationSurveysList from '^/components/survey/OrganisationSurveysList';
import SurveyPeople from '^/components/survey/SurveyPeople';
import SurveySettings from '^/components/survey/SurveySettings';
import SurveyStatus from '^/components/survey/SurveyStatus';
import AnalysisCompare from '^/components/reports/AnalysisCompare';
import ManageCustomQuestions from '^/components/custom-questions/ManageCustomQuestions';
import ReportSuggestions from '^/components/reports/suggestions';
import EditGoal from '^/components/goals/EditGoal';
import GoalDetail from '^/components/goals/GoalDetail';
import ManageResources from '^/components/resources/ManageResources';
import PollUserExport from '^/components/users/PollUserExport';
import PollUserImport from '^/components/users/PollUserImport';
import SubscriptionOverview from '^/components/users/SubscriptionOverview';
import DeriveReport from '^/components/reports/DeriveReport';
import { renderAveragesBySubAreaBarChart } from '^/charts/subarea-average-scores-bar';
import { renderScoresByAreaChart } from '^/charts/subarea-scores-by-area';
import { renderHeatmapChart } from '^/charts/heatmap';
import DeriveHeatmapReport from './components/reports/DeriveHeatmapReport';
import CompareReport from '^/components/reports/CompareReport';
import ShareList from '^/components/reports/ShareList';
import BubbleUserActivity from './components/people/BubbleUserActivity';
import TeamReviewList from './components/reviews/TeamReviewList';
import IndividualReviewList from './components/reviews/IndividualReviewList';
import { setActionableAgendas } from './components/agendas/actions';
import AgendaListManager from './components/agendas/AgendaListManager';
import AgendaListEmployee from './components/agendas/AgendaListEmployee';
import TalentSnapshotList from './components/agendas/TalentSnapshotList';
import EditAgenda from './components/agendas/EditAgenda';
import EditAgendaTemplate from './components/agendas/EditAgendaTemplate';
import RequestOneToOne from './components/agendas/RequestOneToOne';
import ReadOnlyAgenda from './components/agendas/ReadOnlyAgenda';
import CustomReports from './components/custom-reports';
import OrganisationFilters from './components/filters/OrganisationFilters';
import { updateAdditionalFiltersList } from './components/filters/actions';
import MyDashboard from './components/dashboards/MyDashboard';
import ManagerDashboard from './components/dashboards/ManagerDashboard';
import AdminDashboard from './components/dashboards/AdminDashboard';
import BulkAgendaSetUpForm from './components/agendas/BulkAgendaSetUpForm';
import IndividualAgendaSetUpForm from './components/agendas/IndividualAgendaSetUpForm';
import BulkAgendaPreview from './components/agendas/BulkAgendaPreview';
import BulkAgendaParticipantsList from './components/agendas/BulkAgendaParticipantsList';
import CustomQuestionExportFilters from './components/settings/exports/CustomQuestionExportFilters';

export function setupSentry(
  dsn
) {
  Sentry.init({
    dsn,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export function peerFeedbackHub(filterOptions, organisationTeamTerm) {
  ReactDOM.render(
    <Provider store={store}>
      <PeerFeedbackHub filterOptions={filterOptions} organisationTeamTerm={organisationTeamTerm} />
    </Provider>,
    document.getElementById('peer-feedback-list')
  );
}

export function subscriptionOverview(
  employeeCount,
  maxEmployees,
  employeeBubbleCount,
  maxEmployeeBubbles,
  managerCount,
  maxManagers,
  managerWithAdminCount,
  maxManagersWithAdmin
) {
  ReactDOM.render(
    <Provider store={store}>
      <SubscriptionOverview
        employeeCount={employeeCount}
        maxEmployees={maxEmployees}
        employeeBubbleCount={employeeBubbleCount}
        maxEmployeeBubbles={maxEmployeeBubbles}
        managerCount={managerCount}
        maxManagers={maxManagers}
        managerWithAdminCount={managerWithAdminCount}
        maxManagersWithAdmin={maxManagersWithAdmin}
      />
    </Provider>,
    document.getElementById('subscription-overview')
  );
}

export function peoplePageTabs(
  filterOptions,
  editUserOptions,
  organisationFilterTerms
) {
  ReactDOM.render(
    <Provider store={store}>
      <UserList
        filterOptions={filterOptions}
        editUserOptions={editUserOptions}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById('people-page-tabs')
  );
}

export function manageManagers(elementId, filterOptions, organisationFilterTerms) {
  ReactDOM.render(
    <Provider store={store}>
      <ManagerList filterOptions={filterOptions} organisationFilterTerms={organisationFilterTerms} />
    </Provider>,
    document.getElementById(elementId)
  );
}

export function managedUsers(
  elementId,
  managerId,
  filterOptions,
  editUserOptions,
  managerLicensesUsed,
  managerLicensesRemaining,
  managerInfo,
  organisationFilterTerms
  ) {
  ReactDOM.render(
    <Provider store={store}>
      <ManagedUsersList
        managerId={managerId}
        filterOptions={filterOptions}
        editUserOptions={editUserOptions}
        managerLicensesUsed={managerLicensesUsed}
        managerLicensesRemaining={managerLicensesRemaining}
        managerInfo={managerInfo}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById(elementId)
  );
}

export function surveySettingsPage(
  surveyId,
  surveyName,
  filterOptions,
  canIncludeRecentResponses,
  canHaveCustomEmails,
  emailHelpLink,
  organisationFilterTerms
  ) {
  ReactDOM.render(
    <Provider store={store}>
      <SurveySettings
        surveyId={surveyId}
        surveyName={surveyName}
        filterOptions={filterOptions}
        canIncludeRecentResponses={canIncludeRecentResponses}
        canHaveCustomEmails={canHaveCustomEmails}
        emailHelpLink={emailHelpLink}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById('survey-settings-page')
  );
}

export function mySurveyResponsesList(licensedQuestionnaireTypes) {
  ReactDOM.render(
    <Provider store={store}>
      <MySurveyResponsesList licensedQuestionnaireTypes={licensedQuestionnaireTypes} />
    </Provider>,
    document.getElementById('my-survey-responses-list')
  );
}

export function personalReviewList(userId, performanceReviewTerm) {
  ReactDOM.render(
    <Provider store={store}>
      <PersonalReviewList userId={userId} performanceReviewTerm={performanceReviewTerm} />
    </Provider>,
    document.getElementById('personal-review-list')
  );
}

export function organisationSurveysList() {
  ReactDOM.render(
    <Provider store={store}>
      <OrganisationSurveysList />
    </Provider>,
    document.getElementById('organisation-surveys-list')
  );
}

export function teamReviewList(performanceReviewTerm) {
  ReactDOM.render(
    <Provider store={store}>
      <TeamReviewList performanceReviewTerm={performanceReviewTerm} />
    </Provider>,
    document.getElementById('team-review-list')
  );
}

export function individualReviewList(performanceReviewTerm, filterOptions, organisationTeamFilterTerm) {
  ReactDOM.render(
    <Provider store={store}>
      <IndividualReviewList
        performanceReviewTerm={performanceReviewTerm}
        filterOptions={filterOptions}
        organisationTeamFilterTerm={organisationTeamFilterTerm}
      />
    </Provider>,
    document.getElementById('individual-review-list')
  );
}

export function deriveReport(
  id,
  filterOptions,
  organisationFilterTerms = {},
  surveyId,
  selectedPrimaryFilters,
  legacyReportShareId = null,
  lockedFilters = null
) {
  ReactDOM.render(
    <Provider store={store}>
      <DeriveReport
        filterOptions={filterOptions}
        surveyId={surveyId}
        selectedPrimaryFilters={selectedPrimaryFilters}
        legacyReportShareId={legacyReportShareId}
        lockedFilters={lockedFilters}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById(id)
  );
}

export function deriveHeatmapReport(
  id,
  filterOptions,
  organisationFilterTerms,
  groupingOptions,
  surveyId,
  reportType,
  selectedPrimaryFilters,
  groupBy
) {
  ReactDOM.render(
    <Provider store={store}>
      <DeriveHeatmapReport
        filterOptions={filterOptions}
        groupingOptions={groupingOptions}
        surveyId={surveyId}
        reportType={reportType}
        selectedPrimaryFilters={selectedPrimaryFilters}
        groupBy={groupBy}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById(id)
  );
}

export function surveyEmailSettings(surveyId, templateType) {
  ReactDOM.render(
    <Provider store={store}>
      <SurveyCustomEmailSettings surveyId={surveyId} templateType={templateType} />
    </Provider>,
    document.getElementById('survey-email-settings')
  );
}

export function surveyStatusPage(
  surveyId,
  canEditSurvey,
  canExportResponses,
  organisationFilterTerms
) {
  ReactDOM.render(
    <Provider store={store}>
      <SurveyStatus
        surveyId={surveyId}
        canEditSurvey={canEditSurvey}
        canExportResponses={canExportResponses}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById('survey-status-page')
  );
}

export function userSelector (fieldName) {
  ReactDOM.render(
    <Provider store={store}>
      <UserSearch
        fieldName={fieldName} />
    </Provider>,
    document.getElementById('user-selector')
  );
}

export function surveyPeople(surveyId, filterOptions, canIncludeRecentResponses, organisationFilterTerms) {
  ReactDOM.render(
    <Provider store={store}>
      <SurveyPeople
        surveyId={surveyId}
        filterOptions={filterOptions}
        canIncludeRecentResponses={canIncludeRecentResponses}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById('survey-add-people')
  );
}

export function goalsList(isEmployeeBubbleUser) {
  ReactDOM.render(
    <Provider store={store}>
      <GoalsList isEmployeeBubbleUser={isEmployeeBubbleUser} />
    </Provider>,
    document.getElementById('goals-list-react')
  );
}

export function createGoal() {
  ReactDOM.render(
    <Provider store={store}>
      <CreateGoal />
    </Provider>,
    document.getElementById('create-goal-react')
  );
}

export function actionsList() {
  ReactDOM.render(
    <Provider store={store}>
      <ActionsList />
    </Provider>,
    document.getElementById('actions-list-react')
  );
}

export function actionDetail(actionId, canUserEdit, canUserDelete) {
  const urlParams = new URLSearchParams(window.location.search);  // eslint-disable-line
  const origin = urlParams.get('origin');

  ReactDOM.render(
    <Provider store={store}>
      <ActionDetail actionId={actionId} canUserEdit={canUserEdit} canUserDelete={canUserDelete} origin={origin} />
    </Provider>,
    document.getElementById('action-detail-react')
  );
}

export function actionEditor(actionId, isConfidential) {
  ReactDOM.render(
    <Provider store={store}>
      <ActionEditor actionId={actionId} isConfidential={isConfidential} />
    </Provider>,
    document.getElementById('action-editor-react')
  );
}

export function exportActions(showAll) {
  ReactDOM.render(
    <Provider store={store}>
      <ActionExport showAll={showAll} />
    </Provider>,
    document.getElementById('export-actions-react')
  );
}

export function supportPanel(queryParams) {
  const params = fromJS(queryParams);
  const initialTab = params.get('tab');
  const paramsWithoutTab = params.delete('tab');

  ReactDOM.render(
    <Provider store={store}>
      <SupportPanel queryParams={paramsWithoutTab} initialTab={initialTab} />
    </Provider>,
    document.getElementById('support-panel')
  );
}

export function analysisCompare(surveysBeforeCurrentList) {
  const surveysBeforeCurrent = fromJS(surveysBeforeCurrentList);

  ReactDOM.render(
    <Provider store={store}>
      <AnalysisCompare surveysBeforeCurrent={surveysBeforeCurrent} />
    </Provider>,
    document.getElementById('analysis-compare-react')
  );
}

export function organisationCustomEmailSettings() {
  ReactDOM.render(
    <Provider store={store}>
      <OrganisationCustomEmailSettings />
    </Provider>,
    document.getElementById('custom-emails')
  );
}

export function integrationSettings(
  slackInstallUrl,
  slackIntegrationActive,
  slackUninstallInstructionsUrl,
  hrIntegrationActive,
  hrConnectedName
) {

  ReactDOM.render(
    <Provider store={store}>
      <IntegrationSettings
        slackInstallUrl={slackInstallUrl}
        slackUninstallInstructionsUrl={slackUninstallInstructionsUrl}
        slackIntegrationActive={slackIntegrationActive}
        hrIntegrationActive={hrIntegrationActive}
        hrConnectedName={hrConnectedName}
      />
    </Provider>,
    document.getElementById('integration')
  );
}

export function profileSettingsModalRenderer() {
  ReactDOM.render(
    <Provider store={store}>
      <ProfileSettingsModalRenderer />
    </Provider>,
    document.getElementById('profile-settings-modal-renderer')
  );
}

export function profileSettingsToastRenderer() {
  ReactDOM.render(
    <ToastContainer />,
    document.getElementById('profile-settings-toast-renderer')
  );
}

export function surveyCustomQuestions(
  surveyId,
  canPreviewQuestionnaire,
  readOnly,
  isCustomQuestionsStepMandatory,
  nextText,
  nextUrl,
  hasSendTestEmail,
  questionsRecommendedFor,
  isIndividualReview
) {
  ReactDOM.render(
    <Provider store={store}>
      <SurveyCustomQuestions
        surveyId={surveyId}
        canPreviewQuestionnaire={canPreviewQuestionnaire}
        readOnly={readOnly}
        isCustomQuestionsStepMandatory={isCustomQuestionsStepMandatory}
        nextText={nextText}
        nextUrl={nextUrl}
        hasSendTestEmail={hasSendTestEmail}
        questionsRecommendedFor={questionsRecommendedFor}
        isIndividualReview={isIndividualReview}
      />
    </Provider>,
    document.getElementById('survey-custom-questions')
  );
}

export function manageCustomQuestions() {
  ReactDOM.render(
    <Provider store={store}>
      <ManageCustomQuestions />
    </Provider>,
    document.getElementById('manage-custom-questions')
  );
}

export function reportSuggestions(suggestions, isIndividual, reportId = null) {
  ReactDOM.render(
    <Provider store={store}>
      <ReportSuggestions suggestions={suggestions} isIndividual={isIndividual} reportId={reportId} />
    </Provider>,
    document.getElementById('report-suggestions-react')
  );
}

export function editGoal(goalId, isConfidential) {
  ReactDOM.render(
    <Provider store={store}>
      <div>
        <EditGoal
          goalId={goalId}
          isConfidential={isConfidential}
        />
        <ToastContainer />
      </div>
    </Provider>,
    document.getElementById('edit-goal-react')
  );
}

export function goalDetail(goalId, isConfidential) {
  ReactDOM.render(
    <Provider store={store}>
      <GoalDetail
        goalId={goalId}
        isConfidential={isConfidential}
      />
    </Provider>,
    document.getElementById('goal-detail-react')
  );
}

export function renderPieChart(id, percentage, inverted = false) {
  const PIE_BASE_COLOR = '#C4C4C4';
  const PIE_GREEN = '#4FDE8A';
  const PIE_RED = '#E40000';
  const PIE_ORANGE = '#FF910A';

  function getChartColor(value) {
    if (inverted) {
      if (value < 25) {
        return PIE_GREEN;
      }
      if (value <= 50) {
        return PIE_ORANGE;
      }
      return PIE_RED;
    }

    if (value < 50) {
      return PIE_RED;
    }
    if (value <= 75) {
      return PIE_ORANGE;
    }
    return PIE_GREEN;
  }

  const pieChart = document.getElementById(id);

  new EasyPieChart(pieChart, {
    scaleColor: false,
    lineWidth: 12,
    trackColor: PIE_BASE_COLOR,
    lineCap: 'square',
    barColor: getChartColor(percentage),
    size: 90
  });
}

function pollUserExport(exportId) {
  ReactDOM.render(
    <Provider store={store}>
      <PollUserExport exportId={exportId} />
    </Provider>,
    document.getElementById('export-users-poll-react')
  );
}

function pollUserImport(importId) {
  ReactDOM.render(
    <Provider store={store}>
      <PollUserImport importId={importId} />
    </Provider>,
    document.getElementById('import-users-poll-react')
  );
}

function manageResources(areaOptions) {
  ReactDOM.render(
    <Provider store={store}>
      <ManageResources areaOptions={areaOptions} />
    </Provider>,
    document.getElementById('manage-resources')
  );
}

function compareReport(
  elementId,
  surveyId,
  selectedPrimaryFilters,
  currentComparison,
  legacyReportShareId = null
) {
  ReactDOM.render(
    <Provider store={store}>
      <CompareReport
        surveyId={surveyId}
        selectedPrimaryFilters={selectedPrimaryFilters}
        currentComparison={currentComparison}
        legacyReportShareId={legacyReportShareId}
      />
    </Provider>,
    document.getElementById(elementId)
  );
}

export function shareList(elementId) {
  ReactDOM.render(
    <Provider store={store}>
      <ShareList />
    </Provider>,
    document.getElementById(elementId)
  );
}

function bubbleUserActivity(elementId) {
  ReactDOM.render(
    <Provider store={store}>
      <BubbleUserActivity />
    </Provider>,
    document.getElementById(elementId)
  );
}

function createAgenda() {
  ReactDOM.render(
    <Provider store={store}>
      <IndividualAgendaSetUpForm />
    </Provider>,
    document.getElementById('create-agenda')
  );
}

function editAgenda(
  agendaId,
  isEditable,
  goalOptions,
  engagementScoresOptions,
  latestEngagementSurveyName,
  latestEngagementSurveyStartDate,
  latestEngagementSurveyIsAnonymous,
  performanceReviewResponseOptions,
  latestPerformanceReviewName,
  latestPerformanceReviewComplete,
  talentSnapshotOptions,
  engagementQuestions,
  performanceReviewTerm,
  okrOptions,
  peerFeedbackOptions,
  showSelectableItems
) {
  ReactDOM.render(
    <Provider store={store}>
      <EditAgenda
        agendaId={agendaId}
        goalOptions={goalOptions}
        isEditable={isEditable}
        engagementScoresOptions={engagementScoresOptions}
        latestEngagementSurveyName={latestEngagementSurveyName}
        latestEngagementSurveyStartDate={latestEngagementSurveyStartDate}
        latestEngagementSurveyIsAnonymous={latestEngagementSurveyIsAnonymous}
        performanceReviewResponseOptions={performanceReviewResponseOptions}
        latestPerformanceReviewName={latestPerformanceReviewName}
        latestPerformanceReviewComplete={latestPerformanceReviewComplete}
        talentSnapshotOptions={talentSnapshotOptions}
        engagementQuestions={engagementQuestions}
        performanceReviewTerm={performanceReviewTerm}
        okrOptions={okrOptions}
        peerFeedbackOptions={peerFeedbackOptions}
        showSelectableItems={showSelectableItems}
      />
    </Provider>,
    document.getElementById('edit-agenda')
  );
}

function editAgendaTemplate(
  agendaId,
  performanceReviewTerm,
  canCreateOkrs,
  canCreatePeerFeedback
) {
  ReactDOM.render(
    <Provider store={store}>
      <EditAgendaTemplate
        agendaId={agendaId}
        performanceReviewTerm={performanceReviewTerm}
        canCreateOkrs={canCreateOkrs}
        canCreatePeerFeedback={canCreatePeerFeedback}
      />
    </Provider>,
    document.getElementById('edit-agenda-template')
  );
}

function readOnlyAgenda(
  agendaId,
  performanceReviewTerm
) {
  ReactDOM.render(
    <Provider store={store}>
      <ReadOnlyAgenda
        agendaId={agendaId}
        performanceReviewTerm={performanceReviewTerm}
      />
    </Provider>,
    document.getElementById('read-only-agenda')
  );
}

export const agendaListManager = (
  teamOptions,
  pendingAgendas,
  userHasManagers,
  userHasVisibleUsers,
  organisationTeamFilterTerm
) => {
  store.dispatch(setActionableAgendas(pendingAgendas));
  ReactDOM.render(
    <Provider store={store}>
      <AgendaListManager
        teamOptions={teamOptions}
        pendingAgendas={pendingAgendas}
        userHasManagers={userHasManagers}
        userHasVisibleUsers={userHasVisibleUsers}
        organisationTeamFilterTerm={organisationTeamFilterTerm}
      />
    </Provider>,
    document.getElementById('agenda-list-manager')
  );
};

export const agendaListEmployee = (pendingAgendas, userHasManagers, organisationTeamFilterTerm) => {
  store.dispatch(setActionableAgendas(pendingAgendas));
  ReactDOM.render(
    <Provider store={store}>
      <AgendaListEmployee userHasManagers={userHasManagers} organisationTeamFilterTerm={organisationTeamFilterTerm} />
    </Provider>,
    document.getElementById('agenda-list-employee')
  );
};

export const talentSnapshotList = (snapshotQuestionsAndOptions, filterOptions, organisationFilterTerms) => {
  ReactDOM.render(
    <Provider store={store}>
      <TalentSnapshotList
        snapshotQuestionsAndOptions={snapshotQuestionsAndOptions}
        filterOptions={filterOptions}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById('talent-snapshot-list')
  );
};

export function requestOneToOne(managerOptions) {
  ReactDOM.render(
    <Provider store={store}>
      <RequestOneToOne managerOptions={managerOptions}/>
    </Provider>,
    document.getElementById('request-one-to-one')
  );
}

export function organisationFilterSettings(organisationFilterTerms, organisationAdditionalFilters) {
  store.dispatch(updateAdditionalFiltersList(organisationAdditionalFilters));
  ReactDOM.render(
    <Provider store={store}>
      <OrganisationFilters
        organisationFilterTerms={organisationFilterTerms}
        organisationAdditionalFilters={organisationAdditionalFilters}
      />
    </Provider>,
    document.getElementById('organisation-filter-settings')
  );
}

export function okrsList(reportsToOptions) {
  ReactDOM.render(
    <Provider store={store}>
      <OkrsList reportsToOptions={reportsToOptions} />
    </Provider>,
    document.getElementById('okrs-list-react')
  );
}

export function okrDetail(
  okrId,
  canEdit
) {
  ReactDOM.render(
    <Provider store={store}>
      <OkrDetail
        okrId={okrId}
        canEdit={canEdit}
      />
    </Provider>,
    document.getElementById('okr-detail-react')
  );
}

export function okrEdit(
  okrId
) {
  ReactDOM.render(
    <Provider store={store}>
      <OkrEdit
        okrId={okrId}
      />
    </Provider>,
    document.getElementById('okr-edit-react')
  );
}

export function customReports(organisationFilterTerms) {
  ReactDOM.render(
    <Provider store={store}>
      <CustomReports organisationFilterTerms={organisationFilterTerms} />
    </Provider>,
    document.getElementById('custom-reports')
  );
}

export function myDashboard() {
  ReactDOM.render(
    <Provider store={store}>
      <MyDashboard />
    </Provider>,
    document.getElementById('my-dashboard')
  );
}

export function managerDashboard(organisationFilterTerms) {
  ReactDOM.render(
    <Provider store={store}>
      <ManagerDashboard organisationFilterTerms={organisationFilterTerms} />
    </Provider>,
    document.getElementById('manager-dashboard')
  );
}

export function adminDashboard(organisationFilterTerms) {
  ReactDOM.render(
    <Provider store={store}>
      <AdminDashboard organisationFilterTerms={organisationFilterTerms} />
    </Provider>,
    document.getElementById('admin-dashboard')
  );
}

export function createBulkAgenda() {
  ReactDOM.render(
    <Provider store={store}>
      <BulkAgendaSetUpForm />
    </Provider>,
    document.getElementById('create-bulk-agenda')
  );
}

export function bulkAgendaPreview(
  agendaId,
  agendaTitle,
  agendaDescription,
  selectedUsers,
  templateType,
  performanceReviewTerm,
  canCreateOkrs,
  canCreatePeerFeedback
) {
  ReactDOM.render(
    <Provider store={store}>
      <BulkAgendaPreview
        agendaId={agendaId}
        agendaTitle={agendaTitle}
        agendaDescription={agendaDescription}
        selectedUsers={selectedUsers}
        templateType={templateType}
        performanceReviewTerm={performanceReviewTerm}
        canCreateOkrs={canCreateOkrs}
        canCreatePeerFeedback={canCreatePeerFeedback}
      />
    </Provider>,
    document.getElementById('preview-bulk-agenda')
  );
}

export function bulkAgendaParticipantsList(
  agendaId, agendaTitle, agendaDueDate, filterOptions, organisationFilterTerms
) {
  ReactDOM.render(
    <Provider store={store}>
      <BulkAgendaParticipantsList
        agendaId={agendaId}
        agendaTitle={agendaTitle}
        agendaDueDate={agendaDueDate}
        filterOptions={filterOptions}
        organisationFilterTerms={organisationFilterTerms}
      />
    </Provider>,
    document.getElementById('bulk-agenda-participants')
  );
}

export function customQuestionExportFilters(
  filterOptions,
  groupingOptions,
  organisationFilterTerms,
  surveyId
) {
  ReactDOM.render(
    <Provider store={store}>
      <CustomQuestionExportFilters
        filterOptions={filterOptions}
        groupingOptions={groupingOptions}
        organisationFilterTerms={organisationFilterTerms}
        surveyId={surveyId}
      />
    </Provider>,
    document.getElementById('custom-question-export-filters')
  );
}

window.setupSentry = setupSentry;
window.subscriptionOverview = subscriptionOverview;
window.goalsList = goalsList;
window.createGoal = createGoal;
window.actionsList = actionsList;
window.peoplePageTabs = peoplePageTabs;
window.manageManagers = manageManagers;
window.managedUsers = managedUsers;
window.surveySettingsPage = surveySettingsPage;
window.mySurveyResponsesList = mySurveyResponsesList;
window.organisationSurveysList = organisationSurveysList;
window.teamReviewList = teamReviewList;
window.individualReviewList = individualReviewList;
window.surveyStatusPage = surveyStatusPage;
window.userSelector = userSelector;
window.surveyPeople = surveyPeople;
window.actionDetail = actionDetail;
window.actionEditor = actionEditor;
window.exportActions = exportActions;
window.supportPanel = supportPanel;
window.analysisCompare = analysisCompare;
window.integrationSettings = integrationSettings;
window.profileSettingsModalRenderer = profileSettingsModalRenderer;
window.profileSettingsToastRenderer = profileSettingsToastRenderer;
window.surveyCustomQuestions = surveyCustomQuestions;
window.organisationCustomEmailSettings = organisationCustomEmailSettings;
window.surveyEmailSettings = surveyEmailSettings;
window.manageCustomQuestions = manageCustomQuestions;
window.reportSuggestions = reportSuggestions;
window.editGoal = editGoal;
window.goalDetail = goalDetail;
window.renderPieChart = renderPieChart;
window.pollUserExport = pollUserExport;
window.pollUserImport = pollUserImport;
window.manageResources = manageResources;
window.renderAveragesBySubAreaBarChart = renderAveragesBySubAreaBarChart;
window.renderHeatmapChart = renderHeatmapChart;
window.deriveReport = deriveReport;
window.deriveHeatmapReport = deriveHeatmapReport;
window.renderScoresByAreaChart = renderScoresByAreaChart;
window.compareReport = compareReport;
window.shareList = shareList;
window.bubbleUserActivity = bubbleUserActivity;
window.personalReviewList = personalReviewList;
window.createAgenda = createAgenda;
window.editAgenda = editAgenda;
window.editAgendaTemplate = editAgendaTemplate;
window.agendaListManager = agendaListManager;
window.agendaListEmployee = agendaListEmployee;
window.talentSnapshotList = talentSnapshotList;
window.requestOneToOne = requestOneToOne;
window.readOnlyAgenda = readOnlyAgenda;
window.organisationFilterSettings = organisationFilterSettings;
window.okrsList = okrsList;
window.okrDetail = okrDetail;
window.okrEdit = okrEdit;
window.customReports = customReports;
window.myDashboard = myDashboard;
window.managerDashboard = managerDashboard;
window.adminDashboard = adminDashboard;
window.createBulkAgenda = createBulkAgenda;
window.peerFeedbackHub = peerFeedbackHub;
window.bulkAgendaPreview = bulkAgendaPreview;
window.bulkAgendaParticipantsList = bulkAgendaParticipantsList;
window.customQuestionExportFilters = customQuestionExportFilters;
