import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { toggleListItemSelected } from '^/actions/actions';
import { BULK_SEND_AGENDAS } from './actions';
import { AGENDA_LIST_BUTTON_DISABLED_TEXT } from './constants';

export function AgendaListItemCheckbox({isDisabled, toggleSelected, isSelected, item}) {
  return (
    <input
      type="checkbox"
      className="checkbox-color-text"
      disabled={isDisabled}
      onChange={toggleSelected}
      checked={isSelected}
      title={!item.can_send_pdf_summary ? AGENDA_LIST_BUTTON_DISABLED_TEXT : undefined}
    />
  );
}

function mapStateToProps(state, props) {
  return {
    isSelected: state.selectedListItems.includes(props.item.id),
    isDisabled: !props.item.can_send_pdf_summary || isPending(state.responses, BULK_SEND_AGENDAS)
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    toggleSelected: () => {
      if (props.item.can_send_pdf_summary) {
        dispatch(toggleListItemSelected(props.item.id));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaListItemCheckbox);
