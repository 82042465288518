import React from 'react'; // eslint-disable-line
import classNames from 'classnames';

export const PeerFeedbackCard = ({
  item,
  isDisabled,
  updateAgenda,
  openModal,
  isRemoving,
  showReadInFull,
  truncateMessage,
  isReadOnly,
}) => {
  return (
    <div className="agenda-items-card margin-bottom-base padding-horizontal-base">
      <div className="agenda-items-card-container margin-vertical-large margin-auto-horizontal">
        <div className="agenda-items-card-header margin-bottom-base display-flex align-items-center">
          {!isReadOnly && (
            <button
              className="btn-icon orange flex-shrink-0 margin-right-large"
              onClick={updateAgenda}
              disabled={isDisabled}
            >
              <i className={classNames(
                'far',
                isRemoving ? 'fa-minus' : 'fa-plus'
              )}></i>
            </button>
          )}
          <p className="bold margin-none flex-grow font-size-14">{item.feedback_title}</p>
          {!item.feedback_visible_to_user && (
            <div
              className="font-size-28 text-orange flex-shrink-0"
              title="This feedback is not visible to the employee, even when added to the agenda."
            >
              <i className="far fa-lock"></i>
            </div>
          )}
        </div>
        <p className="bold font-size-14 margin-bottom-large">
          {`Feedback from: ${item.feedback_created_by}`}
        </p>
        <p className={classNames(
          'font-size-14 margin-bottom-large white-space-pre-line',
          truncateMessage && 'multi-line-ellipsis'
        )}>
          {item.feedback_message}
        </p>
        <div className="flex-space-between">
          {showReadInFull ? <p
            className="underline bold margin-none font-size-14 cursor-pointer"
            onClick={openModal}
          >
            Read in full
          </p> : <p></p>}
          <p className="bold margin-none font-size-14">{item.feedback_message_modified}</p>
        </div>
      </div>
    </div>
  );
};

export default PeerFeedbackCard;
