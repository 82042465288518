import { isPending } from '@dabapps/redux-requests';
import { Row, Column } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import _ from 'underscore';

import FilterSelect from '^/components/common/FilterSelect';
import { stripFalsyValuesFromObject, getQueryParamsFromLocation } from '^/utils';
import { CREATE_REPORT, createReport } from './actions';

const hadSelectedAdditionalFilters = props =>
  !!Object.keys(props.selectedPrimaryFilters.additional_filters || {}).length;

export class DeriveReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showAdditionalFilters: Object.keys(getQueryParamsFromLocation()).includes('expand_filters')
    };
    this.getReport = this.getReport.bind(this);
    this.formatAdditionalFilters = this.formatAdditionalFilters.bind(this);
    this.toggleShowAdditionalFilters = this.toggleShowAdditionalFilters.bind(this);
  }

  getReport(newFilter) {
    toast.success('Updating report...');
    this.props.createReport(Object.assign(
      {},
      this.props.selectedPrimaryFilters,
      newFilter,
      {
        survey: this.props.surveyId
      }
    ));
  }

  formatAdditionalFilters(filterId, filterValue) {
    return {
      additional_filters: stripFalsyValuesFromObject(Object.assign(
        {},
        this.props.selectedPrimaryFilters.additional_filters,
        { [filterId]: filterValue }
      ))
    };
  }

  isFilterLocked(filterName) {
    return !!(this.props.lockedFilters && _.contains(this.props.lockedFilters, filterName));
  }

  toggleShowAdditionalFilters() {
    this.setState({showAdditionalFilters: !this.state.showAdditionalFilters});
  }

  render() {
    const { selectedPrimaryFilters, isLoading, organisationFilterTerms } = this.props;
    return (
      <div>
        <ToastContainer />
        <Row className="margin-top-large">
          <Column xs={2}>
            <FilterSelect
              filterKeyName="team_filter"
              displayName={organisationFilterTerms.team}
              options={this.props.filterOptions.teams}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.team_filter}
              disabled={isLoading || this.isFilterLocked('team')}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="location_filter"
              displayName={organisationFilterTerms.location}
              options={this.props.filterOptions.locations}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.location_filter}
              disabled={isLoading || this.isFilterLocked('location')}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="department_filter"
              displayName={organisationFilterTerms.department}
              options={this.props.filterOptions.departments}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.department_filter}
              disabled={isLoading || this.isFilterLocked('department')}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="unit_filter"
              displayName={organisationFilterTerms.unit}
              options={this.props.filterOptions.units}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.unit_filter}
              disabled={isLoading || this.isFilterLocked('unit')}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="manager_filter"
              displayName={organisationFilterTerms.manager}
              options={this.props.filterOptions.managers}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.manager_filter}
              disabled={isLoading || this.isFilterLocked('manager')}
              boldLabel
            />
          </Column>
          {!!this.props.filterOptions.individual && (
            <Column xs={2}>
              <FilterSelect
                filterKeyName="individual_filter"
                displayName="Individual"
                options={this.props.filterOptions.individual}
                setFilters={this.getReport}
                value={selectedPrimaryFilters.individual_filter}
                disabled={isLoading}
                boldLabel
              />
            </Column>
          )}
        </Row>
        <Row className={classNames({
          'margin-top-large': this.state.showAdditionalFilters,
          'collapsed-height': !this.state.showAdditionalFilters
        })}>
          {this.props.filterOptions.additional_filters &&
            this.props.filterOptions.additional_filters.map((additionalFilter, index) => (
            <Column xs={2} key={`additionalFilter-${additionalFilter.name}`} className={classNames({
              'margin-top-large': index > 5
            })}>
              <FilterSelect
                filterKeyName={additionalFilter.id}
                displayName={additionalFilter.name}
                options={additionalFilter.options}
                formatFilters={this.formatAdditionalFilters}
                setFilters={this.getReport}
                value={selectedPrimaryFilters.additional_filters[additionalFilter.id]}
                disabled={isLoading}
                boldLabel
              />
            </Column>
          ))}
        </Row>
        {this.props.filterOptions.additional_filters && !!this.props.filterOptions.additional_filters.length && (
          <button
            className="btn orange margin-top-large"
            onClick={this.toggleShowAdditionalFilters}
          >
            Show {this.state.showAdditionalFilters ? 'less' : 'more'} filters
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: isPending(state.responses, CREATE_REPORT, ''),
});

const mapDispatchToProps = (dispatch, props) => ({
  createReport: data =>
    dispatch(createReport(data, props.legacyReportShareId, hadSelectedAdditionalFilters(props)))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeriveReport);
