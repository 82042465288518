import React from 'react';  //eslint-disable-line no-unused-vars

const CellContentOrEmpty = ({children, condition, title}) =>
  condition ? (
    <span title={title} className="display-block text-overflow-ellipsis">{children}</span>
  ) : (
    <span className="display-block text-help">--</span>
  );

export default CellContentOrEmpty;
