import { isPending } from '@dabapps/redux-requests';
import { List } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { clearSelectedListItems, addSelectedListItems } from '^/actions/actions';
import { AGENDA_LIST_MANAGER } from './constants';
import { BULK_SEND_AGENDAS } from './actions';

export function AgendaListAllCheckbox({toggleSelected, isDisabled, isChecked}) {
  return (
    <input
      type="checkbox"
      className="checkbox-color-text margin-right-small"
      disabled={isDisabled}
      checked={isChecked}
      onChange={toggleSelected}
    />
  );
}

function mapStateToProps(state) {
  const items = state.collections.getIn([AGENDA_LIST_MANAGER, 'items'], List());
  const availableItemIds = items.reduce((accumulator, item) =>
    item.get('can_send_pdf_summary') ? [...accumulator, item.get('id')] : accumulator
  , []);
  const selectedItemsCount = availableItemIds.reduce((count, itemId) =>
    state.selectedListItems.includes(itemId) ? count + 1 : count
  , 0);
  return {
    isDisabled: (
      availableItemIds.length === 0 || isPending(state.responses, BULK_SEND_AGENDAS)
    ),
    isChecked: (
      availableItemIds.length &&
      availableItemIds.length === selectedItemsCount
    ),
    availableItemIds
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    toggleSelectedFunction: (availableItemIds) => (event) => {
      if (event.target.checked) {
        dispatch(addSelectedListItems(availableItemIds));
      } else {
        dispatch(clearSelectedListItems(availableItemIds));
      }
    }
  };
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    toggleSelected: dispatchProps.toggleSelectedFunction(
      stateProps.availableItemIds
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AgendaListAllCheckbox);
