import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import AgendaItemCard from './AgendaItemCard';
import { createAgendaOkrAndToast } from './actions';

export const AddOkrToAgendaCard = ({
  item,
  isDisabled,
  addOkr
}) => {
  return (
    <AgendaItemCard
      key={item.okr_id}
      title={
        <a href={`/okrs/${item.okr_id}/`} target="_blank">{item.okr_name}</a>
      }
      onClick={addOkr}
      isDisabled={isDisabled}
      isOkr
      okrData={item}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addOkr: () => (
      dispatch(createAgendaOkrAndToast(ownProps.agendaId, ownProps.item.okr_id))
    )
  };
};

export default connect(undefined, mapDispatchToProps)(AddOkrToAgendaCard);
