import { Column, Row } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { Field, reduxForm } from 'redux-form';

const FormField = ({name, label}) => (
  <Row className="margin-top-large margin-bottom-base">
    <Column md={2}>
      <label htmlFor={name} className="margin-none font-size-14"><strong>{label}</strong></label>
    </Column>
    <Column md={4}>
      <Field id={name} name={name} component="input" type="text" required />
    </Column>
  </Row>
);

export function OrganisationFiltersForm ({handleSubmit}) {
  return (
    <div>
      <p className="font-size-14">
        The filters determine how you segment your survey results.<br />
        Edit the names of the 5 key filters below, activate additional filters, and add your own custom filters.
      </p>
      <p className="font-size-14">
        Note: If you have set up a HRIS integration, the same data will continue to map into the current filters.<br />
        Updating the names of the filters will not change the data synced from your HRIS.
      </p>
      <form className="clearfix" onSubmit={handleSubmit}>
        <FormField name="team" label="Team"/>
        <FormField name="location" label="Location"/>
        <FormField name="department" label="Department"/>
        <FormField name="unit" label="Unit"/>
        <FormField name="manager" label="Manager"/>
        <Row className="margin-top-xx-large line-height-32px padding-right-small">
          <Column md={12}>
            <button
            className="btn middle blue margin-right-base"
            id="submit-button"
            >
              Update filter names
            </button>
          </Column>
        </Row>
      </form>
    </div>
  );
}

export default reduxForm({
  form: 'organisationFiltersForm',
})(OrganisationFiltersForm);
