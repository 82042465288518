import { anyPending } from '@dabapps/redux-requests';
import { Map } from 'immutable';
import moment from 'moment';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import {
  dismissEmployeeAgendaRequest,
  respondToAgendaRequestAndReload,
  DISMISS_EMPLOYEE_AGENDA_REQUEST,
  RESPOND_TO_AGENDA_REQUEST
} from '^/components/agendas/actions';
import { AgendaStatus, AgendaStatusDisplayMap } from '^/components/agendas/constants';
import { getOptions } from '^/components/common/ChadminList';
import { getQueryParamsFromLocation } from '^/utils';

export const PendingAgendaCard = ({
  agenda,
  isAgendaEmployee,
  dismissNotification,
  acceptAgendaRequest,
  declineAgendaRequest,
  isDisabled
}) => (
  <div
    className="widget-item-hollow"
    key={agenda.id}
  >
    <div className="flex-space-between font-family-varela-round font-size-14">
      <div className="overflow-hidden">
        <div className="font-family-effra-bold font-size-16 text-overflow-ellipsis">
          {agenda.title}
        </div>
        <div className="margin-top-small">
          Sent on: {moment(agenda.created).format('DD/MM/YYYY')}
        </div>
        {isAgendaEmployee ? (
          <div className="text-overflow-ellipsis font-family-varela-round font-size-14">
            Sent to: {agenda.manager_name || '-'}
          </div>
        ) : (
          <div className="text-overflow-ellipsis font-family-varela-round font-size-14">
            Sent by: {agenda.employee_name}
          </div>
        )}
      </div>
      {isAgendaEmployee ? (
        <div className="display-flex align-items-center">
          <div>
            {AgendaStatusDisplayMap[agenda.status]}
          </div>
          <button
            className="toggle-inclusion-icon pink margin-left-small"
            onClick={dismissNotification}
            disabled={isDisabled}
          >
            <i className="far fa-times"></i>
          </button>
        </div>
      ) : (
        <div>
          <button
            className="display-block pink-button font-size-13 padding-vertical-small"
            onClick={acceptAgendaRequest}
            disabled={isDisabled}
          >
            Accept
          </button>
          <button
            className="display-block pink-border-button font-size-13 padding-vertical-small margin-top-small"
            onClick={declineAgendaRequest}
            disabled={isDisabled}
          >
            Decline
          </button>
        </div>
      )}
    </div>
  </div>
);

function mapStateToProps(state, props) {
  return {
    isAgendaEmployee: props.agenda.employee_id === state.loggedInUser.get('id'),
    isDisabled: anyPending(state.responses, [RESPOND_TO_AGENDA_REQUEST, DISMISS_EMPLOYEE_AGENDA_REQUEST]),
    collection: state.collections.get(props.collectionName, Map())
  };
};

function mapDispatchToProps(dispatch, props) {
  const filtersFromLocation = getQueryParamsFromLocation();
  return {
    dismissNotification: () => dispatch(dismissEmployeeAgendaRequest(props.agenda.id)),
    getRespondToAgendaRequestFunction: (collection, responseStatus) => () =>
      dispatch(respondToAgendaRequestAndReload(
        props.agenda.id,
        responseStatus,
        getOptions(collection, filtersFromLocation)
      ))
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    acceptAgendaRequest: dispatchProps.getRespondToAgendaRequestFunction(
      stateProps.collection,
      AgendaStatus.ACCEPTED
    ),
    declineAgendaRequest: dispatchProps.getRespondToAgendaRequestFunction(
      stateProps.collection,
      AgendaStatus.DECLINED
    )
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PendingAgendaCard);
