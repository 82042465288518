import React from 'react';
import { connect } from 'react-redux';

import Loading from '^/components/Loading';
import { queueCustomReport, pollCustomReport } from '^/components/custom-reports/actions';
import { REPORT_TYPES } from '^/components/custom-reports/constants';
import { renderChart } from '^/components/custom-reports/utils';
import {
  getPollReportTimoutDuration,
  THREE_SECONDS,
  POLL_MAX_RETRIES
} from '^/utils';

export class TrendAnalysisLineChart extends React.PureComponent {

  constructor(props) {
    super(props);
    this.pollChartData = this.pollChartData.bind(this);
    this.state = {
      chartDataFailed: false
    };
  }

  componentDidMount() {
    if (this.props.report.type === REPORT_TYPES.TREND_ANALYSIS_LINE && this.props.report.cached_chart_data) {
      renderChart(this.props.report, null, true);
    }
    if (!this.props.report.cached_chart_data && !this.props.report.published_version_queued) {
      this.props.queueReport();
      this.pollChartData();
    }
  }

  componentDidUpdate(previousProps) {
    if (!previousProps.report.cached_chart_data && this.props.report.cached_chart_data) {
      clearTimeout(this.timeout);
      renderChart(this.props.report, null, true);
    }
  }

  pollChartData(tryCount = 0, timeoutSeconds = THREE_SECONDS) {
    const timeoutWithBackoff = getPollReportTimoutDuration(tryCount, timeoutSeconds);

    if (tryCount === POLL_MAX_RETRIES) {
      this.setState({chartDataFailed: true});
    } else {
      this.timeout = setTimeout(
        () => {
          this.props.pollReport();
          this.pollChartData(tryCount + 1, timeoutWithBackoff);
        },
        timeoutWithBackoff
      );
    }
  }

  render() {
    const { report } = this.props;

    if (report.type !== REPORT_TYPES.TREND_ANALYSIS_LINE) {
      return null;
    }

    return report.cached_chart_data ? (
      <div
        className="transition-half-second"
        id={`chart-${report.id}-dashboard`}
      >
      </div>
    ) : (
      <div className="min-width-640 padding-top-xx-large">
        {this.state.chartDataFailed ? (
          <p>
            The report is taking longer than usual.{' '}
            Please try again shortly and if this problem persists contact support.
          </p>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch, props) => ({
  queueReport: () => dispatch(queueCustomReport(props.report.id)),
  pollReport: () => dispatch(pollCustomReport(props.report.id)),
});

export default connect(undefined, mapDispatchToProps)(TrendAnalysisLineChart);

