import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { connect } from 'react-redux';
import { Button, ModalBody, ModalFooter, SpacedGroup } from '@dabapps/roe';
import { removeFromSurveyCloseModalAndReload } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';


export class DeleteTeamMemberModal extends PureComponent {
  constructor() {
    super();
    this.deleteMember = this.deleteMember.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  deleteMember() {
    const { surveyId, surveyTeamMember, reloadPage } = this.props;
    this.props.removeFromSurveyCloseModalAndReload(surveyId, [surveyTeamMember.team_member_id], reloadPage);
  }

  cancel() {
    this.props.closeModal();
  }

  render() {
    return (
      <div>
        <ModalBody>
          <p>
            Are you sure you’d like to delete {this.props.surveyTeamMember.display_name}?
            Any scores recorded or comments entered will be permanently deleted.
          </p>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button className="btn gray middle" onClick={this.cancel}>
              Cancel
            </Button>
            <Button className="btn danger middle" onClick={this.deleteMember}>
              Delete
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </div>
    );
  }
}

export default connect(null, {
  removeFromSurveyCloseModalAndReload,
  closeModal
})(DeleteTeamMemberModal);
