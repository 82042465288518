import { faChevronDoubleDown, faChevronDoubleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';  // eslint-disable-line

export const CustomReportCollapseButton = ({isExpanded}) => (
  <button
    aria-label={isExpanded ? 'Close' : 'Open'}
    className="collapse-button padding-none background-none font-size-24 margin-left-auto"
  >
    {
      isExpanded ?
      <FontAwesomeIcon icon={faChevronDoubleUp} /> :
      <FontAwesomeIcon icon={faChevronDoubleDown} />
    }
  </button>
);

export default CustomReportCollapseButton;
