import React from 'react'; // eslint-disable-line no-unused-vars
import AgendaItemCard from './AgendaItemCard';
import { connect } from 'react-redux';
import { deleteAgendaReviewQuestionAndToast } from './actions';
import CustomItemsSection from './CustomItemsSection';
import { isReadOnlyAgendaItem } from './utils';

const DeleteQuestionFromAgendaCard = ({
  item,
  deleteReviewQuestion,
  isDisabled
}) => {
  return (
    <AgendaItemCard
      key={item.question_id}
      title={item.question_text}
      content={item.responses.length === 0 ? null : item.responses}
      onClick={deleteReviewQuestion}
      isDisabled={isDisabled}
      isRemoving
    />
  );
};

function mapStateToPropsCard(state, props) {
  return {
    isDisabled: props.isDisabled || isReadOnlyAgendaItem(state, props.item.created_by)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteReviewQuestion: () => dispatch(
      deleteAgendaReviewQuestionAndToast(ownProps.agendaId, ownProps.item.id)
    ),
  };
};

const ConnectedDeleteQuestionFromAgendaCard = connect(
  mapStateToPropsCard,
  mapDispatchToProps
)(DeleteQuestionFromAgendaCard);

export const AgendaPerformanceReviewSection = ({
  agendaId,
  isDisabled,
  selectedQuestions
}) => {
  return (
    <div>
      {selectedQuestions.length ? selectedQuestions.map(selectedQuestion => (
        <ConnectedDeleteQuestionFromAgendaCard
          key={selectedQuestion.question_id}
          item={selectedQuestion}
          agendaId={agendaId}
          isDisabled={isDisabled}
        />
      )) : (
        <p>No options selected.</p>
      )}
      <div className="margin-top-xx-large">
        <h4 className="font-weight-bold margin-bottom-base font-size-14">
          Comments
        </h4>
        <CustomItemsSection
          agendaId={agendaId}
          isDisabled={isDisabled}
          itemsName="performance_review_comments"
          isCommentItems
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedQuestions: state.editingAgenda.review_questions
  };
}

export default connect(mapStateToProps)(AgendaPerformanceReviewSection);
