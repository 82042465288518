import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars

import { CELL_WIDTH_CLASS_NAMES } from '^/components/peer-feedback/constants';
import PeerFeedbackListAllCheckbox from '^/components/peer-feedback/PeerFeedbackListAllCheckbox';

export function PeerFeedbackListHeader({headings, listName, collectionName}) {
  return (
    <div className="background-gray-lightest padding-vertical-x-large padding-horizontal-large">
      <div className="display-flex">
        <div className="padding-horizontal-small width-15-percent">
          <PeerFeedbackListAllCheckbox collectionName={collectionName} />
          <span className="margin-left-base">Remind</span>
        </div>
        {headings.map((heading, index) => (
          <div
            key={`feedback-list-heading-${heading}`}
            className={classNames(
              'padding-horizontal-small',
              CELL_WIDTH_CLASS_NAMES[listName][index]
            )}
          >
            {heading}
          </div>
        ))}
        <div className="padding-horizontal-small width-15-percent"></div>
      </div>
    </div>
  );
}

export default PeerFeedbackListHeader;
