import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { List } from 'immutable';
import classNames from 'classnames';

import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { bubbleRoleOptions } from '^/roles';
import { clearAllSelectedListItems, toggleListItemSelected } from '^/actions/actions';
import { bubbleUsersActivityListConfig } from './config';
import { REMINDER_EMAIL_TEMPLATE_URLS, REMINDER_TYPES } from './constants';
import { openSimpleModal } from '../../actions/modals';
import ConfirmReminderModal from './ConfirmReminderModal';
import { ModalRenderer } from '@dabapps/roe';
import { arrayContainsNoValues } from '../../utils';
import { sendBubbleRemindersAndToast } from '../../actions/actionSequences';

export const BUBBLE_USER_ACTIVITY_LIST = 'BUBBLE_USER_ACTIVITY_LIST';

function filterSelectedUsersWithBubbleByReminderType(
  selectedUsers = [],
  bubbleUsers = [],
  reminderType
) {

  const {
    ACTIVATION,
    LOGIN,
    ACTION
  } = REMINDER_TYPES;

  switch (reminderType) {
    case ACTIVATION:
      return selectedUsers.filter(user_id => bubbleUsers.find(user => {
        return user.get('has_set_password_token') &&
               !user.get('last_login') &&
               user.get('id') === user_id;
      }));
    case LOGIN:
      return selectedUsers.filter(user_id =>
        bubbleUsers.find(user =>
          user.get('last_login') &&
          user.get('id') === user_id
        )
      );
    case ACTION:
      return selectedUsers.filter(user_id =>
        bubbleUsers.find(user =>
          user.get('last_login') &&
          user.get('id') === user_id &&
          user.get('created_actions_count') === 0
        )
      );
    default:
      throw new Error('ReminderType not specified.');
  }
}

function ExtraControlsLeft(props) {
  const {
    selectedUsers,
    bubbleUsers,
    selectAll,
    openSendReminderModal,
    deselectAll,
  } = props;

  const noneSelected = selectedUsers.length === 0;
  const hasSelectedUsers = !noneSelected;

  const allUsersWhoHaventActivated = filterSelectedUsersWithBubbleByReminderType(
    selectedUsers,
    bubbleUsers,
    REMINDER_TYPES.ACTIVATION
  );

  const allSelectedUsersWithLoginReminders = filterSelectedUsersWithBubbleByReminderType(
    selectedUsers,
    bubbleUsers,
    REMINDER_TYPES.LOGIN
  );

  const allSelectedUsersWhoHaventCreatedActions = filterSelectedUsersWithBubbleByReminderType(
    selectedUsers,
    bubbleUsers,
    REMINDER_TYPES.ACTION
  );

  const {
    ACTIVATION_EMAIL_TEMPLATE_URL,
    LOGIN_EMAIL_TEMPLATE_URL,
    ACTION_EMAIL_TEMPLATE_URL
  } = REMINDER_EMAIL_TEMPLATE_URLS;

  return (
    <div className="pull-left">
      {noneSelected ? (
        <button className="btn orange pull-left" onClick={selectAll}>Select all</button>
      ) : (
        <button className="btn orange pull-left" onClick={deselectAll}>Deselect all</button>
      )}
      <div>
        <div className="control-group">
          <button
            className={classNames('btn', 'orange', !noneSelected && 'tall')}
            onClick={() => openSendReminderModal(REMINDER_TYPES.ACTIVATION, allUsersWhoHaventActivated)}
            disabled={hasSelectedUsers && arrayContainsNoValues(allUsersWhoHaventActivated)}
          >
            {noneSelected ?
              'Re-send activation emails' :
              'Re-send activation email to selected users'
            }
          </button>
          <a
            href={ACTIVATION_EMAIL_TEMPLATE_URL}
            target="_blank"
          >
            View email template
          </a>
        </div>
        <div className="control-group">
          <button
            className={classNames('btn', 'orange', !noneSelected && 'tall')}
            onClick={() => openSendReminderModal(REMINDER_TYPES.LOGIN, allSelectedUsersWithLoginReminders)}
            disabled={hasSelectedUsers && arrayContainsNoValues(allSelectedUsersWithLoginReminders)}
          >
            {noneSelected ?
              'Send login reminders' :
              'Send login reminder to all selected users'
            }
          </button>
          <a
            href={LOGIN_EMAIL_TEMPLATE_URL}
            target="_blank"
          >
            View email template
          </a>
        </div>
        <div className="control-group">
          <button
            className={classNames('btn', 'orange', !noneSelected && 'tall')}
            onClick={() => openSendReminderModal(REMINDER_TYPES.ACTION, allSelectedUsersWhoHaventCreatedActions)}
            disabled={hasSelectedUsers && arrayContainsNoValues(allSelectedUsersWhoHaventCreatedActions)}
          >
            {noneSelected ?
              'Send action reminders' :
              'Send action reminder to all selected users'
            }
          </button>
          <a
            href={ACTION_EMAIL_TEMPLATE_URL}
            target="_blank"
          >
            View email template
          </a>
        </div>
      </div>
    </div>
  );
}


export class BubbleUserActivity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.selectAll = this.selectAll.bind(this);
  }

  componentDidMount() {
    this.props.clearUserSelection();
  }

  selectAll() {
    this.props.currentPageBubbleUsers.toJS().forEach(user => this.props.toggleUserSelection(user.id));
  }

  render() {
    return (
      <div className="will">
        <ChadminList
          className="bubble-users-activity"
          collectionPath="people/bubble-user-activity"
          collectionName={BUBBLE_USER_ACTIVITY_LIST}
          defaultFilters={{ordering: 'first_name'}}
          getColumns={() => bubbleUsersActivityListConfig(
            this.props.selectedUsers,
            this.props.toggleUserSelection,
            this.props.sendBubbleRemindersAndToast
          )}
          ExtraControlsLeft={() => (
            <ExtraControlsLeft
              selectedUsers={this.props.selectedUsers}
              bubbleUsers={this.props.currentPageBubbleUsers}
              selectAll={this.selectAll}
              openSendReminderModal={this.props.openSendReminderModal}
              deselectAll={this.props.clearUserSelection}
            />
          )}
          ExtraControlsRight={() => (
            <UriFilterSelect
              filterName="type"
              name="role"
              options={bubbleRoleOptions}
            />
          )}
        />
        <ModalRenderer modals={this.props.modals}/>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
      </div>
    );
  }
}

export function mapStateToProps({ selectedListItems, collections, modals }) {
  return {
    selectedUsers: selectedListItems,
    modals,
    currentPageBubbleUsers: collections.getIn([BUBBLE_USER_ACTIVITY_LIST, 'items'], List()),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearUserSelection: () => dispatch(clearAllSelectedListItems()),
    toggleUserSelection: userId => dispatch(toggleListItemSelected(userId)),
    sendBubbleRemindersAndToast: (selectedUsers, reminderType) => dispatch(
      sendBubbleRemindersAndToast(selectedUsers, reminderType)
    ),
    openSendReminderModal: (reminderType, selectedUsers) =>
      dispatch(
        openSimpleModal({
          body: <ConfirmReminderModal selectedUsers={selectedUsers} reminderType={reminderType} />,
          title: `Send ${reminderType} reminder`,
        })
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BubbleUserActivity);
