import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { ACTION_STATUS, ACTION_STATUS_MAPPING } from '^/consts/actions';
import { ACTION_URGENCY, ACTION_URGENCY_PERCENTAGE } from '^/components/goals/const';
import { FORMAT_DATE, formatDateTime, differenceBetweenDays } from '^/utils/index';

class GoalCard extends React.PureComponent {

  getCompletionStatus(percentage_complete = 0) {

    if (percentage_complete === 100) {
      return ACTION_STATUS.COMPLETED;
    }

    if (percentage_complete > 0) {
      return ACTION_STATUS.IN_PROGRESS;
    }

    return ACTION_STATUS.NOT_STARTED;
  }

  renderGoalCompletionStatus(status, className = '') {
    return <span
      className={
        classNames(
          'action-chip-home',
          className
        )
      }
    >
      {status}
    </span>;
  }

  getUrgencyStatus({
    due_date,
    created_date,
    percentage_complete
  }) {
    const isComplete = percentage_complete === 100;

    if (!isComplete && due_date) {
      const daysLeftTillGoalRequiresCompletion = differenceBetweenDays(due_date);

      if (daysLeftTillGoalRequiresCompletion < 0) {
        return this.renderGoalCompletionStatus(ACTION_URGENCY.OVERDUE, 'overdue');
      }

      const daysGoalCreatedToGoalDue = moment(due_date).diff(moment(created_date), 'days');

      const percentageOfGoalDuration = (daysGoalCreatedToGoalDue / 100) * ACTION_URGENCY_PERCENTAGE;
      const daysRemainingForDueSoonStatus = daysGoalCreatedToGoalDue - percentageOfGoalDuration;

      if (daysLeftTillGoalRequiresCompletion <= daysRemainingForDueSoonStatus) {
        return this.renderGoalCompletionStatus(ACTION_URGENCY.DUE_SOON, 'due-soon');
      }

      return this.renderGoalCompletionStatus();
    }
  }

  render() {
    const { goal } = this.props;
    const {
      id,
      name,
      percentage_complete,
      due_date,
    } = goal;

    const hasCompleted = percentage_complete === 100;

    return (
      <div className="widget-item-hollow margin-bottom-large">
        <div className="align-items-center flex-space-between flex-wrap">
          <div
            title={name}
            className="widget-item-description padding-base text-overflow-ellipsis font-weight-bold font-size-14"
          >
            {name}
          </div>
          <div className="text-align-center width-18-point-75">
            {this.getUrgencyStatus(goal)}
          </div>
          <div className="font-size-base width-18-point-75">
            <strong className={
                classNames(
                  'action-status-chip',
                  {
                    'border-none': hasCompleted,
                    'background-green': hasCompleted,
                    'font-color-white': hasCompleted,
                  }
                )
              }>
              {
                ACTION_STATUS_MAPPING.get(
                  this.getCompletionStatus(percentage_complete)
                )
              }
            </strong>
          </div>
          <div className="text-align-center width-18-point-75">
            <div className="font-size-base">
              {due_date && (
                <span><strong>Due: {formatDateTime(due_date, '', FORMAT_DATE)}</strong></span>
              )}
            </div>
          </div>
          <div className="widget-item-actions text-align-right width-18-point-75">
            <a
              className={
                classNames(
                  'text-pink',
                  'text-decoration-none',
                  'display-inline-block',
                  'font-weight-bold'
                )
              }
              href={`/goals/${id}`}
            >
              View goal
              <i className="far fa-arrow-right arrow text-pink padding-left-small" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default GoalCard;
