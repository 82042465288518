import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import SettingsUserList from './SettingsUserList';
import {
  fetchCustomTerms, getSurvey
} from '^/actions/actions';


export class SurveyPeople extends React.PureComponent {

  componentWillMount() {
    this.props.fetchCustomTerms();
    this.props.getSurvey(this.props.surveyId);
  }

  render() {
    return <SettingsUserList
      surveyId={this.props.surveyId}
      customTerms={this.props.customTerms}
      filterOptions={this.props.filterOptions}
      canIncludeRecentResponses={this.props.canIncludeRecentResponses}
      showHelpText={true}
      organisationFilterTerms={this.props.organisationFilterTerms}
    />;
  }

}

function mapStateToProps(state) {
  return {
    customTerms: state.organisation.get('customTerms', Map()),
    survey: state.survey.get('survey', Map()),
  };
}

export default connect(mapStateToProps, {
  fetchCustomTerms, getSurvey
})(SurveyPeople);
