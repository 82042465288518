import React from 'react'; // eslint-disable-line no-unused-vars

import { fromJS, List } from 'immutable';
import moment from 'moment';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Column, ModalRenderer } from '@dabapps/roe';
import _ from 'underscore';

import { updateSurvey, setLoading, setNotLoading } from '^/actions/actions';
import { openSimpleModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import AsyncCheckbox from '^/components/common/AsyncCheckbox';
import AsyncDatePicker from '^/components/common/AsyncDatePicker';
import AsyncTimePicker from '^/components/common/AsyncTimePicker';
import { isPerformanceReviewTypeDisplay } from '^/consts/questionnaire';
import SendTestEmailModal from './modals/SendTestEmailModal';

const ROE_TOTAL_COLUMNS = 12;

class SurveyAttributes extends PureComponent {
  constructor(props) {
    super(props);
    this.updateSurveyName = _.debounce(name => {
      if (name.length > 0) {
        this.props.updateSurvey(this.props.survey.set('name', name));
        document.getElementById('survey-header').textContent = name;
      }
    }, 300);
  }

  handleClick(attrName, newState) {
    const updatedSurvey = this.props.survey.set(attrName, newState);
    this.props.updateSurvey(updatedSurvey);
  }

  openSendTestEmailModal (event, template_type) {
    event.preventDefault();
    const emailModal = {
      body: (
        <SendTestEmailModal
          survey={this.props.survey}
          email_type={template_type}
        />
      ),
      title: 'Send test email'
    };

    this.props.openSimpleModal(emailModal);
  }

  render() {
    const { survey, canHaveCustomEmails, emailHelpLink } = this.props;
    const surveyType = isPerformanceReviewTypeDisplay(survey.get('type')) ? 'Review' : 'Survey';
    const emailFields = fromJS({
      'send_questionnaire': {
        label: `Send ${surveyType.toLowerCase()} email`,
        email_type: 'send_questionnaire',
        disabled: true,
        checked: true,
      },
      'warmup_enabled': {
        label: 'Send warmup email',
        email_type: 'survey_warmup'
      },
      'auto_nudge_enabled': {
        label: 'Send auto reminders',
        email_type: 'nudge_questionnaire'
      },
      'thank_you_email': {
        label: 'Send thank you email',
        email_type: 'thank_you_email'
      }
    });

    const additionalCheckboxFields = fromJS({
      'great_working_here': {
        label: 'What\'s great about work?'
      },
      'change_for_better': {
        label: 'What one thing could change for the better?'
      },
    });

    const editableStates = List([
      'EDIT', 'WAIT_AUTO_START'
    ]);

    const readOnly = !editableStates.find(i => i === survey.get('state')) || this.props.readOnly;

    const now = moment();
    const surveyStartTime = survey.get('start_time');
    const surveyStart = (
      surveyStartTime
        ? moment(surveyStartTime)
        : now.add((30 - now.minute() % 30), 'minutes')
    ).format('HH:mm');

    const showOptionalQuestions = survey.get('can_have_optional_questions');
    const showCustomQuestions = survey.get('can_have_custom_questions');
    const numberOfAttributeSections = showOptionalQuestions ? 4 : 3;
    const atributeColumnWidth = ROE_TOTAL_COLUMNS / numberOfAttributeSections;

    return <div className="survey-attributes margin-top-small margin-bottom-large">
      <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
      <ModalRenderer modals={this.props.modals}/>
      <Row>
        <Column xs={atributeColumnWidth}>
          <div className="survey-attribute">
            <label>{surveyType} Name</label>
            {survey.get('state') && survey.get('state') === 'CLOSED'
              ? survey.get('name')
              : <input
                className="survey-name"
                type="text"
                defaultValue={this.props.surveyName}
                onChange={e => this.updateSurveyName(e.target.value)}
              />
            }
          </div>
          <div className="survey-attribute">
            <AsyncDatePicker
              id="auto_start_date"
              label="Start date"
              readOnly={moment(surveyStart, 'HH:mm').isBefore(now) || readOnly}
              handleClick={(id, newState) => this.handleClick(id, newState)}
              value={survey.get('auto_start_date')} />
          </div>
          <div className="survey-attribute">
            <AsyncDatePicker
              id="auto_close_date"
              label="End date"
              readOnly={survey.get('state') === 'CLOSED'}
              handleClick={(id, newState) => this.handleClick(id, newState)}
              value={
                survey.get('state') === 'CLOSED' ?
                  survey.get('date_closed') :
                  survey.get('auto_close_date')
              }
            />
          </div>
          <div className="survey-attribute">
            <AsyncTimePicker
              id="start_time"
              label="Start time"
              readOnly={moment(surveyStart, 'HH:mm').isBefore(now) || readOnly}
              value={surveyStartTime}
              handleClick={(id, newState) => this.handleClick(id, newState)} />
          </div>
          <div className="survey-attribute">
            <AsyncCheckbox
              label={`${surveyType} sent`}
              checked={survey.get('state') !== 'EDIT'}
              id="survey-sent"
              readOnly />
          </div>
          <div className="survey-attribute">
            <AsyncCheckbox
              label="Keep responses anonymous"
              checked={survey.get('keep_responses_anonymous')}
              disabled={survey.get('is_anonymity_locked')}
              id="keep_responses_anonymous"
              handleClick={(_1, newState) => this.handleClick('keep_responses_anonymous', newState)}
              readOnly={readOnly} />
          </div>
        </Column>
        <Column xs={atributeColumnWidth}>
          <h4 className="survey-attribute-header">Email Notifications</h4>
          {emailFields.map((config, attrName) =>
            <div key={attrName}>
              <div className="survey-attribute display-inline-block">
                <AsyncCheckbox
                  className="margin-bottom-none"
                  label={config.get('label')}
                  checked={survey.get(attrName) || config.get('checked', false)}
                  id={attrName}
                  readOnly={readOnly}
                  disabled={config.get('disabled')}
                  handleClick={(_1, newState) => this.handleClick(attrName, newState)} />
              </div>
              {!readOnly && canHaveCustomEmails && (
                <div className="survey-async-addon margin-top-small display-inline-block">
                  <a
                    href={
                      `/${surveyType.toLowerCase()}s/${survey.get('id')}/settings/email/${config.get('email_type')}/`
                    }
                  >
                    Customise
                  </a>
                  <a
                    className="margin-left-base"
                    onClick={(event) => this.openSendTestEmailModal(event, config.get('email_type'))}
                  >
                    Send test email
                  </a>
                </div>
              )}
            </div>
          ).toList()}
          {emailHelpLink && (
            <a className="display-block margin-top-base" href={emailHelpLink} target="_blank">
              View the email templates here
            </a>
          )}
        </Column>
        {showOptionalQuestions && (
          <Column xs={atributeColumnWidth}>
            <h4 className="survey-attribute-header">Optional Questions</h4>
            {additionalCheckboxFields.map((config, attrName) =>
              <div key={attrName}>
                <div className="survey-attribute">
                  <AsyncCheckbox
                    label={config.get('label')}
                    checked={survey.get(attrName)}
                    id={attrName}
                    readOnly={readOnly || config.get('readOnly')}
                    handleClick={(_1, newState) => this.handleClick(attrName, newState)} />
                </div>
              </div>
            ).toList()}
          </Column>
        )}
        {showCustomQuestions && (
          <Column xs={atributeColumnWidth}>
            <h4 className="survey-attribute-header">Custom Questions</h4>
            <p className="display-inline">
              <span className="font-weight-bold">{survey.get('custom_question_count')}</span>{' '}
              custom questions selected
            </p>
            {(!readOnly || survey.get('custom_question_count') > 0) && (
              <a
                href={`/${surveyType.toLowerCase()}s/${survey.get('id')}/custom-questions/`}
                className="margin-left-base"
              >
                View{!readOnly && '/Edit'}
              </a>
            )}
          </Column>
        )}
      </Row>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.survey.get('isLoading', true),
    modals: state.modals,
  };
}

export default connect(
  mapStateToProps,
  {
    updateSurvey,
    setLoading,
    setNotLoading,
    openSimpleModal
  }
)(SurveyAttributes);
