import { ModalBody } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import { AgendaGoalType } from '^/components/agendas/constants';
import { createGoalUpdateAction } from '^/components/goals/actions';
import CreateGoal from '^/components/goals/CreateGoal';
import { removeGoalFromAgendaAndToast } from './actions';
import AgendaItemCard from './AgendaItemCard';
import CustomItemsSection from './CustomItemsSection';
import { isReadOnlyAgendaItem } from './utils';

function SelectedGoalCard({goal, removeGoalFromAgenda, isDisabled}) {
  return (
    <AgendaItemCard
      title={
        <a href={goal.url} target="_blank">{goal.name}</a>
      }
      score={`${goal.percentage_complete}%`}
      onClick={removeGoalFromAgenda}
      isDisabled={isDisabled}
      isRemoving
    />
  );
}

const mapStateToPropsSelectedGoalCard = (state, props) => {
  return {
    isDisabled: props.isDisabled || isReadOnlyAgendaItem(state, props.agendaGoal.created_by)
  };
};

const mapDispatchToPropsSelectedGoalCard = (dispatch, ownProps) => {
  return {
    removeGoalFromAgenda: () => dispatch(removeGoalFromAgendaAndToast(
      ownProps.agendaId,
      ownProps.agendaGoal.id
    )),
  };
};

const ConnectedSelectedGoalCard = connect(
  mapStateToPropsSelectedGoalCard,
  mapDispatchToPropsSelectedGoalCard
)(SelectedGoalCard);

export function SelectedAgendaEmployeeGoals({
  selectedGoals,
  agendaId,
  isDisabled,
  openAddGoalModal
}) {
  return (
    <div>
      {selectedGoals.length ? selectedGoals.map((agendaGoal) => (
        <ConnectedSelectedGoalCard
          key={agendaGoal.id}
          agendaGoal={agendaGoal}
          agendaId={agendaId}
          goal={agendaGoal.goal}
          isDisabled={isDisabled}
        />
      )) : (
        <p>No goals selected.</p>
      )}
      <button
        className="btn middle orange margin-top-base"
        disabled={isDisabled}
        onClick={openAddGoalModal}
      >
        Add new goal
      </button>
      <div className="margin-top-xx-large">
        <h4 className="font-weight-bold margin-bottom-base font-size-14">
          Comments
        </h4>
        <CustomItemsSection
          agendaId={agendaId}
          isDisabled={isDisabled}
          itemsName="employee_goal_comments"
          isCommentItems
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedGoals: state.editingAgenda.employee_goals
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    openAddGoalModal: () => {
      dispatch(createGoalUpdateAction(0, 'owner', ownProps.employee.value));
      dispatch(
        openSimpleModal({
          title: 'Add new goal',
          body: (
            <ModalBody>
              <CreateGoal
                agendaId={ownProps.agendaId}
                agendaGoalType={AgendaGoalType.EMPLOYEE}
                agendaEmployee={ownProps.employee}
              />
            </ModalBody>
          ),
          large: true,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedAgendaEmployeeGoals);
