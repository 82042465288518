import { GET_AVAILABLE_FILTERS, SET_AVAILABLE_FILTERS } from './actions';

export function availableFilters(state = {}, action) {
  switch (action.type) {
    case GET_AVAILABLE_FILTERS.SUCCESS:
      return action.payload.data;
    case SET_AVAILABLE_FILTERS:
      return action.payload;
    default:
      return state;
  }
}
