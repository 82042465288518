import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import {
  EXPORT_STATUS_COMPLETE,
  IMPORT_STATUS_COMPLETE,
  IMPORT_STATUS_FAILED,
  THREE_SECONDS,
  EXPORT_POLL_MAX_RETRIES
} from './const';
import toast from 'react-toastify';

export const QUEUE_USER_EXPORT = makeAsyncActionSet('QUEUE_USER_EXPORT');
export function queueUserExportAndRedirectToPoll() {
  return (dispatch) => request(
    QUEUE_USER_EXPORT,
    '/api/account/export-users/',
    'POST'
  )(dispatch).then((response) => {
    window.location.href = response.data.polling_page_url;
  });
}

export const POLL_USER_EXPORT = makeAsyncActionSet('POLL_USER_EXPORT');
export function pollUserExport(exportId, tryCount = 0) {
  if (tryCount === EXPORT_POLL_MAX_RETRIES) {
    toast.error(
      'The export is taking longer than usual. ' +
      'Please try refreshing the page and if this problem persists contact support.'
    );
    return;
  }

  return (dispatch) => request(
    POLL_USER_EXPORT,
    `/api/account/export-users/${exportId}/`,
    'GET'
  )(dispatch).then((response) => {
    if (response.data.status !== EXPORT_STATUS_COMPLETE) {
      setTimeout(() => dispatch(pollUserExport(exportId, tryCount + 1)), THREE_SECONDS);
    }
  });
}

export const POLL_USER_IMPORT = makeAsyncActionSet('POLL_USER_IMPORT');
export function pollUserImport(importId, tryCount = 0) {
  if (tryCount === EXPORT_POLL_MAX_RETRIES) {
    toast.error(
      'The import is taking longer than usual. ' +
      'Please try refreshing the page and if this problem persists contact support.'
    );
    return;
  }

  return (dispatch) => request(
    POLL_USER_IMPORT,
    `/api/account/import-users/${importId}/`,
    'GET'
  )(dispatch).then((response) => {
    if (response.data.status !== IMPORT_STATUS_COMPLETE && response.data.status !== IMPORT_STATUS_FAILED) {
      setTimeout(() => dispatch(pollUserImport(importId), tryCount + 1), THREE_SECONDS);
    }
  });
}
