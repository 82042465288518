import React from 'react';
import OrganisationList from './organisations/OrganisationList';
import UserList from './users/UserList';
import SurveyList from './surveys/SurveyList';
import ActionList from './actions/ActionList';
import Tabs from '../Tabs';
import { Map, List } from 'immutable';

export const PAGES = {
  ORGANISATION_LIST: 'organisations',
  USER_LIST: 'users',
  SURVEY_LIST: 'survey',
  ACTION_LIST: 'action',
};

const DEFAULT_PAGE = PAGES.ORGANISATION_LIST;

export default class SupportPanel extends React.PureComponent {
  render() {
    const { queryParams } = this.props;

    const orgTab = Map({
      'ID': PAGES.ORGANISATION_LIST,
      'DisplayName': 'Organisations',
      'Component': <OrganisationList queryParams={queryParams} />
    });

    const userTab = Map({
      'ID': PAGES.USER_LIST,
      'DisplayName': 'Users',
      'Component': <UserList queryParams={queryParams} />
    });

    const surveyTab = Map({
      'ID': PAGES.SURVEY_LIST,
      'DisplayName': 'Surveys',
      'Component': <SurveyList queryParams={queryParams} />
    });

    const actionsList = Map({
      'ID': PAGES.ACTION_LIST,
      'DisplayName': 'Actions',
      'Component': <ActionList queryParams={queryParams} />
    });

    const tabs = List([orgTab, userTab, surveyTab, actionsList]);

    return (
      <div className="main-content">
        <div className="main">
          <Tabs defaultTab={this.props.initialTab ? this.props.initialTab : DEFAULT_PAGE} tabs={tabs} />
        </div>
      </div>
    );
  }
}
