import React from 'react'; // eslint-disable-line no-unused-vars

import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Column, Row } from '@dabapps/roe';

import { getCollection, ITEMS_PER_PAGE } from '^/actions/collections';
import { openSimpleModal } from '^/actions/modals';
import { isPending } from '^/consts/responseStates';
import CustomQuestionForm from '^/components/custom-questions/CustomQuestionForm';
import PureComponent from '^/components/PureComponent';
import AdminListPagination from '^/components/admin/AdminListPagination';
import { CUSTOM_QUESTION_SETTINGS, REORDER_QUESTION_DIRECTION } from '../constants';
import Label from '../Label';
import SelectedQuestionList from './SelectedList';
import AvailableQuestionList from './AvailableList';
import {
  copyCustomQuestionIntoSurveyAndReloadList,
  deleteSurveyCustomQuestionAndReloadList,
  setCustomQuestionPosition
 } from './actions';

class QuestionPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.loadCustomQuestions = this.loadCustomQuestions.bind(this);
    this.onClickReorder = this.onClickReorder.bind(this);
  }

  componentDidMount() {
    if (!this.props.customQuestionCollection.count()) {
      this.loadCustomQuestions();
    }
  }

  onAdd(questionId) {
    if (this.props.readOnly) {
      return null;
    }

    const questionBeingCopied = this.props.customQuestionCollection
      .get('items')
      .find(question => question.get('id') === questionId);

    this.props.copyCustomQuestionIntoSurvey(
      questionBeingCopied,
      (
        this.props.unselectedQuestions.count() === 1 ? this.props.currentPageNumber - 1
        : this.props.currentPageNumber
      )
    );
  }

  onRemove(questionId) {
    if (this.props.readOnly) {
      return null;
    }
    this.props.deleteSurveyCustomQuestion(questionId, this.props.currentPageNumber);
  }

  onClickReorder(questionId, direction) {
    if (this.props.readOnly) {
      return null;
    }

    const currentPosition = this.props.selectedQuestions.findIndex((question) => question.get('id') === questionId);
    const newPosition = direction === REORDER_QUESTION_DIRECTION.UP
      ? currentPosition - 1
      : currentPosition + 1;

    this.props.setCustomQuestionPosition(this.props.surveyId, questionId, newPosition);
  }

  loadCustomQuestions(page = 1) {
    if (!this.props.readOnly) {
      this.props.loadCustomQuestions({ page, });
    }
  }

  render() {
    const {
      customQuestionCollection,
      isLoadingAvailableCustomQuestions,
      isReorderingQuestions,
      readOnly,
      selectedQuestions,
      unselectedQuestions,
      currentNumberOfQuestionsCopying,
      questionIdsCurrentlyCopying,
      questionIdsCurrentlyDeleting,
      openAddQuestionModal,
      openEditQuestionModal
    } = this.props;

    return (
      <div>
        <Row>
          <Column sm={12} md={5}>
            <Label className="input-line-height"><strong>Custom questions available</strong></Label>
          </Column>
          <Column sm={12} md={7}>
            <Label className="input-line-height"><strong>Selected custom questions</strong></Label>
            {!readOnly && (
              <button
                className="btn orange hollow input-height input-line-height-within-border
                  float-right padding-vertical-none padding-horizontal-large"
                onClick={openAddQuestionModal}
              >
                Add new question
              </button>
            )}
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={5}>
            {!readOnly ? (
              <div>
                <AvailableQuestionList
                  questions={unselectedQuestions}
                  onClickAdd={this.onAdd}
                  disabled={isLoadingAvailableCustomQuestions || isReorderingQuestions}
                  readOnly={readOnly}
                  questionIdsCurrentlyCopying={questionIdsCurrentlyCopying}
                />
                <AdminListPagination
                  className="survey-question-list-pagination"
                  collection={customQuestionCollection}
                  loadPage={this.loadCustomQuestions}
                  pageSize={ITEMS_PER_PAGE}
                />
              </div>
            ) : (
              <p>Questions can no longer be added to this survey.</p>
            )}

          </Column>
          <Column sm={12} md={7}>
            <SelectedQuestionList
              questions={selectedQuestions}
              onClickRemove={this.onRemove}
              onClickReorder={this.onClickReorder}
              disabled={isReorderingQuestions}
              readOnly={readOnly}
              numberOfQuestionsCopying={currentNumberOfQuestionsCopying}
              questionIdsCurrentlyDeleting={questionIdsCurrentlyDeleting}
              openEditQuestionModal={openEditQuestionModal}
            />
          </Column>
        </Row>
      </div>
    );
  }
}

QuestionPicker.propTypes = {
  customQuestionCollection: ImmutablePropTypes.map.isRequired,
  isLoadingAvailableCustomQuestions: React.PropTypes.bool,
  loadCustomQuestions: React.PropTypes.func.isRequired,
  readOnly: React.PropTypes.bool,
  selectedQuestions: ImmutablePropTypes.list.isRequired,
  surveyId: React.PropTypes.number.isRequired,
  unselectedQuestions: ImmutablePropTypes.list.isRequired,
};


function mapStateToProps(state, props) {
  const selectedQuestions = state.surveyCustomQuestionSettings.get('existing_question_data', List());
  const customQuestionCollection = state.collections.get('custom-questions') || Map();

  const originalQuestionIds = selectedQuestions.map(
    question => question.get('original_question_id')
  ).filter(questionId => !!questionId);

  const availableQuestionData = (
    customQuestionCollection.get('items') || List()
  ).filter(
    question => !originalQuestionIds.contains(question.get('id'))
  );

  return {
    customQuestionCollection,
    selectedQuestions,
    currentPageNumber: customQuestionCollection.get('page'),
    unselectedQuestions: props.readOnly ?
      List() :
      availableQuestionData,
    isLoadingAvailableCustomQuestions: isPending(state.legacyResponses.getIn(['getCollection', 'custom-questions'])),
    isReorderingQuestions: state.surveyCustomQuestionSettings
        .get(CUSTOM_QUESTION_SETTINGS.QUESTION_REORDERING_IN_PROGRESS, false),
    currentNumberOfQuestionsCopying: state.surveyCustomQuestionSettings.get(
      CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_COPYING, List()
    ).count(),
    questionIdsCurrentlyCopying: state.surveyCustomQuestionSettings.get(
      CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_COPYING, List()
    ),
    questionIdsCurrentlyDeleting: state.surveyCustomQuestionSettings.get(
      CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_DELETING, List()
    )
  };
}

function getFiltersForAvailableQuestionsList(surveyId, questionsRecommendedFor) {
  const filters = { exclude_survey: surveyId };
  if (questionsRecommendedFor) {
    filters.questions_recommended_for = questionsRecommendedFor;
  }
  return filters;
};

function mapDispatchToProps(dispatch, props) {
  const filters = getFiltersForAvailableQuestionsList(props.surveyId, props.questionsRecommendedFor);
  return {
    loadCustomQuestions: (options) => {
      return dispatch(getCollection('custom-questions', Object.assign({}, options, { filters })));
    },
    copyCustomQuestionIntoSurvey: (questionBeingCopied, pageToReload) =>
      copyCustomQuestionIntoSurveyAndReloadList(
        props.surveyId,
        questionBeingCopied,
        { page: pageToReload, filters }
      )(dispatch),
    deleteSurveyCustomQuestion: (questionId, pageToReload) =>
      deleteSurveyCustomQuestionAndReloadList(
        props.surveyId,
        questionId,
        { page: pageToReload, filters }
      )(dispatch),
    openAddQuestionModal: () => dispatch(openSimpleModal({
      title: 'Add custom question',
      body: (<CustomQuestionForm surveyId={props.surveyId} />),
    })),
    openEditQuestionModal: (question) => dispatch(openSimpleModal({
      title: `Edit question: ${question.get('name')}`,
      body: (<CustomQuestionForm editQuestionId={question.get('id')} surveyId={props.surveyId} />),
    })),
    setCustomQuestionPosition: (surveyId, questionId, newPosition) =>
      setCustomQuestionPosition(surveyId, questionId, newPosition)(dispatch)
  };
}

export { QuestionPicker };
export default connect(mapStateToProps, mapDispatchToProps)(QuestionPicker);
