import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { ToastContainer } from 'react-toastify';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { FORMAT_DATE_PICKER, FORMAT_DATE_BACKEND } from '^/utils';
import RemoteUserSelect from '^/components/forms/RemoteUserSelect';

import { SelectField } from './BulkAgendaSetUpForm';
import { createAgendaAndRedirect, CREATE_AGENDA } from './actions';

const FORM_NAME = 'individual-agenda-setup-form';

const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper full-width">
      <ReactDatePicker
        selected={props.input.value}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        disabled={props.isDisabled}
        required
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

const EmployeeSelectField = props => (
    <RemoteUserSelect
      className="full-width"
      onChange={props.input.onChange}
      selectId={FORM_NAME}
      placeholder="Type to search..."
      disabled={props.isDisabled}
      optionFilters={{self_excluded: true}}
      required
    />
  );

export function IndividualAgendaSetUpForm({
  handleSubmit,
  isDisabled,
  selectedTemplateType
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="background-white border-radius-16 padding-xx-large margin-vertical-xx-large">
        <h1 className="font-family-effra-bold font-size-38 font-color-main">
          Create 1:1
        </h1>
        <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-large">
          Step 1 of 4: Choose conversation type
        </h4>
        <div className="well padding-left-large padding-right-large">
          <Field
            name="template_type"
            component={SelectField}
            disabled={isDisabled}
            selectedTemplateType={selectedTemplateType}
          />
        </div>
      </div>
      {selectedTemplateType && (
        <div>
          <div className="background-white border-radius-16 padding-xx-large margin-bottom-xx-large">
            <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-large">
              Step 2 of 4: Add details
            </h4>
            <div className="max-width-630">
              <div className="display-flex margin-top-xx-large">
                <label htmlFor="title" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                  1:1 name:
                </label>
                <Field
                  name="title"
                  id="title"
                  component="input"
                  type="text"
                  placeholder="Enter 1:1 name"
                  disabled={isDisabled}
                  required
                />
              </div>
              <div className="display-flex margin-top-x-large">
                <label htmlFor="description" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                  Description:
                </label>
                <div className="margin-bottom-base full-width">
                  <Field
                    name="description"
                    id="description"
                    component="textarea"
                    placeholder="Enter text here..."
                    disabled={isDisabled}
                  />
                </div>
              </div>
              <div className="display-flex margin-top-x-large">
                <label htmlFor="employee" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                  Employee:
                </label>
                <Field
                  name="employee"
                  id="employee"
                  component={EmployeeSelectField}
                  isDisabled={isDisabled}
                  required
                />
              </div>
              <div className="display-flex margin-top-x-large">
                <label htmlFor="date" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                  Date:
                </label>
                <Field
                  name="date"
                  id="date"
                  component={DatePickerField}
                  isDisabled={isDisabled}
                  required
                />
              </div>
              <div className="display-flex margin-top-x-large">
                <label htmlFor="time" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                  Time:
                </label>
                <Field
                  name="time"
                  id="time"
                  component="input"
                  type="time"
                  disabled={isDisabled}
                  required
                />
              </div>
            </div>
            <div className="padding-vertical-large">
              <hr />
            </div>
            <p className="text-align-right font-family-varela-round font-size-16">
              Next section: Create agenda
            </p>
            <div className="display-flex">
              <button
                className="pink-button margin-left-auto"
                disabled={isDisabled}
              >
                Next
              </button>
              <a
                className="pink-border-button link-color-black text-decoration-none
                  margin-left-x-large button-line-height"
                href={!isDisabled ? '/agendas/list/' : undefined}
                disabled={isDisabled}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </form>
  );
}

const IndividualAgendaSetUpFormFormified = reduxForm({
  form: FORM_NAME,
})(IndividualAgendaSetUpForm);

export function mapStateToProps(state) {
  const selectedTemplateType = formValueSelector(FORM_NAME)(state, 'template_type');
  return {
    selectedType: selectedTemplateType,
    isDisabled: isPending(state.responses, CREATE_AGENDA),
    selectedTemplateType
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: data => {
      dispatch(createAgendaAndRedirect(Object.assign({}, data, {
        date: data.date && data.date.format(FORMAT_DATE_BACKEND),
        employee: data.employee && data.employee.value
      })));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualAgendaSetUpFormFormified);
