import { isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import WidgetTitle from '^/components/dashboards/WidgetTitle';
import {
  EMPLOYEE_OKR_DATA,
  getEmployeeOkrList,
  GET_EMPLOYEE_OKR_LIST,
  MANAGER_OKR_DATA,
  getManagerOkrList,
  GET_MANAGER_OKR_LIST,
} from '^/components/dashboards/actions';
import Loading from '^/components/Loading';
import {
  OKR_STATUS_LABEL_MAP,
  getColorForProgress,
  NO_OKRS_INDIVIDUAL_MESSAGE,
  NO_OKRS_TEAM_MESSAGE
} from '^/components/okrs/constants';

export const OkrWidgetTile = ({ okr, narrow }) => {
  return (
    <a
      className="widget-item-hollow okr-widget-tile-container margin-bottom-large text-decoration-none"
      href={`/okrs/${okr.id}`}
    >
      <div
        className={classNames(
          'okr-name-widget padding-right-large',
          {
            'width-55-percent': narrow,
            'width-60-percent': !narrow
          }
        )}
        title={okr.name}
      >
        <p className="margin-bottom-none text-overflow-ellipsis font-color-main font-size-14">{okr.name}</p>
      </div>
      <div className="flex-grow">
        <div className="okr-tile-status-bar">
          <div
            className={`okr-tile-status-bar-progress ${getColorForProgress(okr.percentage_complete)}`}
            style={{width: `${okr.percentage_complete}%`}}
          />
        </div>
        <div className="flex-space-between">
          <p className="margin-bottom-none fwb">{OKR_STATUS_LABEL_MAP[okr.status]}</p>
          <p className="margin-bottom-none fwb">{okr.percentage_complete}%</p>
        </div>
      </div>
    </a>
  );
};

function renderOkrOrNoOkrMessage(okrs, isManagerDashboard, narrow) {

  if (okrs && okrs.length > 0) {
    return okrs.map(okr => <OkrWidgetTile key={okr.id} okr={okr} narrow={narrow} />);
  }

  return <p>{ isManagerDashboard ? NO_OKRS_TEAM_MESSAGE : NO_OKRS_INDIVIDUAL_MESSAGE }</p>;
}

export class OkrWidget extends React.PureComponent {

  componentDidMount() {
    this.props.getOkrs();
  }

  render() {
    const { okrs, isLoading, isManagerDashboard, narrow } = this.props;

    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title={isManagerDashboard ? 'Team OKRs' : 'My OKRs'}
          image="/static/images/dashboards/okrs.svg"
        />
        {isLoading && <Loading />}

        { renderOkrOrNoOkrMessage(okrs, isManagerDashboard, narrow) }

        <div className="full-width text-center margin-top-auto">
          <a
            className="widget-view-all-button"
            href="/okrs/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const responseKey = props.isManagerDashboard ? GET_MANAGER_OKR_LIST : GET_EMPLOYEE_OKR_LIST;
  const reducerKey = props.isManagerDashboard ? MANAGER_OKR_DATA : EMPLOYEE_OKR_DATA;
  return {
    okrs: state.dashboardOkrList[reducerKey],
    isLoading: isPending(state, responseKey)
  };
}

export function mapDispatchToProps(dispatch, props) {
  const getFunction = props.isManagerDashboard ? getManagerOkrList : getEmployeeOkrList;
  return {
    getOkrs: () => dispatch(getFunction())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OkrWidget);
