import React from 'react';
import { connect } from 'react-redux';
import Loading from '^/components/Loading';
import { ToastContainer } from 'react-toastify';
import { isPending, anyPending } from '@dabapps/redux-requests';
import { RETRIEVE_OKR, UPDATE_OKR, retrieveOkr, updateOkrAndRedirectToIt } from './actions';
import EditOkrForm from './EditOkrForm';
import moment from 'moment';
import { FORMAT_DATE_BACKEND } from '^/utils';
import ActionsPicker from './ActionsPicker';
import { fromJS } from 'immutable';

export class OkrEdit extends React.PureComponent {

  componentDidMount() {
    this.props.retrieveOkr();
  }

  render() {
    if (this.props.isLoading || !this.props.okr) {
      return <Loading />;
    }

    const {
      okr,
    } = this.props;

    return (
      <div className="will padding-x-large">
        <ToastContainer />
        <EditOkrForm
          okr={okr}
          initialValues={{
            name: okr.get('name'),
            due_date: moment(okr.get('due_date')),
            status: okr.get('status')
          }}
          onSubmit={this.props.onSubmit}
        />
        <ActionsPicker
          okrId={okr.get('id')}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses, RETRIEVE_OKR),
    isDisabled: anyPending(state.responses, [RETRIEVE_OKR, UPDATE_OKR]),
    okr: state.okrData,
    excludedActions: state.actionPickerExcludedActions,
  };
};

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    retrieveOkr: () => dispatch(retrieveOkr(ownProps.okrId)),
    getOnSubmitFunction: (excludedActions, okr) => (data) => {
      const dataWithActions = fromJS(data)
        .set('due_date', data.due_date.format(FORMAT_DATE_BACKEND))
        .set(
          'actions',
          okr
            .get('actions')
            .filter((action) => !excludedActions.includes(action.get('id')))
            .map((action) => action.get('id'))
        )
        .toJS();
      dispatch(updateOkrAndRedirectToIt(ownProps.okrId, dataWithActions));
    },
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    onSubmit: dispatchProps.getOnSubmitFunction(
      stateProps.excludedActions,
      stateProps.okr
    ),
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(OkrEdit);
