import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { ToastContainer } from 'react-toastify';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { FORMAT_DATE_PICKER, FORMAT_DATE_BACKEND } from '^/utils';

import { createBulkAgendaAndRedirect, CREATE_BULK_AGENDA } from './actions';
import { TEMPLATE_TYPES, TEMPLATE_TYPE_DISPLAY_MAP, TEMPLATE_TYPE_IMAGE_MAP } from './constants';

const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper full-width">
      <ReactDatePicker
        selected={props.input.value}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        disabled={props.isDisabled}
        required
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

export class TemplateTypeCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect() {
    this.props.change(this.props.templateType);
  }

  render() {
    const title = TEMPLATE_TYPE_DISPLAY_MAP[this.props.templateType];
    const hasSlash = title.includes('/');
    const [beforeSlash, afterSlash] = hasSlash ? title.split('/') : [title];

    return (
      <div className="option-card font-family-varela-round">
        <div className="margin-bottom-small">
          <div className="questionnaire-type-info">
            <i className="fas fa-info-circle text-pink"></i>
            <div className="font-size-12">{ this.props.toolTipText }</div>
          </div>
        </div>
        <div className="option-card-name no-wrap margin-bottom-small font-size-14">
          {hasSlash ? (
            <span>{beforeSlash}/<br />{afterSlash}</span>
          ) : (
            <span>{beforeSlash}</span>
          )}
        </div>
        <div className="margin-bottom-small">
          <img className="survey-image" src={TEMPLATE_TYPE_IMAGE_MAP[this.props.templateType]} />
        </div>
        <button
          className="pink-button font-size-14 padding-vertical-base"
          type="button"
          onClick={this.onSelect}
          disabled={this.props.disabled || this.props.selectedTemplateType === this.props.templateType}
        >
          Select
        </button>
      </div>
    );
  }
}

export const SelectField = (props) => {
  return (
    <div className="option-card-container">
      <TemplateTypeCard
        templateType={TEMPLATE_TYPES.ENGAGEMENT}
        change={props.input.onChange}
        disabled={props.disabled}
        selectedTemplateType={props.selectedTemplateType}
        toolTipText="Review engagement by assessing which needs are/are not being met in the workplace."
      />
      <TemplateTypeCard
        templateType={TEMPLATE_TYPES.PERFORMANCE}
        change={props.input.onChange}
        disabled={props.disabled}
        selectedTemplateType={props.selectedTemplateType}
        toolTipText="Review performance & development - these conversations should be taking place regularly."
      />
      <TemplateTypeCard
        templateType={TEMPLATE_TYPES.GOALS_AND_OKRS}
        change={props.input.onChange}
        disabled={props.disabled}
        selectedTemplateType={props.selectedTemplateType}
        toolTipText="Evaluate progress on goals and/or OKRs."
      />
      <TemplateTypeCard
        templateType={TEMPLATE_TYPES.MENTAL_HEALTH}
        change={props.input.onChange}
        disabled={props.disabled}
        selectedTemplateType={props.selectedTemplateType}
        toolTipText="Check in on employees who may be struggling to cope."
        />
      <TemplateTypeCard
        templateType={TEMPLATE_TYPES.CUSTOM}
        change={props.input.onChange}
        disabled={props.disabled}
        selectedTemplateType={props.selectedTemplateType}
        toolTipText="Create a meeting agenda from scratch."
      />
    </div>
  );
};

export function BulkAgendaSetUpForm({
  handleSubmit,
  isDisabled,
  selectedTemplateType
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="background-white border-radius-16 padding-xx-large margin-vertical-xx-large">
        <h1 className="font-family-effra-bold font-size-38 font-color-main">
          Create multiple 1:1s
        </h1>
        <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-large">
          Step 1 of 4: Choose conversation type
        </h4>
        <div className="well padding-left-large padding-right-large">
          <Field
            name="template_type"
            component={SelectField}
            disabled={isDisabled}
            selectedTemplateType={selectedTemplateType}
          />
        </div>
      </div>
      {selectedTemplateType && (
        <div className="background-white border-radius-16 padding-xx-large margin-vertical-xx-large">
          <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-large">
            Step 2 of 4: Add details
          </h4>
          <div className="max-width-630">
            <div className="display-flex margin-top-xx-large">
              <label htmlFor="title" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                1:1 name:
              </label>
              <Field
                name="title"
                id="title"
                component="input"
                type="text"
                placeholder="Enter 1:1 name"
                disabled={isDisabled}
                required
              />
            </div>
            <div className="display-flex margin-top-x-large">
              <label htmlFor="description" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                Description:
              </label>
              <Field
                name="description"
                id="description"
                component="textarea"
                placeholder="Enter text here..."
                disabled={isDisabled}
              />
            </div>
            <div className="display-flex margin-top-xx-large">
              <label htmlFor="due_date" className="font-size-14 width-150px flex-shrink-0 input-line-height">
                Due date:
              </label>
              <Field
                name="due_date"
                id="due_date"
                component={DatePickerField}
                isDisabled={isDisabled}
                required
              />
            </div>
          </div>
          <div className="padding-vertical-large">
            <hr />
          </div>
          <p className="text-align-right font-family-varela-round font-size-16">
            Next section: Select participants
          </p>
          <div className="display-flex">
            <button
              className="pink-button margin-left-auto"
              disabled={isDisabled}
            >
              Next
            </button>
            <a
              className="pink-border-button link-color-black text-decoration-none margin-left-x-large
                button-line-height"
              href={!isDisabled ? '/agendas/list/' : undefined}
              disabled={isDisabled}
            >
              Cancel
            </a>
          </div>
        </div>
      )}
      <ToastContainer />
    </form>
  );
}

const FORM_NAME = 'agenda-details-form';

const BulkAgendaSetUpFormFormified = reduxForm({
  form: FORM_NAME,
})(BulkAgendaSetUpForm);

export function mapStateToProps(state) {
  const selectedTemplateType = formValueSelector(FORM_NAME)(state, 'template_type');
  return {
    selectedType: selectedTemplateType,
    isDisabled: isPending(state.responses, CREATE_BULK_AGENDA),
    selectedTemplateType
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onSubmit: data => {
      dispatch(createBulkAgendaAndRedirect(Object.assign({}, data, {
        due_date: data.due_date && data.due_date.format(FORMAT_DATE_BACKEND)
      })));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkAgendaSetUpFormFormified);
