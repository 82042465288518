import {
  GET_AGENDA_TEMPLATE,
  UPDATE_AGENDA_TEMPLATE,
  ADD_AGENDA_TEMPLATE_PART,
  REMOVE_AGENDA_TEMPLATE_PART,
  DISMISS_EMPLOYEE_AGENDA_REQUEST,
  RESPOND_TO_AGENDA_REQUEST,
  SET_ACTIONABLE_AGENDAS,
  GET_AGENDA,
  UPDATE_AGENDA,
  CREATE_AGENDA_GOAL,
  CREATE_AGENDA_ITEM,
  AGENDA_ITEM_EDIT_ACTION,
  UPDATE_AGENDA_ITEM,
  DELETE_AGENDA_ITEM,
  GET_AGENDA_READ_ONLY,
  AGENDA_ITEM_CANCEL_EDIT,
  CREATE_AGENDA_REVIEW_QUESTION,
  DELETE_AGENDA_REVIEW_QUESTION,
  UPDATE_AGENDA_SECTION_POSITION,
  EXPAND_AGENDA_SECTION,
  COLLAPSE_AGENDA_SECTION,
  ADD_GOAL_TO_AGENDA,
  REMOVE_GOAL_FROM_AGENDA,
  ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA,
  REMOVE_ENGAGEMENT_SURVEY_QUESTION_FROM_AGENDA,
  UPDATE_AGENDA_TALENT_SNAPSHOT,
  SEND_CONFIRMATION_EMAIL,
  CREATE_AGENDA_OKR,
  DELETE_AGENDA_OKR,
  CREATE_AGENDA_PEER_FEEDBACK,
  DELETE_AGENDA_PEER_FEEDBACK,
  TOGGLE_VIEW_ALL_PEER_FEEDBACK_OPTIONS,
  MANAGE_BULK_AGENDA_USERS
} from '^/components/agendas/actions';
import {
  AgendaGoalType,
  AgendaStatus,
  AgendaSectionType,
  ItemsNameMappedToSectionType,
  CustomItemsNameMappedToSectionType,
  EDIT_AGENDA_DETAILS
} from '^/components/agendas/constants';
import { getTemplateItemsMapping } from '^/components/agendas/templates';
import { splitSelectedTemplateParts } from '^/components/agendas/utils';
import { ADD_ACTION_TO_GOAL, UPDATE_GOAL } from '^/components/goals/actions';
import { stripFalsyValuesFromObject, getQueryParamsFromLocation } from '^/utils';


export function actionableAgendas(state = [], action) {
  switch (action.type) {
    case SET_ACTIONABLE_AGENDAS:
      return action.agendas;
    case RESPOND_TO_AGENDA_REQUEST.SUCCESS:
    case DISMISS_EMPLOYEE_AGENDA_REQUEST.SUCCESS:
      return state.filter(agenda => agenda.id !== action.meta.id);
    default:
      return state;
  }
}

function getUpdatedSectionPositionsState(state, action) {
  if (!state) {
    return state;
  }
  const filteredState = state.sections.filter(item => item !== action.meta.sectionType);
  const newState = [
    ...filteredState.slice(0, action.meta.position),
    action.meta.sectionType,
    ...filteredState.slice(action.meta.position)
  ];
  return Object.assign({}, state, {
    sections: newState
  });
}

function getCreatedAgendaItemState(state, action) {
  if (!state) {
    return state;
  }
  return Object.assign({}, state, {
    [action.meta.itemsName]: [...state[action.meta.itemsName], action.payload.data]
  });
}

function getUpdatedAgendaItemState(state, action) {
  if (!state) {
    return state;
  }
  return Object.assign({}, state, {
    [action.meta.itemsName]: state[action.meta.itemsName].map(item => (
      item.id === action.meta.agendaItemId ?
      Object.assign({}, item, { text: action.payload.data.text }) : item
    ))
  });
}

function getDeletedAgendaItemState(state, action, extraState = {}) {
  if (!state) {
    return state;
  }
  return Object.assign({}, state, {
    [action.meta.itemsName]: state[action.meta.itemsName].filter(
      item => item.id !== action.meta.agendaItemId
    )
  }, extraState);
}

export function editingAgenda(state = null, action) {
  switch (action.type) {
    case GET_AGENDA.SUCCESS:
    case GET_AGENDA_READ_ONLY.SUCCESS:
      return action.payload.data;
    case UPDATE_AGENDA.SUCCESS:
      return Object.assign({}, state, action.meta.data);
    case ADD_GOAL_TO_AGENDA.SUCCESS:
      return Object.assign({}, state, {
        employee_goals: [...state.employee_goals, action.payload.data]
      });
    case REMOVE_GOAL_FROM_AGENDA.SUCCESS:
      return Object.assign({}, state, {
        employee_goals: state.employee_goals.filter(agendaGoal => agendaGoal.id !== action.meta.agendaGoalId)
      });
    case CREATE_AGENDA_GOAL.SUCCESS:
      if (action.meta.agendaGoalType === AgendaGoalType.MANAGER) {
        return Object.assign({}, state, {
          manager_goals: [...state.manager_goals, action.payload.data]
        });
      }
      if (action.meta.agendaGoalType === AgendaGoalType.EMPLOYEE) {
        return Object.assign({}, state, {
          employee_goals: [...state.employee_goals, action.payload.data]
        });
      }
    case UPDATE_GOAL.SUCCESS:
      if (!state) {
        return state;
      }
      return Object.assign({}, state, {
        manager_goals: state.manager_goals.map(goal =>
          goal.id === action.payload.data.id ? action.payload.data : goal
        )
      });
    case ADD_ACTION_TO_GOAL.SUCCESS:
      if (!state) {
        return state;
      }
      return Object.assign({}, state, {
        manager_goals: state.manager_goals.map(goal =>
          goal.id === action.meta.goalId ? Object.assign({}, goal, {
            actions_count: goal.actions_count + 1
          }) : goal
        )
      });
    case ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA.SUCCESS:
      return Object.assign({}, state, {
        engagement_survey_questions: [...state.engagement_survey_questions, action.payload.data]
      });
    case REMOVE_ENGAGEMENT_SURVEY_QUESTION_FROM_AGENDA.SUCCESS:
      return Object.assign({}, state, {
        engagement_survey_questions: state.engagement_survey_questions.filter(
          (question) => question.id !== action.meta.agendaEngagementSurveyQuestionId
        )
      });
    case CREATE_AGENDA_REVIEW_QUESTION.SUCCESS:
      return Object.assign({}, state, {
        review_questions: [...state.review_questions, action.payload.data]
      });
    case DELETE_AGENDA_REVIEW_QUESTION.SUCCESS:
      return Object.assign({}, state, {
        review_questions: state.review_questions.filter(
          review_question => review_question.id !== action.meta.reviewQuestionId
        )
      });
    case UPDATE_AGENDA_TALENT_SNAPSHOT.SUCCESS:
      return Object.assign({}, state, {
        talent_snapshot_responses: stripFalsyValuesFromObject(action.meta.data)
      });
    case SEND_CONFIRMATION_EMAIL.SUCCESS:
      return Object.assign({}, state, {
        status: AgendaStatus.ACCEPTED
      });
    case UPDATE_AGENDA_SECTION_POSITION.SUCCESS:
      return getUpdatedSectionPositionsState(state, action);
    case CREATE_AGENDA_ITEM.SUCCESS:
      return getCreatedAgendaItemState(state, action);
    case UPDATE_AGENDA_ITEM.SUCCESS:
      return getUpdatedAgendaItemState(state, action);
    case DELETE_AGENDA_ITEM.SUCCESS:
      return getDeletedAgendaItemState(state, action);
    case CREATE_AGENDA_OKR.SUCCESS:
      return Object.assign({}, state, {
        okrs: [...state.okrs, action.payload.data]
      });
    case DELETE_AGENDA_OKR.SUCCESS:
      return Object.assign({}, state, {
        okrs: state.okrs.filter(okr => okr.id !== action.meta.agendaOkrId)
      });
    case CREATE_AGENDA_PEER_FEEDBACK.SUCCESS:
      return Object.assign({}, state, {
        peer_feedback: [...state.peer_feedback, action.payload.data]
      });
    case DELETE_AGENDA_PEER_FEEDBACK.SUCCESS:
      return Object.assign({}, state, {
        peer_feedback: state.peer_feedback.filter(feedback => feedback.id !== action.meta.agendaPeerFeedbackId)
      });
    default:
      return state;
  }
}

export function editingAgendaTemplate(state = null, action) {
  switch (action.type) {
    case GET_AGENDA_TEMPLATE.SUCCESS:
      return action.payload.data;
    case ADD_AGENDA_TEMPLATE_PART.SUCCESS:
      const { selected_template_parts, custom_item } = action.payload.data;
      return Object.assign({}, state, {
        selected_template_parts,
        custom_items: custom_item ? [...state.custom_items, custom_item] : state.custom_items
      });
    case REMOVE_AGENDA_TEMPLATE_PART.SUCCESS:
    case UPDATE_AGENDA_TEMPLATE.SUCCESS:
      return Object.assign({}, state, action.payload.data);
    case UPDATE_AGENDA_SECTION_POSITION.SUCCESS:
      return getUpdatedSectionPositionsState(state, action);
    case CREATE_AGENDA_ITEM.SUCCESS:
      return getCreatedAgendaItemState(state, action);
    case UPDATE_AGENDA_ITEM.SUCCESS:
      return getUpdatedAgendaItemState(state, action);
    case DELETE_AGENDA_ITEM.SUCCESS:
      const updatedTemplateParts = action.payload.data.selected_template_parts;
      return getDeletedAgendaItemState(state, action, updatedTemplateParts && {
        selected_template_parts: updatedTemplateParts
      });
    default:
      return state;
  }
}

export function agendaGoalsCreated(state = [], action) {
  switch (action.type) {
    case CREATE_AGENDA_GOAL.SUCCESS:
      if (action.meta.agendaGoalType === AgendaGoalType.EMPLOYEE) {
        return [...state, action.payload.data.goal];
      }
    default:
      return state;
  }
}

const EDITING_AGENDA_ITEM_IDS_DEFAULT_STATE = Boolean(getQueryParamsFromLocation().edit_agenda_details) ?
  [EDIT_AGENDA_DETAILS] :
  [];

export function editingAgendaItemIds(state = EDITING_AGENDA_ITEM_IDS_DEFAULT_STATE, action) {
  switch (action.type) {
    case AGENDA_ITEM_EDIT_ACTION:
      return [...state, action.agendaItemId];
    case AGENDA_ITEM_CANCEL_EDIT:
      return state.filter(itemId => itemId !== action.agendaItemId);
    case UPDATE_AGENDA_TEMPLATE.SUCCESS:
    case UPDATE_AGENDA.SUCCESS:
    case UPDATE_AGENDA_ITEM.SUCCESS:
    case CREATE_AGENDA_GOAL.SUCCESS:
    case CREATE_AGENDA_ITEM.SUCCESS:
      return state.filter(itemId => itemId !== action.meta.agendaItemId);
    default:
      return state;
  }
}

function agendaTemplateHasSelectedSection(selectedTemplateParts, sectionType) {
  const itemsForSectionType = getTemplateItemsMapping()[sectionType].map(item => item.id);
  return selectedTemplateParts && !!selectedTemplateParts.find(part =>
    itemsForSectionType.includes(part)
  );
}

function getExpandedCustomItemsAndSharedNotes(state, agenda) {
  const newState = [...state];
  if (agenda.custom_items.length) {
    newState.push(AgendaSectionType.CUSTOM_ITEMS);
  }
  if (agenda.shared_notes) {
    newState.push(AgendaSectionType.SHARED_NOTES);
  }
  return newState;
}

export function expandedAgendaSections(state = [], action) {
  switch (action.type) {
    case EXPAND_AGENDA_SECTION:
      return [...state, action.sectionType];
    case COLLAPSE_AGENDA_SECTION:
      return state.filter(sectionType => sectionType !== action.sectionType);
    case ADD_AGENDA_TEMPLATE_PART.SUCCESS:
    case CREATE_AGENDA_REVIEW_QUESTION.SUCCESS:
    case ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA.SUCCESS:
    case ADD_GOAL_TO_AGENDA.SUCCESS:
    case CREATE_AGENDA_PEER_FEEDBACK.SUCCESS:
    case CREATE_AGENDA_OKR.SUCCESS:
    case CREATE_AGENDA_PEER_FEEDBACK.SUCCESS:
      return [...state, action.meta.sectionType];
    case GET_AGENDA.SUCCESS: {
      const agenda = action.payload.data;

      if (agenda.pending_process_from_template) {
        return state;
      }

      const newState = getExpandedCustomItemsAndSharedNotes(state, agenda);
      [
        AgendaSectionType.PERFORMANCE_REVIEW,
        AgendaSectionType.ENGAGEMENT_SURVEY,
        AgendaSectionType.EMPLOYEE_GOALS,
        AgendaSectionType.OKRS,
        AgendaSectionType.PEER_FEEDBACK
      ].forEach(sectionType => {
        const itemsName = ItemsNameMappedToSectionType[sectionType];
        const commentsName = CustomItemsNameMappedToSectionType[sectionType];
        if (agenda[itemsName].length || agenda[commentsName].length) {
          newState.push(sectionType);
        }
      });
      if (
        (agenda.talent_snapshot_responses && Object.entries(agenda.talent_snapshot_responses).length) ||
        agenda.manager_notes ||
        (agenda.manager_goals && agenda.manager_goals.length)
      ) {
        newState.push(AgendaSectionType.MANAGER_ONLY);
      }
      return newState;
    }
    case GET_AGENDA_TEMPLATE.SUCCESS: {
      const agenda = action.payload.data;
      const newState = getExpandedCustomItemsAndSharedNotes(state, agenda);
      [
        AgendaSectionType.PERFORMANCE_REVIEW,
        AgendaSectionType.ENGAGEMENT_SURVEY,
        AgendaSectionType.EMPLOYEE_GOALS,
        AgendaSectionType.OKRS,
        AgendaSectionType.PEER_FEEDBACK
      ].forEach(sectionType => {
        const commentsName = CustomItemsNameMappedToSectionType[sectionType];
        if (
          agenda[commentsName].length ||
          agendaTemplateHasSelectedSection(
            splitSelectedTemplateParts(agenda.selected_template_parts),
            sectionType
          )
        ) {
          newState.push(sectionType);
        }
      });
      if (agenda.manager_notes) {
        newState.push(AgendaSectionType.MANAGER_ONLY);
      }
      return newState;
    }
    default:
      return state;
  }
}

export function showAllPeerFeedbackOptions(state = false, action) {
  switch (action.type) {
    case TOGGLE_VIEW_ALL_PEER_FEEDBACK_OPTIONS:
      return !state;
    default:
      return state;
  }
}

export function bulkAgendaSelectedUserIds(state = null, action) {
  switch (action.type) {
    case MANAGE_BULK_AGENDA_USERS.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
