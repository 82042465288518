import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Map, List, fromJS } from 'immutable';
import PureComponent from '^/components/PureComponent';
import { stripFalsyValuesFromObject } from '^/utils';
import moment from 'moment';
import { FORMAT_DATE } from '../../utils';
import { isEmployeeBubbleUser } from '^/utils/permissions';
import ActionEditorForm from './ActionEditorForm';
import { getActionOptions, getBasicAction, updateAction } from '^/actions/actions';
import { loadItem } from '../../actions/items';
import Loading from '^/components/Loading';

export function prepareDataForActionUpdateRequest(data) {
  function maybeTransformAreaToEnsureNotBlank(dataMap) {
    return dataMap.get('area') === '' ? dataMap.set('area', null) : dataMap;
  }

  function maybeTransformGoalToEnsureNotBlank(dataMap) {
    return dataMap.get('goal') === '' ? dataMap.set('goal', null) : dataMap;
  }

  function maybeTransformTeamMemberFilterIntoId(dataMap) {
    return dataMap.get('team_member_filter', null)
      ? dataMap.set(
        'team_member_filter', dataMap.getIn(['team_member_filter', 'value'])
      )
      : dataMap;
  }

  function transformOwnerIntoId(dataMap) {
    return dataMap.set(
      'owner', dataMap.getIn(['owner', 'value'])
    );
  }

  const dataMap = fromJS(data);

  return maybeTransformAreaToEnsureNotBlank(
    maybeTransformGoalToEnsureNotBlank(
      maybeTransformTeamMemberFilterIntoId(
        transformOwnerIntoId(
          dataMap
        )
      )
    )
  ).toJS();
}

export class ActionEditor extends PureComponent {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getActionOptions(
      this.props.actionId,
      stripFalsyValuesFromObject({confidential: this.props.isConfidential})
    );
    this.props.getBasicAction(this.props.actionId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hasUpdated) {
      window.location = `/goals/actions/${nextProps.action.get('id')}/${window.location.search}`;
    }
  }

  handleSubmit(data) {
    const preparedData = prepareDataForActionUpdateRequest(data);
    this.props.updateAction(this.props.actionId, preparedData);
  }

  getInitialValues() {
    let action = this.props.action;
    const teamMember = action.get('team_member_filter');

    if (teamMember) {
      action = action.set('team_member_filter', {
        label: teamMember.get('display_name'),
        value: teamMember.get('id')
      });
    }

    return action.toJS();
  }

  render() {
    const {
      action,
      actionId,
      errors,
      isLoading,
      isFetchingAction,
      options
     } = this.props;

    if (isFetchingAction) {
      return <Loading />;
    }

    const areaOptions = options.getIn(['actions', 'PUT', 'area', 'choices'], List());
    const surveyOptions = options.getIn(['actions', 'PUT', 'survey', 'choices'], List());
    const goalOptions = options.getIn(['actions', 'PUT', 'goal', 'choices'], List());

    return (
      <div className="action-editor">
        <ActionEditorForm
          initialValues={this.getInitialValues()}
          isLoading={isLoading}
          areaOptions={areaOptions}
          surveyOptions={surveyOptions}
          goalOptions={goalOptions}
          lockedSurveyName={action.get('is_survey_locked') ? action.get('survey_name') : null}
          actionCreatedDate={moment(action.get('created_date')).format(FORMAT_DATE)}
          onSubmit={this.handleSubmit}
          cancelUrl={`/goals/actions/${actionId}/`}
          errors={errors}
          isEmployeeBubbleUser={this.props.isEmployeeBubbleUser}
        />
      </div>
    );
  }
}

export { ActionEditor as UnconnectedActionEditor };

export function mapStateToProps(state) {
  const teamMemberId = state.actionReducer.getIn(['action', 'team_member_filter']);
  const options = state.actionReducer.get('options', Map());

  return {
    action: state.actionReducer.get('action', Map()),
    errors: state.actionReducer.get('errors', Map()),
    isLoading: state.actionReducer.get('isLoading', false),
    isFetchingAction: state.actionReducer.get('isFetchingAction', false),
    hasUpdated: state.actionReducer.get('hasUpdated', false),
    options,
    initialTeamMember: teamMemberId ? state.items.getIn(['people', teamMemberId], Map()) : null,
    isEmployeeBubbleUser: isEmployeeBubbleUser(state.loggedInUser),
    loggedInUser: state.loggedInUser,
  };
}

export default connect(mapStateToProps, {
  getActionOptions,
  getBasicAction,
  updateAction,
  loadItem
})(ActionEditor);
