import React from 'react'; // eslint-disable-line no-unused-vars

export const StatusCount = ({count, status, colour}) => {
  return (
    <div className="widget-item-grey third-width font-family-varela-round">
      <div className="font-size-38 margin-bottom-small">
        {count}
      </div>
      <p className="font-size-18 line-height-16 margin-bottom-small">
        {status}
      </p>
      <div className="okr-tile-status-bar border-radius-base">
        <div
          className={
            `okr-tile-status-bar-progress border-radius-base full-width ${colour}`
          }
        />
      </div>
    </div>
  );
};
