import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { connect } from 'react-redux';
import {
  setAgendaReadOnlyAndRedirect,
  UPDATE_AGENDA_SET_READONLY
} from './actions';

export const AgendaConfirmSaveReadonlyModal = (props) => {
  return (
    <div>
      <ModalBody>
        <p>
          Please note that once saved as read only, your agenda can no longer be edited.
          If you wish to continue making changes or adding notes,
          please leave your agenda in edit mode and do not save as read only.
        </p>
        <p className="margin-top-base">
          Once saved as read only, a PDF summary will be emailed to you and the employee.
        </p>
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button disabled={props.isDisabled} onClick={props.save}>Save as read only</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isDisabled: isPending(state.responses, UPDATE_AGENDA_SET_READONLY)
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    save: () => {
      dispatch(setAgendaReadOnlyAndRedirect(ownProps.agendaId));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaConfirmSaveReadonlyModal);
