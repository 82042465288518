import { isPending } from '@dabapps/redux-requests';
import moment from 'moment';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { getManagerOneToOnes, GET_MANAGER_ONE_TO_ONES } from '^/components/dashboards/actions';
import Loading from '^/components/Loading';
import WidgetTitle from '^/components/dashboards/WidgetTitle';


export class ManagerOneToOnes extends React.PureComponent {
  componentDidMount() {
    this.props.getManagerOneToOnes();
  }

  render() {
    const { pending, requested, isLoading } = this.props;
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="1:1 meetings" image="/static/images/dashboards/onetoones.svg" />
        {isLoading && <Loading />}
        <div className="font-family-varela-round font-size-18 margin-bottom-base">
          Requested
        </div>
        {!isLoading && requested.length > 0 ? requested.map(agenda => (
          <div className="widget-item-hollow margin-bottom-large" key={agenda.id}>
              <div className="font-size-14 fwb">
                {agenda.title}
              </div>
              <div className="flex-space-between margin-top-small">
                <div className="text-gray font-size-13">
                  {agenda.scheduled_datetime ? moment(agenda.scheduled_datetime).format('DD-MM-YYYY, H:mm') : ''}
                </div>
                <div className="font-size-13">
                  <a href={`/agendas/list/`} className="view-details-link">
                    Review<i className="far fa-arrow-right  margin-left-small"></i>
                  </a>
                </div>
              </div>
            </div>
          )) : (
          <p>No requested 1:1 meetings.</p>
        )}
        <div className="font-family-varela-round font-size-18 margin-bottom-base">
          Pending
        </div>
        {!isLoading && pending.length > 0 ? pending.map(agenda => (
          <div className="widget-item-hollow margin-bottom-large" key={agenda.id}>
            <div className="font-size-14 fwb">
              {agenda.title}
            </div>
            <div className="flex-space-between margin-top-small">
              <div className="text-gray font-size-13">
                {agenda.scheduled_datetime ? moment(agenda.scheduled_datetime).format('DD-MM-YYYY, H:mm') : ''}
              </div>
              <div className="font-size-13">
                <a href={`/agendas/${agenda.id}/edit/`} className="view-details-link">
                  Complete now<i className="far fa-arrow-right  margin-left-small"></i>
                </a>
              </div>
            </div>
          </div>
        )) : (
          <p>No pending 1:1 meetings.</p>
        )}
        <div className="margin-top-auto text-center">
          <a
            className="widget-view-all-button"
            href="/agendas/list/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    pending: state.dashboardOneToOnes.pending || [],
    requested: state.dashboardOneToOnes.requested || [],
    isLoading: isPending(state.responses, GET_MANAGER_ONE_TO_ONES),
  };
}

export default connect(mapStateToProps, { getManagerOneToOnes })(ManagerOneToOnes);
