import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { ModalBody } from '@dabapps/roe';
import { connect } from 'react-redux';
import { deleteCustomReportCloseModalAndToast, DELETE_CUSTOM_REPORT } from './actions';
import { closeModal } from '^/actions/modals';

export const ReportDeleteModal = (props) => {
  return (
    <div>
      <ModalBody>
        <p className="font-size-14">
          Are you sure you want to delete this report?{' '}
          Please note that once this report is deleted, all users who could previously{' '}
          view this report will lose access.
        </p>
      </ModalBody>
      <div className="display-flex margin-vertical-base">
        <button
          disabled={props.isDisabled}
          className="pink-button margin-left-auto"
          onClick={props.close}
        >
          Cancel
        </button>
        <button
          className="pink-border-button margin-left-x-large"
          onClick={props.delete}
          disabled={props.isDisabled}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isDisabled: isPending(state.responses, DELETE_CUSTOM_REPORT)
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    delete: () => {
      dispatch(deleteCustomReportCloseModalAndToast(props.report));
    },
    close: () => {
      dispatch(closeModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDeleteModal);
