import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { toggleListItemSelected } from '^/actions/actions';
import { SEND_PEER_FEEDBACK_REMINDERS } from '^/components/peer-feedback/actions';
import { canSendReminder } from '^/components/peer-feedback/utils';

export function PeerFeedbackListItemCheckbox({isDisabled, toggleSelected, isSelected}) {
  return (
    <input
      type="checkbox"
      className="checkbox-color-text"
      disabled={isDisabled}
      onChange={toggleSelected}
      checked={isSelected}
    />
  );
}

function mapStateToProps(state, props) {
  return {
    isSelected: state.selectedListItems.includes(props.item.get('id')),
    isDisabled: (
      !canSendReminder(props.item) ||
      isPending(state.responses, SEND_PEER_FEEDBACK_REMINDERS)
    )
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    toggleSelected: () => {
      if (canSendReminder(props.item)) {
        dispatch(toggleListItemSelected(props.item.get('id')));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeerFeedbackListItemCheckbox);
