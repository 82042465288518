import React from 'react'; // eslint-disable-line no-unused-vars

export const ConnectButton = (props) => {
  if (props.hrIntegrationActive) {
    return (
      <div className="integration-card integration-card-active">
        <img src="/static/images/hr-logo-tile-transparent.png" alt="HR Logos" />
        <a href="https://www.apideck.com/hris-api"
          target="_blank"
          rel="noopener noreferrer"
        >
          See all HR integrations
        </a>
        <div className="integration-card-active-overlay">
          Active{props.hrConnectedName && ` - connected to ${props.hrConnectedName}`}
        </div>
      </div>
    );
  } else {
    return (
      <div className="integration-card" onClick={props.openModal}>
        <p>Connect your HR system.</p>
        <img src="/static/images/hr-logo-tile-transparent.png" alt="HR Logos" />
        <a href="https://www.apideck.com/hris-api"
          target="_blank"
          rel="noopener noreferrer"
        >
          See all HR integrations
        </a>
      </div>
    );
  }
};
