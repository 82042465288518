import moment from 'moment';
import React from 'react';  // eslint-disable-line no-unused-vars
import { rolesDisplay } from '^/roles';
import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';


export const bubbleUsersActivityListConfig = (
  selectedUsers,
  toggleUserSelection,
  sendBubbleReminders
) => [
  {
    name: 'select',
    display_name: 'Select',
    type: 'custom',
    customItemHandler: user => {
      const isSelected = selectedUsers.indexOf(user.id) !== -1;
      return (
        <input type="checkbox" onChange={() => toggleUserSelection(user.id)} checked={isSelected} />
      );
    }
  },
  {
    name: 'first_name',
    display_name: 'Name/Email',
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <span title={user.full_name} className="display-block text-overflow-ellipsis">
          {user.full_name}
        </span>
        <span title={user.email} className="display-block text-help text-overflow-ellipsis">
          {user.email}
        </span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'type',
    display_name: 'Role',
    type: 'custom',
    customItemHandler: user => rolesDisplay[user.type],
    sortable: true,
  },
  {
    name: 'team_member__team__name',
    display_name: 'Team',
    type: 'custom',
    customItemHandler: user => (
      <CellContentOrEmpty condition={Boolean(user.team)}>{user.team}</CellContentOrEmpty>
    ),
    sortable: true,
  },
  {
    name: 'assigned_actions_count',
    display_name: 'No. of actions assigned',
    type: 'custom',
    customItemHandler: user => (
      <CellContentOrEmpty condition={Boolean(user.assigned_actions_count)}>
        {user.assigned_actions_count}
      </CellContentOrEmpty>
    ),
    sortable: true,
  },
  {
    name: 'completed_actions_count',
    display_name: 'No. of actions completed',
    type: 'custom',
    customItemHandler: user => (
      <CellContentOrEmpty condition={Boolean(user.completed_actions_count)}>
        {user.completed_actions_count}
      </CellContentOrEmpty>
    ),
    sortable: true,
  },
  {
    name: 'last_login',
    display_name: 'Last login',
    type: 'custom',
    customItemHandler: user => user.last_login ? moment(user.last_login).format('D MMM YYYY') : 'Not logged in yet',
    sortable: true,
  },
  {
    name: 'activation_email',
    display_name: 'Activation email',
    type: 'custom',
    customItemHandler: user => {
      return !user.last_login && (
        <button
          className="btn orange hollow btn-block"
          onClick={() => sendBubbleReminders([user.id], 'activation')}
        >
          Re-send
        </button>
      );
    }
  },
  {
    name: 'login_reminder',
    display_name: 'Send login reminders',
    type: 'custom',
    customItemHandler: user => {
      return user.last_login && (
        <button
          className="btn orange hollow btn-block"
          onClick={() => sendBubbleReminders([user.id], 'login')}
        >
          Send
        </button>
      );
    }
  },
];
