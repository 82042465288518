import * as React from 'react';  // eslint-disable-line
import { openSimpleModal } from '^/actions/modals';
import DeleteCustomQuestionModal from './DeleteCustomQuestionModal';
import store from '^/store';

function openRemoveQuestionModal(event, question) {
  event.preventDefault();
  store.dispatch(openSimpleModal({
    body: (<DeleteCustomQuestionModal question={question} />),
    title: 'Do you want to delete the custom question?'
  }));
}

const RemoveQuestionCell = props => (
  <a
    onClick={(event) => openRemoveQuestionModal(event, props.question)}
    className="btn-icon btn-icon-widget-list orange margin-left-large"
    href=""
    title="Remove"
  >
    <i className="far fa-times" aria-hidden="true"></i>
  </a>
);

export default RemoveQuestionCell;
