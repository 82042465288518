import { request, makeAsyncActionSet } from '@dabapps/redux-requests';

export const SET_INCLUDES_RECENT_RESULTS_ON = makeAsyncActionSet('SET_INCLUDES_RECENT_RESULTS_ON');
export function setIncludesRecentResultsOn(surveyId) {
  return (dispatch) => {
    return request(
      SET_INCLUDES_RECENT_RESULTS_ON,
      `/api/survey/${surveyId}/set-includes-recent-results-on/`,
      'POST'
    )(dispatch);
  };
}

export const SET_INCLUDES_RECENT_RESULTS_OFF = makeAsyncActionSet('SET_INCLUDES_RECENT_RESULTS_OFF');
export function setIncludesRecentResultsOff(surveyId) {
  return (dispatch) => {
    return request(
      SET_INCLUDES_RECENT_RESULTS_OFF,
      `/api/survey/${surveyId}/set-includes-recent-results-off/`,
      'POST'
    )(dispatch);
  };
}
