import { Button, ModalRenderer } from '@dabapps/roe';
import { List } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { formValueSelector } from 'redux-form';
import _ from 'underscore';

import { getCustomQuestionSettings } from '^/actions/actions';
import {
  updateCustomQuestionSettingsAndToast,
  sendSurveyAndRedirectToPersonalReviewsForUser,
  sendTestEmailAndShowOutcome
} from '^/actions/actionSequences';
import { isPending } from '^/consts/responseStates';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import { getQueryParamsFromLocation } from '^/utils';
import { CUSTOM_QUESTION_SETTINGS } from './constants';
import { PERFORMANCE_REVIEW } from '^/consts/questionnaire';
import QuestionPicker from './question-picker/QuestionPicker';
import SurveyCustomQuestionsForm, {
  SURVEY_CUSTOM_QUESTIONS_FORM
} from './SurveyCustomQuestionsForm';
import { EMAIL_TEMPLATE_TYPES } from '../../settings/emails/constants';

const INPUT_CHANGE_DELAY = 500;

class SurveyCustomQuestions extends PureComponent {
  constructor(props) {
    super(props);
    this.onNextClick = this.onNextClick.bind(this);
  }

  componentDidMount() {
    this.props.getCustomQuestionSettings();
  }

  onNextClick() {
    if (this.props.hasCompletedRequiredFields) {
      const queryParams = getQueryParamsFromLocation();
      if (this.props.isIndividualReview && queryParams.hasOwnProperty('send_review_to_user')) {
        this.props.sendIndividualSurvey(
          this.props.surveyId,
          queryParams.send_review_to_user
        );
      } else {
        window.location.href = this.props.nextUrl;
      }
    } else {
      window.alert(
        'You must set a heading and select at least one question to continue.'
      );
    }
  }

  render() {
    const {
      isLoading,
      modals,
      nextText,
      readOnly,
      initialValues,
      surveyId,
      canPreviewQuestionnaire,
      hasCompletedRequiredFields,
      updateSettings,
      hasSendTestEmail,
      questionsRecommendedFor
    } = this.props;
    const previewUrl =
      questionsRecommendedFor === PERFORMANCE_REVIEW
        ? `/reviews/${surveyId}/preview`
        : `/surveys/${surveyId}/preview`;
    return (
      <div className="margin-vertical-large">
        <ModalRenderer modals={modals} />
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          hideProgressBar
          transition={Slide}
        />
        <div className="flex-space-between">
          <h4>Select and edit your question set</h4>
          <div>
            {hasSendTestEmail && (
              <div className="test-email-button">
                <p>Preview email notification</p>
                <a
                  className="link-orange"
                  onClick={() =>
                    this.props.sendTestEmailAndShowOutcome(
                      surveyId,
                      EMAIL_TEMPLATE_TYPES.SEND_QUESTIONNAIRE
                    )
                  }
                >
                  Send test email
                </a>
              </div>
            )}
            {canPreviewQuestionnaire && (
              <a
                className="btn orange input-height input-line-height
                  margin-left-xx-large padding-horizontal-large
                "
                href={hasCompletedRequiredFields ? previewUrl : undefined}
                target="_blank"
                disabled={!hasCompletedRequiredFields}
              >
                Preview question set
              </a>
            )}
          </div>
        </div>
        <p>
          Enter a title & short description, then select the questions you'd
          like to include by clicking the '+' icon next to each one.
          <br />
          The questions that will be included in your survey will appear under
          'Selected custom questions'. To remove any, click on the '-' icon next
          to each one.
        </p>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <SurveyCustomQuestionsForm
              initialValues={initialValues}
              surveyId={surveyId}
              onChange={updateSettings}
              readOnly={readOnly}
            />
            <QuestionPicker
              surveyId={surveyId}
              readOnly={readOnly}
              questionsRecommendedFor={questionsRecommendedFor}
            />
          </div>
        )}
        <hr className="margin-bottom-large margin-top-x-large border-base" />
        <Button className="primary" onClick={this.onNextClick}>
          {nextText}
        </Button>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, props) {
  const updateSettings = (data) => {
    window.onbeforeunload = null;
    if (props.readOnly) {
      return null;
    }
    return dispatch(updateCustomQuestionSettingsAndToast(props.surveyId, data));
  };
  return {
    getCustomQuestionSettings: () =>
      dispatch(getCustomQuestionSettings(props.surveyId)),
    updateSettings: _.debounce(updateSettings, INPUT_CHANGE_DELAY, false),
    sendTestEmailAndShowOutcome: (surveyId, templateType) =>
      dispatch(sendTestEmailAndShowOutcome(surveyId, templateType)),
    sendIndividualSurvey: (surveyId, userId) =>
      dispatch(sendSurveyAndRedirectToPersonalReviewsForUser(surveyId, userId)),
  };
}

function getHasCompletedRequiredFields(state, props) {
  const selectQuestionsFormValue = formValueSelector(
    SURVEY_CUSTOM_QUESTIONS_FORM
  );
  const hasHeading = Boolean(
    selectQuestionsFormValue(state, CUSTOM_QUESTION_SETTINGS.HEADING)
  );
  const hasAtLeastOneQuestion =
    state.surveyCustomQuestionSettings
      .get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, List())
      .count() > 0;
  return (
    !props.isCustomQuestionsStepMandatory ||
    (hasHeading && hasAtLeastOneQuestion)
  );
}

function mapStateToProps(state, props) {
  return {
    hasCompletedRequiredFields: getHasCompletedRequiredFields(state, props),
    isLoading: isPending(
      state.legacyResponses.get('getCustomQuestionSettings')
    ),
    modals: state.modals,
    initialValues: {
      [CUSTOM_QUESTION_SETTINGS.HEADING]:
        state.surveyCustomQuestionSettings.get(
          CUSTOM_QUESTION_SETTINGS.HEADING
        ) || '',
      [CUSTOM_QUESTION_SETTINGS.DESCRIPTION]:
        state.surveyCustomQuestionSettings.get(
          CUSTOM_QUESTION_SETTINGS.DESCRIPTION
        ) || '',
    },
  };
}

SurveyCustomQuestions.propTypes = {
  getCustomQuestionSettings: React.PropTypes.func.isRequired,
  updateSettings: React.PropTypes.func.isRequired,
  hasCompletedRequiredFields: React.PropTypes.bool,
  isCustomQuestionsStepMandatory: React.PropTypes.bool.isRequired,
  isLoading: React.PropTypes.bool,
  modals: ImmutablePropTypes.list,
  nextText: React.PropTypes.string.isRequired,
  nextUrl: React.PropTypes.string.isRequired,
  readOnly: React.PropTypes.bool,
  settings: React.PropTypes.object,
  surveyId: React.PropTypes.number.isRequired,
};

export { SurveyCustomQuestions };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyCustomQuestions);
