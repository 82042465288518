import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import CollapsableWell from '../common/CollapsibleWell';
import { SelectableTitleByType, AgendaSectionType } from './constants';
import AddOkrToAgendaCard from './AddOkrToAgendaCard';

export const OkrSelectItems = ({
  agendaId,
  isDisabled,
  selectableItems
}) => {
  return (
    <CollapsableWell
      title={SelectableTitleByType[AgendaSectionType.OKRS]}
      isOrange
      isCollapsed
    >
      {selectableItems.length ? selectableItems.map((item) => {
        return (
          <AddOkrToAgendaCard
            key={item.okr_id}
            agendaId={agendaId}
            item={item}
            isDisabled={isDisabled}
          />
        );
      })
      : <p>No OKRs available.</p>}
    </CollapsableWell>
  );
};

function mapStateToProps(state, props) {
  return {
    selectableItems: props.items.filter(option =>
      !state.editingAgenda.okrs.find(okr => (
        okr.okr_id === option.okr_id
      ))
    )
  };
}

export default connect(mapStateToProps, null)(OkrSelectItems);
