import React from 'react';  // eslint-disable-line
import classNames from 'classnames';
import { PAGE_SIZE_OPTIONS } from '../../consts/inputs';


const PageSize = ({
  pluralItemName,
  hasInlineLabels,
  pageSize,
  onChange,
}) => {
  const label = pluralItemName ? `${pluralItemName} Per Page` : 'Per Page';
  const labelClassName = classNames('line-height-16px', {
    'margin-right-base': hasInlineLabels,
    'label-inline': hasInlineLabels
  });
  return (
    <div className="margin-bottom-large">
      <label htmlFor="page-size" className={labelClassName}>{label}</label>
      <select
        id="page-size"
        className={classNames({'page-size-trigger': hasInlineLabels})}
        value={pageSize}
        onChange={event => onChange(parseInt(event.target.value, 10))}>
          {PAGE_SIZE_OPTIONS.map((pageSizeOption, index) => {
            return (
              <option
                key={`page-size-option-${index}`}
                value={pageSizeOption}
              >
                {pageSizeOption}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default PageSize;

