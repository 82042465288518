import * as React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Modal } from '@dabapps/roe';
import { Column, Row } from '@dabapps/roe';
import classNames from 'classnames';

export const OPEN_MODAL = 'OPEN_MODAL';
export function openModal(modal) {
  return {
    payload: modal,
    type: OPEN_MODAL
  };
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}

const ModalWrapperComponent = props => {
  const {
    config: {
      title,
      titleComponent,
      body,
      large,
      bodyClassName,
      closeButtonExtraClassName
    }
  } = props;

  return (
    <Modal scrollable onClickOutside={props.closeModal} large={large}>
      <Row className="margin-top-large">
        <Column xs={12} md={11}>
          {
            titleComponent ||
            <h4 className="font-family-varela-round font-weight-normal font-size-24 margin-bottom-none">
              {title || ''}
            </h4>
          }
        </Column>
        <Column xs={12} md={1}>
          <a
            className="toggle-inclusion-icon float-right"
            onClick={props.closeModal}
          >
            <i className={classNames('far', 'fa-times', closeButtonExtraClassName)}></i>
          </a>
        </Column>
      </Row>
      <Row>
        <Column xs={12} md={12} className={bodyClassName}>
          {body}
        </Column>
      </Row>
    </Modal>
  );
};

export const ModalWrapper = connect(undefined, {closeModal})(ModalWrapperComponent);

export function openSimpleModal(config) {
  return openModal(<ModalWrapper config={config} />);
}

export function openMediumModal(config) {
  return openModal(
    <div className="medium-modal">
      <ModalWrapper config={config} />
    </div>
  );
}
