import React from 'react';
import classNames from 'classnames';

export default class CollapsibleWell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: props.isCollapsed
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState({isCollapsed: !this.state.isCollapsed});
  }

  render() {
    const { title, isOrange } = this.props;
    const { isCollapsed } = this.state;

    return (
      <div className={
        classNames(
          'collapsible-well',
          'margin-bottom-xx-large',
          {'orange' : isOrange}
        )
      }>
        <div className="collapsible-well-container">
          <button className={
            classNames(
              'collapsible-well-header',
              'margin-auto-horizontal',
              'flex-space-between',
              'align-items-center',
              'padding-large'
            )
          } onClick={this.toggleCollapse}>
            <div className="collapsible-well-header-title">
              <h1 className="font-size-18 font-family-effra-bold margin-bottom-none">{title}</h1>
            </div>
            <div className="collapsible-well-header-button" aria-hidden>
              <i
                className={
                  classNames(
                    'far font-size-24',
                    {
                      'fa-angle-down' : isCollapsed,
                      'fa-angle-up': !isCollapsed
                    }
                  )
                }
              >
              </i>
            </div>
          </button>
          <div className={classNames(
            'collapsible-well-content-container',
            {'hidden' : this.state.isCollapsed}
          )}>
            <div className="collapsible-well-content margin-vertical-large margin-auto-horizontal">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
