import { ModalFooter, SpacedGroup } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { deleteResourceCloseModalAndReload } from '^/actions/actionSequences';

export const DeleteGoalModal = props => (
  <ModalFooter>
    <SpacedGroup block className="margin-vertical-base">
      <button className="btn gray middle" onClick={props.closeModal}>
        Cancel
      </button>
      <button className="btn danger middle" onClick={props.deleteResource}>
        Delete
      </button>
    </SpacedGroup>
  </ModalFooter>
);

export { DeleteGoalModal as UnconnectedDeleteGoalModal };

export function mapDispatchToProps(dispatch, props) {
  return {
    closeModal: () => dispatch(closeModal()),
    deleteResource: () => dispatch(deleteResourceCloseModalAndReload(
      props.resource,
      props.reloadPage
    )),
  };
}

export default connect(null, mapDispatchToProps)(DeleteGoalModal);
