import USER_ROLES from '^/roles';

export function canAccessAdmin(loggedInUser) {
  return Boolean(loggedInUser) && loggedInUser.get('is_staff');
}

export function isEmployeeBubbleUser(loggedInUser) {
  return Boolean(loggedInUser) && loggedInUser.get('type') === USER_ROLES.EMPLOYEE_WITH_BUBBLE;
}

export function isManagerUser(loggedInUser) {
  return Boolean(loggedInUser) && loggedInUser.get('type') === USER_ROLES.MANAGER_USER;
}

export function userMeetsRequiredRole(loggedInUser, requiredRole) {
  const roles = [
    USER_ROLES.EMPLOYEE,
    USER_ROLES.EMPLOYEE_WITH_BUBBLE,
    USER_ROLES.MANAGER_USER,
    USER_ROLES.MANAGER_USER_WITH_ADMIN,
    USER_ROLES.EXECUTIVE,
    USER_ROLES.PARTNER,
    USER_ROLES.SUPPORT_USER,
    USER_ROLES.SYSTEM_ADMIN,
  ];
  const roleLevel = roles.indexOf(loggedInUser.get('type'));
  const requiredRoleLevel = roles.indexOf(requiredRole);

  if (roleLevel === -1 || requiredRoleLevel === -1) {
    return false;
  }

  return roleLevel >= requiredRoleLevel;
};
