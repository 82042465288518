export const REMINDER_TYPES = {
  ACTIVATION: 'activation',
  LOGIN: 'login',
  ACTION: 'action',
};

export const REMINDER_ALL_MESSAGE = {
  activation: 'users who have not activated their logins yet',
  login: 'active users',
  action: 'active users who haven\'t created any actions yet',
};

export const REMINDER_EMAIL_TEMPLATE_URLS = {
  ACTIVATION_EMAIL_TEMPLATE_URL: 'https://intercom.help/wethrive/en/articles/6228008-re-send-activation-email-template',
  LOGIN_EMAIL_TEMPLATE_URL: 'https://intercom.help/wethrive/en/articles/6228015-log-in-reminder-email-template',
  ACTION_EMAIL_TEMPLATE_URL: 'https://intercom.help/wethrive/en/articles/6228035-action-reminder-email-template',
};

