import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, fromJS } from 'immutable';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils';
import {
  addInSurveyAndUpdate,
  removeFromSurveyAndUpdate,
} from '^/actions/actionSequences';
import { surveySelectAll, surveyRemoveAll } from '^/actions/actions';
import ChadminList from '^/components/common/ChadminList';
import FilterSelect from '^/components/common/FilterSelect';
import PureComponent from '^/components/PureComponent';
import { isPending } from '^/consts/responseStates';
import moment from 'moment';
import { FORMAT_DATE } from '../../utils';
import ToggleIncludeRecentResults from './ToggleIncludeRecentResults';


export const LatestCompletionCell = (props) => {
  const latestResponse = props.teamMember.latest_response_within_last_3_months;

  if (!latestResponse) {
    return <div className="text-gray">Not taken in last 3 months</div>;
  }

  if (!latestResponse.completion_date) {
    return (
      <div>
        <div>within last 3 months</div>
        <div className="font-size-smaller margin-top-small display-block text-gray">
          {latestResponse.is_self_initiated ? 'self-intiated' : 'scheduled'}
        </div>
      </div>
    );
  }

  const completedDate = moment(latestResponse.completion_date);

  return (
    <div>
      <div>{completedDate.fromNow()}</div>
      <div className="font-size-smaller margin-top-small display-block text-gray">
        on {completedDate.format(FORMAT_DATE)} - {latestResponse.is_self_initiated ? 'self-intiated' : 'scheduled'}
      </div>
    </div>
  );
};

const COLUMNS = fromJS([
  {
    name: 'user__first_name',
    display_name: 'Name/Email',
    type: 'custom',
    sortable: true,
    customItemHandler: teamMember => (
      <div>
        <div className="display-block">{teamMember.display_name}</div>
        <div className="font-size-smaller margin-top-small display-block text-gray">{teamMember.name}</div>
      </div>
    )
  },
  {
    name: 'completed',
    display_name: 'Completed?',
    type: 'custom',
    sortable: true,
    customItemHandler: teamMember => <LatestCompletionCell teamMember={teamMember} />
  },
  {
    name: 'team',
    display_name: 'Team',
    type: 'text',
    sortable: true
  },
  {
    name: 'location_filter',
    display_name: 'Location',
    type: 'text',
    sortable: true
  },
  {
    name: 'department_filter',
    display_name: 'Department',
    type: 'text',
    sortable: true
  },
  {
    name: 'unit_filter',
    display_name: 'Unit',
    type: 'text',
    sortable: true
  },
  {
    name: 'manager_filter',
    display_name: 'Manager',
    type: 'text',
    sortable: true
  },
]);

export const SETTINGS_TEAM_MEMBERS = 'SETTINGS_TEAM_MEMBERS';

const FilterSelects = ({setFilters, filterOptions, collectionFilters, organisationFilterTerms}) => (
  <div>
    <FilterSelect
      setFilters={setFilters}
      filterKeyName="team_id"
      options={filterOptions.teams}
      value={collectionFilters.team_id}
      displayName={organisationFilterTerms.team}
    />
    <FilterSelect
      setFilters={setFilters}
      filterKeyName={'location_filter_id'}
      options={filterOptions.locations}
      value={collectionFilters.location_filter_id}
      displayName={organisationFilterTerms.location}
    />
    <FilterSelect
      setFilters={setFilters}
      filterKeyName={'department_filter_id'}
      options={filterOptions.departments}
      value={collectionFilters.department_filter_id}
      displayName={organisationFilterTerms.department}
    />
    <FilterSelect
      setFilters={setFilters}
      filterKeyName={'unit_filter_id'}
      options={filterOptions.units}
      value={collectionFilters.unit_filter_id}
      displayName={organisationFilterTerms.unit}
    />
    <FilterSelect
      setFilters={setFilters}
      filterKeyName={'manager_filter_id'}
      options={filterOptions.managers}
      value={collectionFilters.manager_filter_id}
      displayName={organisationFilterTerms.manager}
    />
  </div>
);

export class SettingsUserList extends PureComponent {

  constructor() {
    super();
    this.checkboxClicked = this.checkboxClicked.bind(this);
    this.renderMemberSelect = this.renderMemberSelect.bind(this);
    this.updateColumnsWithCustomTerms = this.updateColumnsWithCustomTerms.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.removeAll = this.removeAll.bind(this);
  }

  selectAll() {
    this.props.surveySelectAll(this.props.surveyId, this.props.userCollection.get('filters'));
  }

  removeAll() {
    this.props.surveyRemoveAll(this.props.surveyId);
  }

  checkboxClicked(teamMember) {
    if (teamMember.is_survey_member) {
      this.props.removeFromSurveyAndUpdate(this.props.surveyId, [teamMember.id], teamMember);
    } else {
      this.props.addInSurveyAndUpdate(this.props.surveyId, [teamMember.id], teamMember);
    }
  }

  renderMemberSelect(teamMember) {
    let isDisabled = teamMember.survey_sent || this.props.isClosed;
    let isChecked = teamMember.is_survey_member;

    if (this.props.survey.get('is_running_recent_results_job')) {
      const hasMemberCompletedInLast3Months = (
        teamMember.latest_response_within_last_3_months &&
        teamMember.latest_response_within_last_3_months.completed_before_include_recent_results
      );
      const shouldForceCheck = this.props.survey.get('includes_users_completed_within_last_3_months')
        && hasMemberCompletedInLast3Months;

      isDisabled = isDisabled || shouldForceCheck;
      isChecked = isChecked || shouldForceCheck;
    }
    return (
      <input
        disabled={isDisabled}
        type="checkbox"
        onClick={() => this.checkboxClicked(teamMember)}
        checked={isChecked}
      />
    );
  }

  updateColumnsWithCustomTerms(organisationFilterTerms) {
    return COLUMNS.insert(0, fromJS({
      name: 'is_member',
      display_name: 'Selected',
      type: 'custom',
      customItemHandler: this.renderMemberSelect,
      sortable: false
    })).update(3, teamColumn => {
      return teamColumn.set('display_name', capitalizeFirstLetter(organisationFilterTerms.team));
    }).update(4, locationColumn => {
      return locationColumn.set('display_name', capitalizeFirstLetter(organisationFilterTerms.location));
    }).update(5, departmentColumn => {
      return departmentColumn.set('display_name', capitalizeFirstLetter(organisationFilterTerms.department));
    }).update(6, unitColumn => {
      return unitColumn.set('display_name', capitalizeFirstLetter(organisationFilterTerms.unit));
    }).update(7, unitColumn => {
      return unitColumn.set('display_name', capitalizeFirstLetter(organisationFilterTerms.manager));
    });
  }

  render() {
    const {
      filterOptions,
      selectingAll,
      removingAll,
      canIncludeRecentResponses,
      showHelpText = false,
      organisationFilterTerms
    } = this.props;
    return (
      <div>
        <ChadminList
          columns={this.updateColumnsWithCustomTerms(organisationFilterTerms).toJS()}
          collectionPath={`survey/${this.props.surveyId}/team-members`}
          collectionName={SETTINGS_TEAM_MEMBERS}
          ExtraControlsLeft={() => (
            <div>
              {canIncludeRecentResponses && (
                <ToggleIncludeRecentResults />
              )}
              {canIncludeRecentResponses && showHelpText && (
                <p className="invite-text">
                  Selected individuals will not receive new invites, but their
                  previous responses will be included in your results.
                </p>
              )}
              <button
                key="select"
                onClick={this.selectAll}
                className="btn orange middle"
                disabled={this.props.isClosed}
              >
                {selectingAll ? 'Selecting...' : 'Select all'}
              </button>
              <button
                key="remove"
                onClick={this.removeAll}
                className="btn orange middle"
                disabled={this.props.isClosed}
              >
                {removingAll ? 'Removing...' : 'Remove all'}
              </button>
            </div>
          )}
          ExtraControlsRight={({setFilters}) => (
            <FilterSelects
              setFilters={setFilters}
              filterOptions={filterOptions}
              collectionFilters={this.props.userCollection.get('filters', {})}
              organisationFilterTerms={organisationFilterTerms}
            />
          )}
          defaultFilters={{ordering: 'user__first_name'}}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    userCollection: state.collections.get(SETTINGS_TEAM_MEMBERS, Map()),
    selectingAll: isPending(state.legacyResponses.get('selectAllMembers')),
    removingAll: isPending(state.legacyResponses.get('removeAllMembers')),
    survey: state.survey.get('survey', Map())
  };
}

export default connect(
  mapStateToProps,
  {
    addInSurveyAndUpdate,
    removeFromSurveyAndUpdate,
    surveySelectAll,
    surveyRemoveAll,
  }
)(SettingsUserList);
