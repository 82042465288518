import React from 'react';  //eslint-disable-line no-unused-vars
import { Row, Column } from '@dabapps/roe';

import FurtherInsightsForSubsection from '../../analysis/FurtherInsightsForSubsection';
import SuggestedActions from './SuggestedActions';
import withExpandableRecommendation from '../../analysis/withExpandableRecommendation';

export function Recommendation (props) {
  const { recommendation, suggestions, isExpanded, toggleExpanded } = props;
  const expandIcon = isExpanded ? 'fa-chevron-up' : 'fa-chevron-down';

  const onToggleExpandEventHandler = (e) => {
    const target = e.target;
    const attributeName = 'aria-expanded';
    if (target.hasAttribute(attributeName)) {
      target.setAttribute(attributeName, !isExpanded);
    }
    toggleExpanded && toggleExpanded();
  };

  const headingName = `${recommendation.get('section')} - ${recommendation.get('subsection')}`;

  return (
      <div>
        <hr className="margin-none" />
        <button
          className="analysis-expandable-section-header"
          onClick={onToggleExpandEventHandler}
          aria-expanded="false"
          aria-label={headingName}
        >
          <strong>{headingName}</strong>
          <i className={`margin-left-base text-orange far ${expandIcon}`} aria-hidden="true"></i>
        </button>

        {isExpanded && (
          <div className="analysis-expandbale-section-content">
            {!props.isIndividual && (
              <FurtherInsightsForSubsection
                suggestions={suggestions}
                recommendation={recommendation}
              />
            )}
            <Row>
              <Column md={6}>
                <SuggestedActions
                  question={recommendation.get('top_subquestion')}
                  recommendation={recommendation}
                  suggestions={suggestions}
                  isIndividual={props.isIndividual}
                />
              </Column>
              <Column md={6}>
                <SuggestedActions
                  question={recommendation.get('second_subquestion')}
                  recommendation={recommendation}
                  suggestions={suggestions}
                  isIndividual={props.isIndividual}
                />
              </Column>
            </Row>
          </div>
        )}
    </div>
  );
}

export default withExpandableRecommendation(Recommendation);
