import * as React from 'react';  // eslint-disable-line
import { QUESTION_TYPE_DISPLAY, QUESTION_TYPES } from '^/components/custom-questions/constants';
import RemoveQuestionCell from './RemoveQuestionCell';
import EditQuestionCell from './EditQuestionCell';

export const customQuestionsConfig = [
  {
    name: 'name',
    display_name: 'Question',
    type: 'text',
    sortable: false,
  },
  {
    name: 'type',
    display_name: 'Type',
    type: 'custom',
    customItemHandler: (question) => {
      const base = QUESTION_TYPE_DISPLAY[question.type];
      const low = question.slider_low_score_label;
      const mid = question.slider_mid_score_label;
      const high = question.slider_high_score_label;
      return question.type === QUESTION_TYPES.SLIDER ? `${base} (${low}/${mid}/${high})` : base;
    },
    sortable: false,
  },
  {
    name: 'created_by',
    display_name: 'Created by',
    type: 'text',
    sortable: false,
  },
  {
    name: 'remove',
    display_name: 'Remove?',
    type: 'custom',
    customItemHandler: (question) => <RemoveQuestionCell question={question} />,
    sortable: false,
  },
  {
    name: 'edit',
    display_name: 'Edit',
    type: 'custom',
    customItemHandler: (question) => <EditQuestionCell question={question} />,
    sortable: false,
  },
];
