import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import PureComponent from '../PureComponent';
import moment from 'moment';


const PLUS = 'PLUS';
const MINUS = 'MINUS';


export default class AsyncTimePicker extends PureComponent {
  getClassName() {
    return 'async-datepicker' + (
      this.props.readOnly ? ' read-only' : ''
    );
  }

  handleClick(action) {
    const currentValue = moment(this.props.value, 'HH:mm');
    const newDate = (action === PLUS
      ? currentValue.add(30, 'minutes')
      : currentValue.subtract(30, 'minutes')
    ).format('HH:mm');
    return this.props.handleClick(this.props.id, newDate);
  }

  render() {
    return <div className={this.getClassName()}>
      {this.props.readOnly ? <div>
        <label>{this.props.label}</label> {this.props.value}
      </div> : <div>
        <label>{this.props.label}</label>
        {!moment(this.props.value, 'HH:mm').isSame(moment(), 'minute') && (
          <i onClick={() => this.handleClick(MINUS)} className="far fa-minus-square" />
        )}
        <span className="date">{this.props.value}</span>
        <i onClick={() => this.handleClick(PLUS)} className="far fa-plus-square" />
      </div>}
    </div>;
  }
}

AsyncTimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func
};
