import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { closeModal } from '^/actions/modals';
import PeerFeedbackCard from './PeerFeedbackCard';
import {
  createAgendaPeerFeedbackAndToast,
  deleteAgendaPeerFeedbackAndToast,
} from './actions';

export const PeerFeedbackModal = ({
  item,
  updateAgenda,
  isDisabled,
  close,
  isRemoving,
  isReadOnly,
}) => {
  return (
    <div>
      <ModalBody>
        <PeerFeedbackCard
          item={item}
          isDisabled={isDisabled}
          updateAgenda={updateAgenda}
          isRemoving={isRemoving}
          isReadOnly={isReadOnly}
        />
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button onClick={close}>Close</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    close: () => {
      dispatch(closeModal());
    },
    updateAgenda: () => {
      if (ownProps.isRemoving) {
        dispatch(deleteAgendaPeerFeedbackAndToast(ownProps.agendaId, ownProps.item.id, true));
      } else {
        dispatch(createAgendaPeerFeedbackAndToast(ownProps.agendaId, ownProps.item.feedback_id, true));
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(PeerFeedbackModal);
