import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { ActionButton } from './AgendaItem';
import {
  expandAgendaSection,
  collapseAgendaSection,
  updateAgendaSectionPositionAndToast
} from '^/components/agendas/actions';
import { AgendaSectionType } from '^/components/agendas/constants';

export function AgendaSectionWrapper({
    isDisabled,
    isFirst,
    isLast,
    title,
    moveSectionUp,
    moveSectionDown,
    sectionType,
    children,
    isExpanded,
    expand,
    collapse,
    hideSection
}) {
  if (hideSection) {
    return null;
  }

  const isManagerSection = sectionType === AgendaSectionType.MANAGER_ONLY;
  return (
    <div
      className={
        classNames(
          'section-create-agenda-padding',
          {
            'section-create-agenda': !isManagerSection,
            'background-light-orange': isManagerSection,
          }
        )
      }
    >
      <div
        className={
          classNames(
            'display-flex align-items-center',
            {
              'margin-bottom-large': isExpanded,
            }
          )
        }
      >
        <button
          className="cursor-pointer padding-none background-none display-flex align-items-center color-text"
          aria-hidden
          onClick={isExpanded ? collapse : expand}
        >
          <i
            className={
              classNames(
                'far font-size-24',
                {
                  'fa-angle-down' : !isExpanded,
                  'fa-angle-up': isExpanded
                }
              )
            }
          ></i>
          <span className="font-size-18 font-weight-bold margin-left-large">{title}</span>
        </button>
        {!isManagerSection && (
          <div className="margin-left-auto display-flex">
            <ActionButton
              label="Move up"
              isDisabled={isFirst || isDisabled}
              handler={moveSectionUp}
              icon="arrow-up"
            />
            <ActionButton
              label="Move down"
              isDisabled={isLast || isDisabled}
              handler={moveSectionDown}
              icon="arrow-down"
              className="margin-left-small"
            />
          </div>
        )}
      </div>
      {isExpanded && children}
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  isExpanded: state.expandedAgendaSections.includes(props.sectionType)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    moveSectionUp: () => {
      const data = {
        sectionType: ownProps.sectionType,
        position: ownProps.position - 1
      };
      dispatch(updateAgendaSectionPositionAndToast(ownProps.agendaId, data));
    },
    moveSectionDown: () => {
      const data = {
        sectionType: ownProps.sectionType,
        position: ownProps.position + 1
      };
      dispatch(updateAgendaSectionPositionAndToast(ownProps.agendaId, data));
    },
    expand: () => dispatch(expandAgendaSection(ownProps.sectionType)),
    collapse: () => dispatch(collapseAgendaSection(ownProps.sectionType))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaSectionWrapper);
