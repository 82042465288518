import { isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import RemoteUserSelect from '^/components/forms/RemoteUserSelect';

import { closeModal } from '^/actions/modals';
import { createPeerFeedbackAndCloseModal, CREATE_PEER_FEEDBACK } from './actions';
import { PEER_FEEDBACK_TYPES, feedbackTypeMessageMap } from './constants';

const FORM_NAME = 'PeerFeedbackModalForm';

const UserSelectField = props => (
  <RemoteUserSelect
    onChange={props.input.onChange}
    selectId="feedback-modal-form"
    placeholder="Who do you want to give feedback to?"
    optionFilters={{'peer_feedback_users': true}}
    required
  />
);

export const PeerFeedbackModalForm = ({
  close,
  handleSubmit,
  selectedFeedbackType,
  isSendingFeedback,
  setTypeRecognition,
  setTypeSuggestions,
  setTypeGeneral
}) => {
  return (
    <div className="modal-body padding-x-large font-family-varela-round">
      <div className="display-flex align-items-center margin-top-large">
        <div className="font-size-14 width-150px">
          Type:
        </div>
        <button
          onClick={setTypeRecognition}
          className={
            classNames(
              'pink-pill-button font-size-14 text-align-center margin-bottom-none',
              {
                'active': selectedFeedbackType === PEER_FEEDBACK_TYPES.RECOGNITION
              }
            )
          }
        >
          Recognition
        </button>
        <button
          onClick={setTypeSuggestions}
          className={
            classNames(
              'pink-pill-button margin-left-x-large font-size-14 text-align-center margin-bottom-none',
              {
                'active': selectedFeedbackType === PEER_FEEDBACK_TYPES.SUGGESTIONS
              }
            )
          }
        >
          Suggestions
        </button>
        <button
          onClick={setTypeGeneral}
          className={
            classNames(
              'pink-pill-button margin-left-x-large font-size-14 text-align-center margin-bottom-none',
              {
                'active': selectedFeedbackType === PEER_FEEDBACK_TYPES.GENERAL
              }
            )
          }
        >
          General
        </button>
      </div>
      <form onSubmit={handleSubmit} disabled={isSendingFeedback}>
        <div className="display-flex margin-top-xx-large">
          <div className="font-size-14 width-150px flex-shrink-0 input-line-height">
            Person:
          </div>
          <div className="flex-grow">
            <Field
              name="user"
              id="user"
              component={UserSelectField}
            />
          </div>
        </div>
        <div className="display-flex margin-top-xx-large">
          <div className="font-size-14 width-150px flex-shrink-0 input-line-height">
            Title:
          </div>
          <div className="flex-grow">
            <Field
              name="title"
              id="title"
              component="input"
              type="text"
              placeholder="Add title here..."
              required
            />
          </div>
        </div>
        <div className="display-flex margin-top-xx-large">
          <div className="font-size-14 width-150px flex-shrink-0 input-line-height">
            Message:
          </div>
          <div className="position-relative flex-grow">
            <Field
              name="message"
              id="message"
              component="textarea"
              className="feedback-text-area padding-top-none"
              placeholder="Add description here..."
              required
            />
            <span className="
              feedback-message-question full-width position-absolute font-size-14
              padding-base line-height-16px left-0 top-0
            ">
              {feedbackTypeMessageMap[selectedFeedbackType]}
            </span>
            <p className="position-absolute right-0 bottom-minus-20 font-size-12">Click and drag to resize</p>
          </div>
        </div>
        <div className="display-flex margin-top-xxx-large font-size-14">
          This feedback is not anonymous - the receiver will know who sent this, so please think about wording and tone.
        </div>
        <div className="margin-top-x-large text-align-right">
          <button
            className="pink-button"
            type="submit"
            disabled={isSendingFeedback}
          >
            Give feedback
          </button>
          <button
            className="pink-border-button margin-left-x-large"
            onClick={close}
            disabled={isSendingFeedback}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const initialValues = {
  type: PEER_FEEDBACK_TYPES.RECOGNITION
};

export const FormifiedPeerFeedbackModal = reduxForm({
  form: FORM_NAME,
  initialValues,
})(PeerFeedbackModalForm);

const mapStateToProps = (state) => {
  return {
    isSendingFeedback: isPending(state.responses, CREATE_PEER_FEEDBACK),
    selectedFeedbackType: formValueSelector(FORM_NAME)(state, 'type')
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  setTypeRecognition: () => dispatch(change(FORM_NAME, 'type', PEER_FEEDBACK_TYPES.RECOGNITION)),
  setTypeSuggestions: () => dispatch(change(FORM_NAME, 'type', PEER_FEEDBACK_TYPES.SUGGESTIONS)),
  setTypeGeneral: () => dispatch(change(FORM_NAME, 'type', PEER_FEEDBACK_TYPES.GENERAL)),
  close: (event) => {
    event.preventDefault();
    dispatch(closeModal());
  },
  onSubmit: (data) => {
    dispatch(createPeerFeedbackAndCloseModal(Object.assign({}, data, {
      user: data.user && data.user.value
    }), props.reloadPage));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormifiedPeerFeedbackModal);
