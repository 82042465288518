import copyToClipboard from 'copy-to-clipboard';
import React from 'react';
import parseUrl from 'url-parse';

export default class CopyToClipboard extends React.PureComponent {
  constructor (props) {
    super(props);

    this.onClick = (event) => {
      event.preventDefault();

      const { href, value } = this.props;

      if (href) {
        copyToClipboard(parseUrl(href).href);
      } else if (value) {
        copyToClipboard(value);
      }
    };
  }

  render () {
    const {
      Component = 'a',
      children,
      className,
      title,
      disabled
    } = this.props;

    return (
      <Component className={className} onClick={this.onClick} title={title} disabled={disabled}>
        {children}
      </Component>
    );
  }
}
