import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import CollapsableWell from '^/components/common/CollapsibleWell';
import { addAgendaTemplatePartAndToast } from '^/components/agendas/actions';
import AgendaItemCard from '^/components/agendas/AgendaItemCard';
import { SelectableTitleByType, AgendaSectionType } from '^/components/agendas/constants';
import { getTemplateItemsMapping } from '^/components/agendas/templates';
import { splitSelectedTemplateParts } from '^/components/agendas/utils';

export const SelectableItem = ({
  item,
  isDisabled,
  addItem
}) => {
  return (
    <AgendaItemCard
      title={item.text}
      onClick={addItem}
      isDisabled={isDisabled}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addItem: () => dispatch(addAgendaTemplatePartAndToast(
      ownProps.agendaId,
      ownProps.item.id,
      ownProps.sectionType
    ))
  };
};

const ConnectedSelectableItem = connect(undefined, mapDispatchToProps)(SelectableItem);

export const EditAgendaTemplateSelect = ({
  agendaId,
  isDisabled,
  selectableItems,
  sectionType,
  performanceReviewTerm
}) => {
  const title = sectionType === AgendaSectionType.PERFORMANCE_REVIEW
    ? `Pre-${performanceReviewTerm.toLowerCase()} responses`
    : SelectableTitleByType[sectionType];
  return (
    <CollapsableWell
      title={title}
      isOrange
      isCollapsed
    >
      {selectableItems.length ? selectableItems.map(item => (
        <ConnectedSelectableItem
          key={`${item.id}-select`}
          agendaId={agendaId}
          item={item}
          sectionType={sectionType}
          isDisabled={isDisabled}
        />
      )) : (
        <p>None available.</p>
      )}
    </CollapsableWell>
  );
};

function mapStateToProps(state, props) {
  const selectedTemplateParts = splitSelectedTemplateParts(state.editingAgendaTemplate.selected_template_parts);
  const items = getTemplateItemsMapping(props.performanceReviewTerm)[props.sectionType];
  return {
    selectableItems: selectedTemplateParts ?
      items.filter(item => !selectedTemplateParts.includes(item.id))
      : items
  };
}

export default connect(mapStateToProps)(EditAgendaTemplateSelect);
