import { isPending } from '@dabapps/redux-requests';
import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import URI from 'urijs';

import { getManagerOptions, GET_MANAGER_OPTIONS } from '^/actions/actions';
import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { getQueryParamsFromLocation, stripFalsyValuesFromObject } from '^/utils';
import roles from '^/roles';
import { userMeetsRequiredRole } from '^/utils/permissions';

import { goalsListConfig } from './config';
import { GOALS_PATH } from './const';


function getSearchFields(isEmployeeBubbleUser) {
  if (isEmployeeBubbleUser) {
    return 'name';
  }
  return 'created_by__first_name,created_by__last_name,owner__first_name,owner__last_name';
}

function ExtraControlsLeft({exportUrl, canRunExports}) {
  if (canRunExports) {
    return (
      <div>
        <a className="btn orange" href="/goals/create">Create goal</a>
        <a
          href={exportUrl}
          className="btn orange"
        >
          Export goals
        </a>
      </div>
    );
  }
  return (
    <div>
      <a className="btn orange" href="/goals/create">Create goal</a>
    </div>
  );
}

export class GoalsList extends React.PureComponent {
  componentDidMount() {
    if (!this.props.isEmployeeBubbleUser) {
      this.props.getManagerOptions();
    }
  }

  render() {
    const { exportUrl, managerOptions, isEmployeeBubbleUser, isLoadingManagerOptions, canRunExports } = this.props;

    return (
      <div className="action-list will">
        <ChadminList
          collectionPath={GOALS_PATH}
          collectionName={GOALS_PATH}
          defaultFilters={{
            ordering: '-created',
            search_fields: getSearchFields(isEmployeeBubbleUser)
          }}
          className="actions-list"
          getColumns={() => goalsListConfig}
          ExtraControlsLeft={() => (
            <ExtraControlsLeft exportUrl={exportUrl} canRunExports={canRunExports} />
          )}
          ExtraControlsRight={() => (
            <div>
              <UriFilterSelect
                filterName="completed"
                name="completed"
                label="Completed"
                options={[
                  {value: '', label: 'All goals'},
                  {value: 'completed', label: 'Completed'},
                  {value: 'not-completed', label: 'Not completed'}
                ]}
                hideBlankOption
              />
              {!isEmployeeBubbleUser && (
                <UriFilterSelect
                  filterName="visible_for_manager"
                  name="visible_for_manager"
                  label="Reports To"
                  options={managerOptions}
                  disabled={isLoadingManagerOptions}
                  hideBlankOption
                />
              )}
            </div>
          )}
          searchLabel={!isEmployeeBubbleUser && 'Search Owner'}
          searchable
          pullSearchRight
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const filtersFromLocation = getQueryParamsFromLocation();
  const searchFields = getSearchFields(props.isEmployeeBubbleUser);
  const {
    searchString,
    ordering,
    page,
    pageSize,
  } = state.collections.get(GOALS_PATH, Map()).toObject();
  const uri = new URI('/api/goals/list-export/');

  uri.search(stripFalsyValuesFromObject(Object.assign(
    {},
    filtersFromLocation,
    {
      ordering,
      page,
      page_size: pageSize,
      search: searchString,
      search_fields: searchString && searchFields,
    }
  )));

  return {
    managerOptions: [
      {value: '', label: 'All team goals'},
      ...state.managerOptions.toJS()
    ],
    isLoadingManagerOptions: isPending(state.responses, GET_MANAGER_OPTIONS),
    exportUrl: uri.resource(),
    canRunExports: userMeetsRequiredRole(state.loggedInUser, roles.MANAGER_USER),
  };
}

export default connect(mapStateToProps, {
  getManagerOptions
})(GoalsList);
