import React from 'react'; // eslint-disable-line no-unused-vars

import {
  CustomItemsNameMappedToSectionType,
  AgendaSectionType,
  AgendaSectionTypeTitleMap
} from '^/components/agendas/constants';
import { getTemplateItemsMapping } from '^/components/agendas/templates';
import { splitSelectedTemplateParts } from '^/components/agendas/utils';

function AgendaPreviewSection({
  sectionType,
  agenda,
  performanceReviewTerm,
}) {
  const title = (
    sectionType === AgendaSectionType.PERFORMANCE_REVIEW ? performanceReviewTerm
    : AgendaSectionTypeTitleMap[sectionType]
  );
  const commentsName = CustomItemsNameMappedToSectionType[sectionType];
  const comments = agenda[commentsName];
  const templateItems = getTemplateItemsMapping()[sectionType];
  const selectedTemplateItems = (
    splitSelectedTemplateParts(agenda.selected_template_parts) || []
  ).reduce((accumulator, part) => {
    const templateItem = templateItems.find(item => item.id === part);
    return templateItem ? [...accumulator, templateItem] : accumulator;
  }, []);

  return (
    <div>
      <h4 className="font-family-effra-bold font-size-18 margin-top-x-large margin-bottom-none">{title}:</h4>
      {selectedTemplateItems.length ? (
        <ul className="margin-top-small font-size-14">
          {selectedTemplateItems.map((selectedTemplateItem) => (
            <li
              key={selectedTemplateItem.id}
              className="margin-bottom-small"
            >
              {selectedTemplateItem.text}
            </li>
          ))}
        </ul>
      ) : (
        <p className="margin-vertical-small font-size-14">None selected</p>
      )}
      <p className="margin-top-base margin-bottom-small font-size-14 underline">Comments:</p>
      {comments.length ? (
        <ul className="font-size-14">
          {comments.map((agendaItem) => (
            <li
              key={agendaItem.id}
              className="margin-bottom-small white-space-pre-line"
            >
              {agendaItem.text}
            </li>
          ))}
        </ul>
      ) : (
        <p className="margin-none font-size-14">No comments</p>
      )}
    </div>
  );
}

export default AgendaPreviewSection;
