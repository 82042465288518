import React from 'react';
import { isPending } from '@dabapps/redux-requests';
import { connect } from 'react-redux';
import {
  createSuggestedActions,
  CREATE_SUGGESTED_ACTIONS,
  CREATE_GOAL_FROM_ANALYSIS,
  createActionAndRedirectToIt,
  createGoalAndRedirectToIt,
} from '../../analysis/actions';
import SuggestedCourseResource from './SuggestedCourseResource';

const privateIfConfidential = isConfidential =>
  isConfidential ? 'private' : '';

export class SuggestedActions extends React.PureComponent {
  createGoal() {
    this.props.createGoalAndRedirectToIt(
      this.props.question,
      this.props.suggestions,
      this.props.recommendation.get('action_area_choice')
    );
  }

  render() {
    const { canCreateActions } = this.props;
    const isLoading = this.props.actionBeingCreated || this.props.goalBeingCreated;
    const isConfidential = this.props.suggestions.get('is_confidential_survey');
    const percentage = this.props.question.get('percentage');

    return (
      <div className="padding-large">
        <p className="font-size-large">
          <i>
            &ldquo;
            {percentage && (this.props.question.get('percentage') + ' ')}
            {this.props.question.get('response')}
            &rdquo;
          </i>
        </p>

        {canCreateActions && (
          <button
            className="btn middle orange margin-bottom-large font-weight-bold"
            onClick={this.createGoal.bind(this)}
            disabled={isLoading}
          >
            Create {privateIfConfidential(isConfidential)} goal with the below actions{' '}
            <i className="far fa-bullseye-arrow" />
          </button>
        )}

        <div className="padding-vertical-large">
          <h5 className="margin-none font-weight-bold font-size-medium text-pink">SUGGESTED ACTIONS</h5>
          <ul className="list-vertical-padding-small">
            {this.props.question.get('suggested_tasks').map((suggestion) => (
              <li key={suggestion.get('id')}>
                {suggestion.get('suggestion')}
              </li>
            ))}
          </ul>
        </div>

        {this.props.question.get('suggested_resources').count() > 0 && (
          <div className="padding-vertical-large">
            <h5 className="margin-none font-weight-bold font-size-medium text-pink">SUGGESTED RESOURCES</h5>
            <div className="margin-vertical-base">
              {this.props.question.get('suggested_resources').map((resource) => (
                <SuggestedCourseResource key={resource.get('id')} resource={resource} />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, { suggestions }) {
  return {
    actionBeingCreated: isPending(state.responses, CREATE_SUGGESTED_ACTIONS),
    goalBeingCreated: isPending(state.responses, CREATE_GOAL_FROM_ANALYSIS),
    canCreateActions: Boolean(suggestions.get('new_actions_owner_id')),
  };
}

export default connect(mapStateToProps, {
  createSuggestedActions,
  createActionAndRedirectToIt,
  createGoalAndRedirectToIt,
})(SuggestedActions);
