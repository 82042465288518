import { ModalRenderer } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

export function ProfileSettingsModalRenderer({modals}) {
  return <ModalRenderer modals={modals} />;
}

function mapStateToProps(state) {
  return {
    modals: state.modals
  };
}

export default connect(mapStateToProps)(ProfileSettingsModalRenderer);
