import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import Heatmap from 'highcharts/modules/heatmap';
import ExportData from 'highcharts/modules/export-data';
import OfflineExportData from 'highcharts/modules/offline-exporting.js';

// var Highcharts is a global singleton and configuring modules mutates it irreversibly.
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExportData(Highcharts);
Heatmap(Highcharts);

export const HIGHCHARTS_CONFIG_DEFAULTS = {
  exporting: {
    fallbackToExportServer: false,
    buttons: {
      contextButton: {
        menuItems: [
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS'
        ]
      }
    }
  }
};

export default Highcharts;
