import React from 'react'; // eslint-disable-line no-unused-vars

import classNames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import PureComponent from '^/components/PureComponent';

export class AdminListPagination extends PureComponent {

  getPaginationLinkClassName(isActive, isDisabled) {
    return classNames('pagination-link', isActive && 'active', isDisabled && 'disabled');
  }

  _loadPage(event, page, activeLink) {
    event.preventDefault();
    if (activeLink) {
      this.props.loadPage(page);
    }
  }

  renderPaginationLink(page, name, isDisabled = false, isActive = false, key = name) {
    const className = this.getPaginationLinkClassName(isActive, isDisabled);
    return (
      <a
        href=""
        key={key}
        className={className}
        onClick={(event) => this._loadPage(event, page, !isActive && !isDisabled)}
      >
        {name}
      </a>
    );
  };

  getPageListStartPageNumber(activePage, numberOfPages, numberOfPagesToRender) {
    const idealStartPage = Math.max(activePage - Math.floor(numberOfPagesToRender / 2), 1);
    return idealStartPage + numberOfPagesToRender - 1 <= numberOfPages ?
      idealStartPage :
      Math.max(1, numberOfPages - numberOfPagesToRender + 1);
  }

  renderPageList(activePage, numberOfPages) {
    const pageListStartPageNumber = this.getPageListStartPageNumber(activePage, numberOfPages, 5);
    return List([0, 1, 2, 3, 4]).map(idx => {
      const pageNumber = pageListStartPageNumber + idx;
      return pageNumber <= numberOfPages &&
        this.renderPaginationLink(pageNumber, pageNumber, false, pageNumber === activePage);
    });
  }

  render () {
    const { className, collection, pageSize } = this.props;
    const { page, count } = collection.toObject();

    const numberOfPages = Math.ceil(count / parseInt(pageSize, 10));
    const isFirstPage = page === 1;
    const isLastPage = numberOfPages === 0 || page === numberOfPages;

    return (
      <div className={classNames('pager tablesorter-pager', className)}>
        <div className="paginator-wrapper wethrive-theme simple-pagination">
          <div className="row">
            <div className="column">
              <div className="pagination-controls">
                {this.renderPaginationLink(1, 'First', isFirstPage)}
                {this.renderPaginationLink(page - 1, 'Prev', isFirstPage)}
                {this.renderPageList(page, numberOfPages)}
                {this.renderPaginationLink(page + 1, 'Next', isLastPage)}
                {this.renderPaginationLink(numberOfPages, 'Last', isLastPage)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminListPagination.propTypes = {
  className: PropTypes.string,
  collection: ImmutablePropTypes.map.isRequired,
  loadPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default AdminListPagination;
