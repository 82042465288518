import React from 'react'; // eslint-disable-line no-unused-vars

const CollapseIcon = () => (
  <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="39.5" y="37.5" width="39" height="37" rx="3.5" transform="rotate(180 39.5 37.5)" fill="#FFFFF9"/>
    <path
      d="M11.75 26.875C11.75 27.4844 11.2344 28 10.625 28C9.96875 28 9.5 27.4844 9.5 26.875L9.5 11.125C9.5
      10.4688 9.96875 10 10.625 10C11.2812 10 11.75 10.4688 11.75 11.125L11.75 26.875ZM22.25 24.1563C22.7188
      24.5781 22.7188 25.2813 22.2969 25.75C21.875 26.2188 21.1719 26.2188 20.7031 25.7969L14.3281 19.7969C13.8594
      19.375 13.8594 18.5781 14.3281 18.1563L20.7031 12.1563C21.1719 11.7344 21.875 11.7344 22.2969 12.2031C22.4844
      12.4375 22.5781 12.7188 22.5781 13C22.5781 13.2813 22.4844 13.5625 22.25 13.7969L17.9375 17.875L29.3281
      17.875C29.9844 17.875 30.5 18.3438 30.5 19C30.5 19.6094 29.9844 20.125 29.3281 20.125L17.9375
      20.125L22.25 24.1563Z" fill="#FF910A"
    />
    <rect x="39.5" y="37.5" width="39" height="37" rx="3.5" transform="rotate(180 39.5 37.5)" stroke="#FF910A"/>
  </svg>
);

export default CollapseIcon;
