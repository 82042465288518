import classNames from 'classnames';
import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';
import _ from 'underscore';

import { updateCustomReportNameAndToast } from '^/components/custom-reports/actions';

const LOAD_OPTIONS_DEBOUNCE_MS = 500;

const getReportNameId = reportId => `report-name-${reportId}`;

export class ReportName extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateNameDebounced = _.debounce(this.props.updateName, LOAD_OPTIONS_DEBOUNCE_MS);

    this.onChangeName = event => {
      event.persist();
      return this.updateNameDebounced(event.target.value);
    };
  }

  render() {
    return (
      <div
        className={classNames('display-flex align-items-center', {
          'margin-bottom-large': !this.props.isDashboardModal,
          'margin-bottom-base': this.props.isDashboardModal,
        })}
      >
        <label
          htmlFor={getReportNameId(this.props.report.id)}
          className={classNames('margin-bottom-none width-15-percent', {
            'font-size-18': !this.props.isDashboardModal,
            'font-size-14': this.props.isDashboardModal,
          })}
        >
          Name:
        </label>
        {this.props.isDashboardModal ? (
          <div className="font-size-18 font-family-effra-bold margin-bottom-none width-85-percent">
            {this.props.report.name}
          </div>
        ) : (
          <input
            id={getReportNameId(this.props.report.id)}
            placeholder="Enter report name"
            onChange={this.onChangeName}
            className="custom-report-name-input font-size-24 font-family-effra-bold"
            defaultValue={this.props.report.name}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, props) {
  const unFiltereredReportId = props.report.unfiltered_report_id || props.report.id;
  return {
    updateName: (name) => dispatch(updateCustomReportNameAndToast(name, unFiltereredReportId)),
  };
}

export default connect(undefined, mapDispatchToProps)(ReportName);
