export const NO_OKRS_INDIVIDUAL_MESSAGE = 'You have no assigned OKRs.';
export const NO_OKRS_TEAM_MESSAGE = 'Your team have no assigned OKRs.';

export const OKR_TYPE = {
  COMPANY: 'COMPANY',
  MANAGER: 'MANAGER',
  EMPLOYEE: 'EMPLOYEE'
};

export const OKR_TYPE_DISPLAY = {
  [OKR_TYPE.COMPANY]: 'Company',
  [OKR_TYPE.MANAGER]: 'Manager',
  [OKR_TYPE.EMPLOYEE]: 'Employee'
};

export const OKR_STATUS = {
  NO_UPDATE: 'NO_UPDATE',
  OFF_TRACK: 'OFF_TRACK',
  PROGRESSING: 'PROGRESSING',
  ON_TRACK: 'ON_TRACK',
  COMPLETE: 'COMPLETE'
};

export const OKR_STATUS_LABEL_MAP = {
  [OKR_STATUS.NO_UPDATE]: 'NO UPDATE',
  [OKR_STATUS.OFF_TRACK]: 'OFF TRACK',
  [OKR_STATUS.PROGRESSING]: 'PROGRESSING',
  [OKR_STATUS.ON_TRACK]: 'ON TRACK',
  [OKR_STATUS.COMPLETE]: 'COMPLETE'
};

export const OKR_STATUS_LABEL_MAP_LOWER = {
  [OKR_STATUS.NO_UPDATE]: 'No update',
  [OKR_STATUS.OFF_TRACK]: 'Off track',
  [OKR_STATUS.PROGRESSING]: 'Progressing',
  [OKR_STATUS.ON_TRACK]: 'On track',
  [OKR_STATUS.COMPLETE]: 'Complete'
};

export function getColorForProgress(progress) {
  if (progress >= 75) {
    return 'background-green';
  }
  if (progress >= 50) {
    return 'background-orange';
  }
  if (progress >= 1) {
    return 'background-red';
  }
  return '';
}

export const COMPANY_GOAL_STATUS_TO_COLOR_MAP = {
  [OKR_STATUS.NO_UPDATE]: 'background-gray-light',
  [OKR_STATUS.PROGRESSING]: 'background-orange',
  [OKR_STATUS.COMPLETE]: 'background-green',
};

export const OKR_STATUS_OPTIONS = [
  {
    value: OKR_STATUS.NO_UPDATE,
    label: 'No update',
  },
  {
    value: OKR_STATUS.OFF_TRACK,
    label: 'Off track',
  },
  {
    value: OKR_STATUS.PROGRESSING,
    label: 'Progressing',
  },
  {
    value: OKR_STATUS.ON_TRACK,
    label: 'On track',
  },
  {
    value: OKR_STATUS.COMPLETE,
    label: 'Complete',
  }
];

export const OKR_ACTION_FIELD = {
  DUE_DATE: 'due_date',
  STATUS: 'status',
  OWNER: 'owner',
  DESCRIPTION: 'description',
  TITLE: 'title'
};

export const TITLES_CONSTANT_MAP = {
  [OKR_TYPE.COMPANY]: 'Company Goal',
  [OKR_TYPE.MANAGER]: 'Manager OKR',
  [OKR_TYPE.EMPLOYEE]: 'Employee OKR'
};

export const CREATE_OKR_FORM = 'CREATE_OKR_FORM';

export const getEditOkrFormName = okrId =>
  `edit-okr-${okrId}`;

export const getAddLinkedOkrFormName = parentOkrId =>
  `add-linked-okr-${parentOkrId}`;
