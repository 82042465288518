import { ModalRenderer } from '@dabapps/roe';
import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { fetchCustomTerms, getSurvey } from '^/actions/actions';
import PureComponent from '^/components/PureComponent';
import StatusUserList from '^/components/survey/StatusUserList';
import Loading from '../Loading';

export class SurveyStatus extends PureComponent {
  componentWillMount() {
    this.props.getSurvey(this.props.surveyId);
    this.props.fetchCustomTerms();
  }
  render() {
    const { survey, modals, customTerms, canEditSurvey, canExportResponses, organisationFilterTerms } = this.props;
    if (survey.isEmpty()) {
      return <Loading />;
    }
    return (
      <div className="will">
        <div className="row">
          <div className="col-12">
            <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
            <ModalRenderer modals={modals}/>
            <StatusUserList
              survey={survey}
              customTerms={customTerms}
              canEditSurvey={canEditSurvey}
              canExportResponses={canExportResponses}
              organisationFilterTerms={organisationFilterTerms}
            />
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    modals: state.modals,
    customTerms: state.organisation.get('customTerms', Map()),
    survey: state.survey.get('survey', Map()),
  };
}

export default connect(mapStateToProps, {fetchCustomTerms, getSurvey})(SurveyStatus);
