import { SpacedGroup } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars

import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';
import { joinTruthyStringsWith } from '^/utils';

export const getManageResourcesListConfig = (
  openCreateEditResourceModal,
  openDeleteModal,
  reloadPage,
  showWeightingActions,
  numberOfResources,
  updateWeight
) => [
  {
    name: 'image',
    display_name: 'Image',
    type: 'custom',
    customItemHandler: resource => (
      <CellContentOrEmpty condition={Boolean(resource.image)}>
        <img
          src={resource.image}
          alt={`Image for ${resource.display_name}`}
        />
      </CellContentOrEmpty>
    ),
    sortable: false
  },
  {
    name: 'display_name',
    display_name: 'Resource / Description',
    type: 'custom',
    customItemHandler: resource => (
      <span>
        <span className="text-orange text-overflow-ellipsis">
          {resource.display_name}
        </span>
        <span className="display-block text-help text-overflow-ellipsis">
          {resource.description}
        </span>
      </span>
    ),
    sortable: true
  },
  {
    name: 'url',
    display_name: 'Link',
    type: 'custom',
    customItemHandler: resource => (
      <a className="link-orange" href={resource.url} target="blank">
        Link
        <i className="far fa-external-link margin-left-base" />
      </a>
    ),
    sortable: false
  },
  {
    name: 'area',
    display_name: 'Low-scoring Category',
    type: 'text',
    sortable: true
  },
  {
    name: 'for_user_type',
    display_name: 'For',
    type: 'text',
    sortable: true
  },
  {
    name: 'contact_details__name',
    display_name: 'Contact',
    type: 'custom',
    customItemHandler: resource => (
      <span>
        {joinTruthyStringsWith(
          [resource.contact_details.name, resource.contact_details.role],
          ' - '
        )}
        <span className="display-block text-help">
          {joinTruthyStringsWith(
            [resource.contact_details.email, resource.contact_details.telephone_no],
            ' • '
          )}
        </span>
      </span>
    ),
    sortable: true
  },
  ...(showWeightingActions ? [{
    name: '',
    display_name: 'Reorder',
    type: 'custom',
    customItemHandler: resource => {
      const upButtonIsDisabled = resource.weight === 1;
      const downButtonIsDisabled = numberOfResources === resource.weight;
      return (
        <span className="padding-right-base">
          <button
            className="resource-weighting-button"
            onClick={upButtonIsDisabled ? undefined
              : () => updateWeight(resource, resource.weight - 1, reloadPage)
            }
            disabled={upButtonIsDisabled}
          >
            <i className="far fa-chevron-up" />
          </button>
          <button
            className="resource-weighting-button"
            onClick={downButtonIsDisabled ? undefined
              : () => updateWeight(resource, resource.weight + 1, reloadPage)
            }
            disabled={downButtonIsDisabled}
          >
            <i className="far fa-chevron-down" />
          </button>
        </span>
      );
    },
    sortable: false
  }] : []),
  {
    name: '',
    display_name: '',
    type: 'custom',
    customItemHandler: resource => (
      <SpacedGroup>
        <button
          className="btn hollow orange"
          onClick={() => openCreateEditResourceModal(reloadPage, resource)}
        >
          Edit
        </button>
        <button
          className="btn hollow danger"
          onClick={() => openDeleteModal(reloadPage, resource)}
        >
          Delete
        </button>
      </SpacedGroup>
    ),
    sortable: false
  }
];
