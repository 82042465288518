import React from 'react';  //eslint-disable-line no-unused-vars
import Recommendation from './Recommendation';

export default function Recommendations (props) {
  return (
    <div>
      {props.recommendations.map((recommendation) => (
        <Recommendation
          key={recommendation.get('id')}
          recommendation={recommendation}
          suggestions={props.suggestions}
          isIndividual={props.isIndividual}
        />
      ))}
    </div>
  );
}
