import { responsesReducer } from '@dabapps/redux-requests';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import collections from './collections';
import organisation from './organisation';
import combineImmutableReducers from '^/combineImmutableReducers';
import legacyResponses from './responses';
import fieldConfigs from './fieldConfigs';
import items from './items';
import modals from './modals';
import * as reducers from './reducers';
import * as ui from './ui';
import * as emailSettings from './email-settings';
import * as agendas from './agendas';
import * as customQuestions from './custom-questions';
import * as additionalFilters from './additional-filters';
import * as suggestions from '^/components/analysis/reducers';
import * as goals from '^/components/goals/reducers';
import * as users from '^/components/users/reducers';
import * as manager from '^/components/managers/reducers';
import * as formFields from '^/components/forms/reducers';
import * as resources from '^/components/resources/reducers';
import * as imageUpload from '^/components/forms/image-upload/reducers';
import * as reports from '^/components/reports/reducers';
import * as okrs from '^/components/okrs/reducers';
import * as customReports from '^/components/custom-reports/reducers';
import * as dashboards from '^/components/dashboards/reducers';
import * as settings from '^/components/settings/reducers';

export default combineReducers(
  Object.assign(
    {
      ui: combineImmutableReducers(ui),
      form: formReducer,
      organisation,
      fieldConfigs,
      legacyResponses,
      items,
      modals,
      collections,
      responses: responsesReducer
    },
    agendas,
    customQuestions,
    additionalFilters,
    emailSettings,
    reducers,
    suggestions,
    goals,
    users,
    manager,
    formFields,
    resources,
    imageUpload,
    reports,
    okrs,
    customReports,
    dashboards,
    settings
  )
);
