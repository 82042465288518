import { Row, Column } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import RemoteUserSelect from '^/components/forms/RemoteUserSelect';
import { FORMAT_DATE_PICKER } from '^/utils';

const FORM_NAME = 'updateIndividualAgendaDetailsForm';

const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper">
      <ReactDatePicker
        selected={props.input.value}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        disabled={props.isDisabled}
        tetherConstraints={[]}
        required={props.dateTimeRequired}
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

const ManagerSelectField = props => (
    <RemoteUserSelect
      onChange={props.input.onChange}
      selectId={FORM_NAME}
      placeholder="Type to search..."
      disabled={props.isDisabled}
      optionFilters={{agenda_manager_options_for_employee: props.agendaEmployeeId}}
      initialValue={props.input.value}
      clearable
    />
  );

export function UpdateIndividualAgendaDetailsForm({
  handleSubmit,
  isDisabled,
  readOnlyEmployee,
  cancel,
  isAgendaEmployee,
  dateTimeRequired,
  initialValues: { manager }
}) {
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="title" className="font-weight-bold">
        Title
      </label>
      <Field
        name="title"
        id="title"
        component="input"
        type="text"
        disabled={isDisabled}
        required
      />
      <label htmlFor="description" className="font-weight-bold margin-top-x-large">
        Description
      </label>
      <Field
        name="description"
        id="description"
        component="textarea"
        disabled={isDisabled}
      />
      <Row>
        <Column xs={12} md={6}>
          <label htmlFor="employee" className="font-weight-bold margin-top-x-large">
            Employee
          </label>
          <select disabled name="employee">
            <option>
              {readOnlyEmployee.label}
            </option>
          </select>
        </Column>
        <Column xs={12} md={6}>
          <label htmlFor="manager" className="font-weight-bold margin-top-x-large">
            Manager
          </label>
          {isAgendaEmployee ? (
            <select disabled name="manager">
              <option>
                {manager ? manager.label : 'None selected'}
              </option>
            </select>
          ) : (
            <Field
              name="manager"
              id="manager"
              component={ManagerSelectField}
              isDisabled={isDisabled}
              agendaEmployeeId={readOnlyEmployee.value}
            />
          )}
        </Column>
        <Column xs={12} md={6}>
          <label htmlFor="date" className="font-weight-bold margin-top-x-large">
            Date
          </label>
          <Field
            name="date"
            id="date"
            component={DatePickerField}
            isDisabled={isDisabled}
            dateTimeRequired={dateTimeRequired || isAgendaEmployee}
          />
        </Column>
        <Column xs={12} md={6}>
          <label htmlFor="time" className="font-weight-bold margin-top-x-large">
            Time
          </label>
          <Field
            name="time"
            id="time"
            component="input"
            type="time"
            disabled={isDisabled}
            required={dateTimeRequired || isAgendaEmployee}
          />
        </Column>
      </Row>
      <button
        className="btn middle orange margin-top-x-large"
        disabled={isDisabled}
      >
        Save
      </button>
      {cancel ? (
        <button
          className="btn middle orange hollow margin-top-x-large margin-left-large"
          onClick={cancel}
          disabled={isDisabled}
        >
          Cancel
        </button>
        ) :
        <a href="/agendas/list/" className="btn middle orange hollow margin-top-x-large margin-left-large">Cancel</a>
      }
    </form>
  );
}

function mapStateToProps(state) {
  const selectedDate = formValueSelector(FORM_NAME)(state, 'date');
  const selectedTime = formValueSelector(FORM_NAME)(state, 'time');
  return {
    dateTimeRequired: !!(selectedDate || selectedTime),
  };
}

const FormifiedUpdateIndividualAgendaDetailsForm = reduxForm({
  form: FORM_NAME,
})(UpdateIndividualAgendaDetailsForm);

export default connect(mapStateToProps)(FormifiedUpdateIndividualAgendaDetailsForm);
