import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { GET_SUBSCRIPTION_ALLOWANCES } from '^/actions/actions';
import Loading from '^/components/Loading';

export const SubscriptionOverview = ({
  isLoading,
  employeeCount,
  maxEmployees,
  employeeBubbleCount,
  maxEmployeeBubbles,
  managerCount,
  maxManagers,
  managerWithAdminCount,
  maxManagersWithAdmin
}) => (
  <div className="alert alert-info">
    {isLoading ? (
      <Loading />
    ) : (
      <p className="info">
        You are using: {employeeCount} of {maxEmployees} Employee Licenses,{' '}
        {employeeBubbleCount} of {maxEmployeeBubbles} Employee Bubbles,{' '}
        {managerCount} of {maxManagers} Manager Bubbles and {managerWithAdminCount}{' '}
        of {maxManagersWithAdmin} Manager Bubbles with Admin.{' '}
        You can <a href="mailto:support@wethrive.net">contact us</a> if additional licenses are required.
      </p>
    )}
  </div>
);

export { SubscriptionOverview as UnconnectedSubscriptionOverview };

export const mapStateToProps = ({responses, subscriptionAllowances}, props) => ({
  isLoading: isPending(responses, GET_SUBSCRIPTION_ALLOWANCES),
  employeeCount: subscriptionAllowances.get('employee_count') || props.employeeCount,
  maxEmployees: subscriptionAllowances.get('max_employees') || props.maxEmployees,
  employeeBubbleCount: subscriptionAllowances.get('employee_bubble_count') || props.employeeBubbleCount,
  maxEmployeeBubbles: subscriptionAllowances.get('max_employee_bubbles') || props.maxEmployeeBubbles,
  managerCount: subscriptionAllowances.get('manager_count') || props.managerCount,
  maxManagers: subscriptionAllowances.get('max_managers') || props.maxManagers,
  managerWithAdminCount: subscriptionAllowances.get('manager_with_admin_count') || props.managerWithAdminCount,
  maxManagersWithAdmin: subscriptionAllowances.get('max_managers_with_admin') || props.maxManagersWithAdmin,
  executiveCount: subscriptionAllowances.get('executive_count') || props.executiveCount,
  maxExecutives: subscriptionAllowances.get('max_executives') || props.maxExecutives
});

export default connect(mapStateToProps)(SubscriptionOverview);
