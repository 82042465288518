export const STATUS_TEAM_MEMBERS = 'STATUS_TEAM_MEMBERS';

export const MY_SURVEY_RESPONSES_LIST = 'MY_SURVEY_RESPONSES_LIST';
export const ORGANISATION_SURVEYS_LIST = 'ORGANISATION_SURVEYS_LIST';

export const RESPONSE_STATES = {
  COMPLETE: 'Complete',
};

export const SURVEY_STATES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  EDIT: 'EDIT',
  WAIT_AUTO_START: 'WAIT_AUTO_START',
  STARTING: 'STARTING',
  WAIT_WARMUP_START: 'WAIT_WARMUP_START',
  SENDING_WARMUP_EMAILS: 'SENDING_WARMUP_EMAILS',
};

export const SURVEY_STATES_DISPLAY = {
  [SURVEY_STATES.OPEN]: 'Open',
  [SURVEY_STATES.CLOSED]: 'Closed',
  [SURVEY_STATES.EDIT]: 'Setup',
  [SURVEY_STATES.WAIT_AUTO_START]: 'Waiting to start',
  [SURVEY_STATES.STARTING]: 'Starting',
  [SURVEY_STATES.WAIT_WARMUP_START]: 'Waiting to start',
  [SURVEY_STATES.SENDING_WARMUP_EMAILS]: 'Sending warmup emails',
};
