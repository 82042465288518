import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { getAdminOneToOnes, GET_ADMIN_ONE_TO_ONES} from '^/components/dashboards/actions';
import Loading from '^/components/Loading';
import WidgetTitle from '^/components/dashboards/WidgetTitle';


export class AdminOneToOnes extends React.PureComponent {
  componentDidMount() {
    this.props.getAdminOneToOnes();
  }

  render() {
    const { agendas_completed, agendas_count, isLoading } = this.props;
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title="1:1 meetings"
          subtitle="In the last 6 months"
          image="/static/images/dashboards/onetoones.svg"
        />
        {isLoading && <Loading />}
        {!isLoading && (
          <div className="widget-item-hollow margin-bottom-large font-family-varela-round">
            <div className="font-size-38 margin-bottom-base">
              {agendas_count > 0 ? agendas_count : 0}
            </div>
            <div className="font-size-18 line-height-16px">
              Sent
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="widget-item-grey margin-bottom-x-large font-family-varela-round">
            <div className="font-size-38 margin-bottom-base">
              {agendas_completed > 0 ? agendas_completed : 0}
            </div>
            <div className="font-size-18 margin-bottom-small">
              Completed
            </div>
            <div className="one-to-one-completed-bar background-green">
            </div>
          </div>
        )}
        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/agendas/list/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    agendas_count: state.dashboardOneToOnes.agendas_count || [],
    agendas_completed: state.dashboardOneToOnes.agendas_completed || [],
    isLoading: isPending(state.responses, GET_ADMIN_ONE_TO_ONES),
  };
}

export default connect(mapStateToProps, { getAdminOneToOnes })(AdminOneToOnes);
