import React from 'react'; // eslint-disable-line no-unused-vars

import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Column, Row } from '@dabapps/roe';
import { isPending, anyPending } from '@dabapps/redux-requests';

import { updateGenericErrorMessages } from '^/actions/actions';
import {
  getAdditionalFilterOptions,
  GET_ADDITIONAL_FILTER_OPTIONS,
  updateAdditionalFilterAndCloseModal,
  UPDATE_ADDITIONAL_FILTER,
  createCustomFilterAndCloseModal,
  CREATE_CUSTOM_FILTER
} from './actions';
import FormError from '^/components/forms/FormError';
import Loading from '^/components/Loading';

import AdditionalFilterOptions from './AdditionalFilterOptions';

const FORM_NAME = 'additional-filters';

export class AdditionalFiltersForm extends React.PureComponent {

  componentDidMount() {
    this.props.resetGenericErrorMessages();
    if (this.props.filterId) {
      this.props.getAdditionalFilterOptions(this.props.filterId);
    }
  }

  render() {
    const {
      handleSubmit,
      response,
      optionsErrors,
      disabled
    } = this.props;

    if (this.props.isLoadingFilterForEdit) {
      return <Loading />;
    }
    return (
      <form onSubmit={handleSubmit} disabled={disabled}>
        <div className="modal-body padding-vertical-none">
          <p>
            Set filter name, active/inactive, and options.
          </p>
          <Row className="padding-top-x-large">
            <Column xs={12} md={2}>
              <label className="input-line-height">
                Filter name
              </label>
            </Column>
            <Column xs={12} md={9}>
              <Field
                name="name"
                type="text"
                component="input"
                placeholder="Enter filter name"
                disabled={disabled}
                autoFocus
              />
              <FormError response={response} formKey="name" />
            </Column>
          </Row>
          <Row>
            <Column xs={12} md={2}>
              <label className="input-line-height">
                Status
              </label>
            </Column>
            <Column xs={12} md={9}>
              <Row className="margin-bottom-large">
                <Column xs={12} md={3} className="padding-right-none">
                  <label className="input-line-height">
                    <Field
                      name="active"
                      type="radio"
                      component="input"
                      value="true"
                      disabled={disabled}
                    />
                    <span className="vertical-align-middle padding-left-small padding-right-none">
                      Active
                    </span>
                  </label>
                </Column>
                <Column xs={12} md={3} className="padding-left-small padding-right-none">
                  <label className="input-line-height">
                    <Field
                      name="active"
                      type="radio"
                      component="input"
                      value="false"
                      disabled={disabled}
                    />
                    <span className="vertical-align-middle padding-left-small padding-right-none">
                      Inactive
                    </span>
                  </label>
                </Column>
              </Row>
              <FormError response={response} formKey="type" />
            </Column>
          </Row>
          {optionsErrors.map((message, index) => (
            <p key={`error-${index}`} className="margin-bottom-base error">{message}</p>
          ))}
          <AdditionalFilterOptions disabled={disabled} />
        </div>
        <Row className="margin-vertical-large">
          <Column>
            <FormError response={response} formKey="non_field_errors" />
            <button className="btn middle orange" type="submit" disabled={disabled}>
              Save
            </button>
          </Column>
        </Row>
      </form>
    );
  }
}

export function mapStateToProps(state) {
  return {
    disabled: anyPending(state.responses, [UPDATE_ADDITIONAL_FILTER, CREATE_CUSTOM_FILTER]),
    additionalFilterOptions: state.additionalFilterOptions,
    optionsErrors: state.genericErrorMessages,
    isLoadingFilterForEdit: isPending(state.responses, GET_ADDITIONAL_FILTER_OPTIONS),
    additionalFiltersList: state.additionalFiltersList
  };
}

function assignDataObject(options, data) {
  return Object.assign({}, data, {
    options,
    active: data.active === 'true' ? true : false
  });
}

export function mapDispatchToProps(dispatch, props) {
  return {
    getOnSubmitFunction: (options, additionalFiltersList) => (data) => {
      const optionsMinusBlanks = options.filter(option => !!option.label);
      const hasBlankName = !data.name;
      const errorMessages = [];

      if (hasBlankName) {
        errorMessages.push('Name cannot be blank');
      }

      if (
        (
          props.filterId &&
          !!additionalFiltersList.find(filter =>
            filter.id !== props.filterId && filter.name.toLowerCase() === data.name.toLowerCase()
          )
        ) || (
          !props.filterId &&
          !!additionalFiltersList.find(filter => filter.name.toLowerCase() === data.name.toLowerCase())
        )
      ) {
        errorMessages.push('Your organisation already has a filter with this name.');
      }

      if (errorMessages.length) {
        dispatch(updateGenericErrorMessages(errorMessages));
      } else {
        dispatch(updateGenericErrorMessages([]));
        if (props.filterId) {
          dispatch(
            updateAdditionalFilterAndCloseModal(
              props.filterId,
              assignDataObject(optionsMinusBlanks, data)
            )
          );
        } else {
          dispatch(
            createCustomFilterAndCloseModal(
              assignDataObject(optionsMinusBlanks, data)
            )
          );
        }
      }
    },
    getAdditionalFilterOptions: (filterId) => getAdditionalFilterOptions(filterId)(dispatch),
    resetGenericErrorMessages: () => dispatch(updateGenericErrorMessages([]))
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    onSubmit: dispatchProps.getOnSubmitFunction(
      stateProps.additionalFilterOptions,
      stateProps.additionalFiltersList
    )
  });
}

export const FormifiedAdditionalFiltersForm = reduxForm({
  form: FORM_NAME
})(AdditionalFiltersForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FormifiedAdditionalFiltersForm);
