import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { connect } from 'react-redux';
import { Button, ModalBody, ModalFooter, SpacedGroup } from '@dabapps/roe';
import { closeModal } from '^/actions/modals';
import { sendTestEmailAndShowOutcome } from '^/actions/actionSequences';


export class SendTestEmailModal extends PureComponent {
  constructor() {
    super();
    this.handleSendTestEmail = this.handleSendTestEmail.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  cancel() {
    this.props.closeModal();
  }

  handleSendTestEmail() {
    const { survey, email_type } = this.props;
    this.props.sendTestEmailAndShowOutcome(survey.get('id'), email_type);
    this.props.closeModal();
  }

  render() {
    return (
      <div>
        <ModalBody>
          <p>
            Do you want to send a test email? It will go to your email address only.
          </p>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button className="btn gray middle" onClick={this.cancel}>
              Cancel
            </Button>
            <Button className="btn danger middle" onClick={this.handleSendTestEmail}>
              Send
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </div>
    );
  }
}

export default connect(null, {sendTestEmailAndShowOutcome, closeModal })(SendTestEmailModal);
