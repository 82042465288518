import { isPending as reduxRequestsIsPending } from '@dabapps/redux-requests';
import { ModalBody, ModalFooter, SpacedGroup, Button, Row, Column } from '@dabapps/roe';
import { fromJS, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import {
  getManagerOptions,
  GET_MANAGER_OPTIONS,
  getSubscriptionAllowances,
  GET_SUBSCRIPTION_ALLOWANCES,
  resetResponse
} from '^/actions/actions';
import { submitUserCloseModalAndReload } from '^/actions/actionSequences';
import { loadItem } from '^/actions/items';
import { closeModal } from '^/actions/modals';
import Loading from '^/components/Loading';
import Field from '^/components/forms/Field';
import InlineRadioButtons from '^/components/forms/InlineRadioButtons';
import FieldSelect from '^/components/forms/FieldSelect';
import {
  USERS_PATH,
  CREATE_USERS_PATH,
  NOTIFICATION_OPTIONS,
  NOTIFICATION_OPTIONS_DISPLAY
} from '^/components/users/const';
import { getManagers } from '^/components/users/utils';
import { isPending } from '^/consts/responseStates';
import roles, {
  organisationLevelRoleOptions,
  rolesDisplay,
  ROLE_TYPE_LICENCES_MAX_MAPPING,
  ROLE_TYPE_LICENCES_USED_MAPPING,
} from '^/roles';
import classNames from 'classnames';

export const FORM_NAME = 'EditUserForm';

const statusRadioButtons = [
  {
    value: true,
    label: 'Active'
  },
  {
    value: false,
    label: 'Inactive'
  }
];

const teamCommentsRadioButtons = [
  {
    value: true,
    label: 'View Team Comments'
  },
  {
    value: false,
    label: 'Not Visible'
  }
];

const notificationOptionsRadioButtons = [
  {
    value: NOTIFICATION_OPTIONS.BOTH,
    label: NOTIFICATION_OPTIONS_DISPLAY.BOTH
  },
  {
    value: NOTIFICATION_OPTIONS.SLACK,
    label: NOTIFICATION_OPTIONS_DISPLAY.SLACK
  },
  {
    value: NOTIFICATION_OPTIONS.EMAIL,
    label: NOTIFICATION_OPTIONS_DISPLAY.EMAIL
  }
];

const defaultInitialValues = {
  can_view_report_comments: true,
  is_active: true,
  type: roles.EMPLOYEE,
  team_member: {
    language: 'en'
  },
  preferred_notification_option: NOTIFICATION_OPTIONS.EMAIL
};

function formatUpdateData(data) {
  const additional_filters = data.team_member && data.team_member.additional_filters;
  const selectValues = additional_filters ? Object.values(additional_filters).filter(value => Boolean(value)) : [];
  return fromJS(data).setIn(['team_member', 'additional_filters'], selectValues).toJS();
}

const getBlankValueLabel = name => `No ${name} selected`;

const TeamCommentsField = props => <InlineRadioButtons {...props} radioButtons={teamCommentsRadioButtons}/>;

const IsActiveField = props => <InlineRadioButtons {...props} radioButtons={statusRadioButtons}/>;

const PreferredNotificationOptionField = props => (
  <InlineRadioButtons
    {...props}
    helpText={<span>Users will only receive survey notifications via Slack if your organisation has Slack configured - please go to the <a target="_blank" href="https://intercom.help/wethrive/en/articles/5589133-integrate-wethrive-with-slack">Integrate with Slack help file</a></span>}  // eslint-disable-line max-len
    radioButtons={notificationOptionsRadioButtons}
  />
);

export class CreateEditUserModal extends React.PureComponent {

  constructor(props) {
    super(props);
    this.renderManagerSelect = this.renderManagerSelect.bind(this);
  }

  componentDidMount() {
    this.props.resetResponse();
    if (this.props.userId) {
      this.props.loadUser();
    }
    this.props.getSubscriptionAllowances();
    this.props.getManagerOptions();
  }

  renderManagerSelect({input: { onChange, value }}) {
    return (
      <FieldSelect
        onChange={onChange}
        value={value}
        placeholder="Select managers..."
        options={this.props.managerOptions}
        multi
      />
    );
  }

  render() {
    const {
      cancel,
      handleSubmit,
      hasPendingRequests,
      selectOptions: {
        teams,
        locations,
        departments,
        units,
        managers,
        additional_filters,
      },
      response,
      selectedType,
      licensesUsed,
      licensesMax,
      initialValues,
      isEdit,
      organisationFilterTerms
    } = this.props;

    if (hasPendingRequests) {
      return (
        <ModalBody>
          <Loading />
        </ModalBody>
      );
    }
    return (
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Column xs={12} md={6}>
              <div className="padding-horizontal-large padding-vertical-base">
                <p><strong>Details</strong></p>
                <Field
                  name="first_name"
                  label="Name"
                  placeholder="Name..."
                  response={response}
                />
                <Field
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="Email..."
                  response={response}
                />
                <Field
                  name="managers"
                  label="Reports to"
                  component={this.renderManagerSelect}
                  response={response}
                  helpText="Selecting users here will allow them to view this employee’s survey results."
                />
                <Field
                  name="type"
                  label="Role"
                  component="select"
                  options={organisationLevelRoleOptions}
                  response={response}
                />
                <Field
                  name="team_member.language"
                  label="Language"
                  component="select"
                  options={this.props.selectOptions.languages}
                  response={response}
                />
                <Field
                  name="preferred_notification_option"
                  label="Preferred Notifications"
                  component={PreferredNotificationOptionField}
                  response={response}
                />
                {isEdit && (
                  <div className="margin-vertical-large text-align-center">
                    <p>
                      <img
                        src="/static/images/slack/Slack_Mark.png"
                        className={
                          classNames(
                            {'filter-grayscale': !initialValues.can_receive_slack_notifications},
                            'slack-inline-icon'
                          )
                        }
                      />
                      This user
                      {' '}{initialValues.can_receive_slack_notifications ? 'can' : 'cannot'}
                      {' '}receive Slack notifications
                    </p>
                  </div>
                )}
                <Field
                  name="can_view_report_comments"
                  label="Team Comments"
                  component={TeamCommentsField}
                  response={response}
                />
                <Field
                  name="is_active"
                  label="Status"
                  component={IsActiveField}
                  response={response}
                />
              </div>
              <div className="modal-alert">
                <p className="info">
                  You are using {licensesUsed} of {licensesMax} active{' '}
                  {rolesDisplay[selectedType] && rolesDisplay[selectedType].toLowerCase()}
                  {' '}licenses.{' '}
                  <a href="mailto:support@wethrive.net">Contact us</a> for additional licenses.
                </p>
              </div>
            </Column>
            <Column xs={12} md={6}>
              <div className="padding-horizontal-large padding-vertical-base">
                <Row>
                  <Column xs={3}>
                    <p><strong>Filters</strong></p>
                  </Column>
                  <Column xs={9}>
                    <p className="text-help">
                      Here you can choose which filters to apply to employee survey results.
                    </p>
                  </Column>
                </Row>
                <Field
                  name="team_member.team"
                  label={organisationFilterTerms.team}
                  component="select"
                  options={teams}
                  blankValueLabel={getBlankValueLabel('team')}
                  response={response}
                />
                <Field
                  name="team_member.location_filter"
                  label={organisationFilterTerms.location}
                  component="select"
                  options={locations}
                  blankValueLabel={getBlankValueLabel('location')}
                  response={response}
                />
                <Field
                  name="team_member.department_filter"
                  label={organisationFilterTerms.department}
                  component="select"
                  options={departments}
                  blankValueLabel={getBlankValueLabel('department')}
                  response={response}
                />
                <Field
                  name="team_member.unit_filter"
                  label={organisationFilterTerms.unit}
                  component="select"
                  options={units}
                  blankValueLabel={getBlankValueLabel('unit')}
                  response={response}
                />
                <Field
                  name="team_member.manager_filter"
                  label={organisationFilterTerms.manager}
                  component="select"
                  options={managers}
                  blankValueLabel={getBlankValueLabel('manager')}
                  response={response}
                />
                {additional_filters.map((additionalFilter, index) => (
                  <Field
                    key={`additional-filter-${index}`}
                    name={`team_member.additional_filters.${additionalFilter.id}`}
                    label={additionalFilter.name}
                    component="select"
                    options={additionalFilter.options}
                    blankValueLabel="None selected"
                    response={response}
                  />
                ))}
              </div>
            </Column>
          </Row>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button className="btn middle orange" type="submit" disabled={hasPendingRequests}>Save</Button>
            <Button className="btn middle orange hollow" onClick={cancel}>Cancel</Button>
          </SpacedGroup>
        </ModalFooter>
      </form>
    );
  }
}

export const FormifiedCreateEditUserModal = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(CreateEditUserModal);

export function mapStateToProps(state, props) {
  const updateResponse = state.legacyResponses.getIn(['updateItem', USERS_PATH]);
  const createResponse = state.legacyResponses.getIn(['createItem', CREATE_USERS_PATH]);
  const user = (state.items.get(USERS_PATH) || Map());
  const selectedType = formValueSelector(FORM_NAME)(state, 'type');

  return {
    managerOptions: getManagers(state.managerOptions, [props.userId]).toJS(),
    hasPendingRequests:
      reduxRequestsIsPending(state.responses, GET_MANAGER_OPTIONS) ||
      reduxRequestsIsPending(state.responses, GET_SUBSCRIPTION_ALLOWANCES) ||
      isPending(state.legacyResponses.getIn(['loadItem', USERS_PATH])) ||
      isPending(updateResponse) ||
      isPending(createResponse),
    isEdit: !!props.userId,
    initialValues: props.userId ? user.toJS() : defaultInitialValues,
    response: createResponse || updateResponse,
    selectedType,
    licensesUsed: state.subscriptionAllowances.get(ROLE_TYPE_LICENCES_USED_MAPPING[selectedType]),
    licensesMax: state.subscriptionAllowances.get(ROLE_TYPE_LICENCES_MAX_MAPPING[selectedType]),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    cancel: (event) => {
      event.preventDefault();
      dispatch(closeModal());
    },
    onSubmit: data =>
      dispatch(submitUserCloseModalAndReload(
        formatUpdateData(data),
        props.userId,
        props.reloadPage
      )
    ),
    loadUser: () => dispatch(loadItem(USERS_PATH, props.userId)),
    getManagerOptions: () => dispatch(getManagerOptions()),
    resetResponse: () => {
      dispatch(resetResponse('createItem'));
      dispatch(resetResponse('updateItem'));
    },
    getSubscriptionAllowances: () => dispatch(getSubscriptionAllowances()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormifiedCreateEditUserModal);
