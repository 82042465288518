import { isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import { closeModal } from '^/actions/modals';
import { publishCustomReportAndCloseModal, PUBLISH_CUSTOM_REPORT } from '^/components/custom-reports/actions';
import { REPORT_TYPES } from '^/components/custom-reports/constants';
import USER_ROLES from '^/roles';
import { isEmployeeBubbleUser, userMeetsRequiredRole, isManagerUser } from '^/utils/permissions';

const FORM_NAME = 'PublishConfirmModalForm';

const PublishConfirmModalButtons = ({selectionRequired, isPublishing, close}) => (
  <div className="margin-top-xx-large text-align-right">
    <button
      className="pink-button"
      type="submit"
      disabled={isPublishing || selectionRequired}
    >
      Publish
    </button>
    <button
      className="pink-border-button margin-left-x-large"
      onClick={close}
      disabled={isPublishing}
    >
      Cancel
    </button>
  </div>
);

export const PublishConfirmModal = ({
  close,
  handleSubmit,
  isPublishing,
  isEmployee,
  isManager,
  isAdminUser,
  selectionRequired,
  reportType,
  publishingError
}) => {
  const isInternalBenchmark = reportType === REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP;

  if (isEmployee) {
    return (
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <p className="font-size-18 text-align-center">
            Publish this report to your own dashboard.{' '}
            The report will display in the 'Trend analysis' section of your homepage.
          </p>
          <PublishConfirmModalButtons isPublishing={isPublishing} close={close} />
        </div>
      </form>
    );
  }

  if (isManager) {
    return (
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <p className="font-size-18 text-align-center">
            Publish this report to individual dashboards and/or your team dashboard.{' '}
            The report will display in the 'Trend analysis' section of the homepage.
          </p>
          <div className="margin-top-xx-large display-flex flex-gap-xx-large justify-content-center">
            <div className="flex-column">
              <div className="display-flex justify-content-center">
                <div>
                  <Field
                    className="checkbox-color-text"
                    name="members"
                    component="input"
                    type="checkbox"
                    id="members"
                    disabled={isPublishing}
                  />
                </div>
                <div>
                  <label
                    className="margin-left-small margin-bottom-small margin-top-small fwb"
                    htmlFor="members"
                  >
                    Individual dashboards
                  </label>
                </div>
              </div>
            </div>
            <div className="flex-column">
              <div className="display-flex justify-content-center">
                <div>
                  <Field
                    className="checkbox-color-text"
                    name="my_team"
                    component="input"
                    type="checkbox"
                    id="my_team"
                    disabled={isPublishing}
                  />
                </div>
                <div>
                  <label
                    className="margin-left-small margin-bottom-small margin-top-small fwb"
                    htmlFor="my_team"
                  >
                    'My team' dashboard
                  </label>
                </div>
              </div>
            </div>
          </div>
          <p className="font-size-14 text-align-center font-style-italic margin-top-xx-large">
            Note: When published to individuals,{' '}
            their reports will display their own results rather than the team results.
          </p>
          <PublishConfirmModalButtons selectionRequired={selectionRequired} isPublishing={isPublishing} close={close} />
        </div>
      </form>
    );
  }

  if (!isAdminUser) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <p className="font-size-18 text-align-center">
          Publish this report to individual, team, and/or executive dashboards.{' '}
          The report will display in the 'Trend analysis' section of the homepage.
        </p>
        <div className="margin-top-x-large display-flex flex-gap-xx-large justify-content-center">
          <div className="flex-column">
            <div className="display-flex justify-content-center">
              <div>
                <Field
                  className="checkbox-color-text"
                  name="members"
                  component="input"
                  type="checkbox"
                  id="members"
                  disabled={isPublishing}
                />
              </div>
              <div>
                <label
                  className="margin-left-small margin-bottom-small margin-top-small fwb"
                  htmlFor="members"
                >
                  Individual
                </label>
              </div>
            </div>
            <div>
              (Can view own scores)
            </div>
          </div>
          <div className="flex-column">
            <div className="display-flex justify-content-center">
              <div>
                <Field
                  className="checkbox-color-text"
                  name="managers"
                  component="input"
                  type="checkbox"
                  id="managers"
                  disabled={isPublishing}
                />
              </div>
              <div>
                <label
                  className="margin-left-small margin-bottom-small margin-top-small fwb"
                  htmlFor="managers"
                >
                  Managers
                </label>
              </div>
            </div>
            <div>
              (Can view assigned team's scores)
            </div>
          </div>
          <div className="flex-column">
            <div
              className="display-flex justify-content-center"
              title={
                isInternalBenchmark &&
                'Can not publish internal benchmarking reports to the executive dashboard'
              }
            >
              <div>
                <Field
                  className="checkbox-color-text"
                  name="executive"
                  component="input"
                  type="checkbox"
                  id="executive"
                  disabled={isPublishing || isInternalBenchmark}
                />
              </div>
              <div>
                <label
                  className={classNames('margin-left-small margin-bottom-small margin-top-small fwb', {
                    disabled: isInternalBenchmark
                  })}
                  htmlFor="executive"
                >
                  Executive
                </label>
              </div>
            </div>
            <div className={classNames({disabled: isInternalBenchmark})}>
              (Can view full set of scores)
            </div>
          </div>
        </div>
        {publishingError && (
          <p className="error margin-top-large text-align-center">{publishingError}</p>
        )}
        <p className="font-size-14 text-align-center font-style-italic margin-top-xx-large">
          Note: When published, the report will automatically be filtered depending on each user's permissions.
        </p>
        <div className="margin-top-xx-large text-align-right">
          <button
            className="pink-button"
            type="submit"
            disabled={isPublishing || selectionRequired}
          >
            Publish
          </button>
          <button
            className="pink-border-button margin-left-x-large"
            onClick={close}
            disabled={isPublishing}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export const FormifiedPublishConfirmModal = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(PublishConfirmModal);

const mapStateToProps = (state, props) => {
  const hasSelectedOption = Object.values(
    (getFormValues(FORM_NAME)(state) || {})
  ).find(value => value);
  return {
    publishingError: state.publishCustomReportError[props.reportId],
    isPublishing: isPending(state.responses, PUBLISH_CUSTOM_REPORT),
    isEmployee: isEmployeeBubbleUser(state.loggedInUser),
    isManager: isManagerUser(state.loggedInUser),
    isAdminUser: userMeetsRequiredRole(state.loggedInUser, USER_ROLES.MANAGER_USER_WITH_ADMIN),
    selectionRequired: userMeetsRequiredRole(state.loggedInUser, USER_ROLES.MANAGER_USER) && !hasSelectedOption
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  close: (event) => {
    event.preventDefault();
    dispatch(closeModal());
  },
  onSubmit: (data) => {
    dispatch(publishCustomReportAndCloseModal(props.reportId, data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormifiedPublishConfirmModal);
