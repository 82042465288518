import React from 'react';  //eslint-disable-line no-unused-vars

const FurtherInsightsForSubsection = ({suggestions, recommendation}) => {
  const furtherInsightsCount = recommendation.get('answers_for_subsection');
  return !suggestions.get('hide_further_insights_stat') &&
  (
    <div className="text-center recommendations-top-priority">
      {recommendation.get('response')}.
      {' '}{furtherInsightsCount}{' '}
      {furtherInsightsCount === 1 ? 'person' : 'people'}{' '}
      provided further insights into this topic.
    </div>
  );
};

export default FurtherInsightsForSubsection;
