import { ModalRenderer } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line
import { isPending } from '@dabapps/redux-requests';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import roles from '^/roles';
import { getQueryParamsFromLocation } from '^/utils';
import { userMeetsRequiredRole } from '^/utils/permissions';
import {
  toggleAddCompanyOkr,
  retrieveOkrList,
  RETRIEVE_OKR_LIST,
  OKR_LIST_PAGE_SIZE,
  OKR_FILTERED_LIST_PAGE_SIZE
} from './actions';
import { OKR_TYPE, OKR_STATUS_OPTIONS, CREATE_OKR_FORM } from './constants';
import CreateUpdateOkrForm from './CreateUpdateOkrForm';
import OkrTile from './OkrTile';
import Loading from '^/components/Loading';

const AUTO_STATUS_HINT = 'Status calculated from linked OKRs';
const MANUAL_STATUS_HINT = 'Status updated manually';

function canEditFilteredOkr(okr, isManager, isManagerAdmin) {
  if (okr.type === OKR_TYPE.COMPANY) {
    return isManagerAdmin;
  }
  if (okr.type === OKR_TYPE.MANAGER) {
    return isManager;
  }
  return true;
}

function getFilteredOkrLockedType(filteredOkr, filteredByReportsTo) {
  if (filteredOkr.type === OKR_TYPE.COMPANY) {
    return OKR_TYPE.COMPANY;
  }
  if (filteredByReportsTo || !!filteredOkr.parent_okr_manager_id) {
    return OKR_TYPE.EMPLOYEE;
  }
}

function getStatusCalculationHint(okr) {
  if (okr.type === OKR_TYPE.MANAGER) {
    return okr.auto_update_status ? AUTO_STATUS_HINT : MANUAL_STATUS_HINT;
  }
  if (okr.type === OKR_TYPE.EMPLOYEE && !okr.auto_update_status) {
    return MANUAL_STATUS_HINT;
  }
}

function getLockedOkrType(okr) {
  if (okr.type === OKR_TYPE.MANAGER && okr.child_okrs.length) {
    return OKR_TYPE.MANAGER;
  }
}

export function OkrsList(props) {
  const showEmpty = !props.addCompanyOkr && !props.okrs.length;
  return (
    <ChadminList
      loadList={props.retrieveOkrList}
      className="will padding-xx-large"
      ExtraControlsLeft={() => (
        <div className="display-flex align-items-center margin-bottom-base">
          {props.isManagerAdmin && !props.isLoading && !props.isFilteredList && (
            <button
              className="btn middle orange margin-right-large"
              onClick={props.toggleAddCompanyOkr}
              disabled={props.addCompanyOkr}
            >
              New company goal
            </button>
          )}
        </div>
      )}
      ExtraControlsRight={() => (
        <div>
          <UriFilterSelect
            filterName="status"
            name="status"
            options={[
              {
                value: '',
                label: 'All'
              },
              ...OKR_STATUS_OPTIONS,
            ]}
            hideBlankOption
          />
          <UriFilterSelect
            filterName="reports_to"
            name="manager"
            label="Reports To"
            options={props.reportsToOptions}
          />
        </div>
      )}
      searchable
      pullSearchRight
      hidePageSize
      pageSize={props.isFilteredList ? OKR_FILTERED_LIST_PAGE_SIZE : OKR_LIST_PAGE_SIZE}
      collection={props.okrListCollection}
    >
      {props.isLoading ? (
        <Loading />
      ) : (
        <div className={classNames({
          'well overflow-auto padding-top-base': !showEmpty
        })}>
          {props.addCompanyOkr && (
            <CreateUpdateOkrForm
              lockedOkrType={OKR_TYPE.COMPANY}
              cancel={props.toggleAddCompanyOkr}
              form={CREATE_OKR_FORM}
              largeContainer
              ownerSelectId="add-company-okr-owner-select"
            />
          )}
          {!props.isFilteredList && !!props.okrs.length && props.okrs.map(companyGoal => (
            <OkrTile
              key={companyGoal.id}
              data={companyGoal}
              lockedOkrType={OKR_TYPE.COMPANY}
              canNotEdit={!props.isManagerAdmin}
              statusCalculationHint={AUTO_STATUS_HINT}
            >
              {companyGoal.child_okrs && companyGoal.child_okrs.map(managerOrEmployeeOkr => (
                <OkrTile
                  key={managerOrEmployeeOkr.id}
                  data={managerOrEmployeeOkr}
                  canNotEdit={managerOrEmployeeOkr.type === OKR_TYPE.MANAGER && !props.isManager}
                  statusCalculationHint={getStatusCalculationHint(managerOrEmployeeOkr)}
                  lockedOkrType={getLockedOkrType(managerOrEmployeeOkr)}
                  isMiddleLayer
                >
                  {managerOrEmployeeOkr.child_okrs &&
                    managerOrEmployeeOkr.child_okrs.map(employeeOkr => (
                    <OkrTile
                      key={employeeOkr.id}
                      data={employeeOkr}
                      isLastLayer
                      lockedOkrType={OKR_TYPE.EMPLOYEE}
                      managerId={managerOrEmployeeOkr.owner_id}
                      managerName={managerOrEmployeeOkr.owner_name}
                      noAutoUpdateParentStatus={!managerOrEmployeeOkr.auto_update_status}
                      parentOkrName={managerOrEmployeeOkr.name}
                    />
                  ))}
                </OkrTile>
              ))}
            </OkrTile>
          ))}
          {props.isFilteredList && !!props.okrs.length && props.okrs.map(filteredOkr => (
            <OkrTile
              key={filteredOkr.id}
              data={filteredOkr}
              lockedOkrType={getFilteredOkrLockedType(filteredOkr, props.filteredByReportsTo)}
              managerId={filteredOkr.parent_okr_manager_id}
              managerName={filteredOkr.parent_okr_manager_name}
              canNotEdit={!canEditFilteredOkr(filteredOkr, props.isManager, props.isManagerAdmin)}
              parentOkrName={filteredOkr.parent_okr_name}
              isFilteredList
            />
          ))}
          {showEmpty && (
            <p>None Found.</p>
          )}
        </div>
      )}
      <ToastContainer />
      <ModalRenderer modals={props.modals} />
    </ChadminList>
  );
}

function mapStateToProps(state) {
  const filters = state.okrListCollection.get('filters');
  return {
    modals: state.modals,
    okrs: state.okrList,
    okrListCollection: state.okrListCollection,
    isFilteredList: (filters && !!Object.entries(filters).length) || state.okrListCollection.get('searchString'),
    addCompanyOkr: state.addCompanyOkr,
    isLoading: isPending(state.responses, RETRIEVE_OKR_LIST),
    isManagerAdmin: userMeetsRequiredRole(state.loggedInUser, roles.MANAGER_USER_WITH_ADMIN),
    isManager: userMeetsRequiredRole(state.loggedInUser, roles.MANAGER_USER),
    reportsToManagerId: getQueryParamsFromLocation().reports_to
  };
}

function mapDispatchToProps(dispatch) {
  return {
    retrieveOkrList: options => dispatch(retrieveOkrList(options)),
    toggleAddCompanyOkr: event => {
      event.preventDefault();
      dispatch(toggleAddCompanyOkr());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OkrsList);
