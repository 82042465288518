import Highcharts, { HIGHCHARTS_CONFIG_DEFAULTS } from './highcharts';

import {
  getChartExportButtons,
  chartExportTitle,
  HEATMAP_CELL_WIDTH,
  getHeatmapChartWidth,
  getHeatmapChartHeight
} from '^/components/custom-reports/utils';

function getPointCategoryName(point, dimension) {
  var series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x']];
}

function getAxisClassName(value, boldValues) {
  if (boldValues.indexOf(value) === -1) {
    return '';
  }
  else {
    return 'bold-y-axis-chart-label';
  }
}

function getYAxisPlotLineObjectsFromValues(boldPlotLineIndexesList) {
  var plotLineList = [];
  boldPlotLineIndexesList.forEach(value => {
    plotLineList.push({
      color: '#888',
      width: 2,
      value: value,
      zIndex: 5,
    });
  });
  return plotLineList;
}

function shrinkStringToCharacters(string, numberOfCharacters) {
  if (string.length > numberOfCharacters) {
    return string.substring(0, numberOfCharacters) + '...';
  }
  else {
    return string;
  }
}

export function renderHeatmapChart(
  elementId,
  data,
  xAxis,
  yAxis,
  boldYAxisLabels,
  boldXAxisLabels,
  boldPlotLineIndexesList,
  minWidth,
  editButtonOnClick,
  hasGroupingOptions,
  title = '',
  marginTop = 140
) {
  const chartContainerWidth = document.getElementById(elementId).clientWidth;
  const chartCellsMaxWidth = xAxis.length * HEATMAP_CELL_WIDTH;

  const defaultLeftMargin = 200;
  const defaultRightMargin = 75;

  const marginSpace = chartContainerWidth - chartCellsMaxWidth;
  const marginOffset = 50;
  const calculatedLeftMargin = (marginSpace / 2) + marginOffset;
  let calculatedRightMargin = (marginSpace / 2) - marginOffset;

  let marginLeft = calculatedLeftMargin > defaultLeftMargin ? calculatedLeftMargin : defaultLeftMargin;
  let legendXOffset = 0;

  if (elementId === 'heatmap-chart') {
    marginLeft = defaultLeftMargin;
    calculatedRightMargin = chartContainerWidth - chartCellsMaxWidth - defaultLeftMargin;
    legendXOffset = xAxis.length < 12 ? 0 - ((12 - xAxis.length) * HEATMAP_CELL_WIDTH / 2) : 0;
  }

  const marginRight = calculatedRightMargin > defaultRightMargin ? calculatedRightMargin : defaultRightMargin;

  const isLargeHeatmap = xAxis.length > 7;

  const borderColor = '#ddd';
  const chartConfig = {
    type: 'heatmap',
    marginTop,
    marginBottom: 80,
    marginLeft,
    marginRight,
    plotBorderWidth: 1,
    plotBorderColor: borderColor,
    scrollablePlotArea: {
      minWidth,
      scrollPositionX: 0,
    }
  };
  const exporting = {
    filename: title,
    buttons: getChartExportButtons(editButtonOnClick, elementId),
    sourceWidth: isLargeHeatmap ? getHeatmapChartWidth(xAxis) : 600,
    sourceHeight: isLargeHeatmap ? getHeatmapChartHeight(yAxis) : 800,
    chartOptions: {
      chart: {
        marginLeft: 150,
        marginRight: 70,
      },
      title: chartExportTitle,
    }
  };

  Highcharts.chart(elementId, Object.assign(HIGHCHARTS_CONFIG_DEFAULTS, {
    chart: chartConfig,

    title: {
      text: title,
      widthAdjust: hasGroupingOptions ? -360 : -200,
      style: {
        lineHeight: '26px'
      },
      y: 20
    },

    xAxis: {
      categories: xAxis,
      opposite: true,
      tickInterval: 1,
      tickLength: 10,
      tickWidth: 1,
      tickColor: borderColor,
      labels: {
        rotation: -55,
        y: -15,
        formatter () {
          const lines = this.value.split('<br/>');
          const truncatedLines = lines.map(line => shrinkStringToCharacters(line, 24));
          return (
            `<span class="${getAxisClassName(this.value, boldXAxisLabels)}">
              ${truncatedLines.join('<br/>')}
            </span>`
          );
        },
        style: {
          whiteSpace: 'normal',
        }
      },
      gridLineColor: borderColor,
    },

    yAxis: {
      categories: yAxis,
      reversed: true,
      title: null,
      tickInterval: 1,
      tickLength: 10,
      tickWidth: 1,
      tickColor: borderColor,
      plotLines: getYAxisPlotLineObjectsFromValues(boldPlotLineIndexesList),
      labels: {
        formatter () {
          return `<span class="${getAxisClassName(this.value, boldYAxisLabels)}">${this.value}</span>`;
        },
      },
      gridLineColor: borderColor,
    },

    series: [{
      name: 'average scores',
      borderWidth: 0,
      borderColor: borderColor,
      data: data.map(d => {
        const yAxisLabel = yAxis[d[1]];
        const xAxisLabel = xAxis[d[0]];
        if (yAxisLabel === 'Completions (n)' || yAxisLabel === 'Completions (%)') {
          return {
            x: d[0],
            y: d[1],
            value: d[2],
            color: 'white',
          };
        }
        if (xAxisLabel === 'Variance') {
          let color = '#fbddce';
          if (d[2] > 0) {
            color = '#c7e7cf';
          } else if (d[2] < 0) {
            color = '#ffc8c8';
          }
          return {
            x: d[0],
            y: d[1],
            value: d[2] <= 0 ? d[2] : `+${d[2]}`,
            color: color,
          };
        }
        return d;
      }),
      dataLabels: {
        enabled: true,
        useHTML: true,
        color: '#000',
        style: {
          textOutline: 'none',
        },
        formatter: function () {
          if (this.point.y === 0) {
            return '<span class="font-weight-extrabold">' + this.point.value + '</span>';
          } else {
            return '<span class="font-weight-normal">' + this.point.value + '</span>';
          }
        }
      },
      turboThreshold: 0,
    }],

    colorAxis: {
      stops: [
          [0.5, '#e77270'],
          [0.67, '#ffeb84'],
          [0.84, '#63be7b']
      ],
      min: 0,
      max: 100,
    },

    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var ix = point.index + 1,
            xName = getPointCategoryName(point, 'x'),
            yName = getPointCategoryName(point, 'y'),
            val = point.value;
          return ix + '. ' + xName + ' category ' + yName + ', ' + val + '%.';
        }
      }
    },

    legend: {
      x: legendXOffset
    },

    tooltip: {
      style: {
        zIndex: 10,
      },
      formatter: function () {
        const categoryName = getPointCategoryName(this.point, 'y');
        const lineBreak = (categoryName === 'Completions (n)') ? '</b> in <br><b>' : '%</b> in <br><b>';
        return '<b>' + getPointCategoryName(this.point, 'x') + '</b> scored <br><b>' +
          this.point.value + lineBreak + getPointCategoryName(this.point, 'y') + '</b>';
      },
      positioner: function (w, h, point) {
        this.chart.pointer.chartPosition = null;
        return this.getPosition(w, h, point);
      }
    },
    credits: false,
    exporting
  }));
}
