import React from 'react'; // eslint-disable-line no-unused-vars
import { Field, reduxForm } from 'redux-form';

export function CommentsForm({
  handleSubmit,
  isDisabled,
  title,
  field,
  cancel,
  pristine
}) {
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor={field} className="font-weight-bold font-size-14">
        {title}
      </label>
      <Field
        name={field}
        id={field}
        component="textarea"
        disabled={isDisabled}
      />
      <button
        disabled={isDisabled || pristine}
        className="btn middle orange margin-top-x-large"
      >
        Save
      </button>
      {cancel && (
        <button
          className="btn middle orange hollow margin-top-x-large margin-left-large"
          onClick={cancel}
          disabled={isDisabled}
        >
          Cancel
        </button>
      )}
    </form>
  );
}

export default reduxForm({
  destroyOnUnmount: false,
})(CommentsForm);
