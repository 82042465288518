import * as React from 'react';  // eslint-disable-line no-unused-vars

import ChadminList from '^/components/common/ChadminList';
import { personalReviewListConfig } from '^/components/reviews/config';
import { PERSONAL_REVIEW_LIST } from '^/components/reviews/constants';

const PersonalReviewList = ({userId, performanceReviewTerm}) => (
  <div className="will">
    <ChadminList
      collectionPath={`reviews/personal-review-responses/${userId}`}
      collectionName={PERSONAL_REVIEW_LIST}
      columns={personalReviewListConfig(performanceReviewTerm)}
      hidePageSize={true}
    />
  </div>
);

export default PersonalReviewList;
