import { fromJS, List, Map } from 'immutable';
import moment from 'moment';

import * as actions from '^/actions/actions';
import { CREATE_AGENDA_GOAL } from '^/components/agendas/actions';
import { CREATE_CUSTOM_FILTER, UPDATE_ADDITIONAL_FILTER } from '^/components/filters/actions';
import {
  CREATE_GOAL_ADD_ACTION,
  CREATE_GOAL_UPDATE_ACTION,
  CREATE_GOAL_REMOVE_ACTION,
} from '^/components/goals/actions';
import { ACTION_STATUS } from '^/consts/actions';
import { CURRENT_USER } from '^/utils/template';

export function expandedItems(state = [], action) {
  switch (action.type) {
    case actions.TOGGLE_ITEM_EXPANDED:
      if (state.includes(action.itemId)) {
        return state.filter(itemId => itemId !== action.itemId);
      }
      return [...state, action.itemId];
    default:
      return state;
  }
}

export function survey(state = Map(), action) {
  switch (action.type) {
    case actions.GET_SURVEY.REQUEST:
      return state.set('isLoading', true);
    case actions.GET_SURVEY.FAILURE:
      return state.set('isLoading', false);
    case actions.GET_SURVEY.SUCCESS:
      return state
        .set('survey', fromJS(action.payload))
        .set('isLoading', false);
    case actions.UPDATE_SURVEY.REQUEST:
      return state
        .set('survey', action.meta.survey)
        .set('isLoading', true);
    case actions.UPDATE_SURVEY.SUCCESS:
      return state
        .set('isLoading', false);
    case actions.CLOSE_SURVEY.REQUEST:
      return state
        .set('isClosing', true);
    case actions.CLOSE_SURVEY.FAILURE:
    case actions.CLOSE_SURVEY.SUCCESS:
      return state
        .set('isClosing', false);
    case actions.REOPEN_SURVEY.REQUEST:
      return state
        .set('isReopening', true);
    case actions.REOPEN_SURVEY.FAILURE:
    case actions.REOPEN_SURVEY.SUCCESS:
      return state
        .set('isReopening', false);
    case actions.SEND_SURVEY.REQUEST:
      return state
        .set('message', null)
        .set('isSending', true);
    case actions.SEND_SURVEY.FAILURE:
      return state
        .set('message', action.payload.response.error)
        .set('isSending', false);
    case actions.SEND_SURVEY.SUCCESS:
      return state
        .set('message', action.payload && action.payload.detail)
        .set('isSending', false);
    default:
      return state;
  }
}

export function filterSpec(state = Map(), action) {
  switch (action.type) {
    case actions.SET_FILTER:
      return state.setIn([action.payload.model, action.payload.filterKey], action.payload.filterValue);
    case actions.SET_FILTER_BULK:
      return state.set(action.payload.model, fromJS(action.payload.filters));
    default:
      return state;
  }
}

export function actionReducer(state = Map(), action) {
  switch (action.type) {
    case actions.UPDATE_ACTION_DESCRIPTION.REQUEST:
      return state
        .set('isLoading', true)
        .set('hasUpdated', false)
        .set('errors', Map());
    case actions.UPDATE_ACTION_DESCRIPTION.FAILURE:
      return state
        .set('errors', fromJS(action.payload.response))
        .set('hasUpdated', false)
        .set('isLoading', false);
    case actions.UPDATE_ACTION_DESCRIPTION.SUCCESS:
      return state
        .setIn(['action', 'description'], action.meta.actionData.description)
        .set('hasUpdated', true)
        .set('isLoading', false);
    case actions.UPDATE_ACTION.REQUEST:
      return state
        .set('isLoading', true)
        .set('errors', Map());
    case actions.UPDATE_ACTION.FAILURE:
      return state
        .set('errors', fromJS(action.payload.response))
        .set('isLoading', false);
    case actions.UPDATE_ACTION.SUCCESS:
      return state
        .set('action', fromJS(action.payload))
        .set('hasUpdated', true)
        .set('isLoading', false);
    case actions.GET_ACTION_OPTIONS.REQUEST:
      return state
        .set('isLoading', true);
    case actions.GET_ACTION_OPTIONS.FAILURE:
      return state
        .set('isLoading', false);
    case actions.GET_ACTION_OPTIONS.SUCCESS:
      return state
        .set('options', fromJS(action.payload))
        .set('isLoading', false);
    case actions.GET_BASIC_ACTION.REQUEST:
    case actions.GET_ACTION.REQUEST:
      return state
        .set('errors', Map())
        .set('isLoading', true)
        .set('isFetchingAction', true);
    case actions.GET_BASIC_ACTION.FAILURE:
    case actions.GET_ACTION.FAILURE:
      return state
        .set('errors', fromJS(action.payload.response))
        .set('isLoading', false)
        .set('isFetchingAction', false);
    case actions.GET_BASIC_ACTION.SUCCESS:
    case actions.GET_ACTION.SUCCESS:
      return state
        .set('action', fromJS(action.payload))
        .set('isLoading', false)
        .set('isFetchingAction', false);
    case actions.CREATE_ACTION_EXPORT.REQUEST:
      return state
        .set('isLoading', true);
    case actions.CREATE_ACTION_EXPORT.SUCCESS:
      return state
        .set('export', fromJS(action.payload))
        .set('isLoading', false);
    case actions.CREATE_ACTION_EXPORT.FAILURE:
      return state
        .set('isLoading', false);
    case actions.GET_ACTION_EXPORT.REQUEST:
      return state
        .set('isLoading', true);
    case actions.GET_ACTION_EXPORT.SUCCESS:
      if (action.payload) {
        return state
          .set('exportUrl', action.payload.url)
          .set('isLoading', false);
      }
      return state;
    case actions.GET_ACTION_EXPORT.FAILURE:
      return state
        .set('isLoading', false);
    case actions.DELETE_ACTION.REQUEST:
      return state
        .set('isDeleted', false)
        .set('isLoading', true);
    case actions.DELETE_ACTION.SUCCESS:
      return state
        .set('isDeleted', true)
        .set('isLoading', false);
    case actions.DELETE_ACTION.FAILURE:
      return state
        .set('isLoading', false);
    default:
      return state;
  }
}

export const loggedInUser = (state = null) => state;

export function filterOptions(state = {}, action) {
  switch (action.type) {
    case actions.GET_FILTER_OPTIONS.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function subscriptionAllowances(state = Map(), action) {
  switch (action.type) {
    case actions.GET_SUBSCRIPTION_ALLOWANCES.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}

export function managerOptions(state = List(), action) {
  switch (action.type) {
    case actions.GET_MANAGER_OPTIONS.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}

export function selectedListItems(state = [], action) {
  switch (action.type) {
    case actions.CLEAR_ALL_SELECTED_LIST_ITEMS:
      return [];
    case actions.TOGGLE_LIST_ITEM_SELECTED:
      if (state.includes(action.itemId)) {
        return state.filter(itemId => itemId !== action.itemId);
      }
      return [...state, action.itemId];
    case actions.CLEAR_SELECTED_LIST_ITEMS:
      return state.filter(itemId => !action.itemIds.includes(itemId));
    case actions.ADD_SELECTED_LIST_ITEMS:
      return [...state, ...action.itemIds.filter(itemId => !state.includes(itemId))];
    default:
      return state;
  }
}

const createGoalActionsDefaultState = [{
  title: '',
  owner: CURRENT_USER && CURRENT_USER.id,
  due_date: moment().add(1, 'days'),
  status: ACTION_STATUS.NOT_STARTED
}];
export function createGoalActions(state = createGoalActionsDefaultState, action) {
  switch (action.type) {
    case CREATE_GOAL_UPDATE_ACTION:
      return fromJS(state).setIn([action.index, action.key], action.value).toJS();
    case CREATE_GOAL_ADD_ACTION:
      if (action.owner) {
        return state.concat([Object.assign({}, createGoalActionsDefaultState[0], {
          owner: action.owner.value
        })]);
      }
      return state.concat(createGoalActionsDefaultState);
    case CREATE_GOAL_REMOVE_ACTION:
      if (state.length > 1) {
        return state.filter((_, index) => index !== action.index);
      }
    case CREATE_AGENDA_GOAL.SUCCESS:
      return createGoalActionsDefaultState;
    default:
      return state;
  }
}

export function genericErrorMessages(state = [], action) {
  switch (action.type) {
    case CREATE_CUSTOM_FILTER.FAILURE:
    case UPDATE_ADDITIONAL_FILTER.FAILURE:
      return action.payload.response.data.options;
    case actions.UPDATE_GENERIC_ERROR_MESSAGES:
      return action.errorMessages;
    default:
      return state;
  }
}

export function orgPermissions(state = Map(), action) {
  switch (action.type) {
    case actions.GET_ORG_PERMISSIONS.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}
