import React from 'react'; // eslint-disable-line no-unused-vars
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Field } from 'redux-form';
import { Column, Row } from '@dabapps/roe';

import FormError from '^/components/forms/FormError';
import ScrollableTextArea from '^/components/forms/ScrollableTextArea';
import {
  FORM_INPUT_COLUMNS,
  FORM_LABEL_COLUMNS,
  ROE_TOTAL_COLUMS,
} from './constants';

const CustomEmailTemplateField = ({
  component,
  disabled,
  fieldClassName,
  fieldName,
  label,
  placeholder,
  suffix,
  response,
  children,
  wrapTextArea
}) => (
  <Row className="margin-bottom-large">
    <Column xs={ROE_TOTAL_COLUMS} sm={FORM_LABEL_COLUMNS}>
      <label className="input-line-height">{label}</label>
    </Column>
    <Column xs={ROE_TOTAL_COLUMS} sm={FORM_INPUT_COLUMNS}>
      <ScrollableTextArea wrap={wrapTextArea}>
        <Field
          name={fieldName}
          type="text"
          component={component || 'input'}
          placeholder={placeholder}
          disabled={disabled}
          className={classNames(fieldClassName, suffix ? 'width-auto' : '')}
        >
          {children}
        </Field>
      </ScrollableTextArea>
      {suffix && (<span className="margin-left-base">{suffix}</span>)}
      <FormError response={response} formKey={fieldName}/>
    </Column>
  </Row>
);

CustomEmailTemplateField.propTypes = {
  component: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  fieldClassName: React.PropTypes.string,
  fieldName: React.PropTypes.string.isRequired,
  label: React.PropTypes.string.isRequired,
  placeholder: React.PropTypes.string.isRequired,
  suffix: React.PropTypes.string,
  response: ImmutablePropTypes.map,
};

export { CustomEmailTemplateField };

export default CustomEmailTemplateField;
