import { request } from '@dabapps/redux-requests';
import { makeAsyncActionSet } from '^/actions/actions';


export const UPDATE_CUSTOM_QUESTION_OPTIONS = 'UPDATE_CUSTOM_QUESTION_OPTIONS';
export function updateCustomQuestionOptions(options) {
  return {
    type: UPDATE_CUSTOM_QUESTION_OPTIONS,
    options
  };
}

export const UPDATE_CUSTOM_QUESTION_OPTION = 'UPDATE_CUSTOM_QUESTION_OPTION';
export function updateCustomQuestionOption(index, optionText) {
  return {
    type: UPDATE_CUSTOM_QUESTION_OPTION,
    index,
    optionText
  };
}

export const REMOVE_CUSTOM_QUESTION_OPTION = 'REMOVE_CUSTOM_QUESTION_OPTION';
export function removeCustomQuestionOption(index, minimumNumberOfOptions) {
  return {
    type: REMOVE_CUSTOM_QUESTION_OPTION,
    index,
    minimumNumberOfOptions
  };
}

export const ADD_CUSTOM_QUESTION_OPTION = 'ADD_CUSTOM_QUESTION_OPTION';
export function addCustomQuestionOption() {
  return {
    type: ADD_CUSTOM_QUESTION_OPTION
  };
}

export const GET_CUSTOM_QUESTION_FOR_EDIT = makeAsyncActionSet('GET_CUSTOM_QUESTION_FOR_EDIT');
export function getCustomQuestionForEdit(questionId) {
  return (dispatch) => {
    return request(
      GET_CUSTOM_QUESTION_FOR_EDIT,
      `/api/custom-questions/${questionId}/edit/`,
      'GET'
    )(dispatch);
  };
}
