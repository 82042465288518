import React from 'react'; // eslint-disable-line no-unused-vars

const CustomReportCheckbox = (props) => {
  const {
    id,
    value,
    checked,
    onChange,
    labelText,
    disabled
  } = props;

  return (
    <div className="display-flex align-items-center">
      <input
        id={id}
        type="checkbox"
        value={value}
        className="grey-checkbox margin-right-base"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id} className="margin-right-base">
        { labelText }
      </label>
    </div>
  );
};

export default CustomReportCheckbox;
