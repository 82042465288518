import { makeAsyncActionSet, request } from '@dabapps/redux-requests';

import { shouldRethrow } from '^/actions/actions';

export const CREATE_GOAL = makeAsyncActionSet('CREATE_GOAL');
export function createGoal(data) {
  return (dispatch) => {
    return request(
      CREATE_GOAL,
      `/api/goals/create-goal/`,
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export const ADD_ACTION_TO_GOAL = makeAsyncActionSet('ADD_ACTION_TO_GOAL');
export function addActionToGoal(goalId, data) {
  return (dispatch) => {
    return request(
      ADD_ACTION_TO_GOAL,
      `/api/goals/${goalId}/add-action/`,
      'POST',
      data,
      { metaData: { goalId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export function createGoalAndRedirectToGoalList(data) {
  return (dispatch) => {
    return createGoal(data)(dispatch).then(() => {
      window.location.href = '/goals/';
    });
  };
}

export function createGoalAndRedirectToGoalDetail(data) {
  return (dispatch) => {
    return createGoal(data)(dispatch).then((response) => {
      window.location.href = `/goals/${response.data.id}/`;
    });
  };
}

export const CREATE_GOAL_UPDATE_ACTION = 'CREATE_GOAL_UPDATE_ACTION';
export function createGoalUpdateAction(index, key, value) {
  return {
    type: CREATE_GOAL_UPDATE_ACTION,
    index,
    key,
    value
  };
}

export const CREATE_GOAL_ADD_ACTION = 'CREATE_GOAL_ADD_ACTION';
export function createGoalAddAction(owner) {
  return {
    type: CREATE_GOAL_ADD_ACTION,
    owner
  };
}

export const CREATE_GOAL_REMOVE_ACTION = 'CREATE_GOAL_REMOVE_ACTION';
export function createGoalRemoveAction(index) {
  return {
    type: CREATE_GOAL_REMOVE_ACTION,
    index
  };
}

export const TOGGLE_ADD_ACTION_FORM = 'TOGGLE_ADD_ACTION_FORM';
export function toggleAddActionForm() {
  return {
    type: TOGGLE_ADD_ACTION_FORM
  };
}

export const GET_GOAL = makeAsyncActionSet('GET_GOAL');
export function getGoal(goalId) {
  return (dispatch) => {
    return request(
      GET_GOAL,
      `/api/goals/${goalId}/`,
      'GET'
    )(dispatch);
  };
}

export function addActionToGoalAndReload(goalId, data) {
  return (dispatch) => {
    return addActionToGoal(goalId, data)(dispatch).then(() => {
      getGoal(goalId)(dispatch);
    });
  };
}

export const UPDATE_GOAL = makeAsyncActionSet('UPDATE_GOAL');
export function updateGoal(goalId, goalData, isAgendaGoal = false) {
  return (dispatch) => {
    return request(
      UPDATE_GOAL,
      `/api/goals/${goalId}/${isAgendaGoal ? '?is_agenda_goal=true' : ''}`,
      'PATCH',
      goalData,
      { shouldRethrow }
    )(dispatch);
  };
}

export const DELETE_GOAL = makeAsyncActionSet('DELETE_GOAL');
export function deleteGoal(goalId) {
  return (dispatch) => {
    return request(
      DELETE_GOAL,
      `/api/goals/${goalId}/`,
      'DELETE'
    )(dispatch);
  };
}

export function deleteGoalThenRedirectToGoalsList(goalId) {
  return (dispatch) => {
    return deleteGoal(goalId)(dispatch).then(() => {
      window.location.href = '/goals/';
    });
  };
}

export function updateGoalAndRedirectToIt(goalId, goalData) {
  return (dispatch) => {
    return updateGoal(
      goalId,
      goalData
    )(dispatch).then(() => {
      window.location.href = `/goals/${goalId}/`;
    });
  };
}

export const ACTIONS_PICKER_EDIT_ACTION = 'ACTIONS_PICKER_EDIT_ACTION';
export function actionPickerEditAction(actionId) {
  return {
    type: ACTIONS_PICKER_EDIT_ACTION,
    payload: {
      actionId,
    },
  };
}

export const ACTIONS_PICKER_EXCLUDE_ACTION = 'ACTIONS_PICKER_EXCLUDE_ACTION';
export function actionPickerExcludeAction(actionId) {
  return {
    type: ACTIONS_PICKER_EXCLUDE_ACTION,
    payload: {
      actionId,
    },
  };
}

export const ACTIONS_PICKER_REINCLUDE_ACTION =
  'ACTIONS_PICKER_REINCLUDE_ACTION';
export function actionPickerReincludeAction(actionId) {
  return {
    type: ACTIONS_PICKER_REINCLUDE_ACTION,
    payload: {
      actionId,
    },
  };
}
