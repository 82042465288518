import React from 'react'; // eslint-disable-line no-unused-vars
import CommentsForm from './CommentsForm';
import { connect } from 'react-redux';
import {
  agendaItemEditAction,
  agendaItemCancelEdit,
  updateAgendaAndToast
} from '^/components/agendas/actions';

const Comments = ({comments, editComments, isDisabled}) => (
  <div>
    <p className="white-space-pre-line margin-bottom-x-large">
      {comments}
    </p>
    <button
      onClick={editComments}
      className="btn middle orange"
      disabled={isDisabled}
    >
      Edit
    </button>
  </div>
);

export const CommentSection = ({
  comments,
  submitFn,
  editFn,
  isEditing,
  isDisabled,
  title,
  field,
  initialValues,
  closeForm
}) => (
  <div>
    {title && (
      <h4 className="font-weight-bold margin-bottom-base font-size-14">
        {title}
      </h4>
    )}
    {(isEditing || !comments) ? (
      <CommentsForm
        onSubmit={submitFn}
        initialValues={initialValues}
        isDisabled={isDisabled}
        field={field}
        form={`commentsForm-${field}`}
        cancel={comments && closeForm}
      />
    ) : (
      <Comments
        comments={comments}
        editComments={editFn}
        isDisabled={isDisabled}
      />
    )}
  </div>
);

const mapStateToProps = (state, props) => {
  return {
    isEditing: state.editingAgendaItemIds.includes(props.editCommentsName),
  };
};

function mapDispatchToProps(dispatch, props) {
  const updateAction = props.updateAction || updateAgendaAndToast;
  return {
    editFn: () => dispatch(agendaItemEditAction(props.editCommentsName)),
    submitFn: data => dispatch(updateAction(
      props.agendaId,
      data,
      props.editCommentsName
    )),
    closeForm: event => {
      event.preventDefault();
      dispatch(agendaItemCancelEdit(props.editCommentsName));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentSection);
