import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import PinkSendIcon from '^/components/common/PinkSendIcon';
import AgendaResendModal from './AgendaResendModal';
import { AGENDA_LIST_BUTTON_DISABLED_TEXT } from './constants';

const AgendaListSendButton = ({openModal, isDisabled, canSendPdfSummary}) => (
  <button
    className="display-inline-block background-none"
    disabled={!canSendPdfSummary || isDisabled}
    onClick={openModal}
    title={!canSendPdfSummary ? AGENDA_LIST_BUTTON_DISABLED_TEXT : undefined
    }
  >
    <PinkSendIcon />
  </button>
);


function mapDispatchToProps(dispatch, props) {
  return {
    openModal: () => {
      dispatch(
        openSimpleModal({
          body: <AgendaResendModal agendaId={props.agendaId} isReadOnly={props.isReadOnly} />,
          title: 'Send PDF agenda',
        })
      );
    },
  };
}

export default connect(undefined, mapDispatchToProps)(AgendaListSendButton);
