import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal, openMediumModal } from '^/actions/modals';
import { REGARDING_OPTIONS } from '^/components/peer-feedback/constants';
import PeerFeedbackModalForm from '^/components/peer-feedback/PeerFeedbackModalForm';
import RequestFeedbackModalForm from '^/components/peer-feedback/RequestFeedbackModalForm';

export function PeerFeedbackListModalButtons({
  openRequestFeedbackModal,
  openPeerFeedbackModal,
}) {
  return (
    <div className="position-absolute top-0 right-0 padding-xx-large">
      <button
        className="pink-button"
        onClick={openRequestFeedbackModal}
      >
        Request feedback
      </button>
      <button
        className="pink-button margin-left-x-large"
        onClick={openPeerFeedbackModal}
      >
        Give feedback
      </button>
    </div>
  );
}

const mapDispatchToProps = (dispatch, props) => ({
  openRequestFeedbackModal: () => dispatch(
    openSimpleModal({
      titleComponent: (
        <h1 className="font-color-main font-size-38 font-family-effra-bold margin-bottom-none padding-left-large">
          Request feedback
        </h1>
      ),
      body: (
        <RequestFeedbackModalForm
          regarding={props.isIndividualList ? REGARDING_OPTIONS.ME : REGARDING_OPTIONS.OTHERS}
          reloadTeamListPage={props.reloadTeamListPage}
          reloadIndividualListPage={props.reloadIndividualListPage}
        />
      ),
      large: true
    })
  ),
  openPeerFeedbackModal: () => dispatch(
    openMediumModal({
      titleComponent: (
        <h1 className="font-color-main font-size-38 font-family-effra-bold margin-bottom-none padding-left-small">
          Give feedback
        </h1>
      ),
      body: (
        <PeerFeedbackModalForm reloadPage={props.reloadTeamListPage} />
      )
    })
  ),
});

export default connect(undefined, mapDispatchToProps)(PeerFeedbackListModalButtons);
