import { fromJS, List, Map } from 'immutable';

import * as actions from '^/components/okrs/actions';
import {
  OKR_TYPE,
  CREATE_OKR_FORM,
  getAddLinkedOkrFormName,
  getEditOkrFormName,
  OKR_ACTION_FIELD
} from '^/components/okrs/constants';
import {
  updateOkrById,
  moveNode,
  addNodeToTree,
  removeOkr,
  updateParentOkrsPercentageComplete
} from '^/components/okrs/util';
import roles from '^/roles';
import store from '^/store';
import { userMeetsRequiredRole } from '^/utils/permissions';
import { stripFalsyValuesFromObject, getQueryParamsFromLocation } from '^/utils';

export function okrData(state = null, action) {
  switch (action.type) {
    case actions.RETRIEVE_OKR.SUCCESS:
      return fromJS(action.payload.data);
    case actions.UPDATE_OKR_DETAIL.SUCCESS:
      return state.merge(fromJS(action.payload.data));
    case actions.UPDATE_OKR_ACTION.REQUEST:
      if (state.isEmpty()) {
        return state;
      }

      return state.set(
        'actions',
        state
          .get('actions')
          .map((okrAction) =>
            okrAction.get('id') === action.meta.actionId
              ? okrAction.merge(
                fromJS(action.meta.actionData).delete('owner')
              )
              : okrAction
          )
      );
    case actions.UPDATE_OKR_ACTION.SUCCESS:
      if (state.isEmpty()) {
        return state;
      }

      return state.set(
        'actions',
        state
          .get('actions')
          .map((okrAction) =>
            okrAction.get('id') === action.meta.actionId
              ? okrAction.merge(
                fromJS(action.meta.actionData).delete('owner')
              )
              : okrAction
          )
      );
    default:
      return state;
  }
}

export function okrPickerEditingAction(state = null, action) {
  switch (action.type) {
    case actions.OKR_ACTIONS_PICKER_EDIT_ACTION:
      return action.payload.actionId;
    case actions.ACTIONS_PICKER_EXCLUDE_ACTION:
      if (action.payload.actionId === state) {
        return null;
      }
      return state;
    case actions.UPDATE_OKR_ACTION.SUCCESS:
      const fieldsUpdated = Object.keys(action.meta.actionData);
      if (fieldsUpdated.length === 1) {
        const fieldUpdated = fieldsUpdated[0];
        if ([OKR_ACTION_FIELD.DUE_DATE, OKR_ACTION_FIELD.OWNER, OKR_ACTION_FIELD.STATUS].includes(fieldUpdated)) {
          return state;
        }
      }
      return null;
    default:
      return state;
  }
}

export function okrActionPickerExcludedActions(state = List(), action) {
  switch (action.type) {
    case actions.ACTIONS_PICKER_EXCLUDE_ACTION:
      return state.push(action.payload.actionId);
    case actions.ACTIONS_PICKER_REINCLUDE_ACTION:
      return state.filterNot(
        (actionId) => actionId === action.payload.actionId
      );
    default:
      return state;
  }
}

export function isShowingAddOkrActionForm(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_ADD_ACTION_FORM:
      return !state;
    case actions.ADD_ACTION_TO_OKR.SUCCESS:
      return false;
    default:
      return state;
  }
}

export function addCompanyOkr(state = false, action) {
  switch (action.type) {
    case actions.TOGGLE_ADD_COMPANY_OKR:
      return !state;
    case actions.CREATE_OKR.SUCCESS:
      if (action.meta.type === OKR_TYPE.COMPANY) {
        return false;
      }
      return state;
    default:
      return state;
  }
}

export function addLinkedOkr(state = [], action) {
  switch (action.type) {
    case actions.ADD_LINKED_OKR:
      return [...state, action.linkedOkrId];
    case actions.CANCEL_ADD_LINKED_OKR:
      return state.filter(okrId => okrId !== action.linkedOkrId);
    case actions.CREATE_OKR.SUCCESS:
      if (action.meta.type !== OKR_TYPE.COMPANY) {
        return state.filter(okrId => okrId !== action.meta.parentOkrId);
      }
    default:
      return state;
  }
}

export function editOkr(state = [], action) {
  switch (action.type) {
    case actions.EDIT_OKR:
      return [...state, action.okrId];
    case actions.CANCEL_EDIT_OKR:
      return state.filter(okrId => okrId !== action.okrId);
    case actions.UPDATE_OKR.SUCCESS:
      return state.filter(okrId => okrId !== action.meta.okrId);
    default:
      return state;
  }
}

function updateParentOkrsPercentageCompleteIfData(state, action) {
  const updatedParentOkrs = action.payload.data.updated_parent_okrs;
  if (updatedParentOkrs) {
    return updateParentOkrsPercentageComplete(state, updatedParentOkrs);
  }
  return state;
}

export function okrList(state = [], action) {
  switch (action.type) {
    case actions.RETRIEVE_OKR_LIST.SUCCESS:
      return action.payload.data.results;
    case actions.CREATE_OKR.SUCCESS:
      if (action.meta.type === OKR_TYPE.COMPANY) {
        return [
          Object.assign({}, action.payload.data, {child_okrs: []}),
          ...state
        ];
      } else {
        let newState = addNodeToTree(
          [...state],
          Object.assign({}, action.payload.data, {
            child_okrs: []
          })
        );
        return updateParentOkrsPercentageCompleteIfData(newState, action);
      }
    case actions.UPDATE_OKR.SUCCESS: {
      let newState = [...state];
      if (action.meta.parentIdChanged) {
        newState = moveNode(newState, action.payload.data);
      } else {
        newState = updateOkrById(newState, action.payload.data.id, action.payload.data);
      }
      return updateParentOkrsPercentageCompleteIfData(newState, action);
    }
    case actions.DELETE_OKR.SUCCESS: {
      let newState = removeOkr([...state], action.meta.okrId);
      return updateParentOkrsPercentageCompleteIfData(newState, action);
    }
    default:
      return state;
  }
}

const removeEntryFor = (state, key) => Object.fromEntries(
    Object.entries(state).filter((keyValue) => keyValue[0] !== key)
  );

export function createUpdateOkrErrors(state = {}, action) {
  switch (action.type) {
    case actions.CREATE_OKR.FAILURE:
    case actions.UPDATE_OKR.FAILURE:
      return Object.assign({}, state, {
        [action.meta.form]: Object.values(action.payload.response.data)
      });
    case actions.EDIT_OKR:
      return removeEntryFor(state, getEditOkrFormName(action.okrId));
    case actions.TOGGLE_ADD_COMPANY_OKR:
      return removeEntryFor(state, CREATE_OKR_FORM);
    case actions.ADD_LINKED_OKR:
      return removeEntryFor(state, getAddLinkedOkrFormName(action.linkedOkrId));
    default:
      return state;
  }
}

export function okrListCollection(state = Map(), action) {
  switch (action.type) {
    case actions.RETRIEVE_OKR_LIST.SUCCESS:
      return Map({
        count: action.payload.data.count,
        page: action.meta.page,
        searchString: action.meta.filters.search,
        filters: stripFalsyValuesFromObject({
          status: action.meta.filters.status,
          reports_to: action.meta.filters.reports_to
        })
      });
    default:
      return state;
  }
}

export function hasCollapsedOkrs(state = false, action) {
  switch (action.type) {
    case actions.COLLAPSE_OKR:
      return true;
    default:
      return state;
  }
}

const expandedCompanyGoal = getQueryParamsFromLocation().expanded_company_goal;

function getIdWithNestedParentOkrIds(companyGoal) {
  return ([
    companyGoal.id,
    ...(companyGoal.child_okrs || []).filter(
      childOkr => childOkr.child_okrs && !!childOkr.child_okrs.length
    ).map(childOkr => childOkr.id)
  ]);
}

export function expandedOkrs(state = [], action) {
  switch (action.type) {
    case actions.RETRIEVE_OKR_LIST.SUCCESS:
      const currentState = store.getState();

      if (
        userMeetsRequiredRole(currentState.loggedInUser, roles.MANAGER_USER_WITH_ADMIN)
        && expandedCompanyGoal
      ) {
        return [
          ...state,
          ...action.payload.data.results.filter(companyGoal =>
            companyGoal.id === expandedCompanyGoal &&
            companyGoal.child_okrs && !!companyGoal.child_okrs.length
          ).map(getIdWithNestedParentOkrIds).flat()
        ];
      }

      if (
        !currentState.hasCollapsedOkrs &&
        !userMeetsRequiredRole(currentState.loggedInUser, roles.MANAGER_USER_WITH_ADMIN)
      ) {
        return action.payload.data.results.filter(
          companyGoal => companyGoal.child_okrs && !!companyGoal.child_okrs.length
        ).map(getIdWithNestedParentOkrIds).flat();
      }
      return state;
    case actions.COLLAPSE_OKR:
      return state.filter(okrId => okrId !== action.okrId);
    case actions.EXPAND_OKR:
      return [...state, action.okrId];
    case actions.CREATE_OKR.SUCCESS:
      if (action.meta.type !== OKR_TYPE.COMPANY) {
        return [...state, action.meta.parentOkrId];
      }
      return state;
    default:
      return state;
  }
}
