import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Column, Row } from '@dabapps/roe';
import {
  updateCustomQuestionOptions,
  updateCustomQuestionOption,
  removeCustomQuestionOption,
  addCustomQuestionOption
} from '^/components/custom-questions/actions';
import { QUESTION_TYPES } from './constants';


class CustomQuestionOptions extends React.PureComponent {

  constructor(props) {
    super(props);
    this.updateCustomQuestionOption = this.updateCustomQuestionOption.bind(this);
    this.removeCustomQuestionOption = this.removeCustomQuestionOption.bind(this);
    this.addCustomQuestionOption = this.addCustomQuestionOption.bind(this);
    this.setDefaultMinimumOptionsForQuestionType = this.setDefaultMinimumOptionsForQuestionType.bind(this);
  }

  updateCustomQuestionOption(event) {
    this.props.updateCustomQuestionOption(event.target.getAttribute('data-index'), event.target.value);
  }

  removeCustomQuestionOption(event) {
    event.preventDefault();
    this.props.removeCustomQuestionOption(
      parseInt(event.target.getAttribute('data-index'), 10),
      this.props.minimumNumberOfOptions
    );
  }

  addCustomQuestionOption(event) {
    event.preventDefault();
    this.props.addCustomQuestionOption();
  }

  setDefaultMinimumOptionsForQuestionType() {
    if (this.props.questionType === QUESTION_TYPES.RADIO) {
      this.props.updateCustomQuestionOptions(['', '']);
    } else {
      this.props.updateCustomQuestionOptions(['']);
    }
  }

  componentDidMount() {
    const { initialOptions } = this.props;
    if (initialOptions && initialOptions.length) {
      this.props.updateCustomQuestionOptions(initialOptions);
    } else {
      this.setDefaultMinimumOptionsForQuestionType();
    }
  }

  componentWillUnmount() {
    this.props.updateCustomQuestionOptions([]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questionType !== this.props.questionType) {
      this.setDefaultMinimumOptionsForQuestionType();
    }
  }

  render() {
    const { customQuestionOptions, minimumNumberOfOptions } = this.props;
    const minimumSatisfied = customQuestionOptions.length > minimumNumberOfOptions;
    return (
      <div>
        {customQuestionOptions.map((optionText, index) => (
          <Row className="margin-top-small" key={index}>
            <Column xs={12} md={2}>
              <label className="input-line-height">Option {index + 1}</label>
            </Column>
            <Column xs={12} md={9}>
              <input type="text"
                data-index={index}
                onChange={this.updateCustomQuestionOption}
                placeholder={`Option ${index + 1}`}
                value={optionText}
                autoFocus={(minimumSatisfied && !optionText) ? (
                  index === customQuestionOptions.length - 1
                ) : undefined}
              />
            </Column>
            {minimumSatisfied && (
              <Column xs={12} md={1} className="custom-question-options padding-left-none">
                <a
                  href=""
                  className="toggle-inclusion-icon input-line-height padding-none float-left"
                  onClick={this.removeCustomQuestionOption}
                  data-index={index}
                >
                  <i data-index={index} className="far fa-times"></i>
                </a>
              </Column>
            )}
          </Row>
        ))}
        <Row className="margin-top-small margin-bottom-large">
          <Column xs={12} md={2}>
          </Column>
          <Column xs={12} md={10}>
            <a
              href=""
              className="btn orange hollow font-size-14"
              onClick={this.addCustomQuestionOption}
            >
              Add option
            </a>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({customQuestionOptions}) {
  return {
    customQuestionOptions
  };
}

export default connect(mapStateToProps, {
  updateCustomQuestionOptions,
  updateCustomQuestionOption,
  removeCustomQuestionOption,
  addCustomQuestionOption
})(CustomQuestionOptions);
