import React from 'react'; // eslint-disable-line no-unused-vars

export const WidgetTitle = ({title, image, subtitle}) => (
  <div className="flex-space-between align-items-center margin-bottom-large">
    <div>
      <h3 className="line-height-36px">{title}</h3>
      {subtitle && (
        <p
          className="font-family-varela-round font-size-18 margin-top-small margin-bottom-none"
        >
          {subtitle}
        </p>
      )}
    </div>
    <img
      src={image}
      alt={title}
      className="dashboard-widget-icon"
    />
  </div>
);

export default WidgetTitle;
