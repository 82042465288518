import classNames from 'classnames';
import * as React from 'react'; // eslint-disable-line no-unused-vars

import ChadminList from '^/components/common/ChadminList';
import QueryParamsFilterSelect from '^/components/common/QueryParamsFilterSelect';
import { getQueryParamsFromLocation } from '^/utils';
import { talentSnapshotListConfig } from './config';
import { TALENT_SNAPSHOT_LIST, TALENT_SNAPSHOT_LIST_URL } from './constants';

const getSelectedQuestionsAndOptions = (
  snapshotQuestionsAndOptions,
  selectedAnswerOptionsList
) => Object.fromEntries(
  selectedAnswerOptionsList.map(selectedOptionId => [
    (snapshotQuestionsAndOptions.find(question =>
      question.options.map(option => option.value).includes(selectedOptionId)
    ) || {}).id,
    selectedOptionId
  ])
);

const getSelectedQuestionsAndOptionsState = (
  snapshotQuestionsAndOptions
) => {
  const selectedAnswerOptions = getQueryParamsFromLocation().answer_options;
  let selectedAnswerOptionMapping = {};

  if (selectedAnswerOptions) {
    selectedAnswerOptionMapping = getSelectedQuestionsAndOptions(
      snapshotQuestionsAndOptions,
      selectedAnswerOptions.split(',')
    );
  }

  return selectedAnswerOptionMapping;
};

const Filter = (props) => (
  <div className="margin-right-base width-140">
    <QueryParamsFilterSelect {...props} />
  </div>
);

export class TalentSnapshotList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.formatAnswerOptionsValue = this.formatAnswerOptionsValue.bind(this);
    this.state = {
      selectedAnswerOptionMapping: getSelectedQuestionsAndOptionsState(this.props.snapshotQuestionsAndOptions)
    };
  }

  formatAnswerOptionsValue(questionId, optionId) {
    const selectedAnswerOptionMapping = Object.assign({}, this.state.selectedAnswerOptionMapping, {
      [questionId]: optionId
    });
    this.setState({selectedAnswerOptionMapping});
    return Object.values(selectedAnswerOptionMapping).filter(selectedOptionId => !!selectedOptionId).join(',');
  }

  render() {
    const { filterOptions: {
      team_options, location_options, department_options, unit_options, manager_options, reports_to_options
    }, organisationFilterTerms } = this.props;
    return (
      <div className="background-white padding-xx-large position-relative border-radius-22">
        <h1 className="talent-snapshot-list-title font-family-effra-bold font-size-38">
          Talent Snapshot
        </h1>
        <ChadminList
          collectionPath={TALENT_SNAPSHOT_LIST_URL}
          collectionName={TALENT_SNAPSHOT_LIST}
          getColumns={() => talentSnapshotListConfig(organisationFilterTerms)}
          className="border-base padding-large border-radius-16 margin-top-base"
          ExtraControlsRight={({setFilters}) => (
            <div>
              <div className="talent-snapshot-list-filters">
                <p className="font-size-24 margin-top-x-large margin-left-small">Select question(s):</p>
                <div className="display-flex align-items-center flex-wrap">
                  {this.props.snapshotQuestionsAndOptions.map(question => {
                    const value = this.state.selectedAnswerOptionMapping[question.id];
                    return (
                      <div key={`filter-select-${question.id}`} className="third-width padding-small">
                        <div className={classNames('padding-large border-radius-16', {
                          'border-base': !value,
                          'thick-orange-border': !!value,
                        })}>
                          <QueryParamsFilterSelect
                            options={question.options}
                            filterName="answer_options"
                            name={question.id}
                            label={question.name}
                            setFilters={setFilters}
                            formatFilterValue={this.formatAnswerOptionsValue}
                            labelClassName="font-size-16"
                            selectClassName="border-base"
                            value={value}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p className="font-size-24 margin-top-xx-large margin-left-small">People</p>
              </div>
              <div className="display-flex flex-wrap">
                <Filter
                  options={team_options}
                  filterName="team"
                  name={organisationFilterTerms.team}
                  setFilters={setFilters}
                />
                <Filter
                  options={location_options}
                  filterName="location"
                  name={organisationFilterTerms.location}
                  setFilters={setFilters}
                />
                <Filter
                  options={department_options}
                  filterName="department"
                  name={organisationFilterTerms.department}
                  setFilters={setFilters}
                />
                <Filter
                  options={unit_options}
                  filterName="unit"
                  name={organisationFilterTerms.unit}
                  setFilters={setFilters}
                />
                <Filter
                  options={manager_options}
                  filterName="manager"
                  name={organisationFilterTerms.manager}
                  setFilters={setFilters}
                />
                <Filter
                  options={reports_to_options}
                  filterName="reports_to"
                  name="reports to"
                  setFilters={setFilters}
                />
              </div>
            </div>
          )}
          searchable
        />
      </div>
    );
  }
};

export default TalentSnapshotList;
