import makeRsaAction from '^/middleware/makeRsaAction';
import { request } from '@dabapps/redux-requests';
import Cookie from 'js-cookie';

import { MANAGE_RESOURCES_PATH } from '^/components/resources/constants';

const FILTER_ALL = 'ALL';

export function makeAsyncActionSet(actionName) {
  return {
    REQUEST: actionName + '_REQUEST',
    SUCCESS: actionName + '_SUCCESS',
    FAILURE: actionName + '_FAILURE'
  };
}

export const SET_SORT_BY = 'SET_SORT_BY';
export function setSortBy(listName, value) {
  return {type: SET_SORT_BY, listName, value};
}

export const FETCH_FIELD_CONFIG = makeAsyncActionSet('FETCH_FIELD_CONFIG');
export function fetchFieldConfig(model) {
  return makeRsaAction(
    FETCH_FIELD_CONFIG,
    `/${model}/chadmin_config/`,
    'GET',
    null,
    {model}
  );
}

export const FETCH_CUSTOM_TERMS = makeAsyncActionSet('FETCH_CUSTOM_TERMS');
export function fetchCustomTerms() {
  return makeRsaAction(
    FETCH_CUSTOM_TERMS,
    '/organisation/custom-terms/',
    'GET',
    null
  );
}

export const REMOVE_FROM_SURVEY = makeAsyncActionSet('REMOVE_FROM_SURVEY');
export function removeFromSurvey(surveyId, membersIds) {
  return makeRsaAction(
    REMOVE_FROM_SURVEY,
    `/survey/${surveyId}/survey-team-members/`,
    'DELETE',
    {team_members: membersIds},
    {team_members: membersIds}
  );
}

export const ADD_TO_SURVEY = makeAsyncActionSet('ADD_TO_SURVEY');
export function addToSurvey(surveyId, membersIds) {
  return makeRsaAction(
    ADD_TO_SURVEY,
    `/survey/${surveyId}/survey-team-members/`,
    'POST',
    {team_members: membersIds},
    {team_members: membersIds}
  );
}

export const SET_SORT_BY_REVERSED = 'SET_SORT_BY_REVERSED';
export function setSortByReversed(listName, value) {
  return {type: SET_SORT_BY_REVERSED, listName, value};
}

export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export function setSelectedFilter(listName, filterKey, value) {
  if (value === FILTER_ALL) {
    value = '';
  }
  Cookie.set(`filter__${listName}-${filterKey}`, value);
  return {type: SET_SELECTED_FILTER, listName, filterKey, value};
}

export const SET_PENDING_UPLOAD_IN_FORM = 'SET_PENDING_UPLOAD_IN_FORM';
export function setPendingUploadInForm(form, field, file) {
  return { type: SET_PENDING_UPLOAD_IN_FORM, payload: { form, field, file } };
}

export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export function setPageSize(listName, value) {
  return {type: SET_PAGE_SIZE, listName, value};
}

export const GET_SURVEY = makeAsyncActionSet('GET_SURVEY');
export function getSurvey(surveyId) {
  return makeRsaAction(
    GET_SURVEY,
    `/survey/${surveyId}/`,
    'GET',
    null
  );
}

export const RESYNC_SURVEY_TEAM_MEMBERS = makeAsyncActionSet('RESYNC_SURVEY_TEAM_MEMBERS');
export function resyncSurveyTeamMembers(surveyId) {
  return makeRsaAction(
    RESYNC_SURVEY_TEAM_MEMBERS,
    `/survey/${surveyId}/survey-team-members/resync/`,
    'POST',
    surveyId,
    {id: surveyId}
  );
}

export const UPDATE_SURVEY = makeAsyncActionSet('UPDATE_SURVEY');
export function updateSurvey(survey) {
  return makeRsaAction(
    UPDATE_SURVEY,
    `/survey/${survey.get('id')}/`,
    'PUT',
    survey,
    {id: survey.get('id'), survey}
  );
}

export const CLOSE_SURVEY = makeAsyncActionSet('CLOSE_SURVEY');
export function closeSurvey(survey) {
  return makeRsaAction(
    CLOSE_SURVEY,
    `/survey/${survey.get('id')}/close/`,
    'POST',
    null,
    { survey }
  );
}

export const REOPEN_SURVEY = makeAsyncActionSet('REOPEN_SURVEY');
export function reopenSurvey(survey) {
  return makeRsaAction(
    REOPEN_SURVEY,
    `/survey/${survey.get('id')}/reopen/`,
    'POST',
    null,
    { survey }
  );
}

export const NUDGE_SURVEY = makeAsyncActionSet('NUDGE_SURVEY');
export function nudgeSurvey(survey) {
  return makeRsaAction(
    NUDGE_SURVEY,
    `/survey/${survey.get('id')}/nudge/`,
    'POST',
    null,
    { survey }
  );
}

export const SEND_SURVEY = makeAsyncActionSet('SEND_SURVEY');
export function sendSurvey(survey) {
  return makeRsaAction(
    SEND_SURVEY,
    `/survey/${survey.get('id')}/send/`,
    'POST',
    null,
    { survey }
  );
}

export const SEND_TEST_EMAIL = makeAsyncActionSet('SEND_TEST_EMAIL');
export function sendTestEmail(surveyId, template_type) {
  return makeRsaAction(
    SEND_TEST_EMAIL,
    `/survey/${surveyId}/send-test-email/`,
    'POST',
    { 'template_type': template_type },
    template_type
  );
}

export const SURVEY_SELECT_ALL_MEMBERS = makeAsyncActionSet('SURVEY_SELECT_ALL_MEMBERS');
export function surveySelectAll(surveyId, filterSpec) {
  return makeRsaAction(
    SURVEY_SELECT_ALL_MEMBERS,
    `/survey/${surveyId}/survey-team-members/select-all/`,
    'PUT',
    filterSpec
  );
}

export const SURVEY_REMOVE_ALL_MEMBERS = makeAsyncActionSet('SURVEY_REMOVE_ALL_MEMBERS');
export function surveyRemoveAll(surveyId) {
  return makeRsaAction(
    SURVEY_REMOVE_ALL_MEMBERS,
    `/survey/${surveyId}/survey-team-members/remove-all/`,
    'DELETE',
    {}
  );
}

export const SET_FILTER = 'SET_FILTER';
export function setFilter(model, filterKey, filterValue) {
  return { type: SET_FILTER, payload: { model, filterKey, filterValue } };
}

export const SET_FILTER_BULK = 'SET_FILTER_BULK';
export function setFilterBulk(model, filters) {
  return { type: SET_FILTER_BULK, payload: { model, filters } };
}

export const GET_ACTION_OPTIONS = makeAsyncActionSet('GET_ACTION_OPTIONS');
export function getActionOptions(actionId, actionData) {
  return makeRsaAction(
    GET_ACTION_OPTIONS,
    `/goals/actions/${actionId}/`,
    'OPTIONS',
    actionData,
    { actionData }
  );
}

export const GET_ACTION = makeAsyncActionSet('GET_ACTION');
export function getAction(actionId) {
  return makeRsaAction(
    GET_ACTION,
    `/goals/actions/${actionId}/`,
    'GET',
    null
  );
}

export const GET_BASIC_ACTION = makeAsyncActionSet('GET_BASIC_ACTION');
export function getBasicAction(actionId) {
  return makeRsaAction(
    GET_BASIC_ACTION,
    `/goals/actions/${actionId}/?basic=True`,
    'GET',
    null
  );
}

export const UPDATE_ACTION = makeAsyncActionSet('UPDATE_ACTION');
export function updateAction(actionId, actionData) {
  return makeRsaAction(
    UPDATE_ACTION,
    `/goals/actions/${actionId}/`,
    'PATCH',
    actionData,
    { actionData, actionId }
  );
}

export const DELETE_ACTION = makeAsyncActionSet('DELETE_ACTION');
export function deleteAction(actionId) {
  return (dispatch) => {
    return request(
      DELETE_ACTION,
      `/api/goals/actions/${actionId}/`,
      'DELETE'
    )(dispatch);
  };
}

export const UPDATE_ACTION_DESCRIPTION = makeAsyncActionSet('UPDATE_ACTION_DESCRIPTION');
export function updateActionDescription(actionId, actionData) {
  return makeRsaAction(
    UPDATE_ACTION_DESCRIPTION,
    `/goals/actions/${actionId}/description/`,
    'PUT',
    actionData,
    { actionData }
  );
}

export const CREATE_ACTION_EXPORT = makeAsyncActionSet('CREATE_ACTION_EXPORT');
export function createActionExport(showAll) {
  return makeRsaAction(
    CREATE_ACTION_EXPORT,
    '/goals/actions/export/',
    'POST',
    { show_all: showAll }
  );
}

export const GET_ACTION_EXPORT = makeAsyncActionSet('GET_ACTION_EXPORT');
export function getActionExport(exportId) {
  return makeRsaAction(
    GET_ACTION_EXPORT,
    `/goals/actions/export/${exportId}/poll/`,
    'GET'
  );
}

export const TOGGLE_SHOW_CUSTOM_FILTERS = 'TOGGLE_SHOW_CUSTOM_FILTERS';
export function toggleShowCustomFilters() {
  return {
    type: TOGGLE_SHOW_CUSTOM_FILTERS
  };
}

export const TOGGLE_SHOW_COMPARE_ANALYSIS = 'TOGGLE_SHOW_COMPARE_ANALYSIS';
export function toggleShowCompareAnalysis() {
  return {
    type: TOGGLE_SHOW_COMPARE_ANALYSIS
  };
}

export const GET_ORGANISATION_EMAIL_TEMPLATES = makeAsyncActionSet('GET_ORGANISATION_EMAIL_TEMPLATES');
export function getOrganisationEmailTemplates() {
  return makeRsaAction(
    GET_ORGANISATION_EMAIL_TEMPLATES,
    '/email-templates/organisation-templates/',
    'GET'
  );
}

export const UPDATE_ORGANISATION_EMAIL_TEMPLATE = makeAsyncActionSet('UPDATE_ORGANISATION_EMAIL_TEMPLATE');
export function updateOrganisationEmailTemplate(templateType, data) {
  return makeRsaAction(
    UPDATE_ORGANISATION_EMAIL_TEMPLATE,
    `/email-templates/organisation-templates/${templateType}/`,
    'PUT',
    data,
    { source: templateType }
  );
}


export const GET_SURVEY_EMAIL_TEMPLATE = makeAsyncActionSet('GET_SURVEY_EMAIL_TEMPLATE');
export function getSurveyEmailTemplate(surveyId, templateType) {
  return makeRsaAction(
    GET_SURVEY_EMAIL_TEMPLATE,
    `/survey/${surveyId}/settings/email/${templateType}/`,
    'GET',
    undefined,
    { source: templateType }
  );
}

export const UPDATE_SURVEY_EMAIL_TEMPLATE = makeAsyncActionSet('UPDATE_SURVEY_EMAIL_TEMPLATE');
export function updateSurveyEmailTemplate(surveyId, templateType, data) {
  return makeRsaAction(
    UPDATE_SURVEY_EMAIL_TEMPLATE,
    `/survey/${surveyId}/settings/email/${templateType}/`,
    'PUT',
    data,
    { source: templateType }
  );
}

export const RESET_RESPONSE = 'RESET_RESPONSE';
export function resetResponse(responseKey) {
  return {
    type: RESET_RESPONSE,
    payload: responseKey,
  };
}

export const CREATE_CUSTOM_QUESTION = makeAsyncActionSet('CREATE_CUSTOM_QUESTION');
export function createCustomQuestion(surveyId, data) {
  return makeRsaAction(
    CREATE_CUSTOM_QUESTION,
    '/custom-questions/',
    'POST',
    Object.assign(
      {},
      data,
      {
        survey: surveyId || null,
      }
    )
  );
}

export const UPDATE_CUSTOM_QUESTION = makeAsyncActionSet('UPDATE_CUSTOM_QUESTION');
export function updateCustomQuestion(questionId, data) {
  return makeRsaAction(
    UPDATE_CUSTOM_QUESTION,
    `/custom-questions/${questionId}/edit/`,
    'PUT',
    data
  );
}

export const DELETE_CUSTOM_QUESTION = makeAsyncActionSet('DELETE_CUSTOM_QUESTION');
export function deleteCustomQuestion(questionId) {
  return makeRsaAction(
    DELETE_CUSTOM_QUESTION,
    `/custom-questions/${questionId}/`,
    'DELETE'
  );
}

export const UPDATE_CUSTOM_QUESTION_SETTINGS = makeAsyncActionSet('UPDATE_CUSTOM_QUESTION_SETTINGS');
export function updateCustomQuestionSettings(surveyId, data) {
  return makeRsaAction(
    UPDATE_CUSTOM_QUESTION_SETTINGS,
    `/survey/${surveyId}/custom-questions/`,
    'PUT',
    data
  );
}

export const GET_CUSTOM_QUESTION_SETTINGS = makeAsyncActionSet('GET_CUSTOM_QUESTION_SETTINGS');
export function getCustomQuestionSettings(surveyId) {
  return makeRsaAction(
    GET_CUSTOM_QUESTION_SETTINGS,
    `/survey/${surveyId}/custom-questions/`,
    'GET'
  );
}

export const GET_FILTER_OPTIONS = makeAsyncActionSet('GET_FILTER_OPTIONS');
export function getFilterOptions(url) {
  return (dispatch) => {
    return request(
      GET_FILTER_OPTIONS,
      `/api/${url}/`,
      'GET'
    )(dispatch);
  };
}

export const getResourcesFilterOptions = () =>
  getFilterOptions('resources/manage-resources-filter-options');

export const shouldRethrow = () => true;

export const DELETE_RESOURCE = makeAsyncActionSet('DELETE_RESOURCE');
export function deleteResource(resourceId) {
  return (dispatch) => {
    return request(
      DELETE_RESOURCE,
      `/api/${MANAGE_RESOURCES_PATH}/${resourceId}/`,
      'DELETE',
      undefined,
      { shouldRethrow }
    )(dispatch);
  };
}

export const GET_SUBSCRIPTION_ALLOWANCES = makeAsyncActionSet('GET_SUBSCRIPTION_ALLOWANCES');
export function getSubscriptionAllowances() {
  return (dispatch) => {
    return request(
      GET_SUBSCRIPTION_ALLOWANCES,
      '/api/organisation/subscription-allowances/',
      'GET'
    )(dispatch);
  };
}

export const GET_MANAGER_OPTIONS = makeAsyncActionSet('GET_MANAGER_OPTIONS');
export function getManagerOptions() {
  return (dispatch) => {
    return request(GET_MANAGER_OPTIONS, '/api/account/manager-options/', 'GET')(dispatch);
  };
}

export const TOGGLE_LIST_ITEM_SELECTED = 'TOGGLE_LIST_ITEM_SELECTED';
export function toggleListItemSelected(itemId) {
  return {
    type: TOGGLE_LIST_ITEM_SELECTED,
    itemId,
  };
}

export const CLEAR_ALL_SELECTED_LIST_ITEMS = 'CLEAR_ALL_SELECTED_LIST_ITEMS';
export function clearAllSelectedListItems() {
  return {
    type: CLEAR_ALL_SELECTED_LIST_ITEMS,
  };
}

export const CLEAR_SELECTED_LIST_ITEMS = 'CLEAR_SELECTED_LIST_ITEMS';
export function clearSelectedListItems(itemIds) {
  return {
    type: CLEAR_SELECTED_LIST_ITEMS,
    itemIds,
  };
}

export const ADD_SELECTED_LIST_ITEMS = 'ADD_SELECTED_LIST_ITEMS';
export function addSelectedListItems(itemIds) {
  return {
    type: ADD_SELECTED_LIST_ITEMS,
    itemIds,
  };
}

export const ASSIGN_MANAGER = makeAsyncActionSet('ASSIGN_MANAGER');
export function assignManager(manager, users) {
  return (dispatch) => {
    return request(
      ASSIGN_MANAGER,
      '/api/account/managers/assign/',
      'POST',
      { manager, users },
      { shouldRethrow }
    )(dispatch);
  };
}

export const REMOVE_MANAGER = makeAsyncActionSet('REMOVE_MANAGER');
export function removeManager(manager, users) {
  return (dispatch) => {
    return request(
      REMOVE_MANAGER,
      '/api/account/managers/remove/',
      'POST',
      { manager, users },
      { shouldRethrow }
    )(dispatch);
  };
}

export const CREATE_ENGAGEMENT_SURVEY = makeAsyncActionSet('CREATE_ENGAGEMENT_SURVEY');
export function createEngagementSurvey() {
  return (dispatch) => {
    return request(
      CREATE_ENGAGEMENT_SURVEY,
      '/api/survey/create-engagement-survey/',
      'POST'
    )(dispatch);
  };
}


export const CREATE_MENTAL_HEALTH_SURVEY = makeAsyncActionSet('CREATE_MENTAL_HEALTH_SURVEY');
export function createMentalHealthSurvey() {
  return (dispatch) => {
    return request(
      CREATE_MENTAL_HEALTH_SURVEY,
      '/api/survey/create-mental-health-survey/',
      'POST'
    )(dispatch);
  };
}

export const SEND_BUBBLE_REMINDERS = makeAsyncActionSet('SEND_BUBBLE_REMINDERS');
export function sendBubbleReminders(user_ids, reminder_type) {
  return makeRsaAction(
    SEND_BUBBLE_REMINDERS,
    '/people/bubble-user-send-reminder/',
    'POST',
    {
      user_ids,
      reminder_type
    }
  );
}

export const UPDATE_GENERIC_ERROR_MESSAGES = 'UPDATE_GENERIC_ERROR_MESSAGES';
export function updateGenericErrorMessages(errorMessages) {
  return {
    type: UPDATE_GENERIC_ERROR_MESSAGES,
    errorMessages
  };
}

export const GET_ORG_PERMISSIONS = makeAsyncActionSet('GET_ORG_PERMISSIONS');
export function getOrgPermissions() {
  return (dispatch) => {
    return request(
      GET_ORG_PERMISSIONS,
      '/api/organisation/get-org-permissions/',
      'GET'
    )(dispatch);
  };
}

export const TOGGLE_ITEM_EXPANDED = 'TOGGLE_ITEM_EXPANDED';
export function toggleItemExpanded(itemId) {
  return {
    type: TOGGLE_ITEM_EXPANDED,
    itemId
  };
}
