export const getIsAgendaEmployee = (state) => {
  if (state.editingAgenda && state.editingAgenda.employee) {
    return state.editingAgenda.employee.value === state.loggedInUser.get('id');
  }
  return false;
};

export function engagementQuestionOptionIsSelected(state, option) {
  return !!state.editingAgenda.engagement_survey_questions.find(question => (
    question.question_sub_area_id === option.question_sub_area_id &&
    question.score_type === option.score_type &&
    question.score === option.score
  ));
}

export function isReadOnlyAgendaItem(state, createdById) {
  const isAgendaEmployee = getIsAgendaEmployee(state);
  if (isAgendaEmployee) {
    return createdById !== state.loggedInUser.get('id');
  }
  if (state.editingAgenda.employee) {
    return createdById === state.editingAgenda.employee.value;
  }
  return true;
}

export const splitSelectedTemplateParts = selectedParts => selectedParts && selectedParts.split(',');
