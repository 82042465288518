import { anyPending } from '@dabapps/redux-requests';
import { List } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import {
  addUsersToBulkAgendaAndToast,
  removeUsersFromBulkAgendaAndToast,
  MANAGE_BULK_AGENDA_USERS,
  CANCEL_BULK_AGENDA
} from '^/components/agendas/actions';
import { AGENDA_PARTICIPANTS_LIST } from '^/components/agendas/constants';

export function BulkAgendaSelectAllUsersCheckbox({toggleSelected, isDisabled, isChecked}) {
  return (
    <input
      type="checkbox"
      className="checkbox-color-text margin-right-small"
      disabled={isDisabled}
      checked={isChecked}
      onChange={toggleSelected}
    />
  );
}

function mapStateToProps(state) {
  const items = state.collections.getIn([AGENDA_PARTICIPANTS_LIST, 'items'], List());
  const availableItemIds = items.map((item) => item.get('id'));
  const selectedItemIds = items.reduce((accumulator, item) =>
    state.bulkAgendaSelectedUserIds.includes(item.get('id')) ? [...accumulator, item.get('id')] : accumulator
  , []);
  return {
    isDisabled: (
      availableItemIds.count() === 0 ||
      anyPending(state.responses, [MANAGE_BULK_AGENDA_USERS, CANCEL_BULK_AGENDA])
    ),
    isChecked: (
      availableItemIds.count() &&
      availableItemIds.count() === selectedItemIds.length
    ),
    availableItemIds,
    selectedItemIds
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    toggleSelectedFunction: (availableItemIds, selectedItemIds) => (event) => {
      if (event.target.checked) {
        const newItemIds = availableItemIds.filter(itemId => !selectedItemIds.includes(itemId));
        dispatch(addUsersToBulkAgendaAndToast(props.agendaTemplateId, newItemIds.toArray()));
      } else {
        dispatch(removeUsersFromBulkAgendaAndToast(props.agendaTemplateId, availableItemIds.toArray()));
      }
    }
  };
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    toggleSelected: dispatchProps.toggleSelectedFunction(
      stateProps.availableItemIds,
      stateProps.selectedItemIds
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BulkAgendaSelectAllUsersCheckbox);
