import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { connect } from 'react-redux';
import { resendAgendaAndToast, RESEND_AGENDA } from './actions';

export const AgendaResendModal = (props) => {
  return (
    <div>
      <ModalBody>
        {props.isReadOnly ? (
          <p>
            A PDF summary of the agenda will be emailed to both participants.
            As this 1:1 has already been saved as ‘read-only’,
            you cannot make any further changes to the agenda.
          </p>
        ) : (
          <p>
            A PDF summary of the agenda will be emailed to both participants.
            This summary can be resent if further changes are made to the agenda.
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button disabled={props.isDisabled} onClick={props.save}>Send</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isDisabled: isPending(state.responses, RESEND_AGENDA)
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    save: () => {
      dispatch(resendAgendaAndToast(props.agendaId));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaResendModal);
