import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

export const formatAdditionalFilters = data => Object.assign({}, data, data.additional_filters && {
  additional_filters: Object.values(data.additional_filters)
});

export const CREATE_REPORT = makeAsyncActionSet('CREATE_REPORT');
export function createReport(data, legacyReportShareId = undefined, hadSelectedAdditionalFilters = false) {
  return (dispatch) => {
    return request(
      CREATE_REPORT,
      !legacyReportShareId
          ? `/api/reports/create-report/${hadSelectedAdditionalFilters ? '?expand_filters=true' : ''}`
          : `/api/reports/create-engagement-share-report/${legacyReportShareId}/`,
      'POST',
      formatAdditionalFilters(data)
    )(dispatch).then((response) => {
      window.location.href = response.data.report_url;
    }).catch(() => {
      toast.error('There was a problem creating the report.');
    });
  };
}

export const CREATE_HEATMAP = makeAsyncActionSet('CREATE_HEATMAP');
export function createHeatmap(data, hadSelectedAdditionalFilters) {
  return (dispatch) => {
    return request(
      CREATE_HEATMAP,
      `/api/reports/create-heatmap/${hadSelectedAdditionalFilters ? '?expand_filters=true' : ''}`,
      'POST',
      formatAdditionalFilters(data)
    )(dispatch).then((response) => {
      window.location.href = response.data.report_url;
    }).catch(() => {
      toast.error('There was a problem creating the heatmap.');
    });
  };
}

export const SHOW_COMPARE_TO_FORM = 'SHOW_COMPARE_TO_FORM';
export function showCompareToForm() {
  return {
    type: SHOW_COMPARE_TO_FORM
  };
}

export const GET_REPORT_SUGGESTIONS = makeAsyncActionSet('GET_REPORT_SUGGESTIONS');
export function getReportSuggestions(reportId) {
  return (dispatch) => {
    return request(
      GET_REPORT_SUGGESTIONS,
      `/api/reports/report-suggestions/${reportId}/`,
      'GET'
    )(dispatch);
  };
}
