import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import { reduxForm } from 'redux-form';
import { Button, Column, Row } from '@dabapps/roe';

import FormError from '^/components/forms/FormError';
import {
  EMAIL_TEMPLATE_TYPES_WITH_SURVEY_LINK,
  FORM_INPUT_COLUMNS,
  FORM_LABEL_COLUMNS,
  ROE_TOTAL_COLUMS,
  INTRO_TEXT_PLACEHOLDER,
  OUTRO_TEXT_PLACEHOLDER,
} from './constants';
import CustomEmailTemplateField from './CustomEmailTemplateField';


const CustomEmailTemplateForm = ({
  children,
  footerExtras,
  handleSubmit,
  isUpdating,
  response,
  templateType,
}) => (
  <form onSubmit={handleSubmit}>

    <CustomEmailTemplateField
      disabled={isUpdating}
      fieldName="email_title"
      label="Email Subject"
      placeholder="Survey email title"
      response={response}
    />

    {children}

    <Row>
      <Column>
        <fieldset
          className="border-base border-radius-base padding-large margin-bottom-base"
        >
          <h5 className="fieldset-title">Email text</h5>

          <CustomEmailTemplateField
            disabled={isUpdating}
            fieldName="greeting_text"
            label="Greeting"
            placeholder="Dear"
            response={response}
            suffix="[NAME],"
          />

          <CustomEmailTemplateField
            fieldClassName="email-content-input"
            component="textarea"
            disabled={isUpdating}
            fieldName="intro_text"
            label="Intro text"
            placeholder={INTRO_TEXT_PLACEHOLDER}
            response={response}
            wrapTextArea
          />

          {EMAIL_TEMPLATE_TYPES_WITH_SURVEY_LINK.indexOf(templateType) !== -1 && (
            <Row className="margin-bottom-base">
              <Column xs={ROE_TOTAL_COLUMS} sm={FORM_LABEL_COLUMNS} />
              <Column xs={ROE_TOTAL_COLUMS} sm={FORM_INPUT_COLUMNS}>
                <a>Survey Link</a>
              </Column>
            </Row>
          )}

          <CustomEmailTemplateField
            fieldClassName="email-content-input"
            component="textarea"
            disabled={isUpdating}
            fieldName="outro_text"
            label="End text"
            placeholder={OUTRO_TEXT_PLACEHOLDER}
            response={response}
            wrapTextArea
          />
        </fieldset>
      </Column>
    </Row>

    <Row>
      <Column>
        <Button
          className="primary padding-horizontal-large input-height"
          type="submit"
          disabled={isUpdating}
        >
          Update
        </Button>
        {footerExtras}
        <FormError response={response} formKey="non_field_errors"/>
      </Column>
    </Row>

  </form>
);

CustomEmailTemplateForm.propTypes = {
  form: React.PropTypes.string.isRequired,
  footerExtras: React.PropTypes.element,
  isUpdating: React.PropTypes.bool,
  handleSubmit: React.PropTypes.func.isRequired,
  response: ImmutablePropTypes.map,
  templateType: React.PropTypes.string.isRequired,
};


export { CustomEmailTemplateForm };

export default reduxForm({ enableReinitialize: true })(CustomEmailTemplateForm);
