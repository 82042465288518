import React, { Component } from 'react'; // eslint-disable-line

/*

  To use the Carousel component, wrap CarouselItem components in a Carousel component.

  The Carousel component will automatically disable the left and right scroll buttons when the user is at the beginning or end of the carousel.
  To adjust styling of each child component, pass a classNames prop to the CarouselItem component.
  Pass content as children to the CarouselItem component.

  Example usage:

  <Carousel>
    <CarouselItem classNames="background-red">
      <h2>SLIDE 1!!!</h2>
    </CarouselItem>
    <CarouselItem classNames="background-green" />
    <CarouselItem classNames="background-orange" />
  </Carousel>

*/

export const CarouselItem = ({ classNames, children }) => (
  <div className={`carousel-inner ${classNames}`}>
    {children}
  </div>
);

export class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAtStart: true,
      isAtEnd: false
    };

    this.scrollContainerRef = null;
    this.setScrollContainerRef = element => {
      this.scrollContainerRef = element;
    };

    this.handleScrollClick = this.handleScrollClick.bind(this);
    this.handleScrollClickLeft = this.handleScrollClickLeft.bind(this);
    this.handleScrollClickRight = this.handleScrollClickRight.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    if (this.scrollContainerRef) {
      this.scrollContainerRef.addEventListener('scroll', this.handleScroll);
    }
    this.handleScroll();
  }

  componentWillUnmount() {
    if (this.scrollContainerRef) {
      this.scrollContainerRef.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll() {
    if (this.scrollContainerRef && this.props.children.length > 0) {
      const isAtStart = this.scrollContainerRef.scrollLeft === 0;
      const maxScrollLeft = this.scrollContainerRef.scrollWidth - this.scrollContainerRef.offsetWidth;
      const isAtEnd = this.scrollContainerRef.scrollLeft === maxScrollLeft;

      if (isAtStart) {
        this.setState({isAtStart});
      } else if (this.state.isAtStart) {
        this.setState({isAtStart: false});
      }

      if (isAtEnd) {
        this.setState({isAtEnd});
      } else if (this.state.isAtEnd) {
        this.setState({isAtEnd: false});
      }
    }
  }

  handleScrollClick(distance) {
    this.scrollContainerRef.scrollBy(distance, 0);
  }

  handleScrollClickLeft() {
    this.handleScrollClick(-1);
  }

  handleScrollClickRight() {
    this.handleScrollClick(1);
  }

  render() {
    return (
      <div className="position-relative">
        <button
          className="carousel-scroll-button left"
          onClick={this.handleScrollClickLeft}
          disabled={this.state.isAtStart}
        >
          <i className="fa fa-arrow-left"></i>
        </button>
        <button
          className="carousel-scroll-button right"
          onClick={this.handleScrollClickRight}
          disabled={this.state.isAtEnd}
        >
          <i className="fa fa-arrow-right"></i>
        </button>
        <div className="carousel" ref={this.setScrollContainerRef}>
          <div className="display-flex flex-gap-xx-large">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
};

export default Carousel;
