import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { shouldRethrow, clearAllSelectedListItems } from '^/actions/actions';
import { getCollection } from '^/actions/collections';
import { closeModal } from '^/actions/modals';
import {
  AGENDA_LIST_MANAGER,
  AGENDA_LIST_URL,
  AgendaSectionType
} from '^/components/agendas/constants';
import { updateGoal } from '^/components/goals/actions';
import store from '^/store';

export const SET_ACTIONABLE_AGENDAS = 'SET_ACTIONABLE_AGENDAS';
export const setActionableAgendas = agendas => ({
  type: SET_ACTIONABLE_AGENDAS,
  agendas
});

export const DISMISS_EMPLOYEE_AGENDA_REQUEST = makeAsyncActionSet('DISMISS_EMPLOYEE_AGENDA_REQUEST');
export function dismissEmployeeAgendaRequest(id) {
  return (dispatch) => {
    return request(
      DISMISS_EMPLOYEE_AGENDA_REQUEST,
      `/api/agendas/${id}/dismiss/`,
      'POST',
      null,
      { metaData: { id } }
    )(dispatch);
  };
}

export const RESPOND_TO_AGENDA_REQUEST = makeAsyncActionSet('RESPOND_TO_AGENDA_REQUEST');
export function respondToAgendaRequest(id, response) {
  return (dispatch) => {
    return request(
      RESPOND_TO_AGENDA_REQUEST,
      `/api/agendas/${id}/request-response/`,
      'POST',
      { response },
      {
        metaData: { id },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const respondToAgendaRequestAndReload = (agendaId, response, options) => dispatch =>
  respondToAgendaRequest(agendaId, response)(dispatch).then(() => {
    toast.dismiss();
    toast.success(`The agenda has been ${response.toLowerCase()}.`);
    store.dispatch(getCollection(
      AGENDA_LIST_URL,
      options,
      AGENDA_LIST_MANAGER
    ));
  }).catch(() => {
    toast.dismiss();
    toast.error('There was an error responding to the agenda request.');
  });

export const REQUEST_ONE_TO_ONE = makeAsyncActionSet('REQUEST_ONE_TO_ONE');
export function requestOneToOne(data) {
  return (dispatch) => {
    return request(
      REQUEST_ONE_TO_ONE,
      `/api/agendas/request-one-to-one/`,
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export function requestOneToOneAndRedirect(data) {
  return (dispatch) => {
    return requestOneToOne(data)(dispatch).then(() => {
      window.location.href = '/agendas/list/';
    }).catch(() => {
      toast.dismiss();
      toast.error('There was an error creating the requested agenda.');
    });
  };
}

export const CREATE_AGENDA = makeAsyncActionSet('CREATE_AGENDA');
export function createAgenda(data) {
  return (dispatch) => {
    return request(
      CREATE_AGENDA,
      '/api/agendas/create/',
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export const createAgendaAndRedirect = data => dispatch =>
  createAgenda(data)(dispatch).then(
    response => window.location.href = response.data
  ).catch(() => {
    toast.dismiss();
    toast.error('There was an error creating the agenda.');
  });;

export const GET_AGENDA = makeAsyncActionSet('GET_AGENDA');
export function getAgenda(agendaId) {
  return (dispatch) => {
    return request(
      GET_AGENDA,
      `/api/agendas/${agendaId}/`,
      'GET'
    )(dispatch);
  };
}

function getManagerId(manager) {
  // Allow the manager to be nulled in the database
  if (manager === null) {
    return null;
  }
  if (manager) {
    return manager.value;
  }
}

export const UPDATE_AGENDA = makeAsyncActionSet('UPDATE_AGENDA');
export function updateAgenda(agendaId, data, agendaItemId) {
  return (dispatch) => {
    return request(
      UPDATE_AGENDA,
      `/api/agendas/${agendaId}/update/`,
      'PATCH',
      Object.assign({}, data, {manager: getManagerId(data.manager)}),
      {
        metaData: {
          data,
          agendaItemId
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const updateAgendaAndToast = (agendaId, data, agendaItemId) => dispatch =>
  updateAgenda(agendaId, data, agendaItemId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The agenda has been updated.');
  }).catch(() => {
    toast.dismiss();
    toast.error('There was a problem updating the agenda.');
  });

export const EXPAND_AGENDA_SECTION = 'EXPAND_AGENDA_SECTION';
export function expandAgendaSection(sectionType) {
  return {
    type: EXPAND_AGENDA_SECTION,
    sectionType,
  };
}

export const COLLAPSE_AGENDA_SECTION = 'COLLAPSE_AGENDA_SECTION';
export function collapseAgendaSection(sectionType) {
  return {
    type: COLLAPSE_AGENDA_SECTION,
    sectionType,
  };
}

export const UPDATE_AGENDA_SECTION_POSITION = makeAsyncActionSet('UPDATE_AGENDA_SECTION_POSITION');
export function updateAgendaSectionPosition(agendaId, data) {
  return (dispatch) => {
    return request(
      UPDATE_AGENDA_SECTION_POSITION,
      `/api/agendas/${agendaId}/update-section-position/`,
      'PATCH',
      data,
      {
        metaData: {
          sectionType: data.sectionType,
          position: data.position
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const updateAgendaSectionPositionAndToast = (agendaId, data) => dispatch =>
  updateAgendaSectionPosition(agendaId, data)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The agenda section position has been updated.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The agenda section position could not be updated.');
  });

export const SEND_CONFIRMATION_EMAIL = makeAsyncActionSet('SEND_CONFIRMATION_EMAIL');
export function sendConfirmationEmail(agendaId) {
  return (dispatch) => {
    return request(
      SEND_CONFIRMATION_EMAIL,
      `/api/agendas/${agendaId}/send-confirmation-email/`,
      'PATCH',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const sendConfirmationEmailAndToast = (agendaId) => dispatch =>
  sendConfirmationEmail(agendaId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Successfully saved Agenda.');
    dispatch(closeModal());
  }).catch(() => {
    toast.dismiss();
    toast.error('The Agenda could not be saved.');
  });

export const UPDATE_AGENDA_LAST_EDITED = makeAsyncActionSet('UPDATE_AGENDA_LAST_EDITED');
export function updateAgendaLastEdited(agendaId) {
  return (dispatch) => {
    return request(
      UPDATE_AGENDA_LAST_EDITED,
      `/api/agendas/${agendaId}/update-last-edited/`,
      'PATCH'
    )(dispatch);
  };
}

export const CREATE_AGENDA_ITEM = makeAsyncActionSet('CREATE_AGENDA_ITEM');
export function createAgendaItem(agendaId, data, itemsName) {
  return (dispatch) => {
    return request(
      CREATE_AGENDA_ITEM,
      `/api/agendas/${agendaId}/agenda-items/create/`,
      'POST',
      data,
      {
        metaData: {
          agendaItemId: itemsName,
          itemsName
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const createAgendaItemAndToast = (agendaId, data, itemsName) => dispatch =>
  createAgendaItem(agendaId, data, itemsName)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The agenda item has been added.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The agenda item could not be added.');
  });

export const AGENDA_ITEM_EDIT_ACTION = 'AGENDA_ITEM_EDIT_ACTION';
export function agendaItemEditAction(agendaItemId) {
  return {
    type: AGENDA_ITEM_EDIT_ACTION,
    agendaItemId,
  };
}

export const AGENDA_ITEM_CANCEL_EDIT = 'AGENDA_ITEM_CANCEL_EDIT';
export function agendaItemCancelEdit(agendaItemId) {
  return {
    type: AGENDA_ITEM_CANCEL_EDIT,
    agendaItemId,
  };
}

export const UPDATE_AGENDA_ITEM = makeAsyncActionSet('UPDATE_AGENDA_ITEM');
export function updateAgendaItem(agendaId, agendaItemId, data, itemsName) {
  return (dispatch) => {
    return request(
      UPDATE_AGENDA_ITEM,
      `/api/agendas/${agendaId}/agenda-items/${agendaItemId}/`,
      'PATCH',
      data,
      {
        shouldRethrow,
        metaData: {
          agendaItemId,
          itemsName
        }
      }
    )(dispatch);
  };
}

export const updateAgendaItemAndToast = (agendaId, agendaItemId, data, itemsName) => dispatch =>
  updateAgendaItem(agendaId, agendaItemId, data, itemsName)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The agenda item has been updated.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The agenda item could not be updated.');
  });

export const DELETE_AGENDA_ITEM = makeAsyncActionSet('DELETE_AGENDA_ITEM');
export function deleteAgendaItem(agendaId, agendaItemId, itemsName) {
  return (dispatch) => {
    return request(
      DELETE_AGENDA_ITEM,
      `/api/agendas/${agendaId}/agenda-items/${agendaItemId}/delete/`,
      'DELETE',
      null,
      {
        shouldRethrow,
        metaData: {
          agendaItemId,
          itemsName
        }
      }
    )(dispatch);
  };
}

export const deleteAgendaItemAndToast = (agendaId, agendaItemId, itemsName) => dispatch =>
  deleteAgendaItem(agendaId, agendaItemId, itemsName)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The agenda item has been deleted.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The agenda item could not be deleted.');
  });

export const CREATE_AGENDA_REVIEW_QUESTION = makeAsyncActionSet('CREATE_AGENDA_REVIEW_QUESTION');
export function createAgendaReviewQuestion(agendaId, item) {
  return (dispatch) => {
    return request(
      CREATE_AGENDA_REVIEW_QUESTION,
      `/api/agendas/${agendaId}/review-question/create/`,
      'POST',
      {
        question_id: item.question_id,
        answer_id: item.answer_id
      },
      {
        metaData: { sectionType: AgendaSectionType.PERFORMANCE_REVIEW },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const createAgendaReviewQuestionAndToast = (agendaId, item) => dispatch =>
  createAgendaReviewQuestion(agendaId, item)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The agenda review question has been added.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The agenda review question could not be added.');
  });

export const DELETE_AGENDA_REVIEW_QUESTION = makeAsyncActionSet('DELETE_AGENDA_REVIEW_QUESTION');
export function deleteAgendaReviewQuestion(agendaId, reviewQuestionId) {
  return (dispatch) => {
    return request(
      DELETE_AGENDA_REVIEW_QUESTION,
      `/api/agendas/${agendaId}/remove-review-question/${reviewQuestionId}/`,
      'DELETE',
      null,
      {
        metaData: { reviewQuestionId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const deleteAgendaReviewQuestionAndToast = (agendaId, reviewQuestionId) => dispatch =>
  deleteAgendaReviewQuestion(agendaId, reviewQuestionId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The review question has been removed.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The review question could not be removed.');
  });

export const ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA = makeAsyncActionSet('ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA');
export function addEngagementSurveyQuestionToAgenda(agendaId, data) {
  return (dispatch) => {
    return request(
      ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA,
      `/api/agendas/${agendaId}/add-engagement-question/`,
      'POST',
      data,
      {
        metaData: { sectionType: AgendaSectionType.ENGAGEMENT_SURVEY },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const addEngagementSurveyQuestionToAgendaAndToast = (agendaId, option) => dispatch =>
  addEngagementSurveyQuestionToAgenda(agendaId, option)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Added Engagement question to Agenda.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The Engagement question could not be added to the Agenda.');
  });

export const REMOVE_ENGAGEMENT_SURVEY_QUESTION_FROM_AGENDA = makeAsyncActionSet(
  'REMOVE_ENGAGEMENT_SURVEY_QUESTION_FROM_AGENDA'
);
export function removeEngagementSurveyQuestionFromAgenda(agendaId, agendaEngagementSurveyQuestionId) {
  return (dispatch) => {
    return request(
      REMOVE_ENGAGEMENT_SURVEY_QUESTION_FROM_AGENDA,
      `/api/agendas/${agendaId}/remove-engagement-question/${agendaEngagementSurveyQuestionId}/`,
      'DELETE',
      null,
      {
        metaData: { agendaEngagementSurveyQuestionId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const removeEngagementSurveyQuestionFromAgendaAndToast = (
  agendaId,
  agendaEngagementSurveyQuestionId
) => dispatch =>
  removeEngagementSurveyQuestionFromAgenda(
    agendaId,
    agendaEngagementSurveyQuestionId
  )(dispatch).then(() => {
    toast.dismiss();
    toast.success('Successfully removed Engagement question from Agenda.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The Engagement question could not be removed from the Agenda.');
  });

export const ADD_GOAL_TO_AGENDA = makeAsyncActionSet('ADD_GOAL_TO_AGENDA');
export function addGoalToAgenda(agendaId, goal) {
  return (dispatch) => {
    return request(
      ADD_GOAL_TO_AGENDA,
      `/api/agendas/${agendaId}/${goal.id}/add-goal/`,
      'POST',
      null,
      {
        metaData: {
          sectionType: AgendaSectionType.EMPLOYEE_GOALS,
          goal,
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const addGoalToAgendaAndToast = (agendaId, goalId) => dispatch =>
  addGoalToAgenda(agendaId, goalId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Added Goal to Agenda.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The Goal could not be added to the Agenda.');
  });

export const REMOVE_GOAL_FROM_AGENDA = makeAsyncActionSet('REMOVE_GOAL_FROM_AGENDA');
export function removeGoalFromAgenda(agendaId, agendaGoalId) {
  return (dispatch) => {
    return request(
      REMOVE_GOAL_FROM_AGENDA,
      `/api/agendas/${agendaId}/remove-goal/${agendaGoalId}/`,
      'DELETE',
      null,
      {
        metaData: { agendaGoalId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const removeGoalFromAgendaAndToast = (agendaId, agendaGoalId) => dispatch =>
  removeGoalFromAgenda(agendaId, agendaGoalId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Successfully removed Goal from Agenda.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The Goal could not be removed from the Agenda.');
  });

export const ADD_AGENDA_GOAL = 'ADD_AGENDA_GOAL';
export const CREATE_AGENDA_GOAL = makeAsyncActionSet('CREATE_AGENDA_GOAL');
export function createAgendaGoal(agendaId, data, agendaGoalType) {
  return (dispatch) => {
    return request(
      CREATE_AGENDA_GOAL,
      `/api/agendas/${agendaId}/create-goal/${agendaGoalType}/`,
      'POST',
      data,
      {
        metaData: {
          agendaItemId: ADD_AGENDA_GOAL,
          agendaGoalType,
          data
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const createAgendaGoalAndToast = (agendaId, data, agendaGoalType) => dispatch =>
  createAgendaGoal(agendaId, data, agendaGoalType)(dispatch).then(() => {
    dispatch(closeModal());
    toast.dismiss();
    toast.success('The agenda goal has been added');
  }).catch(() => {
    dispatch(closeModal());
    toast.dismiss();
    toast.error('The agenda goal could not be added');
  });

export const updateAgendaGoalManagerAndToast = (goalId, data) => dispatch =>
  updateGoal(goalId, data, true)(dispatch).then(() => {
    dispatch(closeModal());
    toast.dismiss();
    toast.success('The goal has been updated');
  }).catch(() => {
    dispatch(closeModal());
    toast.dismiss();
    toast.error('The goal could not be updated');
  });

export const UPDATE_AGENDA_TALENT_SNAPSHOT = makeAsyncActionSet('UPDATE_AGENDA_TALENT_SNAPSHOT');
export function updateAgendaTalentSnapshot(agendaId, data) {
  return (dispatch) => {
    return request(
      UPDATE_AGENDA_TALENT_SNAPSHOT,
      `/api/agendas/${agendaId}/update-talent-snapshot/`,
      'POST',
      data,
      {
        metaData: {
          data
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const updateAgendaTalentSnapshotAndToast = (agendaId, data) => dispatch =>
  updateAgendaTalentSnapshot(agendaId, data)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Successfully updated Talent Snapshot.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The Talent Snapshot could not be updated.');
  });

export const UPDATE_AGENDA_SET_READONLY = makeAsyncActionSet('UPDATE_AGENDA_SET_READONLY');
export function updateAgendaSetReadOnly(agendaId) {
  return (dispatch) => {
    return request(
      UPDATE_AGENDA_SET_READONLY,
      `/api/agendas/${agendaId}/save-as-read-only/`,
      'PATCH',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const setAgendaReadOnlyAndRedirect = (agendaId) => dispatch =>
  updateAgendaSetReadOnly(agendaId)(dispatch).then(() => {
    window.location.href = `/agendas/list/`;
  })
  .catch(() => {
    toast.dismiss();
    toast.error('There was a problem setting the agenda to read only.');
  });

export const GET_AGENDA_READ_ONLY = makeAsyncActionSet('GET_AGENDA_READ_ONLY');
export function getAgendaReadOnly(agendaId) {
  return (dispatch) => {
    return request(
      GET_AGENDA_READ_ONLY,
      `/api/agendas/${agendaId}/get-read-only/`,
      'GET'
    )(dispatch);
  };
}

export const DELETE_AGENDA = makeAsyncActionSet('DELETE_AGENDA');
export function deleteAgenda(agendaId) {
  return (dispatch) => {
    return request(
      DELETE_AGENDA,
      `/api/agendas/${agendaId}/delete/`,
      'DELETE',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const deleteAgendaAndToast = (agendaId) => dispatch =>
  deleteAgenda(agendaId)(dispatch).then(() => {
    window.location.href = '/agendas/list/';
  }).catch(() => {
    toast.dismiss();
    toast.error('The Agenda could not be deleted.');
  });

export const CREATE_AGENDA_OKR = makeAsyncActionSet('CREATE_AGENDA_OKR');
export function createAgendaOkr(agendaId, okrId) {
  return (dispatch) => {
    return request(
      CREATE_AGENDA_OKR,
      `/api/agendas/${agendaId}/agenda-okrs/create/`,
      'POST',
      { agenda: agendaId, okr: okrId },
      {
        metaData: {
          sectionType: AgendaSectionType.OKRS,
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const createAgendaOkrAndToast = (agendaId, data) => dispatch =>
  createAgendaOkr(agendaId, data)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The okr has been added.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The okr could not be added.');
  });

export const DELETE_AGENDA_OKR = makeAsyncActionSet('DELETE_AGENDA_OKR');
export function deleteAgendaOkr(agendaId, agendaOkrId) {
  return (dispatch) => {
    return request(
      DELETE_AGENDA_OKR,
      `/api/agendas/${agendaId}/agenda-okrs/delete/${agendaOkrId}/`,
      'DELETE',
      null,
      {
        metaData: { agendaOkrId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const deleteAgendaOkrAndToast = (agendaId, agendaOkrId) => dispatch =>
  deleteAgendaOkr(agendaId, agendaOkrId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The okr has been removed.');
  }).catch(() => {
    toast.dismiss();
    toast.error('The okr could not be removed.');
  });

export const RESEND_AGENDA = makeAsyncActionSet('RESEND_AGENDA');
export function resendAgenda(agendaId) {
  return (dispatch) => {
    return request(
      RESEND_AGENDA,
      `/api/agendas/${agendaId}/resend-agenda/`,
      'POST',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const resendAgendaAndToast = (agendaId) => dispatch =>
  resendAgenda(agendaId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Successfully sent PDF agenda.');
    dispatch(closeModal());
  }).catch(() => {
    toast.dismiss();
    toast.error('The PDF agenda could not be sent.');
  });

export const CREATE_AGENDA_PEER_FEEDBACK = makeAsyncActionSet('CREATE_AGENDA_PEER_FEEDBACK');
export function createAgendaPeerFeedback(agendaId, peerFeedbackId) {
  return (dispatch) => {
    return request(
      CREATE_AGENDA_PEER_FEEDBACK,
      `/api/agendas/${agendaId}/agenda-peer-feedback/create/`,
      'POST',
      { peer_feedback: peerFeedbackId },
      {
        metaData: {
          sectionType: AgendaSectionType.PEER_FEEDBACK,
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const createAgendaPeerFeedbackAndToast = (agendaId, peerFeedbackId, shouldCloseModal = false) => dispatch =>
  createAgendaPeerFeedback(agendaId, peerFeedbackId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The peer feedback has been added.');
    if (shouldCloseModal) {
      dispatch(closeModal());
    }
  }).catch(() => {
    toast.dismiss();
    toast.error('The peer feedback could not be added.');
  });

export const DELETE_AGENDA_PEER_FEEDBACK = makeAsyncActionSet('DELETE_AGENDA_PEER_FEEDBACK');
export function deleteAgendaPeerFeedback(agendaId, agendaPeerFeedbackId) {
  return (dispatch) => {
    return request(
      DELETE_AGENDA_PEER_FEEDBACK,
      `/api/agendas/${agendaId}/agenda-peer-feedback/delete/${agendaPeerFeedbackId}/`,
      'DELETE',
      null,
      {
        metaData: { agendaPeerFeedbackId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const deleteAgendaPeerFeedbackAndToast = (
  agendaId,
  agendaPeerFeedbackId,
  shouldCloseModal = false
) => dispatch =>
deleteAgendaPeerFeedback(agendaId, agendaPeerFeedbackId)(dispatch).then(() => {
  toast.dismiss();
  toast.success('The peer feedback has been removed.');
  if (shouldCloseModal) {
    dispatch(closeModal());
  }
}).catch(() => {
  toast.dismiss();
  toast.error('The peer feedback could not be removed.');
});

export const TOGGLE_VIEW_ALL_PEER_FEEDBACK_OPTIONS = 'TOGGLE_VIEW_ALL_PEER_FEEDBACK_OPTIONS';
export const toggleViewAllPeerFeedback = () => {
  return {
    type: TOGGLE_VIEW_ALL_PEER_FEEDBACK_OPTIONS
  };
};

export const CREATE_BULK_AGENDA = makeAsyncActionSet('CREATE_BULK_AGENDA');
export function createBulkAgenda(data) {
  return (dispatch) => {
    return request(
      CREATE_BULK_AGENDA,
      '/api/agendas/create-bulk-agenda/',
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export const createBulkAgendaAndRedirect = data => dispatch =>
  createBulkAgenda(data)(dispatch).then(
    response => window.location.href = response.data
  ).catch(() => {
    toast.dismiss();
    toast.error('There was an error creating the bulk agenda');
  });


export const MANAGE_BULK_AGENDA_USERS = makeAsyncActionSet('MANAGE_BULK_AGENDA_USERS');
export function manageBulkAgendaUsers(method, agendaTemplateId, userIds) {
  return (dispatch) => {
    return request(
      MANAGE_BULK_AGENDA_USERS,
      `/api/agendas/${agendaTemplateId}/manage-bulk-users/`,
      method,
      {user_ids: userIds},
      { shouldRethrow }
    )(dispatch);
  };
}

export const addUsersToBulkAgendaAndToast = (agendaTemplateId, userIds) => dispatch =>
  manageBulkAgendaUsers('POST', agendaTemplateId, userIds)(dispatch).then(() => {
    toast.dismiss();
    toast.success(`Added ${userIds.length} user(s)`);
  }).catch(() => {
    toast.dismiss();
    toast.error('There was an error adding users');
  });

export const removeUsersFromBulkAgendaAndToast = (agendaTemplateId, userIds) => dispatch =>
  manageBulkAgendaUsers('DELETE', agendaTemplateId, userIds)(dispatch).then(() => {
    toast.dismiss();
    toast.success(`Removed ${userIds.length} user(s)`);
  }).catch(() => {
    toast.dismiss();
    toast.error('There was an error removing users');
  });

export const CANCEL_BULK_AGENDA = makeAsyncActionSet('CANCEL_BULK_AGENDA');
export function cancelBulkAgenda(agendaTemplateId) {
  return (dispatch) => {
    return request(
      CANCEL_BULK_AGENDA,
      `/api/agendas/${agendaTemplateId}/cancel/`,
      'DELETE',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const cancelBulkAgendaAndRedirect = (agendaTemplateId) => dispatch =>
  cancelBulkAgenda(agendaTemplateId)(dispatch).then(() => {
    window.location.href = '/agendas/list/';
  }).catch(() => {
    toast.dismiss();
    toast.error('There was an error cancelling the agenda');
  });

export const GET_AGENDA_TEMPLATE = makeAsyncActionSet('GET_AGENDA_TEMPLATE');
export function getAgendaTemplate(agendaId) {
  return (dispatch) => {
    return request(
      GET_AGENDA_TEMPLATE,
      `/api/agendas/${agendaId}/template/`,
      'GET'
    )(dispatch);
  };
}

export const UPDATE_AGENDA_TEMPLATE = makeAsyncActionSet('UPDATE_AGENDA_TEMPLATE');
export function updateAgendaTemplate(agendaId, data, agendaItemId) {
  return (dispatch) => {
    return request(
      UPDATE_AGENDA_TEMPLATE,
      `/api/agendas/${agendaId}/update-template/`,
      'PATCH',
      data,
      {
        metaData: {
          agendaItemId
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const updateAgendaTemplateAndToast = (agendaId, data, agendaItemId) => dispatch =>
  updateAgendaTemplate(agendaId, data, agendaItemId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('The agenda template has been updated.');
  }).catch(() => {
    toast.dismiss();
    toast.error('There was a problem updating the agenda template.');
  });

export const ADD_AGENDA_TEMPLATE_PART = makeAsyncActionSet('ADD_AGENDA_TEMPLATE_PART');
export function addAgendaTemplatePart(agendaId, templatePart, sectionType) {
  return (dispatch) => {
    return request(
      ADD_AGENDA_TEMPLATE_PART,
      `/api/agendas/${agendaId}/add-template-part/`,
      'PATCH',
      {
        template_part: templatePart
      },
      {
        metaData: { sectionType },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const addAgendaTemplatePartAndToast = (agendaId, templatePart, sectionType) => dispatch =>
  addAgendaTemplatePart(agendaId, templatePart, sectionType)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Added to the agenda template.');
  }).catch(() => {
    toast.dismiss();
    toast.error('There was a problem updating the agenda template.');
  });

export const REMOVE_AGENDA_TEMPLATE_PART = makeAsyncActionSet('REMOVE_AGENDA_TEMPLATE_PART');
export function removeAgendaTemplatePart(agendaId, templatePart) {
  return (dispatch) => {
    return request(
      REMOVE_AGENDA_TEMPLATE_PART,
      `/api/agendas/${agendaId}/remove-template-part/`,
      'PATCH',
      {
        template_part: templatePart
      },
      { shouldRethrow }
    )(dispatch);
  };
}

export const removeAgendaTemplatePartAndToast = (agendaId, data, agendaItemId) => dispatch =>
  removeAgendaTemplatePart(agendaId, data, agendaItemId)(dispatch).then(() => {
    toast.dismiss();
    toast.success('Removed from the agenda template.');
  }).catch(() => {
    toast.dismiss();
    toast.error('There was a problem updating the agenda template.');
  });

export const SEND_BULK_AGENDA = makeAsyncActionSet('SEND_BULK_AGENDA');
export function sendBulkAgenda(agendaId) {
  return (dispatch) => {
    return request(
      SEND_BULK_AGENDA,
      `/api/agendas/${agendaId}/send-bulk-agenda/`,
      'POST',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const sendBulkAgendaAndRedirect = (agendaId) => dispatch =>
  sendBulkAgenda(agendaId)(dispatch).then(() => {
    window.location.href = '/agendas/list/';
  }).catch(() => {
    toast.dismiss();
    toast.error('The bulk agenda could not be sent.');
  });

export const cancelBulkAgendaAndRedirectToCreate = (agendaTemplateId) => dispatch =>
  cancelBulkAgenda(agendaTemplateId)(dispatch).then(() => {
    window.location.href = '/agendas/create-multiple/';
  }).catch(() => {
    toast.dismiss();
    toast.error('There was an error cancelling the agenda');
  });

export const BULK_SEND_AGENDAS = makeAsyncActionSet('BULK_SEND_AGENDAS');
export function bulkSendAgendas(agendaIds) {
  return (dispatch) => {
    return request(
      BULK_SEND_AGENDAS,
      '/api/agendas/bulk-send-agendas/',
      'POST',
      {agenda_ids: agendaIds},
      { shouldRethrow }
    )(dispatch);
  };
}

export const bulkSendAgendasAndToast = (agendaIds) => dispatch =>
  bulkSendAgendas(agendaIds)(dispatch).then(() => {
    toast.dismiss();
    toast.success(`Sent ${agendaIds.length} agenda(s)`);
    dispatch(clearAllSelectedListItems());
  }).catch(() => {
    toast.dismiss();
    toast.error('There was an error sending agendas');
  });
