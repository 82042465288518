import * as React from 'react';  // eslint-disable-line
import { openSimpleModal } from '^/actions/modals';
import store from '^/store';
import CustomQuestionForm from '^/components/custom-questions/CustomQuestionForm';

function openEditQuestionModal(event, question) {
  event.preventDefault();
  store.dispatch(
    openSimpleModal({
      body: <CustomQuestionForm editQuestionId={question.id} />,
      title: `Edit custom question`,
    })
  );
}

const EditQuestionCell = props => (
  <a
    onClick={(event) => openEditQuestionModal(event, props.question)}
    className="btn-icon btn-icon-widget-list orange"
    href=""
    title="Edit"
  >
    <i className="far fa-pencil" aria-hidden="true"></i>
  </a>
);

export default EditQuestionCell;
