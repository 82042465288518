import classNames from 'classnames';
import * as React from 'react';  // eslint-disable-line no-unused-vars

import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';
import { rolesDisplay } from '^/roles';
import { joinTruthyStringsWith } from '^/utils';

export const usersListConfig = (
  isExecutive,
  isManagerAdminUserOrHigher,
  selectedUsers,
  toggleUserSelection,
  openEditUserModal,
  reloadPage,
  organisationFilterTerms,
  hasAdditionalFilters,
  ethnicityTerm,
  genderTerm,
  hasCustomFilters
) => [
  ...(
    isManagerAdminUserOrHigher && !isExecutive ? [{
      name: 'selectForManagerAssignment',
      display_name: '',
      type: 'custom',
      customItemHandler: (user) => {
        const isSelected = selectedUsers.indexOf(user.id) !== -1;
        return (
          <input type="checkbox" onChange={() => toggleUserSelection(user.id)} checked={isSelected}/>
        );
      },
      sortable: false,
    }] : []
  ),
  {
    name: 'first_name',
    display_name: 'Name/Email',
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <span title={user.name} className="display-block text-overflow-ellipsis">
          {user.name}
        </span>
        <span title={user.email} className="display-block text-help text-overflow-ellipsis">
          {user.email}
        </span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'type',
    display_name: 'Role',
    type: 'custom',
    customItemHandler: (user) => rolesDisplay[user.type],
    sortable: true,
  },
  {
    name: 'team',
    display_name: `${organisationFilterTerms.team}/Reports To`,
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.team)}>{user.team}</CellContentOrEmpty>
        <span className="display-block text-help text-overflow-ellipsis">
          <span>{user.manager}</span>
          {user.remaining_managers > 0 && (
            <span> + {user.remaining_managers} more</span>
          )}
        </span>
      </span>
    ),
    sortable: false,
  },
  {
    name: 'filters',
    display_name:
      `${organisationFilterTerms.department}/${organisationFilterTerms.location}/${organisationFilterTerms.unit}`,
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.department_filter)}>{user.department_filter}</CellContentOrEmpty>
        <span className="display-block text-help">
          {joinTruthyStringsWith(
            [user.location_filter, user.unit_filter],
            ' • '
          )}
        </span>
      </span>
    ),
    sortable: false,
  },
  {
    name: 'manager_filter',
    display_name: `${organisationFilterTerms.manager}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.manager_filter)}>{user.manager_filter}</CellContentOrEmpty>
    ),
    sortable: false,
  },
  ...(
    hasAdditionalFilters || hasCustomFilters ? [
      {
        name: 'additional_filters',
        display_name: [
          genderTerm,
          ethnicityTerm,
          hasCustomFilters && 'Custom Filters'
        ].filter(term => !!term).join('/'),
        type: 'custom',
        customItemHandler: (user) => {
          const customFilterLabels = user.custom_filters.join(', ');
          return (
            <CellContentOrEmpty condition={!!user.additional_filters.length || !!user.custom_filters.length}>
              {user.additional_filters.map(filterLabel => {
                return (
                  <span
                    key={`${filterLabel}-${user.id}`}
                    title={filterLabel}
                    className="display-block text-overflow-ellipsis"
                  >
                    {filterLabel}
                  </span>
                );
              })}
              <span
                key={`custom-${user.id}`}
                title={customFilterLabels}
                className="display-block text-overflow-ellipsis"
              >
                {customFilterLabels}
              </span>
            </CellContentOrEmpty>
          );
        },
        sortable: false,
      },
    ] : []
  ),
  {
    name: 'is_active',
    display_name: 'Active',
    type: 'custom',
    customItemHandler: (user) => (
      <i
        title="1"
        className={classNames('far', {
          'fa-check ico-check': user.is_active,
          'fa-times ico-times': !user.is_active,
        })}
        aria-hidden={user.is_active}
      ></i>
    ),
    sortable: true,
  },
  ...(
    isManagerAdminUserOrHigher && !isExecutive ?
    [{
      name: 'actions',
      display_name: '',
      type: 'custom',
      customItemHandler: user => (
        <button
          className="btn orange hollow"
          onClick={() => openEditUserModal(reloadPage, user)}
        >
          Edit
        </button>
      ),
      sortable: false,
    }] : []
  ),
];
