import React from 'react'; // eslint-disable-line no-unused-vars

export const ActionLinkedResource = ({action}) => {
  const linkedResource = action.get('course_hyperlink') ||
    action.get('resource_hyperlink');

  if (!linkedResource) {
    return null;
  }

  return (
    <div>
      <p className="margin-top-none margin-bottom-small">
        <a key={linkedResource.get('id')} href={linkedResource.get('url')} target="_blank">
          {linkedResource.get('display_name')}
        </a>
      </p>
    </div>
  );
};

export default ActionLinkedResource;
