import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { createAgendaReviewQuestionAndToast } from './actions';
import AgendaItemCard from './AgendaItemCard';

export const AddQuestionToAgendaCard = ({
  item,
  isDisabled,
  createReviewQuestion
}) => {
  return (
    <AgendaItemCard
      key={item.question_id}
      title={item.question_text}
      content={item.responses.length === 0 ? null : item.responses}
      onClick={createReviewQuestion}
      isDisabled={isDisabled}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createReviewQuestion: () => (
      dispatch(createAgendaReviewQuestionAndToast(ownProps.agendaId, ownProps.item))
    )
  };
};

export default connect(undefined, mapDispatchToProps)(AddQuestionToAgendaCard);
