import { hasFailed, isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';

import { getHomepageTeamGoals, GET_HOME_PAGE_TEAM_GOALS } from '^/components/dashboards/actions';
import Loading from '^/components/Loading';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import GoalCard from '^/components/dashboards/GoalCard';
import { Stat } from '^/components/dashboards/MyGoals';

class TeamGoals extends React.PureComponent {
  componentDidMount() {
    this.props.getHomepageTeamGoals();
  }

  render() {
    const { data, isLoading, hasFailedToLoad } = this.props;

    const {
      goals = [],
      number_of_goals = 0,
      number_of_completed_goals = 0,
    } = data;

    if (hasFailedToLoad) {
      return (
        <p>Failed to load goals.</p>
      );
    }

    if (isLoading) {
      return (
        <Loading />
      );
    }

    const hasGoals = goals && Array.isArray(goals) && goals.length > 0;

    return (
      <div className="dashboard-widget-inner-container">
        <div className="flex-space-between align-items-center margin-bottom-large">
          <WidgetTitle title="Team goals" image="/static/images/dashboards/goals.svg" />
          <div className="display-flex flex-gap-base">
            <Stat
              number={number_of_goals}
              title="Goals set"
              className="dashboard-stat"
            />
            <Stat
              number={number_of_completed_goals}
              title="Completed"
              className="dashboard-stat"
            />
          </div>
        </div>

        {
          hasGoals && goals.map(goal => (
            <GoalCard key={goal.id} goal={goal} />
          )) || <p>Your team have not set any goals yet.</p>
        }
        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/goals/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    data: state.homepageTeamGoals,
    isLoading: isPending(state.responses, GET_HOME_PAGE_TEAM_GOALS),
    hasFailedToLoad: hasFailed(state.responses, GET_HOME_PAGE_TEAM_GOALS),
  };
}


export default connect(mapStateToProps, { getHomepageTeamGoals })(TeamGoals);
