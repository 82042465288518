export const CUSTOM_REPORT_LIST_PAGE_SIZE = 3;

export const SURVEY_TYPES = {
  ENGAGEMENT: 'ENGAGEMENT',
  MENTAL_HEALTH: 'MENTAL_HEALTH',
  CULTURE_AND_DIVERSITY: 'CULTURE_AND_DIVERSITY'
};

export const SURVEY_TYPES_BUTTON_LABEL_MAP = {
  [SURVEY_TYPES.ENGAGEMENT]: 'Engagement',
  [SURVEY_TYPES.MENTAL_HEALTH]: 'Mental health',
  [SURVEY_TYPES.CULTURE_AND_DIVERSITY]: 'DEEI'
};

export const REPORT_TYPES = {
  TREND_ANALYSIS_LINE: 'TREND_ANALYSIS_LINE',
  TREND_ANALYSIS_HEATMAP: 'TREND_ANALYSIS_HEATMAP',
  INTERNAL_BENCHMARKING_HEATMAP: 'INTERNAL_BENCHMARKING_HEATMAP',
  EXTERNAL_BENCHMARKING_HEATMAP: 'EXTERNAL_BENCHMARKING_HEATMAP'
};

export const CHART_TYPE_LABEL_MAP = {
  [REPORT_TYPES.TREND_ANALYSIS_LINE]: 'Overall score over time line chart',
  [REPORT_TYPES.TREND_ANALYSIS_HEATMAP]: '16 scores over time heatmap',
  [REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP]: 'Internal benchmarking heatmap',
  [REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP]: 'External benchmarking heatmap',
};

export const HEATMAP_REPORT_TYPES = [
  REPORT_TYPES.TREND_ANALYSIS_HEATMAP,
  REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP,
  REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP
];

export const WETHRIVE_AVERAGE = 'WETHRIVE_AVERAGE';
export const INDUSTRY = 'INDUSTRY';
export const COMPANY_SIZE = 'COMPANY_SIZE';
export const INDUSTRY_AND_COMPANY_SIZE = 'INDUSTRY_AND_COMPANY_SIZE';

export const selectableBenchmarks = [
  {
    id: WETHRIVE_AVERAGE,
    name: 'WeThrive average',
  },
  {
    id: INDUSTRY,
    name: 'Industry',
  },
  {
    id: COMPANY_SIZE,
    name: 'Org size',
  },
  {
    id: INDUSTRY_AND_COMPANY_SIZE,
    name: 'Industry & size',
  },
];
