import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import CollapsableWell from '../common/CollapsibleWell';
import AgendaItemCard from './AgendaItemCard';
import { addGoalToAgendaAndToast } from './actions';
import { SelectableTitleByType, AgendaSectionType } from './constants';

function SelectableGoalCard({goal, addGoalToAgenda, isDisabled}) {
  return (
    <AgendaItemCard
      title={
        <a href={goal.url} target="_blank">{goal.name}</a>
      }
      score={`${goal.percentage_complete}%`}
      onClick={addGoalToAgenda}
      isDisabled={isDisabled}
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addGoalToAgenda: () => dispatch(addGoalToAgendaAndToast(
      ownProps.agendaId,
      ownProps.goal
    )),
  };
};

const ConnectedSelectableGoalCard = connect(undefined, mapDispatchToProps)(SelectableGoalCard);

export function SelectableAgendaEmployeeGoals({selectableGoals, agendaId, isDisabled}) {
  return (
    <CollapsableWell
      title={SelectableTitleByType[AgendaSectionType.EMPLOYEE_GOALS]}
      isOrange
      isCollapsed
    >
      {selectableGoals.length ? selectableGoals.map((goal) => (
        <ConnectedSelectableGoalCard
          key={goal.id}
          agendaId={agendaId}
          goal={goal}
          isDisabled={isDisabled}
        />
      )) : (
        <p className="margin-top-x-large font-size-14 font-weight-bold">No goals available.</p>
      )}
    </CollapsableWell>
  );
}


const mapStateToProps = (state, props) => {
  const availableGoals = props.goalOptions.concat(state.agendaGoalsCreated);
  return {
    selectableGoals: availableGoals.filter(goalOption =>
      !state.editingAgenda.employee_goals.find(agendaGoal =>
        goalOption.id === agendaGoal.goal.id
      )
    )
  };
};

export default connect(mapStateToProps)(SelectableAgendaEmployeeGoals);
