import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'underscore';

import {
  getSurveyOptions,
  updateSelectedSurveys,
  GET_SURVEY_OPTIONS
} from '^/components/custom-reports/actions';
import { REPORT_TYPES } from '^/components/custom-reports/constants';

export class SurveySelect extends React.PureComponent {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.props.updateSelectedSurveys(this.props.initialValue);
    }
    if (!this.props.options) {
      this.props.getSurveyOptions();
    }
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.selectedSurveyType !== this.props.selectedSurveyType &&
      !this.props.options
    ) {
      this.props.getSurveyOptions();
    }
  }

  onChange(selectedSurvey) {
    if (this.props.isMulti) {
      this.props.updateSelectedSurveys([...this.props.selectedSurveys, selectedSurvey]);
    }
    this.props.updateSelectedSurveys(selectedSurvey);
  }

  render() {
    return (
      <div>
        <Select
          placeholder={this.props.placeholder || 'Type to search...'}
          noResultsText={this.props.noResultsText || 'No Results found. Type to search...'}
          options={this.props.options}
          onChange={this.onChange}
          clearable={false}
          onBlur={_.noop}
          multi={this.props.multi}
          value={this.props.selectedSurveys}
          disabled={this.props.isDisabled}
        />
        {this.props.multi && (
          <button
            className="link-color-text background-none underline padding-none font-size-14 margin-top-base"
            onClick={this.props.selectAll}
          >
            Select all
          </button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let options = state.surveyOptions[ownProps.selectId];
  const selectedSurveys = state.selectedSurveys[ownProps.selectId] || [];

  if (options && ownProps.reportType === REPORT_TYPES.TREND_ANALYSIS_LINE) {
    const selectedSurveyDates = selectedSurveys.map((survey) => survey.label.slice(0, 10));
    options = options.map(
      (option) => Object.assign({}, option, { disabled: selectedSurveyDates.includes(option.label.slice(0, 10)) })
    );
  }

  return {
    options,
    selectedSurveys,
    selectedSurveyType: state.selectedSurveyType,
    isDisabled: isPending(state.responses, GET_SURVEY_OPTIONS) || ownProps.isCreatingReport
  };
}

function mapDispatchToProps(dispatch, props) {
  const filters = Object.assign(
    {'questionnaire_type': props.selectedSurveyType},
    props.reportType === REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP ? {'internal_benchmarking': true} : {}
  );
  return {
    getSurveyOptions: () => dispatch(getSurveyOptions(props.selectId, filters)),
    updateSelectedSurveysFunction: (surveyType) =>
      (survey) => dispatch(updateSelectedSurveys(surveyType, survey)),
    selectAllFunction: (options) => () => dispatch(updateSelectedSurveys(props.selectId, options))
  };
}


function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    selectAll: dispatchProps.selectAllFunction(
      stateProps.options
    ),
    updateSelectedSurveys: dispatchProps.updateSelectedSurveysFunction(
      stateProps.selectedSurveyType
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SurveySelect);
