import React from 'react'; // eslint-disable-line
import { isPending } from '@dabapps/redux-requests';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { closeModal } from '^/actions/modals';
import { createRequestedPeerFeedbackAndCloseModal } from '^/components/peer-feedback/actions';
import { CREATE_REQUESTED_PEER_FEEDBACK } from '^/components/peer-feedback/actions';

const FORM_NAME = 'RequestedFeedbackModalForm';

export const RequestedFeedbackModalForm = ({
  handleSubmit,
  close,
  disabled
}) => {
  return (
    <form onSubmit={handleSubmit} disabled={disabled}>
      <div className="display-flex margin-top-xx-large position-relative">
        <label
          className="font-size-14 width-180px flex-shrink-0 font-family-varela-round"
          htmlFor="message"
        >
          Feedback:
        </label>
        <Field
          className="min-height-130px"
          name="message"
          placeholder="Add text here..."
          component="textarea"
          required
        />
        <p className="position-absolute right-0 bottom-minus-20 font-size-12">Click and drag to resize</p>
      </div>
      <div className="margin-top-xxx-large display-flex justify-content-end flex-gap-x-large">
        <button
          className="pink-button"
          type="submit"
          disabled={disabled}
        >
          Save & share
        </button>
        <button
          className="pink-border-button text-pink"
          onClick={close}
          disabled={disabled}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export const FormifiedRequestedFeedbackModalForm = reduxForm({
  form: FORM_NAME,
})(RequestedFeedbackModalForm);

const mapStateToProps = (state) => ({
  disabled: isPending(state.responses, CREATE_REQUESTED_PEER_FEEDBACK),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  close: (event) => {
    event.preventDefault();
    dispatch(closeModal());
  },
  onSubmit: (data) => {
    dispatch(createRequestedPeerFeedbackAndCloseModal(ownProps.feedbackRequestId, data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormifiedRequestedFeedbackModalForm);
