import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { isPending } from '@dabapps/redux-requests';
import { closeModal } from '^/actions/modals';
import { ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA } from '^/components/agendas/actions';
import SelectableEngagementSurveyQuestionCard from './SelectableEngagementSurveyQuestionCard';

export const EngagementQuestionsModal = ({
  agendaId,
  isDisabled,
  close,
  engagementQuestions
}) => {
  return (
    <div>
      <ModalBody>
        {engagementQuestions.map((option) => (
          <SelectableEngagementSurveyQuestionCard
            key={option.question_sub_area_id}
            agendaId={agendaId}
            option={option}
            isDisabled={isDisabled}
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button onClick={close}>Close</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    isDisabled: props.isDisabled || isPending(state.responses, ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => {
      dispatch(closeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EngagementQuestionsModal);
