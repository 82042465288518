import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import roles from '^/roles';
import { userMeetsRequiredRole } from '^/utils/permissions';
import { getTeamReviewListConfig } from './config';
import { TEAM_REVIEW_LIST } from './constants';

export const TeamReviewList = ({ loggedInUser, performanceReviewTerm }) => (
  <div className="will">
    <ChadminList
      className="team-review-list"
      collectionPath="survey/organisation-surveys"
      collectionName={TEAM_REVIEW_LIST}
      columns={getTeamReviewListConfig(performanceReviewTerm, loggedInUser)}
      defaultFilters={{
        show_all: userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN),
        is_review_list: true
      }}
      ExtraControlsLeft={() => (
        <a
          className="btn orange middle"
          href="/reviews/create/"
        >
          Schedule {performanceReviewTerm.toLowerCase()}
        </a>
      )}
      ExtraControlsRight={() => userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN) && (
        <UriFilterSelect
          filterName="show_all"
          name="show"
          hideBlankOption
          options={[
            {value: 'true', label: 'All reviews'},
            {value: 'false', label: 'My team reviews'},
          ]}
          hasInlineLabels
        />
      )}
      hasInlineLabels
      searchable
    />
  </div>
);

const mapStateToProps = ({ loggedInUser }) => ({ loggedInUser });

export default connect(mapStateToProps)(TeamReviewList);
