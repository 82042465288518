import { List } from 'immutable';
import { ModalRenderer } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { getResourcesFilterOptions } from '^/actions/actions';
import { updateResourceWeightAndReload } from '^/actions/actionSequences';
import { openSimpleModal } from '^/actions/modals';
import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { getManageResourcesListConfig } from '^/components/resources/config';
import { MANAGE_RESOURCES_LIST } from '^/components/resources/constants';
import CreateEditResourceModal from '^/components/resources/CreateEditResourceModal';
import DeleteResourceModal from '^/components/resources/DeleteResourceModal';
import { getQueryParamsFromLocation } from '^/utils';


export class ManageResources extends React.Component {

  componentDidMount() {
    this.props.getFilterOptions();
  }

  render() {
    const { modals, filterOptions, filtersFromLocation } = this.props;

    return (
      <div className="will manage-resources-list">
        <ModalRenderer modals={modals}/>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
        <ChadminList
          collectionPath={'resources/manage_resources'}
          collectionName={MANAGE_RESOURCES_LIST}
          getColumns={(reloadPage) => getManageResourcesListConfig(
            this.props.openCreateEditResourceModal,
            this.props.openDeleteModal,
            reloadPage,
            Boolean(filtersFromLocation.area) && !filtersFromLocation.for_user_type,
            this.props.numberOfResources,
            this.props.updateWeight
          )}
          className="resources-list"
          ExtraControlsLeft={({reloadPage}) => (
            <button
              className="btn hollow orange"
              onClick={() => this.props.openCreateEditResourceModal(reloadPage)}
            >
              Add Resource
            </button>
          )}
          ExtraControlsRight={() => (
            <div>
              <UriFilterSelect
                filterName="area"
                name="area"
                options={filterOptions.areas}
                hasInlineLabels
              />
              <UriFilterSelect
                filterName="for_user_type"
                name="User Type"
                label="For"
                options={filterOptions.for_user_types}
                hasInlineLabels
              />
            </div>
          )}
          hasInlineLabels
        />
      </div>
    );
  }
}

export { ManageResources as UnconnectedManageResources };

export function mapStateToProps({collections, filterOptions, modals}) {
  return {
    filtersFromLocation: getQueryParamsFromLocation(),
    numberOfResources: collections.getIn(
      [MANAGE_RESOURCES_LIST, 'items'],
      List()
    ).count(),
    filterOptions,
    modals,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    openCreateEditResourceModal: (reloadPage, resource) => {
      const title = resource ? `Edit ${resource.display_name}` : 'Add resource';
      return dispatch(
        openSimpleModal({
          title,
          body: (
            <CreateEditResourceModal
              resourceId={resource ? resource.id : undefined}
              reloadPage={reloadPage}
              areaOptions={props.areaOptions}
            />
          ),
          large: true,
        })
      );
    },
    openDeleteModal: (reloadPage, resource) => {
      return dispatch(
        openSimpleModal({
          title: `Delete ${resource.display_name}?`,
          body: (
            <DeleteResourceModal
              resource={resource}
              reloadPage={reloadPage}
            />
          )
        })
      );
    },
    getFilterOptions: () => dispatch(getResourcesFilterOptions()),
    updateWeight: (resource, newWeight, reloadPage) =>
      dispatch(updateResourceWeightAndReload(
        resource,
        newWeight,
        reloadPage
      )
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageResources);
