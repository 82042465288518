export const MANAGE_RESOURCES_LIST = 'MANAGE_RESOURCES_LIST';

export const MANAGE_RESOURCES_PATH = 'resources/manage_resources';
export const MANAGE_RESOURCES_UPDATE_WEIGHT_PATH = 'resources/manage_resources/update-weight';

export const USER_TYPES_FOR = {
  EMPLOYEES: 'EMPLOYEES',
  MANAGERS: 'MANAGERS',
  MANAGERS_AND_EMPLOYEES: 'MANAGERS_AND_EMPLOYEES',
};

export const USER_TYPE_FOR_DISPLAY_MAPPING = {
  [USER_TYPES_FOR.EMPLOYEES]: 'Employees',
  [USER_TYPES_FOR.MANAGERS]: 'Managers',
  [USER_TYPES_FOR.MANAGERS_AND_EMPLOYEES]: 'Managers and Employees',
};
