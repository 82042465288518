import classNames from 'classnames';
import { isPending, hasFailed } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import {
  getMyTeamFeedbackReceived,
  GET_MY_TEAM_FEEDBACK_RECEIVED
} from '^/components/dashboards/actions';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import { openSimpleModal, openMediumModal } from '^/actions/modals';
import { REGARDING_OPTIONS } from '^/components/peer-feedback/constants';
import PeerFeedbackModalForm from '^/components/peer-feedback/PeerFeedbackModalForm';
import RequestFeedbackModalForm from '^/components/peer-feedback/RequestFeedbackModalForm';
import Loading from '^/components/Loading';

const cardClassNames = [
  'padding-vertical-xx-large',
  'padding-horizontal-large',
  'border-base',
  'border-radius-10'
];

const flexCardClassNames = [
  'flex-column',
  'align-items-center',
  'flex-space-between'
];

export class ManagerFeedbackWidget extends React.PureComponent {
  componentDidMount() {
    this.props.getMyTeamFeedbackReceived();
  }

  renderFeedbackDataSection() {
    const {
      isLoading,
      hasFailedLoading,
      feedback_received_month,
      feedback_received_year,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    if (hasFailedLoading) {
      return <p>Failed to load feedback data.</p>;
    }

    return (
      <div className="display-flex flex-gap-base full-width margin-vertical-xx-large">
        <div className="widget-item-grey width-50-percent text-align-left">
          <div className="font-size-38 margin-bottom-small">
            {feedback_received_month}
          </div>
          <div className="font-size-18 margin-bottom-small">
            This month
          </div>
        </div>
        <div className="widget-item-grey width-50-percent text-align-left">
          <div className="font-size-38 margin-bottom-small">
            {feedback_received_year}
          </div>
          <div className="font-size-18 margin-bottom-small">
            This year
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      openPeerFeedbackModal,
      openRequestFeedbackModal,
      large
    } = this.props;
    return (
      <div className="dashboard-widget-inner-container text-align-center">
        <WidgetTitle title="Feedback" image="/static/images/dashboards/feedback.png" />
        <div className="display-flex flex-gap-base">
          <div className={classNames(...cardClassNames, ...flexCardClassNames, 'width-30-percent')}>
            <div className="font-family-effra-bold font-size-28">
              Give
            </div>
            <div className="font-size-18">
              Send feedback to someone else
            </div>
            <button
              className="widget-view-all-button font-size-18 padding-large"
              onClick={openPeerFeedbackModal}
            >
              Give feedback
            </button>
          </div>
          <div className={classNames(...cardClassNames, ...flexCardClassNames, 'width-30-percent')}>
            <div className="font-family-effra-bold font-size-28">
              Request
            </div>
            <div className="font-size-18">
              Want feedback about your team members?
            </div>
            <button
              className="widget-view-all-button font-size-18 padding-large"
              onClick={openRequestFeedbackModal}
            >
              Request feedback
            </button>
          </div>
          <div className={classNames(...cardClassNames, {
            'flex-grow': large,
            'width-350': !large
          })}>
            <div className="font-family-effra-bold font-size-28">
              All feedback my team has received
            </div>
            {this.renderFeedbackDataSection()}
            <a
              className="widget-view-all-button font-size-18 padding-large"
              href="/people/peer-feedback/"
            >
              View all
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    feedback_received_month: state.myTeamFeedbackReceived.feedback_received_month,
    feedback_received_year: state.myTeamFeedbackReceived.feedback_received_year,
    isLoading: isPending(state.responses, GET_MY_TEAM_FEEDBACK_RECEIVED),
    hasFailedLoading: hasFailed(state.responses, GET_MY_TEAM_FEEDBACK_RECEIVED),
  };
}

const mapDispatchToProps = dispatch => ({
  openRequestFeedbackModal: () => dispatch(
    openSimpleModal({
      titleComponent: (
        <h1 className="font-color-main font-size-38 font-family-effra-bold margin-bottom-none padding-left-large">
          Request employee feedback
        </h1>
      ),
      body: (
        <RequestFeedbackModalForm regarding={REGARDING_OPTIONS.OTHERS} />
      ),
      large: true
    })
  ),
  openPeerFeedbackModal: () => dispatch(
    openMediumModal({
      titleComponent: (
        <h1 className="font-color-main font-size-38 font-family-effra-bold margin-bottom-none padding-left-small">
          Give feedback
        </h1>
      ),
      body: (
        <PeerFeedbackModalForm />
      )
    })
  ),
  getMyTeamFeedbackReceived: () => dispatch(getMyTeamFeedbackReceived())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerFeedbackWidget);
