import { Column, Row } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { openSimpleModal } from '^/actions/modals';
import UninstallSlack from './UninstallSlack';
import { ConnectButton } from './ConnectButton';
import HRIntegrationModal from './HRIntegrationModal';

export class IntegrationSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.openSlackUninstallModal = this.openSlackUninstallModal.bind(this);
    this.openIntegrationModal = this.openIntegrationModal.bind(this);
  }

  openIntegrationModal() {
    if (this.props.hrIntegrationActive) {
      return null;
    }
    this.props.openSimpleModal({
      body: <HRIntegrationModal />,
      title: 'Connect your HR system'
    });
  }

  openSlackUninstallModal() {
    this.props.openSimpleModal({
      body: <UninstallSlack slackUninstallInstructionsUrl={this.props.slackUninstallInstructionsUrl} />,
      title: 'Uninstalling Slack Integration'
    });
  }

  render() {
    return (
      <div>
        <div>
          <h3 className="margin-bottom-small">HR Systems</h3>
          <p>Sync your people data from your existing HR system.</p>
          <Row>
            <Column md={3}>
              <ConnectButton
                openModal={this.openIntegrationModal}
                hrIntegrationActive={this.props.hrIntegrationActive}
                hrConnectedName={this.props.hrConnectedName}
              />
            </Column>
          </Row>
        </div>

        <div className="margin-top-large">
          <h3 className="margin-bottom-small">Notifications</h3>
          <p>Get notifications to your people via existing communications channels.</p>
          <Row>
            <Column md={3}>
              {this.props.slackIntegrationActive ? (
                <div onClick={this.openSlackUninstallModal} className="integration-card integration-card-active">
                  <img className="integration-card-logo" src="/static/images/slack/Slack_RGB.svg" />
                  <div className="integration-card-active-overlay">Active</div>
                </div>
              ) : (
                <a href={this.props.slackInstallUrl} target="_blank">
                  <div className="integration-card">
                    <img className="integration-card-logo" src="/static/images/slack/Slack_RGB.svg" />
                  </div>
                </a>
              )}
            </Column>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modals
  };
}

export default connect(mapStateToProps, {
  openSimpleModal
})(IntegrationSettings);
