import React from 'react';

import classNames from 'classnames';

const ProgressBar = ({ percentComplete, className, children }) => {
  return (
    <div className={classNames('progress-container', className)}>
      <span className={classNames('progressbar', percentComplete === 100 && 'completed')}>
        <span className="progress-indicator" style={{ width: `${percentComplete}%` }} />
      </span>
      <span className="number">
        {children}
      </span>
    </div>
  );
};

ProgressBar.propTypes = {
  className: React.PropTypes.string,
  large: React.PropTypes.bool,
  percentComplete: React.PropTypes.number.isRequired,
};

export default ProgressBar;
