import React from 'react'; // eslint-disable-line

export default function NotificationBody ({ children, title, text }) {
  return (
    <div className="padding-vertical-small">
      {title && <h4 className="margin-vertical-none font-size-smaller">{title}</h4>}
      {text && <p className="margin-vertical-none font-size-smaller">{text}</p>}
      {children}
    </div>
  );
};

NotificationBody.propTypes = {
  title: React.PropTypes.string,
};
