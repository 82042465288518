

export const SELECT_REMOTE_USER = 'SELECT_REMOTE_USER';
export function selectRemoteUser(selectId, newValue) {
  return {
    type: SELECT_REMOTE_USER,
    payload: {
      selectId,
      newValue
    }
  };
}
