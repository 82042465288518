import React from 'react'; // eslint-disable-line no-unused-vars

import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { getManagerListConfig } from '^/components/managers/config';

export const MANAGER_LIST = 'MANAGER_LIST';

export default class ManagerList extends React.PureComponent {
  render() {
    const {
      filterOptions: { teams, departments, locations, units, managers },
      organisationFilterTerms
    } = this.props;
    return (
      <div className="will">
        <ChadminList
          collectionPath="people/manage-managers"
          collectionName={MANAGER_LIST}
          columns={getManagerListConfig(organisationFilterTerms)}
          ExtraControlsRight={() => (
            <div>
              <UriFilterSelect
                filterName="team_member__team_id"
                name={organisationFilterTerms.team}
                options={teams}
              />
              <UriFilterSelect
                filterName="team_member__department_filter_id"
                name={organisationFilterTerms.department}
                options={departments}
              />
              <UriFilterSelect
                filterName="team_member__location_filter_id"
                name={organisationFilterTerms.location}
                options={locations}
              />
              <UriFilterSelect
                filterName="team_member__unit_filter_id"
                name={organisationFilterTerms.unit}
                options={units}
              />
              <UriFilterSelect
                filterName="team_member__manager_filter_id"
                name={organisationFilterTerms.manager}
                options={managers}
              />
            </div>
          )}
        />
      </div>
    );
  }
}
