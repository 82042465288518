import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { AdminList } from '@dabapps/chadmin';
import AdminListSearch from '^/components/admin/AdminListSearch';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';
import {
  addInSurveyAndUpdate,
  removeFromSurveyAndUpdate,
} from '^/actions/actionSequences';
import { getCollectionFromUrl } from '^/actions/collections';
import { setPageSize, surveySelectAll, surveyRemoveAll } from '^/actions/actions';
import AdminListPagination from '^/components/admin/AdminListPagination';
import { fetchFieldConfig, setFilter, setFilterBulk } from '^/actions/actions';
import AdminListFilter from '../../admin/AdminListFilter';
import { ALL } from '^/consts/forms';
import { DEFAULT_PAGE_SIZE } from '^/consts/inputs';
import { Column, Row, SpacedGroup } from '@dabapps/roe';
import Loading from '^/components/Loading';
import { isPending } from '../../../consts/responseStates';
import DateRangeFilter from '^/components/common/DateRangeFilter';
import moment from 'moment/moment';
import PageSize from '^/components/common/PageSize';
import { FORMAT_DATE } from '../../../utils';
import { canAccessAdmin } from '^/utils/permissions';

export const SUPPORT_USERS_LIST = 'SUPPORT_USERS_LIST';

const CONFIG_NAME = 'support/users';

export class UserList extends PureComponent {

  constructor() {
    super();
    this.loadPage = this.loadPage.bind(this);
    this.getOrganisationDisplay = this.getOrganisationDisplay.bind(this);
  }

  getOrganisationDisplay(user) {
    const { shouldLinkToAdmin } = this.props;
    if (!user.organisation) {
      return (<span></span>);
    }
    return shouldLinkToAdmin ? (
        <a target="_blank" href={`/admin/organisations/organisation/${user.organisation.id}/`}>
          {user.organisation.name}
        </a>
      ) :
      user.organisation.name;
  }

  getColumns() {
    const { shouldLinkToAdmin } = this.props;
    return fromJS([
      {
        name: 'username',
        display_name: 'Username',
        type: 'custom',
        customItemHandler: user => shouldLinkToAdmin ? (
          <a target="_blank" href={`/admin/account/user/${user.id}/`}>
            {user.username}
          </a>
        ) : user.username,
        sortable: true
      },
      {
        name: 'organisation',
        display_name: 'Organisation',
        type: 'custom',
        customItemHandler: this.getOrganisationDisplay,
        sortable: true
      },
      {
        name: 'type',
        display_name: 'Type',
        type: 'text',
        sortable: true
      },
      {
        name: 'is_active',
        display_name: 'Active',
        type: 'boolean',
        sortable: true
      },
      {
        name: 'date_joined',
        display_name: 'Joined',
        type: 'custom',
        customItemHandler: user => moment(user.date_joined).format(FORMAT_DATE),
        sortable: true
      },
      {
        name: 'last_login',
        display_name: 'Last Login',
        type: 'custom',
        customItemHandler: user => moment(user.last_login).format(FORMAT_DATE),
        sortable: true
      },
      {
        name: 'action_count',
        display_name: 'Actions',
        type: 'text',
        sortable: true
      },
      {
        name: 'complete_action_count',
        display_name: 'Completed Actions',
        type: 'text',
        sortable: true
      },
      {
        name: 'impersonate',
        display_name: 'Impersonate',
        type: 'custom',
        customItemHandler: user => <a href={`/runas/${user.id}`}>Impersonate</a>,
        sortable: true
      },
    ]);
  }

  getFilters(overrideFilterSpec) {
    const filterSpec = overrideFilterSpec || this.props.filterSpec;
    return filterSpec.map((filterValue) => {
      const filter = filterValue !== ALL ? filterValue : null;
      return (filter instanceof moment) ? filter.format('YYYY-MM-DD') : filter;
    }).toJS();
  }

  loadCollection(pageSize) {
    this.props.getCollectionFromUrl(
      `/support/users/`,
      {pageSize: pageSize || this.props.pageSize, filters: this.getFilters()},
      SUPPORT_USERS_LIST
    );

  }

  componentWillReceiveProps(newProps) {
    if (newProps.filterSpec !== this.props.filterSpec) {
      this.loadPage(this.page, newProps.filterSpec);
    }
  }

  componentWillMount() {
    this.props.setFilterBulk(SUPPORT_USERS_LIST, this.props.queryParams.toJS());

    if (this.props.queryParams.isEmpty()) {
      this.loadCollection();
    }

    this.props.fetchFieldConfig(CONFIG_NAME);
  }

  loadPage(page, overrideFilterSpec = null, searchString = null) {
    this.props.getCollectionFromUrl(
      `/support/users/`,
      {page, pageSize: this.props.pageSize, filters: this.getFilters(overrideFilterSpec), searchString},
      SUPPORT_USERS_LIST
    );
  }

  setPageSize(pageSize) {
    this.props.setPageSize(SUPPORT_USERS_LIST, pageSize);
    this.loadCollection(pageSize);
  }

  filterCollection(filterKey, filterValue) {
    this.props.setFilter(SUPPORT_USERS_LIST, filterKey, filterValue);
  }

  render() {
    if (!this.props.filters) {
      return <Loading />;
    }

    const filters = this.props.filters;
    const columns = this.getColumns();

    return (
      <div className="admin-container-content">
        <div className="admin-list-container">

          <Row className="admin-list-controls">
            <Column>
              <AdminListSearch
               onSubmit={({searchString}) => this.loadPage(this.page, null, searchString)}
               form="users_search"
               listName={SUPPORT_USERS_LIST}
               isLoading={this.props.isLoading} />
            </Column>
          </Row>

          <Row className="admin-list-controls">
            <Column className="admin-list-survey-date-filter" xs={4}>
              <DateRangeFilter
                 filterName="Created Date"
                 fromKey="date_joined__gte"
                 toKey="date_joined__lte"
                 fromValue={this.props.filterSpec.get('date_joined__gte')}
                 toValue={this.props.filterSpec.get('date_joined__lte')}
                 onChange={this.filterCollection.bind(this)}
               />
            </Column>
            <Column className="survey-team-member-filters" xs={8}>
              <SpacedGroup className="float-right">
                {filters.map(filter => (
                  <AdminListFilter
                    key={filter.get('filter_key')}
                    onChange={(filterKey, filterValue) => this.filterCollection(filterKey, filterValue)}
                    keyName={filter.get('filter_key') + '_filter'}
                    filter={filter} />
                ))}
              </SpacedGroup>
            </Column>
          </Row>

          <Row className="admin-list-controls">
            <Column>
              <SpacedGroup className="float-right">
                <div className="stm-filter">
                  <PageSize
                    pluralItemName="Users"
                    pageSize={this.props.pageSize}
                    onChange={(newPageSize) => this.setPageSize(newPageSize)}
                  />
                </div>
              </SpacedGroup>
            </Column>
          </Row>

          <AdminList
            items={this.props.organisations.toJS()}
            columns={columns.toJS()}
            listName={SUPPORT_USERS_LIST}
            itemCount={this.props.organisationCount}
            filters={this.props.filters}
          />
          <AdminListPagination
            collection={this.props.organisationCollection}
            loadPage={this.loadPage}
            pageSize={this.props.pageSize}/>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    organisationCollection: state.collections.get(SUPPORT_USERS_LIST, Map()),
    organisations: state.collections.getIn([SUPPORT_USERS_LIST, 'items'], List()),
    organisationCount: state.collections.getIn([SUPPORT_USERS_LIST, 'count'], 0),
    pageSize: state.ui.getIn(['pageSize', SUPPORT_USERS_LIST]) || DEFAULT_PAGE_SIZE,
    filters: state.fieldConfigs.getIn([CONFIG_NAME, 'filters'], List()),
    filterSpec: state.filterSpec.get(SUPPORT_USERS_LIST, Map()),
    isLoading: isPending(state.legacyResponses.getIn(['getCollection', SUPPORT_USERS_LIST])),
    shouldLinkToAdmin: canAccessAdmin(state.loggedInUser),
  };
}

export default connect(
  mapStateToProps,
  {
    getCollectionFromUrl,
    addInSurveyAndUpdate,
    removeFromSurveyAndUpdate,
    setPageSize,
    surveySelectAll,
    surveyRemoveAll,
    fetchFieldConfig,
    setFilter,
    setFilterBulk
  }
)(UserList);
