import React from 'react';
import Loading from '^/components/Loading';
import { connect } from 'react-redux';
import { pollUserImport } from './actions';

export class PollUserImport extends React.PureComponent {
  componentDidMount() {
    this.props.pollUserImport(this.props.importId);
  }

  render() {
    return this.props.isLoading ? (
      <div className="text-center">
        <p>
          The import is currently being processed.
          Please do not leave this page as you will be unable to track its progress if you do.
        </p>
        <Loading />
        <div className="margin-top-base">
          <a href="/people/manage-people/" className="btn middle orange">Go back anyway</a>
        </div>
      </div>
    ) : (
      <div>
        {this.props.errors ? (
            <div>
              <p>Sorry, but there was an issue with the import file. Please review the error(s) below:</p>
              <ul>
                  {this.props.errors.map(error => (
                      <li>{error}</li>
                  ))}
              </ul>
              <a href="/user-import/" className="btn middle orange">Try importing again</a>
            </div>
        ) : (
            <p>The import has been processed successfully.</p>
        )}
        <a href="/people/manage-people/" className="btn middle orange">Go back</a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.pollUserImport.loading,
    errors: state.pollUserImport.errors
  };
}

export default connect(mapStateToProps, {
  pollUserImport
})(PollUserImport);
