import composeReducers from '^/reducers/composeReducers';
import { Map, fromJS } from 'immutable';
import * as actions from '^/actions/actions';


function fieldConfigs(state = Map(), action) {
  switch (action.type) {
    case actions.FETCH_FIELD_CONFIG.SUCCESS:
      return state.set(action.meta.model, fromJS(action.payload));
    default:
      return state;
  }
}

export default composeReducers([fieldConfigs], Map());