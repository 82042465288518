import classNames from 'classnames';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import {
  agendaItemEditAction,
  updateAgendaItemAndToast,
  deleteAgendaItemAndToast
} from '^/components/agendas/actions';
import CreateUpdateAgendaItemForm from '^/components/agendas/CreateUpdateAgendaItemForm';
import { isReadOnlyAgendaItem } from '^/components/agendas/utils';

export const ActionButton = ({
  label,
  isDisabled,
  handler,
  icon,
  className
}) => {
  const action = isDisabled ? null : handler;
  return (
    <button
      className={classNames(
        'btn-icon orange font-size-24 flex-shrink-0',
        className
      )}
      title={isDisabled ? '' : label}
      onClick={action}
      disabled={isDisabled}
    >
      <i className={'far margin-none fa-' + icon} aria-hidden="true"></i>
    </button>
  );
};

export function AgendaItem(props) {
  const {
    isEditing,
    agendaItem,
    isDisabled,
    isDisabledEditing,
    agendaItemId,
    isCommentItems
  } = props;

  return isEditing ? (
    <CreateUpdateAgendaItemForm
      form={`UpdateAgendaItemForm-${agendaItem.id}`}
      onSubmit={props.updateAgendaItem}
      initialValues={{ text: agendaItem.text }}
      isDisabled={isDisabled}
      agendaItemId={agendaItemId}
    />
  ) : (
    <div className="margin-top-xx-large">
      <div className="display-flex">
        <p className="margin-bottom-large margin-right-large white-space-pre-line">
          {agendaItem.text}
        </p>
        <div className="margin-left-auto">
          <ActionButton
            label="Delete"
            handler={props.deleteAgendaItem}
            icon="times"
            isDisabled={isDisabledEditing}
          />
        </div>
      </div>
      <button
        className={isCommentItems ? 'link-orange background-none underline padding-none' : 'btn middle orange'}
        onClick={props.editAgendaItem}
        disabled={isDisabledEditing}
      >
        Edit
      </button>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isDisabledEditing: (
      ownProps.isDisabled ||
      // Not needed for agenda templates which are only visible to their creators
      state.editingAgenda && isReadOnlyAgendaItem(state, ownProps.agendaItem.created_by)
    ),
    isEditing: state.editingAgendaItemIds.includes(ownProps.agendaItemId)
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editAgendaItem: () => {
      dispatch(agendaItemEditAction(ownProps.agendaItemId));
    },
    updateAgendaItem: data => {
      dispatch(updateAgendaItemAndToast(
        ownProps.agendaId,
        ownProps.agendaItemId,
        data,
        ownProps.itemsName
      ));
    },
    deleteAgendaItem: () => {
      dispatch(deleteAgendaItemAndToast(
        ownProps.agendaId,
        ownProps.agendaItemId,
        ownProps.itemsName
      ));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaItem);
