import { CALL_API } from 'redux-api-middleware';
import SETTINGS from '^/consts/settings';
import _ from 'underscore';
import * as Cookies from 'js-cookie';


// Construct a Redux Standard API-calling Action (RSAA)

export function joinQueryParameters(options) {
  return _.map(options, (value, key) =>
      [key, value].map(encodeURIComponent).join('=')
      )
    .join('&');
}

export default function makeRsaAction(
  actionSet,
  path,
  method,
  optionalData,
  meta,
  fullPath
) {
  const types = _.map([actionSet.REQUEST, actionSet.SUCCESS, actionSet.FAILURE], (requestType) => {
    if (meta) {
      return { type: requestType, meta: _.clone(meta) };
    }
    return { type: requestType };
  });

  let callSpec = {
    types: types,
    endpoint: !fullPath ? SETTINGS.API_PREFIX + path : path,
    method,
    headers: {
      'Accept': 'application/json'
    },
    credentials: 'same-origin'
  };

  // const token = TokenStore.get();
  // if (token) {
  //   callSpec.headers['Authorization'] = 'Token ' + token;
  // }

  const csrf = Cookies.get('csrftoken');
  if (csrf) {
    callSpec.headers['X-CSRFToken'] = csrf;
  }

  if (optionalData) {
    if (method === 'GET' || method === 'OPTIONS') {
      callSpec.endpoint += '?' + joinQueryParameters(optionalData);
    }
    else if (method === 'POST' || method === 'PUT' || method === 'DELETE' || method === 'PATCH') {
      const hasFile = _(optionalData).some(value => value && value.constructor === window.File);
      if (hasFile) {
        const formData = new FormData();
        _.each(optionalData, (value, key) => {
          formData.append(key, value);
        });
        callSpec.body = formData;
      }
      else {
        callSpec.headers['Content-Type'] = 'application/json';
        callSpec.body = JSON.stringify(optionalData);
      }
    }
  }

  return {
    [CALL_API]: callSpec
  };
}
