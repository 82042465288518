import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { cancelBulkAgendaAndRedirect } from '^/components/agendas/actions';

export function BulkAgendaCancelButton({cancel, isDisabled}) {
  return (
    <button
      className="pink-border-button margin-left-x-large"
      onClick={cancel}
      disabled={isDisabled}
      title="Removes the agenda template and all participants' agendas"
    >
      Cancel
    </button>
  );
}

export function mapDispatchToProps(dispatch, props) {
  return {
    cancel: () => dispatch(cancelBulkAgendaAndRedirect(props.agendaId))
  };
};

export default connect(undefined, mapDispatchToProps)(BulkAgendaCancelButton);
