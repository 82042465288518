import React from 'react'; // eslint-disable-line
import moment from 'moment';
import { FORMAT_DATE } from '../../utils';

export default function ReadonlyActionCard(props) {
  const description = props.action.get('description');
  return (
    <div className="action-card display-flex-md align-items-center">
      <div className="max-width-900">
        <strong className="font-size-14">
          {props.action.get('title')}
        </strong>
        {description && (
          <p className="margin-top-base margin-bottom-none white-space-pre-line">
            {props.action.get('description')}
          </p>
        )}
      </div>
      <div className="margin-left-auto text-align-right flex-shrink-0 padding-left-large">
        <p className="font-weight-bold margin-bottom-small">
          Due: {moment(props.action.get('due_date')).format(FORMAT_DATE)}
        </p>
        Assignee:{' '}
        {props.action.getIn(['owner', 'label'])}
      </div>
    </div>
  );
};
