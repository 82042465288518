import React from 'react'; // eslint-disable-line no-unused-vars

const PinkSendIcon = () => (
  <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.9531 11C24.9531 11.5625 24.5781 12.125 24.0156 12.3594L3.0625 21.4062C2.875 21.5 2.6875 21.5 2.5
      21.5C2.07812 21.5 1.65625 21.3594 1.375 21.0312C0.953125 20.6094 0.859375 19.9062 1.14062 19.3438L4.60938
      12.4531L15.9531 11.0469L4.60938 9.59375L1.14062 2.70312C0.859375 2.14062 0.953125 1.4375 1.375
      1.01562C1.79688 0.546875 2.5 0.40625 3.0625 0.640625L24.0625 9.64062C24.625 9.875 24.9531
      10.4375 24.9531 11Z" fill="#FF4F7B"/>
  </svg>
);

export default PinkSendIcon;
