import { fromJS, Map, List } from 'immutable';
import {
  GET_GOAL,
  ACTIONS_PICKER_EDIT_ACTION,
  ACTIONS_PICKER_EXCLUDE_ACTION,
  ACTIONS_PICKER_REINCLUDE_ACTION,
  ADD_ACTION_TO_GOAL,
  TOGGLE_ADD_ACTION_FORM,
  UPDATE_GOAL,
} from './actions';
import { UPDATE_ACTION } from '^/actions/actions';
import { ACTION_FIELD, ACTION_STATUS } from '^/consts/actions';
import { asPercent } from '../../utils';

export function goalData(state = Map(), action) {
  switch (action.type) {
    case GET_GOAL.SUCCESS:
      return fromJS(action.payload.data);
    case UPDATE_GOAL.SUCCESS:
      return state.merge(fromJS(action.payload.data));
    case UPDATE_ACTION.REQUEST:
      if (state.isEmpty()) {
        return state;
      }

      const updatedState = state.set(
        'actions',
        state
          .get('actions')
          .map((goalAction) =>
            goalAction.get('id') === action.meta.actionId
              ? goalAction.merge(
                fromJS(action.meta.actionData).delete('owner')
              )
              : goalAction
          )
      );
      return updatedState.set(
        'percentage_complete',
        asPercent(
          updatedState.get('actions').filter(
            goalAction => goalAction.get('status') === ACTION_STATUS.COMPLETED
          ).count(),
          updatedState.get('actions').count()
        )
      );
    case UPDATE_ACTION.SUCCESS:
      if (state.isEmpty()) {
        return state;
      }

      return state.set(
        'actions',
        state
          .get('actions')
          .map((goalAction) =>
            goalAction.get('id') === action.meta.actionId
              ? goalAction.merge(
                fromJS(action.payload).delete('owner')
              )
              : goalAction
          )
      );
    default:
      return state;
  }
}

export function goalPickerEditingAction(state = null, action) {
  switch (action.type) {
    case ACTIONS_PICKER_EDIT_ACTION:
      return action.payload.actionId;
    case ACTIONS_PICKER_EXCLUDE_ACTION:
      if (action.payload.actionId === state) {
        return null;
      }

      return state;
    case UPDATE_ACTION.SUCCESS:
      const fieldsUpdated = Object.keys(action.meta.actionData);
      if (fieldsUpdated.length === 1) {
        const fieldUpdated = fieldsUpdated[0];
        if ([ACTION_FIELD.DUE_DATE, ACTION_FIELD.OWNER, ACTION_FIELD.STATUS].includes(fieldUpdated)) {
          return state;
        }
      }

      return null;
    default:
      return state;
  }
}

export function actionPickerExcludedActions(state = List(), action) {
  switch (action.type) {
    case ACTIONS_PICKER_EXCLUDE_ACTION:
      return state.push(action.payload.actionId);
    case ACTIONS_PICKER_REINCLUDE_ACTION:
      return state.filterNot(
        (actionId) => actionId === action.payload.actionId
      );
    default:
      return state;
  }
}

export function isShowingAddActionForm(state = false, action) {
  switch (action.type) {
    case TOGGLE_ADD_ACTION_FORM:
      return !state;
    case ADD_ACTION_TO_GOAL.SUCCESS:
    case UPDATE_GOAL.SUCCESS:
      return false;
    default:
      return state;
  }
}
