import { anyPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { updateGenericErrorMessages } from '^/actions/actions';
import {
  createGoalAndRedirectToGoalDetail,
  CREATE_GOAL,
} from '^/components/goals/actions';
import { CREATE_AGENDA_GOAL, createAgendaGoalAndToast } from '^/components/agendas/actions';
import CreateGoalForm from '^/components/goals/CreateGoalForm';
import CreateGoalActions from '^/components/goals/CreateGoalActions';
import { FORMAT_DATE_BACKEND } from '^/utils';

export function CreateGoal({
  onSubmit,
  errorMessages,
  isCreatingGoal,
  initialValues,
  agendaId,
  agendaEmployee
}) {
  return (
    <div className={classNames({
      will: !agendaId,
      'padding-x-large': !agendaId
    })}>
      <CreateGoalForm
        onSubmit={onSubmit}
        disabled={isCreatingGoal}
        initialValues={initialValues}
        hideTitle={!!agendaId}
      />
      {errorMessages.map((message, index) => (
        <p key={`error-${index}`} className="margin-bottom-base error">{message}</p>
      ))}
      <p className="margin-bottom-large">
        <strong>Actions</strong>
      </p>
      <CreateGoalActions readOnlyOwner={agendaEmployee} />
    </div>
  );
}

function mapStateToProps(state, props) {
  return {
    actions: state.createGoalActions,
    errorMessages: state.genericErrorMessages,
    isCreatingGoal: anyPending(state.responses, [CREATE_GOAL, CREATE_AGENDA_GOAL]),
    initialValues: Object.assign({}, {
      due_date: moment().add(1, 'days'),
      owner: state.loggedInUser.get('id')
    }, props.initialValues)
  };
}

export function mapDispatchToProps(dispatch, {agendaId, agendaGoalType}) {
  return {
    getOnSubmitFunction: (actionsData) => (goalData) => {
      const data = Object.assign(
        {},
        goalData,
        {
          due_date: goalData.due_date && goalData.due_date.format(FORMAT_DATE_BACKEND),
          actions: actionsData.map(actionData => Object.assign(
            {},
            actionData,
            {
              due_date: actionData.due_date.format(FORMAT_DATE_BACKEND)
            }
          ))
        }
      );
      const hasIncompleteActions = data.actions.filter(actionData => !actionData.title).length > 0;
      let errorMessages = [];

      if (!goalData.name) {
        errorMessages.push('Please add a goal name.');
      }

      if (hasIncompleteActions) {
        errorMessages.push('Please ensure all actions have a title.');
      }

      dispatch(updateGenericErrorMessages(errorMessages));

      if (errorMessages.length === 0) {
        if (agendaId) {
          dispatch(createAgendaGoalAndToast(agendaId, data, agendaGoalType));
        } else {
          dispatch(createGoalAndRedirectToGoalDetail(data));
        }
      }
    },
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    onSubmit: dispatchProps.getOnSubmitFunction(stateProps.actions),
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateGoal);
