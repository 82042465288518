import { ModalBody } from '@dabapps/roe';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import EditGoal from '^/components/goals/EditGoal';

export function AgendaGoalManager({goal, openEditGoalModal, isDisabled}) {
  const numActions = goal.actions_count;
  const pluralised = numActions === 1 ? 'action' : 'actions';
  const actionsCountText = `With ${numActions} ${pluralised}`;
  return (
    <div className="margin-bottom-xx-large">
      <p className="margin-bottom-large">{goal.name}</p>
      {goal.description &&
        <p className="margin-bottom-large">{goal.description}</p>
      }
      <p className="margin-bottom-large">{actionsCountText}</p>
      <button
        className="btn middle orange"
        onClick={openEditGoalModal}
        disabled={isDisabled}
      >
        Edit
      </button>
    </div>
  );
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    openEditGoalModal: () => dispatch(
      openSimpleModal({
        title:'Edit goal',
        body: (
          <ModalBody>
            <EditGoal goalId={ownProps.goal.id} isAgendaGoal />
          </ModalBody>
        ),
        large: true,
      })
    ),
  };
}

export default connect(undefined, mapDispatchToProps)(AgendaGoalManager);
