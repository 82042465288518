export const EXPORT_STATUS_COMPLETE = 'COMPLETE';
export const IMPORT_STATUS_COMPLETE = 'COMPLETE';
export const IMPORT_STATUS_FAILED = 'FAILED';
export const THREE_SECONDS = 3000;
export const EXPORT_POLL_MAX_RETRIES = 50;

export const USERS_PATH = 'user';
export const CREATE_USERS_PATH = 'user/create';

export const NOTIFICATION_OPTIONS = {
  SLACK: 'SLACK',
  EMAIL: 'EMAIL',
  BOTH: 'BOTH',
};

export const NOTIFICATION_OPTIONS_DISPLAY = {
  SLACK: 'Slack',
  EMAIL: 'Email',
  BOTH: 'Both',
};
