import { anyPending, isPending } from '@dabapps/redux-requests';
import { Column, Row, ModalRenderer } from '@dabapps/roe';
import moment from 'moment';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import AgendaEditWarningModal from './AgendaEditWarningModal';
import AgendaConfirmSaveReadonlyModal from './AgendaConfirmSaveReadonlyModal';
import { openSimpleModal } from '^/actions/modals';
import CommentSection from './CommentSection';
import {
  agendaItemEditAction,
  getAgenda,
  GET_AGENDA,
  UPDATE_AGENDA,
  UPDATE_AGENDA_ITEM,
  CREATE_AGENDA_ITEM,
  DELETE_AGENDA_ITEM,
  CREATE_AGENDA_REVIEW_QUESTION,
  DELETE_AGENDA_REVIEW_QUESTION,
  updateAgendaLastEdited,
  UPDATE_AGENDA_SECTION_POSITION,
  agendaItemCancelEdit,
  updateAgendaAndToast,
  ADD_GOAL_TO_AGENDA,
  REMOVE_GOAL_FROM_AGENDA,
  ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA,
  REMOVE_ENGAGEMENT_SURVEY_QUESTION_FROM_AGENDA,
  UPDATE_AGENDA_TALENT_SNAPSHOT,
  CREATE_AGENDA_PEER_FEEDBACK,
  DELETE_AGENDA_PEER_FEEDBACK
} from '^/components/agendas/actions';
import UpdateIndividualAgendaDetailsForm from '^/components/agendas/UpdateIndividualAgendaDetailsForm';
import {
  AgendaStatus,
  AgendaSectionType,
  AgendaSectionTypeTitleMap,
  EDIT_AGENDA_DETAILS,
  EDIT_SHARED_NOTES
} from '^/components/agendas/constants';
import { getIsAgendaEmployee } from '^/components/agendas/utils';
import Loading from '^/components/Loading';
import {
  FORMAT_DATE_BACKEND,
  getPollReportTimoutDuration,
  THREE_SECONDS,
  POLL_MAX_RETRIES
} from '^/utils';
import AgendaManagerOnlySection from './AgendaManagerOnlySection';
import SelectableAgendaEmployeeGoals from './SelectableAgendaEmployeeGoals';
import SelectedAgendaEmployeeGoals from './SelectedAgendaEmployeeGoals';
import SelectableEngagementSurveyQuestions from './SelectableEngagementSurveyQuestions';
import SelectedEngagementSurveyQuestions from './SelectedEngagementSurveyQuestions';
import AgendaPerformanceReviewSelectItems from './AgendaPerformanceReviewSelectItems';
import AgendaPerformanceReviewSection from './AgendaPerformanceReviewSection';
import AgendaSaveAsDraftModal from './AgendaSaveAsDraftModal';
import DeleteAgendaModal from './DeleteAgendaModal';
import CustomItemsSection from './CustomItemsSection';
import AgendaSectionWrapper from './AgendaSectionWrapper';
import OkrSelectItems from './OkrSelectItems';
import OkrSection from './OkrSection';
import AgendaResendModal from './AgendaResendModal';
import ExpandIcon from '../common/ExpandIcon';
import CollapseIcon from '../common/CollapseIcon';
import AgendaPeerFeedbackSelect from './AgendaPeerFeedbackSelect';
import AgendaPeerFeedbackSection from './AgendaPeerFeedbackSection';

const CHOOSE_MANAGER_HINT = 'Please choose a manager from the dropdown above';

const AgendaDetails = ({agenda, isDisabled, editAgendaDetails, canEditAgendaDetails}) => (
  <div>
    <p className="font-weight-bold margin-bottom-base font-size-14">Title</p>
    <p>{agenda.title}</p>
    <p className="font-weight-bold margin-bottom-base font-size-14">Description</p>
    <p className="white-space-pre-line">{agenda.description || '-'}</p>
    <Row>
      <Column xs={12} md={6}>
        <p className="font-weight-bold margin-bottom-base font-size-14">Employee</p>
        <p>{agenda.employee.label}</p>
      </Column>
      <Column xs={12} md={6}>
        <p className="font-weight-bold margin-bottom-base font-size-14">Manager</p>
        <p>{agenda.manager ? agenda.manager.label : '-'}</p>
      </Column>
    </Row>
    <Row>
      <Column xs={12} md={6}>
        <p className="font-weight-bold margin-bottom-base font-size-14">Date</p>
        <p>{agenda.date ? moment(agenda.date).format('DD/MM/YYYY') : '-'}</p>
      </Column>
      <Column xs={12} md={6}>
        <p className="font-weight-bold margin-bottom-base font-size-14">Time</p>
        <p>{agenda.time || '-'}</p>
      </Column>
    </Row>
    {canEditAgendaDetails && (
      <button
        className="btn middle orange"
        disabled={isDisabled}
        onClick={editAgendaDetails}
      >
        Edit
      </button>
    )}
  </div>
);

function hideAgendaSection(sectionType, agenda, okrOptions, peerFeedbackOptions) {
  if (
    sectionType === AgendaSectionType.OKRS &&
    (
      !okrOptions &&
      !(agenda.okrs.length || agenda.okr_comments.length)
    )
  ) {
    return true;
  }
  if (
    sectionType === AgendaSectionType.PEER_FEEDBACK &&
    (
      !peerFeedbackOptions &&
      !(agenda.peer_feedback.length || agenda.peer_feedback_comments.length)
    )
  ) {
    return true;
  }
  return false;
}

export class EditAgenda extends React.PureComponent {

  constructor(props) {
    super(props);
    this.pollGetAgenda = this.pollGetAgenda.bind(this);
    this.toggleSelectableItems = this.toggleSelectableItems.bind(this);
    this.state = {
      pollGetAgendaFailed: false,
      showSelectableItems: props.showSelectableItems
    };
  }

  componentDidMount() {
    this.props.getAgenda();
    if (this.props.isEditable) {
      this.props.updateAgendaLastEdited();
      this.intervalId = setInterval(this.props.updateAgendaLastEdited, (30 * 1000));
    } else {
      this.props.openEditWarningModal();
    }
  }

  componentDidUpdate(previousProps) {
    if (!previousProps.pendingProcessFromTemplate && this.props.pendingProcessFromTemplate) {
      this.pollGetAgenda();
    }
    if (previousProps.pendingProcessFromTemplate && !this.props.pendingProcessFromTemplate) {
      clearTimeout(this.timeout);
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  pollGetAgenda(tryCount = 0, timeoutSeconds = THREE_SECONDS) {
    const timeoutWithBackoff = getPollReportTimoutDuration(tryCount, timeoutSeconds);
    if (tryCount === POLL_MAX_RETRIES) {
      this.setState({pollGetAgendaFailed: true});
    } else {
      this.timeout = setTimeout(
        () => {
          this.props.getAgenda();
          this.pollGetAgenda(tryCount + 1, timeoutWithBackoff);
        },
        timeoutWithBackoff
      );
    }
  }

  toggleSelectableItems() {
    this.setState({showSelectableItems: !this.state.showSelectableItems});
  }

  render() {
    const {
      isDisabled,
      isLoadingAgenda,
      agenda,
      isAgendaEmployee,
      canEditAgendaDetails,
      isEditingDetails,
      engagementScoresOptions,
      latestEngagementSurveyName,
      latestEngagementSurveyStartDate,
      latestEngagementSurveyIsAnonymous,
      performanceReviewResponseOptions,
      latestPerformanceReviewName,
      latestPerformanceReviewComplete,
      engagementQuestions,
      talentSnapshotOptions,
      performanceReviewTerm,
      okrOptions,
      peerFeedbackOptions,
    } = this.props;

    if (this.state.pollGetAgendaFailed) {
      return (
        <p>
          The agenda is taking longer than usual.{' '}
          Please try again shortly and if this problem persists contact support.
        </p>
      );
    }

    if (isLoadingAgenda || !agenda) {
      return <Loading />;
    }

    const choooseManagerHint = !agenda.manager ? CHOOSE_MANAGER_HINT : undefined;

    return (
      <div>
        <Row className="margin-bottom-x-large">
          {this.state.showSelectableItems ? (
            <Column lg={6}>
              <div className="background-white border-radius-10
                section-create-agenda-padding padding-bottom-base"
              >
                <button
                  className="agenda-toggle-collapse-button pull-right padding-none"
                  onClick={this.toggleSelectableItems}
                >
                  <CollapseIcon />
                </button>
                <h4 className="font-size-18 font-weight-bold">
                  Select agenda items
                </h4>
                <p className="font-size-14 margin-vertical-xx-large">
                  Expand the dropdown menus and select the cards you want to add to the agenda by
                  clicking on the + sign next to each one.
                  Make your 1:1 agenda full screen and hide this section by clicking on the orange arrow.
                </p>
                <AgendaPerformanceReviewSelectItems
                  agendaId={this.props.agendaId}
                  items={performanceReviewResponseOptions}
                  isDisabled={isDisabled}
                  latestPerformanceReviewName={latestPerformanceReviewName}
                  latestPerformanceReviewComplete={latestPerformanceReviewComplete}
                  isAgendaEmployee={isAgendaEmployee}
                  performanceReviewTerm={performanceReviewTerm}
                />
                {peerFeedbackOptions && (
                  <AgendaPeerFeedbackSelect
                    agendaId={this.props.agendaId}
                    isDisabled={isDisabled}
                    items={peerFeedbackOptions}
                    isAgendaEmployee={isAgendaEmployee}
                  />
                )}
                <SelectableEngagementSurveyQuestions
                  agendaId={this.props.agendaId}
                  engagementScoresOptions={engagementScoresOptions}
                  isDisabled={isDisabled}
                  isAgendaEmployee={isAgendaEmployee}
                  latestEngagementSurveyName={latestEngagementSurveyName}
                  latestEngagementSurveyStartDate={latestEngagementSurveyStartDate}
                  latestEngagementSurveyIsAnonymous={latestEngagementSurveyIsAnonymous}
                  engagementQuestions={engagementQuestions}
                />
                <SelectableAgendaEmployeeGoals
                  agendaId={this.props.agendaId}
                  goalOptions={this.props.goalOptions}
                  isDisabled={isDisabled}
                />
                {okrOptions && (
                  <OkrSelectItems
                    agendaId={this.props.agendaId}
                    isDisabled={isDisabled}
                    items={okrOptions}
                  />
                )}
              </div>
            </Column>
          ) : (
            <Column lg={1}>
              <div className="agenda-left-side-collapsed">
                <button
                  className="agenda-toggle-collapse-button padding-none margin-bottom-xx-large"
                  onClick={this.toggleSelectableItems}
                >
                  <ExpandIcon />
                </button>
                <h4 className="agenda-left-side-collapsed-text font-size-24 font-weight-bold">Select agenda items</h4>
              </div>
            </Column>
          )}
          <Column lg={this.state.showSelectableItems ? 6 : 11}>
            <div className="border-radius-10 background-white overflow-hidden">
              <div className="section-create-agenda section-create-agenda-padding">
                <h4 className="font-size-18 font-weight-bold">
                  1:1 Agenda
                </h4>
                {canEditAgendaDetails && isEditingDetails ? (
                  <UpdateIndividualAgendaDetailsForm
                    onSubmit={this.props.updateAgenda}
                    initialValues={{
                      title: agenda.title,
                      description: agenda.description,
                      date: agenda.date && moment(agenda.date),
                      time: agenda.time,
                      manager: agenda.manager
                    }}
                    isDisabled={isDisabled}
                    readOnlyEmployee={agenda.employee}
                    cancel={this.props.cancelAgendaDetails}
                    isAgendaEmployee={isAgendaEmployee}
                  />
                ) : (
                  <AgendaDetails
                    agenda={agenda}
                    isDisabled={isDisabled}
                    editAgendaDetails={this.props.editAgendaDetails}
                    canEditAgendaDetails={canEditAgendaDetails}
                  />
                )}
              </div>
              {agenda.sections.map((sectionType, index) => (
                <AgendaSectionWrapper
                  isFirst={index === 0}
                  isLast={index === agenda.sections.length - 1}
                  agendaId={this.props.agendaId}
                  title={
                    sectionType === AgendaSectionType.PERFORMANCE_REVIEW
                    ? performanceReviewTerm
                    : AgendaSectionTypeTitleMap[sectionType]}
                  sectionType={sectionType}
                  position={index}
                  isDisabled={isDisabled}
                  key={`section-${index}`}
                  hideSection={hideAgendaSection(sectionType, agenda, okrOptions, peerFeedbackOptions)}
                >
                  {sectionType === AgendaSectionType.CUSTOM_ITEMS && (
                    <CustomItemsSection
                      agendaId={this.props.agendaId}
                      isDisabled={isDisabled}
                      itemsName="custom_items"
                    />
                  )}
                  {sectionType === AgendaSectionType.SHARED_NOTES && (
                    <CommentSection
                      agendaId={this.props.agendaId}
                      comments={agenda.shared_notes}
                      editCommentsName={EDIT_SHARED_NOTES}
                      isDisabled={isDisabled}
                      field="shared_notes"
                      initialValues={{
                        shared_notes: agenda.shared_notes
                      }}
                    />
                  )}
                  {sectionType === AgendaSectionType.PERFORMANCE_REVIEW && (
                    <AgendaPerformanceReviewSection agendaId={this.props.agendaId} isDisabled={isDisabled} />
                  )}
                  {sectionType === AgendaSectionType.PEER_FEEDBACK && (
                    <AgendaPeerFeedbackSection
                      agendaId={this.props.agendaId}
                      isDisabled={isDisabled}
                      selectedFeedback={agenda.peer_feedback}
                    />
                  )}
                  {sectionType === AgendaSectionType.ENGAGEMENT_SURVEY && (
                    <SelectedEngagementSurveyQuestions
                      agendaId={this.props.agendaId}
                      engagementScoresOptions={engagementScoresOptions}
                      isDisabled={isDisabled}
                      isAgendaEmployee={isAgendaEmployee}
                      latestEngagementSurveyName={latestEngagementSurveyName}
                    />
                  )}
                  {sectionType === AgendaSectionType.EMPLOYEE_GOALS && (
                    <SelectedAgendaEmployeeGoals
                      agendaId={this.props.agendaId}
                      goalOptions={this.props.goalOptions}
                      isDisabled={isDisabled}
                      employee={agenda.employee}
                    />
                  )}
                  {sectionType === AgendaSectionType.OKRS && (
                    <OkrSection
                      agendaId={this.props.agendaId}
                      isDisabled={isDisabled}
                      selectedOkrs={agenda.okrs}
                    />
                  )}
                </AgendaSectionWrapper>
              ))}
              {!isAgendaEmployee && (
                <AgendaSectionWrapper
                  title={AgendaSectionTypeTitleMap[AgendaSectionType.MANAGER_ONLY]}
                  sectionType={AgendaSectionType.MANAGER_ONLY}
                >
                  <AgendaManagerOnlySection
                    isDisabled={isDisabled}
                    agenda={agenda}
                    agendaId={this.props.agendaId}
                    talentSnapshotOptions={talentSnapshotOptions}
                  />
                </AgendaSectionWrapper>
              )}
              <div className="section-create-agenda">
                <div className="edit-agenda-btn-section background-gray-darker section-create-agenda-padding">
                  <button
                    className="btn middle orange"
                    onClick={() => window.location.href = '/agendas/list/'}
                    disabled={isDisabled}
                  >
                    Go back
                  </button>
                  {(agenda.status === AgendaStatus.PENDING && !isAgendaEmployee) &&
                    <button
                      className="save-btn"
                      onClick={this.props.openSaveAsDraftModal}
                      disabled={isDisabled || (
                        !agenda.manager || !agenda.date || !agenda.time
                      )}
                      title={choooseManagerHint}
                    >
                      Save & send invite
                    </button>
                  }
                  {agenda.status === AgendaStatus.ACCEPTED && (
                    <button
                      className="save-btn"
                      onClick={this.props.openResendModal}
                      disabled={isDisabled || !agenda.manager}
                      title={choooseManagerHint}
                    >
                      Send PDF agenda
                    </button>
                  )}
                  {(agenda.status === AgendaStatus.ACCEPTED && !isAgendaEmployee) &&
                    <button
                      className="save-btn"
                      onClick={this.props.openSaveAsReadonlyModal}
                      disabled={isDisabled || !agenda.manager}
                      title={choooseManagerHint}
                    >
                      Save as read only
                    </button>
                  }
                  {!isAgendaEmployee &&
                    <button
                      className="delete-btn"
                      onClick={this.props.openDeleteModal}
                      disabled={isDisabled}
                    >
                      Delete agenda
                    </button>
                  }
                </div>
              </div>
            </div>
          </Column>
        </Row>
        <ToastContainer />
        <ModalRenderer modals={this.props.modals}/>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const isAgendaEmployee = getIsAgendaEmployee(state);
  const agenda = state.editingAgenda;
  const pendingProcessFromTemplate = agenda && agenda.pending_process_from_template;

  return {
    isEditingDetails: state.editingAgendaItemIds.includes(EDIT_AGENDA_DETAILS),
    agenda: !pendingProcessFromTemplate ? agenda : null,
    pendingProcessFromTemplate,
    modals: state.modals,
    isLoadingAgenda: isPending(state.responses, GET_AGENDA),
    isDisabled: !props.isEditable || anyPending(state.responses, [
      GET_AGENDA, UPDATE_AGENDA, UPDATE_AGENDA_ITEM, CREATE_AGENDA_ITEM, DELETE_AGENDA_ITEM,
      ADD_GOAL_TO_AGENDA, REMOVE_GOAL_FROM_AGENDA,
      ADD_ENGAGEMENT_SURVEY_QUESTION_TO_AGENDA, REMOVE_ENGAGEMENT_SURVEY_QUESTION_FROM_AGENDA,
      CREATE_AGENDA_REVIEW_QUESTION, DELETE_AGENDA_REVIEW_QUESTION,
      UPDATE_AGENDA_TALENT_SNAPSHOT, UPDATE_AGENDA_SECTION_POSITION,
      CREATE_AGENDA_PEER_FEEDBACK, DELETE_AGENDA_PEER_FEEDBACK
    ]),
    isAgendaEmployee,
    canEditAgendaDetails: (agenda && agenda.status === AgendaStatus.PENDING) || !isAgendaEmployee,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getAgenda: () => dispatch(getAgenda(ownProps.agendaId)),
    editAgendaDetails: () => dispatch(agendaItemEditAction(EDIT_AGENDA_DETAILS)),
    cancelAgendaDetails: event => {
      event.preventDefault();
      dispatch(agendaItemCancelEdit(EDIT_AGENDA_DETAILS));
    },
    updateAgenda: data => {
      dispatch(updateAgendaAndToast(
        ownProps.agendaId,
        Object.assign({}, data, {
          date: data.date && data.date.format(FORMAT_DATE_BACKEND)
        }),
        EDIT_AGENDA_DETAILS
      ));
    },
    updateAgendaLastEdited: () => dispatch(updateAgendaLastEdited(ownProps.agendaId)),
    openEditWarningModal: () => {
      dispatch(
        openSimpleModal({
          body: <AgendaEditWarningModal />,
          title: 'Already being edited',
        })
      );
    },
    openSaveAsReadonlyModal: () => {
      dispatch(
        openSimpleModal({
          body: <AgendaConfirmSaveReadonlyModal agendaId={ownProps.agendaId} />,
          title: 'Save as read only',
        })
      );
    },
    openSaveAsDraftModal: () => {
      dispatch(
        openSimpleModal({
          body: <AgendaSaveAsDraftModal agendaId={ownProps.agendaId} />,
          title: 'Save as draft',
        })
      );
    },
    openDeleteModal: () => {
      dispatch(
        openSimpleModal({
          body: <DeleteAgendaModal agendaId={ownProps.agendaId} />,
          title: 'Delete agenda',
        })
      );
    },
    openResendModal: () => {
      dispatch(
        openSimpleModal({
          body: <AgendaResendModal agendaId={ownProps.agendaId} />,
          title: 'Send PDF agenda',
        })
      );
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAgenda);
