import Slik from 'slik';
import { animate } from 'react-slik';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Row, Column } from '@dabapps/roe';

import PureComponent from '^/components/PureComponent';
import SettingsUserList from '^/components/survey/SettingsUserList';
import SurveyAttributes from '^/components/survey/SurveyAttributes';
import { isPerformanceReviewTypeDisplay } from '^/consts/questionnaire';
import { fetchCustomTerms, getSurvey, closeSurvey, reopenSurvey } from '^/actions/actions';
import { sendSurveyAndRedirectToSurveys, nudgeSurveyAndDisplayToast } from '^/actions/actionSequences';
import Loading from '../Loading';

const animation = new Slik.Animation({
  delay: 1000,
  duration: 500,
  from: 1,
  to: 0
});

const animationOptions = {
  bind: 'all',
  startOnMount: false,
  stopOnUnmount: true
};

export class SurveySettings extends PureComponent {

  constructor(props) {
    super(props);
    this.closeSurvey = this.closeSurvey.bind(this);
    this.reopenSurvey = this.reopenSurvey.bind(this);
    this.nudgeSurvey = this.nudgeSurvey.bind(this);
    this.renderCloseOrReopenButton = this.renderCloseOrReopenButton.bind(this);
  }

  componentWillMount() {
    this.props.fetchCustomTerms();
    this.props.getSurvey(this.props.surveyId);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.isClosing && !nextProps.isClosing) ||
      (this.props.isReopening && !nextProps.isReopening) ||
      (this.props.wasSent !== nextProps.wasSent)
    ) {
      this.props.getSurvey(this.props.surveyId);
    }
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.wasSent && this.props.wasSent) {
      animation.start();
    }
  }

  closeSurvey() {
    this.props.closeSurvey(this.props.survey);
  }

  reopenSurvey() {
    this.props.reopenSurvey(this.props.survey);
  }

  sendSurvey() {
    const { survey, wasSent, isSending } = this.props;
    if (!wasSent && !isSending) {
      const redirectTo = isPerformanceReviewTypeDisplay(survey.get('type')) ? '/reviews/' : '/surveys/';
      this.props.sendSurveyAndRedirectToSurveys(survey, redirectTo);
    }
  }

  renderCloseOrReopenButton() {
    const state = this.props.survey.get('state');
    const disabled = state === 'EDIT';
    const surveyType = this.getSurveyType().toLowerCase();
    return state === 'CLOSED'
      ? <button
          disabled={disabled}
          onClick={this.reopenSurvey}
          className="btn orange middle margin-bottom-none">Reopen {surveyType}</button>
      : <button
          disabled={disabled}
          onClick={this.closeSurvey}
          className="btn orange middle margin-bottom-none">Close {surveyType}</button>;
  }

  getSurveyType() {
    return isPerformanceReviewTypeDisplay(this.props.survey.get('type')) ? 'Review' : 'Survey';
  }

  nudgeSurvey() {
    this.props.nudgeSurveyAndDisplayToast(this.props.survey);
  }

  render() {
    const {
      isSending,
      wasSent,
      isClosed,
      loading,
      message
    } = this.props;

    const surveyState = this.props.survey.get('state');
    const showSend = !loading && !isClosed;
    const surveyType = this.getSurveyType();

    let sendButtonText = `Send ${surveyType.toLowerCase()}`;


    if (!this.props.survey) {
      return <Loading/>;
    }

    if (this.props.survey.get('survey_starts_in_the_future')) {
      sendButtonText = `Schedule ${surveyType.toLowerCase()}`;
    }

    if (isSending) {
      sendButtonText = 'Sending...';
    }

    return (
      <div>
        <div className="will">
          <Row>
            <Column xs={6}>
              <h4 className="margin-bottom-none">{surveyType} Details</h4>
            </Column>
            <Column xs={6}>
              <div className="pull-right">
                <div>
                  {
                    showSend && (
                      <button
                        onClick={() => this.sendSurvey()}
                        style={{
                          opacity: 1
                        }}
                        className={`btn middle margin-right margin-bottom-none ${wasSent ? 'green' : 'orange'}`}
                      >
                        {sendButtonText}
                      </button>
                    )
                  }
                  <button
                    disabled={surveyState === 'EDIT'}
                    onClick={this.nudgeSurvey}
                    className="btn orange middle margin-right margin-bottom-none">Remind</button>
                  {this.renderCloseOrReopenButton()}
                </div>
              </div>
            </Column>
          </Row>
          {message && (
            <div className="margin-top-base">
              <p className="alert alert-info">{message}</p>
            </div>
          )}
          <SurveyAttributes
            surveyName={this.props.surveyName}
            survey={this.props.survey}
            canHaveCustomEmails={this.props.canHaveCustomEmails}
            emailHelpLink={this.props.emailHelpLink}
          />
          <SettingsUserList
            surveyId={this.props.surveyId}
            customTerms={this.props.customTerms}
            isClosed={isClosed}
            filterOptions={this.props.filterOptions}
            canIncludeRecentResponses={this.props.canIncludeRecentResponses}
            showHelpText={false}
            organisationFilterTerms={this.props.organisationFilterTerms}
          />
        </div>
      </div>
    );
  }
}

export function mapStateToProps({ loggedInUser, organisation, survey }) {
  return {
    customTerms: organisation.get('customTerms', Map()),
    survey: survey.get('survey', Map()),
    isClosing: survey.get('isClosing', false),
    isReopening: survey.get('isReopening', false),
    isSending: survey.get('isSending', false),
    wasSent: survey.get('wasSent', false),
    isClosed: survey.getIn(['survey', 'state']) === 'CLOSED',
    loading: survey.get('isLoading'),
    message: survey.get('message', null),
    loggedInUser,
  };
}

const ConnnectedSurveySettings = connect(
  mapStateToProps,
  { fetchCustomTerms, getSurvey, closeSurvey, reopenSurvey, nudgeSurveyAndDisplayToast, sendSurveyAndRedirectToSurveys }
)(SurveySettings);

export default animate(ConnnectedSurveySettings, {sendButtonOpacity: animation}, animationOptions);
