import React from 'react';
import Select from 'react-select';
import { getUserOptions, getTeamMemberOptions } from '^/components/common/actions';
import _ from 'underscore';
import { connect } from 'react-redux';
import { selectRemoteUser } from './actions';

const LOAD_OPTIONS_DEBOUNCE_MS = 500;

export class RemoteUserSelect extends React.PureComponent {

  constructor(props) {
    super(props);
    this.filterOption = this.filterOption.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchUsersDebounced = _.debounce(this.fetchUsers, LOAD_OPTIONS_DEBOUNCE_MS);

    this.onInputKeyDown = event => {
      event.persist();
      return this.fetchUsersDebounced(event.target.value);
    };
  }

  componentDidUpdate(previousProps) {
    if (
      Object.entries(previousProps.optionFilters || {}).toString() !==
      Object.entries(this.props.optionFilters || {}).toString()
    ) {
      this.fetchUsers('');
    }
  }

  fetchUsers(searchString) {
    const { isTeamMember, optionFilters, selectId, } = this.props;

    if (isTeamMember) {
      this.props.getTeamMemberOptions(selectId, optionFilters, searchString);
    } else {
      this.props.getUserOptions(selectId, optionFilters, searchString);
    }
  }

  componentDidMount() {
    this.props.selectRemoteUser(this.props.selectId, this.props.initialValue);
    this.fetchUsers('');
  }

  onChange(selectedUser) {
    this.props.selectRemoteUser(this.props.selectId, selectedUser);
    this.props.onChange(selectedUser);
  }

  filterOption() {
    return true;
  }

  render() {
    return (
      <Select
        placeholder={this.props.placeholder || 'Type to search...'}
        noResultsText={this.props.noResultsText || 'No Results found. Type to search...'}
        {...this.props}
        onInputKeyDown={this.onInputKeyDown}
        onChange={this.onChange}
        clearable={false}
        onBlur={_.noop}
        isMulti={this.props.multi}
        filterOption={this.filterOption}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const selectedUser = state.selectedRemoteUser.get(ownProps.selectId, {});

  return {
    value: selectedUser ? selectedUser : null,
    options: state.remoteUsers.get(ownProps.selectId)
  };
}

export default connect(mapStateToProps, {
  getUserOptions,
  getTeamMemberOptions,
  selectRemoteUser
})(RemoteUserSelect);
