import { fromJS } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { getReportSuggestions } from '../actions';
import Recommendations from './Recommendations';
import Loading from '^/components/Loading';
import {
  getPollReportTimoutDuration,
  THREE_SECONDS,
  POLL_MAX_RETRIES
} from '^/utils';

export class ReportSuggestions extends React.PureComponent {

  constructor(props) {
    super(props);
    this.pollSuggestionsData = this.pollSuggestionsData.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.state = {
      suggestionsDataFailed: false
    };
  }

  componentDidMount() {
    if (this.props.suggestions.scores_cache_pending) {
      this.pollSuggestionsData();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.suggestions.scores_cache_pending && !this.props.suggestions.scores_cache_pending) {
      clearTimeout(this.timeout);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  pollSuggestionsData(tryCount = 0, timeoutSeconds = THREE_SECONDS) {
    const timeoutWithBackoff = getPollReportTimoutDuration(tryCount, timeoutSeconds);

    if (tryCount === POLL_MAX_RETRIES) {
      this.setState({suggestionsDataFailed: true});
    } else {
      this.timeout = setTimeout(
        () => {
          this.props.pollSuggestions();
          this.pollSuggestionsData(tryCount + 1, timeoutWithBackoff);
        },
        timeoutWithBackoff
      );
    }
  }

  renderContent(suggestions) {
    return (
      <div>
        <div className="margin-top-large will padding-none padding-vertical-base">
          <h3 className="analysis-suggestions-header">
            {this.props.isIndividual ?
              'YOUR COACHING RECOMMENDATIONS'
              :
              'PRODUCTIVITY RECOMMENDATIONS FOR YOUR TEAM'
            }
          </h3>
          {suggestions ? (
            <Recommendations
              recommendations={suggestions.get('causes')}
              suggestions={suggestions}
              isIndividual={this.props.isIndividual}
            />
          ) : (
            <Loading className="margin-bottom-base" />
          )}
        </div>
        <div className="margin-top-large will padding-none padding-vertical-base">
          <h3 className="analysis-suggestions-header">
            {this.props.isIndividual ?
              'YOUR WELLBEING RECOMMENDATIONS'
              :
              'WELLBEING RECOMMENDATIONS FOR YOUR TEAM'
            }
          </h3>
          {suggestions ? (
            <Recommendations
              recommendations={suggestions.get('effects')}
              suggestions={suggestions}
              isIndividual={this.props.isIndividual}
            />
          ) : (
            <Loading className="margin-bottom-base" />
          )}
        </div>
        {suggestions && <ToastContainer />}
      </div>
    );
  }

  render() {
    if (this.props.suggestions.scores_cache_pending) {
      return this.renderContent();
    }

    if (this.state.suggestionsDataFailed) {
      return (
        <div className="margin-top-large will padding-xx-large">
          <p className="margin-none">
            Your recommendations are taking longer than usual.{' '}
            Please try again shortly and if this problem persists contact support.
          </p>
        </div>
      );
    }

    const suggestions = fromJS(this.props.suggestions);

    return this.renderContent(suggestions);
  }
}

function mapStateToProps(state, props) {
  return {
    suggestions: state.reportSuggestions || props.suggestions,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    pollSuggestions: () => dispatch(getReportSuggestions(props.reportId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSuggestions);
