import { hasFailed, isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';

import { GET_USER_PERFORMANCE_REVIEW_SURVEYS, getUserPerformanceReviewSurveys } from '^/components/dashboards/actions';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';

const PerformanceReviewSurveySection = (props) => {
  const {
    title,
    name,
    date,
    actionText,
    href
  } = props;

  return (
    <div>
      <h4 className="font-family-varela-round font-size-18 font-weight-normal margin-bottom-base">{ title }</h4>
      <div className="margin-bottom-large widget-item-hollow">
        <p className="margin-bottom-small font-size-14 font-weight-bold">{ name }</p>
        <div className="flex-space-between align-items-center font-size-13">
          <span className="text-gray display-inline-block">{date}</span>
          <a href={href} className="view-details-link">
            { actionText } {' '}
            <i className="far fa-arrow-right arrow text-pink"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export class PerformanceReviewSurveysWidget extends React.PureComponent {
  componentDidMount() {
    this.props.getUserPerformanceReviewSurveys(this.props.isManagerDashboard);
  }

  getUpcomingLink(upcomingSurvey) {
    const { isManagerDashboard } = this.props;

    if (!isManagerDashboard) {
      return `${upcomingSurvey.questionnaire_url}?is_my_dashboard=true`;
    }

    return `/reviews/${upcomingSurvey.survey_id}/status/`;
  }

  getCompletedLink(completedSurvey) {
    return this.props.isManagerDashboard ?
    `/reviews/${completedSurvey.survey_id}/status/` :
    `${completedSurvey.report_url}?is_my_dashboard=true`;
  }

  renderContent() {
    const { isManagerDashboard, isLoading, hasFailedLoading, surveys } = this.props;

    const noCompletedPerformanceSurveyMessage = isManagerDashboard ?
      'Your team have not completed a performance review survey yet.' :
      'You have not completed a performance review survey yet.';

    const noUpcomingPerformanceSurveyMessage = isManagerDashboard ?
      'Your team have no upcoming performance review surveys.' :
      'You have no upcoming performance review surveys.';

    if (isLoading) {
      return <Loading />;
    }

    if (hasFailedLoading) {
      return <p>Failed to load performance review surveys.</p>;
    }

    return (
      <div>
        {surveys.upcoming ? <PerformanceReviewSurveySection
          title="Upcoming"
          name={surveys.upcoming.name}
          date={surveys.upcoming.date}
          actionText={isManagerDashboard ? 'View results' : 'Complete now'}
          href={this.getUpcomingLink(surveys.upcoming)}
        /> : (
          <div className="margin-top-large">
            <h4 className="font-family-varela-round font-size-18 font-weight-normal margin-bottom-base">Upcoming</h4>
            <p>{ noUpcomingPerformanceSurveyMessage }</p>
          </div>
        )}

        {surveys.completed ? <PerformanceReviewSurveySection
          title="Completed"
          name={surveys.completed.name}
          date={surveys.completed.date}
          actionText="View results"
          href={this.getCompletedLink(surveys.completed)}
        /> : (
          <div className="margin-top-large">
            <h4 className="font-family-varela-round font-size-18 font-weight-normal margin-bottom-base">Completed</h4>
            <p>{ noCompletedPerformanceSurveyMessage }</p>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="Performance review surveys" image="/static/images/dashboards/reviews.svg" />
        {this.renderContent()}
        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href={this.props.isManagerDashboard ? '/reviews' : `/reviews/personal-reviews/${this.props.userId}/`}
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    userId: state.loggedInUser.get('id'),
    isLoading: isPending(state.responses, GET_USER_PERFORMANCE_REVIEW_SURVEYS),
    hasFailedLoading: hasFailed(state.responses, GET_USER_PERFORMANCE_REVIEW_SURVEYS),
    surveys: state.homepageUserPerformanceReviewSurveys.toJS(),
  };
}

export default connect(mapStateToProps, { getUserPerformanceReviewSurveys })(PerformanceReviewSurveysWidget);
