import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { connect } from 'react-redux';
import { closeModal } from '^/actions/modals';
import { deleteGoalThenRedirectToGoalsList } from './actions';

export const DeleteGoalModal = (props) => (
  <div>
    <ModalBody>
      <p>
        Do you want to delete this goal? You will delete all associated actions
        as a result.
      </p>
    </ModalBody>
    <ModalFooter>
      <SpacedGroup block className="margin-vertical-base">
        <Button className="btn gray middle" onClick={props.closeModal}>
          Cancel
        </Button>
        <Button className="btn danger middle" onClick={props.deleteGoal}>
          Delete
        </Button>
      </SpacedGroup>
    </ModalFooter>
  </div>
);

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    deleteGoal: () => {
      dispatch(deleteGoalThenRedirectToGoalsList(ownProps.goalId));
    },
  };
}

export default connect(null, mapDispatchToProps)(DeleteGoalModal);
