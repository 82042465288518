import { Map, fromJS } from 'immutable';
import _ from 'underscore';
import composeReducers from '^/reducers/composeReducers';
import combineImmutableReducers from '^/combineImmutableReducers';
import * as actions from '^/actions/actions';
import * as collections from '^/actions/collections';
import * as items from '^/actions/items';
import * as responseStates from '^/consts/responseStates';

function makeResponseReducer(actionSet, keyed, responseName) {
  return (state = Map(), action) => {
    const source = (action.meta && action.meta.source) || 'default';
    switch (action.type) {
      case actionSet.REQUEST:
        const pendingResponse = Map({ state: responseStates.PENDING });
        return keyed ? state.set(source, pendingResponse) : pendingResponse;
      case actionSet.SUCCESS:
        const successResponse = Map({ state: responseStates.SUCCESSFUL, payload: fromJS(action.payload) });
        return keyed ? state.set(source, successResponse) : successResponse;
      case actionSet.FAILURE:
        const failureResponse = Map({
          state: responseStates.FAILED,
          errors: action.error ? fromJS(action.payload.response) : null
        });
        return keyed ? state.set(source, failureResponse) : failureResponse;
      case actions.RESET_RESPONSE_ERROR_FOR_KEY:
        const { responseKey, key } = action.payload;
        if (responseName === responseKey) {
          return keyed ? state.setIn([source, 'errors', key]) : state.setIn(['errors', key]);
        }
        return state;
      default:
        return state;
    }
  };
}

function makeResponsesReducer(actionsByKey) {
  return combineImmutableReducers(
    _.mapObject(actionsByKey,
      (actionType, responseName) =>
        actionType.actionSet instanceof Array
          ? composeReducers(actionType.actionSet.map(makeResponseReducer), Map())
          : makeResponseReducer(actionType.actionSet, actionType.keyed, responseName)
    )
  );
}

const responsesReducer = makeResponsesReducer({
  getCollection: {actionSet: collections.GET_COLLECTION, keyed: true},
  addToCollection: {actionSet: collections.ADD_TO_COLLECTION, keyed: true},
  loadItem: {actionSet: items.LOAD_ITEM, keyed: true},
  updateItem: {actionSet: items.UPDATE_ITEM, keyed: true},
  createItem: {actionSet: items.CREATE_ITEM, keyed: true},
  getItemOptions: {actionSet: items.GET_ITEM_OPTIONS, keyed: true},
  deleteItem: {actionSet: collections.DELETE_FROM_COLLECTION, keyed: true},
  selectAllMembers: {actionSet: actions.SURVEY_SELECT_ALL_MEMBERS},
  removeAllMembers: {actionSet: actions.SURVEY_REMOVE_ALL_MEMBERS},
  getOrganisationEmailTemplates: {actionSet: actions.GET_ORGANISATION_EMAIL_TEMPLATES},
  updateOrganisationEmailTemplate: {actionSet: actions.UPDATE_ORGANISATION_EMAIL_TEMPLATE, keyed: true},
  getSurveyEmailTemplate: {actionSet: actions.GET_SURVEY_EMAIL_TEMPLATE, keyed:true },
  updateSurveyEmailTemplate: {actionSet: actions.UPDATE_SURVEY_EMAIL_TEMPLATE, keyed:true },
  createCustomQuestion: {actionSet: actions.CREATE_CUSTOM_QUESTION},
  updateCustomQuestionSettings: {actionSet: actions.UPDATE_CUSTOM_QUESTION_SETTINGS},
  getCustomQuestionSettings: {actionSet: actions.GET_CUSTOM_QUESTION_SETTINGS},
  getManagerOptions: {actionSet: actions.GET_MANAGER_OPTIONS},
  assignManager: {actionSet: actions.ASSIGN_MANAGER},
  removeManager: {actionSet: actions.REMOVE_MANAGER},
});

function responses(state = Map(), action) {
  const updatedState = responsesReducer(state, action);

  if (action.type === actions.RESET_RESPONSE) {
    return Map(updatedState).delete(action.payload);
  }
  else if (action.type === actions.CLOSE_MODAL) {
    return Map();
  }
  return updatedState;
}

export default responses;
