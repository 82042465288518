export function updateOkrById(tree, id, newNode) {
  function traverse(node) {
    if (!node || !node.child_okrs) {
      return;
    }

    for (let i = 0; i < node.child_okrs.length; i++) {
      const child = node.child_okrs[i];

      if (child.id === id) {
        node.child_okrs[i] = Object.assign({}, node.child_okrs[i], newNode);
        return true;
      }

      if (traverse(child)) {
        return true;
      }
    }

    return false;
  }

  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === id) {
      tree[i] = Object.assign({}, tree[i], newNode);;
      return tree;
    }

    if (traverse(tree[i])) {
      return tree;
    }
  }
}

function removeNode(node, okrId, tree, companyGoalIndex, preserveChildOkrsCallback) {
  if (node.id === okrId) {
    tree.splice(companyGoalIndex, 1);
    return true;
  }

  if (!node.child_okrs) {
    return;
  }

  for (let i = 0; i < node.child_okrs.length; i++) {
    const child = node.child_okrs[i];

    if (child.id === okrId) {
      node.child_okrs.splice(i, 1);
      if (preserveChildOkrsCallback) {
        preserveChildOkrsCallback(child.child_okrs);
      }
      return true;
    }

    if (removeNode(child, okrId)) {
      return true;
    }
  }

  return false;
}

export function moveNode(tree, updatedNode) {

  let preservedChildOkrs = null;

  const preserveChildOkrsCallback = childOkrs => {
    preservedChildOkrs = childOkrs;
  };

  function addNode(node) {
    if (!node || !node.child_okrs) {
      return;
    }

    if (node.id === updatedNode.parent_okr) {
      if (preservedChildOkrs) {
        node.child_okrs.push(Object.assign({}, updatedNode, { child_okrs: preservedChildOkrs }));
      } else {
        node.child_okrs.push(updatedNode);
      }
      return true;
    }

    for (const child of node.child_okrs) {
      if (addNode(child)) {
        return true;
      }
    }

    return false;
  }

  // Remove the node from its current position in the tree
  tree.some((node) => removeNode(node, updatedNode.id, undefined, undefined, preserveChildOkrsCallback));

  // Add the node under its new parent node
  tree.some((node) => addNode(node));

  return tree;
}

export function removeOkr(tree, okrId) {
  tree.some((node, index) => removeNode(node, okrId, tree, index));
  return tree;
}

export function addNodeToTree(tree, newNode) {
  function findParentAndAddNode(node) {
    if (!node || !node.child_okrs) {
      return;
    }

    if (node.id === newNode.parent_okr) {
      node.child_okrs.push(newNode);
      return true;
    }

    for (const child of node.child_okrs) {
      if (findParentAndAddNode(child)) {
        return true;
      }
    }

    return false;
  }

  // Add the new node under its parent node
  tree.some((node) => findParentAndAddNode(node));

  return tree;
}

function getUpdatedOkrOrOriginal(orginalOkr, updatedOkrsData) {
  const updatedOkrData = updatedOkrsData.find(
    updatedOkr => updatedOkr.id === orginalOkr.id
  );
  if (updatedOkrData) {
    return Object.assign({}, orginalOkr, updatedOkrData);
  }
  return orginalOkr;
}

export function updateParentOkrsPercentageComplete(state, updatedOkrsData) {
  return state.map(companyGoal => {
    const updatedChildOkrs = companyGoal.child_okrs.map(
      childOkr => getUpdatedOkrOrOriginal(childOkr, updatedOkrsData)
    );
    const companyGoalWithUpdatedChildOkrs = Object.assign({}, companyGoal, {child_okrs: updatedChildOkrs});
    return getUpdatedOkrOrOriginal(companyGoalWithUpdatedChildOkrs, updatedOkrsData);
  });
}
