import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';

import Loading from '^/components/Loading';
import {
  updateSelectedBenchmarks,
  getAvailableBenchmarks,
  GET_AVAILABLE_BENCHMARKS
} from '^/components/custom-reports/actions';
import { selectableBenchmarks } from '^/components/custom-reports/constants';
import CustomReportCheckbox from './CustomReportCheckbox';

export class CustomReportBenchmarkSelector extends React.PureComponent {

  componentDidMount() {
    if (!Object.keys(this.props.availableBenchmarks).length) {
      this.props.getAvailableBenchmarks();
    }
  }

  render() {
    const availableBenchmarks = selectableBenchmarks.filter(benchmark =>
      (this.props.availableBenchmarks[benchmark.id] || []).includes(this.props.selectedSurveyType)
    );

    if (this.props.isLoadingAvailableBenchmarks) {
      return (
        <div className="margin-top-xx-large">
          <Loading />
        </div>
      );
    }

    return (
      <div className="display-flex align-items-center margin-top-xx-large line-height-32px">
        <div className="width-150px">
          <div className="benchmark-type-info">
            <span className="font-size-14 font-weight-bold">Benchmarks:</span>
            <sup>
              <i className="fas fa-info-circle text-pink font-size-14"></i>
            </sup>
            <div className="line-height-18px">
              Compare your results to others if enough benchmark data is available.
            </div>
          </div>
        </div>
        <div className="display-flex flex-wrap">
          {availableBenchmarks.length ? selectableBenchmarks.map((benchmark) => (
            <CustomReportCheckbox
              key={benchmark.id}
              id={benchmark.id}
              value={benchmark.id}
              checked={this.props.selectedBenchmarksOptions.includes(benchmark.id)}
              onChange={this.props.updateSelectedBenchmarks}
              labelText={benchmark.name}
              disabled={!availableBenchmarks.includes(benchmark) || this.props.isDisabled}
            />
          )) : <p>No benchmarks available for this survey type.</p>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedBenchmarksOptions: state.selectedBenchmarkingOptions[state.selectedSurveyType],
  availableBenchmarks: state.availableBenchmarks,
  selectedSurveyType: state.selectedSurveyType,
  isLoadingAvailableBenchmarks: isPending(state.responses, GET_AVAILABLE_BENCHMARKS)
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedBenchmarksFunction: (selectedSurveyType) =>
    (event) => dispatch(updateSelectedBenchmarks(selectedSurveyType, event.target.value)),
  getAvailableBenchmarks: () => dispatch(getAvailableBenchmarks())
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    updateSelectedBenchmarks: dispatchProps.updateSelectedBenchmarksFunction(
      stateProps.selectedSurveyType
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomReportBenchmarkSelector);
