import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { AdminList } from '@dabapps/chadmin';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';
import {
  addInSurveyAndUpdate,
  removeFromSurveyAndUpdate,
} from '^/actions/actionSequences';
import { getCollectionFromUrl } from '^/actions/collections';
import { setPageSize, surveySelectAll, surveyRemoveAll } from '^/actions/actions';
import AdminListPagination from '^/components/admin/AdminListPagination';
import { fetchFieldConfig, setFilter, setFilterBulk } from '^/actions/actions';
import AdminListFilter from '../../admin/AdminListFilter';
import { ALL } from '^/consts/forms';
import { DEFAULT_PAGE_SIZE } from '^/consts/inputs';
import { Column, Row, SpacedGroup } from '@dabapps/roe';
import Loading from '^/components/Loading';
import DateRangeFilter from '^/components/common/DateRangeFilter';
import moment from 'moment/moment';
import PageSize from '^/components/common/PageSize';
import { FORMAT_DATE } from '../../../utils';

export const SUPPORT_ACTION_LIST = 'SUPPORT_ACTION_LIST';

const CONFIG_NAME = 'support/actions';

export class ActionList extends PureComponent {

  constructor() {
    super();
    this.loadPage = this.loadPage.bind(this);
  }

  getColumns() {
    return fromJS([
      {
        name: 'organisation',
        display_name: 'Organisation',
        type: 'text',
        sortable: true
      },
      {
        name: 'survey',
        display_name: 'Survey',
        type: 'text',
        sortable: true
      },
      {
        name: 'title',
        display_name: 'Title',
        type: 'text',
        sortable: true
      },
      {
        name: 'area',
        display_name: 'Area',
        type: 'text',
        sortable: true
      },
      {
        name: 'owner',
        display_name: 'Owner',
        type: 'text',
        sortable: true
      },
      {
        name: 'created_date',
        display_name: 'Created Date',
        type: 'custom',
        customItemHandler: survey => survey.created_date ? moment(survey.created_date).format(FORMAT_DATE) : null,
        sortable: true
      },
      {
        name: 'status',
        display_name: 'Status',
        type: 'text',
        sortable: true
      },
    ]);
  }

  getFilters(overrideFilterSpec) {
    const filterSpec = overrideFilterSpec || this.props.filterSpec;
    return filterSpec.map((filterValue) => {
      const filter = filterValue !== ALL ? filterValue : null;
      return (filter instanceof moment) ? filter.format('YYYY-MM-DD') : filter;
    }).toJS();
  }

  loadCollection(pageSize) {
    this.props.getCollectionFromUrl(
      `/support/actions/`,
      {pageSize: pageSize || this.props.pageSize, filters: this.getFilters()},
      SUPPORT_ACTION_LIST
    );
  }

  componentWillReceiveProps(newProps) {
    if (newProps.filterSpec !== this.props.filterSpec) {
      this.loadPage(this.page, newProps.filterSpec);
    }
  }

  componentWillMount() {
    this.props.setFilterBulk(SUPPORT_ACTION_LIST, this.props.queryParams.toJS());

    if (this.props.queryParams.isEmpty()) {
      this.loadCollection();
    }

    this.props.fetchFieldConfig(CONFIG_NAME);
  }

  loadPage(page, overrideFilterSpec = null) {
    this.props.getCollectionFromUrl(
      `/support/actions/`,
      {page, pageSize: this.props.pageSize, filters: this.getFilters(overrideFilterSpec)},
      SUPPORT_ACTION_LIST
    );
  }

  setPageSize(pageSize) {
    this.props.setPageSize(SUPPORT_ACTION_LIST, pageSize);
    this.loadCollection(pageSize);
  }

  filterCollection(filterKey, filterValue) {
    this.props.setFilter(SUPPORT_ACTION_LIST, filterKey, filterValue);
  }

  render() {
    if (!this.props.filters) {
      return <Loading />;
    }

    const filters = this.props.filters;
    const columns = this.getColumns();

    return (
      <div className="admin-container-content">
        <div className="admin-list-container">

          <Row className="admin-list-controls">
            <Column className="admin-list-survey-date-filter" xs={4}>
              <DateRangeFilter
                filterName="Created Date"
                fromKey="created_date__gte"
                toKey="created_date__lte"
                fromValue={this.props.filterSpec.get('created_date__gte')}
                toValue={this.props.filterSpec.get('created_date__lte')}
                onChange={this.filterCollection.bind(this)}
              />
            </Column>
            <Column className="survey-team-member-filters" xs={8}>
              <SpacedGroup className="float-right">
                {filters.map(filter => (
                  <AdminListFilter
                    key={filter.get('filter_key') + '_filter'}
                    onChange={(filterKey, filterValue) => this.filterCollection(filterKey, filterValue)}
                    keyName={filter.get('filter_key') + '_filter'}
                    filter={filter} />
                ))}
              </SpacedGroup>
            </Column>
          </Row>

          <Row className="admin-list-controls">
            <Column>
              <SpacedGroup className="float-right">
                <div className="stm-filter">
                  <PageSize
                    pluralItemName="Actions"
                    pageSize={this.props.pageSize}
                    onChange={(newPageSize) => this.setPageSize(newPageSize)}
                  />
                </div>
              </SpacedGroup>
            </Column>
          </Row>

          <AdminList
            items={this.props.organisations.toJS()}
            columns={columns.toJS()}
            listName={SUPPORT_ACTION_LIST}
            itemCount={this.props.organisationCount}
            filters={this.props.filters}
          />
          <AdminListPagination
            collection={this.props.organisationCollection}
            loadPage={this.loadPage}
            pageSize={this.props.pageSize}/>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    organisationCollection: state.collections.get(SUPPORT_ACTION_LIST, Map()),
    organisations: state.collections.getIn([SUPPORT_ACTION_LIST, 'items'], List()),
    organisationCount: state.collections.getIn([SUPPORT_ACTION_LIST, 'count'], 0),
    pageSize: state.ui.getIn(['pageSize', SUPPORT_ACTION_LIST]) || DEFAULT_PAGE_SIZE,
    filters: state.fieldConfigs.getIn([CONFIG_NAME, 'filters'], List()),
    filterSpec: state.filterSpec.get(SUPPORT_ACTION_LIST, Map()),
  };
}

export default connect(
  mapStateToProps,
  {
    getCollectionFromUrl,
    addInSurveyAndUpdate,
    removeFromSurveyAndUpdate,
    setPageSize,
    surveySelectAll,
    surveyRemoveAll,
    fetchFieldConfig,
    setFilter,
    setFilterBulk
  }
)(ActionList);
