import React from 'react';  // eslint-disable-line no-unused-vars
import classNames from 'classnames';

import { OKR_TYPE, OKR_TYPE_DISPLAY } from '../okrs/constants';
import { OkrStatus } from '../okrs/OkrStatus';

export default function AgendaItemCard({
  onClick,
  content,
  score,
  title,
  isRemoving = false,
  isDisabled,
  isOkr = false,
  okrData,
}) {
  return (
    <div className="agenda-items-card margin-bottom-base padding-horizontal-base">
      <div className="agenda-items-card-container margin-vertical-large margin-auto-horizontal">
        <div className="agenda-items-card-header margin-bottom-base display-flex align-items-center">
          <button
            className="btn-icon orange flex-shrink-0 margin-right-large"
            onClick={onClick}
            disabled={isDisabled}
          >
            <i className={`far fa-${ !isRemoving ? 'plus' : 'minus'}`}></i>
          </button>
          {!isOkr && <p className="font-size-base bold margin-none flex-grow font-size-14">{title}</p>}
          {score && (
            <span className="agenda-items-card-score padding-left-base flex-shrink-0">
              {score}
            </span>
          )}
          {isOkr && (
            <div className="agenda-okr-title-type">
              <p className="font-size-base bold margin-none flex-grow font-size-14">{title}</p>
              <span className="agenda-items-card-score flex-shrink-0">
                <i className={
                  classNames(
                    'far font-size-18 margin-horizontal-base color-gray-dark',
                    {
                      'fa-globe': okrData.okr_type === OKR_TYPE.COMPANY,
                      'fa-building': okrData.okr_type === OKR_TYPE.MANAGER,
                      'fa-user': okrData.okr_type === OKR_TYPE.EMPLOYEE
                    }
                  )
                }></i>
                {OKR_TYPE_DISPLAY[okrData.okr_type]} OKR
              </span>
            </div>
          )}
        </div>
        {isOkr && (
          <div className="agenda-items-card-content">
            <OkrStatus status={okrData.okr_status} completedPercentage={okrData.okr_completed_percentage} />
          </div>
        )}
        {content && (Array.isArray(content) ? (
            <div className="agenda-items-card-content">
              {content.map((item, index) => {
                return (
                  <p key={index} className="margin-bottom-none">
                    {item}
                  </p>
                );
              })}
            </div>
          ) : (
            <div className="agenda-items-card-content">
              <p className="margin-bottom-none">{content}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
}
