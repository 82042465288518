import * as yup from 'yup';

const getTemplateJson = (elementId, schema) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return null;
  }
  const json = JSON.parse(element.textContent || '');
  return schema.validateSync(json);
};

const CURRENT_USER_SCHEMA = yup
  .object()
  .nullable()
  .shape({
    role: yup.string(),
    id: yup.number(),
    is_staff: yup.boolean(),
    is_org_owner: yup.boolean(),
    first_name: yup.string(),
    last_name: yup.string(),
  });

export const CURRENT_USER = getTemplateJson(
  'current_user',
  CURRENT_USER_SCHEMA
);

const BULK_AGENDA_SELECTED_IDS_SCHEMA = yup.array().of(yup.number());

export const BULK_AGENDA_SELECTED_IDS = getTemplateJson(
  'bulk_agenda_selected_user_ids',
  BULK_AGENDA_SELECTED_IDS_SCHEMA
);
