import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { removeEngagementSurveyQuestionFromAgendaAndToast } from './actions';
import AgendaItemCard from './AgendaItemCard';
import { ScoreType } from './constants';
import CustomItemsSection from './CustomItemsSection';
import { isReadOnlyAgendaItem } from './utils';

function SelectedEngagementSurveyQuestionCard({
  option,
  removeEngagementSurveyQuestionFromAgenda,
  isDisabled
}) {
  return (
    <AgendaItemCard
      title={option.question_text}
      score={(option.score || option.score === 0) && (
        `${option.score_type === ScoreType.TEAM ? 'Team - ' : ''}${option.score}%`
      )}
      onClick={removeEngagementSurveyQuestionFromAgenda}
      isDisabled={isDisabled}
      isRemoving
    />
  );
}

const mapStateToPropsCard = (state, props) => {
  return {
    isDisabled: props.isDisabled || isReadOnlyAgendaItem(state, props.option.created_by)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeEngagementSurveyQuestionFromAgenda: () => dispatch(removeEngagementSurveyQuestionFromAgendaAndToast(
      ownProps.agendaId,
      ownProps.option.id
    ))
  };
};

const ConnectedSelectedEngagementSurveyQuestionCard = connect(
  mapStateToPropsCard,
  mapDispatchToProps
)(SelectedEngagementSurveyQuestionCard);

export function SelectedEngagementSurveyQuestions({
  selectedEnagagementOptions,
  agendaId,
  isDisabled,
  isAgendaEmployee
}) {
  return (
    <div>
      {selectedEnagagementOptions.length ? (
        selectedEnagagementOptions.map((option) => (
          <ConnectedSelectedEngagementSurveyQuestionCard
            key={`${option.question_sub_area_id}-${option.score}-${option.score_type}`}
            agendaId={agendaId}
            option={option}
            isDisabled={isDisabled}
            isAgendaEmployee={isAgendaEmployee}
          />
        ))
      ) : (
        <p>No options selected.</p>
      )}
      <div className="margin-top-xx-large">
        <h4 className="font-weight-bold margin-bottom-base font-size-14">
          Comments
        </h4>
        <CustomItemsSection
          agendaId={agendaId}
          isDisabled={isDisabled}
          itemsName="engagement_survey_comments"
          isCommentItems
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedEnagagementOptions: state.editingAgenda.engagement_survey_questions
  };
};

export default connect(mapStateToProps)(SelectedEngagementSurveyQuestions);
