import { anyPending, isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { TEMPLATE_TYPE_DISPLAY_MAP, AgendaSectionType, AgendaSectionTypeTitleMap } from './constants';
import {
  sendBulkAgendaAndRedirect,
  cancelBulkAgendaAndRedirectToCreate,
  SEND_BULK_AGENDA,
  getAgendaTemplate,
  GET_AGENDA_TEMPLATE,
  CANCEL_BULK_AGENDA
} from './actions';
import BulkAgendaCancelButton from './BulkAgendaCancelButton';
import { hasAgendaSection } from '^/components/agendas/EditAgendaTemplate';
import Loading from '^/components/Loading';
import AgendaPreviewSection from './AgendaPreviewSection';

const grayContainerClassNames = [
  'border-radius-base',
  'background-gray-lightest',
  'padding-xx-large',
  'margin-bottom-large'
];

const effraClassNames = [
  'font-family-effra-bold',
  'font-size-18',
  'margin-top-base'
];

const varelaClassNames = [
  'font-family-varela-round',
  'font-size-16',
  'margin-left-small'
];

const CustomItemsSection = ({customItems}) => (
  <div>
    <h4 className="font-family-effra-bold font-size-18 margin-top-x-large margin-bottom-none">
      {AgendaSectionTypeTitleMap[AgendaSectionType.CUSTOM_ITEMS]}:
    </h4>
    {customItems.length ? (
      <ul className="margin-top-small font-size-14">
        {customItems.map((agendaItem) => (
          <li
            key={agendaItem.id}
            className="margin-bottom-small white-space-pre-line"
          >
            {agendaItem.text}
          </li>
        ))}
      </ul>
    ) : (
      <p>None.</p>
    )}
  </div>
);

const NotesSection = ({sectionType, notes, children}) => (
  <div>
    <h4 className="font-family-effra-bold font-size-18 margin-top-x-large margin-bottom-none">
      {AgendaSectionTypeTitleMap[sectionType]}:
    </h4>
    {children}
    {notes ? (
      <p className="font-size-14 margin-top-small margin-bottom-none white-space-pre-line">
        {notes}
      </p>
    ) : (
      <p className="font-size-14 margin-top-small margin-bottom-none">None</p>
    )}
  </div>
);

const EditAgendaTemplateLink = ({agendaId, isDisabled}) => (
  <a
    href={isDisabled ? undefined : `/agendas/${agendaId}/edit-template/?edit_agenda_details=true`}
    disabled={isDisabled}
  >
    <i className="far fa-edit text-pink font-size-24"></i>
  </a>
);

export class BulkAgendaPreview extends React.PureComponent {

  componentDidMount() {
    this.props.getAgendaTemplate();
  }

  render() {
    const {
      isDisabled,
      isLoadingAgendaTemplate,
      agendaId,
      agenda,
      agendaTitle,
      agendaDescription,
      selectedUsers,
      templateType,
      performanceReviewTerm,
      sendAgendas,
      startOver,
      canCreateOkrs,
      canCreatePeerFeedback
    } = this.props;

    if (isLoadingAgendaTemplate || !agenda) {
      return (
        <div className="padding-xx-large margin-vertical-xx-large">
          <Loading />
        </div>
      );
    }

    return (
      <div className="background-white border-radius-16 padding-xx-large margin-vertical-xx-large
        font-family-varela-round"
      >
        <h1 className="font-family-effra-bold font-size-38 font-color-main">
          Create multiple 1:1s
        </h1>
        <h1 className="font-family-effra-bold font-size-28 font-color-main">
          Preview and edit your 1:1 meeting details before sending
        </h1>
        <div className={classNames(...grayContainerClassNames)}>
          <div className="font-family-effra-bold font-size-22">
            Type of 1:1 meeting:
          </div>
          <div className={classNames(...effraClassNames)}>
            Conversation type:
            <span className={classNames(...varelaClassNames)}>
              {TEMPLATE_TYPE_DISPLAY_MAP[templateType]}
            </span>
          </div>
        </div>
        <div className={classNames(...grayContainerClassNames)}>
          <div className="flex-space-between">
            <div className="font-family-effra-bold font-size-22">
              Details:
            </div>
            <EditAgendaTemplateLink agendaId={agendaId} isDisabled={isDisabled} />
          </div>
          <div className={classNames(...effraClassNames)}>
            1:1 name:
            <span className={classNames(...varelaClassNames)}>
              {agendaTitle}
            </span>
          </div>
          <div className={classNames(...effraClassNames, 'margin-bottom-large')}>
            Description:
          </div>
          <p className="font-family-varela-round font-size-16 white-space-pre-line margin-bottom-none">
            {agendaDescription || 'None'}
          </p>
        </div>
        <div className={classNames(...grayContainerClassNames)}>
          <div className="flex-space-between">
            <div className="font-family-effra-bold font-size-22">
              Participants:
            </div>
            <a
              href={isDisabled ? undefined : `/agendas/${agendaId}/select-participants/`}
              disabled={isDisabled}
            >
              <i className="far fa-edit text-pink font-size-24"></i>
            </a>
          </div>
          <div className="font-size-14 margin-top-base">
            {selectedUsers.length} participants added
          </div>
          {!!selectedUsers.length && (
            <div className={classNames(...effraClassNames)}>
              {selectedUsers.map((user) => (
                <div key={user.email}>
                  {user.name}
                  <span className={classNames('overflow-break-word', ...varelaClassNames)}>
                    {user.email}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={classNames(...grayContainerClassNames)}>
          <div className="flex-space-between">
            <div className="font-family-effra-bold font-size-22">
              Agenda details:
            </div>
            <EditAgendaTemplateLink agendaId={agendaId} isDisabled={isDisabled} />
          </div>
          {agenda.sections.map((sectionType) => (
            <div key={`agenda-preview${sectionType}`}>
              {sectionType === AgendaSectionType.CUSTOM_ITEMS && (
                <CustomItemsSection customItems={agenda.custom_items} />
              )}
              {sectionType === AgendaSectionType.SHARED_NOTES && (
                <NotesSection sectionType={AgendaSectionType.SHARED_NOTES} notes={agenda.shared_notes} />
              )}
              {[
                AgendaSectionType.PERFORMANCE_REVIEW,
                AgendaSectionType.PEER_FEEDBACK,
                AgendaSectionType.ENGAGEMENT_SURVEY,
                AgendaSectionType.EMPLOYEE_GOALS,
                AgendaSectionType.OKRS
              ].includes(sectionType) && hasAgendaSection(sectionType, canCreateOkrs, canCreatePeerFeedback) && (
                <AgendaPreviewSection
                  sectionType={sectionType}
                  agenda={agenda}
                  performanceReviewTerm={performanceReviewTerm}
                />
              )}
            </div>
          ))}
          <NotesSection sectionType={AgendaSectionType.MANAGER_ONLY} notes={agenda.manager_notes}>
            <p className="margin-top-base margin-bottom-small font-size-14 underline">Manager notes:</p>
          </NotesSection>
        </div>
        <button
          onClick={startOver}
          className={classNames(
            'text-align-right',
            'font-family-varela-round',
            'font-size-16',
            'font-color-pink',
            'margin-vertical-xx-large',
            'margin-left-auto',
            'padding-none',
            'background-none',
            'display-flex',
            'align-items-center'
          )}
          disabled={isDisabled}
        >
          Start over
          <span className="margin-left-base">
            <i className="far fa-trash-alt text-pink font-size-20"></i>
          </span>
        </button>
        <div className="font-size-16 padding-vertical-x-large">
          When you click 'Send' your 1:1 request will be sent to all of the participants you have added in the list.
          There will be no way to undo this action.
        </div>
        <div className="margin-top-xx-large display-flex align-items-center">
          <a
            className="link-color-text underline font-size-16"
            href={isDisabled ? undefined : `/agendas/${this.props.agendaId}/edit-template/`}
            disabled={isDisabled}
          >
            {'< Back to create agenda'}
          </a>
          <button
            className="pink-button margin-left-auto"
            onClick={sendAgendas}
            disabled={isDisabled}
          >
            Send
          </button>
          <BulkAgendaCancelButton agendaId={agendaId} isDisabled={isDisabled} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agenda: state.editingAgendaTemplate,
    isLoadingAgendaTemplate: isPending(state.responses, GET_AGENDA_TEMPLATE),
    isDisabled: anyPending(state.responses, [
      GET_AGENDA_TEMPLATE, SEND_BULK_AGENDA, CANCEL_BULK_AGENDA
    ])
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendAgendas: () => (
      dispatch(sendBulkAgendaAndRedirect(ownProps.agendaId))
    ),
    startOver: () => (
      dispatch(cancelBulkAgendaAndRedirectToCreate(ownProps.agendaId))
    ),
    getAgendaTemplate: () => dispatch(getAgendaTemplate(ownProps.agendaId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkAgendaPreview);
