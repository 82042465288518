import React from 'react'; // eslint-disable-line no-unused-vars

import ChadminList from '^/components/common/ChadminList';
import QueryParamsFilterSelect from '^/components/common/QueryParamsFilterSelect';
import {
  PEER_FEEDBACK_TEAM_LIST_URL,
  TEAM_LIST_ITEM_HEADINGS,
  TEAM_LIST_ITEM_FIELDS,
  LIST_OPTIONS
} from '^/components/peer-feedback/constants';
import PeerFeedbackListHeader from '^/components/peer-feedback/PeerFeedbackListHeader';
import PeerFeedbackListItems from '^/components/peer-feedback/PeerFeedbackListItems';
import PeerFeedbackListModalButtons from '^/components/peer-feedback/PeerFeedbackListModalButtons';
import { organisationLevelRoleOptions } from '^/roles';

export const Filter = (props) => (
  <div className="margin-right-base width-140">
    <QueryParamsFilterSelect {...props} />
  </div>
);

export function PeerFeedbackTeamList({filterOptions, organisationTeamTerm}) {
  const { teams, managers } = filterOptions;
  return (
    <div>
      <ChadminList
        className="border-base padding-large border-radius-16"
        collectionPath={PEER_FEEDBACK_TEAM_LIST_URL}
        collectionName={PEER_FEEDBACK_TEAM_LIST_URL}
        ExtraControlsRight={({setFilters}) => (
          <div className="display-flex flex-wrap">
            <Filter
              options={[
                {value: 'received', label: 'Received'},
                {value: 'pending', label: 'Pending'}
              ]}
              filterName="status"
              name="status"
              setFilters={setFilters}
            />
            <Filter
              options={organisationLevelRoleOptions}
              filterName="user__type"
              name="role"
              setFilters={setFilters}
            />
            <Filter
              options={teams}
              filterName="user__team_member__team"
              name={organisationTeamTerm}
              setFilters={setFilters}
            />
            <Filter
              options={managers}
              filterName="user__managers"
              name="reports to"
              setFilters={setFilters}
            />
          </div>
        )}
        ExtraControlsLeft={({reloadPage}) => (
          <PeerFeedbackListModalButtons reloadTeamListPage={reloadPage} />
        )}
        searchable
        pullSearchRight
      >
        <div className="overflow-auto font-family-varela-round font-size-14">
          <div className="min-width-640">
            <PeerFeedbackListHeader
              headings={TEAM_LIST_ITEM_HEADINGS}
              listName={LIST_OPTIONS.TEAM}
              collectionName={PEER_FEEDBACK_TEAM_LIST_URL}
            />
            <PeerFeedbackListItems
              collectionName={PEER_FEEDBACK_TEAM_LIST_URL}
              fields={TEAM_LIST_ITEM_FIELDS}
              listName={LIST_OPTIONS.TEAM}
            />
          </div>
        </div>
      </ChadminList>
    </div>
  );
}

export default PeerFeedbackTeamList;
