import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { joinQueryParameters } from '^/middleware/makeRsaAction';
import { shouldRethrow } from '^/actions/actions';
import { closeModal } from '^/actions/modals';
import { stripFalsyValuesFromObject } from '^/utils';

export const COLLAPSE_OKR = 'COLLAPSE_OKR';
export function collapseOkr(okrId) {
  return {
    type: COLLAPSE_OKR,
    okrId
  };
}

export const EXPAND_OKR = 'EXPAND_OKR';
export function expandOkr(okrId) {
  return {
    type: EXPAND_OKR,
    okrId
  };
}

export const TOGGLE_ADD_COMPANY_OKR = 'TOGGLE_ADD_COMPANY_OKR';
export function toggleAddCompanyOkr() {
  return {
    type: TOGGLE_ADD_COMPANY_OKR,
  };
}

export const ADD_LINKED_OKR = 'ADD_LINKED_OKR';
export function addLinkedOkr(linkedOkrId) {
  return {
    type: ADD_LINKED_OKR,
    linkedOkrId
  };
}

export const CANCEL_ADD_LINKED_OKR = 'CANCEL_ADD_LINKED_OKR';
export function cancelAddLinkedOkr(linkedOkrId) {
  return {
    type: CANCEL_ADD_LINKED_OKR,
    linkedOkrId
  };
}

export const EDIT_OKR = 'EDIT_OKR';
export function editOkr(okrId) {
  return {
    type: EDIT_OKR,
    okrId
  };
}

export const CANCEL_EDIT_OKR = 'CANCEL_EDIT_OKR';
export function cancelEditOkr(okrId) {
  return {
    type: CANCEL_EDIT_OKR,
    okrId
  };
}

export const CREATE_OKR = makeAsyncActionSet('CREATE_OKR');
export function createOkr(parentOkrId, data, form) {
  return (dispatch) => {
    return request(
      CREATE_OKR,
      '/api/okrs/create/',
      'POST',
      data,
      {
        metaData: {
          parentOkrId,
          form,
          type: data.type
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const createOkrAndToastOrRedirect = (parentOkrId, data, form) => dispatch =>
  createOkr(parentOkrId, data, form)(dispatch).then((response) => {
    toast.dismiss();
    if (parentOkrId) {
      window.location.href = `/okrs/${response.data.id}/edit/`;
    } else {
      toast.success('OKR created');
    }
  }).catch(() => {
    toast.dismiss();
    toast.error('Failed to create OKR');
  });

export const UPDATE_OKR = makeAsyncActionSet('UPDATE_OKR');
export function updateOkr(okrId, parentOkrId, data, form) {
  return (dispatch) => {
    return request(
      UPDATE_OKR,
      `/api/okrs/${okrId}/update/`,
      'PATCH',
      data,
      {
        metaData: {
          okrId,
          form,
          parentIdChanged: parentOkrId !== data.parent_okr
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const updateOkrAndToast = (okrId, parentOkrId, data, form) => dispatch =>
  updateOkr(okrId, parentOkrId, data, form)(dispatch).then(() => {
    toast.dismiss();
    toast.success('OKR updated');
  }).catch(() => {
    toast.dismiss();
    toast.error('Failed to update OKR');
  });

export const RETRIEVE_OKR = makeAsyncActionSet('RETRIEVE_OKR');
export function retrieveOkr(okrId) {
  return (dispatch) => {
    return request(
      RETRIEVE_OKR,
      `/api/okrs/${okrId}/`,
      'GET',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const DELETE_OKR = makeAsyncActionSet('DELETE_OKR');
export function deleteOkr(okrId) {
  return (dispatch) => {
    return request(
      DELETE_OKR,
      `/api/okrs/${okrId}/delete/`,
      'DELETE',
      null,
      {
        metaData: { okrId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const deleteOkrAndToast = (okrId, redirectOnDelete) => dispatch =>
  deleteOkr(okrId)(dispatch).then(() => {
    if (redirectOnDelete) {
      window.location.href = '/okrs/';
    } else {
      dispatch(closeModal());
      toast.dismiss();
      toast.success('The OKR was deleted.');
    }
  }).catch(() => {
    toast.dismiss();
    toast.error('The OKR could not be deleted.');
  });

export const OKR_ACTIONS_PICKER_EDIT_ACTION = 'ACTIONS_PICKER_EDIT_ACTION';
export function actionPickerEditAction(actionId) {
  return {
    type: OKR_ACTIONS_PICKER_EDIT_ACTION,
    payload: {
      actionId,
    },
  };
}

export const ACTIONS_PICKER_EXCLUDE_ACTION = 'ACTIONS_PICKER_EXCLUDE_ACTION';
export function actionPickerExcludeAction(actionId) {
  return {
    type: ACTIONS_PICKER_EXCLUDE_ACTION,
    payload: {
      actionId,
    },
  };
}

export const ACTIONS_PICKER_REINCLUDE_ACTION =
  'ACTIONS_PICKER_REINCLUDE_ACTION';
export function actionPickerReincludeAction(actionId) {
  return {
    type: ACTIONS_PICKER_REINCLUDE_ACTION,
    payload: {
      actionId,
    },
  };
}

export const TOGGLE_ADD_ACTION_FORM = 'TOGGLE_ADD_ACTION_FORM';
export function toggleAddActionForm() {
  return {
    type: TOGGLE_ADD_ACTION_FORM
  };
}

export const ADD_ACTION_TO_OKR = makeAsyncActionSet('ADD_ACTION_TO_OKR');
export function addActionToOkr(okrId, data) {
  return (dispatch) => {
    return request(
      ADD_ACTION_TO_OKR,
      `/api/okrs/action/create/`,
      'POST',
      data,
      { metaData: { okrId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export function addActionToOkrAndReload(okrId, data) {
  return (dispatch) => {
    return addActionToOkr(okrId, data)(dispatch).then(() => {
      retrieveOkr(okrId)(dispatch);
    });
  };
}

export const UPDATE_OKR_DETAIL = makeAsyncActionSet('UPDATE_OKR_DETAIL');
export function updateOkrDetail(okrId, data) {
  return (dispatch) => {
    return request(
      UPDATE_OKR_DETAIL,
      `/api/okrs/${okrId}/update-detail/`,
      'PATCH',
      data,
      {
        shouldRethrow
      }
    )(dispatch);
  };
}

export function updateOkrAndRedirectToIt(okrId, data) {
  return (dispatch) => {
    return updateOkrDetail(
      okrId,
      data
    )(dispatch).then(() => {
      window.location.href = `/okrs/${okrId}/`;
    });
  };
}

export const updateOkrDetailAndToast = (okrId, data) => dispatch =>
  updateOkrDetail(okrId, data)(dispatch).then(() => {
    toast.dismiss();
    toast.success('OKR updated');
  }).catch(() => {
    toast.dismiss();
    toast.error('Failed to update OKR');
  });

export const OKR_LIST_PAGE_SIZE = 5;
export const OKR_FILTERED_LIST_PAGE_SIZE = 12;

export const RETRIEVE_OKR_LIST = makeAsyncActionSet('RETRIEVE_OKR_LIST');
export function retrieveOkrList(options) {
  const filters = stripFalsyValuesFromObject({
    search: options.searchString,
    status: options.additionalParams.status,
    reports_to: options.additionalParams.reports_to
  });
  const isFilteredList = !!Object.entries(filters).length;
  const formattedOptions = stripFalsyValuesFromObject(Object.assign({}, filters, {
    page: options.page,
    page_size: (
      isFilteredList ?
      OKR_FILTERED_LIST_PAGE_SIZE : OKR_LIST_PAGE_SIZE
    )
  }));
  return (dispatch) => {
    return request(
      RETRIEVE_OKR_LIST,
      `/api/okrs/${isFilteredList ? 'filtered-' : ''}list/?${joinQueryParameters(formattedOptions)}`,
      'GET',
      null,
      {
        metaData: { page: options.page, filters },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const UPDATE_OKR_ACTION = makeAsyncActionSet('UPDATE_OKR_ACTION');
export function updateOkrAction(actionId, actionData) {
  return (dispatch) => {
    return request(
      UPDATE_OKR_ACTION,
      `/api/okrs/action/update/${actionId}/`,
      'PATCH',
      actionData,
      { metaData: { actionData, actionId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const updateOkrActionAndToast = (actionId, data) => dispatch =>
  updateOkrAction(actionId, data)(dispatch).then(() => {
    toast.dismiss();
    toast.success('OKR action updated');
  }).catch(() => {
    toast.dismiss();
    toast.error('Failed to update OKR action');
  });
