import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';
import { openSimpleModal } from '^/actions/modals';
import DeleteOkrModal from './DeleteOkrModal';

const DeleteOkrBtn = ({ openDeleteOkrModal }) => (
  <div className="text-align-center margin-top-base padding-top-large">
    <button
      onClick={openDeleteOkrModal}
      className="btn middle danger hollow"
    >
      Delete OKR
    </button>
  </div>
);

const mapDispatchToProps = (dispatch, props) => {
  return {
    openDeleteOkrModal: () =>
      dispatch(
        openSimpleModal({
          body: (
            <DeleteOkrModal
              okrType={props.okr.get('type')}
              okrId={props.okr.get('id')}
              redirectOnDelete
            />
          ),
          title: 'Delete OKR',
        })
      ),
  };
};

export default connect(null, mapDispatchToProps)(DeleteOkrBtn);
