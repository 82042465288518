import { isPending } from '@dabapps/redux-requests';
import { Row, Column } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import FilterSelect from '^/components/common/FilterSelect';
import { stripFalsyValuesFromObject, getQueryParamsFromLocation } from '^/utils';
import { CREATE_HEATMAP, createHeatmap } from './actions';

const hadSelectedAdditionalFilters = props =>
  !!Object.keys(props.selectedPrimaryFilters.additional_filters || {}).length;

export class DeriveHeatmapReport extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showAdditionalFilters: Object.keys(getQueryParamsFromLocation()).includes('expand_filters')
    };
    this.getReport = this.getReport.bind(this);
    this.formatAdditionalFilters = this.formatAdditionalFilters.bind(this);
    this.toggleShowAdditionalFilters = this.toggleShowAdditionalFilters.bind(this);
  }

  getReport(newFilter) {
    toast.success('Updating heatmap...');
    this.props.createHeatmap(
      Object.assign(
        {},
        this.props.selectedPrimaryFilters,
        {'group_by': this.props.groupBy},
        newFilter,
        {
          survey: this.props.surveyId,
          report_type: this.props.reportType
        }
      ),
      hadSelectedAdditionalFilters(this.props)
    );
  }

  formatAdditionalFilters(filterId, filterValue) {
    return {
      additional_filters: stripFalsyValuesFromObject(Object.assign(
        {},
        this.props.selectedPrimaryFilters.additional_filters,
        { [filterId]: filterValue }
      ))
    };
  }

  toggleShowAdditionalFilters() {
    this.setState({showAdditionalFilters: !this.state.showAdditionalFilters});
  }

  render() {
    const { selectedPrimaryFilters, groupBy, isLoading, organisationFilterTerms } = this.props;
    return (
      <div>
        <ToastContainer />
        <Row className="margin-top-large">
          <Column xs={2}>
            <FilterSelect
              filterKeyName="group_by"
              displayName="Group data by"
              options={this.props.groupingOptions}
              setFilters={this.getReport}
              value={groupBy}
              disabled={isLoading}
              boldLabel
              showAll={false}
            />
          </Column>
        </Row>
        <Row className="margin-top-large">
          <Column xs={2}>
            <FilterSelect
              filterKeyName="team_filter"
              displayName={organisationFilterTerms.team}
              options={this.props.filterOptions.teams}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.team_filter}
              disabled={isLoading}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="location_filter"
              displayName={organisationFilterTerms.location}
              options={this.props.filterOptions.locations}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.location_filter}
              disabled={isLoading}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="department_filter"
              displayName={organisationFilterTerms.department}
              options={this.props.filterOptions.departments}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.department_filter}
              disabled={isLoading}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="unit_filter"
              displayName={organisationFilterTerms.unit}
              options={this.props.filterOptions.units}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.unit_filter}
              disabled={isLoading}
              boldLabel
            />
          </Column>
          <Column xs={2}>
            <FilterSelect
              filterKeyName="manager_filter"
              displayName={organisationFilterTerms.manager}
              options={this.props.filterOptions.managers}
              setFilters={this.getReport}
              value={selectedPrimaryFilters.manager_filter}
              disabled={isLoading}
              boldLabel
            />
          </Column>
        </Row>
        <Row className={classNames({
          'margin-top-large': this.state.showAdditionalFilters,
          'collapsed-height': !this.state.showAdditionalFilters
        })}>
          {this.props.filterOptions.additional_filters &&
            this.props.filterOptions.additional_filters.map((additionalFilter, index) => (
            <Column xs={2} key={`additionalFilter-${additionalFilter.name}`} className={classNames({
              'margin-top-large': index > 5
            })}>
              <FilterSelect
                filterKeyName={additionalFilter.id}
                displayName={additionalFilter.name}
                options={additionalFilter.options}
                formatFilters={this.formatAdditionalFilters}
                setFilters={this.getReport}
                value={selectedPrimaryFilters.additional_filters[additionalFilter.id]}
                disabled={isLoading}
                boldLabel
              />
            </Column>
          ))}
        </Row>
        {!!this.props.filterOptions.additional_filters.length && (
          <button
            className="btn orange margin-top-large"
            onClick={this.toggleShowAdditionalFilters}
          >
            Show {this.state.showAdditionalFilters ? 'less' : 'more'} filters
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: isPending(state.responses, CREATE_HEATMAP, ''),
});

export default connect(mapStateToProps, {
  createHeatmap
})(DeriveHeatmapReport);
