import classNames from 'classnames';
import React from 'react';  // eslint-disable-line
import { ACTION_TYPE_MAPPING, ACTION_TYPE_TO_ICON_MAPPING } from '^/consts/actions';

export const ActionTypeIcon = ({actionType}) => (
  <span>
    <span className="margin-right-small">
      <i className={classNames(ACTION_TYPE_TO_ICON_MAPPING[actionType])}></i>
    </span>
    {ACTION_TYPE_MAPPING[actionType]}
  </span>
);

export default ActionTypeIcon;
