import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import CollapsableWell from '../common/CollapsibleWell';
import AddPeerFeedbackToAgendaCard from './AddPeerFeedbackToAgendaCard';
import { toggleViewAllPeerFeedback } from '^/components/agendas/actions';
import { SelectableTitleByType, AgendaSectionType } from '^/components/agendas/constants';

export const AgendaPeerFeedbackSelect = ({
  agendaId,
  isDisabled,
  selectableItems,
  isAgendaEmployee,
  viewAll,
  toggleViewAll
}) => {
  return (
    <CollapsableWell
      title={SelectableTitleByType[AgendaSectionType.PEER_FEEDBACK]}
      isOrange
      isCollapsed
    >
      <p className="fwb cursor-pointer fit-content font-size-14">
        Feedback received over the last 6 months.
      </p>
      {!isAgendaEmployee && (
        <p className="font-size-14">
          Feedback displayed with a padlock symbol cannot be viewed by the employee, even when added to the agenda.
        </p>
      )}
      {selectableItems.length ? (
        <div>
          {
            selectableItems.map((item, index) => {
              if (viewAll || index < 3) {
                return (
                  <AddPeerFeedbackToAgendaCard
                    key={item.feedback_id}
                    agendaId={agendaId}
                    item={item}
                    isDisabled={isDisabled}
                  />
                );
              }
            })
          }
          {selectableItems.length > 3 && (
            <div className="display-flex justify-content-end">
              <div
                role="button"
                className="fwb link-orange underline font-size-14 cursor-pointer"
                onClick={toggleViewAll}
              >
                {viewAll ? 'View less' : 'View all'}
              </div>
            </div>
          )}
        </div>
      )
      : <p>No feedback available.</p>}
    </CollapsableWell>
  );
};

function mapStateToProps(state, props) {
  return {
    selectableItems: props.items.filter(option =>
      !state.editingAgenda.peer_feedback.find(feedback => (
        feedback.feedback_id === option.feedback_id
      ))
    ),
    viewAll: state.showAllPeerFeedbackOptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleViewAll: () => {
      dispatch(toggleViewAllPeerFeedback());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaPeerFeedbackSelect);
