import { Map } from 'immutable';
import { GET_USER_OPTIONS, GET_TEAM_MEMBER_OPTIONS } from '^/components/common/actions';
import { SELECT_REMOTE_USER } from './actions';

export function selectedRemoteUser(state = Map(), action) {
  switch (action.type) {
    case SELECT_REMOTE_USER:
      return state.set(action.payload.selectId, action.payload.newValue);
    default:
      return state;
  }
}

export function remoteUsers(state = Map(), action) {
  switch (action.type) {
    case GET_TEAM_MEMBER_OPTIONS.SUCCESS:
    case GET_USER_OPTIONS.SUCCESS:
      return state.set(action.meta.selectId, action.payload.data);
    default:
      return state;
  }
}
