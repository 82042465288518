import thunk from 'redux-thunk';
import { fromJS } from 'immutable';
import { apiMiddleware } from 'redux-api-middleware';
import { createStore, applyMiddleware, compose } from 'redux';
import actionChain from '^/middleware/actionChain';
import rootReducer from '^/reducers/rootReducer';
import { CURRENT_USER, BULK_AGENDA_SELECTED_IDS } from '^/utils/template';

const middlewares = [
  thunk,
  apiMiddleware,
  actionChain //This item MUST be last in the queue
];

const finalCreateStore = compose(
  applyMiddleware(
    ...middlewares
  )
)(createStore);

const store = finalCreateStore(
  rootReducer,
  {
    loggedInUser: CURRENT_USER ? fromJS(CURRENT_USER) : null,
    bulkAgendaSelectedUserIds: BULK_AGENDA_SELECTED_IDS
  }
);

export default store;
