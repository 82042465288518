import React from 'react';
import { FORMAT_DATE_PICKER } from '../../utils';
import DatePicker from 'react-datepicker';


export default class DateRangeFilter extends React.PureComponent {
  render() {
    const { fromKey, toKey, fromValue, toValue, onChange, filterName } = this.props;

    return (
      <div className="date-range-filter">
        <span className="to-text">{filterName}</span>
        <DatePicker
          selected={fromValue}
          selectsStart
          dateFormat={FORMAT_DATE_PICKER}
          startDate={fromValue}
          endDate={toValue}
          onChange={d => onChange(fromKey, d)}
        />

        <span className="to-text">to</span>

        <DatePicker
          selected={toValue}
          selectsEnd
          dateFormat={FORMAT_DATE_PICKER}
          startDate={fromValue}
          endDate={toValue}
          onChange={d => onChange(toKey, d)}
        />

        <div className="clear-btn">
          <small onClick={() => {
            onChange(fromKey, null);
            onChange(toKey, null);
          }}>Clear</small>
        </div>
      </div>
    );
  }
}
