import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { getOrgPermissions } from '^/actions/actions';
import { getAdminEnpsData, ADMIN_ENPS_DATA } from '^/components/dashboards/actions';
import DashboardWrapper from '^/components/dashboards/DashboardWrapper';
import EnpsWidget from '^/components/dashboards/EnpsWidget';
import CompanyGoalWidget from '^/components/dashboards/CompanyGoalWidget';
import AdminPerformanceReviewWidget from '^/components/dashboards/AdminPerformanceReviewWidget'; // eslint-disable-line
import AdminLatestSurveysWidget from '^/components/dashboards/AdminLatestSurveysWidget';
import AdminGoalsWidget from '^/components/dashboards/AdminGoalsWidget';
import AdminOneToOnes from '^/components/dashboards/AdminOneToOnes';
import TalentSnapshotWidget from '^/components/dashboards/TalentSnapshotWidget';
import TrendAnalysisWidget from '^/components/dashboards/TrendAnalysisWidget';
import AdminPeerFeedbackWidget from '^/components/dashboards/AdminPeerFeedbackWidget';

const getPeerFeedbackRowHalfWidths = orgPermissions => ['performance_reviews', 'agendas', 'peer_feedback'].filter(
  name => !!orgPermissions.get(name)
).length === 2;

export const FeedbackRowWidgetContainer = ({children, halfWidths}) => (
  <div className={classNames('dashboard-widget-container', {
    'dashboard-widget-container-50': halfWidths,
    'dashboard-widget-container-33': !halfWidths,
  })}>
    {children}
  </div>
);

export class AdminDashboard extends React.PureComponent {
  componentDidMount() {
    this.props.getOrgPermissions();
  }

  render() {
    const { orgPermissions, organisationFilterTerms } = this.props;
    const peerFeedbackRowHalfWidths = getPeerFeedbackRowHalfWidths(orgPermissions);
    const hasOkrs = orgPermissions.get('okrs');
    const hasAgendas = orgPermissions.get('agendas');

    return (
      <DashboardWrapper activeTab={2}>
        <h1 className={
          classNames(
            'font-size-18',
            'font-family-varela-round',
            'margin-top-base',
            'margin-bottom-none'
          )
        }>You are viewing an organisation-wide executive summary.</h1>
        <div className="dashboard-widget-container flex-grow">
          <TrendAnalysisWidget
            // Only show original reports created and published by admins
            listFilter={{admin_versions_only: true}}
            organisationFilterTerms={organisationFilterTerms}
            emptyText="Your organisation has no published executive reports yet."
          />
        </div>
        <div className="display-flex-md flex-wrap flex-gap-xx-large">
          {hasOkrs &&
            <div className={classNames('dashboard-widget-container flex-grow', {
              'max-width-720': hasAgendas,
            })}>
              <CompanyGoalWidget />
            </div>
          }
          {hasAgendas && (
            <div className={classNames('dashboard-widget-container flex-grow', {
              'max-width-510': hasOkrs,
            })}>
              <TalentSnapshotWidget />
            </div>
          )}
        </div>
        <div className="display-flex-md flex-gap-xx-large">
          <div className="dashboard-widget-container flex-grow">
            <AdminLatestSurveysWidget />
          </div>
          <div className="dashboard-widget-container max-width-375">
            <EnpsWidget getEnpsData={getAdminEnpsData} enpsKey={ADMIN_ENPS_DATA} hasChart />
          </div>
        </div>
        <div className="display-flex-md flex-wrap flex-gap-xx-large">
          {orgPermissions.get('performance_reviews') &&
            <FeedbackRowWidgetContainer halfWidths={peerFeedbackRowHalfWidths}>
              <AdminPerformanceReviewWidget />
            </FeedbackRowWidgetContainer>
          }
          {hasAgendas &&
            <FeedbackRowWidgetContainer halfWidths={peerFeedbackRowHalfWidths}>
              <AdminOneToOnes />
            </FeedbackRowWidgetContainer>
          }
          {orgPermissions.get('peer_feedback') &&
            <FeedbackRowWidgetContainer halfWidths={peerFeedbackRowHalfWidths}>
              <AdminPeerFeedbackWidget />
            </FeedbackRowWidgetContainer>
          }
          <div className="dashboard-widget-container flex-grow max-width-720">
            <AdminGoalsWidget />
          </div>
        </div>
      </DashboardWrapper>
    );
  }
};

export function mapStateToProps(state) {
  return {
    orgPermissions: state.orgPermissions,
  };
}

export default connect(mapStateToProps, { getOrgPermissions })(AdminDashboard);
