import * as React from 'react'; // eslint-disable-line no-unused-vars
import { Row, Column, Button } from '@dabapps/roe';
import CustomQuestionForm from './CustomQuestionForm';
import CustomQuestionsList from './CustomQuestionsList';
import { ToastContainer, toast } from 'react-toastify';
import { getCollection } from '^/actions/collections';
import { openSimpleModal } from '^/actions/modals';
import { connect } from 'react-redux';
import { ModalRenderer } from '@dabapps/roe';

export function ManageCustomQuestions(props) {
  return (
    <div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
      <ModalRenderer modals={props.modals} />
      <Row>
        <Column xs={12}>
          <h4>Add new custom question</h4>
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <Button className="primary" type="submit"
            onClick={props.openCustomQuestionModal}
          >
            Add custom question
          </Button>
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <hr className="margin-vertical-xx-large" />
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <h4>Custom questions available for surveys</h4>
          <p>
            Below are all questions already created. If you remove a question,
            it’s just removed from the selection list - it will still exist on
            any survey that uses it.
          </p>
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <div className="organisation-questions-list">
            <CustomQuestionsList />
          </div>
        </Column>
      </Row>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    modals: state.modals
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    openCustomQuestionModal: () =>
      dispatch(
        openSimpleModal({
          body: <CustomQuestionForm />,
          title: `Add new custom question`,
        }),
        getCollection
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomQuestions);
