import { Column, Row } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDatePicker from 'react-datepicker';
import { Field, reduxForm } from 'redux-form';
import { FORMAT_DATE_PICKER } from '^/utils';
import { TEMPLATE_TYPES, TEMPLATE_TYPE_DISPLAY_MAP } from './constants';

export const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper">
      <ReactDatePicker
        selected={props.input.value}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        disabled={props.isDisabled}
        required
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

export function RequestOneToOneForm ({handleSubmit, managerOptions, isDisabled}) {
  return (
    <form className="clearfix" onSubmit={handleSubmit}>
      <Row className="margin-bottom-base">
        <Column md={8}>
          <label htmlFor="title" className="margin-none font-size-14"><strong>1:1 Title</strong></label>
        </Column>
        <Column md={4}>
          <label
            htmlFor="manager"
            className="margin-none font-size-14"
          >
            <strong>Select manager</strong>
          </label>
        </Column>
      </Row>
      <Row className="margin-bottom-xx-large">
        <Column md={8}>
          <Field id="title" name="title" component="input" type="text" disabled={isDisabled} required />
        </Column>
        <Column md={4}>
          <Field
            name="manager"
            component="select"
            disabled={isDisabled}
            required
          >
            <option value="">Select manager</option>
            {managerOptions.map(option => (
              <option key={`manager-option${option.value}`} value={option.value}>{option.label}</option>
            ))}
          </Field>
        </Column>
      </Row>
      <Row className="margin-top-large margin-bottom-base">
        <Column md={8}>
          <label htmlFor="description" className="margin-none font-size-14"><strong>1:1 Description</strong></label>
        </Column>
        <Column md={2}>
          <label htmlFor="date" className="margin-none font-size-14"><strong>Desired 1:1 date</strong></label>
        </Column>
        <Column md={2}>
          <label htmlFor="time" className="margin-none font-size-14"><strong>Desired 1:1 time</strong></label>
        </Column>
      </Row>
      <Row>
        <Column md={8}>
          <Field
            id="description"
            name="description"
            component="textarea"
            className="height-130"
            disabled={isDisabled}
          />
        </Column>
        <Column md={2}>
          <Field
            id="date"
            name="date"
            component={DatePickerField}
            isDisabled={isDisabled}
          />
        </Column>
        <Column md={2}>
          <Field
            id="time"
            name="time"
            component="input"
            type="time"
            className="timepicker-with-icon-wrapper"
            disabled={isDisabled}
            required
          />
        </Column>
        <Column md={4}>
          <label
            htmlFor="template_type"
            className="margin-bottom-base margin-top-xx-large font-size-14"
          >
            <strong>Choose a conversation type</strong>
          </label>
          <Field
            id="template_type"
            name="template_type"
            component="select"
            disabled={isDisabled}
            required
          >
            <option value="">None selected</option>
            {Object.keys(TEMPLATE_TYPES).map(template => (
              <option key={`template-${template}`} value={template}>{TEMPLATE_TYPE_DISPLAY_MAP[template]}</option>
            ))}
          </Field>
        </Column>
      </Row>
      <Row className="margin-top-xx-large line-height-32px padding-right-small">
        <Column md={12}>
          <button
            className="btn middle orange margin-right-base"
            id="submit-button"
            disabled={isDisabled}
          >
            Send request
          </button>
          or
          <a
            className="margin-left-base"
            href={!isDisabled ? '/agendas/list/' : undefined}
            disabled={isDisabled}
          >
            Cancel
          </a>
        </Column>
      </Row>
    </form>
  );
}

export default reduxForm({
  form: 'requestOneToOneForm',
})(RequestOneToOneForm);
