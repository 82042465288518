import { isPending } from '@dabapps/redux-requests';
import { ModalRenderer } from '@dabapps/roe';
import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Loading from '^/components/Loading';
import AdminListPagination from '^/components/admin/AdminListPagination';
import {
  toggleAddCustomReport,
  getCustomReportList,
  GET_CUSTOM_REPORT_LIST
} from '^/components/custom-reports/actions';
import AddReportSection from '^/components/custom-reports/AddReportSection';
import ReportSection from '^/components/custom-reports/ReportSection';
import { CUSTOM_REPORT_LIST_PAGE_SIZE } from '^/components/custom-reports/constants';

export class CustomReports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.loadPage = this.loadPage.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
  }

  loadPage(page) {
    this.props.getCustomReportList(page);
  }

  render() {
    return (
      <div>
        {this.props.addNewCustomReport ? (
          <AddReportSection />
        ) : (
          <button
            className="build-another-report-container"
            onClick={this.props.toggleAddCustomReport}
            disabled={this.props.isLoadingList}
          >
            <div className="build-another-report-content">
              <h1 className="title font-family-noto-sans font-size-28">
                Build a{!!this.props.customReportsList.length && 'nother'} report
              </h1>
              <i className="fas fa-plus-circle"></i>
            </div>
          </button>
        )}
        {this.props.isLoadingList ? (
          <div className="padding-vertical-xx-large">
            <Loading />
          </div>
        ) : this.props.customReportsList.map(report => (
          <ReportSection key={report.id} report={report} organisationFilterTerms={this.props.organisationFilterTerms} />
        ))}
        <div className="padding-bottom-xx-large">
          {!!this.props.customReportsList.length && (
            <AdminListPagination
              collection={this.props.customReportListCollection}
              loadPage={this.loadPage}
              pageSize={CUSTOM_REPORT_LIST_PAGE_SIZE}
            />
          )}
        </div>
        <ModalRenderer modals={this.props.modals} />
        <ToastContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modals,
    customReportsList: state.customReportsList,
    addNewCustomReport: state.addNewCustomReport,
    customReportListCollection: state.customReportListCollection,
    isLoadingList: isPending(state.responses, GET_CUSTOM_REPORT_LIST)
  };
}

export default connect(mapStateToProps, {
  toggleAddCustomReport,
  getCustomReportList,
})(CustomReports);
