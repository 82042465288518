import React from 'react'; // eslint-disable-line
import { Field, reduxForm } from 'redux-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Loading from '../../components/Loading';
import { FORMAT_DATE_BACKEND } from '../../utils';
import UserSelect from '^/components/common/UserSelect';
import ScrollableTextArea from '^/components/forms/ScrollableTextArea';
import { ACTION_STATUS_OPTIONS } from '^/consts/actions';


const renderDatePicker = ({input, meta: {touched, error} }) => (
  <div>
    <DatePicker
      {...input}
      dateFormat={FORMAT_DATE_BACKEND}
      selected={input.value ? moment(input.value) : null}
      required
    />
    {touched && error && <span>{error}</span>}
  </div>
);


const WrappedUserSelect = ({input, meta: {touched, error}, disabled }) => (
  <div>
      <UserSelect
        selectId="actions-owner-select"
        {...input}
        disabled={disabled}
        optionFilters={{'bubble_users_only': true}}
      />
      {touched && error && <span>{error}</span>}
  </div>
);


const ActionEditorForm = props => {
  const { actionCreatedDate, errors, lockedSurveyName } = props;

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        {errors.get('non_field_errors') && (
          <div className="col-12">
            <div className="alert alert-danger">
              <ul>
                {errors.get('non_field_errors').map(err => <li>{err}</li>)}
              </ul>
            </div>
          </div>
        )}
        <div className="col-8">
          <div className="action-field font-size-14">
            <label htmlFor="title" className="margin-top-large margin-bottom-base">Title</label>
            {errors.get('title') && (
              <ul className="errorlist">
                <li>{errors.get('title')}</li>
              </ul>
            )}
            <Field name="title" placeholder="Enter Title" component="input" type="text" required />
          </div>
          <div className="action-field description-field font-size-14">
            <label htmlFor="description" className="margin-bottom-base">Description</label>
            {errors.get('description') && (
              <ul className="errorlist">
                <li>{errors.get('description')}</li>
              </ul>
            )}
            <ScrollableTextArea wrap>
              <Field name="description" placeholder="Enter Description" component="textarea" type="text" />
            </ScrollableTextArea>
          </div>
        </div>
        <div className="col-4">
          <div className="action-field status">
            <div className="action-field margin-left-x-large margin-right-x-large">
              <label htmlFor="status">Status</label>
              {errors.get('status') && (
                <ul className="errorlist">
                  <li>{errors.get('status')}</li>
                </ul>
              )}
              <Field name="status" component="select">
                <option value="">Please Select...</option>
                {ACTION_STATUS_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </div>
          </div>

          <div className="action-field margin-left-x-large margin-right-x-large">
            <label htmlFor="survey">Survey (Optional)</label>
            {errors.get('survey') && (
              <ul className="errorlist">
                <li>{errors.get('survey')}</li>
              </ul>
            )}
            {lockedSurveyName ? (
              <span>{lockedSurveyName}</span>
            ) : (
              <Field name="survey" component="select">
                <option value="">Please Select...</option>
                {props.surveyOptions.map(
                  user => <option key={user.get('value')} value={user.get('value')}>{user.get('display_name')}</option>
                )}
              </Field>
            )}
          </div>
          <div className="action-field margin-left-x-large margin-right-x-large">
            <label htmlFor="goal">Goal (Optional)</label>
            {errors.get('goal') && (
              <ul className="errorlist">
                <li>{errors.get('goal')}</li>
              </ul>
            )}
            <Field name="goal" component="select">
              <option value="">Please Select...</option>
              {props.goalOptions.map(
                goal => <option key={goal.get('value')} value={goal.get('value')}>{goal.get('display_name')}</option>
              )}
            </Field>
          </div>
          <div className="action-field margin-left-x-large margin-right-x-large">
            <label htmlFor="area">Area (Optional)</label>
            {errors.get('area') && (
              <ul className="errorlist">
                <li>{errors.get('area')}</li>
              </ul>
            )}
            <Field name="area" component="select">
              <option value="">Please Select...</option>
              {props.areaOptions.map(
                area => <option key={area.get('value')} value={area.get('value')}>{area.get('display_name')}</option>
              )}
            </Field>
          </div>
          <div className="action-field margin-left-x-large margin-right-x-large">
            <hr />
          </div>
          <div className="action-field margin-left-x-large margin-right-x-large">
            <label htmlFor="owner">Owner</label>
            {errors.get('owner') && (
              <ul className="errorlist">
                <li>{errors.get('owner')}</li>
              </ul>
            )}
            <Field
              name="owner"
              disabled={props.isEmployeeBubbleUser}
              placeholder="Owner"
              component={WrappedUserSelect}
              type="text" />
          </div>
          <div className="action-field margin-left-x-large margin-right-x-large">
            <hr />
          </div>
          <div className="action-field margin-left-x-large margin-right-x-large">
            <label htmlFor="due_date">Due Date</label>
            {errors.get('due_date') && (
              <ul className="errorlist">
                <li>{errors.get('due_date')}</li>
              </ul>
            )}
            <Field name="due_date" component={renderDatePicker} />
          </div>
          <div className="action-field margin-left-small">
            <div className="col-2 text-grey-darker font-size-14">
              Created
            </div>
            <div className="col-10 font-size-14">
              <strong>
              {actionCreatedDate}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div>
        {!props.isLoading ? (
          <span className="force-align-middle">
            <button className="btn middle orange force-align-middle">Save action</button>
            {' or '}
            <a className="force-align-middle" href={props.cancelUrl}>Cancel</a>
          </span>
        ) : <Loading />}
      </div>
    </form>
  );
};

export { ActionEditorForm as UnconnectedActionEditorForm };

export default reduxForm({
  form: 'action-editor-form',
  enableReinitialize: true
})(ActionEditorForm);;
