import { Row, Column } from '@dabapps/roe';
import React from 'react';  // eslint-disable-line no-unused-vars
import { Field as ReduxFormField } from 'redux-form';
import FormError from '^/components/forms/FormError';

const Field = ({
  name,
  label,
  placeholder,
  response,
  blankValueLabel,
  options,
  helpText,
  type = 'text',
  component = 'input',
  disabled = false
}) => (
  <Row className="margin-top-large">
    <Column xs={12} md={3}>
      <label
        className="centred-field-label margin-bottom-none"
        htmlFor={`${name}-field`}
      >
        <span>{label}</span>
      </label>
    </Column>
    <Column xs={12} md={9}>
      {component === 'select' ? (
        <ReduxFormField
          id={`${name}-field`}
          name={name}
          component="select"
          placeholder={placeholder}
          disabled={disabled}
        >
          {blankValueLabel && (
            <option value="">{blankValueLabel}</option>
          )}
          {options.map(option =>
            <option key={`${name}-${option.value}`} value={option.value}>{option.label}</option>
          )}
        </ReduxFormField>
      ) : (
        <ReduxFormField
          id={`${name}-field`}
          name={name}
          component={component}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
      <FormError response={response} formKey={name} />
      {helpText && (
        <p className="margin-top-base text-help">{helpText}</p>
      )}
    </Column>
  </Row>
);

export default Field;
