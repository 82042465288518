import React from 'react';
import moment from 'moment';
import { FORMAT_DATE_PICKER, FORMAT_TIME } from '^/utils';

export default class RecentSurveyItem extends React.PureComponent {
  getDateTimeStringForSurvey(survey) {
    const complete = survey.get('complete');
    const dateClosedOrWillClose = survey.get('date_closed_or_will_close');

    if (this.props.isManagerDashboardItem) {
      return moment(dateClosedOrWillClose).format(`${FORMAT_DATE_PICKER}`);
    }

    if (complete) {
      return moment(complete).format(`${FORMAT_DATE_PICKER}, ${FORMAT_TIME}`);
    }

    return survey.get('is_self_initiated') ? 'N/A' : moment(dateClosedOrWillClose).format(`${FORMAT_DATE_PICKER}`);
  };

  getSurveyPrefix(survey) {

    const complete = survey.get('complete');
    const dateClosedOrWillClose = survey.get('date_closed_or_will_close');

    if (complete) {
      return 'Completed';
    }

    return (
      dateClosedOrWillClose === 'unknown' ||
      moment(dateClosedOrWillClose).diff(moment(), 'days') > 0
    ) && !complete ? 'Due' : 'Closed';
  };

  render() {
    const { survey } = this.props;
    const surveyHasUrl = survey.get('survey_link') !== null;

    return (
      <div className="margin-bottom-large widget-item-hollow">
        <p
          className="margin-bottom-small font-size-14 font-weight-bold"
        >
          {survey.get('name')}
        </p>
        <div className="flex-space-between">

          <p className="margin-bottom-none text-gray font-size-13 display-inline-block">
            <span className="font-color-black">
              { `${this.getSurveyPrefix(survey)}: ` }
            </span>
            { this.getDateTimeStringForSurvey(survey) }
          </p>

          {
            this.props.isManagerDashboardItem && !surveyHasUrl && (
              <a
                className="view-details-link font-size-13 cursor-pointer margin-bottom-none"
                title="You do not have permissions to view this survey"
                href="#"
                disabled
              >
                View results{' '}
                <i className="far fa-arrow-right arrow text-pink"></i>
              </a>
            )
          }

          {surveyHasUrl && (
              <a
                href={survey.get('survey_link')}
                className="view-details-link font-size-13"
              >
                View results{' '}
                <i className="far fa-arrow-right arrow text-pink"></i>
              </a>
            )
          }
          {survey.get('complete_survey_link') && (
            <a
              className="view-details-link font-size-13"
              href={survey.get('complete_survey_link')}
            >
              Complete now{' '}
              <i className="far fa-arrow-right arrow text-pink"></i>
            </a>
          )}
        </div>
      </div>
    );
  };
}

RecentSurveyItem.defaultProps = {
  showCompleteLinks: true
};

RecentSurveyItem.PropTypes = {
  survey: React.PropTypes.object
};
