import React from 'react'; // eslint-disable-line no-unused-vars
import { Map } from 'immutable';

import { EMAIL_TEMPLATE_TYPES, EMAIL_TEMPLATE_NAMES } from './constants';

const JumpToEmailSection = () => (
  <div className="margin-bottom-xx-large">
    <p>
      Go to:
      {Map(EMAIL_TEMPLATE_TYPES).map(
        (templateType) => (
          <a className="margin-left-base" key={templateType} href={`#${templateType}`}>
            {EMAIL_TEMPLATE_NAMES[templateType]}
          </a>
        ))
      }
    </p>
  </div>
);

export { JumpToEmailSection };

export default JumpToEmailSection;
