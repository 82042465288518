import Highcharts, { HIGHCHARTS_CONFIG_DEFAULTS } from './highcharts';
import _ from 'underscore';
import moment from 'moment';

import { getChartExportButtons } from '^/components/custom-reports/utils';
import { FORMAT_DATE } from '../utils';

const COMPARISON_DATA_COLUMN_COLOR = '#d7d7d7';

export function renderAveragesBySubAreaBarChart(elementId, data, comparisonData = null) {
  var columnData = data.average_scores;
  var minimumValues = data.minimum_scores;
  var maximumValues = data.maximum_scores;
  var colorMap = data.area_color_map;
  var descriptionMap = data.area_description_map;
  var industryAverages = data.industry_averages;
  var globalAverages = data.global_averages;

  Highcharts.chart(elementId, Object.assign(HIGHCHARTS_CONFIG_DEFAULTS, {
    chart: {
      type: 'column',
      height: 500,
      marginTop: 70
    },
    xAxis: {
      categories: Object.keys(columnData),
      crosshair: true
    },
    credits: {
      enabled: false
    },
    title: {
      text: 'Average scores by area'
    },
    subtitle: comparisonData && comparisonData.has_hidden_anon_results ? {
      text: 'Please note that the comparison data excludes anonymous results as there were fewer than 4 total.'
    } : undefined,
    yAxis: {
      max: 100,
      min: 0,
      tickInterval: 25,
      title: {
        text: 'Score'
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return `<strong>${this.x}</strong> ${this.y} <br /> ${descriptionMap[this.x]}`;
      }
    },
    legend: {
      symbolRadius: 0
    },
    series: [
      ...(comparisonData ? [
        {
          color: COMPARISON_DATA_COLUMN_COLOR,
          name: `Average score in period (${moment(comparisonData.start_date).format(FORMAT_DATE)} - ${moment(comparisonData.end_date).format(FORMAT_DATE)})`,  // eslint-disable-line
          data: Object.values(comparisonData.average_scores).map(score => ({
            y: score
          })),
        }
      ] : []),
      {
        name: 'Average score',
        data: _.pairs(columnData).map((column) => {
          var columnLabel = column[0];
          var columnValue = column[1];

          return {
            y: columnValue,
            color: colorMap[columnLabel],
          };
        }),
      },
      {
        name: 'Minimum score',
        type: 'spline',
        data: Object.values(minimumValues)
      },
      {
        name: 'Maximum score',
        type: 'spline',
        data: Object.values(maximumValues)
      },
      ...(
        industryAverages ? [{
          name: 'Industry average',
          type: 'spline',
          visible: false,
          data: Object.values(industryAverages)
        }] : []
      ),
      ...(
        globalAverages ? [{
          name: 'WeThrive average',
          type: 'spline',
          visible: false,
          data: Object.values(globalAverages)
        }] : []
      )
    ],
    exporting: {
      buttons: getChartExportButtons(null, elementId),
    }
  }));
}
