import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import {
  getCreateGoalData,
  getResourceActionData,
  getTaskActionData,
} from '^/utils/actions';

export const CREATE_SUGGESTED_ACTIONS = makeAsyncActionSet(
  'CREATE_SUGGESTED_ACTIONS'
);
export function createSuggestedActions(data) {
  return (dispatch) => {
    return request(
      CREATE_SUGGESTED_ACTIONS,
      `/api/goals/actions/create-from-analysis/`,
      'POST',
      data
    )(dispatch);
  };
}

export const CREATE_GOAL_FROM_ANALYSIS = makeAsyncActionSet('CREATE_GOAL_FROM_ANALYSIS');
export function createGoalFromAnalysis(question, suggestionsMap, area) {
  return (dispatch) => {
    const tasks = question
      .get('suggested_tasks')
      .map((suggestion) =>
        getTaskActionData(
          suggestion.get('suggestion'),
          suggestionsMap,
          area,
          suggestion.get('weight')
        )
      );
    const resources = question
      .get('suggested_resources')
      .map((resource) =>
        getResourceActionData(resource, suggestionsMap, area)
      );

    const allActions = tasks.concat(resources).toJS();

    return createSuggestedActions(allActions)(dispatch).then((response) => {
      return request(
        CREATE_GOAL_FROM_ANALYSIS,
        `/api/goals/create-from-analysis/`,
        'POST',
        getCreateGoalData(
          question.get('suggested_goal'),
          question.get('suggested_goal_detail'),
          area,
          response.data,
          suggestionsMap.get('survey')
        )
      )(dispatch);
    });
  };
}

export const EXPAND_RECOMMENDATION = 'EXPAND_RECOMMENDATION';
export function expandRecommendation(recommendationId) {
  return {
    type: EXPAND_RECOMMENDATION,
    payload: {
      recommendationId,
    },
  };
}

export const COLLAPSE_RECOMMENDATION = 'COLLAPSE_RECOMMENDATION';
export function collapseRecommendation(recommendationId) {
  return {
    type: COLLAPSE_RECOMMENDATION,
    payload: {
      recommendationId,
    },
  };
}

export function createActionAndRedirectToIt(data) {
  return (dispatch) => {
    return createSuggestedActions([data])(dispatch).then((response) => {
      window.location.href = `/goals/actions/${response.data[0]}/edit/?origin=analysis`;
    });
  };
}

export function createGoalAndRedirectToIt(
  question,
  suggestionsMap,
  area
) {
  return (dispatch) => {
    return createGoalFromAnalysis(
      question,
      suggestionsMap,
      area
    )(dispatch).then((createGoalResponse) => {
      window.location.href = `/goals/${createGoalResponse.data.id}/edit/`;
    });
  };
}
