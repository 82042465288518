import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import AgendaItemCard from './AgendaItemCard';
import { addEngagementSurveyQuestionToAgendaAndToast } from './actions';
import { engagementQuestionOptionIsSelected } from './utils';

export const SelectableEngagementSurveyQuestionCard = ({
  option,
  isDisabled,
  addEngagementSurveyQuestionToAgenda
}) => {
  return (
    <AgendaItemCard
      title={option.question_full_text || option.question_text}
      score={(option.score || option.score === 0) && `${option.score}%`}
      onClick={addEngagementSurveyQuestionToAgenda}
      isDisabled={isDisabled}
    />
  );
};

const mapStateToProps = (state, props) => ({
  isDisabled: props.isDisabled || engagementQuestionOptionIsSelected(state, props.option)
});

function transformOption(option) {
  if (!option.score && !option.score_type) {
    return Object.assign({}, option, {question_text: option.question_full_text});
  }
  return option;
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addEngagementSurveyQuestionToAgenda: () => dispatch(addEngagementSurveyQuestionToAgendaAndToast(
      ownProps.agendaId,
      transformOption(ownProps.option)
    )),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectableEngagementSurveyQuestionCard);
