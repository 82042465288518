import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { CREATE_ENPS_SCORE } from '^/components/dashboards/actions';
import FieldEnpsSlider from '^/components/forms/FieldEnpsSlider';

const EnpsScoreForm = ({
  handleSubmit,
  isDisabled
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <p className="font-size-16 margin-bottom-base">
        On a scale of 0-10 (10 being the highest),
        how likely would you be to recommend working here
        to a friend or family member?
      </p>
      <FieldEnpsSlider name="score" />
      <div className="full-width text-center">
        <button
          className="enps-modal-button"
          type="submit"
          disabled={isDisabled}
        >
          Update
        </button>
      </div>
    </form>
  );
};

const FormifiedEnpsScoreForm = reduxForm({
  form: 'updated-enps-score-form',
  initialValues: {
    score: 0
  }
})(EnpsScoreForm);

export function mapStateToProps(state) {
  return {
    isDisabled: isPending(state.responses, CREATE_ENPS_SCORE)
  };
}

export default connect(mapStateToProps)(FormifiedEnpsScoreForm);
