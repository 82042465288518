import { COLLAPSE_RECOMMENDATION, EXPAND_RECOMMENDATION } from './actions';
import { Map } from 'immutable';

export function expandedRecommendations(state = Map(), action) {
  switch (action.type) {
    case EXPAND_RECOMMENDATION:
      return state.set(action.payload.recommendationId, true);
    case COLLAPSE_RECOMMENDATION:
      return state.set(action.payload.recommendationId, false);
    default:
      return state;
  }
}
