import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { setSelectedFilter } from '^/actions/actions';
import { connect } from 'react-redux';
import { ALL } from '^/consts/forms';
import classnames from 'classnames';

export const AdminListFilter = ({loading, filter, onChange, keyName, selectedValue, setFilter }) => {
  const selectId = `list-filter-${filter.get('filter_key')}`;
  return (
    <div className="admin-list-filter">
      <form>
        <label
          htmlFor={selectId}
          className="label-inline"
        >
          {filter.get('display_name')}
        </label>
        <select
          id={selectId}
          defaultValue={selectedValue || ALL}
          onChange={(event) => {
            setFilter(keyName, filter.get('filter_key'), event.target.value);
            onChange(filter.get('filter_key'), event.target.value);
          }}
          className={classnames(filter.get('narrow') ? 'filter-narrow' : null)}
          disabled={loading}>
            {!filter.get('no_all_option', false) && (
              <option value={ALL}>All {filter.get('display_name').toLowerCase()}</option>
            )}
            {filter.get('options').map((key) => (
              <option key={key.get(0)} value={key.get(0)}>{key.get(1)}</option>
            ))}
        </select>
      </form>
    </div>
  );
};

export function mapStateToProps(state, props) {
  return {
    selectedValue: state.ui.getIn(['filters', props.keyName, props.filter.get('filter_key')]),
  };
}

AdminListFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  filter: ImmutablePropTypes.map.isRequired,
  loading: PropTypes.bool,
};

export default connect(mapStateToProps, { setFilter: setSelectedFilter })(AdminListFilter);
