import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { unpublishCustomReportAndCloseModal, UNPUBLISH_CUSTOM_REPORT } from '^/components/custom-reports/actions';
import { isEmployeeBubbleUser, isManagerUser } from '^/utils/permissions';

const UnpublishConfirmModalButtons = ({isUnpublishing, submit, close}) => (
  <div className="margin-top-xx-large text-align-right">
    <button
      className="pink-button"
      onClick={submit}
      disabled={isUnpublishing}
    >
      Unpublish
    </button>
    <button
      className="pink-border-button margin-left-x-large"
      onClick={close}
      disabled={isUnpublishing}
    >
      Cancel
    </button>
  </div>
);

export const UnpublishConfirmModal = ({
  close,
  submit,
  isUnpublishing,
  isEmployee,
  isManager,
}) => {

  let message = `
    Unpublish this report from individual, team, and executive dashboards.
    The report will no longer be displayed in the \'Trend analysis\' section of the homepage.
  `;
  if (isManager) {
    message = `
      Unpublish this report from individual dashboards and your team dashboard.
      The report will no longer be displayed in the \'Trend analysis\' section of the homepage.
    `;
  } else if (isEmployee) {
    message = `
      Unpublish this report from your own dashboard.
      The report will no longer be displayed in the \'Trend analysis\' section of the homepage.
    `;
  };
  return (
    <div className="modal-body">
      <p className="font-size-18 text-align-center">
        {message}
      </p>
      <UnpublishConfirmModalButtons isUnpublishing={isUnpublishing} submit={submit} close={close} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isUnpublishing: isPending(state.responses, UNPUBLISH_CUSTOM_REPORT),
    isEmployee: isEmployeeBubbleUser(state.loggedInUser),
    isManager: isManagerUser(state.loggedInUser),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    close: () => dispatch(closeModal()),
    submit: () => dispatch(unpublishCustomReportAndCloseModal(props.reportId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnpublishConfirmModal);
