import { Map } from 'immutable';

export const ACTION_TYPE = {
  TASK: 'TASK',
  COURSE: 'COURSE',
  RESOURCE: 'RESOURCE'
};

export const ACTION_TYPE_MAPPING = {
  [ACTION_TYPE.TASK]: 'Task',
  [ACTION_TYPE.COURSE]: 'Course',
  [ACTION_TYPE.RESOURCE]: 'Resource',
};

export const ACTION_TYPE_TO_ICON_MAPPING = {
  [ACTION_TYPE.TASK]: ['far', 'fa-list'],
  [ACTION_TYPE.COURSE]: ['far', 'fa-phone-laptop'],
  [ACTION_TYPE.RESOURCE]: ['far', 'fa-books'],
};

export const ACTION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

export const ACTION_STATUS_MAPPING = Map({
  [ACTION_STATUS.NOT_STARTED]: 'Not started',
  [ACTION_STATUS.IN_PROGRESS]: 'In progress',
  [ACTION_STATUS.COMPLETED]: 'Completed',
});

export const ACTION_STATUS_OPTIONS = [
  {
    value: ACTION_STATUS.NOT_STARTED,
    label: 'Not started',
  },
  {
    value: ACTION_STATUS.IN_PROGRESS,
    label: 'In progress',
  },
  {
    value: ACTION_STATUS.COMPLETED,
    label: 'Completed',
  },
];

export const ACTION_FIELD = {
  DUE_DATE: 'due_date',
  STATUS: 'status',
  OWNER: 'owner',
  DESCRIPTION: 'description',
  TITLE: 'title'
};

export const AREA_PREFIX_ENGAGEMENT = 'Engagement';
export const AREA_PREFIX_MENTAL_HEALTH = 'Mental Health';
export const AREA_PREFIX_CULTURE_AND_DIVERSITY = 'Diversity, Equality, Equity & Inclusion';
