import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';  // eslint-disable-line no-unused-vars

import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';
import { SURVEY_STATES_DISPLAY } from '^/components/survey/constants';
import { FORMAT_DATE } from '^/utils';
import { userMeetsRequiredRole } from '^/utils/permissions';
import roles from '^/roles';
import { rolesDisplay } from '../../roles';

export const getTeamReviewListConfig = (performanceReviewTerm, loggedInUser) => ([
  {
    name: 'name',
    display_name: `${performanceReviewTerm} name`,
    sortable: true,
  },
  {
    name: 'auto_start_date',
    display_name: 'Start date',
    type: 'custom',
    customItemHandler: (response) => moment(response.date_started).format(FORMAT_DATE),
    sortable: true,
  },
  {
    name: 'date_closed_or_will_close',
    display_name: 'End date',
    type: 'custom',
    customItemHandler: (response) => moment(response.date_closed_or_will_close).format(FORMAT_DATE),
    sortable: true,
  },
  {
    name: 'state',
    display_name: 'Status',
    type: 'custom',
    customItemHandler: (survey) => SURVEY_STATES_DISPLAY[survey.state] || SURVEY_STATES_DISPLAY.CLOSED,
    sortable: true,
  },
  {
    name: 'completed',
    display_name: 'Completed',
    type: 'custom',
    customItemHandler: (survey) => `${survey.survey_members_completed} of ${survey.survey_members_total}`,
    sortable: false,
  },
  {
    name: 'auto_nudge_enabled',
    display_name: 'Auto nudge',
    type: 'custom',
    customItemHandler: (survey) => (
      <i
        title="1"
        className={classNames('far', {
          'fa-check': survey.auto_nudge_enabled,
          'fa-times': !survey.auto_nudge_enabled,
          'ico-check': survey.auto_nudge_enabled,
          'ico-times': !survey.auto_nudge_enabled
        })}
        aria-hidden={survey.auto_nudge_enabled}
      ></i>
    ),
    sortable: true,
  },
  {
    name: 'view_responses',
    display_name: 'View responses',
    type: 'custom',
    customItemHandler: (survey) => (
      <a href={`/reviews/${survey.id}/status/`} className="btn-icon orange margin-none text-center">
        <i className="far fa-info margin-none" aria-hidden="true"></i>
      </a>
    ),
    sortable: false,
  },
  {
    name: 'settings',
    display_name: 'Settings',
    type: 'custom',
    customItemHandler: (survey) => {
      const canAccessSettings = Boolean(
        (
          userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN) &&
          loggedInUser.get('type') !== roles.EXECUTIVE
        ) || loggedInUser.get('id') === survey.created_by
      );
      const isDisabled = !canAccessSettings;
      const title = isDisabled ? 'You are not allowed to access the settings this survey.' : 'Settings';
      const href = isDisabled ? undefined : `/reviews/${survey.id}/settings/`;
      const className = classNames('btn-icon orange margin-none text-center', { disabled: isDisabled });
      return (
        <a className={className} title={title} href={href}>
          <i className="far fa-cog margin-none" aria-hidden="true"></i>
        </a>
      );
    },
    sortable: false,
  },
]);

export const individualReviewsListConfig = (performanceReviewTerm, organisationTeamFilterTerm) => ([
  {
    name: 'first_name',
    display_name: 'Name/Email',
    type: 'custom',
    customItemHandler: user => (
      <span>
        <a className="display-block" href={`/reviews/personal-reviews/${user.id}`}>{user.full_name}</a>
        <span className="display-block text-help">{user.email}</span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'type',
    display_name: 'Role',
    type: 'custom',
    customItemHandler: user => rolesDisplay[user.type],
    sortable: true,
  },
  {
    name: 'team_member__team__name',
    display_name: `${organisationTeamFilterTerm}/Reports to`,
    type: 'custom',
    customItemHandler: user => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.team)}>{user.team}</CellContentOrEmpty>
        <span className="display-block text-help">
          <span>{user.reports_to}</span>
          {user.remaining_managers > 0 && (
            <span> + {user.remaining_managers} more</span>
          )}
        </span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'latest_review',
    display_name: 'Latest review',
    type: 'custom',
    customItemHandler: user => (
      <a
        className={classNames('btn hollow', user.latest_review ? 'orange' : 'disabled')}
        href={user.latest_review ? `${user.latest_review}?is_individual_list=true` : undefined}
        disabled={!user.latest_review}
      >
        Report
      </a>
    ),
  },
  {
    name: '',
    display_name: `Start ${performanceReviewTerm}`,
    type: 'custom',
    customItemHandler: user => (
      <a
        className="btn hollow orange"
        href={`/reviews/send-performance-review-survey/${user.id}`}
      >
        Send
      </a>
    ),
  }
]);

export const personalReviewListConfig = (performanceReviewTerm) => ([
  {
    name: 'survey__sent_by__first_name',
    display_name: 'Requested by (Name/Email)',
    type: 'custom',
    customItemHandler: (response) => (
      <span>
        <span className="display-block">{response.requested_by_name}</span>
        <span className="display-block text-help">
          {response.requested_by_email}
        </span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'survey__name',
    display_name: `${performanceReviewTerm} name`,
    type: 'custom',
    customItemHandler: (response) => response.title,
  },
  {
    name: 'survey__date_opened',
    display_name: 'Date',
    type: 'custom',
    customItemHandler: (response) => moment(response.date).format(FORMAT_DATE),
    sortable: true,
  },
  {
    name: 'complete',
    display_name: 'Status',
    type: 'custom',
    customItemHandler: (response) => (
      <span>
        <span className="display-block">{response.status}</span>
        {response.complete && (
          <span className="display-block text-help">
            {moment(response.complete).format(FORMAT_DATE)}
          </span>
        )}
      </span>
    ),
    sortable: true,
  },
  {
    name: 'actions',
    display_name: 'Actions',
    type: 'custom',
    customItemHandler: (response) => {
      if (response.report_url) {
        return (
          <a
            className="btn orange hollow"
            href={`${response.report_url}?is_individual_profile=true`}
          >
            Report
          </a>
        );
      }
      if (response.questionnaire_url) {
        return (
          <a
            className="btn orange hollow"
            href={`${response.questionnaire_url}`}
          >
            Complete
          </a>
        );
      }
      return '--';
    },
    sortable: false,
  },
]);
