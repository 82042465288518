import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';
import { queueUserExportAndRedirectToPoll, QUEUE_USER_EXPORT } from './actions';
import { isPending } from '@dabapps/redux-requests';


export class QueueExportButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.queueExport = this.queueExport.bind(this);
  }

  queueExport() {
    this.props.queueUserExportAndRedirectToPoll();
  }

  render() {
    return (
      <button
        onClick={this.queueExport}
        disabled={this.props.isLoading}
        className="btn middle orange hollow margin-right-base"
      >
        {this.props.isLoading ? 'Loading...' : 'Export all'}
      </button>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses, QUEUE_USER_EXPORT)
  };
}

export default connect(mapStateToProps, {
  queueUserExportAndRedirectToPoll
})(QueueExportButton);
