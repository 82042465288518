export const PEER_FEEDBACK_TYPES = {
  RECOGNITION: 'RECOGNITION',
  SUGGESTIONS: 'SUGGESTIONS',
  GENERAL: 'GENERAL',
};

export const feedbackTypeMessageMap = {
  [PEER_FEEDBACK_TYPES.RECOGNITION]: 'What has this person done well? What impact has this made?',
  [PEER_FEEDBACK_TYPES.SUGGESTIONS]: 'How can this person improve?',
  [PEER_FEEDBACK_TYPES.GENERAL]: 'Please enter your general feedback.'
};

export const feedbackTypeLabelMap = {
  [PEER_FEEDBACK_TYPES.RECOGNITION]: 'Recognition',
  [PEER_FEEDBACK_TYPES.SUGGESTIONS]: 'Suggestions',
  [PEER_FEEDBACK_TYPES.GENERAL]: 'General'
};

export const PEER_FEEDBACK_TEAM_LIST_URL = 'people/peer-feedback-team-list';
export const PEER_FEEDBACK_INDIVIDUAL_LIST_URL = 'people/peer-feedback-individual-list';

export const TEAM_LIST_ITEM_HEADINGS = ['Regarding', 'Feedback from', 'Type', 'Date given', 'Due date'];
export const TEAM_LIST_ITEM_FIELDS = ['user', 'created_by', 'type', 'message_modified', 'due_date'];

export const INDIVIDUAL_LIST_ITEM_HEADINGS = ['Feedback from', 'Title', 'Type', 'Date given'];
export const INDIVIDUAL_LIST_ITEM_FIELDS = ['created_by', 'title', 'type', 'message_modified'];

export const LIST_OPTIONS = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
};

export const CELL_WIDTH_CLASS_NAMES = {
  [LIST_OPTIONS.INDIVIDUAL]: [
    'width-25-percent',
    'width-40-percent',
    'width-15-percent',
    'width-15-percent'
  ],
  [LIST_OPTIONS.TEAM]: [
    'width-25-percent',
    'width-25-percent',
    'width-15-percent',
    'width-15-percent',
    'width-15-percent',
  ],
};

export const REGARDING_OPTIONS = {
  ME: 'me',
  OTHERS: 'others'
};
