import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import PureComponent from '^/components/PureComponent';
import {
  getAction,
  updateActionDescription,
  deleteAction,
} from '^/actions/actions';
import { Map } from 'immutable';
import Linkify from 'linkifyjs/react';
import moment from 'moment';

import PrivateIcon from '^/components/common/PrivateIcon';
import ActionStatus from '^/components/goals/ActionStatus';
import { FORMAT_DATE } from '../../utils';
import ActionDescriptionForm from './ActionDescriptionForm';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  SpacedGroup,
  ModalCloseIcon,
  ModalRenderer,
  Button,
} from '@dabapps/roe';
import ActionLinkedResource from './ActionLinkedResource';
import ActionTypeIcon from './ActionTypeIcon';


const ORIGIN_GOAL = 'goal';

export const AnalysisLinkOrSurveyText = (props) => {
  const action = props.action;

  if (action.get('survey') && action.get('analysis_url')) {
    return <a href={action.get('analysis_url')}>{action.get('survey')}</a>;
  }

  if (action.get('survey')) {
    return <span>{action.get('survey')}</span>;
  }

  return <span>n/a</span>;
};

export class ActionDetail extends PureComponent {
  componentWillMount() {
    this.props.getAction(this.props.actionId);
    this.state = {
      showDescriptionForm: false,
      showDeleteModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.hasUpdated && nextProps.hasUpdated) {
      this.toggleShowDescriptionForm();
    }
  }

  toggleShowDescriptionForm() {
    this.setState({
      showDescriptionForm: !this.state.showDescriptionForm,
    });
  }

  handleSubmit(data) {
    this.props.updateActionDescription(this.props.actionId, data);
  }

  handleDelete() {
    this.props.deleteAction(this.props.actionId).then(() => {
      if (this.props.origin === ORIGIN_GOAL && this.props.action.get('goal')) {
        window.location.href = `/goals/${this.props.action.getIn([
          'goal',
          'id',
        ])}/`;
      } else {
        window.location.href = `/goals/actions/`;
      }
    });
  }

  openDeleteModal(e) {
    e.stopPropagation();
    this.setState({
      showDeleteModal: true,
    });
  }

  closeDeleteModal() {
    this.setState({
      showDeleteModal: false,
    });
  }

  getBackLinkAndText() {
    const action = this.props.action;

    if (this.props.origin === ORIGIN_GOAL && action.get('goal')) {
      return [
        `/goals/${action.getIn(['goal', 'id'])}/`,
        '< Back to goal',
      ];
    }
    return ['/goals/actions/', '< Back to actions'];
  }

  render() {
    const { action, isLoading } = this.props;
    const image =
      action.getIn(['course_hyperlink', 'image']) ||
      action.getIn(['resource_hyperlink', 'image']);

    const courseOrResourceUrl =
      action.getIn(['course_hyperlink', 'url']) ||
      action.getIn(['resource_hyperlink', 'url']);

    const backLinkAndText = this.getBackLinkAndText();

    return (
      <div className="action-detail">
        {this.state.showDeleteModal && (
          <ModalRenderer
            modals={[
              <Modal onClickOutside={() => this.closeDeleteModal()}>
                <ModalHeader>
                  <ModalCloseIcon onClick={() => this.closeDeleteModal()}>
                    <span className="far fa-times" />
                  </ModalCloseIcon>
                  <h3>Delete Action</h3>
                </ModalHeader>
                <ModalBody>
                  Are you sure you'd like to delete this action?
                </ModalBody>
                <ModalFooter>
                  <SpacedGroup block className="margin-vertical-base">
                    <Button
                      className="btn gray middle"
                      onClick={() => this.closeDeleteModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn danger middle"
                      onClick={() => this.handleDelete()}
                    >
                      Delete
                    </Button>
                  </SpacedGroup>
                </ModalFooter>
              </Modal>,
            ]}
          />
        )}

        <div className="row">
          <div className="col-8">
            <div className="margin-bottom-large">
              <div>
                <h4 className="margin-bottom-small">{action.get('title')}</h4>
                <p className="margin-bottom-large text-gray">
                  <ActionTypeIcon actionType={action.get('type')} />
                </p>
              </div>
              <div className="action-description">
                {this.state.showDescriptionForm ? (
                  <ActionDescriptionForm
                    isLoading={isLoading}
                    initialValues={{ description: action.get('description') }}
                    onSubmit={(data) => this.handleSubmit(data)}
                  />
                ) : (
                  <div className="clearfix">
                    <ActionLinkedResource action={action} />
                    {image && (
                      <a href={courseOrResourceUrl} target="_blank">
                        <img
                          className="pull-left margin-right-large margin-bottom-large"
                          src={image}
                        ></img>
                      </a>
                    )}
                    <p>
                      <Linkify>{action.get('description')}</Linkify>
                    </p>
                  </div>
                )}

                {!this.state.showDescriptionForm && !this.props.canUserEdit && (
                  <button
                    onClick={() => this.toggleShowDescriptionForm()}
                    className="btn middle orange"
                  >
                    Edit Description
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="row padding-bottom-large">
              <div className="col-6">
              {this.props.canUserEdit && (
                <a
                href={`/goals/actions/${action.get('id')}/edit/${
                  window.location.search
                }`}
                  className="force-align-middle btn orange middle display-block"
                >
                  Edit
                </a>
              )}
              </div>
              <div className="col-6">
              {this.props.canUserDelete && (
                <a
                  onClick={(e) => this.openDeleteModal(e)}
                  className="force-align-middle btn danger middle display-block"
                >
                  Delete
                </a>
              )}
              </div>
            </div>
            <div className="row padding-top-large padding-bottom-base">
              <div className="col-2 text-grey-darker font-size-14">
                Status
              </div>
              <div className="col-10 font-size-10">
                <ActionStatus action={action} solid />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
            <div className="row padding-top-base padding-bottom-base">
              <div className="col-2 text-grey-darker font-size-14">
                Survey
              </div>
              <div className="col-10 font-size-14">
                <strong>
                  {action.get('survey')}
                </strong>
              </div>
            </div>
            <div className="row padding-top-base padding-bottom-base">
              <div className="col-2 text-grey-darker font-size-14">
                Goal
              </div>
              <div className="col-10 font-size-14">
                <strong>
                  {action.get('goal') ? (
                    <a
                      href={`/goals/${action.getIn(['goal', 'id'])}/`}
                      target="_blank"
                    >
                      {action.getIn(['goal', 'name'])}
                    </a>
                  ) : (
                    '-'
                  )}
                </strong>
              </div>
            </div>
            <div className="row padding-top-base padding-bottom-base">
              <div className="col-2 text-grey-darker font-size-14">
                Area
              </div>
              <div className="col-10 font-size-14">
                <strong>
                  {action.get('area')}
                </strong>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
            <div className="row padding-top-base padding-bottom-base">
              <div className="col-2 text-grey-darker font-size-14">
                Owner
              </div>
              <div className="col-10 font-size-14">
                <strong>{action.getIn(['owner', 'label'])}</strong>{' '}
                  {action.get('is_confidential') && (
                    <PrivateIcon itemName="action" />
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
            <div className="row padding-top-base padding-bottom-base">
              <div className="col-2 text-grey-darker font-size-14">
                Due
              </div>
              <div className="col-10 font-size-14">
                <strong>
                  {moment(action.get('due_date')).format(FORMAT_DATE)}
                </strong>
              </div>
            </div>
            <div className="row padding-top-base padding-bottom-large">
              <div className="col-2 text-grey-darker font-size-14">
                Created
              </div>
              <div className="col-10 font-size-14">
                <strong>
                {moment(action.get('created_date')).format(FORMAT_DATE)}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div className="padding-top-large padding-bottom-large margin-top-large">
          <hr />
          <a className="force-align-middle" href={backLinkAndText[0]}>
            <strong>{backLinkAndText[1]}</strong>
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    action: state.actionReducer.get('action', Map()),
    isLoading: state.actionReducer.get('isLoading', false),
    hasUpdated: state.actionReducer.get('hasUpdated', false),
    isDeleted: state.actionReducer.get('isDeleted', false),
  };
}

export default connect(mapStateToProps, {
  getAction,
  updateActionDescription,
  deleteAction,
})(ActionDetail);
