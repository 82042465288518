import { ModalBody, ModalFooter, SpacedGroup, Button, Row, Column } from '@dabapps/roe';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, change } from 'redux-form';

import { resetResponse } from '^/actions/actions';
import { submitResourceCloseModalAndReload } from '^/actions/actionSequences';
import { loadItem } from '^/actions/items';
import { closeModal } from '^/actions/modals';
import Field from '^/components/forms/Field';
import InlineRadioButtons from '^/components/forms/InlineRadioButtons';
import Loading from '^/components/Loading';
import {
  MANAGE_RESOURCES_PATH,
  USER_TYPES_FOR,
  USER_TYPE_FOR_DISPLAY_MAPPING
} from '^/components/resources/constants';
import {
  AREA_PREFIX_MENTAL_HEALTH,
  AREA_PREFIX_CULTURE_AND_DIVERSITY
} from '^/consts/actions';
import { isPending } from '^/consts/responseStates';
import ImageUpload from '^/components/forms/image-upload/ImageUpload';
import { uploadResourceImage } from './actions';
import store from '^/store';

export const FORM_NAME = 'EditOrganisationResourceForm';

const isMentalHealth = area => (
  area.indexOf(AREA_PREFIX_MENTAL_HEALTH) !== -1
);

const isCultureAndDiversity = area => (
  area.indexOf(AREA_PREFIX_CULTURE_AND_DIVERSITY) !== -1
);

const isMentalHealthOrCultureAndDiversity = area => (
  isMentalHealth(area) ||
  isCultureAndDiversity(area)
);

const resourceForRadioButtons = [
  {
    value: USER_TYPES_FOR.EMPLOYEES,
    label: USER_TYPE_FOR_DISPLAY_MAPPING[USER_TYPES_FOR.EMPLOYEES]
  },
  {
    value: USER_TYPES_FOR.MANAGERS,
    label: USER_TYPE_FOR_DISPLAY_MAPPING[USER_TYPES_FOR.MANAGERS]
  },
  {
    value: USER_TYPES_FOR.MANAGERS_AND_EMPLOYEES,
    label: 'All'
  }
];

const defaultInitialValues = {
  contact_details: {}
};

const ResourceForField = props => <InlineRadioButtons {...props} radioButtons={resourceForRadioButtons}/>;
const WrappedImageUpload = (props) => (
  <ImageUpload
    value={props.input.value}
    onChange={props.input.onChange}
    uploadImage={(file) => store.dispatch(uploadResourceImage([file]))}
    id="resource-image-upload"
  />
);
const ResourceDescriptionField = props => (
  <textarea
    {...props.input}
    id={props.id}
    placeholder={props.placeholder}
    maxLength="255"
  >
  </textarea>
);

export class CreateEditResourceModal extends React.PureComponent {

  componentDidMount() {
    this.props.resetResponse();
    if (this.props.resourceId) {
      this.props.loadResource();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.selectedArea !== this.props.selectedArea) {
      if (isMentalHealth(this.props.selectedArea)) {
        this.props.changeForUserTypeValue(USER_TYPES_FOR.EMPLOYEES);
      }
      if (isCultureAndDiversity(this.props.selectedArea)) {
        this.props.changeForUserTypeValue(USER_TYPES_FOR.MANAGERS);
      }
    }
  }

  render() {
    const { cancel, handleSubmit, hasPendingRequests, response, areaOptions } = this.props;

    if (hasPendingRequests) {
      return (
        <ModalBody>
          <Loading />
        </ModalBody>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Column xs={12} md={6}>
              <div className="padding-horizontal-large padding-vertical-base">
                <p><strong>Details</strong></p>
                <Field
                  name="display_name"
                  label="Display Name"
                  placeholder="Display Name..."
                  response={response}
                />
                <Field
                  name="description"
                  label="Description"
                  component={ResourceDescriptionField}
                  placeholder="Description..."
                  response={response}
                />
                <Field
                  component={WrappedImageUpload}
                  name="image"
                  label="Image (Optional) - recommended size 400 x 200"
                  helpText="If you don't add an image we'll use your uploaded logo."
                  uploadResourceImage={this.props.uploadResourceImage}
                />
                <Field
                  name="url"
                  label="URL"
                  placeholder="URL..."
                  helpText="You must include https:// or http:// at the beginning of your URL."
                  type="url"
                  response={response}
                />
                <Field
                  name="for_user_type"
                  label="Resource for"
                  component={ResourceForField}
                  response={response}
                  disabled={this.props.disabledForUserType}
                />
                <Field
                  name="area"
                  label="Low-scoring Category"
                  component="select"
                  options={areaOptions}
                  response={response}
                  blankValueLabel="None selected"
                />
              </div>
            </Column>
            <Column xs={12} md={6}>
              <div className="padding-horizontal-large padding-vertical-base">
                <Row>
                  <Column xs={3}>
                    <p><strong>Contact</strong></p>
                  </Column>
                  <Column xs={9}>
                    <p className="text-help">
                      This information will be shown along with the recommended resource -{' '}
                      useful if people have questions
                    </p>
                  </Column>
                </Row>
                <Field
                  name="contact_details.name"
                  label="Name"
                  placeholder="Optional"
                  response={response}
                />
                <Field
                  name="contact_details.role"
                  label="Role"
                  placeholder="Optional"
                  response={response}
                />
                <Field
                  name="contact_details.email"
                  label="Email"
                  placeholder="Optional"
                  response={response}
                />
                <Field
                  name="contact_details.telephone_no"
                  label="Telephone"
                  placeholder="Optional"
                  response={response}
                />
                <Field
                  name="contact_details.advice"
                  label="Advice"
                  placeholder="Optional"
                  response={response}
                />
              </div>
            </Column>
          </Row>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button className="btn middle orange" type="submit" disabled={hasPendingRequests}>Save</Button>
            <Button className="btn middle orange hollow" onClick={cancel}>Cancel</Button>
          </SpacedGroup>
        </ModalFooter>
      </form>
    );
  }
}

export const FormifiedCreateEditResourceModal = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(CreateEditResourceModal);

export { CreateEditResourceModal as UnconnectedCreateEditResourceModel };

export function mapStateToProps(state, props) {
  const updateResponse = state.legacyResponses.getIn(['updateItem', MANAGE_RESOURCES_PATH]);
  const createResponse = state.legacyResponses.getIn(['createItem', MANAGE_RESOURCES_PATH]);
  const resource = (state.items.get(MANAGE_RESOURCES_PATH) || Map());
  const selectedArea = formValueSelector(FORM_NAME)(state, 'area') || '';
  return {
    hasPendingRequests:
      isPending(state.legacyResponses.getIn(['loadItem', MANAGE_RESOURCES_PATH])) ||
      isPending(updateResponse) ||
      isPending(createResponse),
    initialValues: props.resourceId ? resource.toJS() : defaultInitialValues,
    response: createResponse || updateResponse,
    disabledForUserType: isMentalHealthOrCultureAndDiversity(selectedArea),
    selectedArea
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    cancel: event => {
      event.preventDefault();
      dispatch(closeModal());
    },
    onSubmit: data =>
      dispatch(submitResourceCloseModalAndReload(
        data,
        props.resourceId,
        props.reloadPage
      )
    ),
    loadResource: () => dispatch(loadItem(MANAGE_RESOURCES_PATH, props.resourceId)),
    resetResponse: () => {
      dispatch(resetResponse('createItem'));
      dispatch(resetResponse('updateItem'));
    },
    changeForUserTypeValue: value => dispatch(change(FORM_NAME, 'for_user_type', value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormifiedCreateEditResourceModal);
