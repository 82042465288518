import { isPending, hasFailed } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';

import { openMediumModal } from '^/actions/modals';
import {
  getEmployeeCompleteFeedbackRequests,
  GET_EMPLOYEE_COMPLETE_FEEDBACK_REQUESTS
} from '^/components/dashboards/actions';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';
import RequestedFeedbackModal from '../peer-feedback/RequestedFeedbackModal';

export const NUMBER_OF_RENDERED_FEEDBACK_REQUESTS = 3;

export const GiveFeedbackButton = ({openRequestedFeedbackModal}) => {
  return (
    <button
      className="pink-button font-size-14 padding-vertical-small"
      onClick={openRequestedFeedbackModal}
    >
      Give feedback
    </button>
  );
};

const openModalFunction = requestId => openMediumModal({
  titleComponent: (
    <h1 className="font-color-main font-size-38 font-family-effra-bold margin-bottom-none padding-left-small">
      Give feedback
    </h1>
  ),
  body: (
    <RequestedFeedbackModal feedbackRequestId={requestId} />
  )
});

const mapDispatchToPropsButton = (dispatch, ownProps) => {
  return {
    openRequestedFeedbackModal: () => dispatch(openModalFunction(ownProps.requestId))
  };
};

const ConnectedGiveFeedbackButton = connect(undefined, mapDispatchToPropsButton)(GiveFeedbackButton);

export class EmployeeCompleteFeedbackWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numberOfItemsToShow: NUMBER_OF_RENDERED_FEEDBACK_REQUESTS,
      showMoreItems: false,
    };
    this.toggleShowMoreItems = this.toggleShowMoreItems.bind(this);
  }

  componentDidMount() {
    this.props.getEmployeeCompleteFeedbackRequests();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.feedbackRequests.length !== this.props.feedbackRequests.length) {
      this.setState({
        numberOfItemsRemaining: Math.max(0, this.props.feedbackRequests.length - NUMBER_OF_RENDERED_FEEDBACK_REQUESTS),
      });
    }
    if (prevProps.feedbackRequests.length === 0 && this.props.feedbackRequests.length > 0) {
      const requestIdFromUrl = new URLSearchParams(window.location.search).get('feedback_request_id'); // eslint-disable-line no-undef
      if (requestIdFromUrl && !!this.props.feedbackRequests.find(
        request => request.id === requestIdFromUrl
      )) {
        this.props.openRequestedFeedbackModal(requestIdFromUrl);
      }
    }
  }

  toggleShowMoreItems() {
    this.setState({
      showMoreItems: !this.state.showMoreItems,
      numberOfItemsToShow: (
        this.state.showMoreItems ?
        NUMBER_OF_RENDERED_FEEDBACK_REQUESTS :
        this.props.feedbackRequests.length
      )
    });
  }

  renderFeedbackCompleteSection() {
    const {
      feedbackRequests,
      isLoading,
      hasFailedLoading
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    if (hasFailedLoading) {
      return <p>Failed to load feedback data.</p>;
    }

    return (
      <div className="margin-bottom-base">
        <div className="font-family-varela-round font-size-18 margin-bottom-base">
          Outstanding
        </div>

        {
          feedbackRequests.length === 0 ? (
            <p>No outstanding feedback requests.</p>
          ) :
            feedbackRequests.slice(0, this.state.numberOfItemsToShow).map(request => (
              <div
                className="widget-item-hollow margin-top-small flex-space-between align-items-center flex-wrap"
                key={request.id}
              >
                <div className="font-size-14 fwb width-30-percent text-overflow-ellipsis">
                  {request.feedback_for}
                </div>
                <div className="font-size-14 fwb">
                  Due: {request.deadline}
                </div>
                <div>
                  <ConnectedGiveFeedbackButton requestId={request.id} />
                </div>
              </div>
            )
            )
        }
      </div>
    );
  }

  render() {
    const showMoreText = `Show ${this.state.showMoreItems ? 'less' : `more (${this.state.numberOfItemsRemaining})`}`;
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="Feedback I've been asked to complete" image="/static/images/dashboards/feedback-love.png" />
        {this.renderFeedbackCompleteSection()}
        <div className="margin-top-auto text-align-center">
          {this.props.feedbackRequests.length > NUMBER_OF_RENDERED_FEEDBACK_REQUESTS && (
            <button
              className="link-color-text background-none underline padding-none font-size-14"
              onClick={this.toggleShowMoreItems}
            >
              { showMoreText }
            </button>
          )}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    feedbackRequests: state.dashboardFeedbackRequests,
    isLoading: isPending(state.responses, GET_EMPLOYEE_COMPLETE_FEEDBACK_REQUESTS),
    hasFailedLoading: hasFailed(state.responses, GET_EMPLOYEE_COMPLETE_FEEDBACK_REQUESTS),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getEmployeeCompleteFeedbackRequests: () => dispatch(getEmployeeCompleteFeedbackRequests()),
  openRequestedFeedbackModal: (requestId) => dispatch(openModalFunction(requestId))
});


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCompleteFeedbackWidget);
