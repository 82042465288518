import moment from 'moment';
import React from 'react';  //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { DatePickerField, FORM_NAME } from './CompareReportForm';

const StartDateField = props => (
  <DatePickerField
    input={props.input}
    label={props.label}
    maxDate={props.maxDate}
  />
);

const mapStateToProps = state => {
  const endDateValue = formValueSelector(FORM_NAME)(state, 'compare_to_end_date');
  return {
    maxDate: (endDateValue ? moment(endDateValue) : moment())
  };
};

export default connect(mapStateToProps)(StartDateField);
