import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';

import { GET_ADMIN_DASHBOARD_GOALS_DATA, getAdminDashboardGoalsData } from '^/components/dashboards/actions';
import {
  GOAL_STATUS,
  GOAL_STATUS_LABEL_MAP,
  GOAL_STATUS_COLOR_MAP
} from '^/components/goals/const';
import Loading from '^/components/Loading';
import { StatusCount } from '^/components/dashboards/StatusCount';
import WidgetTitle from '^/components/dashboards/WidgetTitle';

export class AdminGoalsWidget extends React.PureComponent {

  componentDidMount() {
    this.props.getData();
  }

  render() {

    const { data, isLoading } = this.props;

    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title="Goals"
          subtitle="In the last 6 months"
          image="/static/images/dashboards/goals.svg"
        />

        {isLoading && <Loading />}

        {data && (
          <div data-testid="goal-count-boxes">
            <div className="widget-item-hollow margin-bottom-large font-family-varela-round">
              <div className="font-size-38 font-family-varela-round margin-bottom-base">
                {data.get('goals_created_count')}
              </div>
              <p className="font-size-18 margin-bottom-none line-height-16px">
                Goals created
              </p>
            </div>
            <div className="display-flex flex-gap-xx-large margin-bottom-x-large">
              <StatusCount
                count={data.get('goals_completed_count')}
                status={GOAL_STATUS_LABEL_MAP[GOAL_STATUS.COMPLETED]}
                colour={GOAL_STATUS_COLOR_MAP[GOAL_STATUS.COMPLETED]}
              />
              <StatusCount
                count={data.get('goals_due_soon_count')}
                status={GOAL_STATUS_LABEL_MAP[GOAL_STATUS.DUE_SOON]}
                colour={GOAL_STATUS_COLOR_MAP[GOAL_STATUS.DUE_SOON]}
              />
              <StatusCount
                count={data.get('goals_overdue_count')}
                status={GOAL_STATUS_LABEL_MAP[GOAL_STATUS.OVERDUE]}
                colour={GOAL_STATUS_COLOR_MAP[GOAL_STATUS.OVERDUE]}
              />
            </div>
          </div>
        )}

        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/goals/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    data: state.adminDashboardGoalsData,
    isLoading: isPending(state.responses, GET_ADMIN_DASHBOARD_GOALS_DATA)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getData: () => dispatch(getAdminDashboardGoalsData())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminGoalsWidget);
