import React from 'react';  // eslint-disable-line no-unused-vars

const InlineRadioButtons = ({
  input: { value, onChange },
  radioButtons,
  helpText,
  disabled
}) => (
  <div className="input-line-height">
    {radioButtons.map(radioButton =>
      <label
        key={`radio-${radioButton.value}`}
        className="display-inline-block margin-right-large margin-bottom-none"
      >
        <input
          className="margin-right-base"
          type="radio"
          value={radioButton.value}
          checked={value === radioButton.value}
          onChange={() => onChange(radioButton.value)}
          disabled={disabled}
        />
        {radioButton.label}
      </label>
    )}
    {helpText && (
      <p className="text-gray">{helpText}</p>
    )}
  </div>
);

export default InlineRadioButtons;
