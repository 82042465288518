
// User role constants to match the backend
const roles = {
  EMPLOYEE: 'EMPLOYEE',
  EMPLOYEE_WITH_BUBBLE: 'EMPLOYEE_WITH_BUBBLE',
  MANAGER_USER: 'MANAGER_USER',
  MANAGER_USER_WITH_ADMIN: 'MANAGER_USER_WITH_ADMIN',
  EXECUTIVE: 'EXECUTIVE',
  PARTNER: 'PARTNER',
  SUPPORT_USER: 'SUPPORT_USER',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN'
};

export const rolesDisplay = {
  [roles.EMPLOYEE]: 'Employee',
  [roles.EMPLOYEE_WITH_BUBBLE]: 'Employee Bubble',
  [roles.MANAGER_USER]: 'Manager Bubble',
  [roles.MANAGER_USER_WITH_ADMIN]: 'Manager Bubble with Admin',
  [roles.EXECUTIVE]: 'Executive',
  [roles.PARTNER]: 'Partner User',
  [roles.SUPPORT_USER]: 'Support User',
  [roles.SYSTEM_ADMIN]: 'System Admin',
};

export const organisationLevelRoles = [
  roles.EMPLOYEE,
  roles.EMPLOYEE_WITH_BUBBLE,
  roles.MANAGER_USER,
  roles.MANAGER_USER_WITH_ADMIN,
  roles.EXECUTIVE,
];

export const bubbleRoles = [
  roles.EMPLOYEE_WITH_BUBBLE,
  roles.MANAGER_USER,
  roles.MANAGER_USER_WITH_ADMIN,
  roles.EXECUTIVE
];

export const organisationLevelRoleOptions = organisationLevelRoles.map(role =>
  ({ value: role, label: rolesDisplay[role] })
);

export const bubbleRoleOptions = bubbleRoles.map(role =>
  ({ value: role, label: rolesDisplay[role] })
);

export const ROLE_TYPE_LICENCES_USED_MAPPING = {
  [roles.EMPLOYEE]: 'employee_count',
  [roles.EMPLOYEE_WITH_BUBBLE]: 'employee_bubble_count',
  [roles.MANAGER_USER]: 'manager_count',
  [roles.MANAGER_USER_WITH_ADMIN]: 'manager_with_admin_count',
  [roles.EXECUTIVE]: 'executive_count',
};

export const ROLE_TYPE_LICENCES_MAX_MAPPING = {
  [roles.EMPLOYEE]: 'max_employees',
  [roles.EMPLOYEE_WITH_BUBBLE]: 'max_employee_bubbles',
  [roles.MANAGER_USER]: 'max_managers',
  [roles.MANAGER_USER_WITH_ADMIN]: 'max_managers_with_admin',
  [roles.EXECUTIVE]: 'max_executives',
};

export default roles;
