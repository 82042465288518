import React from 'react'; // eslint-disable-line no-unused-vars
import { Map } from 'immutable';

import { getOrganisationEmailTemplates } from '^/actions/actions';
import Loading from '^/components/Loading';
import PureComponent from '^/components/PureComponent';
import { isPending } from '^/consts/responseStates';
import { EMAIL_TEMPLATE_TYPES } from './constants';
import OrganisationCustomEmailTemplate from './OrganisationCustomEmailTemplate';
import JumpToEmailSection from './JumpToEmailSection';
import { connect } from 'react-redux';

export class OrganisationCustomEmailSettings extends PureComponent {
  componentDidMount () {
    this.props.getOrganisationEmailTemplates();
  }

  render () {
    return (
      <div>
        <h3 className="margin-bottom-small margin-top-base">Custom Email Settings</h3>
        <p>
          Standard emails are provided for each stage of the survey process, but you can enter your own versions here.
          Your version will then be used in all your Engagement and Pulse surveys,
          unless overridden on a survey-by-survey basis.
        </p>
        {this.props.isLoading ? (
          <Loading />
        ) : (
          <div>
            <JumpToEmailSection/>
            {Map(EMAIL_TEMPLATE_TYPES).map(templateType => (
              <OrganisationCustomEmailTemplate key={templateType} templateType={templateType} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

OrganisationCustomEmailSettings.propTypes = {
  getOrganisationEmailTemplates: React.PropTypes.func.isRequired,
  isLoading: React.PropTypes.bool,
};

function mapStateToProps (state) {
  return {
    isLoading: isPending(state.legacyResponses.get('getOrganisationEmailTemplates')),
  };
}

export default connect(mapStateToProps, { getOrganisationEmailTemplates })(OrganisationCustomEmailSettings);
