import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup } from '@dabapps/roe';
import { connect } from 'react-redux';
import { DELETE_OKR, deleteOkrAndToast } from '^/components/okrs/actions';
import { OKR_TYPE } from '^/components/okrs/constants';
import { closeModal } from '^/actions/modals';

export const DeleteOkrModal = ({
  okrType,
  deleteOkr,
  close,
  isDisabled
}) => {
  let message = 'Are you sure you want to delete this OKR?';
  let btnMsg = 'Delete OKR';
  if (okrType === OKR_TYPE.COMPANY) {
    message = 'Child OKRs are attached, are you sure you want to delete this Company Goal?';
    btnMsg = 'Delete Company Goal';
  }
  return (
    <div>
      <ModalBody>
        <p>{message}</p>
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <button
            className="button btn gray middle"
            onClick={close}
            disabled={isDisabled}
          >
            Cancel
          </button>
          <button
            className="button btn danger middle"
            onClick={deleteOkr}
            type="danger"
            disabled={isDisabled}
          >
            {btnMsg}
          </button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isDisabled: isPending(state.responses, DELETE_OKR),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    close: () => {
      dispatch(closeModal());
    },
    deleteOkr: () => {
      dispatch(deleteOkrAndToast(
        props.okrId,
        props.redirectOnDelete
      ));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteOkrModal);
