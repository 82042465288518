export const AGENDA_LIST_URL = 'agendas/list';
export const AGENDA_LIST_MANAGER = 'AGENDA_LIST_MANAGER';
export const AGENDA_LIST_EMPLOYEE = 'AGENDA_LIST_EMPLOYEE';

export const AgendaStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
};

export const AgendaStatusDisplayMap = {
  [AgendaStatus.PENDING]: 'Pending',
  [AgendaStatus.ACCEPTED]: 'Accepted',
  [AgendaStatus.DECLINED]: 'Declined',
};

export const ScoreType = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM'
};

export const AgendaGoalType = {
  MANAGER: 'MANAGER',
  EMPLOYEE: 'EMPLOYEE'
};

export const AgendaSectionType = {
  CUSTOM_ITEMS: 'CUSTOM_ITEMS',
  SHARED_NOTES: 'SHARED_NOTES',
  PERFORMANCE_REVIEW: 'PERFORMANCE_REVIEW',
  ENGAGEMENT_SURVEY: 'ENGAGEMENT_SURVEY',
  EMPLOYEE_GOALS: 'EMPLOYEE_GOALS',
  MANAGER_ONLY: 'MANAGER_ONLY',
  OKRS: 'OKRS',
  PEER_FEEDBACK: 'PEER_FEEDBACK'
};

export const SelectableTitleByType = {
  [AgendaSectionType.CUSTOM_ITEMS]: 'Talking points',
  [AgendaSectionType.ENGAGEMENT_SURVEY]: 'Engagement survey results',
  [AgendaSectionType.EMPLOYEE_GOALS]: 'Employee goals',
  [AgendaSectionType.OKRS]: 'OKRs',
  [AgendaSectionType.PEER_FEEDBACK]: 'Feedback',
};

export const AgendaSectionTypeTitleMap = {
  [AgendaSectionType.CUSTOM_ITEMS]: 'Custom items / talking points',
  [AgendaSectionType.SHARED_NOTES]: 'Shared notes',
  [AgendaSectionType.PERFORMANCE_REVIEW]: 'Performance review',
  [AgendaSectionType.ENGAGEMENT_SURVEY]: 'Engagement survey',
  [AgendaSectionType.EMPLOYEE_GOALS]: 'Employee goals',
  [AgendaSectionType.MANAGER_ONLY]: 'Manager only (hidden to employee)',
  [AgendaSectionType.OKRS]: 'OKRs',
  [AgendaSectionType.PEER_FEEDBACK]: 'Feedback'
};

export const EDIT_AGENDA_DETAILS = 'EDIT_AGENDA_DETAILS';
export const EDIT_SHARED_NOTES = 'EDIT_SHARED_NOTES';
export const EDIT_MANAGER_NOTES = 'EDIT_MANAGER_NOTES';

export const TALENT_SNAPSHOT_LIST_URL = 'agendas/talent-snapshot-list';
export const TALENT_SNAPSHOT_LIST = 'TALENT_SNAPSHOT_LIST';

export const TEMPLATE_TYPES = {
  ENGAGEMENT: 'ENGAGEMENT',
  PERFORMANCE: 'PERFORMANCE',
  GOALS_AND_OKRS: 'GOALS_AND_OKRS',
  MENTAL_HEALTH: 'MENTAL_HEALTH',
  CUSTOM: 'CUSTOM'
};

export const TEMPLATE_TYPE_DISPLAY_MAP = {
  [TEMPLATE_TYPES.ENGAGEMENT]: 'Engagement',
  [TEMPLATE_TYPES.PERFORMANCE]: 'Appraisal/Performance',
  [TEMPLATE_TYPES.GOALS_AND_OKRS]: 'Goal/OKR progress review',
  [TEMPLATE_TYPES.MENTAL_HEALTH]: 'Mental health/Wellbeing',
  [TEMPLATE_TYPES.CUSTOM]: 'Custom'
};

export const TEMPLATE_TYPE_IMAGE_MAP = {
  [TEMPLATE_TYPES.ENGAGEMENT]: '/static/images/agendas/template-engagement.svg',
  [TEMPLATE_TYPES.PERFORMANCE]: '/static/images/agendas/template-appraisal-performance.svg',
  [TEMPLATE_TYPES.GOALS_AND_OKRS]: '/static/images/agendas/template-goals-okrs.svg',
  [TEMPLATE_TYPES.MENTAL_HEALTH]: '/static/images/agendas/template-mental-health.svg',
  [TEMPLATE_TYPES.CUSTOM]: '/static/images/agendas/template-custom.svg'
};

export const AGENDA_PARTICIPANTS_LIST_URL = 'agendas/bulk-participants-list';
export const AGENDA_PARTICIPANTS_LIST = 'AGENDA_PARTICIPANTS_LIST';

export const ItemsNameMappedToSectionType = {
  [AgendaSectionType.PERFORMANCE_REVIEW]: 'review_questions',
  [AgendaSectionType.ENGAGEMENT_SURVEY]: 'engagement_survey_questions',
  [AgendaSectionType.EMPLOYEE_GOALS]: 'employee_goals',
  [AgendaSectionType.OKRS]: 'okrs',
  [AgendaSectionType.PEER_FEEDBACK]: 'peer_feedback',
};

export const CustomItemsNameMappedToSectionType = {
  [AgendaSectionType.CUSTOM_ITEMS]: 'custom_items',
  [AgendaSectionType.PERFORMANCE_REVIEW]: 'performance_review_comments',
  [AgendaSectionType.ENGAGEMENT_SURVEY]: 'engagement_survey_comments',
  [AgendaSectionType.EMPLOYEE_GOALS]: 'employee_goal_comments',
  [AgendaSectionType.OKRS]: 'okr_comments',
  [AgendaSectionType.PEER_FEEDBACK]: 'peer_feedback_comments',
};

export const AGENDA_LIST_BUTTON_DISABLED_TEXT = `Must be confirmed and have a manager assigned.
Template processing must be complete.`;
