import React from 'react'; // eslint-disable-line no-unused-vars
import { Field, reduxForm } from 'redux-form';

export function CreateAgendaGoalManagerForm({
  agendaId,
  handleSubmit,
  isDisabled
}) {
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor={`name-${agendaId}`} className="margin-top-x-large">
        Goal name
      </label>
      <Field
        name="name"
        id={`name-${agendaId}`}
        component="input"
        type="text"
        disabled={isDisabled}
        required
      />
      <label htmlFor={`description-${agendaId}`} className="margin-top-x-large">
        Goal description
      </label>
      <Field
        name="description"
        id={`description-${agendaId}`}
        component="textarea"
        disabled={isDisabled}
      />
      <button
        className="btn middle orange margin-top-x-large"
        disabled={isDisabled}
      >
        Save
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'CreateAgendaGoalManagerForm',
})(CreateAgendaGoalManagerForm);
