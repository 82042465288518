import React from 'react';
import { Row, Column } from '@dabapps/roe';

class SuggestedCourseResource extends React.PureComponent {
  render() {
    const { resource } = this.props;

    return (
      <Row key={resource.get('id')}>
        {resource.get('image') && (
          <Column md={6}>
            <img
              className="img-responsive"
              src={resource.get('image')}
            />
          </Column>
        )}
        <Column md={6}>
          <h6 className="font-weight-bold margin-bottom-base">{resource.get('display_name')}</h6>
          <p className="margin-bottom-none">{resource.get('description')}</p>
          {resource.get('contact_details') && (
            <p className="text-gray margin-bottom-none">
              {resource.get('contact_details')}
            </p>
          )}
          <p>
            <a
              className="link-orange font-weight-bold margin-top-x-large display-inline-block"
              target="_blank"
              href={resource.get('url')}
            >
              View <i className="far fa-external-link"></i>
            </a>
          </p>
        </Column>
      </Row>
    );
  }
}

export default SuggestedCourseResource;
