import classNames from 'classnames';
import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';

import { isEmployeeBubbleUser } from '^/utils/permissions';
import { toggleAddCustomReport } from '^/components/custom-reports/actions';
import CustomReportCard from '^/components/custom-reports/CustomReportCard';
import { REPORT_TYPES } from '^/components/custom-reports/constants';

export function AddReportSection(props) {
  return (
    <div className="will custom-report-container">
      <div className="custom-report-header">
        <i className="far fa-times icon" onClick={props.toggleAddCustomReport}></i>
        <h2 className={
          classNames(
            'font-color-black',
            'flex-center',
            'margin-top-xx-large',
            'font-size-38',
            'title',
            'font-family-effra-bold'
          )
        }>What data would you like to compare?</h2>
      </div>

      <div className="well padding-left-large padding-right-large margin-top-xx-large display-flex">
        <div className="display-flex flex-wrap flex-gap-x-large margin-auto-horizontal">
          <CustomReportCard
            name="Overall score over time"
            description="Track your average survey score over time."
            reportType={REPORT_TYPES.TREND_ANALYSIS_LINE}
            image="/static/images/custom-reports/overall_score.svg"
          />
          <CustomReportCard
            name="16 scores over time"
            description="Track 16 categories from the human givens framework over time."
            reportType={REPORT_TYPES.TREND_ANALYSIS_HEATMAP}
            image="/static/images/custom-reports/16_scores_heatmap.svg"
          />
          <CustomReportCard
            name="Internal benchmarking"
            description={
              props.isEmployee
                ? 'Compare your own results to the organisation average.'
                : 'Compare team results to the organisation average.'
            }
            reportType={REPORT_TYPES.INTERNAL_BENCHMARKING_HEATMAP}
            image="/static/images/custom-reports/internal_benchmarking.svg"
          />
          <CustomReportCard
            name="External benchmarking"
            description="Compare survey scores to other customers."
            reportType={REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP}
            image="/static/images/custom-reports/external_benchmarking.svg"
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isEmployee: isEmployeeBubbleUser(state.loggedInUser)
  };
}

export default connect(mapStateToProps, {
  toggleAddCustomReport
})(AddReportSection);
