import {
  ModalBody,
} from '@dabapps/roe';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import { createEnpsScoreCloseModalAndToast } from '^/components/dashboards/actions';
import EnpsScoreForm from '^/components/dashboards/EnpsScoreForm';

const EnpsScoreButton = ({ openEnpsScoreModal, buttonText }) => (
  <button
    className="widget-view-all-button"
    onClick={openEnpsScoreModal}
  >
    {buttonText}
  </button>
);

const mapDispatchToProps = dispatch => ({
  openEnpsScoreModal: () => dispatch(
    openSimpleModal({
      titleComponent: (
        <div className="display-flex justify-content-center margin-top-large">
          <h1 className="font-family-varela-round font-color-main font-size-48 fwb text-center margin-bottom-none">
            eNPS
          </h1>
          <div className="enps-info-icon">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="margin-top-base font-size-18 margin-left-small font-color-pink"
            />
          </div>
        </div>
      ),
      bodyClassName: 'padding-left-xxx-large padding-right-xxx-large',
      body: (
        <ModalBody>
          <EnpsScoreForm
            onSubmit={data => dispatch(
              createEnpsScoreCloseModalAndToast(data)
            )}
          />
        </ModalBody>
      ),
      closeButtonExtraClassName: 'color-text'
    })
  )
});

export default connect(undefined, mapDispatchToProps)(EnpsScoreButton);
