import classNames from 'classnames';
import { isPending } from '@dabapps/redux-requests';
import moment from 'moment';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { getEmployeeOneToOnes, GET_EMPLOYEE_ONE_TO_ONES} from '^/components/dashboards/actions';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';


export class EmployeeOneToOnes extends React.PureComponent {
  componentDidMount() {
    this.props.getEmployeeOneToOnes();
  }

  render() {
    const { completed, upcoming, isLoading } = this.props;
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="1:1 meetings" image="/static/images/dashboards/onetoones.svg" />
        {isLoading && <Loading />}
        <div className="font-family-varela-round font-size-18 margin-bottom-base">
          Upcoming
        </div>
        {!isLoading && upcoming.length > 0 ? upcoming.map(agenda => (
          <div className="widget-item-hollow margin-top-small" key={agenda.id}>
            <div className="font-size-14 fwb">
              {agenda.title}
            </div>
            <div className="flex-space-between margin-top-small font-size-13">
              <div className="text-gray">
                {agenda.scheduled_datetime ? moment(agenda.scheduled_datetime).format('DD-MM-YYYY, H:mm') : ''}
              </div>
              <div>
                <a href={`/agendas/${agenda.id}/edit/`} className="text-decoration-none text-pink fwb">
                  Complete now<i className="far fa-arrow-right  margin-left-small"></i>
                </a>
              </div>
            </div>
          </div>
        )) : (
          <p>No upcoming 1:1 meetings.</p>
        )}
        <div className="font-family-varela-round font-size-18 margin-top-large margin-bottom-base">
          Completed
        </div>
        {!isLoading && completed.length > 0 ? completed.map((agenda, index) => (
          <div
            className={classNames('widget-item-hollow margin-top-small', {
              'margin-bottom-large': index === (completed.length - 1)
            })}
            key={agenda.id}
          >
            <div className="font-size-14 fwb">
              {agenda.title}
            </div>
            <div className="flex-space-between margin-top-small font-size-13">
              <div className="text-gray">
                {agenda.scheduled_datetime ? moment(agenda.scheduled_datetime).format('DD-MM-YYYY, H:mm') : ''}
              </div>
              <div>
                <a href={`/agendas/${agenda.id}/read-only/`} className="text-decoration-none text-pink fwb">
                  View<i className="far fa-arrow-right  margin-left-small"></i>
                </a>
              </div>
            </div>
          </div>
        )) : (
          <p>No completed 1:1 meetings.</p>
        )}
        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/agendas/list/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    upcoming: state.dashboardOneToOnes.upcoming || [],
    completed: state.dashboardOneToOnes.completed || [],
    isLoading: isPending(state.responses, GET_EMPLOYEE_ONE_TO_ONES),
  };
}

export default connect(mapStateToProps, { getEmployeeOneToOnes })(EmployeeOneToOnes);
