import React from 'react';
import Loading from '^/components/Loading';
import { connect } from 'react-redux';
import { pollUserExport } from './actions';

export class PollUserExport extends React.PureComponent {
  componentDidMount() {
    this.props.pollUserExport(this.props.exportId);
  }

  render() {
    return this.props.isLoading ? (
      <div className="text-center">
        <p>The export is currently being generated. Please do not leave this page as you will need to start the process
          again if you do.</p>
        <Loading/>
        <div className="margin-top-base">
          <a href="/people/manage-people/" className="btn middle orange">Go back anyway</a>
        </div>
      </div>
    ) : (
      <div>
        <a href={this.props.downloadUrl} target="_blank" className="btn middle orange margin-right-base">Download
          export</a>
        <a href="/people/manage-people/" className="btn middle orange">Go back</a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.pollUserExport.loading,
    downloadUrl: state.pollUserExport.downloadUrl
  };
}

export default connect(mapStateToProps, {
  pollUserExport
})(PollUserExport);
