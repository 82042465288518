
export const BEGIN_SCALE_IMAGE = 'BEGIN_SCALE_IMAGE';
export function beginScaleImage(uploadId) {
  return {
    type: BEGIN_SCALE_IMAGE,
    payload: {
      uploadId
    }
  };
}

export const COMPLETE_SCALE_IMAGE = 'COMPLETE_SCALE_IMAGE';
export function completeScaleImage(uploadId) {
  return {
    type: COMPLETE_SCALE_IMAGE,
    payload: {
      uploadId
    }
  };
}
