import { request, makeAsyncActionSet } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { shouldRethrow } from '^/actions/actions';
import { closeModal } from '^/actions/modals';
import { formatAdditionalFilters } from '^/components/reports/actions';
import { CUSTOM_REPORT_LIST_PAGE_SIZE } from '^/components/custom-reports/constants';
import store from '^/store';

export const PUBLISH_CUSTOM_REPORT = makeAsyncActionSet('PUBLISH_CUSTOM_REPORT');
export function publishCustomReport(reportId, data) {
  return (dispatch) => {
    return request(
      PUBLISH_CUSTOM_REPORT,
     `/api/custom-reports/${reportId}/publish/`,
      'POST',
      data,
      {
        metaData: {reportId},
        shouldRethrow
      }
    )(dispatch);
  };
}

export function publishCustomReportAndCloseModal(reportId, data) {
  return (dispatch) => {
    return publishCustomReport(reportId, data)(dispatch).then(() => {
      dispatch(closeModal());
      toast.dismiss();
      toast.success('Your report is being published.');
    }).catch(() => {
      toast.dismiss();
      toast.error('Could not publish report.');
    });
  };
}

export const UNPUBLISH_CUSTOM_REPORT = makeAsyncActionSet('UNPUBLISH_CUSTOM_REPORT');
export function unpublishCustomReport(reportId) {
  return (dispatch) => {
    return request(
      UNPUBLISH_CUSTOM_REPORT,
     `/api/custom-reports/${reportId}/unpublish/`,
      'POST',
      {},
      {
        metaData: {reportId},
        shouldRethrow
      }
    )(dispatch);
  };
}

export function unpublishCustomReportAndCloseModal(reportId) {
  return (dispatch) => {
    return unpublishCustomReport(reportId)(dispatch).then(() => {
      dispatch(closeModal());
      toast.dismiss();
      toast.success('Your report was unpublished successfully.');
    }).catch(() => {
      dispatch(closeModal());
      toast.dismiss();
      toast.error('Could not unpublish report.');
    });
  };
}

export const QUEUE_CUSTOM_REPORT = makeAsyncActionSet('QUEUE_CUSTOM_REPORT');
export function queueCustomReport(reportId) {
  return (dispatch) => {
    return request(
      QUEUE_CUSTOM_REPORT,
     `/api/custom-reports/${reportId}/queue-version/`,
      'POST',
      undefined,
      {
        metaData: { reportId },
      }
    )(dispatch);
  };
}

export const GET_AVAILABLE_BENCHMARKS = makeAsyncActionSet('GET_AVAILABLE_BENCHMARKS');
export function getAvailableBenchmarks() {
  return (dispatch) => {
    return request(
      GET_AVAILABLE_BENCHMARKS,
      '/api/custom-reports/available-benchmarks/',
      'GET'
    )(dispatch);
  };
}

export const GET_CUSTOM_REPORT_FILTER_OPTIONS = makeAsyncActionSet('GET_CUSTOM_REPORT_FILTER_OPTIONS');
export function getCustomReportFilterOptions(reportId, selectedFilters) {
  return (dispatch) => {
    return request(
      GET_CUSTOM_REPORT_FILTER_OPTIONS,
      `/api/custom-reports/${reportId}/filter-options/`,
      'POST',
      formatAdditionalFilters(selectedFilters),
      {
        metaData: { reportId }
      }
    )(dispatch);
  };
}

export const GET_SURVEY_OPTIONS = makeAsyncActionSet('GET_SURVEY_OPTIONS');
export function getSurveyOptions(selectId, filters) {
  return (dispatch) => {
    return request(
      GET_SURVEY_OPTIONS,
      `/api/custom-reports/survey-options/`,
      'GET',
      filters,
      {
        metaData: {
          selectId
        }
      }
    )(dispatch);
  };
}

export const UPDATE_SELECTED_SURVEY_TYPE = 'UPDATE_SELECTED_SURVEY_TYPE';
export function updateSelectedSurveyType(surveyType) {
  return {
    type: UPDATE_SELECTED_SURVEY_TYPE,
    surveyType
  };
}

export const UPDATE_SELECTED_SURVEYS = 'UPDATE_SELECTED_SURVEYS';
export function updateSelectedSurveys(surveyType, selectedSurveys) {
  return {
    type: UPDATE_SELECTED_SURVEYS,
    surveyType,
    selectedSurveys
  };
}

export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS';
export function updateSelectedFilters(reportId, filterData) {
  return {
    type: UPDATE_SELECTED_FILTERS,
    reportId,
    filterData
  };
}

export const CLEAR_SELECTED_SURVEYS = 'CLEAR_SELECTED_SURVEYS';
export function clearSelectedSurveys() {
  return {
    type: CLEAR_SELECTED_SURVEYS
  };
}

export const UPDATE_SELECTED_SURVEY_COMMENT = 'UPDATE_SELECTED_SURVEY_COMMENT';
export function updateSelectedSurveyComment(surveyType, surveyId, comment) {
  return {
    type: UPDATE_SELECTED_SURVEY_COMMENT,
    surveyType,
    surveyId,
    comment
  };
}

export const CREATE_CUSTOM_REPORT = makeAsyncActionSet('CREATE_CUSTOM_REPORT');
export function createCustomReport(reportOptions) {
  return (dispatch) => {
    return request(
      CREATE_CUSTOM_REPORT,
      '/api/custom-reports/create-report/',
      'POST',
      reportOptions,
      { shouldRethrow }
    )(dispatch);
  };
}

export const CREATE_FILTERED_CUSTOM_REPORT = makeAsyncActionSet('CREATE_FILTERED_CUSTOM_REPORT');
export function createFilteredCustomReport(unfilteredReportId, reportOptions, originalReportId) {
  return (dispatch) => {
    return request(
      CREATE_FILTERED_CUSTOM_REPORT,
      `/api/custom-reports/${unfilteredReportId}/create-filtered-report/`,
      'POST',
      formatAdditionalFilters(reportOptions),
      {
        metaData: {
          reportId: originalReportId
        },
        shouldRethrow
      }
    )(dispatch);
  };
}

export function createCustomReportAndCloseModal(reportOptions) {
  return (dispatch) => {
    return createCustomReport(reportOptions)(dispatch).then(() => {
      dispatch(closeModal());
      toast.dismiss();
      toast.success('Your report is being generated.');
    }).catch(() => {
      dispatch(closeModal());
      toast.dismiss();
      toast.error('Could not generate report.');
    });
  };
}

export function createFilteredCustomReportAndToast(unfilteredReportId, reportOptions, originalReportId) {
  return (dispatch) => {
    return createFilteredCustomReport(unfilteredReportId, reportOptions, originalReportId)(dispatch).then(() => {
      toast.dismiss();
      toast.success('Applying filters to your report.');
    }).catch(() => {
      toast.dismiss();
      toast.error('Could not apply filters.');
    });
  };
}

export const GET_CUSTOM_REPORT = makeAsyncActionSet('GET_CUSTOM_REPORT');
export function getCustomReport(reportId) {
  return (dispatch) => {
    return request(
      GET_CUSTOM_REPORT,
      `/api/custom-reports/${reportId}/`,
      'GET'
    )(dispatch);
  };
}

export const POLL_CUSTOM_REPORT = makeAsyncActionSet('POLL_CUSTOM_REPORT');
export function pollCustomReport(reportId) {
  return (dispatch) => {
    return request(
      POLL_CUSTOM_REPORT,
      `/api/custom-reports/${reportId}/poll-report/`,
      'GET',
      undefined,
      {
        metaData: { reportId },
      }
    )(dispatch);
  };
}

export const TOGGLE_ADD_CUSTOM_REPORT = 'TOGGLE_ADD_CUSTOM_REPORT';
export function toggleAddCustomReport() {
  return {
    type: TOGGLE_ADD_CUSTOM_REPORT
  };
}

export const UPDATE_CUSTOM_REPORT_NAME = makeAsyncActionSet('UPDATE_CUSTOM_REPORT_NAME');
export function updateCustomReportName(name, reportId) {
  return (dispatch) => {
    return request(
      UPDATE_CUSTOM_REPORT_NAME,
      `/api/custom-reports/${reportId}/update-name/`,
      'PUT',
      {name},
      {
        metaData: { reportId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export function updateCustomReportNameAndToast(name, reportId) {
  return (dispatch) => {
    return updateCustomReportName(name, reportId)(dispatch).then(() => {
      toast.dismiss();
      toast.success('The report name has been updated.');
    }).catch(() => {
      toast.dismiss();
      toast.error('The report name could not be updated.');
    });
  };
}

export const UPDATE_CUSTOM_REPORT = makeAsyncActionSet('UPDATE_CUSTOM_REPORT');
export function updateCustomReport(data, unFilteredReportId, originalReportId) {
  return (dispatch) => {
    return request(
      UPDATE_CUSTOM_REPORT,
      `/api/custom-reports/${unFilteredReportId}/update/`,
      'PUT',
      data,
      {
        metaData: { reportId: originalReportId },
        shouldRethrow
      }
    )(dispatch);
  };
}

export function updateCustomReportAndToast(data, unFilteredReportId, originalReportId) {
  return (dispatch) => {
    return updateCustomReport(data, unFilteredReportId, originalReportId)(dispatch).then(() => {
      // filters get cleared when the report is edited, so we need to re-fetch filter options
      getCustomReportFilterOptions(unFilteredReportId, {})(dispatch);
      toast.dismiss();
      toast.success('The report has been updated.');
    }).catch(() => {
      toast.dismiss();
      toast.error('The report could not be updated.');
    });
  };
}

export const UPDATE_SELECTED_BENCHMARKS = 'UPDATE_SELECTED_BENCHMARKS';
export function updateSelectedBenchmarks(surveyType, selectedBenchmark) {
  return {
    type: UPDATE_SELECTED_BENCHMARKS,
    surveyType,
    selectedBenchmark
  };
}

export const ADD_SELECTED_BENCHMARKS = 'ADD_SELECTED_BENCHMARKS';
export function addSelectedBenchmarks(surveyType, selectedBenchmarks) {
  return {
    type: ADD_SELECTED_BENCHMARKS,
    surveyType,
    selectedBenchmarks
  };
}

export const DELETE_CUSTOM_REPORT = makeAsyncActionSet('DELETE_CUSTOM_REPORT');
export function deleteCustomReport(report) {
  const unFiltereredReportId = report.unfiltered_report_id || report.id;
  return (dispatch) => {
    return request(
      DELETE_CUSTOM_REPORT,
      `/api/custom-reports/${unFiltereredReportId}/delete-report/`,
      'DELETE',
      undefined,
      {
        metaData: { reportId: report.id },
        shouldRethrow
      }
    )(dispatch);
  };
}

export const GET_CUSTOM_REPORT_LIST = makeAsyncActionSet('GET_CUSTOM_REPORT_LIST');
export function getCustomReportList(page) {
  return (dispatch) => {
    return request(
      GET_CUSTOM_REPORT_LIST,
      `/api/custom-reports/?page=${page}&page_size=${CUSTOM_REPORT_LIST_PAGE_SIZE}`,
      'GET',
      undefined,
      {
        shouldRethrow,
        metaData: { page }
      }
    )(dispatch);
  };
}

export function deleteCustomReportCloseModalAndToast(report) {
  return (dispatch) => {
    return deleteCustomReport(report)(dispatch).then(() => {
      const storeState = store.getState();
      const listLength = storeState.customReportsList.length;
      const page = storeState.customReportListCollection.get('page');
      const count = storeState.customReportListCollection.get('count');

      if (
        !listLength ||
        page === 1 && count === CUSTOM_REPORT_LIST_PAGE_SIZE
      ) {
        const pageToReload = (
          count > ((page - 1 ) * CUSTOM_REPORT_LIST_PAGE_SIZE) ?
          page :
          page - 1
        );
        if (pageToReload) {
          dispatch(getCustomReportList(pageToReload));
        }
      }

      dispatch(closeModal());
      toast.dismiss();
      toast.success('The report has been deleted.');
    }).catch(() => {
      toast.dismiss();
      toast.error('The report could not be deleted.');
    });
  };
}
