import { Row, Column } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';

import ActionTypeIcon from '^/components/actions/ActionTypeIcon';
import RemoteUserSelect from '^/components/forms/RemoteUserSelect';
import { createGoalRemoveAction, createGoalUpdateAction } from '^/components/goals/actions';
import {
  ACTION_STATUS_OPTIONS,
  ACTION_TYPE,
} from '^/consts/actions';
import { FORMAT_DATE_PICKER } from '^/utils';

export function CreateGoalActionCard({
  removeAction,
  index,
  action,
  updateActionTitle,
  updateActionDescription,
  updateActionOwner,
  updateActionDueDate,
  updateActionStatus,
  ownerInitialValue,
  hasMultipleActions,
  readOnlyOwner
}) {
  return (
    <div className="action-card margin-top-large padding-large">
      {hasMultipleActions && (
        <button
          className="toggle-inclusion-icon"
          onClick={removeAction}
        >
          <i className="far fa-times"></i>
        </button>
      )}
      <Row>
        <Column md={11}>
          <div className="text-gray margin-bottom-base">
            <ActionTypeIcon actionType={ACTION_TYPE.TASK} />
          </div>
          <input
            name="title"
            type="text"
            className="margin-bottom-base"
            placeholder="Title"
            onChange={updateActionTitle}
            value={action.title}
          />
          <textarea
            name="description"
            placeholder="Description"
            onChange={updateActionDescription}
            value={action.description}
          />
        </Column>
      </Row>
      <Row>
        <Column md={11}>
          <Column md={2} className="padding-none margin-top-large">
            {readOnlyOwner ? (
              <select disabled>
                <option>
                  {readOnlyOwner.label}
                </option>
              </select>
            ) : (
              <RemoteUserSelect
                onChange={updateActionOwner}
                selectId={`create-goal-action-${index}`}
                optionFilters={{'bubble_users_only': true}}
                placeholder="User (type to search...)"
                initialValue={ownerInitialValue}
              />
            )}
          </Column>
          <Column md={2} className="padding-none margin-top-large md-margin-left-large">
            <div className="datepicker-with-icon-wrapper">
              <ReactDatePicker
                selected={action.due_date}
                onChange={updateActionDueDate}
                dateFormat={FORMAT_DATE_PICKER}
                placeholderText="Due Date"
                fixedHeight
              />
              <i className="far fa-calendar datepicker-icon"></i>
            </div>
          </Column>
          <Column md={2} className="padding-none margin-top-large md-margin-left-large">
            <select
              value={action.status}
              onChange={updateActionStatus}
            >
              {ACTION_STATUS_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Column>
        </Column>
      </Row>
    </div>
  );
}

function mapStateToProps(state) {
  const { id: value, first_name, last_name } = state.loggedInUser.toObject();
  return {
    ownerInitialValue: {
      value,
      label: `${first_name} ${last_name}`,
    }
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    updateActionTitle: event => {
      dispatch(createGoalUpdateAction(props.index, 'title', event.target.value));
    },
    updateActionDescription: event => {
      dispatch(createGoalUpdateAction(props.index, 'description', event.target.value));
    },
    updateActionOwner: option => {
      if (option) {
        dispatch(createGoalUpdateAction(props.index, 'owner', option.value));
      }
    },
    updateActionDueDate: value => {
      if (value) {
        dispatch(createGoalUpdateAction(props.index, 'due_date', value));
      }
    },
    updateActionStatus: event => {
      dispatch(createGoalUpdateAction(props.index, 'status', event.target.value));
    },
    removeAction: () => {
      dispatch(createGoalRemoveAction(props.index));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGoalActionCard);
