import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import DashboardTabs from '^/components/dashboards/DashboardTabs';

export function DashboardWrapper({
  loggedInUser,
  activeTab,
  children
}) {
  return (
    <div>
      <div className="main-content padding-top-none display-flex align-items-center min-height-70px">
        <DashboardTabs loggedInUser={loggedInUser} activeTab={activeTab} />
        <p className={
          classNames(
            'font-family-varela-round',
            'font-size-18',
            'margin-left-auto',
            'margin-bottom-none',
            'dashboard-user-name'
          )
        }>
          {`Welcome ${loggedInUser.get('first_name')}!`}
        </p>
      </div>
      <div className="background-white padding-bottom-xx-large overflow-hidden">
        <div className="main-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    loggedInUser: state.loggedInUser,
  };
}

export default connect(mapStateToProps)(DashboardWrapper);
