import { ModalRenderer } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { getOrgPermissions } from '^/actions/actions';
import {
  getEmployeeEnpsData,
  EMPLOYEE_ENPS_DATA,
} from '^/components/dashboards/actions';
import DashboardWrapper from '^/components/dashboards/DashboardWrapper';
import MyGoals from '^/components/dashboards/MyGoals';
import OkrWidget from '^/components/dashboards/OkrWidget';
import EnpsWidget from '^/components/dashboards/EnpsWidget';
import PerformanceReviewSurveysWidget from '^/components/dashboards/PerformanceReviewSurveysWidget';
import SurveysWidgetIndividual from '^/components/dashboards/SurveysWidgetIndividual';
import EmployeeOneToOnes from '^/components/dashboards/EmployeeOneToOnes';
import TrendAnalysisWidget from '^/components/dashboards/TrendAnalysisWidget';
import EmployeeCompleteFeedbackWidget from '^/components/dashboards/EmployeeCompleteFeedbackWidget';
import { userMeetsRequiredRole } from '^/utils/permissions';
import USER_ROLES from '^/roles';
import PeerFeedbackGiveRequestReceive from './PeerFeedbackGiveRequestReceive';

export const getCountOfTopRowWidgets = orgPermissions => ['performance_reviews', 'agendas'].filter(
  name => !!orgPermissions.get(name)
).length + 1;  // +1 for surveys widget which always features

export const TopRowWidgetContainer = ({children, widgetsCount}) => (
  <div className={classNames('dashboard-widget-container', {
    'flex-grow': widgetsCount === 1,
    'dashboard-widget-container-50': widgetsCount === 2,
    'dashboard-widget-container-33': widgetsCount === 3,
  })}>
    {children}
  </div>
);

export class EmployeeDashboard extends React.PureComponent {

  componentDidMount() {
    this.props.getOrgPermissions();
  }

  render() {
    const { orgPermissions, modals } = this.props;
    const topRowWidgetsCount = getCountOfTopRowWidgets(orgPermissions);

    return (
      <DashboardWrapper activeTab={0}>
        <h1 className={
          classNames(
            'font-size-18',
            'font-family-varela-round',
            'margin-top-base',
            'margin-bottom-none'
          )
        }>You are viewing information about yourself as an employee.</h1>
        <div className="display-flex-md flex-wrap flex-gap-xx-large">
          <TopRowWidgetContainer widgetsCount={topRowWidgetsCount}>
            <SurveysWidgetIndividual />
          </TopRowWidgetContainer>
          {orgPermissions.get('performance_reviews') &&
            <TopRowWidgetContainer widgetsCount={topRowWidgetsCount}>
              <PerformanceReviewSurveysWidget />
            </TopRowWidgetContainer>
          }
          {orgPermissions.get('agendas') &&
            <TopRowWidgetContainer widgetsCount={topRowWidgetsCount}>
              <EmployeeOneToOnes />
            </TopRowWidgetContainer>
          }
        </div>
        {orgPermissions.get('peer_feedback') &&
          <div className="display-flex-md flex-gap-xx-large">
            <div className="dashboard-widget-container flex-grow">
              <PeerFeedbackGiveRequestReceive />
            </div>
            <div className="dashboard-widget-container employee-complete-feedback-widget-container">
              <EmployeeCompleteFeedbackWidget />
            </div>
          </div>
        }
        <div className="dashboard-widget-container">
          <MyGoals />
        </div>
        <div className="display-flex-md flex-gap-xx-large">
          {orgPermissions.get('okrs') &&
            <div className="dashboard-widget-container flex-grow">
              <OkrWidget />
            </div>
          }
          <div className="dashboard-widget-container enps-widget-container">
            <EnpsWidget getEnpsData={getEmployeeEnpsData} enpsKey={EMPLOYEE_ENPS_DATA} />
          </div>
        </div>
        <div className="dashboard-widget-container">
          <TrendAnalysisWidget
            // Employee bubbles also see original reports they created and published
            listFilter={this.props.isManagerUser && {published_version_member: this.props.userId}}
            emptyText="You have no published reports yet."
            noModalRenderer
            isMyDashboard
          />
        </div>
        <ModalRenderer modals={modals}/>
        <ToastContainer />
      </DashboardWrapper>
    );
  }
};

export function mapStateToProps(state) {
  return {
    orgPermissions: state.orgPermissions,
    modals: state.modals,
    userId: state.loggedInUser.get('id'),
    isManagerUser: userMeetsRequiredRole(state.loggedInUser, USER_ROLES.MANAGER_USER)
  };
}

export default connect(mapStateToProps, { getOrgPermissions })(EmployeeDashboard);
