import React from 'react'; // eslint-disable-line no-unused-vars

import Empty from './Empty';
import SelectedItem from './SelectedItem';

export default ({
  questions,
  onClickReorder,
  onClickRemove,
  readOnly,
  disabled,
  numberOfQuestionsCopying,
  questionIdsCurrentlyDeleting,
  openEditQuestionModal
}) => {
  return (
    <div className="survey-question-list margin-top-large">
      {questions.map((question, index) =>
        <SelectedItem
          key={question.get('id')}
          question={question}
          isFirstItem={index === 0}
          isLastItem={index + 1 === questions.size}
          disabled={
            disabled || questionIdsCurrentlyDeleting.count() > 0
          }
          onClickRemove={onClickRemove}
          onClickReorder={onClickReorder}
          readOnly={readOnly}
          recommended={question.get('recommended_for')}
          openEditQuestionModal={openEditQuestionModal}
        />
      )}
      {questions.count() === 0 && (
        <Empty>{readOnly ? '' : 'No questions selected yet...'}</Empty>
      )}
      {Array.from(Array(numberOfQuestionsCopying)).map(index => (
        <div className="margin-top-base" key={`adding-question-${index}`}>
          <Empty>Adding question to survey...</Empty>
        </div>
      ))}
    </div>
  );
};
