import { isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { GET_ADMIN_DASHBOARD_OKR_DATA, getAdminDashboardOkrList } from '^/components/dashboards/actions';
import { StatusCount } from '^/components/dashboards/StatusCount';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';
import {
  OKR_STATUS_LABEL_MAP,
  OKR_STATUS_LABEL_MAP_LOWER,
  getColorForProgress,
  COMPANY_GOAL_STATUS_TO_COLOR_MAP,
  OKR_STATUS
} from '^/components/okrs/constants';

export const CompanyGoalWidgetTile = ({ okr }) => {
  return (
    <div className="okr-widget-tile-container widget-item-hollow flex-space-between margin-bottom-large">
      <p
        className="okr-name-widget width-25-percent font-size-14"
        title={okr.name}
      >
        {okr.name}
      </p>
      <div className="width-20-percent">
        <div className="okr-tile-status-bar border-radius-base">
          <div
            className={
              classNames(
                'okr-tile-status-bar-progress',
                'border-radius-base',
                getColorForProgress(okr.percentage_complete)
              )
            }
            style={{width: `${okr.percentage_complete}%`}}
          />
        </div>
        <div className="flex-space-between">
          <p className="margin-bottom-none fwb">{OKR_STATUS_LABEL_MAP[okr.status]}</p>
          <p className="margin-bottom-none fwb">{okr.percentage_complete}%</p>
        </div>
      </div>
      <div>
        <p className="fwb margin-bottom-none">On track:</p>
        <p className="margin-bottom-none">{okr.managers_on_track} managers</p>
      </div>
      <div>
        <p className="fwb margin-bottom-none">Off track:</p>
        <p className="margin-bottom-none">{okr.managers_off_track} managers</p>
      </div>
      <a href={`/okrs/?expanded_company_goal=${okr.id}`} className="view-details-link">
        View details{' '}
        <i className="far fa-arrow-right arrow text-pink"></i>
      </a>
    </div>
  );
};

export class CompanyGoalWidget extends React.PureComponent {

  componentDidMount() {
    this.props.getData();
  }

  render() {

    const { data, isLoading } = this.props;

    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="Company objectives" image="/static/images/dashboards/okrs.svg" />

        {isLoading && <Loading />}

        {data && (
          <div className="display-flex flex-gap-xx-large margin-bottom-large">
            <StatusCount
              count={data.complete_count}
              status={OKR_STATUS_LABEL_MAP_LOWER[OKR_STATUS.COMPLETE]}
              colour={COMPANY_GOAL_STATUS_TO_COLOR_MAP[OKR_STATUS.COMPLETE]}
            />
            <StatusCount
              count={data.progressing_count}
              status={OKR_STATUS_LABEL_MAP_LOWER[OKR_STATUS.PROGRESSING]}
              colour={COMPANY_GOAL_STATUS_TO_COLOR_MAP[OKR_STATUS.PROGRESSING]}
            />
            <StatusCount
              count={data.no_update_count}
              status={OKR_STATUS_LABEL_MAP_LOWER[OKR_STATUS.NO_UPDATE]}
              colour={COMPANY_GOAL_STATUS_TO_COLOR_MAP[OKR_STATUS.NO_UPDATE]}
            />
          </div>
        )}

        {(data && data.okrs && data.okrs.length > 0) ?
          data.okrs.map(okr => <CompanyGoalWidgetTile key={okr.id} okr={okr} />)
          : <p>No company objectives set.</p>
        }

        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/okrs/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    data: state.adminDashboardOkrData,
    isLoading: isPending(state.responses, GET_ADMIN_DASHBOARD_OKR_DATA)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getData: () => dispatch(getAdminDashboardOkrList())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyGoalWidget);
