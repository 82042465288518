import React from 'react'; // eslint-disable-line
import { Field, reduxForm } from 'redux-form';
import Loading from '^/components/Loading';

export const ActionCardEditForm = props => {
  if (props.isLoading) {
    return <Loading />;
  }

  return (
    <form onSubmit={props.handleSubmit}>
      <Field name="title" type="text" component="input" className="margin-bottom-base" required />
      <Field name="description" component="textarea" className="margin-bottom-base" />
      <button type="submit" className="btn middle orange">Save</button>
    </form>
  );
};

export default reduxForm()(ActionCardEditForm);
