import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import AgendaItem from './AgendaItem';
import { AgendaSectionType } from '^/components/agendas/constants';
import CreateUpdateAgendaItemForm from '^/components/agendas/CreateUpdateAgendaItemForm';
import { agendaItemEditAction, createAgendaItemAndToast } from '^/components/agendas/actions';

const getFormName = itemsName => `CreateAgendaItemForm-${itemsName}`;

const ITEMS_NAME_TO_SECTION_TYPE_MAPPING = {
  custom_items: AgendaSectionType.CUSTOM_ITEMS,
  performance_review_comments: AgendaSectionType.PERFORMANCE_REVIEW,
  engagement_survey_comments: AgendaSectionType.ENGAGEMENT_SURVEY,
  employee_goal_comments: AgendaSectionType.EMPLOYEE_GOALS,
  okr_comments: AgendaSectionType.OKRS,
  peer_feedback_comments: AgendaSectionType.PEER_FEEDBACK
};

export function CustomItemsSection({
  agendaId,
  items,
  displayCreateAgendaItem,
  isDisabled,
  createAgendaItem,
  isEmptyItems,
  itemsName,
  isCommentItems,
  addAgendaItem
}) {
  return (
    <div>
      {!!items.length && items.map((agendaItem, index) => (
        <AgendaItem
          key={agendaItem.id}
          agendaId={agendaId}
          agendaItem={agendaItem}
          agendaItemId={agendaItem.id}
          isLast={index === items.length - 1}
          isDisabled={isDisabled}
          index={index}
          itemsName={itemsName}
          isCommentItems={isCommentItems}
        />
      ))}
      {(isCommentItems || displayCreateAgendaItem) && (
        <CreateUpdateAgendaItemForm
          form={getFormName(itemsName)}
          onSubmit={createAgendaItem}
          isDisabled={isDisabled}
          isEmptyItems={isEmptyItems}
          isCommentItems={isCommentItems}
          itemsName={itemsName}
        />
      )}
      {(!isCommentItems && !displayCreateAgendaItem) &&
        <button
          type="button"
          className="btn middle orange display-block margin-top-xx-large"
          onClick={addAgendaItem}
          disabled={isDisabled}
        >
          Add new item
        </button>
      }
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const editingAgenda = props.agendaStateKey ? state[props.agendaStateKey] : state.editingAgenda;
  const items = editingAgenda[props.itemsName];
  return {
    displayAddNew: state.editingAgendaItemIds.includes(props.itemsName),
    displayCreateAgendaItem: (
      !items.length || state.editingAgendaItemIds.includes(props.itemsName)
    ),
    isEmptyItems: !items.length,
    items
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addAgendaItem: () => dispatch(agendaItemEditAction(ownProps.itemsName)),
    createAgendaItem: data => {
      dispatch(reset(getFormName(ownProps.itemsName)));
      dispatch(createAgendaItemAndToast(
        ownProps.agendaId,
        Object.assign({}, data, {
          section_type: ITEMS_NAME_TO_SECTION_TYPE_MAPPING[ownProps.itemsName]
        }),
        ownProps.itemsName
      ));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomItemsSection);
