import { Map, fromJS } from 'immutable';

import * as actions from '^/components/dashboards/actions';
import { QUEUE_CUSTOM_REPORT, POLL_CUSTOM_REPORT } from '^/components/custom-reports/actions';
import { CREATE_REQUESTED_PEER_FEEDBACK } from '^/components/peer-feedback/actions';

export function dashboardOkrList(state = [], action) {
  switch (action.type) {
    case actions.GET_EMPLOYEE_OKR_LIST.SUCCESS:
    case actions.GET_MANAGER_OKR_LIST.SUCCESS:
      return Object.assign({}, state, {
        [action.meta.key]: Object.values(action.payload.data.results)
      });
    default:
      return state;
  }
}

export function adminDashboardGoalsData(state = Map(), action) {
  switch (action.type) {
    case actions.GET_ADMIN_DASHBOARD_GOALS_DATA.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}

export function homepageAdminDashboardLatestSurveys(state = Map(), action) {
  switch (action.type) {
    case actions.GET_ADMIN_DASHBOARD_SURVEYS.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}

export function adminDashboardOkrData(state = {}, action) {
  switch (action.type) {
    case actions.GET_ADMIN_DASHBOARD_OKR_DATA.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function adminDashboardPerformanceReviewSurveys(state = Map(), action) {
  switch (action.type) {
    case actions.GET_ADMIN_DASHBOARD_PERFORMANCE_REVIEW_DATA.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}

export function adminDashboardPeerFeedbackStats(state = Map(), action) {
  switch (action.type) {
    case actions.GET_ADMIN_DASHBOARD_PEER_FEEDBACK_STATS.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}

export function homepageSnapshotData(state = {}, action) {
  switch (action.type) {
    case actions.GET_HOMEPAGE_SNAPSHOT_DATA.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function homepageTeamGoals(state = Map(), action) {
  switch (action.type) {
    case actions.GET_HOME_PAGE_TEAM_GOALS.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function homepageUserPerformanceReviewSurveys(state = Map(), action) {
  switch (action.type) {
    case actions.GET_USER_PERFORMANCE_REVIEW_SURVEYS.SUCCESS:
      return fromJS(action.payload.data);
    default:
      return state;
  }
}

export function homepageMyGoals(state = Map(), action) {
  switch (action.type) {
    case actions.GET_HOMEPAGE_MY_GOALS.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function homepageEnpsData(state = {}, action) {
  switch (action.type) {
    case actions.CREATE_ENPS_SCORE.SUCCESS:
      return Object.assign({}, state, {
        [actions.EMPLOYEE_ENPS_DATA]: {enps_score: action.meta.score}
      });
    case actions.GET_EMPLOYEE_ENPS_DATA.SUCCESS:
    case actions.GET_MANAGER_ENPS_DATA.SUCCESS:
    case actions.GET_ADMIN_ENPS_DATA.SUCCESS:
      return Object.assign({}, state, {
        [action.meta.key]: action.payload.data
      });
    default:
      return state;
  }
}

export function dashboardOneToOnes(state = {}, action) {
  switch (action.type) {
    case actions.GET_EMPLOYEE_ONE_TO_ONES.SUCCESS:
    case actions.GET_MANAGER_ONE_TO_ONES.SUCCESS:
    case actions.GET_ADMIN_ONE_TO_ONES.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

const DEFAULT_TREND_ANALYSIS_LIST_STATE = {
  items: [],
  hasNextPage: false,
  page: 1
};

function updateReportData(items, reportId, newData) {
  return items.map(report => report.id === reportId ?
    Object.assign({}, report, newData) : report
  );
}

export function dashboardTrendAnalysisList(state = DEFAULT_TREND_ANALYSIS_LIST_STATE, action) {
  switch (action.type) {
    case actions.GET_TREND_ANALYSIS_WIDGET_NEXT_PAGE.SUCCESS:
    case actions.GET_TREND_ANALYSIS_WIDGET_LIST.SUCCESS:
      return Object.assign({}, state, {
        items: [...state.items, ...action.payload.data.results],
        hasNextPage: !!action.payload.data.next,
        page: action.meta.page || state.page
      });
    case POLL_CUSTOM_REPORT.SUCCESS:
    case QUEUE_CUSTOM_REPORT.SUCCESS:
      return Object.assign({}, state, {
        items: updateReportData(state.items, action.meta.reportId, action.payload.data)
      });
    default:
      return state;
  }
}

export function myTeamFeedbackReceived(state = {}, action) {
  switch (action.type) {
    case actions.GET_MY_TEAM_FEEDBACK_RECEIVED.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function dashboardFeedbackRequests(state = [], action) {
  switch (action.type) {
    case actions.GET_EMPLOYEE_COMPLETE_FEEDBACK_REQUESTS.SUCCESS:
      return action.payload.data.feedback_requests;
    case CREATE_REQUESTED_PEER_FEEDBACK.SUCCESS:
      return state.filter(item => item.id !== action.payload.data.id);
    default:
      return state;
  }
}

export function dashboardUnreadPeerFeedbackCount(state = 0, action) {
  switch (action.type) {
    case actions.GET_UNREAD_PEER_FEEDBACK_COUNT.SUCCESS:
      return action.payload.data.count;
    default:
      return state;
  }
}
