import { ModalRenderer } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { clearCollection } from '^/actions/collections';
import { clearAllSelectedListItems } from '^/actions/actions';
import roles from '^/roles';
import { changeQueryParamsWithoutPageLoad } from '^/utils';
import { userMeetsRequiredRole } from '^/utils/permissions';
import PeerFeedbackTeamList from '^/components/peer-feedback/PeerFeedbackTeamList';
import PeerFeedbackIndividualList from '^/components/peer-feedback/PeerFeedbackIndividualList';
import {
  PEER_FEEDBACK_TEAM_LIST_URL,
  PEER_FEEDBACK_INDIVIDUAL_LIST_URL
} from '^/components/peer-feedback/constants';
import PeerFeedbackRemindersBanner from '^/components/peer-feedback/PeerFeedbackRemindersBanner';

const Tab = ({label, active, onClick}) => {
  return (
    <div
      role="button"
      className={classNames('dashboard-tab', {'active': active})}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

const getInfoMessage = (isTeamList, isAdminOrAbove) => {
  if (isTeamList) {
    if (isAdminOrAbove) {
      return 'View feedback for all members within the organisation.';
    }
    return 'View feedback your team has received, plus feedback you have given to others.';
  }
  return 'View all feedback you have received from others.';
};

const getTeamFeedbackTabLabel = user =>
  userMeetsRequiredRole(user, roles.MANAGER_USER_WITH_ADMIN) ? 'All feedback'
  : 'Team feedback';

const isLocationIndividual = () => window.location.search.includes('?individual=true');

export class PeerFeedbackHub extends React.Component {
  constructor(props) {
    super(props);
    this.tabToList = this.tabToList.bind(this);
    this.tabToIndividualList = this.tabToIndividualList.bind(this);
    this.tabToTeamList = this.tabToTeamList.bind(this);
  }

  tabToList(queryParams, listUrl) {
    changeQueryParamsWithoutPageLoad(queryParams);
    this.props.clearAllSelectedListItems();
    this.props.clearCollection(listUrl);
  }

  tabToIndividualList() {
    // Add the query param so that page reloads keep the user on the individual list
    this.tabToList({individual: true}, PEER_FEEDBACK_INDIVIDUAL_LIST_URL);
  }

  tabToTeamList() {
    if (!this.props.filterOptions) {
      // Filter options need to be loaded through the template view
      window.location.href = '/people/peer-feedback/';
    } else {
      this.tabToList({}, PEER_FEEDBACK_TEAM_LIST_URL);
    }
  }

  render () {
    const {
      loggedInUser,
      isManagerOrAbove,
      filterOptions,
      organisationTeamTerm,
      isAdminOrAbove,
      isIndividualList
    } = this.props;

    return (
      <div className="background-white padding-xx-large border-radius-22 margin-vertical-xx-large position-relative">
        <h1 className="font-family-effra-bold font-size-38">
          Feedback
        </h1>
        <p className="font-size-18 font-family-varela-round">
          {getInfoMessage(!isIndividualList, isAdminOrAbove)}
        </p>
        {isManagerOrAbove && (
          <div className="dashboard-tabs">
            <Tab
              label="My feedback"
              active={isIndividualList}
              onClick={this.tabToIndividualList}
            />
            <Tab
              label={getTeamFeedbackTabLabel(loggedInUser)}
              active={!isIndividualList}
              onClick={this.tabToTeamList}
            />
          </div>
        )}
        {isIndividualList ? (
          <PeerFeedbackIndividualList />
        ) : (
          <PeerFeedbackTeamList filterOptions={filterOptions} organisationTeamTerm={organisationTeamTerm} />
        )}
        <PeerFeedbackRemindersBanner />
        <ToastContainer />
        <ModalRenderer modals={this.props.modals}/>
      </div>
    );
  }
}

export function mapStateToProps({loggedInUser, modals}) {
  const isManagerOrAbove = userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER);
  return {
    loggedInUser,
    isManagerOrAbove,
    isAdminOrAbove: userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN),
    modals,
    isIndividualList: !isManagerOrAbove || isLocationIndividual()
  };
}

const mapDispatchToProps = dispatch => ({
  clearAllSelectedListItems: () => dispatch(clearAllSelectedListItems()),
  clearCollection: collectionName => dispatch(clearCollection(collectionName))
});

export default connect(mapStateToProps, mapDispatchToProps)(PeerFeedbackHub);
