import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { getOrganisationSurveysConfig } from '^/components/survey/config';
import { ORGANISATION_SURVEYS_LIST } from '^/components/survey/constants';
import roles from '^/roles';
import { userMeetsRequiredRole } from '^/utils/permissions';


export const OrganisationSurveysList = ({ loggedInUser, isExecutive }) => {
  return (
    <div className="will">
      <ChadminList
        className="organisation-survey-list"
        collectionPath="survey/organisation-surveys"
        collectionName={ORGANISATION_SURVEYS_LIST}
        columns={getOrganisationSurveysConfig(loggedInUser, isExecutive)}
        defaultFilters={{
          show_all: userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN)
        }}
        ExtraControlsLeft={!isExecutive ? () => (
          <a
            className="btn orange middle"
            href="create/"
          >
            New survey
          </a>
        ) : undefined}
        ExtraControlsRight={() => userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN) && (
          <UriFilterSelect
            filterName="show_all"
            name="show"
            hideBlankOption
            options={[
              { value: 'true', label: 'All surveys' },
              { value: 'false', label: 'My team surveys' },
            ]}
            hasInlineLabels
          />
        )}
        hasInlineLabels
      />
    </div>
  );
};

export function mapStateToProps({ loggedInUser }) {
  return {
    loggedInUser,
    isExecutive: loggedInUser.get('type') === roles.EXECUTIVE,
  };
}

export default connect(mapStateToProps)(OrganisationSurveysList);
