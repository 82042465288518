import { HEATMAP_REPORT_TYPES } from './constants';
import { renderHeatmapChart } from '../../charts/heatmap';
import { renderLineChart } from '../../charts/trend-analysis-line';

export const HEATMAP_CELL_WIDTH = 100;

export const getHeatmapChartWidth = (xAxisLabels) => {
  const axisLabelPadding = 295;
  return axisLabelPadding + (HEATMAP_CELL_WIDTH * xAxisLabels.length);
};

export const getHeatmapChartHeight = (yAxisLabels) => {
  const titleAndLegendPadding = 150;
  const heightPerCell = 33;
  const height = titleAndLegendPadding + (heightPerCell * yAxisLabels.length);
  return height;
};

const printDiv = (id) => {
  var divContents = document.getElementById(id).innerHTML;
  var a = window.open('', '_blank');
  a.document.write('<html>');
  a.document.write('<body >');
  a.document.write(divContents);
  a.document.write('</body></html>');
  a.document.close();
  a.print();

  // allow the browser to prepare before reverting
  setTimeout(function () {
    a.close();
  }, 200);
};

export const renderChart = (report, editButtonOnClick, isDashboardWidget = false) => {
  const chartData = JSON.parse(report.cached_chart_data);

  if (chartData.errors) {
    return;
  }

  if (HEATMAP_REPORT_TYPES.includes(report.type)) {
    const chartWidth = getHeatmapChartWidth(chartData.x_axis);
    renderHeatmapChart(
      `chart-${report.id}`,
      chartData.data,
      chartData.x_axis,
      chartData.y_axis,
      chartData.bold_y_axis_labels,
      chartData.bold_x_axis_labels || [],
      chartData.bold_plot_line_indexes_list,
      chartWidth,
      editButtonOnClick,
      !!chartData.grouping_options,
      report.name,
      190
    );
  } else {
    const chartId = isDashboardWidget ? `chart-${report.id}-dashboard` : `chart-${report.id}`;
    const reportName = isDashboardWidget ? '' : report.name;
    renderLineChart(
      chartId,
      chartData,
      reportName,
      editButtonOnClick,
      isDashboardWidget
    );
  }
};

export const getChartExportButtons = (editButtonOnClick, elementId = null) => {
  const exportButtons = {
    contextButton: {
      symbol: 'url(/static/images/icons/download.svg)',
      symbolX: 22.5,
      symbolY: 22.5,
      x: -7,
      y: -10,
      menuItems: [
        'viewFullscreen',
        {
          textKey: 'printChart',
          onclick: function () {
            if (elementId) {
              printDiv(elementId);
            } else {
              this.print();
            }
          }
        },
        'separator',
        'downloadPNG',
        'downloadJPEG',
        'downloadPDF',
        'downloadSVG',
        'separator',
        'downloadCSV',
        'downloadXLS'
      ]
    }
  };
  if (editButtonOnClick) {
    return Object.assign({}, exportButtons, {
      customButton: {
        onclick: editButtonOnClick,
        className: 'highcharts-contextbutton',
        x: -62,
        y: -10,
        symbolX: 23.5,
        symbolY: 21.5,
        symbol: 'url(/static/images/icons/pen-to-square.svg)',
      }
    });
  }
  return exportButtons;
};

export const chartExportTitle = {
  widthAdjust: 0,
  style: {
    lineHeight: '20px'
  },
  y: 20
};
