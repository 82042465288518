import { request } from '@dabapps/redux-requests';
import { makeAsyncActionSet } from '../../actions/actions';

export const GET_AVAILABLE_FILTERS = makeAsyncActionSet('GET_AVAILABLE_FILTERS');
export function getAvailableFilters(surveyId, selectedFilters) {
  return (dispatch) => {
    return request(
      GET_AVAILABLE_FILTERS,
      `/api/export/${surveyId}/filter-options/`,
      'POST',
      selectedFilters
    )(dispatch);
  };
}

export const SET_AVAILABLE_FILTERS = 'SET_AVAILABLE_FILTERS';
export function setAvailableFilters(data) {
  return {
    type: SET_AVAILABLE_FILTERS,
    payload: data
  };
}
