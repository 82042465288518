import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { openSimpleModal } from '^/actions/modals';
import CollapsableWell from '../common/CollapsibleWell';
import { SelectableTitleByType, AgendaSectionType } from './constants';
import SelectableEngagementSurveyQuestionCard from './SelectableEngagementSurveyQuestionCard';
import EngagementQuestionsModal from './EngagementQuestionsModal';
import { engagementQuestionOptionIsSelected } from './utils';

export function SelectableEngagementSurveyQuestions({
  selectableEngagementOptions,
  agendaId,
  isAgendaEmployee,
  latestEngagementSurveyName,
  latestEngagementSurveyStartDate,
  latestEngagementSurveyIsAnonymous,
  openEngagementQuestionsModal,
  isDisabled
}) {
  const isAnonymousTeamScores = !isAgendaEmployee && latestEngagementSurveyIsAnonymous;
  return (
    <CollapsableWell
      title={SelectableTitleByType[AgendaSectionType.ENGAGEMENT_SURVEY]}
      isOrange
      isCollapsed
    >
      {latestEngagementSurveyName ? (
        <div>
          {isAnonymousTeamScores ? (
            <p className="margin-top-x-large margin-bottom-none font-weight-bold font-size-14">
              Most recent engagement survey - team's average scores
            </p>
          ) : (
            <div className="margin-top-x-large">
              <p className="font-weight-bold font-size-14">
                Most recent engagement survey -{' '}
                {isAgendaEmployee ? 'my' : 'employee\'s'}
                {' '}scores
              </p>
              {latestEngagementSurveyIsAnonymous && (
                <p className="margin-bottom-none font-size-14">
                  * You completed this survey anonymously. If you include these in
                  the agenda, they can be viewed by your manager.
                </p>
              )}
            </div>
          )}
          <p
            className=
            "font-size-14 display-flex flex-space-between align-items-center margin-top-large"
          >
            <span className="font-weight-bold">{latestEngagementSurveyName}</span>
            <span className="font-weight-bold">{latestEngagementSurveyStartDate}</span>
          </p>
          <p
            className="display-inline-block fwb underline cursor-pointer font-size-14"
            onClick={openEngagementQuestionsModal}
          >
            Full question list
          </p>
          {isAnonymousTeamScores && selectableEngagementOptions && (
            <p className="font-size-14">
              The latest survey your team took part in was anonymous. View your team's average scores below.
            </p>
          )}
          {isAnonymousTeamScores && !selectableEngagementOptions && (
            <p className="font-size-14">
              The latest survey your team took part in was anonymous.
              Fewer than 4 members of your team have completed the survey so we are unable
              {' '}to show your team's average scores.
            </p>
          )}
          {selectableEngagementOptions && selectableEngagementOptions.length ? (
            selectableEngagementOptions.map((option) => (
              <SelectableEngagementSurveyQuestionCard
                key={option.question_sub_area_id}
                agendaId={agendaId}
                option={option}
                isDisabled={isDisabled}
              />
            ))
          ) : null}
          {selectableEngagementOptions && !selectableEngagementOptions.length && (
            <p className="font-weight-bold font-size-14">
              No options available.
            </p>
          )}
        </div>
      ) : (
        <div className="margin-vertical-x-large font-size-14">
          <p className="font-weight-bold font-size-14">
            {isAgendaEmployee ? 'You have' : 'This employee has'}
            {' '}not completed an engagement survey yet so no results are available.
          </p>
          <p className="margin-bottom-large font-size-14">
            You can include questions in the agenda for discussion.
          </p>
          <p
            className="display-inline-block fwb underline cursor-pointer font-size-14"
            onClick={openEngagementQuestionsModal}
          >
            Full question list
          </p>
        </div>
      )}
    </CollapsableWell>
  );
}

const mapStateToProps = (state, props) => {
  return {
    selectableEngagementOptions: props.engagementScoresOptions && props.engagementScoresOptions.filter(
      option => !engagementQuestionOptionIsSelected(state, option)
    )
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    openEngagementQuestionsModal: () => {
      dispatch(
        openSimpleModal({
          body: (
            <EngagementQuestionsModal
              agendaId={ownProps.agendaId}
              engagementQuestions={ownProps.engagementQuestions}
              isDisabled={ownProps.isDisabled}
            />
          ),
          title: 'Engagement survey questions'
        })
      );
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectableEngagementSurveyQuestions);
