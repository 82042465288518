import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import ChadminList from '^/components/common/ChadminList';
import { mySurveyResponsesConfig } from '^/components/survey/config';
import { MY_SURVEY_RESPONSES_LIST } from '^/components/survey/constants';
import { ENGAGEMENT, MENTAL_HEALTH, QUESTIONNAIRE_TYPE_TO_READABLE_NAME } from '^/consts/questionnaire';
import {
  createEngagementSurveyAndRedirect,
  createMentalHealthSurveyAndRedirect
} from '^/actions/actionSequences';

const SelfSurveyButton = ({
  licensedQuestionnaireTypes,
  questionnaireType,
  clickHandler,
  text
}) => licensedQuestionnaireTypes.indexOf(questionnaireType) !== -1 ? (
  <button
    className="btn orange middle pull-left"
    onClick={clickHandler}
  >
    {text}
  </button>
) : (
  <button
    className="btn orange middle pull-left"
    title={`${QUESTIONNAIRE_TYPE_TO_READABLE_NAME[questionnaireType]} survey is unavailable`}
    disabled
  >
    {text}
  </button>
);

const MySurveyResponsesList = props => (
  <div className="will">
    <ChadminList
      collectionPath="survey/my-survey-responses"
      collectionName={MY_SURVEY_RESPONSES_LIST}
      columns={mySurveyResponsesConfig}
      ExtraControlsLeft={() => (
        <div>
          <SelfSurveyButton
            licensedQuestionnaireTypes={props.licensedQuestionnaireTypes}
            questionnaireType={ENGAGEMENT}
            clickHandler={props.createEngagementSurveyAndRedirect}
            text="Take the engagement survey"
          />
          <SelfSurveyButton
            licensedQuestionnaireTypes={props.licensedQuestionnaireTypes}
            questionnaireType={MENTAL_HEALTH}
            clickHandler={props.createMentalHealthSurveyAndRedirect}
            text="Take the mental health survey"
          />
        </div>
      )}
      hasInlineLabels
    />
  </div>
);

export default connect(undefined, {
  createEngagementSurveyAndRedirect,
  createMentalHealthSurveyAndRedirect
})(MySurveyResponsesList);
