import { hasFailed, isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { getHomepageMyGoals, GET_HOMEPAGE_MY_GOALS } from '^/components/dashboards/actions';
import GoalCard from '^/components/dashboards/GoalCard';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';


export function Stat({ number, title }) {
  return (
    <div className="widget-item-grey padding-base width-120 font-family-varela-round">
      <div className="font-size-38 margin-bottom-small">{number}</div>
      <div className="margin-bottom-none font-size-18">{title}</div>
    </div>
  );
}

export class MyGoals extends React.PureComponent {
  componentDidMount() {
    this.props.getHomepageMyGoals();
  }

  render() {
    const { data, isLoading, hasFailedToLoad } = this.props;

    const {
      goals = [],
      number_of_goals = 0,
      number_of_completed_goals = 0,
    } = data;

    if (hasFailedToLoad) {
      return (
        <p>Failed to load goals.</p>
      );
    }

    if (isLoading) {
      return (
        <Loading />
      );
    }

    const hasGoals = goals && Array.isArray(goals) && goals.length > 0;

    return (
      <div className="dashboard-widget-inner-container">
        <div className="flex-space-between align-items-center flex-wrap margin-bottom-large">
          <WidgetTitle title="My goals" image="/static/images/dashboards/goals.svg" />
          <div className="display-flex flex-gap-base">
            <Stat
              number={number_of_goals}
              title="Goals set"
              className="dashboard-stat"
            />
            <Stat
              number={number_of_completed_goals}
              title="Completed"
              className="dashboard-stat"
            />
          </div>
        </div>
        {
          hasGoals &&
          goals.map(goal => (
            <GoalCard key={goal.id} goal={goal} />
          )) || <p>You have not created any goals yet.</p>
        }
        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/goals/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    data: state.homepageMyGoals,
    isLoading: isPending(state.responses, GET_HOMEPAGE_MY_GOALS),
    hasFailedToLoad: hasFailed(state.responses, GET_HOMEPAGE_MY_GOALS),
  };
}

export default connect(mapStateToProps, { getHomepageMyGoals })(MyGoals);
