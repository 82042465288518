import composeReducers from '^/reducers/composeReducers';
import { Map, List, fromJS } from 'immutable';
import { getIn } from '^/utils';
import * as actions from '^/actions/actions';
import {
  GET_COLLECTION,
  UPDATE_LOCAL_COLLECTION,
  ADD_TO_COLLECTION,
  DELETE_FROM_COLLECTION,
  CLEAR_COLLECTION
} from '^/actions/collections';
import { UPDATE_ITEM, CREATE_ITEM } from '^/actions/items';


function collectionResult(collectionMap, action) {
  let collectionItems;
  if (action.type === GET_COLLECTION.SUCCESS) {

    const results = fromJS(getIn(action, ['payload', 'results'], []));

    if (action.meta.shouldAppend) {
      collectionItems = collectionMap
        .get('items', List())
        .concat(results);
    }
    else {
      collectionItems = results;
    }
  }
  else {
    collectionItems = collectionMap.get('items');
  }

  const hasMore = Boolean(getIn(action, ['payload', 'next'])) || false;
  const newState = collectionMap
    .set('searchString', action.meta.search)
    .set('filters', action.meta.filters)
    .set('page', action.meta.page)
    .set('hasMore', hasMore)
    .set('count', getIn(action, ['payload', 'count']))
    .set('ordering', action.meta.ordering)
    .set('reverseOrdering', action.meta.reverseOrdering)
    .set('pageSize', action.meta.pageSize);

  if (collectionItems) {
    return newState.set('items', collectionItems);
  }
  return newState;
}

function collectionsReducer(state = Map(), action) {
  const updateMultipleItems = collectionNames => updater =>
    List(collectionNames).reduce(
      (_state, collectionName) => _state.updateIn([collectionName, 'items'], List(), updater),
      state
    );
  const updateItems = updater => updateMultipleItems([
    action.meta.collectionName, action.meta.collectionName
  ])(updater);

  switch (action.type) {
    case GET_COLLECTION.REQUEST:
    case GET_COLLECTION.SUCCESS:
      const existing = state.get(action.meta.collectionName, Map());
      return state.set(
        action.meta.collectionName,
        collectionResult(existing, action)
      );
    case CREATE_ITEM.SUCCESS:
    case ADD_TO_COLLECTION.SUCCESS: {
      return updateItems(items => items.unshift(fromJS(action.payload)));
    }
    case DELETE_FROM_COLLECTION.SUCCESS: {
      const id = action.meta.id;
      return updateItems(
        items => items.filter(item => item.get('id') !== id)
      );
    }
    case UPDATE_LOCAL_COLLECTION:
    case UPDATE_ITEM.SUCCESS: {
      const id = action.payload.id;
      return updateItems(
        items => items.map(item => item.get('id') === id ? item.merge(fromJS(action.payload)) : item)
      );
    }
    case CLEAR_COLLECTION: {
      const collectionName = action.payload;
      return state.set(collectionName, Map());
    }
    case actions.SURVEY_SELECT_ALL_MEMBERS.SUCCESS:
    case actions.SURVEY_REMOVE_ALL_MEMBERS.SUCCESS:
      const members = state.getIn(['SETTINGS_TEAM_MEMBERS', 'items'], List())
        .map((member) => {
          return member.set(
            'is_survey_member',
            member.get('survey_sent') || action.type === actions.SURVEY_SELECT_ALL_MEMBERS.SUCCESS
          );
        });
      return state.setIn(['SETTINGS_TEAM_MEMBERS', 'items'], members);
    default:
      return state;
  }
}

export default composeReducers([
  collectionsReducer,
], Map());
