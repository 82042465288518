import React from 'react';
import { connect } from 'react-redux';
import { Column, Row, ModalRenderer } from '@dabapps/roe';
import Loading from '^/components/Loading';
import ActionsPicker from './ActionsPicker';
import { ToastContainer } from 'react-toastify';
import { isPending } from '@dabapps/redux-requests';
import { RETRIEVE_OKR, retrieveOkr, updateOkrDetailAndToast, UPDATE_OKR_DETAIL } from './actions';
import { OKR_STATUS_OPTIONS } from './constants';
import DeleteOkrBtn from './DeleteOkrBtn';

export class OkrDetail extends React.PureComponent {

  componentDidMount() {
    this.props.retrieveOkr();
  }

  render() {
    const { okr, isLoading, canEdit } = this.props;

    if (isLoading || !okr) {
      return <Loading />;
    }

    return (
      <div className="will padding-x-large">
        <ModalRenderer modals={this.props.modals} />
        <ToastContainer />
        <Row>
          <Column md={8}>
            <div className="margin-bottom-x-large">
              <h2 className="fwb font-size-18 margin-bottom-small">{okr.get('name')}</h2>
              <span className="font-size-14 margin-bottom-small display-flex">
                <p className="fwb margin-right-base margin-bottom-none">Linked to:</p>
                <p className="margin-bottom-none">{okr.get('parent_okr')}</p>
              </span>
              <p className="font-size-13 margin-bottom-small">Due: {okr.get('due_date')}</p>
              <div className="display-flex align-items-center">
                <div className="okr-detail-status-bar">
                  <div
                    className="okr-detail-status-bar-fill"
                    style={{ width: `${okr.get('percentage_complete')}%` }}
                  >
                  </div>
                </div>
                <p className="font-size-14 margin-bottom-none">{okr.get('percentage_complete')}% complete</p>
              </div>
              {canEdit && (
                <select
                  disabled={this.props.hasNoActions || this.props.isUpdating}
                  className="margin-top-large"
                  onChange={this.props.updateStatus}
                  value={this.props.okr.get('status')}
                >
                  {OKR_STATUS_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              )}
            </div>
          </Column>
          {canEdit &&
            <Column md={4}>
              <div className="text-align-right">
                <a
                  className="btn middle orange"
                  href={`/okrs/${okr.get('id')}/edit/`}
                >
                  Edit OKR
                </a>
              </div>
            </Column>
          }
        </Row>
        <ActionsPicker readonly />
        {canEdit && <DeleteOkrBtn okr={okr} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUserId: state.loggedInUser.get('id'),
    isLoading: isPending(state.responses, RETRIEVE_OKR),
    modals: state.modals,
    okr: state.okrData,
    hasNoActions: state.okrData && !state.okrData.get('actions').count(),
    isUpdating: isPending(state.responses, UPDATE_OKR_DETAIL),
  };
};

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    retrieveOkr: () => dispatch(retrieveOkr(ownProps.okrId)),
    getUpdateStatusFunction: (okr) => (event) => {
      dispatch(updateOkrDetailAndToast(ownProps.okrId, {
        actions: okr.get('actions').map(action => action.get('id')),
        status: event.target.value
      }));
    },
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    updateStatus: dispatchProps.getUpdateStatusFunction(
      stateProps.okr
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OkrDetail);
