import React from 'react'; // eslint-disable-line no-unused-vars
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { Column, Row } from '@dabapps/roe';

import { updateOrganisationEmailTemplateAndToastResponse } from '^/actions/actionSequences';
import AnchorTarget from '^/components/common/AnchorTarget';
import BackToTop from '^/components/common/BackToTop';
import { isPending } from '^/consts/responseStates';
import { ROE_TOTAL_COLUMS, EMAIL_TEMPLATE_NAMES} from './constants';
import CustomEmailTemplateForm from './CustomEmailTemplateForm';


const OrganisationCustomEmailTemplate = (props) => (
  <Row className="margin-bottom-x-large">
    <AnchorTarget id={props.templateType} />
    <Column md={ROE_TOTAL_COLUMS} lg={6}>
      <h4 className="margin-bottom-x-large font-weight-normal">
        {EMAIL_TEMPLATE_NAMES[props.templateType]}
      </h4>
      <CustomEmailTemplateForm
        {...props}
        footerExtras={
          <BackToTop className="float-right input-line-height" />
        }
      />
    </Column>
  </Row>
);


function mapStateToProps(state, props) {
  const { templateType } = props;
  const response = state.legacyResponses.getIn(['updateOrganisationEmailTemplate', templateType]);
  return {
    form: templateType,
    initialValues: state.organisationEmailSettings.get(templateType, Map()).toObject(),
    isUpdating: isPending(response),
    response,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onSubmit: (data) =>
      dispatch(updateOrganisationEmailTemplateAndToastResponse(props.templateType, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationCustomEmailTemplate);
