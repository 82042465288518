import { isPending } from '@dabapps/redux-requests';
import { ModalBody, ModalRenderer } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import Carousel, { CarouselItem } from '^/components/common/Carousel';
import Loading from '^/components/Loading';
import TrendAnalysisModal from '^/components/dashboards/TrendAnalysisModal';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import { REPORT_TYPES } from '^/components/custom-reports/constants';
import {
  getTrendAnalysisReportsList,
  GET_TREND_ANALYSIS_WIDGET_LIST,
  getTrendAnalysisReportsNextPage,
  GET_TREND_ANALYSIS_WIDGET_NEXT_PAGE
} from '^/components/dashboards/actions';
import TrendAnalysisLineChart from '^/components/dashboards/TrendAnalysisLineChart';

export const TrendAnalysisInnerWidget = ({
  name,
  type,
  report,
  openModal
}) => {
  if (type === REPORT_TYPES.TREND_ANALYSIS_LINE) {
    return (
      <div className="trend-analysis-inner-widget">
        <div className="flex-space-between align-items-center margin-bottom-base">
          <h4 title={name} className="font-size-24">{name}</h4>
          {report.cached_chart_data && (
            <button
              className="pink-button width-120"
              onClick={openModal}
            >
              View
            </button>
          )}
        </div>
        <TrendAnalysisLineChart report={report} />
      </div>
    );
  } else {
    return (
      <div className="trend-analysis-inner-widget-heatmap text-align-center width-300">
        <div className="flex-column align-items-center">
          <h4 title={name} className="font-size-24">{name}</h4>
          <img src="/static/images/heatmap-widget.png" alt="heatmap" className="margin-top-base" />
          <button
            className="pink-button width-120 margin-top-base margin-bottom-large"
            onClick={openModal}
          >
            View
          </button>
        </div>
      </div>
    );
  }
};

function mapDispatchToPropsInnerWidget(dispatch, props) {
  return {
    openModal: () => {
      dispatch(
        openSimpleModal({
          body: (
            <ModalBody className="custom-report-modal-body">
              <TrendAnalysisModal
                reportId={props.report.id}
                organisationFilterTerms={props.organisationFilterTerms}
                isMyDashboard={props.isMyDashboard}
                publishedVersionQueued={props.report.published_version_queued}
              />
            </ModalBody>
          ),
          large: true,
          titleComponent: (
            <h1 className="font-size-28 padding-left-base padding-bottom-base margin-bottom-none">
              View report
            </h1>
          )
        })
      );
    }
  };
}

const ConnectedTrendAnalysisInnerWidget = connect(
  undefined,
  mapDispatchToPropsInnerWidget
)(TrendAnalysisInnerWidget);

export class TrendAnalysisWidget extends React.PureComponent {
  componentDidMount() {
    this.props.getTrendAnalysisReportsList(this.props.listFilter);
  }

  render() {
    const { isLoading, reports, organisationFilterTerms } = this.props;

    if (isLoading) {
      return (
        <div className="dashboard-widget-inner-container">
          <WidgetTitle
            title="Trend analysis"
            image="/static/images/dashboards/trendanalysis.svg"
          />
          <Loading />
        </div>
      );
    }

    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title="Trend analysis"
          image="/static/images/dashboards/trendanalysis.svg"
        />
        {reports.length ? (
          <Carousel>
            {reports.map(report => (
              <CarouselItem key={report.id}>
                <ConnectedTrendAnalysisInnerWidget
                  key={report.id}
                  report={report}
                  name={report.name}
                  type={report.type}
                  organisationFilterTerms={organisationFilterTerms}
                  isMyDashboard={this.props.isMyDashboard}
                />
              </CarouselItem>
            ))}
            {this.props.hasNextPage && (
              <div className="padding-right-xxx-large padding-top-xx-large flex-shrink-0">
                <button
                  onClick={this.props.getNextPage}
                  className={classNames(
                    'display-block',
                    'link-color-text',
                    'background-none',
                    'underline',
                    'padding-none',
                    'font-size-14',
                    'margin-top-small',
                    'line-height-36px'
                  )}
                  disabled={this.props.isLoadingNextPage}
                >
                  Load more...
                </button>
              </div>
            )}
          </Carousel>
        ) : (
          <p>{this.props.emptyText}</p>
        )}
        {!this.props.noModalRenderer && (
          <ModalRenderer modals={this.props.modals} />
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    isLoading: isPending(state.responses, GET_TREND_ANALYSIS_WIDGET_LIST),
    isLoadingNextPage: isPending(state.responses, GET_TREND_ANALYSIS_WIDGET_NEXT_PAGE),
    reports: state.dashboardTrendAnalysisList.items,
    page: state.dashboardTrendAnalysisList.page,
    hasNextPage: state.dashboardTrendAnalysisList.hasNextPage,
    modals: state.modals
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  getTrendAnalysisReportsList: () => dispatch(getTrendAnalysisReportsList(props.listFilter)),
  getNextPageFunction: (currentPage) => () =>
    dispatch(getTrendAnalysisReportsNextPage(props.listFilter, currentPage + 1))
});

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    getNextPage: dispatchProps.getNextPageFunction(stateProps.page)
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TrendAnalysisWidget);
