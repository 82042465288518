import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { updateAgendaTalentSnapshotAndToast } from '^/components/agendas/actions';

class TalentSnapshotQuestionField extends React.PureComponent {

  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.props.input.onChange(value || null);
  }

  render() {
    return (
      <div className="talent-snapshot-question">
        <label htmlFor={this.props.name}>{this.props.question_text}</label>
        <div className="talent-snapshot-option-select">
          <select
            name={this.props.name}
            id={this.props.name}
            disabled={this.props.isDisabled}
            onChange={this.onChange}
            value={this.props.input.value}
          >
            <option value="">Please select</option>
            {this.props.options.map(option => (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export function TalentSnapshotForm(props) {
  return (
    <div>
      <h4 className="font-size-14 fwb">Talent snapshot</h4>
      <form onSubmit={props.handleSubmit}>
        {props.talentSnapshotOptions.map((question) => (
          <Field
            key={question.question_id}
            name={question.question_id}
            question_text={question.question_text}
            options={question.options}
            disabled={props.isDisabled}
            component={TalentSnapshotQuestionField}
            isDisabled={props.isDisabled}
          />
        ))}
      </form>
      <div className="display-flex">
        <button
          className="btn middle orange"
          onClick={props.handleSubmit}
          disabled={props.isDisabled || props.pristine}
        >
          Submit
        </button>
        <button
          className="btn middle orange hollow margin-left-large"
          onClick={props.resetSelectedValues}
          disabled={props.isDisabled || props.disableClearButton}
        >
          Clear
        </button>
      </div>
    </div>
  );
}

const TalentSnapshotFormified = reduxForm({
  form: 'talent-snapshot-form',
  enableReinitialize: true
})(TalentSnapshotForm);

function mapStateToProps(state) {
  const initialValues = state.editingAgenda.talent_snapshot_responses;
  return {
    initialValues,
    disableClearButton: !Object.entries(initialValues).length
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: (data) => dispatch(updateAgendaTalentSnapshotAndToast(ownProps.agendaId, data)),
    getResetFunction: (initialValues) => () => {
      dispatch(updateAgendaTalentSnapshotAndToast(
        ownProps.agendaId,
        Object.fromEntries(Object.entries(initialValues).map((entry) => [entry[0], null]))
      ));
    }
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    resetSelectedValues: dispatchProps.getResetFunction(stateProps.initialValues),
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TalentSnapshotFormified);
