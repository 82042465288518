import classnames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';

import { ACTION_STATUS_MAPPING } from '^/consts/actions';

export function getActionStatusBadgeClassName(action) {
  const baseClassName = 'action-badge';

  switch (action.get('status')) {
    case 'NOT_STARTED':
      return classnames(baseClassName, 'not-started', 'font-size-10');
    case 'IN_PROGRESS':
      return classnames(baseClassName, 'in-progress', 'font-size-10');
    case 'COMPLETED':
      return classnames(baseClassName, 'completed', 'font-size-10');
    default:
      return baseClassName;
  }
}

const ActionStatus = (props) => (
  <strong
    className={classnames(getActionStatusBadgeClassName(props.action), { solid: props.solid })}
  >
    {ACTION_STATUS_MAPPING.get(props.action.get('status'))}
  </strong>
);

ActionStatus.propTypes = {
  action: ImmutablePropTypes.map.isRequired,
};

export default ActionStatus;
