import * as React from 'react';  // eslint-disable-line no-unused-vars
import { SpacedGroup } from '@dabapps/roe';

import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';

export const getManagerListConfig = (organisationFilterTerms) => [
  {
    name: 'email',
    display_name: `${organisationFilterTerms.manager}`,
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <span className="display-block">{user.first_name} {user.last_name}</span>
        <span className="display-block text-help">{user.email}</span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team_filter_name',
    display_name: `${organisationFilterTerms.team}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.team_filter_name)}>{user.team_filter_name}</CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'department_filter_name',
    display_name: `${organisationFilterTerms.department}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.department_filter_name)}>
        {user.department_filter_name}
      </CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'location_filter_name',
    display_name: `${organisationFilterTerms.location}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.location_filter_name)}>
        {user.location_filter_name}
      </CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'unit_filter_name',
    display_name: `${organisationFilterTerms.unit}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.unit_filter_name)}>{user.unit_filter_name}</CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'manager_filter_name',
    display_name: `${organisationFilterTerms.manager} Filter`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.manager_filter_name)}>{user.manager_filter_name}</CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'managed_people_count',
    display_name: 'People',
    type: 'custom',
    customItemHandler: (user) => `manages ${user.managed_people_count} people`,
    sortable: false,
  },
  {
    name: 'actions',
    display_name: '',
    type: 'custom',
    customItemHandler: (user) => (
      <a href={`/people/manage-managers/${user.id}/`} className="btn orange hollow">view people</a>
    ),
    sortable: false,
  },
];

export const createGetManagedUsersConfig = (
  removeManager,
  isRemovingManager,
  openEditUserModal,
  organisationFilterTerms
) => (reloadPage) => [
  {
    name: 'email',
    display_name: 'Person',
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <span className="display-block">{user.first_name} {user.last_name}</span>
        <span className="display-block text-help">{user.email}</span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team_filter_name',
    display_name: `${organisationFilterTerms.team}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.team_filter_name)}>{user.team_filter_name}</CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'department_filter_name',
    display_name: `${organisationFilterTerms.department}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.department_filter_name)}>
        {user.department_filter_name}
      </CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'location_filter_name',
    display_name: `${organisationFilterTerms.location}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.location_filter_name)}>
        {user.location_filter_name}
      </CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'unit_filter_name',
    display_name: `${organisationFilterTerms.unit}`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.unit_filter_name)}>{user.unit_filter_name}</CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'manager_filter_name',
    display_name: `${organisationFilterTerms.manager} Filter`,
    type: 'custom',
    customItemHandler: (user) => (
      <CellContentOrEmpty condition={Boolean(user.manager_filter_name)}>{user.manager_filter_name}</CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'actions',
    display_name: 'Actions',
    type: 'custom',
    customItemHandler: (user) => (
      <SpacedGroup>
        <a
          className="btn orange hollow"
          onClick={() => openEditUserModal(user, reloadPage)}
        >
          Edit
        </a>
        <a
          className="btn danger hollow"
          onClick={() => removeManager(user.id, reloadPage)}
          disabled={isRemovingManager}
        >
          Remove
        </a>
      </SpacedGroup>
    ),
    sortable: false,
  },
];
