import React from 'react'; // eslint-disable-line no-unused-vars

import classNames from 'classnames';

import { QUESTION_TYPE_DISPLAY, QUESTION_TYPES } from '^/components/custom-questions/constants';
import { REORDER_QUESTION_DIRECTION } from '../constants';


export default class SelectedItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.moveQuestionUp = this.moveQuestionUp.bind(this);
    this.moveQuestionDown = this.moveQuestionDown.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.openEditQuestionModal = this.openEditQuestionModal.bind(this);
  }

  removeItem() {
    if (!this.props.disabled) {
      this.props.onClickRemove(this.props.question.get('id'));
    }
  }

  moveQuestionUp() {
    if (!this.props.disabled && !this.props.isFirstItem) {
      this.props.onClickReorder(this.props.question.get('id'), REORDER_QUESTION_DIRECTION.UP);
    }
  }

  moveQuestionDown() {
    if (!this.props.disabled && !this.props.isLastItem) {
      this.props.onClickReorder(this.props.question.get('id'), REORDER_QUESTION_DIRECTION.DOWN);
    }
  }

  openEditQuestionModal() {
    this.props.openEditQuestionModal(this.props.question);
  }

  render() {
    const { question, disabled, recommended, readOnly } = this.props;
    return (
      <div
        className={classNames('question-card selected', { disabled, recommended })}
        title={question.get('name')}
      >
        <div className="display-flex align-items-center">
          <button className="btn middle orange hollow" disabled={readOnly} onClick={this.openEditQuestionModal}>
            Edit
          </button>
          <div>
            <h6 className="title">{question.get('name')}</h6>
            <p className="text-help margin-none">
              {QUESTION_TYPE_DISPLAY[question.get('type')]}
              {question.get('type') === QUESTION_TYPES.SLIDER && (
                <span>
                  {' '}(
                    {question.get('slider_low_score_label')}/
                    {question.get('slider_mid_score_label')}/
                    {question.get('slider_high_score_label')}
                  )
                </span>
              )}
            </p>
          </div>
        </div>
        <div className="margin-left-auto display-flex align-items-center">
          {recommended && (
            <span className="recommended margin-left-large">
              Recommended
            </span>
          )}
          {!readOnly && (
            <div className="display-flex">
              <button
                className={classNames(
                  'question-card-icon-wrapper margin-left-large', {
                    'disabled': this.props.isFirstItem || disabled
                  }
                )}
                onClick={this.moveQuestionUp}
              >
                <i className="far fa-arrow-up" />
              </button>
              <button
                className={classNames(
                  'question-card-icon-wrapper margin-left-large', {
                    'disabled': this.props.isLastItem || disabled
                  }
                )}
                onClick={this.moveQuestionDown}
              >
                <i className="far fa-arrow-down" />
              </button>
              <button
                className={classNames('question-card-icon-wrapper margin-left-large', { disabled })}
                onClick={this.removeItem}
              >
                <i className="far fa-minus" />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
