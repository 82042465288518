import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { isPending } from '@dabapps/redux-requests';
import { getGoal, GET_GOAL } from './actions';
import PrivateIcon from '^/components/common/PrivateIcon';
import ProgressBar from '^/components/common/ProgressBar';
import Loading from '^/components/Loading';
import { FORMAT_DATE } from '^/utils';
import ActionsPicker from './ActionsPicker';
import { Column, Row, ModalRenderer } from '@dabapps/roe';
import { openSimpleModal } from '^/actions/modals';
import DeleteGoalModal from './DeleteGoalModal';


const ActionsCompleted = ({ goal }) => {
  const percentComplete = goal.get('percentage_complete', 0);
  const percentCompleteDisplay = `${percentComplete}%`;
  const isComplete = percentComplete === 100;
  const assignedByText = goal.get('assigned_by_text');

  return (
      <ProgressBar className="margin-vertical-base text-gray" percentComplete={percentComplete}>
        {`${percentCompleteDisplay} complete${isComplete ? '!' : ''}`}
        {assignedByText && (
          <span>{' '}&bull;{' '}{assignedByText}</span>
        )}
        {isComplete === 100 && (
          <span className="text-orange margin-left-large font-size-base">
            <i className="far fa-star" />
          </span>
        )}
      </ProgressBar>
  );
};

export class GoalDetail extends React.PureComponent {
  componentDidMount() {
    this.props.getGoal();
  }

  render() {
    if (this.props.isLoading || this.props.goal.isEmpty()) {
      return <Loading />;
    }

    return (
      <div className="will padding-x-large">
        <ModalRenderer modals={this.props.modals} />
        <ToastContainer />
        <Row>
          <Column md={8}>
            <h4 className="margin-bottom-base">
              {this.props.goal.get('name')}
            </h4>
            <h5 className="margin-bottom-base text-gray">
              Goal
              {' '}&bull; assigned by {this.props.goal.get('assigned_by')}{' '}
              {this.props.goal.get('is_confidential') && (
                <PrivateIcon itemName="goal" />
              )}
            </h5>
            <p className="margin-bottom-small font-size-14">
              {this.props.goal.get('description')}
            </p>
            <p className="margin-bottom-small font-size-14">
              Due: {moment(this.props.goal.get('due_date')).format(FORMAT_DATE)}
            </p>
            <div className="margin-bottom-large">
              <ActionsCompleted goal={this.props.goal} />
            </div>
          </Column>
          {this.props.canEdit && (
            <Column md={4}>
              <div className="text-align-right">
                <a
                  href={`/goals/${this.props.goal.get('id')}/edit/`}
                  className="btn middle orange"
                >
                  Edit goal
                </a>
              </div>
            </Column>
          )}
        </Row>
        <ActionsPicker readonly />
        {this.props.canEdit && (
          <div className="text-align-center margin-top-base padding-top-large">
            <button
              onClick={this.props.openDeleteGoalModal}
              className="btn middle danger hollow"
            >
              Delete goal
            </button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses, GET_GOAL),
    goal: state.goalData,
    modals: state.modals,
    canEdit:
      state.goalData.get('created_by') === state.loggedInUser.get('id') ||
      state.goalData.get('owner') === state.loggedInUser.get('id')
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    getGoal: () => dispatch(getGoal(ownProps.goalId)),
    openDeleteGoalModal: () =>
      dispatch(
        openSimpleModal({
          body: <DeleteGoalModal goalId={ownProps.goalId} />,
          title: 'Delete goal',
        })
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalDetail);
