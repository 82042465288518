import { isPending, hasFailed } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';
import {
    getAdminDashboardPeerFeedbackStats,
    GET_ADMIN_DASHBOARD_PEER_FEEDBACK_STATS
} from '^/components/dashboards/actions';
import { PEER_FEEDBACK_TYPES, feedbackTypeLabelMap } from '^/components/peer-feedback/constants';

const FeedbackTypeCard = ({type, total}) => (
  <div className="widget-item-grey margin-bottom-x-large font-family-varela-round">
    <div className="font-size-38 margin-bottom-small">
      {total}
    </div>
    <div className="font-size-18 line-height-18px margin-bottom-small">
      {feedbackTypeLabelMap[type]}
    </div>
  </div>
);

export class AdminPeerFeedbackWidget extends React.PureComponent {

  componentDidMount() {
    this.props.getAdminDashboardPeerFeedbackStats();
  }

  renderContent() {
    const { isLoading, hasFailedLoading, peerFeedbackStats } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    if (hasFailedLoading) {
      return <p>Failed to load peer feedback data.</p>;
    }

    return (
      <div>
        <FeedbackTypeCard
          type={PEER_FEEDBACK_TYPES.RECOGNITION}
          total={peerFeedbackStats.get(PEER_FEEDBACK_TYPES.RECOGNITION)}
        />
        <FeedbackTypeCard
          type={PEER_FEEDBACK_TYPES.SUGGESTIONS}
          total={peerFeedbackStats.get(PEER_FEEDBACK_TYPES.SUGGESTIONS)}
        />
        <FeedbackTypeCard
          type={PEER_FEEDBACK_TYPES.GENERAL}
          total={peerFeedbackStats.get(PEER_FEEDBACK_TYPES.GENERAL)}
        />
        <div className="margin-top-x-large text-align-center">
          <a
            className="widget-view-all-button"
            href="/people/peer-feedback/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title="All feedback received"
          image="/static/images/dashboards/feedback.png"
        />
        {this.renderContent()}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses, GET_ADMIN_DASHBOARD_PEER_FEEDBACK_STATS),
    hasFailedLoading: hasFailed(state.responses, GET_ADMIN_DASHBOARD_PEER_FEEDBACK_STATS),
    peerFeedbackStats: state.adminDashboardPeerFeedbackStats
  };
}

export default connect(mapStateToProps, { getAdminDashboardPeerFeedbackStats })(AdminPeerFeedbackWidget);
