import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import classNames from 'classnames';

import { getHomepageSnapshotData, GET_HOMEPAGE_SNAPSHOT_DATA} from '^/components/dashboards/actions';
import Loading from '^/components/Loading';
import WidgetTitle from '^/components/dashboards/WidgetTitle';


const SnapshotQuestionCard = ({question, answer, total}) => (
  <div className="widget-item-hollow snapshot-large-card margin-bottom-large">
    <div className="font-size-38 font-family-varela-round">{total}</div>
    <div className="margin-left-x-large">
      <div className="fwb">{question}</div>
      <div className="margin-top-small">{answer}</div>
    </div>
  </div>
);

const optionTextMap = {
  'QUALITY_OF_WORK_EXCEEDS': {
    question: 'Quality of work',
    response: 'Exceeds expectations',
  },
  'VALUE_TO_COMPANY_EXCEEDS': {
    question: 'Value to company',
    response: 'Exceeds expectations',
  },
  'ATTITUDE_TO_TEAMWORK_EXCEEDS': {
    question: 'Attitude to teamwork',
    response: 'Exceeds expectations',
  },
  'LEVELS_OF_SKILLS_AND_KNOWLEDGE_EXCEEDS': {
    question: 'Level of skills and knowledge',
    response: 'Exceeds expectations',
  },
  'CAREER_PREFERENCE_PROMOTION': {
    question: 'Career preference',
    response: 'Seek to promote',
  },
  'SALARY_LEVEL_REVIEW': {
    question: 'Salary level',
    response: 'Needs reviewing',
  },
  'PROMOTION_ASSESSMENT_PROMOTE': {
    question: 'Promotional assessment',
    response: 'Look to promote',
  },
  'QUALITY_OF_WORK_BELOW': {
    question: 'Quality of work',
    response: 'Below expectations',
  },
  'VALUE_TO_COMPANY_BELOW': {
    question: 'Value to company',
    response: 'Below expectations',
  },
  'ATTITUDE_TO_TEAMWORK_BELOW': {
    question: 'Attitude to teamwork',
    response: 'Below expectations',
  },
  'LEVELS_OF_SKILLS_AND_KNOWLEDGE_BELOW': {
    question: 'Level of skills and knowledge',
    response: 'Below expectations',
  },
  'CAREER_PREFERENCE_LEAVE': {
    question: 'Career preference',
    response: 'Considering leaving',
  },
  'SALARY_LEVEL_TOO_HIGH': {
    question: 'Salary level',
    response: 'Too high'
  },
  'PROMOTION_ASSESSMENT_PAY_REVIEW': {
    question: 'Promotional assessment',
    response: 'Pay review in current role',
  },
};

const SHOW_MORE = 2;

export class TalentSnapshotWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMoreItems: false
    };
    this.toggleShowMoreItems = this.toggleShowMoreItems.bind(this);
  }

  componentDidMount() {
    this.props.getHomepageSnapshotData(this.props.isManagerDashboard);
  }

  toggleShowMoreItems() {
    this.setState({showMoreItems: !this.state.showMoreItems});
  }

  render() {
    const { snapshotData, isLoading } = this.props;
    const hasMoreRecognitionItems = (snapshotData.ordered_recognition_items || []).length > SHOW_MORE;
    const hasMoreRiskItems = (snapshotData.ordered_risk_items || []).length > SHOW_MORE;

    if (isLoading) {
      return (
        <div className="dashboard-widget-inner-container">
          <WidgetTitle
            title="Talent snapshot"
            image="/static/images/dashboards/snapshots.svg"
          />
          <Loading />
        </div>
      );
    }

    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title="Talent snapshot"
          image="/static/images/dashboards/snapshots.svg"
        />

        <div className="display-flex flex-gap-xx-large font-family-varela-round">
          <div className="widget-item-grey third-width">
            <div className="font-size-38 margin-bottom-small">
              {snapshotData.total_below}
            </div>
            <div className="font-size-18 line-height-36px margin-bottom-small">
              Risks
            </div>
            <div className="color-bar background-red"></div>
          </div>
          <div className="widget-item-grey third-width">
            <div className="font-size-38 margin-bottom-small">
              {snapshotData.total_exceeds}
            </div>
            <div className="font-size-18 line-height-18px margin-bottom-small">
              Deserves<div>recognition</div>
            </div>
            <div className="color-bar background-green"></div>
          </div>
          <div className="widget-item-grey third-width">
            <div className="font-size-38 margin-bottom-small">
              {snapshotData.total_in_line}
            </div>
            <div className="font-size-18 line-height-36px margin-bottom-small">
              Neutral
            </div>
            <div className="color-bar background-gray"></div>
          </div>
        </div>
        <div className="snapshot-breakdown-container margin-top-large margin-bottom-small">
          <div className="font-size-18 heading font-family-varela-round">
            At risk
          </div>
          <div className="font-size-18 heading font-family-varela-round">
            Deserves recognition
          </div>
        </div>
        <div className="snapshot-breakdown-container margin-top-none">
          <div className="width-50-percent">
            {(snapshotData.ordered_risk_items || []).length ? (
              <div>
                {snapshotData.ordered_risk_items.slice(0, SHOW_MORE).map((item) => (
                  <SnapshotQuestionCard
                    question={optionTextMap[item.option_text].question}
                    answer={optionTextMap[item.option_text].response}
                    total={item.count}
                    key={optionTextMap[item.option_text].question}
                  />
                ))}
                {hasMoreRiskItems && (
                  <div className={classNames({
                    'display-none': !this.state.showMoreItems
                  })}>
                    {snapshotData.ordered_risk_items.slice(SHOW_MORE).map((item) => (
                      <SnapshotQuestionCard
                        question={optionTextMap[item.option_text].question}
                        answer={optionTextMap[item.option_text].response}
                        total={item.count}
                        key={optionTextMap[item.option_text].question}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <p>None yet.</p>
            )}
          </div>
          <div className="width-50-percent">
            {(snapshotData.ordered_recognition_items || []).length ? (
              <div>
                {snapshotData.ordered_recognition_items.slice(0, SHOW_MORE).map((item) => (
                  <SnapshotQuestionCard
                    question={optionTextMap[item.option_text].question}
                    answer={optionTextMap[item.option_text].response}
                    total={item.count}
                    key={optionTextMap[item.option_text].question}
                  />
                ))}
                {hasMoreRecognitionItems && (
                  <div className={classNames({
                    'display-none': !this.state.showMoreItems
                  })}>
                    {snapshotData.ordered_recognition_items.slice(SHOW_MORE).map((item) => (
                      <SnapshotQuestionCard
                        question={optionTextMap[item.option_text].question}
                        answer={optionTextMap[item.option_text].response}
                        total={item.count}
                        key={optionTextMap[item.option_text].question}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <p>None yet.</p>
            )}
          </div>
        </div>
        {(hasMoreRecognitionItems || hasMoreRiskItems) && (
          <div className="margin-bottom-x-large">
            <button
              className="link-color-text background-none underline padding-none font-size-14"
              onClick={this.toggleShowMoreItems}
            >
              Show {this.state.showMoreItems ? 'less' : 'more'}
            </button>
          </div>
        )}
        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/agendas/talent-snapshots/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses, GET_HOMEPAGE_SNAPSHOT_DATA),
    snapshotData: state.homepageSnapshotData
  };
}

export default connect(mapStateToProps, { getHomepageSnapshotData })(TalentSnapshotWidget);
