import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';

import Loading from '^/components/Loading';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import {
  getAdminDashboardPerformanceReviewData,
  GET_ADMIN_DASHBOARD_PERFORMANCE_REVIEW_DATA
} from '^/components/dashboards/actions';

export class AdminPerformanceReviewWidget extends React.PureComponent {
  componentDidMount() {
    this.props.getData();
  }

  render() {
    const { isLoading, data } = this.props;

    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title="Performance review surveys"
          image="/static/images/dashboards/reviews.svg"
          subtitle="In the last 6 months"
        />
        {isLoading && data && <Loading />}

        {!isLoading && (
          <div>
            <div className="widget-item-hollow margin-bottom-large font-family-varela-round">
              <div className="font-size-38 margin-bottom-large">
                {data.get('sent_count')}
              </div>
              <div className="font-size-18">
                Sent
              </div>
            </div>
            <div className="widget-item-grey margin-bottom-x-large font-family-varela-round">
              <div className="font-size-38 margin-bottom-large">
                {data.get('completed_count')}
              </div>
              <div className="font-size-18">
                Completed
              </div>
              <div className="completed-bar background-green">
              </div>
            </div>
          </div>
        )}

        <div className="margin-top-auto text-align-center">
          <a
            className="widget-view-all-button"
            href="/reviews/"
          >
            View all
          </a>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses, GET_ADMIN_DASHBOARD_PERFORMANCE_REVIEW_DATA),
    data: state.adminDashboardPerformanceReviewSurveys
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getData: () => dispatch(getAdminDashboardPerformanceReviewData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPerformanceReviewWidget);
