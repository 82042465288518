import { Map } from 'immutable';
import { BEGIN_SCALE_IMAGE, COMPLETE_SCALE_IMAGE } from './actions';


export function isImageProcessing(state = Map(), action) {
  switch (action.type) {
    case BEGIN_SCALE_IMAGE:
      return state.set(action.payload.uploadId, true);
    case COMPLETE_SCALE_IMAGE:
      return state.set(action.payload.uploadId, false);
    default:
      return state;
  }
}
