import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import Loading from '^/components/Loading';
import { getCustomReport } from '^/components/custom-reports/actions';
import ReportSection from '^/components/custom-reports/ReportSection';

export class TrendAnalysisModal extends React.PureComponent {

  componentDidMount() {
    if (!this.props.reportList.length) {
      this.props.getReport();
    }
  }

  render() {
    if (!this.props.reportList.length) {
      return (
        <div className="custom-report-loading">
          <Loading />
        </div>
      );
    }

    return (
      <div>
        {/*
          This needs to render with map so that ReportSection.componentDidMount and CustomReportFilters.constructor
          always run when a report is switched out for a filtered version.
        */}
        {this.props.reportList.map(report => (
          <ReportSection
            key={report.id}
            report={report}
            organisationFilterTerms={this.props.organisationFilterTerms}
            isMyDashboard={this.props.isMyDashboard}
            publishedVersionQueued={this.props.publishedVersionQueued}
            isDashboardModal
          />
        ))}
      </div>
    );
  }
}

export const mapStateToProps = (state, props) => {
  return {
    reportList: state.customReportsList.filter(report =>
      report.id === props.reportId ||
      report.unfiltered_report_id === props.reportId
    ),
  };
};

function mapDispatchToProps(dispatch, props) {
  return {
    getReport: () => dispatch(getCustomReport(props.reportId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendAnalysisModal);
