import * as React from 'react';
import { ModalFooter, ModalBody, Button, SpacedGroup } from '@dabapps/roe';
import { deleteCustomQuestionAndCloseModal } from '^/actions/actionSequences';
import { connect } from 'react-redux';
import { closeModal } from '^/actions/modals';

export class DeleteCustomQuestionModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.deleteQuestionAndCloseModal = this.deleteQuestionAndCloseModal.bind(this);
  }

  deleteQuestionAndCloseModal() {
    this.props.deleteCustomQuestionAndCloseModal(this.props.question.id);
  }

  render() {
    return (
      <div>
        <ModalBody>
          <p>
            Do you want to delete the custom question "
            {this.props.question.name}" Note: this will remove it from the
            custom question selection list, but it will still remain on surveys
            where used.
          </p>
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button className="btn gray middle" onClick={this.props.closeModal}>
              Cancel
            </Button>
            <Button
              className="btn danger middle"
              onClick={this.deleteQuestionAndCloseModal}
            >
              Delete
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </div>
    );
  }
}

export default connect(null, {
  deleteCustomQuestionAndCloseModal,
  closeModal
})(DeleteCustomQuestionModal);
