import classnames from 'classnames';
import moment from 'moment';
import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import { ACTION_URGENCY, ACTION_URGENCY_PERCENTAGE } from './const';
import { ACTION_STATUS } from '^/consts/actions';

function checkUrgency(action) {

  if (action.get('status') === ACTION_STATUS.COMPLETED) {
    return ACTION_URGENCY.COMPLETED;
  }

  const currentDate = moment();
  const actionDueDate = moment(action.get('due_date'));
  const actionCreatedDate = moment(action.get('created_date')).format('YYYY-MM-DD');
  const daysActionDueFromCurrentDate = actionDueDate.diff(currentDate, 'days');

  if (daysActionDueFromCurrentDate < 0) {
    return ACTION_URGENCY.OVERDUE;
  }

  const daysActionCreatedToActionDue = actionDueDate.diff(actionCreatedDate, 'days');
  const percentageOfActionDuration = (daysActionCreatedToActionDue / 100) * ACTION_URGENCY_PERCENTAGE;
  const daysRemainingToCompleteAction = daysActionCreatedToActionDue - percentageOfActionDuration;

  if (daysActionDueFromCurrentDate <= daysRemainingToCompleteAction) {
    return ACTION_URGENCY.DUE_SOON;
  }

  return ACTION_URGENCY.NON_URGENT;

}

function getUrgencyDisplayText(action) {

  const urgency = checkUrgency(action);
  if (urgency === ACTION_URGENCY.COMPLETED || urgency === ACTION_URGENCY.NON_URGENT) {
    return '';
  }
  return urgency;
}

export function getActionUrgencyChipClassName(action, className) {

  const urgency = checkUrgency(action);
  const baseClassName = className;

  switch (urgency) {
    case ACTION_URGENCY.COMPLETED:
      return classnames(baseClassName, 'far', 'fa-check', 'completed');
    case ACTION_URGENCY.OVERDUE:
      return classnames(baseClassName, 'overdue');
    case ACTION_URGENCY.DUE_SOON:
      return classnames(baseClassName, 'due-soon');
    default:
      return baseClassName;
  }
}

const ActionUrgency = (props) => (
  <span
    className={classnames(getActionUrgencyChipClassName(props.action, props.baseClassName))}
  >
    {getUrgencyDisplayText(props.action)}
  </span>
);

ActionUrgency.propTypes = {
  action: ImmutablePropTypes.map.isRequired,
};

export default ActionUrgency;
