import { GET_COLLECTION } from '^/actions/collections';
import { MANAGED_USERS_LIST } from './ManagedUsersList';


export function managedUsersCount(state = 0, action) {
  switch (action.type) {
    case GET_COLLECTION.SUCCESS:
      if (action.meta.collectionName === MANAGED_USERS_LIST) {
        return action.payload.count;
      }
    default:
      return state;
  }
}
