import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { openSimpleModal } from '^/actions/modals';
import { createAgendaPeerFeedbackAndToast } from './actions';
import PeerFeedbackCard from './PeerFeedbackCard';
import PeerFeedbackModal from './PeerFeedbackModal';

export const AddPeerFeedbackToAgendaCard = ({
  item,
  isDisabled,
  updateAgenda,
  openModal,
}) => {
  return (
    <PeerFeedbackCard
      item={item}
      isDisabled={isDisabled}
      updateAgenda={updateAgenda}
      openModal={openModal}
      showReadInFull
      truncateMessage
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAgenda: () => {
      dispatch(createAgendaPeerFeedbackAndToast(ownProps.agendaId, ownProps.item.feedback_id));
    },
    openModal: () => {
      dispatch(
        openSimpleModal({
          body: (
            <PeerFeedbackModal
              agendaId={ownProps.agendaId}
              item={ownProps.item}
              isDisabled={ownProps.isDisabled}
            />
          ),
          title: 'Peer feedback'
        })
      );
    }
  };
};

export default connect(undefined, mapDispatchToProps)(AddPeerFeedbackToAgendaCard);
