import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line
import { toast } from 'react-toastify';
import _ from 'underscore';

import NotificationBody from '^/components/common/NotificationBody';
import { MANAGE_RESOURCES_PATH, MANAGE_RESOURCES_UPDATE_WEIGHT_PATH } from '^/components/resources/constants';
import { SETTINGS_TEAM_MEMBERS } from '^/components/survey/SettingsUserList';
import { USERS_PATH, CREATE_USERS_PATH } from '^/components/users/const';
import store from '^/store';
import ActionChain from './ActionChain';
import * as actions from './actions';
import { updateCollection, getCollection } from './collections';
import * as items from './items';
import { closeModal } from './modals';
import { CUSTOM_QUESTIONS_LIST } from '^/components/custom-questions/constants';
import { REMINDER_ALL_MESSAGE } from '../components/people/constants';
import { getCustomQuestionSettings } from '^/actions/actions';

export function makeActionChain(sequence) {
  return _.reduce(
    _.rest(sequence),
    (chain, step) => step.onSuccess ? chain.then(step.onSuccess, step.onFail) : chain.then(step),
    new ActionChain(_.first(sequence))
  ).process();
}

export function createItemAndRunCallback(model, data, collectionName, formName, callback) {
  return makeActionChain([
    () => items.createItem(model, data, collectionName),
    newItem => callback(model, data, collectionName, formName, newItem),
  ]);
}
export function patchItemAndRunCallback(model, itemId, data, collectionName, callback) {
  return makeActionChain([
    () => items.patchItem(model, itemId, data, collectionName),
    newItem => callback(model, itemId, data, collectionName, newItem),
  ]);
}

const setIsSurveyMemberOnTeamMember = (teamMember, isSurveyMember) =>
  Object.assign({}, teamMember, {is_survey_member: isSurveyMember});

export function addInSurveyAndUpdate(surveyId, membersIds, teamMember) {
  return makeActionChain([
    () => actions.addToSurvey(surveyId, membersIds),
    () => updateCollection(SETTINGS_TEAM_MEMBERS, setIsSurveyMemberOnTeamMember(teamMember, true))
  ]);
}

export function removeFromSurveyAndUpdate(surveyId, membersIds, teamMember) {
  return makeActionChain([
    () => actions.removeFromSurvey(surveyId, membersIds),
    () => updateCollection(SETTINGS_TEAM_MEMBERS, setIsSurveyMemberOnTeamMember(teamMember, false))
  ]);
}

export function removeFromSurveyCloseModalAndReload(surveyId, membersIds, reloadPage) {
  return makeActionChain([
    () => actions.removeFromSurvey(surveyId, membersIds),
    closeModal,
    reloadPage,
  ]);
}

export function resyncSurveyTeamMembersAndCloseModal(surveyId, NotificationComponent) {
  return makeActionChain([
    () => actions.resyncSurveyTeamMembers(surveyId),
    () => closeModal(),
    () => toast(<NotificationComponent />)
  ]);
}


export function sendTestEmailAndShowOutcome(surveyId, template_type) {
  return makeActionChain([
    () => actions.sendTestEmail(surveyId, template_type),
    {
      onSuccess: () => toast.success(<NotificationBody text="Test email sent."/>),
      onFail: () => toast.error(<NotificationBody text="Test email failed to send."/>),
    }
  ]);
}


export function updateOrganisationEmailTemplateAndToastResponse(templateType, data) {
  return makeActionChain([
    () => actions.updateOrganisationEmailTemplate(templateType, data),
    {
      onSuccess: () => toast.success('Custom email updated.'),
      onFail: () => toast.error('There was a problem updating your custom email.'),
    }
  ]);
}

export function updateSurveyEmailTemplateAndToastResponse(surveyId, templateType, data) {
  return makeActionChain([
    () => actions.updateSurveyEmailTemplate(surveyId, templateType, data),
    {
      onSuccess: () => toast(<NotificationBody title="Email settings updated." />),
      onFail: () => toast(<NotificationBody title="There was a problem updating your email settings." />),
    }
  ]);
}

export function createCustomQuestionDisplayToastCloseModalAndLoadList(
  surveyId,
  data
) {
  return makeActionChain([
    () => actions.createCustomQuestion(surveyId, data),
    {
      onSuccess: () => {
        store.dispatch(closeModal());
        toast.success(<NotificationBody text="Question created." />);
        if (!surveyId) {
          store.dispatch(getCollection(
            'custom-questions',
            {},
            CUSTOM_QUESTIONS_LIST
          ));
        }
      },
      onFail: () => toast.error(<NotificationBody text="There was a problem creating your question." />),
    },
  ]);
}

export function updateCustomQuestionAndCloseModal(
  surveyId,
  questionId,
  data
) {
  return makeActionChain([
    () => actions.updateCustomQuestion(questionId, data),
    {
      onSuccess: () => {
        store.dispatch(closeModal()).then(() => {
          if (surveyId) {
            store.dispatch(
              getCustomQuestionSettings(surveyId)
            );
          } else {
            store.dispatch(getCollection(
              'custom-questions',
              {},
              CUSTOM_QUESTIONS_LIST
            ));
          }
          toast.success(<NotificationBody text="Question updated." />);
        });
      },
      onFail: () => toast.error(<NotificationBody text="There was a problem updating this question." />),
    },
  ]);
}

export function deleteCustomQuestionAndCloseModal(
  questionId
) {
  return makeActionChain([
    () => actions.deleteCustomQuestion(questionId),
    {
      onSuccess: () => {
        toast(<NotificationBody title="Question deleted." />);
      },
      onFail: () => toast(<NotificationBody title="There was a problem deleting your question." />),
    },
    () => closeModal(),
    () => store.dispatch(getCollection(
      'custom-questions',
      {},
      CUSTOM_QUESTIONS_LIST
    ))
  ]);
}

export function updateCustomQuestionSettingsAndToast(surveyId, data) {
  return makeActionChain([
    () => actions.updateCustomQuestionSettings(surveyId, data),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success(<NotificationBody text="Custom question settings updated." />);
      },
      onFail: () => {
        toast.dismiss();
        toast.error(<NotificationBody text="There was a problem updating custom question settings." />);
      },
    }
  ]);
}

export function assignManagerNotiftyAndReloadUsers(manager, users, reloadUsers) {
  return (dispatch) => {
    return actions.assignManager(manager, users)(dispatch).then(() => {
      dispatch(actions.resetResponse(actions.ASSIGN_MANAGER));
      dispatch(actions.clearAllSelectedListItems());
      dispatch(closeModal());
      reloadUsers();
      toast.dismiss();
      toast.success(<NotificationBody text="Manager Assigned." />);
    }).catch(() => {
      toast.dismiss();
      toast.error(<NotificationBody text="There was a problem assigning a manager." />);
    });
  };
}

export function submitUserCloseModalAndReload(data, userId, reloadUsers) {
  const verb = userId ? 'update' : 'create';
  return makeActionChain([
    userId ?
    () => items.updateItem(USERS_PATH, userId, data) :
    () => items.createItem(CREATE_USERS_PATH, data),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success(
          <NotificationBody text={`User ${data.first_name} ${verb}d.`} />
        );
      },
      onFail: () => {
        toast.dismiss();
        toast.error(
          <NotificationBody text={`Failed to ${verb} user.`} />
        );
      },
    },
    closeModal,
    () => store.dispatch(actions.getSubscriptionAllowances()),
    reloadUsers,
  ]);
}

export function submitResourceCloseModalAndReload(data, resourceId, reloadResources) {
  const verb = resourceId ? 'update' : 'create';
  return makeActionChain([
    resourceId ?
    () => items.updateItem(MANAGE_RESOURCES_PATH, resourceId, data) :
    () => items.createItem(MANAGE_RESOURCES_PATH, data),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success(
          <NotificationBody text={`Resource ${data.display_name} ${verb}d.`} />
        );
      },
      onFail: () => {
        toast.dismiss();
        toast.error(
          <NotificationBody text={`Failed to ${verb} resource.`} />
        );
      },
    },
    closeModal,
    () => store.dispatch(actions.getResourcesFilterOptions()),
    reloadResources,
  ]);
}

export function updateResourceWeightAndReload(resource, weight, reloadResources) {
  return makeActionChain([
    () => items.updateItem(MANAGE_RESOURCES_UPDATE_WEIGHT_PATH, resource.id, { weight }),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success(
          <NotificationBody text={`Resource ${resource.display_name} updated.`} />
         );
      },
      onFail: () => {
        toast.dismiss();
        toast.error(
          <NotificationBody text="Failed to update resource." />
        );
      },
    },
    reloadResources,
  ]);
}

export function deleteResourceCloseModalAndReload(resource, reloadResources) {
  return (dispatch) => {
    return actions.deleteResource(resource.id)(dispatch).then(() => {
      toast.dismiss();
      toast.success(
        <NotificationBody text={`Resource ${resource.display_name} deleted.`} />
      );
      dispatch(closeModal());
      reloadResources();
    }).catch(() => {
      toast.dismiss();
      toast.error(
        <NotificationBody text="Failed to delete resource." />
      );
    });
  };
};

export function removeManagerNotiftyAndReloadManagedUsers(manager, users, reloadManagedUsers) {
  return (dispatch) => {
    return actions.removeManager(manager, users)(dispatch).then(() => {
      dispatch(actions.resetResponse(actions.REMOVE_MANAGER));
      reloadManagedUsers();
      toast.dismiss();
      toast.success(<NotificationBody text="Employee Removed." />);
    }).catch(() => {
      toast.dismiss();
      toast.error(<NotificationBody text="There was a problem removing the employee." />);
    });
  };
}

export function sendSurveyAndRedirectToSurveys(survey, redirectTo) {
  return makeActionChain([
    () => actions.sendSurvey(survey),
    () => {
      window.location = redirectTo;
    }
  ]);
}

export function sendSurveyAndRedirectToPersonalReviewsForUser(surveyId, userId) {
  return makeActionChain([
    () => actions.sendSurvey(Map({id: surveyId})),
    () => {
      window.location.href = `/reviews/personal-reviews/${userId}/`;
    }
  ]);
}

export const createEngagementSurveyAndRedirect = () => (dispatch) =>
  actions.createEngagementSurvey()(dispatch).then(
    response => window.location.href = response.data
  );

export const createMentalHealthSurveyAndRedirect = () => (dispatch) =>
  actions.createMentalHealthSurvey()(dispatch).then(
    response => window.location.href = response.data
  );

export const sendBubbleRemindersAndToast = (selectedUsers, reminderType) => {
  return makeActionChain([
    () => actions.sendBubbleReminders(selectedUsers, reminderType),
    {
      onSuccess: () => {
        let recipientMessage;
        if (selectedUsers.length > 0) {
          recipientMessage = `${selectedUsers.length} user${selectedUsers.length > 1 ? 's' : ''}`;
        } else {
          recipientMessage = REMINDER_ALL_MESSAGE[reminderType];
        }
        toast.dismiss();
        toast.success(
          <NotificationBody
            text={
              `Successfully sent ${reminderType} reminder to ${recipientMessage}`
            }
          />
        );
      },
      onFail: () => {
        toast.dismiss();
        toast.error(<NotificationBody text="There was a problem sending reminders" />);
      }
    }
  ]);
};

export const updateActionAndDisplayToast = (actionId, actionData) => {
  return makeActionChain([
    () => actions.updateAction(actionId, actionData),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success('Action updated successfully');
      },
      onFail: () => {
        toast.dismiss();
        toast.error('Failed to update action');
      }
    }
  ]);
};

export function nudgeSurveyAndDisplayToast(survey) {
  return makeActionChain([
    () => actions.nudgeSurvey(survey),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success('Sent reminder(s)');
      },
      onFail: error => {
        toast.dismiss();
        toast.error(error.response.detail);
      }
    }
  ]);
}
