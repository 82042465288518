import React from 'react'; // eslint-disable-line no-unused-vars

import { reduxForm, Field } from 'redux-form';
import { Column, Row } from '@dabapps/roe';
import { connect } from 'react-redux';

import FormError from '^/components/forms/FormError';
import { CUSTOM_QUESTION_SETTINGS } from './constants';

const SurveyCustomQuestionsForm = (props) => {
  const {
    handleSubmit,
    updateResponse,
    readOnly,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row className="margin-top-large margin-bottom-small">
        <Column sm={12} md={2}>
        <label htmlFor="custom-question-heading" className="input-line-height">Title</label>
        </Column>
        <Column sm={12} md={10}>
          <Field
            id="custom-question-heading"
            component="input"
            type="text"
            name={CUSTOM_QUESTION_SETTINGS.HEADING}
            placeholder="Enter title"
            readOnly={readOnly}
          />
          <FormError response={updateResponse} formKey={CUSTOM_QUESTION_SETTINGS.HEADING} />
        </Column>
      </Row>
      <Row className="margin-top-small margin-bottom-xx-large">
        <Column sm={12} md={2}>
          <label htmlFor="custom-question-description" className="input-line-height">Description</label>
        </Column>
        <Column sm={12} md={10}>
          <Field
            id="custom-question-description"
            component="input"
            type="text"
            name={CUSTOM_QUESTION_SETTINGS.DESCRIPTION}
            placeholder="Enter description"
            readOnly={readOnly}
          />
          <FormError response={updateResponse} formKey={CUSTOM_QUESTION_SETTINGS.DESCRIPTION} />
        </Column>
      </Row>
    </form>
  );
};

function mapStateToProps(state) {
  const updateResponse = state.legacyResponses.get('updateCustomQuestionSettings');
  return {
    updateResponse,
  };
}


export const SURVEY_CUSTOM_QUESTIONS_FORM = 'survey-custom-questions';
const FormifiedSurveyCustomQuestionsForm = reduxForm({ form: SURVEY_CUSTOM_QUESTIONS_FORM })(SurveyCustomQuestionsForm);
export { FormifiedSurveyCustomQuestionsForm, SurveyCustomQuestionsForm };
export default connect(mapStateToProps)(FormifiedSurveyCustomQuestionsForm);
