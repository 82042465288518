import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { connect } from 'react-redux';
import { closeModal } from '^/actions/modals';

export const AgendaEditWarningModal = (props) => {

  return (
    <div>
      <ModalBody>
        <p>
          The agenda is currently being edited by another user.
          You can only make changes once the other user has saved their draft.
        </p>
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button onClick={props.close}>Continue</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(closeModal());
    }
  };
}

export default connect(undefined, mapDispatchToProps)(AgendaEditWarningModal);
