export const EMAIL_TEMPLATE_TYPES = {
  SURVEY_WARMUP: 'survey_warmup',
  SEND_QUESTIONNAIRE: 'send_questionnaire',
  NUDGE_QUESTIONNAIRE: 'nudge_questionnaire',
  THANK_YOU: 'thank_you_email',
};

export const EMAIL_TEMPLATE_NAMES = {
  [EMAIL_TEMPLATE_TYPES.THANK_YOU]: 'Thank you email',
  [EMAIL_TEMPLATE_TYPES.SURVEY_WARMUP]: 'Warmup email',
  [EMAIL_TEMPLATE_TYPES.NUDGE_QUESTIONNAIRE]: 'Reminder email',
  [EMAIL_TEMPLATE_TYPES.SEND_QUESTIONNAIRE]: 'Survey email',
};

export const EMAIL_TEMPLATE_TYPES_WITH_SURVEY_LINK = [
  EMAIL_TEMPLATE_TYPES.SEND_QUESTIONNAIRE,
  EMAIL_TEMPLATE_TYPES.NUDGE_QUESTIONNAIRE,
];

export const ROE_TOTAL_COLUMS = 12;
export const FORM_LABEL_COLUMNS = 3;
export const FORM_INPUT_COLUMNS = ROE_TOTAL_COLUMS - FORM_LABEL_COLUMNS;

export const INTRO_TEXT_PLACEHOLDER = '' +
  'This is introductory text before the survey link - e.g. \'Below is our survey we would like you to fill out\'.';
export const OUTRO_TEXT_PLACEHOLDER = '' +
  'This is text that appears after the survey link - outline next steps and set peoples expectations.';
