import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import { Field } from 'redux-form';

const renderSlider = ({ input, min = 0, max = 10}) => (
  <input
    {...input}
    type="range"
    min={min}
    max={max}
    step={1}
    className="margin-top-large enps-slider"
    style={{
      backgroundSize: `${((input.value - min) * 100) / (max - min)}% 100%`
    }}
  />
);

class FieldEnpsSlider extends PureComponent {
  render() {
    const { min = 0, max = 10 } = this.props;

    return (
      <div>
        <Field
          name="score"
          component={renderSlider}
          min={min}
          max={max}
        />
        <div className="flex-space-between margin-top-large">
          {
            Array.from(
              { length: max - min + 1 },
              (_, i) => i + min
            )
            .map(number => (
              <span
                key={`notch_${number}`}
                className="text-align-center font-size-12 notch"
                style={{width: `${3.5 * max}px` }}>
                  { number }
              </span>)
            )
          }
        </div>
      </div>
    );
  }
}

export default FieldEnpsSlider;
