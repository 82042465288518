import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';

export default class Tabs extends PureComponent {
  componentDidMount() {
    if (!this.state || !this.state.currentTabId) {
      this.setState({
        'currentTabId': this.props.defaultTab
      });
    }
  }

  render() {
    const tabs = this.props.tabs;
    const buttons = this.getButtons(tabs);
    const tabContent = this.getTabsContent(tabs);
    return (
      <div>
        <div className="inline-tabs">{buttons}</div>
        <div className="will table-will">
          { tabContent }
        </div>
      </div>
    );
  }

  buttonClicked(buttonId) {
    this.setState({
      'currentTabId': buttonId
    });
  }

  getButtons(tabs) {
    return tabs.map(tab =>
      <div
        key={tab.get('ID')}
        className={`tab ${this.state && this.state.currentTabId === tab.get('ID') ? 'active' : ''}`}
        onClick={
          () => this.buttonClicked(tab.get('ID'))
        }
      >
        {tab.get('DisplayName')}
      </div>
    );
  }

  getTabsContent(tabs) {
    return tabs.map(tab =>
      <div
        key={tab.get('ID')}
        className={ this.state && this.state.currentTabId === tab.get('ID') ? '' : 'hidden'}
      >
        {tab.get('Component')}
      </div>
    );
  }
}
