import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { createGoalAddAction } from '^/components/goals/actions';
import CreateGoalActionCard from '^/components/goals/CreateGoalActionCard';

export function CreateGoalActions(props) {
  return (
    <div className="well padding-left-large padding-right-large">
      {props.actions.map((action, index) => (
        <CreateGoalActionCard
          key={`create-goal-action${index}`}
          index={index}
          action={action}
          hasMultipleActions={props.actions.length > 1}
          readOnlyOwner={props.readOnlyOwner}
        />
      ))}
      <div className="text-center">
        <button
          className="btn middle orange margin-vertical-large"
          onClick={props.createGoalAddAction}
        >
          Add action
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    actions: state.createGoalActions,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    createGoalAddAction: () => dispatch(createGoalAddAction(props.readOnlyOwner))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGoalActions);
