import classNames from 'classnames';
import React from 'react'; // eslint-disable-line

class FilterSelect extends React.PureComponent {

  constructor(props) {
    super(props);
    this.setFilters = this.setFilters.bind(this);
    this.state = {};
  }

  setFilters(event) {
    const selectedOption = event.target.value;
    this.setState({selectedOption});

    if (this.props.formatFilters) {
      this.props.setFilters(this.props.formatFilters(this.props.filterKeyName, selectedOption));
    } else {
      this.props.setFilters({[this.props.filterKeyName]: selectedOption});
    }
  }

  render() {
    const {
      selectClassName,
      filterKeyName,
      options,
      displayName,
      boldLabel,
      disabled,
      showAll = true,
    } = this.props;
    const selectId = `filter-select-${filterKeyName}`;
    const value =
      this.state.selectedOption === '' ? '' :
      (this.state.selectedOption || this.props.value || '');
    return (
      <div className="filter-select">
        <label
          className={classNames('text-overflow-ellipsis line-height-16px', {'font-weight-bold': boldLabel})}
          htmlFor={selectId}
        >
          {displayName}
        </label>
        <select
          id={selectId}
          onChange={this.setFilters}
          value={value}
          disabled={disabled}
          className={selectClassName}
        >
          {showAll && <option value="">All</option>}
          {options.map(option => (
            <option
              key={`filter-option-${filterKeyName}-${option.value}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default FilterSelect;
