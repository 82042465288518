import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { AdminList } from '@dabapps/chadmin';
import AdminListSearch from '^/components/admin/AdminListSearch';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';
import {
  addInSurveyAndUpdate,
  removeFromSurveyAndUpdate,
} from '^/actions/actionSequences';
import { getCollectionFromUrl } from '^/actions/collections';
import { setPageSize, surveySelectAll, surveyRemoveAll } from '^/actions/actions';
import AdminListPagination from '^/components/admin/AdminListPagination';
import { fetchFieldConfig, setFilter, setFilterBulk } from '^/actions/actions';
import AdminListFilter from '../../admin/AdminListFilter';
import { ALL } from '^/consts/forms';
import { DEFAULT_PAGE_SIZE } from '^/consts/inputs';
import Loading from '^/components/Loading';
import { isPending } from '../../../consts/responseStates';
import moment from 'moment';
import DateRangeFilter from '^/components/common/DateRangeFilter';
import PageSize from '^/components/common/PageSize';
import { Column, Row, SpacedGroup } from '@dabapps/roe';
import { canAccessAdmin } from '../../../utils/permissions';

export const SUPPORT_ORGANISATION_LIST = 'SUPPORT_ORGANISATION_LIST';

const CONFIG_NAME = 'support/organisations';

export class OrganisationList extends PureComponent {

  constructor() {
    super();
    this.loadPage = this.loadPage.bind(this);
    this.getOwnerDisplay = this.getOwnerDisplay.bind(this);
  }

  getOwnerDisplay(organisation) {
    const { shouldLinkToAdmin } = this.props;
    if (!organisation.owner) {
      return (<span></span>);
    }
    return shouldLinkToAdmin ? (
        <a target="_blank" href={`/admin/account/user/${organisation.owner.id}/`}>
          {organisation.owner.display_name}
        </a>
      ) :
      organisation.owner.display_name;
  }

  getColumns() {
    const { shouldLinkToAdmin } = this.props;

    return fromJS([
      {
        name: 'name',
        display_name: 'Name',
        type: 'custom',
        customItemHandler: org => shouldLinkToAdmin ? (
          <a target="_blank" href={`/admin/organisations/organisation/${org.id}/`}>
            {org.name}
          </a>
        ) : org.name,
        sortable: true
      },
      {
        name: 'owner',
        display_name: 'Owner',
        type: 'custom',
        customItemHandler: this.getOwnerDisplay,
        sortable: true
      },
      {
        name: 'subscription_state',
        display_name: 'State',
        type: 'text',
        sortable: true
      },
      {
        name: 'subscription_package',
        display_name: 'Product',
        type: 'text',
        sortable: true
      },
      {
        name: 'subscription_max_people',
        display_name: 'Licence',
        type: 'text',
        sortable: true
      },
      {
        name: 'team_member_count',
        display_name: 'Active',
        type: 'text',
        sortable: true
      },
      {
        name: 'user_count',
        display_name: 'Users',
        type: 'text',
        sortable: true
      },
      {
        name: 'survey_count',
        display_name: 'Surveys',
        type: 'text',
        sortable: true
      },
      {
        name: 'action_count',
        display_name: 'Actions',
        type: 'text',
        sortable: true
      },
    ]);
  }

  getFilters(overrideFilterSpec) {
    const filterSpec = overrideFilterSpec || this.props.filterSpec;
    return filterSpec.map((filterValue) => {
      const filter = filterValue !== ALL ? filterValue : null;
      return (filter instanceof moment) ? filter.format('YYYY-MM-DD') : filter;
    }).toJS();
  }

  loadCollection(pageSize) {
    this.props.getCollectionFromUrl(
      `/support/organisations/`,
      {pageSize: pageSize || this.props.pageSize, filters: this.getFilters()},
      SUPPORT_ORGANISATION_LIST
    );
  }

  componentWillReceiveProps(newProps) {
    if (newProps.filterSpec !== this.props.filterSpec) {
      this.loadPage(this.page, newProps.filterSpec);
    }
  }

  componentWillMount() {
    this.props.setFilterBulk(SUPPORT_ORGANISATION_LIST, this.props.queryParams.toJS());

    if (this.props.queryParams.isEmpty()) {
      this.loadCollection();
    }

    this.props.fetchFieldConfig(CONFIG_NAME);
  }

  loadPage(page, overrideFilterSpec = null, searchString = null) {
    this.props.getCollectionFromUrl(
      `/support/organisations/`,
      {page, pageSize: this.props.pageSize, filters: this.getFilters(overrideFilterSpec), searchString},
      SUPPORT_ORGANISATION_LIST
    );
  }

  setPageSize(pageSize) {
    this.props.setPageSize(SUPPORT_ORGANISATION_LIST, pageSize);
    this.loadCollection(pageSize);
  }

  filterCollection(filterKey, filterValue) {
    this.props.setFilter(SUPPORT_ORGANISATION_LIST, filterKey, filterValue);
  }

  render() {
    if (!this.props.filters) {
      return <Loading />;
    }

    const filters = this.props.filters;
    const columns = this.getColumns();

    return (
      <div className="admin-container-content">
        <div className="admin-list-container">

          <Row>
            <Column>
              <AdminListSearch
                onSubmit={({searchString}) => this.loadPage(this.page, null, searchString)}
                form="organisations_search"
                listName={SUPPORT_ORGANISATION_LIST}
                isLoading={this.props.isLoading}
              />
            </Column>
          </Row>

          <Row className="admin-list-controls">
            <Column className="admin-list-survey-date-filter" xs={4}>
              <DateRangeFilter
                filterName="Subscription Expiry"
                fromKey="subscription__expiry_date__gte"
                toKey="subscription__expiry_date__lte"
                fromValue={this.props.filterSpec.get('subscription__expiry_date__gte')}
                toValue={this.props.filterSpec.get('subscription__expiry_date__lte')}
                onChange={this.filterCollection.bind(this)}
              />
            </Column>
            <Column className="survey-team-member-filters" xs={8}>
              <SpacedGroup className="float-right">
                {filters.map(filter => (
                  <AdminListFilter
                    key={filter.get('filter_key') + '_filter'}
                    onChange={(filterKey, filterValue) => this.filterCollection(filterKey, filterValue)}
                    keyName={filter.get('filter_key') + '_filter'}
                    filter={filter} />
                ))}
              </SpacedGroup>
            </Column>
          </Row>

          <Row className="admin-list-controls">
            <Column>
              <SpacedGroup className="float-right">
                <div className="stm-filter">
                  <PageSize
                    pluralItemName="Organisations"
                    pageSize={this.props.pageSize}
                    onChange={(newPageSize) => this.setPageSize(newPageSize)}
                  />
                </div>
              </SpacedGroup>
            </Column>
          </Row>

          <AdminList
            items={this.props.organisations.toJS()}
            columns={columns.toJS()}
            listName={SUPPORT_ORGANISATION_LIST}
            itemCount={this.props.organisationCount}
            filters={this.props.filters}
          />
          <AdminListPagination
            collection={this.props.organisationCollection}
            loadPage={this.loadPage}
            pageSize={this.props.pageSize}/>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    organisationCollection: state.collections.get(SUPPORT_ORGANISATION_LIST, Map()),
    organisations: state.collections.getIn([SUPPORT_ORGANISATION_LIST, 'items'], List()),
    organisationCount: state.collections.getIn([SUPPORT_ORGANISATION_LIST, 'count'], 0),
    pageSize: state.ui.getIn(['pageSize', SUPPORT_ORGANISATION_LIST]) || DEFAULT_PAGE_SIZE,
    filters: state.fieldConfigs.getIn([CONFIG_NAME, 'filters'], List()),
    filterSpec: state.filterSpec.get(SUPPORT_ORGANISATION_LIST, Map()),
    isLoading: isPending(state.legacyResponses.getIn(['getCollection', SUPPORT_ORGANISATION_LIST])),
    shouldLinkToAdmin: canAccessAdmin(state.loggedInUser),
  };
}

export default connect(
  mapStateToProps,
  {
    getCollectionFromUrl,
    addInSurveyAndUpdate,
    removeFromSurveyAndUpdate,
    setPageSize,
    surveySelectAll,
    surveyRemoveAll,
    fetchFieldConfig,
    setFilter,
    setFilterBulk
  }
)(OrganisationList);
