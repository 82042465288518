import classNames from 'classnames';
import React from 'react';  // eslint-disable-line
import URI from 'urijs';

import PureComponent from '^/components/PureComponent';
import { stripFalsyValuesFromObject } from '^/utils';

import { capitalizeFirstLetter, getQueryParamsFromLocation } from '^/utils';

export class UriFilterSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.state = {};
    this.filtersFromLocation = getQueryParamsFromLocation();
  }

  onChangeFilter(event) {
    const uri = new URI(window.location.href);
    this.setState({selectedOption: event.target.value});
    uri.search(stripFalsyValuesFromObject(Object.assign(
      {},
      this.filtersFromLocation,
      { [this.props.filterName]: event.target.value }
    )));
    window.location.assign(uri.resource());
  }

  render() {
    const {
      filterName,
      name,
      label,
      options,
      hideBlankOption,
      hasInlineLabels,
      disabled
     } = this.props;
    const value =
      this.state.selectedOption === '' ? '' :
      (this.state.selectedOption || this.filtersFromLocation[filterName] || '');

    if (!options || options.length < 1) {
      return null;
    }

    return (
      <div className={classNames('filter-select', {
        'filter-select-inline': hasInlineLabels
      })}>
        <label
          htmlFor={`${name}-select`}
          className="text-overflow-ellipsis line-height-16px"
        >
          {label || capitalizeFirstLetter(name)}
        </label>
        <select
          id={`${name}-select`}
          name={`${name}-select`}
          onChange={this.onChangeFilter}
          value={value}
          disabled={disabled}
        >
          {!hideBlankOption && (
            <option value="">All</option>
          )}
          {options.map(option => (
            <option
              key={`filter-option-${name}-${option.value}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default UriFilterSelect;
