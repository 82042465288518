import React from 'react';
import { connect } from 'react-redux';
import { expandRecommendation, collapseRecommendation } from './actions';


export default function withExpandableRecommendation(WrappedComponent) {

  const ExpandableRecommendation = class extends React.PureComponent {
    constructor(props) {
      super(props);
      this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    toggleExpanded () {
      const { isExpanded, recommendation } = this.props;

      if (isExpanded) {
        return this.props.collapseRecommendation(recommendation.get('id'));
      }
      return this.props.expandRecommendation(recommendation.get('id'));
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          toggleExpanded={this.toggleExpanded}
        />
      );
    }
  };

  function mapStateToProps(state, props) {
    return {
      isExpanded: state.expandedRecommendations.get(props.recommendation.get('id')),
    };
  }

  return connect(mapStateToProps, {
    expandRecommendation,
    collapseRecommendation,
  })(ExpandableRecommendation);
}
