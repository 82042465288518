import React from 'react'; // eslint-disable-line no-unused-vars

const ExpandIcon = () => (
  <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="37" rx="3.5" fill="#FFFFF9"/>
    <path
      d="M28.25 11.125C28.25 10.5156 28.7656 10 29.375 10C30.0312 10 30.5 10.5156 30.5 11.125L30.5 26.875C30.5
      27.5312 30.0312 28 29.375 28C28.7188 28 28.25 27.5312 28.25 26.875L28.25 11.125ZM17.75 13.8437C17.2812 13.4219
      17.2812 12.7187 17.7031 12.25C18.125 11.7812 18.8281 11.7812 19.2969 12.2031L25.6719 18.2031C26.1406 18.625
      26.1406 19.4219 25.6719 19.8437L19.2969 25.8437C18.8281 26.2656 18.125 26.2656 17.7031 25.7969C17.5156 25.5625
      17.4219 25.2812 17.4219 25C17.4219 24.7187 17.5156 24.4375 17.75 24.2031L22.0625 20.125L10.6719 20.125C10.0156
      20.125 9.5 19.6562 9.5 19C9.5 18.3906 10.0156 17.875 10.6719 17.875L22.0625 17.875L17.75 13.8437Z" fill="#FF910A"
    />
    <rect x="0.5" y="0.5" width="39" height="37" rx="3.5" stroke="#FF910A"/>
  </svg>
);

export default ExpandIcon;
