import { isPending } from '@dabapps/redux-requests';
import { ModalRenderer } from '@dabapps/roe';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { REMOVE_MANAGER } from '^/actions/actions';
import { removeManagerNotiftyAndReloadManagedUsers } from '^/actions/actionSequences';
import { openSimpleModal } from '^/actions/modals';
import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { createGetManagedUsersConfig } from '^/components/managers/config';
import AddNewUserToManager from '^/components/managers/AddNewUserToManager';
import CreateEditUserModal from '^/components/users/CreateEditUserModal';

export const MANAGED_USERS_LIST = 'MANAGED_USERS_LIST';

export class ManagedUsersList extends React.PureComponent {
  render() {
    const {
      filterOptions: { teams, departments, locations, units, managers },
      isRemovingManager,
      openEditUserModal,
      managerId,
      modals,
      removeManager,
      managerInfo,
      managedUsersCount,
      organisationFilterTerms
    } = this.props;
    return (
      <div>
        <h3>{managerInfo.first_name} {managerInfo.last_name} manages {managedUsersCount} people</h3>
        <div className="will">
          <ChadminList
            collectionPath={`people/manage-managers/${managerId}`}
            collectionName={MANAGED_USERS_LIST}
            getColumns={
              createGetManagedUsersConfig(
                removeManager,
                isRemovingManager,
                openEditUserModal,
                organisationFilterTerms
              )
            }
            ExtraControlsLeft={({reloadPage}) => (
              <AddNewUserToManager managerId={managerId} reloadUsers={reloadPage} />
            )}
            ExtraControlsRight={() => (
              <div>
                <UriFilterSelect
                  filterName="team_member__team_id"
                  name={organisationFilterTerms.team}
                  options={teams}
                />
                <UriFilterSelect
                  filterName="team_member__department_filter_id"
                  name={organisationFilterTerms.department}
                  options={departments}
                />
                <UriFilterSelect
                  filterName="team_member__location_filter_id"
                  name={organisationFilterTerms.location}
                  options={locations}
                />
                <UriFilterSelect
                  filterName="team_member__unit_filter_id"
                  name={organisationFilterTerms.unit}
                  options={units}
                />
                <UriFilterSelect
                  filterName="team_member__manager_filter_id"
                  name={organisationFilterTerms.manager}
                  options={managers}
                />
              </div>
            )}
          />
        </div>
        <ModalRenderer modals={modals}/>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isRemovingManager: isPending(state.responses, REMOVE_MANAGER),
    modals: state.modals,
    managedUsersCount: state.managedUsersCount
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    removeManager: (userId, reloadPage) =>
      dispatch(removeManagerNotiftyAndReloadManagedUsers(props.managerId, [userId], reloadPage)),
    openEditUserModal: (user, reloadPage) => dispatch(
      openSimpleModal({
        title: `Edit ${user.first_name} ${user.last_name}`,
        body: (
          <CreateEditUserModal
            userId={user.id}
            reloadPage={reloadPage}
            selectOptions={props.editUserOptions}
            managerLicensesUsed={props.managerLicensesUsed}
            managerLicensesRemaining={props.managerLicensesRemaining}
            organisationFilterTerms={props.organisationFilterTerms}
          />
        ),
        large: true,
      })
  ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagedUsersList);
