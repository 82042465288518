import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Row, Column } from '@dabapps/roe';
import moment from 'moment';
import { FORMAT_DATE } from '../../utils';
import StartDateField from './StartDateField';
import ReactDatePicker from 'react-datepicker';

export const FORM_NAME = 'compare-report';

export const DatePickerField = (props) => {
  return (
    <div>
      <label>{props.label}</label>
      <div className="datepicker-with-icon-wrapper">
        <ReactDatePicker
          disabled={props.input.disabled}
          selected={moment(props.input.value)}
          onChange={props.input.onChange}
          dateFormat={FORMAT_DATE}
          maxDate={props.maxDate || moment().toDate()}
        />
        <i className="far fa-calendar datepicker-icon"></i>
      </div>
    </div>
  );
};

function MaybeErrorMessage(props) {
  const errorStrings = props.errors[props.errorKey];
  if (errorStrings) {
    return (
      <div className="margin-top-base">
        {errorStrings.map(errorString => (
          <p className="error">{errorString}</p>
        ))}
      </div>
    );
  }

  return null;
}

export class CompareReportForm extends React.PureComponent {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Row>
          <Column xs={12}>
            <p className="margin-bottom-base">
              <strong>Compare these results with data from the following time period:</strong>
            </p>
          </Column>
        </Row>
        <Row>
          <Column xs={3}>
            <Field name="compare_to_start_date" label="Date from" component={StartDateField} />
            <MaybeErrorMessage errors={this.props.errors} errorKey="compare_to_start_date" />
          </Column>
          <Column xs={3}>
            <Field name="compare_to_end_date" label="Date to" component={DatePickerField} />
            <MaybeErrorMessage errors={this.props.errors} errorKey="compare_to_end_date" />
          </Column>
          <Column xs={3}>
            <button type="submit" className="middle orange btn margin-top-x-large">Add date range</button>
          </Column>
        </Row>
        <MaybeErrorMessage errors={this.props.errors} errorKey="non_field_errors" />
      </form>
    );
  }
}

export default reduxForm({
  form: FORM_NAME
})(CompareReportForm);
