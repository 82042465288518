import { Map } from 'immutable';
import _ from 'underscore';
import URI from 'urijs';
import moment from 'moment';
import { isRSAA } from 'redux-api-middleware';
import { isFSA } from 'flux-standard-action';
import { getErrorData } from '@dabapps/redux-requests';

export function getIn(item, path, defaultItem) {
  if (item === undefined) {
    return defaultItem;
  }
  if (path.length === 0) {
    return item;
  }
  return getIn(item[_.first(path)], _.rest(path), defaultItem);
}

export function isAction(obj) {
  return isRSAA(obj) || isFSA(obj);
}

export const FORMAT_DATE = 'DD MMM YYYY';
export const FORMAT_TIME = 'HH:mm';
export const FORMAT_DATE_PICKER = 'DD-MM-YYYY';
export const FORMAT_DATE_BACKEND = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME = FORMAT_DATE.concat(' [at] ').concat(FORMAT_TIME);
export const FORMAT_DATE_TIME_ADMIN = FORMAT_DATE_BACKEND.concat(' ').concat(FORMAT_TIME);

export function formatDateTime(date, noDate, format = FORMAT_DATE_TIME) {
  if (!date) {
    return noDate || 'unknown';
  }
  return moment(date).format(format);
}

export function differenceBetweenDays(date1, date2, unitOfTime = 'days') {
  return moment(date1).diff(moment(date2), unitOfTime);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getQueryParamsFromLocation() {
  const uri = new URI(window.location.href);
  return uri.search(true);
}

export function getFormErrorData(responseState, actionSet, tag) {
  const axiosError = getErrorData(responseState, actionSet, tag);
  return axiosError ? axiosError.response.data : {};
}

export function getFormErrorString(formErrorData, key) {
  const errorArray = formErrorData && formErrorData[key] || [];
  return errorArray.join(', ');
}

export const joinTruthyStringsWith = (strings, joinString) =>
  strings.filter(string => Boolean(string)).join(joinString);

export const stripFalsyValuesFromObject = object =>
  Map(object).filter(value => Boolean(value)).toObject();

export function changeQueryParamsWithoutPageLoad(queryParams) {
  const uri = new URI(window.location.href);
  uri.search(queryParams);
  window.history.replaceState({}, '', uri.resource());
}

export const asPercent = (count, total) =>
  Math.round((count / total) * 100);

export const arrayContainsNoValues = (arr) => arr.length === 0;

export const THREE_SECONDS = 3000;
export const TEN_SECONDS = 10000;
export const BACKOFF_DURATION = 1000;
export const POLL_MAX_RETRIES = 18;

export const getPollReportTimoutDuration = (tryCount, timeoutSeconds) => (
  tryCount === 0 || timeoutSeconds === TEN_SECONDS
) ? timeoutSeconds : timeoutSeconds + BACKOFF_DURATION;
