import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { FORMAT_DATE_BACKEND } from '^/utils';
import { requestOneToOneAndRedirect, REQUEST_ONE_TO_ONE } from './actions';
import RequestOneToOneForm from './RequestOneToOneForm';


export function RequestOneToOne({
  handleSubmit,
  managerOptions,
  isDisabled
}) {
  return (
    <div className="background-white border-radius-16 padding-xx-large margin-bottom-xx-large">
      <RequestOneToOneForm
        onSubmit={handleSubmit}
        managerOptions={managerOptions}
        isDisabled={isDisabled}
      />
      <ToastContainer />
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    isDisabled: isPending(state.responses, REQUEST_ONE_TO_ONE),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    handleSubmit: (oneToOneData) => {
      const data = Object.assign(
        {},
        oneToOneData,
        {
          date: oneToOneData.date.format(FORMAT_DATE_BACKEND),
        }
      );
      dispatch(requestOneToOneAndRedirect(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestOneToOne);
