import * as React from 'react';  // eslint-disable-line no-unused-vars
import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';
import moment from 'moment';
import { FORMAT_DATE } from '../../utils';


export const goalsListConfig = [
  {
    name: 'name',
    display_name: 'Goal name',
    type: 'custom',
    customItemHandler: goal => (
      <a href={`/goals/${goal.id}/`}>{goal.name}</a>
    ),
    sortable: true,
  },
  {
    name: 'description',
    display_name: 'Goal description',
    type: 'text',
    sortable: true,
  },
  {
    name: 'percentage_complete',
    display_name: '% Completed',
    type: 'custom',
    customItemHandler: goal => (
      <CellContentOrEmpty condition={goal.percentage_complete !== null}>
        <span className="display-block padding-left-xx-large">{goal.percentage_complete}%</span>
      </CellContentOrEmpty>
    ),
    sortable: false,
  },
  {
    name: 'due_date',
    display_name: 'Due',
    type: 'custom',
    customItemHandler: goal => moment(goal.due_date).format(FORMAT_DATE),
    sortable: true,
  },
  {
    name: 'owner',
    display_name: 'Goal owner',
    type: 'custom',
    sortable: true,
    customItemHandler: goal => goal.owner ? goal.owner : goal.created_by,
  },
  {
    name: 'number_of_actions',
    display_name: 'No. of actions',
    type: 'custom',
    sortable: true,
    customItemHandler: goal => (
      <span className="display-block padding-left-xxx-large">{goal.number_of_actions}</span>
    ),
  },
  {
    name: 'edit',
    display_name: 'Edit goal',
    type: 'custom',
    sortable: false,
    customItemHandler: goal => (
      <a href={`/goals/${goal.id}/`} className="btn orange hollow">Edit goal</a>
    ),
  },
];
