import { AdminList } from '@dabapps/chadmin';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import * as React from 'react';
import { getCollection, getCollectionSortBy } from '^/actions/collections';
import { connect } from 'react-redux';
import _ from 'underscore';

import AdminListPagination from '^/components/admin/AdminListPagination';
import PageSize from '^/components/common/PageSize';
import { PAGE_SIZE_OPTIONS } from '^/consts/inputs';
import { getQueryParamsFromLocation } from '^/utils';

function getPageSize(collection) {
  return collection.get('pageSize') || PAGE_SIZE_OPTIONS[0];
}

export function getOptions(collection, filtersFromLocation, defaultFilters = {}) {
  const { filters, ordering, page, searchString, reverseOrdering = false } = collection.toObject();
  return {
    page,
    searchString,
    filters,
    pageSize: getPageSize(collection),
    ordering: ordering || defaultFilters.ordering,
    reverseOrdering,
    additionalParams: Map(defaultFilters).merge(Map(filtersFromLocation)).filter(filter => !!filter).toObject(),
  };
}

export class ChadminList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.loadPage = this.loadPage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.setSortBy = this.setSortBy.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.setPageSize = this.setPageSize.bind(this);
    this.getPageSize = this.getPageSize.bind(this);
    this.debouncedSearch = _.debounce(searchString => {
      this.loadPage(Object.assign(
        this.getOptions(),
        { searchString, page: 1 }
      ));
    }, 300);
    this.search = this.search.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
    this.getColumns = this.getColumns.bind(this);
  }

  componentWillMount() {
    this.loadPage(Object.assign(
      this.getOptions(),
      {page: 1}
    ));
  }

  loadPage(options) {
    if (this.props.loadList) {
      this.props.loadList(options);
    } else {
      this.props.getCollection(
        this.props.collectionPath,
        options,
        this.props.collectionName
      );
    }
  }

  changePage(page) {
    this.loadPage(Object.assign(
      this.getOptions(),
      { page }
    ));
  }

  setSortBy(key) {
    this.loadPage(Object.assign(
      this.getOptions(),
      getCollectionSortBy(key, this.props.collection.toObject())
    ));
  }

  setPageSize(pageSize) {
    this.loadPage(Object.assign(
      this.getOptions(),
      { pageSize, page: 1 }
    ));
  }

  setFilters(filters) {
    const options = this.getOptions();
    this.loadPage(Object.assign(
      this.getOptions(),
      {
        filters: Object.assign(options.filters, filters),
        page: 1
      }
    ));
  }

  reloadPage() {
    this.loadPage(this.getOptions());
  }

  search(event) {
    this.debouncedSearch(event.target.value);
  }

  getOptions() {
    const { collection, defaultFilters = {} } = this.props;
    const filtersFromLocation = getQueryParamsFromLocation();
    return getOptions(collection, filtersFromLocation, defaultFilters);
  }

  getPageSize() {
    return this.props.pageSize || getPageSize(this.props.collection);
  }

  getColumns () {
    const { columns, getColumns } = this.props;
    if (typeof getColumns === 'function') {
      return getColumns(this.reloadPage);
    }

    return columns;
  }

  render() {
    const { ExtraControlsLeft, ExtraControlsRight, children } = this.props;
    const collectionOrdering = this.props.collection.get('ordering');

    return (
      <div className={classNames('admin-container-content chadmin-list', this.props.className)}>
        <div className="chadmin-list-controls">
          {ExtraControlsLeft && (
            <div
              className={classNames('margin-bottom-large', {
                'extra-controls-top-spacing': !this.props.hasInlineLabels
              })}
            >
              <ExtraControlsLeft reloadPage={this.reloadPage} setFilters={this.setFilters} />
            </div>
          )}
          {this.props.searchable && (
            <div className={classNames('margin-right-base', {
              'margin-left-auto': this.props.pullSearchRight
            })}>
              {this.props.searchLabel && (
                <label className="line-height-16px" htmlFor="search">{this.props.searchLabel}</label>
              )}
              <input
                id="search"
                className={classNames('chadmin-search width-140', {
                  'extra-controls-top-spacing': !this.props.hasInlineLabels && !this.props.searchLabel
                })}
                onChange={this.search}
                type="search"
                placeholder="Search..."
              />
            </div>
          )}
          <div className={classNames('display-flex', {
            'margin-left-auto': !this.props.searchable || !this.props.pullSearchRight})
          }>
            {ExtraControlsRight && (
              <div className="margin-bottom-large">
                <ExtraControlsRight reloadPage={this.reloadPage} setFilters={this.setFilters} />
              </div>
            )}
            {!this.props.hidePageSize && (
              <PageSize
                hasInlineLabels={this.props.hasInlineLabels}
                onChange={this.setPageSize}
                pageSize={this.getPageSize()}
              />
            )}
          </div>
        </div>
        {children ? children : (
          <AdminList
            items={this.props.collectionItems.toJS()}
            columns={this.getColumns()}
            listName={this.props.collectionName}
            itemCount={this.props.collectionCount}
            sortItems={this.setSortBy}
            sortBy={collectionOrdering && collectionOrdering.replace('-', '')}
            sortByReversed={this.props.collection.get('reverseOrdering')}
            response={this.props.response.toObject()}
          />
        )}
        <AdminListPagination
          collection={this.props.collection}
          loadPage={this.changePage}
          pageSize={this.getPageSize()}
        />
      </div>
    );
  }
}

export { ChadminList as UnconnectedChadminList };

function mapStateToProps(state, props) {
  return {
    collection: props.collection || state.collections.get(props.collectionName, Map()),
    collectionItems: state.collections.getIn(
      [props.collectionName, 'items'],
      List()
    ),
    collectionCount: state.collections.getIn(
      [props.collectionName, 'count'],
      0
    ),
    response: state.legacyResponses.getIn(['getCollection', props.collectionName], Map())
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(ChadminList);
