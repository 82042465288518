import { Well } from '@dabapps/roe';
import moment from 'moment';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';

import ActionCard from '^/components/okrs/ActionCard';
import { addActionToOkrAndReload, toggleAddActionForm } from '^/components/okrs/actions';
import EditOkrAddActionForm from '^/components/okrs/EditOkrAddActionForm';
import ReadonlyActionCard from '^/components/okrs/ReadonlyActionCard';
import { FORMAT_DATE_BACKEND } from '^/utils';

export function ActionsPicker(props) {
  const sortedActions = props.okr
    .get('actions')
    .sortBy((action) =>
      props.excludedActions.includes(action.get('id'))
    );
  const myActions = props.okr.get('actions')
    .filter(action => action.getIn(['owner', 'value']) === props.loggedInUserOwnerOption.value);

  const otherActions = props.okr.get('actions')
    .filter(action => action.getIn(['owner', 'value']) !== props.loggedInUserOwnerOption.value);

  return (
    <div className="actions-picker">
      {props.readonly ? (
        <div>
          {myActions.count() ? (
            <div>
              <h4 className="margin-top-x-large margin-bottom-none">Assigned to me</h4>
              {myActions.map((action) => (
                <ReadonlyActionCard
                  key={action.get('id')}
                  action={action}
                  goal_due_date={props.okr.get('due_date')}
                  loggedInUserOwnerId={props.loggedInUserOwnerOption.value}
                />
              ))}
            </div>
          ) : null}
          {otherActions.count() ? (
            <div>
              <h4 className="margin-top-x-large margin-bottom-none">Key results assigned to others</h4>
              {otherActions.map((action) => (
                <ReadonlyActionCard
                  key={action.get('id')}
                  action={action}
                  loggedInUserOwnerId={props.loggedInUserOwnerOption.value}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <p className="margin-bottom-large">
            <strong>Key results</strong>
          </p>
          <Well className="padding-left-large padding-right-large">
            {sortedActions.map((action, index) => (
              <ActionCard
                key={action.get('id')}
                action={action}
                isLoading={props.isLoading}
                excludeActionsMaxReached={props.excludeActionsMaxReached}
                index={index}
              />
            ))}
            {props.showAddActionForm ? (
              <EditOkrAddActionForm
                onSubmit={props.onSubmitAddAction}
                initialValues={{
                  owner: props.loggedInUserOwnerOption,
                  due_date: moment().add(1, 'days'),
                }}
                removeAction={props.toggleAddActionForm}
              />
            ) : (
              <div className="text-center">
                <button
                  className="btn middle orange margin-vertical-large"
                  onClick={props.toggleAddActionForm}
                >
                  Add key result
                </button>
              </div>
            )}
          </Well>
        </div>
      )}
    </div>
  );
}

export function mapStateToProps(state) {
  const { id: value, first_name, last_name } = state.loggedInUser.toObject();
  return {
    okr: state.okrData,
    isLoading: state.actionReducer.get('isLoading', false),
    excludedActions: state.okrActionPickerExcludedActions,
    loggedInUserOwnerOption: {
      value,
      label: `${first_name} ${last_name}`,
    },
    showAddActionForm: state.isShowingAddOkrActionForm || !state.okrData.get('actions').count(),
    excludeActionsMaxReached: (
      state.okrActionPickerExcludedActions.count() === (
        state.okrData.get('actions').count() - 1
      )
    )
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmitAddAction: data => dispatch(addActionToOkrAndReload(
      ownProps.okrId,
      Object.assign({}, data, {
        okr: ownProps.okrId,
        due_date: data.due_date.format(FORMAT_DATE_BACKEND),
        owner: data.owner.value
      })
    )),
    toggleAddActionForm: () => dispatch(toggleAddActionForm())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPicker);
