import React from 'react';
import CompareReportForm from './CompareReportForm';
import { CREATE_REPORT, createReport, showCompareToForm } from './actions';
import { connect } from 'react-redux';
import moment from 'moment';
import { FORMAT_DATE_BACKEND, getFormErrorData } from '../../utils';

export class CompareReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.showCompareToForm = this.showCompareToForm.bind(this);
    this.clearComparison = this.clearComparison.bind(this);
  }

  onSubmit(data) {
    const dataWithFormattedDates = {
      compare_to_start_date: moment(data.compare_to_start_date).format(FORMAT_DATE_BACKEND),
      compare_to_end_date: moment(data.compare_to_end_date).format(FORMAT_DATE_BACKEND)
    };

    this.props.createReport(Object.assign(
      dataWithFormattedDates,
      {survey: this.props.surveyId},
      this.props.selectedPrimaryFilters
    ), this.props.legacyReportShareId);
  }

  clearComparison() {
    this.props.createReport(Object.assign(
      {survey: this.props.surveyId},
      this.props.selectedPrimaryFilters
    ));
  }

  showCompareToForm(event) {
    event.preventDefault();
    this.props.showCompareToForm();
  }

  render() {
    const hasComparison = this.props.currentComparison.compare_to_start_date
      && this.props.currentComparison.compare_to_end_date;

    if (!this.props.showForm) {
      return (
        <div>
          <a className="middle orange btn" onClick={this.showCompareToForm}>Compare report</a>
          {hasComparison && (
            <a className="margin-left-small middle orange btn" onClick={this.clearComparison}>Clear comparison</a>
          )}
        </div>
      );
    }

    return (
      <CompareReportForm
        onSubmit={this.onSubmit}
        initialValues={
          {
            compare_to_start_date: this.props.currentComparison.compare_to_start_date
              ? moment(this.props.currentComparison.compare_to_start_date) : moment(),
            compare_to_end_date: this.props.currentComparison.compare_to_end_date
              ? moment(this.props.currentComparison.compare_to_end_date) : moment(),
          }
        }
        errors={this.props.errors}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: getFormErrorData(state.responses, CREATE_REPORT),
    showForm: state.showCompareToForm
  };
}

export default connect(mapStateToProps, {
  createReport,
  showCompareToForm
})(CompareReport);
