import { anyPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import ChadminList from '^/components/common/ChadminList';
import QueryParamsFilterSelect from '^/components/common/QueryParamsFilterSelect';

import { MANAGE_BULK_AGENDA_USERS, CANCEL_BULK_AGENDA } from './actions';
import { AGENDA_PARTICIPANTS_LIST_URL, AGENDA_PARTICIPANTS_LIST } from './constants';
import { agendaParticipantsListConfig } from './config';
import BulkAgendaCancelButton from './BulkAgendaCancelButton';

const Filter = (props) => (
  <div className="margin-right-base width-140">
    <QueryParamsFilterSelect {...props} />
  </div>
);

export function BulkAgendaParticipantsList(props) {
  const {
    agendaId,
    agendaTitle,
    agendaDueDate,
    filterOptions: {
      team_options,
      location_options,
      department_options,
      unit_options,
      manager_options
    },
    organisationFilterTerms,
    bulkAgendaSelectedUserIdsCount,
    isDisabled
  } = props;
  const disableNextButton = isDisabled || !bulkAgendaSelectedUserIdsCount;

  return (
    <div className="background-white padding-xx-large border-radius-22 margin-vertical-xx-large">
      <h1 className="font-family-effra-bold font-size-38 font-color-main">
        Create multiple 1:1s
      </h1>
      <h1 className="font-family-effra-bold font-size-28 font-color-main">
        Step 3 of 4: Select Participants
      </h1>
      <div className="font-family-varela-round font-size-18 margin-bottom-small">
        <span className="fwb">1:1 name: </span>{agendaTitle}
      </div>
      <div className="font-family-varela-round font-size-18 margin-bottom-small">
        <span className="fwb">Due Date: </span>{agendaDueDate}
      </div>
      <ChadminList
        collectionPath={AGENDA_PARTICIPANTS_LIST_URL}
        collectionName={AGENDA_PARTICIPANTS_LIST}
        columns={agendaParticipantsListConfig(agendaId, organisationFilterTerms)}
        className="border-base padding-large border-radius-16 margin-top-base bulk-agenda-participants-list"
        ExtraControlsRight={({setFilters}) => (
          <div>
            <div className="display-flex flex-wrap">
              <Filter
                options={team_options}
                filterName="team_member__team"
                name={organisationFilterTerms.team}
                setFilters={setFilters}
              />
              <Filter
                options={location_options}
                filterName="team_member__location_filter"
                name={organisationFilterTerms.location}
                setFilters={setFilters}
              />
              <Filter
                options={department_options}
                filterName="team_member__department_filter"
                name={organisationFilterTerms.department}
                setFilters={setFilters}
              />
              <Filter
                options={unit_options}
                filterName="team_member__unit_filter"
                name={organisationFilterTerms.unit}
                setFilters={setFilters}
              />
              <Filter
                options={manager_options}
                filterName="team_member__manager_filter"
                name={organisationFilterTerms.manager}
                setFilters={setFilters}
              />
            </div>
          </div>
        )}
      />
      <p className="text-align-right font-family-varela-round font-size-16 margin-top-large margin-bottom-xx-large">
        {bulkAgendaSelectedUserIdsCount} participant(s) added
      </p>
      <p className="text-align-right font-family-varela-round font-size-16">
        Next section: Create agenda
      </p>
      <div className="display-flex">
        <a
          className="pink-button font-color-white text-decoration-none margin-left-auto button-line-height"
          href={disableNextButton ? undefined : `/agendas/${agendaId}/edit-template/`}
          disabled={disableNextButton}
        >
          Next
        </a>
        <BulkAgendaCancelButton agendaId={agendaId} isDisabled={isDisabled} />
      </div>
      <ToastContainer />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    bulkAgendaSelectedUserIdsCount: state.bulkAgendaSelectedUserIds.length,
    isDisabled: anyPending(state.responses, [MANAGE_BULK_AGENDA_USERS, CANCEL_BULK_AGENDA])
  };
}

export default connect(mapStateToProps)(BulkAgendaParticipantsList);
