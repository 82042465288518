export const CUSTOM_QUESTION_SETTINGS = {
  DESCRIPTION: 'description',
  HEADING: 'heading',
  QUESTION_DATA: 'existing_question_data',
  QUESTION_IDS_CURRENTLY_COPYING: 'question_ids_currently_copying',
  QUESTION_IDS_CURRENTLY_DELETING: 'question_ids_currently_deleting',
  QUESTION_REORDERING_IN_PROGRESS: 'question_reordering_in_progress'
};

export const REORDER_QUESTION_DIRECTION = {
  UP: 'UP',
  DOWN: 'DOWN'
};
