import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars

export function SelectedListItemsBanner({
  selectedItemsCount,
  isDisabled,
  buttonLabel,
  buttonTitle,
  bannerText,
  onClickSend,
  onClickCancel
}) {
  const isVisible = !!selectedItemsCount;
  return (
    <div className={classNames(
      'position-fixed',
      'left-0',
      'background-color-text',
      'font-color-white',
      'z-index-1',
      'width-0',
      {
        'full-width': isVisible,
        'opacity-0': !isVisible,
        'full-opacity': isVisible,
        'bottom-minus-65': !isVisible,
        'bottom-0': isVisible,
        'peer-feedback-banner-hidden': !isVisible,
        'peer-feedback-banner': isVisible
      }
    )}>
      <div className="main-content padding-top-none display-flex align-items-center height-130">
        <p className="margin-none font-size-20">{selectedItemsCount} {bannerText}</p>
        <div className="display-flex margin-left-auto">
          <button
            className="pink-button"
            onClick={onClickSend}
            disabled={isDisabled}
            title={buttonTitle}
          >
            {buttonLabel}
          </button>
          <button
            className="white-border-button margin-left-x-large"
            onClick={onClickCancel}
            disabled={isDisabled}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectedListItemsBanner;
