import { isPending, hasFailed } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';

import { openSimpleModal, openMediumModal } from '^/actions/modals';
import { REGARDING_OPTIONS } from '^/components/peer-feedback/constants';
import PeerFeedbackModalForm from '^/components/peer-feedback/PeerFeedbackModalForm';
import RequestFeedbackModalForm from '^/components/peer-feedback/RequestFeedbackModalForm';
import { getUnreadFeedbackCount, GET_UNREAD_PEER_FEEDBACK_COUNT } from '^/components/dashboards/actions';

const FeedbackCard = ({title, children}) => (
  <div className="
    feedback-give-receive-card flex-space-between flex-column align-items-center border-radius-10
    space-between border-base padding-horizontal-large padding-vertical-xx-large
  ">
    <div className="font-family-effra-bold font-size-28 margin-bottom-small">
      {title}
    </div>
    {children}
  </div>
);

export class PeerFeedbackGiveRequestReceive extends React.PureComponent {

  componentDidMount() {
    this.props.getUnreadFeedbackCount();
  }

  renderContent() {
    const {
      isLoading,
      hasFailedLoading,
      openGiveFeedbackModal,
      openRequestFeedbackModal,
      unreadFeedbackCount,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    if (hasFailedLoading) {
      return <p>Failed to load peer feedback data.</p>;
    }

    return (
      <div className="display-flex flex-column flex-grow flex-space-between">
        <div className="flex-space-between gap-base flex-wrap text-align-center">
          <FeedbackCard title="Give">
            <p className="margin-bottom-none font-size-18 width-150px line-height-32px">
              Send feedback to someone else
            </p>
            <button
              className="widget-view-all-button font-size-16 padding-large"
              onClick={openGiveFeedbackModal}
            >
              Give feedback
            </button>
          </FeedbackCard>
          <FeedbackCard title="Request">
            <p className="margin-bottom-none font-size-18 width-150px">Ask someone for feedback</p>
            <button
              className="widget-view-all-button font-size-16 padding-large"
              onClick={openRequestFeedbackModal}
            >
              Request feedback
            </button>
          </FeedbackCard>
          <FeedbackCard title="Received">
            <div className="display-flex flex-column align-items-center flex-gap-base width-150px">
              <div
                className="
                  feedback-received-circle font-color-white background-pink font-family-effra-bold font-size-28
                "
              >
                {unreadFeedbackCount}
              </div>
              <p className="margin-bottom-none font-size-18">New</p>
            </div>
            <a
              className="widget-view-all-button font-size-16 padding-large"
              href="/people/peer-feedback/?individual=true&status=unread"
            >
              View feedback
            </a>
          </FeedbackCard>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle
          title="Feedback"
          image="/static/images/dashboards/feedback.png"
        />
        {this.renderContent()}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses, GET_UNREAD_PEER_FEEDBACK_COUNT),
    hasFailedLoading: hasFailed(state.responses, GET_UNREAD_PEER_FEEDBACK_COUNT),
    unreadFeedbackCount: state.dashboardUnreadPeerFeedbackCount
  };
}

const mapDispatchToProps = dispatch => ({
  openGiveFeedbackModal: () => dispatch(
    openMediumModal({
      titleComponent: (
        <h1 className="font-color-main font-size-38 font-family-effra-bold margin-bottom-none padding-left-small">
          Give feedback
        </h1>
      ),
      body: (
        <PeerFeedbackModalForm />
      ),
    })
  ),
  openRequestFeedbackModal: () => dispatch(
    openSimpleModal({
      titleComponent: (
        <h1 className="font-color-main font-size-38 font-family-effra-bold margin-bottom-none padding-left-large">
          Request employee feedback
        </h1>
      ),
      body: (
        <RequestFeedbackModalForm regarding={REGARDING_OPTIONS.ME} />
      ),
      large: true,
    })
  ),
  getUnreadFeedbackCount: () => dispatch(getUnreadFeedbackCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeerFeedbackGiveRequestReceive);
