import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { getUserOptions } from './actions';

import RemoteUserSelect from '^/components/forms/RemoteUserSelect';

export const UserSelect = props => <RemoteUserSelect {...props} initialValue={props.value} />;

export default connect(null, {
  getUserOptions
})(UserSelect);
