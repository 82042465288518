import { request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { closeModal } from '^/actions/modals';
import { makeAsyncActionSet } from '^/actions/actions';

export const GET_ADMIN_DASHBOARD_GOALS_DATA = makeAsyncActionSet('GET_ADMIN_DASHBOARD_GOALS_DATA');
export function getAdminDashboardGoalsData() {
  return (dispatch) => {
    return request(
      GET_ADMIN_DASHBOARD_GOALS_DATA,
      '/api/goals/admin-dashboard-goals/',
      'GET'
    )(dispatch);
  };
}

export const GET_ADMIN_DASHBOARD_SURVEYS = makeAsyncActionSet('GET_ADMIN_DASHBOARD_SURVEYS');
export function getAdminDashboardSurveyData() {
  return (dispatch) => {
    return request(
      GET_ADMIN_DASHBOARD_SURVEYS,
      '/api/survey/admin-dashboard-survey-widget/',
      'GET'
    )(dispatch);
  };
}

export const EMPLOYEE_OKR_DATA = 'EMPLOYEE_OKR_DATA';
export const GET_EMPLOYEE_OKR_LIST = makeAsyncActionSet('GET_EMPLOYEE_OKR_LIST');
export function getEmployeeOkrList() {
  return (dispatch) => {
    return request(
      GET_EMPLOYEE_OKR_LIST,
      `/api/okrs/employee-dashboard-list/`,
      'GET',
      null,
      {
        metaData: { key: EMPLOYEE_OKR_DATA }
      }
    )(dispatch);
  };
}

export const MANAGER_OKR_DATA = 'MANAGER_OKR_DATA';
export const GET_MANAGER_OKR_LIST = makeAsyncActionSet('GET_MANAGER_OKR_LIST');
export function getManagerOkrList() {
  return (dispatch) => {
    return request(
      GET_MANAGER_OKR_LIST,
      `/api/okrs/manager-dashboard-list/`,
      'GET',
      null,
      {
        metaData: { key: MANAGER_OKR_DATA }
      }
    )(dispatch);
  };
}

export const GET_ADMIN_DASHBOARD_OKR_DATA = makeAsyncActionSet('GET_ADMIN_DASHBOARD_OKR_DATA');
export function getAdminDashboardOkrList() {
  return (dispatch) => {
    return request(
      GET_ADMIN_DASHBOARD_OKR_DATA,
      `/api/okrs/admin-dashboard/`,
      'GET'
    )(dispatch);
  };
}

export const GET_HOMEPAGE_SNAPSHOT_DATA = makeAsyncActionSet('GET_HOMEPAGE_SNAPSHOT_DATA');
export function getHomepageSnapshotData(isManagerDashboard) {
  return (dispatch) => {
    return request(
      GET_HOMEPAGE_SNAPSHOT_DATA,
      '/api/agendas/homepage-snapshot-data/',
      'GET',
      { isManagerDashboard }
    )(dispatch);
  };
}

export const GET_HOME_PAGE_TEAM_GOALS = makeAsyncActionSet('GET_HOME_PAGE_TEAM_GOALS');
export function getHomepageTeamGoals() {
  return (dispatch) => {
    return request(
      GET_HOME_PAGE_TEAM_GOALS,
      '/api/goals/homepage-team-goals/',
      'GET'
    )(dispatch);
  };
}

export const GET_ADMIN_DASHBOARD_PERFORMANCE_REVIEW_DATA = makeAsyncActionSet(
  'GET_ADMIN_DASHBOARD_PERFORMANCE_REVIEW_DATA'
);
export function getAdminDashboardPerformanceReviewData() {
  return (dispatch) => {
    return request(
      GET_ADMIN_DASHBOARD_PERFORMANCE_REVIEW_DATA,
      `/api/reviews/admin-dashboard-performance-reviews/`,
      'GET'
    )(dispatch);
  };
}

export const GET_USER_PERFORMANCE_REVIEW_SURVEYS = makeAsyncActionSet('GET_USER_PERFORMANCE_REVIEW_SURVEYS');
export function getUserPerformanceReviewSurveys(isManagerDashboard) {
  return (dispatch) => {
    return request(
      GET_USER_PERFORMANCE_REVIEW_SURVEYS,
      `/api/reviews/latest-completed-and-upcoming-reviews/`,
      'GET',
      { isManagerDashboard }
    )(dispatch);
  };
}

export const GET_EMPLOYEE_ONE_TO_ONES = makeAsyncActionSet('GET_EMPLOYEE_ONE_TO_ONES');
export function getEmployeeOneToOnes() {
  return (dispatch) => {
    return request(
        GET_EMPLOYEE_ONE_TO_ONES,
      '/api/agendas/employee-one-to-ones/',
      'GET'
    )(dispatch);
  };
}

export const GET_MANAGER_ONE_TO_ONES = makeAsyncActionSet('GET_MANAGER_ONE_TO_ONES');
export function getManagerOneToOnes() {
  return (dispatch) => {
    return request(
        GET_MANAGER_ONE_TO_ONES,
      '/api/agendas/manager-one-to-ones/',
      'GET'
    )(dispatch);
  };
}

export const GET_ADMIN_ONE_TO_ONES = makeAsyncActionSet('GET_ADMIN_ONE_TO_ONES');
export function getAdminOneToOnes() {
  return (dispatch) => {
    return request(
        GET_ADMIN_ONE_TO_ONES,
      '/api/agendas/admin-one-to-ones/',
      'GET'
    )(dispatch);
  };
}

export const GET_HOMEPAGE_MY_GOALS = makeAsyncActionSet('GET_HOMEPAGE_MY_GOALS');
export function getHomepageMyGoals() {
  return (dispatch) => {
    return request(
      GET_HOMEPAGE_MY_GOALS,
      '/api/goals/homepage-my-goals/',
      'GET'
    )(dispatch);
  };
}

export const CREATE_ENPS_SCORE = makeAsyncActionSet('CREATE_ENPS_SCORE');
export function createEnpsScore(data) {
  return (dispatch) => request(
    CREATE_ENPS_SCORE,
    '/api/organisation/create-enps-score/',
    'POST',
    data,
    {metaData: {score: data.score}}
  )(dispatch);
}

export const createEnpsScoreCloseModalAndToast = (data) => dispatch => createEnpsScore(data)(dispatch).then(() => {
  toast.dismiss();
  dispatch(closeModal());
  toast.success('Your eNPS score has been added.');
}).catch(() => {
  toast.dismiss();
  dispatch(closeModal());
  toast.error('There was a problem adding your eNPS score.');
});

export const EMPLOYEE_ENPS_DATA = 'EMPLOYEE_ENPS_DATA';
export const GET_EMPLOYEE_ENPS_DATA = makeAsyncActionSet('GET_EMPLOYEE_ENPS_DATA');
export function getEmployeeEnpsData() {
  return (dispatch) => {
    return request(
      GET_EMPLOYEE_ENPS_DATA,
      '/api/organisation/employee-enps-score/',
      'GET',
      undefined,
      {metaData: {key: EMPLOYEE_ENPS_DATA}}
    )(dispatch);
  };
}

export const MANAGER_ENPS_DATA = 'MANAGER_ENPS_DATA';
export const GET_MANAGER_ENPS_DATA = makeAsyncActionSet('GET_MANAGER_ENPS_DATA');
export function getManagerEnpsData() {
  return (dispatch) => {
    return request(
      GET_MANAGER_ENPS_DATA,
      '/api/organisation/manager-enps-score/',
      'GET',
      undefined,
      {metaData: {key: MANAGER_ENPS_DATA}}
    )(dispatch);
  };
}

export const ADMIN_ENPS_DATA = 'ADMIN_ENPS_DATA';
export const GET_ADMIN_ENPS_DATA = makeAsyncActionSet('GET_ADMIN_ENPS_DATA');
export function getAdminEnpsData() {
  return (dispatch) => {
    return request(
      GET_ADMIN_ENPS_DATA,
      '/api/organisation/admin-enps-score/',
      'GET',
      undefined,
      {metaData: {key: ADMIN_ENPS_DATA}}
    )(dispatch);
  };
}


export const GET_TREND_ANALYSIS_WIDGET_LIST = makeAsyncActionSet('GET_TREND_ANALYSIS_WIDGET_LIST');
export function getTrendAnalysisReportsList(filters = {}) {
  return (dispatch) => {
    return request(
      GET_TREND_ANALYSIS_WIDGET_LIST,
      '/api/custom-reports/dashboard-list/',
      'GET',
      Object.assign({page_size: 5, page: 1}, filters)
    )(dispatch);
  };
}

export const GET_TREND_ANALYSIS_WIDGET_NEXT_PAGE = makeAsyncActionSet('GET_TREND_ANALYSIS_WIDGET_NEXT_PAGE');
export function getTrendAnalysisReportsNextPage(filters = {}, page) {
  return (dispatch) => {
    return request(
      GET_TREND_ANALYSIS_WIDGET_NEXT_PAGE,
      '/api/custom-reports/dashboard-list/',
      'GET',
      Object.assign({page_size: 5, page}, filters),
      {metaData: {page}}
    )(dispatch);
  };
}

export const GET_MY_TEAM_FEEDBACK_RECEIVED = makeAsyncActionSet('GET_MY_TEAM_FEEDBACK_RECEIVED');
export function getMyTeamFeedbackReceived() {
  return (dispatch) => {
    return request(
      GET_MY_TEAM_FEEDBACK_RECEIVED,
      '/api/people/my-team-feedback-received/',
      'GET'
    )(dispatch);
  };
}

export const GET_EMPLOYEE_COMPLETE_FEEDBACK_REQUESTS = makeAsyncActionSet('GET_EMPLOYEE_COMPLETE_FEEDBACK_REQUESTS');
export function getEmployeeCompleteFeedbackRequests() {
  return (dispatch) => {
    return request(
      GET_EMPLOYEE_COMPLETE_FEEDBACK_REQUESTS,
      '/api/people/employee-complete-feedback-requests/',
      'GET'
    )(dispatch);
  };
}

export const GET_ADMIN_DASHBOARD_PEER_FEEDBACK_STATS = makeAsyncActionSet('GET_ADMIN_DASHBOARD_PEER_FEEDBACK_STATS');
export function getAdminDashboardPeerFeedbackStats() {
  return (dispatch) => {
    return request(
      GET_ADMIN_DASHBOARD_PEER_FEEDBACK_STATS,
      '/api/people/admin-dashboard-peer-feedback-stats/',
      'GET'
    )(dispatch);
  };
}

export const GET_UNREAD_PEER_FEEDBACK_COUNT = makeAsyncActionSet('GET_UNREAD_PEER_FEEDBACK_COUNT');
export function getUnreadFeedbackCount() {
  return (dispatch) => {
    return request(
      GET_UNREAD_PEER_FEEDBACK_COUNT,
      '/api/people/retrieve-unread-feedback-count/',
      'GET'
    )(dispatch);
  };
}
