import { POLL_USER_EXPORT, POLL_USER_IMPORT } from './actions';
import { EXPORT_STATUS_COMPLETE, IMPORT_STATUS_COMPLETE, IMPORT_STATUS_FAILED } from './const';

export function pollUserExport(state = {downloadUrl: null, loading: false}, action) {
  switch (action.type) {
    case POLL_USER_EXPORT.REQUEST:
      return {
        downloadUrl: null,
        loading: true
      };
    case POLL_USER_EXPORT.SUCCESS:
      if (action.payload.data.status !== EXPORT_STATUS_COMPLETE) {
        return state;
      }

      return {
        downloadUrl: action.payload.data.export_file_url,
        loading: false
      };
    default:
      return state;
  }
}

export function pollUserImport(state = {errors: null, loading: false}, action) {
  switch (action.type) {
    case POLL_USER_IMPORT.REQUEST:
      return {
        errors: null,
        loading: true
      };
    case POLL_USER_IMPORT.SUCCESS:
      if (action.payload.data.status === IMPORT_STATUS_FAILED) {
        return {
          loading: false,
          errors: action.payload.data.errors
        };
      }

      if (action.payload.data.status !== IMPORT_STATUS_COMPLETE) {
        return state;
      }

      return {
        loading: false
      };
    default:
      return state;
  }
}
