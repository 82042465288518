export const GOALS_PATH = 'goals';

export const ACTION_URGENCY = {
  OVERDUE: 'Overdue',
  DUE_SOON: 'Due soon',
  NON_URGENT: 'Non urgent',
  COMPLETED: 'Completed'
};

export const ACTION_URGENCY_PERCENTAGE = 80;

export const GOAL_STATUS = {
  COMPLETED: 'COMPLETED',
  DUE_SOON: 'DUE_SOON',
  OVERDUE: 'OVERDUE',
};

export const GOAL_STATUS_LABEL_MAP = {
  [GOAL_STATUS.COMPLETED]: 'Completed',
  [GOAL_STATUS.DUE_SOON]: 'Due soon',
  [GOAL_STATUS.OVERDUE]: 'Overdue',
};

export const GOAL_STATUS_COLOR_MAP = {
  [GOAL_STATUS.COMPLETED]: 'background-green',
  [GOAL_STATUS.DUE_SOON]: 'background-orange',
  [GOAL_STATUS.OVERDUE]: 'background-red',
};
