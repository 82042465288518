import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { removeAgendaTemplatePartAndToast } from '^/components/agendas/actions';
import AgendaItemCard from '^/components/agendas/AgendaItemCard';
import { CustomItemsNameMappedToSectionType } from '^/components/agendas/constants';
import CustomItemsSection from '^/components/agendas/CustomItemsSection';
import { getTemplateItemsMapping } from '^/components/agendas/templates';
import { splitSelectedTemplateParts } from '^/components/agendas/utils';

function RemovableItem({
  item,
  removeTemplatePart,
  isDisabled
}) {
  return (
    <AgendaItemCard
      title={item.text}
      onClick={removeTemplatePart}
      isDisabled={isDisabled}
      isRemoving
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeTemplatePart: () => dispatch(removeAgendaTemplatePartAndToast(
      ownProps.agendaId,
      ownProps.item.id
    ))
  };
};

const ConnectedRemovableItem = connect(undefined, mapDispatchToProps)(RemovableItem);

export function EditAgendaTemplateSelected({
  items,
  agendaId,
  isDisabled,
  sectionType
}) {
  return (
    <div>
      {items && items.length ? (
        items.map(item => (
          <ConnectedRemovableItem
            key={`${item.id}-remove`}
            agendaId={agendaId}
            item={item}
            isDisabled={isDisabled}
          />
        ))
      ) : (
        <p>No options selected.</p>
      )}
      <div className="margin-top-xx-large">
        <h4 className="font-weight-bold margin-bottom-base font-size-14">
          Comments
        </h4>
        <CustomItemsSection
          agendaId={agendaId}
          isDisabled={isDisabled}
          itemsName={CustomItemsNameMappedToSectionType[sectionType]}
          agendaStateKey="editingAgendaTemplate"
          isCommentItems
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const selectedTemplateParts = splitSelectedTemplateParts(state.editingAgendaTemplate.selected_template_parts);
  const itemsForSectionType = getTemplateItemsMapping(ownProps.performanceReviewTerm)[ownProps.sectionType];
  return {
    items: selectedTemplateParts && selectedTemplateParts.reduce((accumulator, selectedPart) => {
      const itemForPart = itemsForSectionType.find(item => item.id === selectedPart);
      return itemForPart ? [...accumulator, itemForPart] : accumulator;
    }, [])
  };
};

export default connect(mapStateToProps)(EditAgendaTemplateSelected);
