import * as React from 'react';  // eslint-disable-line no-unused-vars

import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { organisationLevelRoleOptions } from '../../roles';
import { individualReviewsListConfig } from './config';
import { INDIVIDUAL_REVIEW_LIST } from './constants';


export const IndividualReviewList = ({
  performanceReviewTerm,
  filterOptions,
  organisationTeamFilterTerm
}) => {
  const { teams, managers } = filterOptions;

  return (
    <div className="will">
      <ChadminList
        className="individual-review-list"
        collectionPath="reviews/individual-reviews"
        collectionName={INDIVIDUAL_REVIEW_LIST}
        columns={individualReviewsListConfig(performanceReviewTerm, organisationTeamFilterTerm)}
        ExtraControlsRight={() => (
          <div>
            <UriFilterSelect
              filterName="type"
              name="role"
              options={organisationLevelRoleOptions}
            />
            <UriFilterSelect
              filterName="team_member__team__id"
              name={organisationTeamFilterTerm}
              options={teams}
            />
            <UriFilterSelect
              filterName="managers__id"
              name="manager"
              label="Reports To"
              options={managers}
            />
          </div>
        )}
        searchable
        pullSearchRight
      />
    </div>
  );
};

export default IndividualReviewList;
