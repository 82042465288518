import moment from 'moment';
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactDatePicker from 'react-datepicker';
import { Field, reduxForm } from 'redux-form';

import { FORMAT_DATE_PICKER } from '^/utils';

const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper">
      <ReactDatePicker
        selected={props.input.value || moment().add(1, 'days')}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        disabled={props.isDisabled}
        tetherConstraints={[]}
        required
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

export function UpdateBulkAgendaDetailsForm({
  handleSubmit,
  isDisabled,
  cancel
}) {
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="title" className="font-weight-bold">
        Title
      </label>
      <Field
        name="title"
        id="title"
        component="input"
        type="text"
        disabled={isDisabled}
        required
      />
      <label htmlFor="description" className="font-weight-bold margin-top-x-large">
        Description
      </label>
      <Field
        name="description"
        id="description"
        component="textarea"
        disabled={isDisabled}
      />
      <label htmlFor="due_date" className="font-weight-bold margin-top-x-large">
        Due date
      </label>
      <Field
        name="due_date"
        id="due_date"
        component={DatePickerField}
        isDisabled={isDisabled}
      />
      <button
        className="btn middle orange margin-top-x-large"
        disabled={isDisabled}
      >
        Save
      </button>
      {cancel ? (
        <button
          className="btn middle orange hollow margin-top-x-large margin-left-large"
          onClick={cancel}
          disabled={isDisabled}
        >
          Cancel
        </button>
        ) :
        <a href="/agendas/list/" className="btn middle orange hollow margin-top-x-large margin-left-large">Cancel</a>
      }
    </form>
  );
}

export default reduxForm({
  form: 'updateBulkAgendaDetailsForm',
})(UpdateBulkAgendaDetailsForm);
