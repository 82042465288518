import { Well } from '@dabapps/roe';
import moment from 'moment';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';

import ActionCard from '^/components/goals/ActionCard';
import { addActionToGoalAndReload, toggleAddActionForm } from '^/components/goals/actions';
import EditGoalAddActionForm from '^/components/goals/EditGoalAddActionForm';
import ReadonlyActionCard from '^/components/goals/ReadonlyActionCard';
import { ACTION_STATUS } from '^/consts/actions';
import { FORMAT_DATE_BACKEND } from '^/utils';

export function ActionsPicker(props) {
  const sortedActions = props.goal
    .get('actions')
    .sortBy((action) =>
      props.excludedActions.includes(action.get('id'))
    );

  const myActions = props.goal.get('actions')
    .filter(action => action.getIn(['owner', 'value']) === props.loggedInUserOwnerOption.value);

  const otherActions = props.goal.get('actions')
    .filter(action => action.getIn(['owner', 'value']) !== props.loggedInUserOwnerOption.value);

  return (
    <div className="actions-picker">
      {props.readonly ? (
        <div>
          {myActions.count() ? (
            <div>
              <h4 className="margin-top-x-large margin-bottom-none">Assigned to me</h4>
              {myActions.map((action) => (
                <ReadonlyActionCard
                  key={action.get('id')}
                  action={action}
                  goal_due_date={props.goal.get('due_date')}
                  loggedInUserOwnerId={props.loggedInUserOwnerOption.value}
                />
              ))}
            </div>
          ) : null}
          {otherActions.count() ? (
            <div>
              <h4 className="margin-top-x-large margin-bottom-none">Other actions</h4>
              {otherActions.map((action) => (
                <ReadonlyActionCard
                  key={action.get('id')}
                  action={action}
                  loggedInUserOwnerId={props.loggedInUserOwnerOption.value}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <p className="margin-bottom-large">
            <strong>Actions available</strong>
          </p>
          <Well className="padding-left-large padding-right-large">
            {sortedActions.map((action, index) => (
              <ActionCard
                key={action.get('id')}
                canChangeUser={props.canChangeUser}
                action={action}
                isLoading={props.isLoading}
                excludeActionsMaxReached={props.excludeActionsMaxReached}
                index={index}
              />
            ))}
            {props.showAddActionForm ? (
              <EditGoalAddActionForm
                onSubmit={props.onSubmitAddAction}
                initialValues={{
                  owner: props.loggedInUserOwnerOption,
                  due_date: moment().add(1, 'days'),
                  status: ACTION_STATUS.NOT_STARTED
                }}
                removeAction={props.toggleAddActionForm}
              />
            ) : (
              <div className="text-center">
                <button
                  className="btn middle orange margin-vertical-large"
                  onClick={props.toggleAddActionForm}
                >
                  Add action
                </button>
              </div>
            )}
          </Well>
        </div>
      )}
    </div>
  );
}

export function mapStateToProps(state) {
  const { id: value, first_name, last_name } = state.loggedInUser.toObject();
  return {
    goal: state.goalData,
    isLoading: state.actionReducer.get('isLoading', false),
    excludedActions: state.actionPickerExcludedActions,
    loggedInUserOwnerOption: {
      value,
      label: `${first_name} ${last_name}`,
    },
    showAddActionForm: state.isShowingAddActionForm,
    excludeActionsMaxReached: (
      state.actionPickerExcludedActions.count() === (
        state.goalData.get('actions').count() - 1
      )
    )
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmitAddAction: data => dispatch(addActionToGoalAndReload(
      ownProps.goalId,
      Object.assign({}, data, {
        due_date: data.due_date.format(FORMAT_DATE_BACKEND),
        owner: data.owner.value
      })
    )),
    toggleAddActionForm: () => dispatch(toggleAddActionForm())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPicker);
