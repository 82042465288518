import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Column, Row } from '@dabapps/roe';
import ActionTypeIcon from '../actions/ActionTypeIcon';
import ActionUrgency from './ActionUrgency';
import moment from 'moment';
import { FORMAT_DATE } from '../../utils';
import { updateActionAndDisplayToast } from '^/actions/actionSequences';
import { ACTION_STATUS_OPTIONS, ACTION_TYPE } from '^/consts/actions';

function getActionImage(action) {
  if (action.get('type') === ACTION_TYPE.COURSE) {
    return action.getIn(['course_hyperlink', 'image']);
  }

  if (action.get('type') === ACTION_TYPE.RESOURCE) {
    return action.getIn(['resource_hyperlink', 'image']);
  }

  if (action.get('image')) {
    return action.get('image');
  }

  return null;
}

const getRadioButtonId = (actionId, id) => `${id}-${actionId}`;

const StatusRadioButtons = ({actionId, updateStatusField, currentStatus, disabled}) => (
  <div className="margin-top-base">
    {ACTION_STATUS_OPTIONS.map(({value, label}) => (
      <div className="display-inline-block margin-top-large" key={`radio-status-input-${label}-${actionId}`}>
        <input
          onChange={updateStatusField}
          type="radio"
          name={`status-${actionId}`}
          id={getRadioButtonId(actionId, value)}
          value={value}
          checked={value === currentStatus}
          disabled={disabled}
        />
        <label className="margin-left-small margin-right-large" htmlFor={getRadioButtonId(actionId, value)}>
          {label}
        </label>
      </div>
    ))}
  </div>
);

function checkStatus(action) {
  const actionStatus = action.get('status');
  if (actionStatus === 'COMPLETED') {
    return null;
  }
  return actionStatus;
}

export const ReadonlyActionCard = (props) => {
  const actionImage = getActionImage(props.action);
  const actionStatus = checkStatus(props.action);
  const disableStatusRadioButtons = props.loggedInUserOwnerId !== props.action.getIn(['owner', 'value']);
  return (
    <div
      className={classnames(
        'action-card',
        {
          excluded: !actionStatus,
        }
      )}
    >
      <div className="text-gray">
        <ActionTypeIcon actionType={props.action.get('type')} />
      </div>
      <Row>
        <Column md={9}>
          <div className="margin-top-base">
            <a href={`/goals/actions/${props.action.get('id')}/?origin=goal`} className="font-size-14 font-weight-bold">
              {props.action.get('title')}
            </a>
            {actionImage && (
              <img
                src={actionImage}
                className="display-block max-width-200 margin-bottom-x-large margin-top-x-large"
              />
            )}
            <div className="margin-top-base">
              {props.action.get('description')}
            </div>
          </div>
          <StatusRadioButtons
            actionId={props.action.get('id')}
            updateStatusField={props.updateStatusField}
            currentStatus={props.action.get('status')}
            disabled={disableStatusRadioButtons}
          />
        </Column>
        {actionStatus ? (
        <Column md={3}>
          <div className="margin-bottom-small text-align-right">
            <strong>
              Due: {moment(props.action.get('due_date')).format(FORMAT_DATE)}
            </strong>
          </div>
          <div className="margin-top-small margin-bottom-large text-align-right">
            Assignee:{' '}
            {props.action.getIn(['owner', 'label'])}
          </div>
          <div className="margin-bottom-base text-align-right">
            <ActionUrgency action={props.action} baseClassName="action-chip" />
          </div>
        </Column>
        ) : (
        <Column md={3}>
          <div className="margin-top-base margin-bottom-none padding-bottom-small text-align-right">
            Assignee:{' '}
            {props.action.getIn(['owner', 'label'])}
          </div>
          <div className="margin-top-none margin-bottom-base text-align-right padding-top-small">
            <ActionUrgency action={props.action} baseClassName="action-chip" />
          </div>
        </Column>
        )}
      </Row>
    </div>
  );
};

export function mapDispatchToProps(dispatch, ownProps) {
  const actionId = ownProps.action.get('id');
  return {
    updateStatusField: (event) => {
      dispatch(updateActionAndDisplayToast(actionId, {status: event.target.value}));
    },
  };
}

export default connect(undefined, mapDispatchToProps)(ReadonlyActionCard);
