import React from 'react'; // eslint-disable-line no-unused-vars
import makeRsaAction from '^/middleware/makeRsaAction';
import { makeAsyncActionSet } from '^/actions/actions';

export const LOAD_ITEM = makeAsyncActionSet('LOAD_ITEM');

export function _loadItem(itemType, id, url, method = 'GET') {
  const action = makeRsaAction(
    LOAD_ITEM,
    url,
    method,
    null,
    { itemType, id, source: itemType }
  );
  return action;
}

export const TOGGLE_EDIT_ITEM = 'TOGGLE_EDIT_ITEM';

export function toggleItemEdit(model, item) {
  return {
    type: TOGGLE_EDIT_ITEM,
    model,
    item
  };
}

export const CLEAR_ITEM = 'CLEAR_ITEM';

export function clearItem(itemType) {
  return {
    type: CLEAR_ITEM,
    payload: itemType
  };
}

export const UPDATE_ITEM = makeAsyncActionSet('UPDATE_ITEM');

export function _updateItem(collectionName, url, data, method = 'PUT', source = collectionName) {
  return makeRsaAction(
    UPDATE_ITEM,
    url,
    method,
    data,
    { collectionName, source }
  );
}

export const CREATE_ITEM = makeAsyncActionSet('CREATE_ITEM');

export function _createItem(collectionName, url, data, method = 'POST') {
  return makeRsaAction(
    CREATE_ITEM,
    url,
    method,
    data,
    { collectionName, source: collectionName }
  );
}

export const GET_ITEM_OPTIONS = makeAsyncActionSet('GET_ITEM_OPTIONS');

export function _getItemOptions(collectionName, url) {
  return makeRsaAction(
    GET_ITEM_OPTIONS,
    url,
    'OPTIONS',
    null,
    { collectionName, source: collectionName }
  );
}


export function loadItem(type, id, name = type) {
  return _loadItem(name, id, `/${type}/${id}/`);
}

export function updateItem(type, id, data, name = type) {
  return _updateItem(name, `/${type}/${id}/`, data, 'PUT');
}

export function patchItem(type, id, data, source = type) {
  return _updateItem(type, `/${type}/${id}/`, data, 'PATCH', source);
}

export function createItem(type, data, name = type, method) {
  return _createItem(name, `/${type}/`, data, method);
}

export function getItemOptions(type, id, name = type) {
  if (!id) {
    return _getItemOptions(name, `/${type}/`);
  } else {
    return _getItemOptions(name, `/${type}/${id}/`);
  }
}
