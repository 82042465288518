export const PENDING = 'PENDING';
export const SUCCESSFUL = 'SUCCESSFUL';
export const FAILED = 'FAILED';

export function isPending(response) {
  return response && response.get('state') === PENDING;
}

export function hasFailed(response) {
  return response && response.get('state') === FAILED;
}

export function hasSucceeded(response) {
  return response && response.get('state') === SUCCESSFUL;
}