import { Map, List } from 'immutable';
import { addCallbacks, addCallbacksToBareAction } from '../middleware/actionChain';
import { isAction } from '../utils';

// Wow, javascript does not like mutually recursive functions

let processNextSuccess, processNextFailure;

processNextSuccess = function (next, catcher, after, payload, action) {
  if (next.count() > 0) {
    const nextHandler = next.first().get('success');
    if (nextHandler) {
      const result = nextHandler(payload, action);
      if (isAction(result)) {
        return addCallbacksToBareAction(
          result,
          processNextSuccess.bind(null, next.rest(), catcher, after),
          processNextFailure.bind(null, next.rest(), catcher, after)
        );
      } else {
        //If we've still got more, pass it to the next success handler
        return processNextSuccess(next.rest(), catcher, after, result);
      }
    }
  }
};

processNextFailure = function(next, catcher, after, payload, action) {
  if (next.count() > 0) {
    const nextHandler = next.first().get('failure');
    if (nextHandler) {
      const result = nextHandler(payload, action);
      if (isAction(result)) {
        return addCallbacksToBareAction(
          result,
          processNextSuccess.bind(null, next.rest(), catcher, after),
          processNextFailure.bind(null, next.rest(), catcher, after)
        );
      } else if (result !== undefined && result !== null) {
        //If we've still got more, pass it to the next success handler
        return processNextSuccess(next.rest(), catcher, after, result);
      }
      return;
    }
  }
};

export default class ActionChain {

  constructor(actionCreator, nextItems = List([])) {
    this._actionCreator = actionCreator;
    this._next = nextItems;
    this._failure = null;
    this._finally = null;
  }

  then(success, failure) {
    return new ActionChain(
      this._actionCreator,
      this._next.push(Map({
        success,
        failure
      }))
    );
  }

  catch(/* failure */) {
    throw new Error('Not yet implemented');
  }

  finally(/* fn */) {
    throw new Error('Not yet implemented');
  }

  process() {
    return addCallbacks(
      this._actionCreator,
      processNextSuccess.bind(null, this._next, this._failure, null),
      processNextFailure.bind(null, this._next, this._failure, null)
    )();
  }
}
