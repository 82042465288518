import React from 'react';  // eslint-disable-line no-unused-vars

import AgendaGoalsManager from '^/components/agendas/AgendaGoalsManager';
import CommentSection from '^/components/agendas/CommentSection';
import { EDIT_MANAGER_NOTES } from '^/components/agendas/constants';
import TalentSnapshotForm from './TalentSnapshotForm';

export function AgendaManagerOnlySection(props) {
  return (
    <div>
      <div className="padding-bottom-x-large">
        <CommentSection
          agendaId={props.agendaId}
          comments={props.agenda.manager_notes}
          editCommentsName={EDIT_MANAGER_NOTES}
          isDisabled={props.isDisabled}
          title="Manager notes"
          field="manager_notes"
          initialValues={{
            manager_notes: props.agenda.manager_notes
          }}
        />
      </div>
      <div className="padding-top-x-large padding-bottom-x-large border-top">
        <AgendaGoalsManager
          agendaId={props.agendaId}
          goals={props.agenda.manager_goals}
          isDisabled={props.isDisabled}
        />
      </div>
      <div className="padding-top-x-large border-top">
        <TalentSnapshotForm
          talentSnapshotOptions={props.talentSnapshotOptions}
          agendaId={props.agendaId}
          isDisabled={props.isDisabled}
        />
      </div>
    </div>
  );
}

export default AgendaManagerOnlySection;
