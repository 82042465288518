import { Map } from 'immutable';
import {
  GET_SURVEY_OPTIONS,
  UPDATE_SELECTED_SURVEYS,
  UPDATE_SELECTED_SURVEY_COMMENT,
  UPDATE_SELECTED_SURVEY_TYPE,
  CLEAR_SELECTED_SURVEYS,
  CREATE_CUSTOM_REPORT,
  CREATE_FILTERED_CUSTOM_REPORT,
  POLL_CUSTOM_REPORT,
  TOGGLE_ADD_CUSTOM_REPORT,
  UPDATE_SELECTED_BENCHMARKS,
  ADD_SELECTED_BENCHMARKS,
  GET_CUSTOM_REPORT_FILTER_OPTIONS,
  UPDATE_SELECTED_FILTERS,
  DELETE_CUSTOM_REPORT,
  GET_CUSTOM_REPORT_LIST,
  GET_AVAILABLE_BENCHMARKS,
  GET_CUSTOM_REPORT,
  UPDATE_CUSTOM_REPORT,
  UPDATE_CUSTOM_REPORT_NAME,
  PUBLISH_CUSTOM_REPORT,
  UNPUBLISH_CUSTOM_REPORT
} from '^/components/custom-reports/actions';
import { SURVEY_TYPES } from '^/components/custom-reports/constants';

export function publishCustomReportError(state = {}, action) {
  switch (action.type) {
    case PUBLISH_CUSTOM_REPORT.REQUEST:
      return Object.assign({}, state, {[action.meta.reportId]: null});
    case PUBLISH_CUSTOM_REPORT.FAILURE:
      return Object.assign({}, state, {[action.meta.reportId]: action.payload.response.data});
    default:
      return state;
  }
}

export function availableBenchmarks(state = {}, action) {
  switch (action.type) {
    case GET_AVAILABLE_BENCHMARKS.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function customReportFilterOptions(state = {}, action) {
  switch (action.type) {
    case GET_CUSTOM_REPORT_FILTER_OPTIONS.SUCCESS:
      return Object.assign({}, state, {
        [action.meta.reportId]: action.payload.data
      });
    default:
      return state;
  }
}

export function surveyOptions(state = {}, action) {
  switch (action.type) {
    case GET_SURVEY_OPTIONS.SUCCESS:
      return Object.assign({}, state, {
        [action.meta.selectId]: action.payload.data
      });
    default:
      return state;
  }
}

export function selectedSurveyType(state = SURVEY_TYPES.ENGAGEMENT, action) {
  switch (action.type) {
    case UPDATE_SELECTED_SURVEY_TYPE:
      return action.surveyType;
    default:
      return state;
  }
}

const DEFAULT_SELECTED_SURVEY_STATE = {
  [SURVEY_TYPES.ENGAGEMENT]: null,
  [SURVEY_TYPES.MENTAL_HEALTH]: null,
  [SURVEY_TYPES.CULTURE_AND_DIVERSITY]: null
};

export function selectedSurveys(state = DEFAULT_SELECTED_SURVEY_STATE, action) {
  switch (action.type) {
    case UPDATE_SELECTED_SURVEYS:
      return Object.assign({}, state, {
        [action.surveyType]: action.selectedSurveys
      });
    case UPDATE_SELECTED_SURVEY_COMMENT:
      return Object.assign({}, state, {
        [action.surveyType]: state[action.surveyType].map(selectedSurvey => (
          selectedSurvey.value === action.surveyId ?
          Object.assign({}, selectedSurvey, { comment: action.comment })
          : selectedSurvey
        ))});
    case CLEAR_SELECTED_SURVEYS:
      return DEFAULT_SELECTED_SURVEY_STATE;
    default:
      return state;
  }
}

function updateReportData(state, reportId, newData) {
  return state.map(report => report.id === reportId ?
    Object.assign({}, report, newData) : report
  );
}

export function customReportsList(state = [], action) {
  switch (action.type) {
    case GET_CUSTOM_REPORT_LIST.SUCCESS:
      return action.payload.data.results;
    case GET_CUSTOM_REPORT.SUCCESS:
    case CREATE_CUSTOM_REPORT.SUCCESS:
      return [action.payload.data, ...state];
    case PUBLISH_CUSTOM_REPORT.SUCCESS:
    case UNPUBLISH_CUSTOM_REPORT.SUCCESS:
    case POLL_CUSTOM_REPORT.SUCCESS:
    case UPDATE_CUSTOM_REPORT_NAME.SUCCESS:
    case UPDATE_CUSTOM_REPORT.SUCCESS:
    case CREATE_FILTERED_CUSTOM_REPORT.SUCCESS:
      return updateReportData(state, action.meta.reportId, action.payload.data);
    case UPDATE_SELECTED_FILTERS:
      return updateReportData(state, action.reportId, action.filterData);
    case DELETE_CUSTOM_REPORT.SUCCESS:
      return state.filter(report => report.id !== action.meta.reportId);
    default:
      return state;
  }
}

export function addNewCustomReport(state = false, action) {
  switch (action.type) {
    case CREATE_CUSTOM_REPORT.SUCCESS:
      return false;
    case TOGGLE_ADD_CUSTOM_REPORT:
      return !state;
    default:
      return state;
  }
}

const DEFAULT_SELECTED_BENCHMARK_STATE = {
  [SURVEY_TYPES.ENGAGEMENT]: [],
  [SURVEY_TYPES.MENTAL_HEALTH]: [],
  [SURVEY_TYPES.CULTURE_AND_DIVERSITY]: []
};

export function selectedBenchmarkingOptions(state = DEFAULT_SELECTED_BENCHMARK_STATE, action) {
  switch (action.type) {
    case UPDATE_SELECTED_BENCHMARKS:
      const benchmarksForSurveyType = state[action.surveyType];
      return Object.assign({}, state, {
        [action.surveyType]: benchmarksForSurveyType.includes(action.selectedBenchmark) ?
          benchmarksForSurveyType.filter(benchmark => benchmark !== action.selectedBenchmark) :
          [...benchmarksForSurveyType, action.selectedBenchmark]
      });
    case ADD_SELECTED_BENCHMARKS:
      return Object.assign({}, state, {
        [action.surveyType]: action.selectedBenchmarks
      });
    default:
      return state;
  }
}

export function customReportListCollection(state = Map(), action) {
  switch (action.type) {
    case GET_CUSTOM_REPORT_LIST.SUCCESS:
      return Map({
        count: action.payload.data.count,
        page: action.meta.page,
      });
    case CREATE_CUSTOM_REPORT.SUCCESS:
      return state.set('count', state.get('count') + 1);
    case DELETE_CUSTOM_REPORT.SUCCESS:
      return state.set('count', state.get('count') - 1);
    default:
      return state;
  }
}
