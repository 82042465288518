import { anyPending, isPending } from '@dabapps/redux-requests';
import { Column, Row, ModalRenderer } from '@dabapps/roe';
import moment from 'moment';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import CommentSection from './CommentSection';
import {
  agendaItemEditAction,
  getAgendaTemplate,
  GET_AGENDA_TEMPLATE,
  UPDATE_AGENDA_TEMPLATE,
  UPDATE_AGENDA_ITEM,
  CREATE_AGENDA_ITEM,
  DELETE_AGENDA_ITEM,
  UPDATE_AGENDA_SECTION_POSITION,
  agendaItemCancelEdit,
  updateAgendaTemplateAndToast,
  ADD_AGENDA_TEMPLATE_PART,
  REMOVE_AGENDA_TEMPLATE_PART,
  CANCEL_BULK_AGENDA
} from '^/components/agendas/actions';
import UpdateBulkAgendaDetailsForm from '^/components/agendas/UpdateBulkAgendaDetailsForm';
import {
  AgendaSectionType,
  AgendaSectionTypeTitleMap,
  EDIT_AGENDA_DETAILS,
  EDIT_SHARED_NOTES,
  EDIT_MANAGER_NOTES
} from '^/components/agendas/constants';
import Loading from '^/components/Loading';
import {
  FORMAT_DATE_BACKEND
} from '^/utils';
import EditAgendaTemplateSelect from './EditAgendaTemplateSelect';
import EditAgendaTemplateSelected from './EditAgendaTemplateSelected';
import BulkAgendaCancelButton from './BulkAgendaCancelButton';
import CustomItemsSection from './CustomItemsSection';
import AgendaSectionWrapper from './AgendaSectionWrapper';
import ExpandIcon from '../common/ExpandIcon';
import CollapseIcon from '../common/CollapseIcon';

export function hasAgendaSection(sectionType, canCreateOkrs, canCreatePeerFeedback) {
  if (sectionType === AgendaSectionType.OKRS && !canCreateOkrs) {
    return false;
  }
  if (sectionType === AgendaSectionType.PEER_FEEDBACK && !canCreatePeerFeedback) {
    return false;
  }
  return true;
}

const AgendaTemplateDetails = ({agenda, isDisabled, editAgendaDetails}) => (
  <div>
    <p className="font-weight-bold margin-bottom-base font-size-14">Title</p>
    <p>{agenda.title}</p>
    <p className="font-weight-bold margin-bottom-base font-size-14">Description</p>
    <p className="white-space-pre-line">{agenda.description || '-'}</p>
    <p className="font-weight-bold margin-bottom-base font-size-14">Due Date</p>
    <p>{moment(agenda.due_date).format('DD/MM/YYYY')}</p>
    <button
      className="btn middle orange"
      disabled={isDisabled}
      onClick={editAgendaDetails}
    >
      Edit
    </button>
  </div>
);

export class EditAgendaTemplate extends React.PureComponent {

  constructor(props) {
    super(props);
    this.toggleSelectableItems = this.toggleSelectableItems.bind(this);
    this.state = {
      showSelectableItems: true
    };
  }

  componentDidMount() {
    this.props.getAgendaTemplate();
  }

  toggleSelectableItems() {
    this.setState({showSelectableItems: !this.state.showSelectableItems});
  }

  render() {
    const {
      isDisabled,
      isLoadingAgendaTemplate,
      agenda,
      isEditingDetails,
      performanceReviewTerm,
      canCreateOkrs,
      canCreatePeerFeedback
    } = this.props;

    if (isLoadingAgendaTemplate || !agenda) {
      return <Loading />;
    }

    return (
      <div className="background-white padding-xx-large border-radius-22 margin-vertical-xx-large">
        <h1 className="font-family-effra-bold font-size-38 font-color-main">
          Create multiple 1:1s
        </h1>
        <h2 className="font-family-effra-bold font-size-28 font-color-main">
          Step 4 of 4: Create agenda
        </h2>
        <Row className="margin-bottom-xxx-large">
          {this.state.showSelectableItems ? (
            <Column lg={6}>
              <div className="border-base border-radius-10 overflow-hidden
                section-create-agenda-padding padding-bottom-base"
              >
                <button
                  className="agenda-toggle-collapse-button pull-right padding-none"
                  onClick={this.toggleSelectableItems}
                >
                  <CollapseIcon />
                </button>
                <h4 className="font-size-18 font-weight-bold">
                  Select agenda items
                </h4>
                <p className="font-size-14 margin-vertical-xx-large">
                  Expand the dropdown menus and select the cards you want to add to the agenda by
                  clicking on the + sign next to each one.
                  Make your 1:1 agenda full screen and hide this section by clicking on the orange arrow.
                </p>
                {[
                  AgendaSectionType.CUSTOM_ITEMS,
                  AgendaSectionType.PERFORMANCE_REVIEW,
                  AgendaSectionType.PEER_FEEDBACK,
                  AgendaSectionType.ENGAGEMENT_SURVEY,
                  AgendaSectionType.EMPLOYEE_GOALS,
                  AgendaSectionType.OKRS
                ].map(sectionType =>
                  hasAgendaSection(sectionType, canCreateOkrs, canCreatePeerFeedback) && (
                    <EditAgendaTemplateSelect
                      key={`${sectionType}-select-items`}
                      sectionType={sectionType}
                      agendaId={this.props.agendaId}
                      performanceReviewTerm={performanceReviewTerm}
                      isDisabled={isDisabled}
                    />
                  )
                )}
              </div>
            </Column>
          ) : (
            <Column lg={1}>
              <div className="agenda-left-side-collapsed">
                <button
                  className="agenda-toggle-collapse-button padding-none margin-bottom-xx-large"
                  onClick={this.toggleSelectableItems}
                >
                  <ExpandIcon />
                </button>
                <h4 className="agenda-left-side-collapsed-text font-size-24 font-weight-bold">Select agenda items</h4>
              </div>
            </Column>
          )}
          <Column lg={this.state.showSelectableItems ? 6 : 11}>
            <div className="border-base border-radius-10 overflow-hidden">
              <div className="section-create-agenda section-create-agenda-padding">
                <h4 className="font-size-18 font-weight-bold">
                  1:1 Agenda
                </h4>
                {isEditingDetails ? (
                  <UpdateBulkAgendaDetailsForm
                    onSubmit={this.props.updateAgendaTemplate}
                    initialValues={{
                      title: agenda.title,
                      description: agenda.description,
                      due_date: moment(agenda.due_date)
                    }}
                    isDisabled={isDisabled}
                    cancel={this.props.cancelAgendaDetails}
                  />
                ) : (
                  <AgendaTemplateDetails
                    agenda={agenda}
                    isDisabled={isDisabled}
                    editAgendaDetails={this.props.editAgendaDetails}
                  />
                )}
              </div>
              {agenda.sections.map((sectionType, index) => (
                <AgendaSectionWrapper
                  hideSection={!hasAgendaSection(sectionType, canCreateOkrs, canCreatePeerFeedback)}
                  isFirst={index === 0}
                  isLast={index === agenda.sections.length - 1}
                  agendaId={this.props.agendaId}
                  title={
                    sectionType === AgendaSectionType.PERFORMANCE_REVIEW
                    ? performanceReviewTerm
                    : AgendaSectionTypeTitleMap[sectionType]}
                  sectionType={sectionType}
                  position={index}
                  isDisabled={isDisabled}
                  key={`section-${index}`}
                >
                  {sectionType === AgendaSectionType.CUSTOM_ITEMS && (
                    <CustomItemsSection
                      agendaId={this.props.agendaId}
                      isDisabled={isDisabled}
                      itemsName="custom_items"
                      agendaStateKey="editingAgendaTemplate"
                    />
                  )}
                  {sectionType === AgendaSectionType.SHARED_NOTES && (
                    <CommentSection
                      agendaId={this.props.agendaId}
                      comments={agenda.shared_notes}
                      editCommentsName={EDIT_SHARED_NOTES}
                      isDisabled={isDisabled}
                      field="shared_notes"
                      initialValues={{
                        shared_notes: agenda.shared_notes
                      }}
                      updateAction={updateAgendaTemplateAndToast}
                    />
                  )}
                  {[
                    AgendaSectionType.PERFORMANCE_REVIEW,
                    AgendaSectionType.ENGAGEMENT_SURVEY,
                    AgendaSectionType.EMPLOYEE_GOALS,
                    AgendaSectionType.OKRS,
                    AgendaSectionType.PEER_FEEDBACK
                  ].includes(sectionType) && (
                    <EditAgendaTemplateSelected
                      sectionType={sectionType}
                      agendaId={this.props.agendaId}
                      performanceReviewTerm={performanceReviewTerm}
                      isDisabled={isDisabled}
                    />
                  )}
                </AgendaSectionWrapper>
              ))}
              <AgendaSectionWrapper
                title={AgendaSectionTypeTitleMap[AgendaSectionType.MANAGER_ONLY]}
                sectionType={AgendaSectionType.MANAGER_ONLY}
              >
                <CommentSection
                  agendaId={this.props.agendaId}
                  comments={agenda.manager_notes}
                  editCommentsName={EDIT_MANAGER_NOTES}
                  isDisabled={isDisabled}
                  title="Manager notes"
                  field="manager_notes"
                  initialValues={{
                    manager_notes: agenda.manager_notes
                  }}
                  updateAction={updateAgendaTemplateAndToast}
                />
              </AgendaSectionWrapper>
            </div>
          </Column>
        </Row>
        <p className="text-align-right font-family-varela-round font-size-16">
          Next: Preview agenda template
        </p>
        <div className="display-flex align-items-center">
          <a
            className="link-color-text underline font-size-16"
            href={isDisabled ? undefined : `/agendas/${this.props.agendaId}/select-participants/`}
            disabled={isDisabled}
          >
            {'< Back to participants'}
          </a>
          <a
            className="pink-button font-color-white text-decoration-none margin-left-auto button-line-height"
            href={isDisabled ? undefined : `/agendas/${this.props.agendaId}/preview/`}
            disabled={isDisabled}
          >
            Next
          </a>
          <BulkAgendaCancelButton agendaId={this.props.agendaId} isDisabled={isDisabled} />
        </div>
        <ToastContainer />
        <ModalRenderer modals={this.props.modals}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEditingDetails: state.editingAgendaItemIds.includes(EDIT_AGENDA_DETAILS),
    agenda: state.editingAgendaTemplate,
    modals: state.modals,
    isLoadingAgendaTemplate: isPending(state.responses, GET_AGENDA_TEMPLATE),
    isDisabled: anyPending(state.responses, [
      GET_AGENDA_TEMPLATE, UPDATE_AGENDA_TEMPLATE, UPDATE_AGENDA_ITEM, CREATE_AGENDA_ITEM, DELETE_AGENDA_ITEM,
      UPDATE_AGENDA_SECTION_POSITION, ADD_AGENDA_TEMPLATE_PART, REMOVE_AGENDA_TEMPLATE_PART, CANCEL_BULK_AGENDA
    ])
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getAgendaTemplate: () => dispatch(getAgendaTemplate(ownProps.agendaId)),
    editAgendaDetails: () => dispatch(agendaItemEditAction(EDIT_AGENDA_DETAILS)),
    cancelAgendaDetails: event => {
      event.preventDefault();
      dispatch(agendaItemCancelEdit(EDIT_AGENDA_DETAILS));
    },
    updateAgendaTemplate: data => {
      dispatch(updateAgendaTemplateAndToast(
        ownProps.agendaId,
        Object.assign({}, data, {
          due_date: data.due_date.format(FORMAT_DATE_BACKEND)
        }),
        EDIT_AGENDA_DETAILS
      ));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAgendaTemplate);
