import React from 'react'; // eslint-disable-line no-unused-vars
import classNames from 'classnames';

export default (props) => (
  <label
    {...props}
    className={classNames('display-inline', props.className)}
  >
    {props.children}
  </label>
);
