import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Column, Row } from '@dabapps/roe';
import {
  updateAdditionalFilterOptions,
  updateAdditionalFilterOption,
  removeAdditionalFilterOption,
  addAdditionalFilterOption
} from '^/components/filters/actions';
import { MINIMUM_OPTIONS } from './constants';

export class AdditionalFilterOptions extends React.PureComponent {

  constructor(props) {
    super(props);
    this.updateAdditionalFilterOption = this.updateAdditionalFilterOption.bind(this);
    this.removeAdditionalFilterOption = this.removeAdditionalFilterOption.bind(this);
    this.addAdditionalFilterOption = this.addAdditionalFilterOption.bind(this);
    this.setDefaultMinimumOptionsForAdditionalFilter = this.setDefaultMinimumOptionsForAdditionalFilter.bind(this);
  }

  updateAdditionalFilterOption(event) {
    this.props.updateAdditionalFilterOption(event.target.getAttribute('data-index'), event.target.value);
  }

  removeAdditionalFilterOption(event) {
    event.preventDefault();
    this.props.removeAdditionalFilterOption(
      parseInt(event.target.getAttribute('data-index'), 10),
      MINIMUM_OPTIONS
    );
  }

  addAdditionalFilterOption(event) {
    event.preventDefault();
    this.props.addAdditionalFilterOption();
  }

  setDefaultMinimumOptionsForAdditionalFilter() {
    this.props.updateAdditionalFilterOptions([{label: ''}]);
  }

  componentDidMount() {
    const { additionalFilterOptions } = this.props;
    if (additionalFilterOptions && additionalFilterOptions.length) {
      this.props.updateAdditionalFilterOptions(additionalFilterOptions);
    } else {
      this.setDefaultMinimumOptionsForAdditionalFilter();
    }
  }

  componentWillUnmount() {
    this.props.updateAdditionalFilterOptions([]);
  }

  render() {
    const { additionalFilterOptions, disabled } = this.props;
    const minimumSatisfied = additionalFilterOptions.length > MINIMUM_OPTIONS;
    return (
      <div>
        {additionalFilterOptions.map((option, index) => (
          <Row className="margin-top-small" key={index}>
            <Column xs={12} md={2}>
              <label className="input-line-height">Option {index + 1}</label>
            </Column>
            <Column xs={12} md={9}>
              <input type="text"
                data-index={index}
                onChange={this.updateAdditionalFilterOption}
                placeholder={`Option ${index + 1}`}
                value={option.label}
                autoFocus={(minimumSatisfied && !option.label) ? (
                  index === additionalFilterOptions.length - 1
                ) : undefined}
                disabled={disabled}
              />
            </Column>
            {minimumSatisfied && (
              <Column xs={12} md={1} className="custom-question-options padding-left-none">
                <a
                  href={!disabled ? '' : undefined}
                  className="toggle-inclusion-icon input-line-height padding-none float-left"
                  onClick={!disabled && this.removeAdditionalFilterOption}
                  disabled={disabled}
                  data-index={index}
                >
                  <i data-index={index} className="far fa-times"></i>
                </a>
              </Column>
            )}
          </Row>
        ))}
        <Row className="margin-top-small margin-bottom-large">
          <Column xs={12} md={2}>
          </Column>
          <Column xs={12} md={10}>
            <a
              href={!disabled ? '' : undefined}
              className="btn orange hollow font-size-14"
              onClick={!disabled && this.addAdditionalFilterOption}
              disabled={disabled}
            >
              Add option
            </a>
          </Column>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalFilterOptions: state.additionalFilterOptions
  };
}

export default connect(mapStateToProps, {
  updateAdditionalFilterOptions,
  updateAdditionalFilterOption,
  removeAdditionalFilterOption,
  addAdditionalFilterOption
})(AdditionalFilterOptions);
