import React from 'react'; // eslint-disable-line
import { getColorForProgress, OKR_STATUS_LABEL_MAP } from './constants';

export const OkrStatus = ({
  status,
  completedPercentage,
  statusCalculationHint
}) => {

  return (
    <div title={statusCalculationHint}>
      <div className="okr-tile-status-bar">
        <div
          className={`okr-tile-status-bar-progress ${getColorForProgress(completedPercentage)}`}
          style={{width: `${completedPercentage}%`}}
        >
        </div>
      </div>
      <div className="flex-space-between">
        <p className="fwb margin-bottom-none text-size-13">{OKR_STATUS_LABEL_MAP[status]}</p>
        <p className="fwb margin-bottom-none text-size-13">{completedPercentage || 0}%</p>
      </div>
    </div>
  );
};


