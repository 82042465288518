import React from 'react'; // eslint-disable-line no-unused-vars
import RemovePeerFeedbackFromAgendaCard from './RemovePeerFeedbackFromAgendaCard';
import CustomItemsSection from './CustomItemsSection';

export const AgendaPeerFeedbackSection = ({
  agendaId,
  isDisabled,
  selectedFeedback
}) => {
  return (
    <div>
      {selectedFeedback.length ? selectedFeedback.map(feedback => (
        <RemovePeerFeedbackFromAgendaCard
          key={feedback.id}
          item={feedback}
          agendaId={agendaId}
          isDisabled={isDisabled}
        />
      )) : (
        <p>No feedback selected.</p>
      )}
      <div className="margin-top-xx-large">
        <h4 className="font-weight-bold margin-bottom-base font-size-14">
          Comments
        </h4>
        <CustomItemsSection
          agendaId={agendaId}
          isDisabled={isDisabled}
          itemsName="peer_feedback_comments"
          isCommentItems
        />
      </div>
    </div>
  );
};

export default AgendaPeerFeedbackSection;
