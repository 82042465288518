import React from 'react'; // eslint-disable-line no-unused-vars

import SurveyItem from '^/components/dashboards/SurveyItem';
import WidgetTitle from '^/components/dashboards/WidgetTitle';
import Loading from '^/components/Loading';

export function SurveysList({
  hasFailedLoading,
  isLoading,
  surveys,
  emptyText,
  surveyButton = null,
  isManagerDashboard = false
}) {
  if (isLoading) {
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="Surveys" image="/static/images/dashboards/surveys.svg" />
        <Loading />
      </div>
    );
  }

  if (hasFailedLoading) {
    return (
      <div className="dashboard-widget-inner-container">
        <WidgetTitle title="Surveys" image="/static/images/dashboards/surveys.svg" />
        <p>Sorry, something went wrong while fetching surveys.</p>
      </div>
    );
  }

  return (
    <div className="dashboard-widget-inner-container">
      <WidgetTitle title="Surveys" image="/static/images/dashboards/surveys.svg" />
      {surveys.count() === 0 ? (
        <div>
          <p className="text-gray font-size-16">No active surveys</p>
          <hr />
          <p className="line-height-25px">
            {emptyText}
          </p>
        </div>
      ) : surveys.map((survey, index) => (
        <SurveyItem
          survey={survey}
          key={index}
          isManagerDashboardItem={isManagerDashboard}
        />
      ))}
      <div className="margin-top-auto text-align-center">
        {surveyButton}
      </div>
    </div>
  );
};

export default SurveysList;
