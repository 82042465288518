import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import ChadminList from '^/components/common/ChadminList';
import PureComponent from '^/components/PureComponent';
import { getStatusUserListConfig } from '^/components/survey/config';
import { SURVEY_STATES, STATUS_TEAM_MEMBERS } from '^/components/survey/constants';
import DeleteTeamMemberModal from '^/components/survey/modals/DeleteTeamMemberModal';
import ResyncSurveyTeamMemberModal from '^/components/survey/modals/ResyncSurveyTeamMemberModal';
import roles from '^/roles';
import { capitalizeFirstLetter } from '^/utils';


export class StatusUserList extends PureComponent {

  constructor() {
    super();
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.openRefreshModal = this.openRefreshModal.bind(this);
  }

  openRefreshModal() {
    const refreshModal = {
      body: (<ResyncSurveyTeamMemberModal surveyId={this.props.survey.get('id')} />),
      title: 'Re-Sync People Data'
    };
    return this.props.openSimpleModal(refreshModal);
  }

  openDeleteModal(surveyTeamMember, reloadPage) {

    if (this.props.survey.get('state') === SURVEY_STATES.CLOSED) {
      return null;
    }

    const DELETE_MODAL = {
      body: (
        <DeleteTeamMemberModal
          surveyTeamMember={surveyTeamMember}
          surveyId={this.props.survey.get('id')}
          reloadPage={reloadPage}
        />
      ),
      title: 'Delete Person'
    };

    this.props.openSimpleModal(DELETE_MODAL);
  }

  render() {
    const { survey, canEditSurvey, canExportResponses, organisationFilterTerms, isExecutive } = this.props;

    return (
      <ChadminList
        collectionPath={`survey/${this.props.survey.get('id')}/survey-team-members`}
        collectionName={STATUS_TEAM_MEMBERS}
        ExtraControlsLeft={() => (
          <div>
            {canEditSurvey && (
              <a
                target="_blank"
                href={`/surveys/${this.props.survey.get('id')}/status/export/`}
                className="btn orange middle"
              >
                Export status
              </a>
            )}
            {canExportResponses && survey.get('has_exportable_responses') && (
              <a
              target="_blank"
              href={`/export/${survey.get('id')}/pulse-data/`}
                className="btn orange middle"
              >
                Export responses
              </a>
            )}
            {canEditSurvey && !isExecutive && (
              <button onClick={this.openRefreshModal} className="btn orange middle">Re-sync</button>
            )}
          </div>
        )}
        getColumns={reloadPage => getStatusUserListConfig(
          organisationFilterTerms,
          survey.get('state') === SURVEY_STATES.CLOSED,
          canEditSurvey,
          survey.get('keep_responses_anonymous'),
          capitalizeFirstLetter(this.props.customTerms.get('team', 'Team')),
          this.openDeleteModal,
          reloadPage,
          survey.get('show_partipation_link_in_listings')
        )}
        defaultFilters={{ordering: 'team_member__user__first_name'}}
        hasInlineLabels
      />
    );
  }
}

export function mapStateToProps({ loggedInUser }) {
  return {
    isExecutive: loggedInUser.get('type') === roles.EXECUTIVE
  };
}

export default connect(
  mapStateToProps,
  {
    openSimpleModal
  }
)(StatusUserList);
