import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { getOrgPermissions } from '^/actions/actions';
import { getManagerEnpsData, MANAGER_ENPS_DATA } from '^/components/dashboards/actions';
import DashboardWrapper from '^/components/dashboards/DashboardWrapper';
import EnpsWidget from '^/components/dashboards/EnpsWidget';
import OkrWidget from '^/components/dashboards/OkrWidget';
import SurveysWidgetTeam from '^/components/dashboards/SurveysWidgetTeam';
import ManagerOneToOnes from '^/components/dashboards/ManagerOneToOnes';
import { getCountOfTopRowWidgets, TopRowWidgetContainer } from '^/components/dashboards/MyDashboard';
import PerformanceReviewSurveysWidget from '^/components/dashboards/PerformanceReviewSurveysWidget';
import TalentSnapshotWidget from '^/components/dashboards/TalentSnapshotWidget';
import TeamGoals from '^/components/dashboards/TeamGoals';
import TrendAnalysisWidget from '^/components/dashboards/TrendAnalysisWidget';
import { userMeetsRequiredRole } from '^/utils/permissions';
import USER_ROLES from '^/roles';
import ManagerFeedbackWidget from './ManagerFeedbackWidget';

export class ManagerDashboard extends React.PureComponent {

  componentDidMount() {
    this.props.getOrgPermissions();
  }

  render() {
    const { orgPermissions, organisationFilterTerms } = this.props;
    const topRowWidgetsCount = getCountOfTopRowWidgets(orgPermissions);
    const hasOkrs = orgPermissions.get('okrs');
    const hasAgendas = orgPermissions.get('agendas');
    const hasPeerFeedback = orgPermissions.get('peer_feedback');

    return (
      <DashboardWrapper activeTab={1}>
        <h1 className={
          classNames(
            'font-size-18',
            'font-family-varela-round',
            'margin-top-base',
            'margin-bottom-none'
          )
        }>You are viewing information related to your team.</h1>
        <div className="display-flex-md flex-wrap flex-gap-xx-large">
          <TopRowWidgetContainer widgetsCount={topRowWidgetsCount}>
            <SurveysWidgetTeam />
          </TopRowWidgetContainer>
          {orgPermissions.get('performance_reviews') &&
            <TopRowWidgetContainer widgetsCount={topRowWidgetsCount}>
              <PerformanceReviewSurveysWidget isManagerDashboard />
            </TopRowWidgetContainer>
          }
          {hasAgendas &&
            <TopRowWidgetContainer widgetsCount={topRowWidgetsCount}>
              <ManagerOneToOnes />
            </TopRowWidgetContainer>
          }
        </div>
        <div className="display-flex-md flex-wrap flex-gap-xx-large">
          {hasPeerFeedback && (
            <div className={classNames(
              'dashboard-widget-container',
              {
                'flex-grow': !hasOkrs,
                'max-width-850': hasOkrs
              }
            )}>
              <ManagerFeedbackWidget large={!hasOkrs} />
            </div>
          )}
          {hasOkrs &&
            <div className={classNames(
              'dashboard-widget-container flex-grow',
              {
                'max-width-380': hasPeerFeedback,
                'max-width-510': !hasPeerFeedback
              }
            )}>
              <OkrWidget isManagerDashboard narrow={hasPeerFeedback} />
            </div>
          }
          <div className="dashboard-widget-container flex-grow">
            <TeamGoals />
          </div>
        </div>
        <div className="display-flex-md flex-wrap flex-gap-xx-large">
          <div className="dashboard-widget-container enps-widget-container">
            <EnpsWidget getEnpsData={getManagerEnpsData} enpsKey={MANAGER_ENPS_DATA} hasChart />
          </div>

          {hasAgendas && (
            <div className="dashboard-widget-container flex-grow">
              <TalentSnapshotWidget isManagerDashboard />
            </div>
          )}
        </div>
        <div className="dashboard-widget-container flex-grow">
          <TrendAnalysisWidget
            organisationFilterTerms={organisationFilterTerms}
            listFilter={
              this.props.isManagerAdminUser ?
              {published_version_manager: this.props.userId} :
              {is_manager_dashboard: true}
            }
            emptyText="Your team has no published reports yet."
          />
        </div>
      </DashboardWrapper>
    );
  };
};

export function mapStateToProps(state) {
  return {
    orgPermissions: state.orgPermissions,
    userId: state.loggedInUser.get('id'),
    isManagerAdminUser: userMeetsRequiredRole(state.loggedInUser, USER_ROLES.MANAGER_USER_WITH_ADMIN)
  };
}

export default connect(mapStateToProps, { getOrgPermissions })(ManagerDashboard);
