import { SHOW_COMPARE_TO_FORM, GET_REPORT_SUGGESTIONS } from './actions';


export function showCompareToForm(state = false, action) {
  switch (action.type) {
    case SHOW_COMPARE_TO_FORM:
      return true;
    default:
      return state;
  }
}

export function reportSuggestions(state = null, action) {
  switch (action.type) {
    case GET_REPORT_SUGGESTIONS.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
