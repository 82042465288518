import Highcharts, { HIGHCHARTS_CONFIG_DEFAULTS } from './highcharts';
import _ from 'underscore';

import { getChartExportButtons } from '^/components/custom-reports/utils';

export function renderScoresByAreaChart(elementId, data) {
  var columnData = data.average_scores;
  var colorMap = data.area_color_map;
  var descriptionMap = data.area_description_map;

  Highcharts.chart(elementId, Object.assign(HIGHCHARTS_CONFIG_DEFAULTS, {
    chart: {
      type: 'column',
      height: 500,
      marginTop: 70
    },
    xAxis: {
      categories: Object.keys(columnData),
      crosshair: true
    },
    title: {
      text: 'Scores by area'
    },
    credits: {
      enabled: false
    },
    yAxis: {
      max: 100,
      min: 0,
      tickInterval: 25,
      title: {
        text: 'Score'
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return `<strong>${this.x}</strong> ${this.y} <br /> ${descriptionMap[this.x]}`;
      }
    },
    series: [
      {
        name: 'Score',
        data: _.pairs(columnData).map((column) => {
          var columnLabel = column[0];
          var columnValue = column[1];

          return {
            y: columnValue,
            color: colorMap[columnLabel],
          };
        })
      }
    ],
    exporting: {
      buttons: getChartExportButtons(null, elementId),
    }
  }));
}
