import React from 'react'; // eslint-disable-line no-unused-vars
import Highcharts, { HIGHCHARTS_CONFIG_DEFAULTS } from '^/charts/highcharts';

const DEFAULT_ENPS_CHART_CONFIG = {
  color: undefined,
  width: 300,
  height: 250
};

function renderEnpsChart(
  elementId,
  data = [],
  config = {}
) {

  const { color, width, height } = Object.assign(
    {},
    DEFAULT_ENPS_CHART_CONFIG,
    config
  );

  Highcharts.chart(
    elementId,
    Object.assign(
      {},
      HIGHCHARTS_CONFIG_DEFAULTS,
      {
        title: null,
        chart: {
          type: 'spline',
          width,
          height
        },
        xAxis: {
          categories: data.map(item => item.date),
        },
        yAxis: {
          min: -100,
          max: 100,
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              if (this.value > 0) {
                return `+${this.value}`;
              }

              return this.value;
            }
          }
        },
        series: [
          {
            name: 'Score',
            data,
            color
          }
        ],
        tooltip: {
          useHTML: true,
          formatter: function() {
            return `
              <div class="margin-bottom-base text-gray font-size-12">${this.point.date}</div>
              <div class="flex-space-between margin-bottom-small">
                <div class="enps-chart-tooltip-bullet">
                  <div
                    class="enps-chart-bullet-circle"
                    style="background-color: #000;"
                  >
                  </div>
                  <span class="font-size-12">Score:</span>
                </div>
                <span><b>${this.y}</b></span>
              </div>
              <div class="flex-space-between margin-bottom-small">
                <div class="enps-chart-tooltip-bullet">
                  <div
                    class="enps-chart-bullet-circle"
                    style="background-color: #00AA00;"
                  >
                  </div>
                  <span>Promoters:</span>
                </div>
                <span><b>${this.point.promoters.count} (${this.point.promoters.percentage}%)</b></span>
              </div>
              <div class="flex-space-between margin-bottom-small">
                <div class="enps-chart-tooltip-bullet">
                  <div
                    class="enps-chart-bullet-circle"
                    style="background-color: #FFAA00;"
                  >
                  </div>
                  <span>Passives:</span>
                </div>
                <span><b>${this.point.passives.count} (${this.point.passives.percentage}%)</b></span>
              </div>
              <div class="flex-space-between margin-bottom-base">
                <div class="enps-chart-tooltip-bullet">
                  <div
                    class="enps-chart-bullet-circle"
                    style="background-color: #DD0000;"
                  >
                  </div>
                  <span>Detractors:</span>
                </div>
                <span>
                  <b>${this.point.detractors.count} (${this.point.detractors.percentage}%)</b>
                </span>
              </div>
              <span class="text-gray font-size-12">${this.point.responses} responses</span>
            `;
          }
        },
        plotOptions: {
          series: {
            connectNulls: true,
          }
        },
        legend: {
          enabled: false
        },
        credits: false,
        exporting: {
          chartOptions: {
            title: {
              text: 'eNPS over the last 6 months'
            },
            chart: {
              width: 1000,
              height: 500
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true
                }
              }
            },
          },
          buttons: {
            contextButton: {
              x: 10,
              y: -10,
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
                'separator',
                'downloadCSV',
                'downloadXLS'
              ]
            },
          }
        },
      }
    )
  );
}

export default class EnpsChartScoreWidget extends React.PureComponent {
  componentDidMount() {
    const {
      id,
      data,
      config
    } = this.props;

    renderEnpsChart(
      id,
      data,
      config
    );
  }

  render() {
    return <div id={this.props.id}></div>;
  }
}
