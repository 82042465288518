import { isPending } from '@dabapps/redux-requests';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import Loading from '^/components/Loading';
import EnpsChartScoreWidget from '^/components/dashboards/EnpsChartScoreWidget';
import EnpsScoreModalButton from '^/components/dashboards/EnpsScoreModalButton';
import WidgetTitle from '^/components/dashboards/WidgetTitle';

const InfoIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faInfoCircle}
      className="font-size-18 margin-left-small font-color-pink"
    />
  );
};

function addEnpsScorePrefix(score) {
  if (score > 0) {
    return `+${score}`;
  }
  return score;
}

function renderEnpsScore(score, hasChart) {
  if (!score && score !== 0) {
    return 'N/A';
  }

  return hasChart ? addEnpsScorePrefix(score) : `${score}/10`;
}

function renderNumberOfRespondents(enpsData) {
  const lastItem = enpsData[enpsData.length - 1];

  if (lastItem && lastItem.number_of_respondents) {
    return `(${lastItem.number_of_respondents} responses)`;
  }

  return '(0 responses)';
}

export class EnpsWidget extends React.PureComponent {
  componentDidMount() {
    this.props.getEnpsData();
  }

  render() {
    const { enpsData, isLoading, hasChart, enpsKey } = this.props;

    const has2MonthsOfData =
      hasChart && !!(enpsData || []).length && enpsData.filter(item => item.average_score).length >= 2;

    let enpsScore = null;
    if (enpsData) {
      if (hasChart && !!enpsData.length) {
        enpsScore = enpsData[enpsData.length - 1].average_score;
      } else {
        enpsScore = enpsData.enps_score;
      }
    }

    return (
      <div className="dashboard-widget-inner-container position-relative">
        <WidgetTitle
          title="eNPS"
          image="/static/images/dashboards/enps.svg"
        />
        <div className="flex-center">
          <div className="max-width-375">
            <div className="enps-info-icon-container">
              {!hasChart ? (
                <div className="enps-info-icon">
                  <InfoIcon />
                </div>
              ) : (
                <a
                  href="https://intercom.help/wethrive/en/articles/8260467-enps-employee-net-promoter-score"
                  target="_blank"
                >
                  <InfoIcon />
                </a>
              )}
            </div>

            <p className={classNames({'font-size-18 margin-bottom-large': !hasChart})}>
              {!hasChart ? 'Would you recommend working here?' :
                'Employee Net Promoter Score provides a quick measure of employee satisfaction.' +
                ' Scores range between -100 & +100.'
              }
            </p>

            {isLoading && <Loading />}

            {!isLoading && enpsData && (
              <div className="flex-center flex-column margin-bottom-x-large">
                <div className={classNames(
                  'circle',
                  {
                    'circle-ring-red':
                      (!hasChart && enpsScore <= 6) ||
                      (hasChart && enpsScore <= -21),
                    'circle-ring-orange':
                      !enpsScore ||
                      (!hasChart && enpsScore >= 7 && enpsScore <= 8) ||
                      (hasChart && enpsScore >= -20 && enpsScore <= 9),
                    'circle-ring-green':
                      (!hasChart && enpsScore >= 9) ||
                      (hasChart && enpsScore >= 10),
                  }
                )}>
                  <div className="font-size-32">
                    { renderEnpsScore(enpsScore, hasChart) }
                  </div>
                </div>

                {hasChart && enpsData &&
                  <div className="font-size-18 margin-vertical-base">
                    { renderNumberOfRespondents(enpsData) }
                  </div>
                }
              </div>
            )}

            {!isLoading && !hasChart && (
              <div className="full-width text-center margin-top-auto">
                <EnpsScoreModalButton buttonText={enpsScore ? 'Update score' : 'Add score'} />
              </div>
            )}

            {!isLoading && has2MonthsOfData &&
              <EnpsChartScoreWidget
                id={enpsKey}
                data={enpsData.map(item => {
                  return {
                    y: item.average_score,
                    promoters: {
                      count: item.number_of_promoters,
                      percentage: item.number_of_respondents ?
                        (item.number_of_promoters / item.number_of_respondents * 100).toFixed(0)
                        : 0
                    },
                    passives: {
                      count: item.number_of_passives,
                      percentage: item.number_of_respondents ?
                        (item.number_of_passives / item.number_of_respondents * 100).toFixed(0)
                        : 0
                    },
                    detractors: {
                      count: item.number_of_detractors,
                      percentage: item.number_of_respondents ?
                        (item.number_of_detractors / item.number_of_respondents * 100).toFixed(0)
                        : 0
                    },
                    responses: item.number_of_respondents,
                    date: item.date
                  };
                })}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    enpsData: state.homepageEnpsData[props.enpsKey],
    isLoading: isPending(state.responses, props.enpsKey)
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    getEnpsData: () => dispatch(props.getEnpsData())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnpsWidget);
