import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import classNames from 'classnames';

export default class Loading extends PureComponent {

  render() {
    var divClass = classNames({
      'indicator': true,
      'loading': true,
      [this.props.className]: this.props.className
    });
    return (
      <div className={divClass}>
        <svg width="36" height="36" viewBox="0 0 36 36">
          <circle cx="18" cy="18" r="17" strokeWidth="2" fill="none" />
          <circle cx="18" cy="18" r="11" strokeWidth="2" fill="none" />
        </svg>
      </div>
    );
  }
};
