import * as React from 'react';  // eslint-disable-line no-unused-vars
import moment from 'moment';
import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';
import { joinTruthyStringsWith } from '^/utils';
import AgendaListItemCheckbox from './AgendaListItemCheckbox';
import AgendaListAllCheckbox from './AgendaListAllCheckbox';
import AgendaListSendButton from './AgendaListSendButton';
import BulkAgendaSelectAllUsersCheckbox from './BulkAgendaSelectAllUsersCheckbox';
import BulkAgendaSelectUserCheckbox from './BulkAgendaSelectUserCheckbox';

export const agendaListConfig = (
  organisationTeamFilterTerm,
  isManager,
  disableSendButton
) => [
  ...(isManager ? [
    {
      name: 'select_agenda',
      display_name: (
        <span>
          <AgendaListAllCheckbox />
          <span className="margin-left-base">
            Send agenda(s)
          </span>
        </span>
      ),
      type: 'custom',
      customItemHandler: agenda => (
        <AgendaListItemCheckbox
          item={agenda}
        />
      ),
      sortable: false,
    }
  ] : []),
  ...(isManager ? [
    {
      name: 'employee__first_name',
      display_name: 'Name/Email',
      type: 'custom',
      customItemHandler: agenda => (
        <span>
          <span
            title={agenda.employee_name}
            className="display-block text-overflow-ellipsis"
          >
            {agenda.employee_name}
          </span>
          <span
            title={agenda.employee}
            className="display-block text-overflow-ellipsis"
          >
            {agenda.employee}
          </span>
        </span>
      ),
      sortable: true,
    }
  ] : []),
  {
    name: 'title',
    display_name: 'Title',
    type: 'custom',
    customItemHandler: agenda => (
      <span>
        <span title={agenda.title} className="display-block text-overflow-ellipsis">{agenda.title}</span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'due_date',
    display_name: 'Due date',
    type: 'custom',
    customItemHandler: agenda => (
      <CellContentOrEmpty condition={agenda.due_date}>
        {moment(agenda.due_date).format('Do MMM YYYY')}
      </CellContentOrEmpty>
    ),
    sortable: true,
  },
  {
    name: 'manager__first_name',
    display_name: 'Completed by',
    type: 'custom',
    customItemHandler: agenda => (
      <span title={agenda.scheduled_by} className="text-overflow-ellipsis">{agenda.scheduled_by || '-'}</span>
    ),
    sortable: true,
  },
  ...(isManager ? [
    {
      name: 'employee__team_member__team__name',
      display_name: organisationTeamFilterTerm,
      type: 'custom',
      customItemHandler: agenda => (
        <CellContentOrEmpty condition={agenda.team} title={agenda.team}>
          {agenda.team}
        </CellContentOrEmpty>
      ),
      sortable: true,
    }
  ] : []),
  {
    name: 'scheduled_datetime',
    display_name: 'Scheduled',
    type: 'custom',
    customItemHandler: agenda => (
      isManager && !agenda.read_only && !agenda.scheduled_datetime
    ) ? (
        <a
          href={`/agendas/${agenda.id}/edit/?edit_agenda_details=true`}
          className="pink-border-button text-pink text-decoration-none font-size-13 padding-vertical-small"
        >
          Schedule
        </a>
    ) : (
      <CellContentOrEmpty condition={agenda.scheduled_datetime}>
        <span>
          <span className="display-block">{moment(agenda.scheduled_datetime).format('Do MMM YYYY')}</span>
          <span className="display-block">{moment(agenda.scheduled_datetime).format('h:mm a')}</span>
        </span>
      </CellContentOrEmpty>
    ),
    sortable: true,
  },
  {
    name: 'status',
    display_name: (
      <span className="display-block text-align-center">Edit/View</span>
    ),
    type: 'custom',
    customItemHandler: agenda => (
      <a
        href={`/agendas/${agenda.id}/${agenda.read_only ? 'read-only' : 'edit'}/`}
        className="display-block text-align-center"
      >
        {agenda.read_only ?
          <i className="far fa-list text-pink font-size-24"></i>
          : <i className="fas fa-edit text-pink font-size-24"></i>
        }
      </a>
    ),
    sortable: false,
  },
  {
    name: 'send',
    display_name: (
      <span className="display-block text-align-center">Send agenda</span>
    ),
    type: 'custom',
    customItemHandler: agenda => (
      <span className="display-block text-align-center padding-right-base">
        <AgendaListSendButton
          agendaId={agenda.id}
          isDisabled={disableSendButton}
          canSendPdfSummary={agenda.can_send_pdf_summary}
          isReadOnly={agenda.read_only}
        />
      </span>
    ),
    sortable: false,
  }
];

export const talentSnapshotListConfig = (organisationFilterTerms) => [
  {
    name: 'name',
    display_name: 'Name/Email',
    type: 'custom',
    customItemHandler: (agenda) => (
      <span>
        <span title={agenda.name} className="display-block text-overflow-ellipsis">
          {agenda.name}
        </span>
        <span title={agenda.email} className="display-block text-help text-overflow-ellipsis">
          {agenda.email}
        </span>
      </span>
    ),
    sortable: false,
  },
  {
    name: 'team',
    display_name: `${organisationFilterTerms.team}/Reports To`,
    type: 'custom',
    customItemHandler: (agenda) => (
      <span>
        <CellContentOrEmpty condition={Boolean(agenda.team)}>{agenda.team}</CellContentOrEmpty>
        <span className="display-block text-help text-overflow-ellipsis">
          <span>{agenda.manager}</span>
          {agenda.remaining_managers > 0 && (
            <span> + {agenda.remaining_managers} more</span>
          )}
        </span>
      </span>
    ),
    sortable: false,
  },
  {
    name: 'filters',
    display_name:
      `${organisationFilterTerms.department}/${organisationFilterTerms.location}/${organisationFilterTerms.unit}`,
    type: 'custom',
    customItemHandler: (agenda) => (
      <span>
        <CellContentOrEmpty condition={Boolean(agenda.department_filter)}>
          {agenda.department_filter}
        </CellContentOrEmpty>
        <span className="display-block text-help">
          {joinTruthyStringsWith(
            [agenda.location_filter, agenda.unit_filter],
            ' • '
          )}
        </span>
      </span>
    ),
    sortable: false,
  },
  {
    name: 'manager',
    display_name: 'Manager',
    type: 'custom',
    customItemHandler: (agenda) => agenda.agenda_manager || '-',
    sortable: true,
  },
  {
    name: 'read_only_date_time',
    display_name: 'Feedback provided',
    type: 'custom',
    customItemHandler: agenda => (
      <a
        href={`/agendas/${agenda.id}/read-only/`}
      >
        {moment(agenda.read_only_date_time).format('DD/MM/YYYY')}
      </a>
    ),
    sortable: true,
  },
];

export const agendaParticipantsListConfig = (agendaTemplateId, organisationFilterTerms) => [
  {
    name: 'select',
    display_name: (
      <BulkAgendaSelectAllUsersCheckbox agendaTemplateId={agendaTemplateId} />
    ),
    type: 'custom',
    customItemHandler: user => (
      <BulkAgendaSelectUserCheckbox agendaTemplateId={agendaTemplateId} userId={user.id} />
    ),
    sortable: false,
  },
  {
    name: 'first_name',
    display_name: 'Name/Email',
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <span title={user.name} className="display-block text-overflow-ellipsis">
          {user.name}
        </span>
        <span title={user.email} className="display-block text-help text-overflow-ellipsis">
          {user.email}
        </span>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team_member__team',
    display_name: `${organisationFilterTerms.team}`,
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.team)}>{user.team}</CellContentOrEmpty>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team_member__location_filter',
    display_name:
      `${organisationFilterTerms.location}`,
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.location_filter)}>{user.location_filter}</CellContentOrEmpty>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team_member__department_filter',
    display_name:
      `${organisationFilterTerms.department}`,
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.department_filter)}>{user.department_filter}</CellContentOrEmpty>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team_member__unit_filter',
    display_name:
      `${organisationFilterTerms.unit}`,
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.unit_filter)}>{user.unit_filter}</CellContentOrEmpty>
      </span>
    ),
    sortable: true,
  },
  {
    name: 'team_member__manager_filter',
    display_name: 'Manager',
    type: 'custom',
    customItemHandler: (user) => (
      <span>
        <CellContentOrEmpty condition={Boolean(user.manager_filter)}>{user.manager_filter}</CellContentOrEmpty>
      </span>
    ),
    sortable: true,
  },
];
