import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { clearAllSelectedListItems } from '^/actions/actions';
import {
  sendPeerFeedbackRemindersAndToast,
  SEND_PEER_FEEDBACK_REMINDERS
} from '^/components/peer-feedback/actions';
import SelectedListItemsBanner from '../common/SelectedListItemsBanner';

export function PeerFeedbackRemindersBanner(props) {
  return (
    <SelectedListItemsBanner
      selectedItemsCount={props.selectedPeerFeedbackCount}
      isDisabled={props.isDisabled}
      buttonLabel="Send reminder(s)"
      bannerText="respondent(s) selected"
      onClickSend={props.sendReminders}
      onClickCancel={props.clearSelectedFeedback}
    />
  );
}

function mapStateToProps({selectedListItems, responses}) {
  const selectedPeerFeedbackCount = selectedListItems.length;
  return {
    selectedPeerFeedbackCount,
    selectedPeerFeedback: selectedListItems,
    isDisabled: isPending(responses, SEND_PEER_FEEDBACK_REMINDERS) || !selectedPeerFeedbackCount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSelectedFeedback: () => dispatch(clearAllSelectedListItems()),
    sendRemindersFunction: (selectedFeedback) =>
      () => dispatch(sendPeerFeedbackRemindersAndToast({feedback_ids: selectedFeedback}))
  };
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    sendReminders: dispatchProps.sendRemindersFunction(stateProps.selectedPeerFeedback),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PeerFeedbackRemindersBanner);
