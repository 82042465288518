import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';
import {
  SET_INCLUDES_RECENT_RESULTS_OFF,
  SET_INCLUDES_RECENT_RESULTS_ON,
  setIncludesRecentResultsOff,
  setIncludesRecentResultsOn
} from './actions';
import { Map } from 'immutable';
import { isPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import { getSurvey } from '../../actions/actions';


export class ToggleIncludeRecentResults extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleIncludeRecentResults = this.toggleIncludeRecentResults.bind(this);
  }

  getSetAction() {
    if (this.props.survey.get('includes_users_completed_within_last_3_months')) {
      return this.props.setIncludesRecentResultsOff(this.props.survey.get('id'));
    } else {
      return this.props.setIncludesRecentResultsOn(this.props.survey.get('id'));
    }
  }

  toggleIncludeRecentResults() {
    const setAction = this.getSetAction();
    setAction.then(() => {
      window.location.reload();
    });
  }

  render() {
    return (
      <label className={classnames('margin-bottom-large', {
        'text-gray': this.props.isLoading
      })}>
        <input
          disabled={this.props.isLoading}
          type="checkbox"
          className="margin-right-base"
          checked={this.props.survey.get('includes_users_completed_within_last_3_months')}
          onChange={this.toggleIncludeRecentResults}
        />
        Include recently completed survey responses
      </label>
    );
  }
}

function mapStateToProps(state) {
  return {
    survey: state.survey.get('survey', Map()),
    isLoading: (
      isPending(state.responses, SET_INCLUDES_RECENT_RESULTS_ON)
      | isPending(state.responses, SET_INCLUDES_RECENT_RESULTS_OFF)
    )
  };
}

export default connect(mapStateToProps, {
  setIncludesRecentResultsOff,
  setIncludesRecentResultsOn,
  getSurvey
})(ToggleIncludeRecentResults);
