import { ModalRenderer } from '@dabapps/roe';
import * as React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import ChadminList from '^/components/common/ChadminList';
import { agendaListConfig } from './config';
import { AGENDA_LIST_EMPLOYEE, AGENDA_LIST_URL } from './constants';
import PendingAgendaCard from './PendingAgendaCard';
import { AgendaSelectCard } from './AgendaListManager';


export function AgendaListEmployee(props) {
  return (
    <div>
      <div className="display-flex flex-gap-xx-large">
        {props.userHasManagers && (
          <div className="background-white padding-xx-large border-radius-16 padding-bottom-x-large">
            <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-xx-large">
              Request 1:1
            </h4>
            <AgendaSelectCard
              title="Request a 1:1 meeting"
              description="Send a meeting request to your manager"
              image="/static/images/agendas/request-individual.svg"
              url="/agendas/request-one-to-one"
            />
          </div>
        )}
        <div className="background-white padding-xx-large border-radius-16 padding-bottom-xxx-large flex-grow">
          <h4 className="font-family-effra-bold font-color-main font-size-28">
            Pending 1:1 requests:
          </h4>
          <div className="display-flex flex-column flex-gap-large">
            {props.actionableAgendas.length ? props.actionableAgendas.map(agenda => (
              <PendingAgendaCard agenda={agenda} key={agenda.id} collectionName={AGENDA_LIST_EMPLOYEE} />
            )) : (
              <p>No notifications.</p>
            )}
          </div>
        </div>
      </div>
      <div className="background-white padding-xx-large border-radius-16 employee-agenda-list margin-vertical-xx-large">
        <ChadminList
          collectionPath={AGENDA_LIST_URL}
          collectionName={AGENDA_LIST_EMPLOYEE}
          columns={agendaListConfig(props.organisationTeamFilterTerm)}
          ExtraControlsLeft={() => (
            <h4 className="font-family-effra-bold font-size-28 font-color-main margin-bottom-small">
              All 1:1s
            </h4>
          )}
          searchable
          pullSearchRight
        />
      </div>
      <ToastContainer />
      <ModalRenderer modals={props.modals}/>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    actionableAgendas: (state.actionableAgendas || []).slice(0, 3),
    modals: state.modals
  };
}

export default connect(mapStateToProps)(AgendaListEmployee);
