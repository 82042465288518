import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';

import { shouldRethrow, clearAllSelectedListItems } from '^/actions/actions';
import { closeModal } from '^/actions/modals';
import { updateItem } from '^/actions/items';
import { PEER_FEEDBACK_INDIVIDUAL_LIST_URL } from '^/components/peer-feedback/constants';

export const CREATE_PEER_FEEDBACK = makeAsyncActionSet('CREATE_PEER_FEEDBACK');
export function createPeerFeedback(data) {
  return (dispatch) => {
    return request(
      CREATE_PEER_FEEDBACK,
      '/api/people/create-peer-feedback/',
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export function createPeerFeedbackAndCloseModal(data, reloadPage) {
  return (dispatch) => {
    return createPeerFeedback(data)(dispatch).then(() => {
      if (reloadPage) {
        reloadPage();
      }
      dispatch(closeModal());
      toast.dismiss();
      toast.success('Feedback added successfully.');
    }).catch(() => {
      toast.dismiss();
      toast.error('Could not add feedback.');
    });
  };
}

export const UPDATE_PEER_FEEDBACK_READ_BY_USER = makeAsyncActionSet('UPDATE_PEER_FEEDBACK_READ_BY_USER');
export function updatePeerFeedbackReadByUser(peerFeedbackId) {
  return (dispatch) => {
    dispatch(
      updateItem('people/peer-feedback-update-read-by-user', peerFeedbackId, {}, PEER_FEEDBACK_INDIVIDUAL_LIST_URL)
    );
  };
}

export const REQUEST_PEER_FEEDBACK = makeAsyncActionSet('REQUEST_PEER_FEEDBACK');
export function requestPeerFeedback(data) {
  return (dispatch) => {
    return request(
      REQUEST_PEER_FEEDBACK,
      '/api/people/request-peer-feedback/',
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export function requestPeerFeedbackAndCloseModal(data, reloadPage) {
  return (dispatch) => {
    return requestPeerFeedback(data)(dispatch).then(() => {
      if (reloadPage) {
        reloadPage();
      }
      dispatch(closeModal());
      toast.dismiss();
      toast.success('Feedback requested successfully.');
    }).catch(() => {
      toast.dismiss();
      toast.error('Could not request feedback.');
    });
  };
}

export const SEND_PEER_FEEDBACK_REMINDERS = makeAsyncActionSet('SEND_PEER_FEEDBACK_REMINDERS');
export function sendPeerFeedbackReminders(data) {
  return (dispatch) => {
    return request(
      SEND_PEER_FEEDBACK_REMINDERS,
      '/api/people/send-peer-feedback-reminders/',
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export function sendPeerFeedbackRemindersAndToast(data) {
  const numberOfUsers = data.feedback_ids.length;
  return (dispatch) => {
    return sendPeerFeedbackReminders(data)(dispatch).then(() => {
      toast.dismiss();
      toast.success(`Successfully sent reminder(s) to ${numberOfUsers} respondent(s).`);
      dispatch(clearAllSelectedListItems());
    }).catch(() => {
      toast.dismiss();
      toast.error('Could not send reminders.');
    });
  };
}

export const GET_PEER_FEEDBACK_REQUEST = makeAsyncActionSet('GET_PEER_FEEDBACK_REQUEST');
export function getPeerFeedbackRequest(feedbackRequestId) {
  return (dispatch) => {
    return request(
      GET_PEER_FEEDBACK_REQUEST,
      `/api/people/peer-feedback-request/${feedbackRequestId}/`,
      'GET',
      null,
      { shouldRethrow }
    )(dispatch);
  };
}

export const CREATE_REQUESTED_PEER_FEEDBACK = makeAsyncActionSet('CREATE_REQUESTED_PEER_FEEDBACK');
export function createRequestedPeerFeedback(feedbackRequestId, data) {
  return (dispatch) => {
    return request(
      CREATE_REQUESTED_PEER_FEEDBACK,
      `/api/people/peer-feedback-request/${feedbackRequestId}/create-feedback/`,
      'POST',
      data,
      { shouldRethrow }
    )(dispatch);
  };
}

export function createRequestedPeerFeedbackAndCloseModal(feedbackRequestId, data) {
  return (dispatch) => {
    return createRequestedPeerFeedback(feedbackRequestId, data)(dispatch).then(() => {
      dispatch(closeModal());
      toast.dismiss();
      toast.success('Feedback given successfully.');
    }).catch(() => {
      toast.dismiss();
      toast.error('Could not process feedback.');
    });
  };
}
