import React from 'react'; // eslint-disable-line no-unused-vars
import AgendaItemCard from './AgendaItemCard';
import { connect } from 'react-redux';
import { deleteAgendaOkrAndToast } from './actions';
import CustomItemsSection from './CustomItemsSection';
import { isReadOnlyAgendaItem } from './utils';

const DeleteOkrFromAgendaCard = ({
  item,
  deleteOkr,
  isDisabled
}) => {
  return (
    <AgendaItemCard
      key={item.okr_id}
      title={
        <a href={`/okrs/${item.okr_id}/`} target="_blank">{item.okr_name}</a>
      }
      onClick={deleteOkr}
      isDisabled={isDisabled}
      isRemoving
      isOkr
      okrData={item}
    />
  );
};

function mapStateToPropsCard(state, props) {
  return {
    isDisabled: props.isDisabled || isReadOnlyAgendaItem(state, props.item.created_by)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteOkr: () => dispatch(
      deleteAgendaOkrAndToast(ownProps.agendaId, ownProps.item.id)
    ),
  };
};

const ConnectedDeleteOkrFromAgendaCard = connect(
  mapStateToPropsCard,
  mapDispatchToProps
)(DeleteOkrFromAgendaCard);

export const OkrSection = ({
  agendaId,
  isDisabled,
  selectedOkrs
}) => {
  return (
    <div>
      {selectedOkrs.length ? selectedOkrs.map(okr => (
        <ConnectedDeleteOkrFromAgendaCard
          key={okr.okr_id}
          item={okr}
          agendaId={agendaId}
          isDisabled={isDisabled}
        />
      )) : (
        <p>No OKRs selected.</p>
      )}
      <div className="margin-top-xx-large">
        <h4 className="font-weight-bold margin-bottom-base font-size-14">
          Comments
        </h4>
        <CustomItemsSection
          agendaId={agendaId}
          isDisabled={isDisabled}
          itemsName="okr_comments"
          isCommentItems
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedQuestions: state.editingAgenda.review_questions
  };
}

export default connect(mapStateToProps)(OkrSection);
