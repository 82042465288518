import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import PureComponent from '^/components/PureComponent';
import { getAction, createActionExport, getActionExport } from '^/actions/actions';
import { Map } from 'immutable';
import Loading from '^/components/Loading';
import ImmutablePropTypes from 'react-immutable-proptypes';


class ActionExport extends PureComponent {
  componentWillMount() {
    this.props.createActionExport(this.props.showAll);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.export.isEmpty() && !this.props.export.isEmpty()) {
      this.intervalId = setInterval(this.getExport.bind(this), 2000);
    } else if (!previousProps.exportUrl && Boolean(this.props.exportUrl)) {
      clearInterval(this.intervalId);
    }
  }

  handleSubmit(data) {
    this.props.updateActionDescription(this.props.actionId, data);
  }

  getExport() {
    this.props.getActionExport(this.props.export.get('id'));
  }

  render() {
    if (this.props.export.isEmpty()) {
      return (
        <div>
          <p>Requesting export...</p>
          <Loading />
        </div>
      );
    } else if (!this.props.exportUrl) {
      return (
        <div>
          <p>Processing export... this might take a few minutes.</p>
          <Loading />
        </div>
      );
    }

    return (
      <div className="action-export">
        <a className="btn middle orange" href={this.props.exportUrl}>Download Export</a>
      </div>
    );
  }
}

ActionExport.propTypes = {
  export: ImmutablePropTypes.map.isRequired,
  exportUrl: React.PropTypes.string
};

export function mapStateToProps(state) {
  return {
    export: state.actionReducer.get('export', Map()),
    exportUrl: state.actionReducer.get('exportUrl', null)
  };
}

export default connect(mapStateToProps, { getAction, createActionExport, getActionExport })(ActionExport);
