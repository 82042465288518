import { anyPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import {
  getGoal,
  GET_GOAL,
  updateGoalAndRedirectToIt,
  UPDATE_GOAL,
} from './actions';
import { updateAgendaGoalManagerAndToast } from '^/components/agendas/actions';
import Loading from '^/components/Loading';
import { FORMAT_DATE_BACKEND } from '^/utils';
import EditGoalForm from './EditGoalForm';
import ActionsPicker from './ActionsPicker';
import { fromJS } from 'immutable';

export class EditGoal extends React.PureComponent {
  componentDidMount() {
    this.props.getGoal();
  }

  render() {
    const { goal, isAgendaGoal } = this.props;

    if (this.props.isLoading || goal.isEmpty()) {
      return <Loading />;
    }
    return (
      <div className={classNames({
        will: !isAgendaGoal,
        'padding-x-large': !isAgendaGoal
      })}>
        <EditGoalForm
          initialValues={{
            name: goal.get('name'),
            description: goal.get('description'),
            due_date: moment(goal.get('due_date')),
            owner: goal.getIn(['owner']),
          }}
          onSubmit={this.props.onSubmit}
          submitButtonLabel="Save goal"
          assignedBy={goal.get('assigned_by')}
          hideTitle={isAgendaGoal}
        />
        <ActionsPicker
          canChangeUser={!this.props.isConfidential}
          goalId={this.props.goal.get('id')}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: anyPending(state.responses, [GET_GOAL, UPDATE_GOAL]),
    goal: state.goalData,
    excludedActions: state.actionPickerExcludedActions,
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    getGoal: () => dispatch(getGoal(ownProps.goalId)),
    getOnSubmitFunction: (excludedActions, goal) => (data) => {
      const dataWithActions = fromJS(data)
        .set('due_date', data.due_date.format(FORMAT_DATE_BACKEND))
        .set(
          'actions',
          goal
            .get('actions')
            .filter((action) => !excludedActions.includes(action.get('id')))
            .map((action) => action.get('id'))
        )
        .toJS();

      if (ownProps.isAgendaGoal) {
        dispatch(updateAgendaGoalManagerAndToast(ownProps.goalId, dataWithActions));
      } else {
        dispatch(updateGoalAndRedirectToIt(ownProps.goalId, dataWithActions));
      }
    },
  };
}

export function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    onSubmit: dispatchProps.getOnSubmitFunction(
      stateProps.excludedActions,
      stateProps.goal
    ),
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditGoal);
