import { ModalBody } from '@dabapps/roe';
import React from 'react';  // eslint-disable-line no-unused-vars

const UninstallSlack = (props) => (
  <ModalBody>
    <p className="margin-bottom-base">
      To uninstall the Slack integration,
      please follow this Slack help article:
      {' '}
      <a
        href={props.slackUninstallInstructionsUrl}
        target="_blank"
      >Slack: Remove apps and customised integrations from your workspace</a>
    </p>
    <p>
      After you uninstall,
      WeThrive will immediately lose access to your Slack workspace
      and you will no longer receive Slack notifications.
    </p>
  </ModalBody>
);

export default UninstallSlack;
