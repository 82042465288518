import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Column, Row } from '@dabapps/roe';
import ReactDatePicker from 'react-datepicker';
import { OKR_STATUS_OPTIONS } from './constants';
import { FORMAT_DATE_PICKER } from '../../utils';

export const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper">
      <ReactDatePicker
        selected={props.input.value || props.meta.initial}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        fixedHeight
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

export class EditOkrForm extends React.PureComponent {

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        {!this.props.hideTitle && (
          <h4 className="margin-bottom-base">OKR details</h4>
        )}
        <Row className="margin-top-large line-height-32px">
          <Column md={2}>
            <label htmlFor="name" className="margin-none">Objective</label>
          </Column>
          <Column md={10}>
            <Field id="name" name="name" component="input" type="text" />
          </Column>
        </Row>
        <Row className="margin-top-large line-height-32px">
          <Column md={2}>
            <label className="margin-none">Linked to</label>
          </Column>
          <Column md={10}>
            <p className="margin-bottom-none margin-top-small color-gray-dark">{this.props.okr.get('parent_okr')}</p>
          </Column>
        </Row>
        <Row className="margin-top-large line-height-32px">
          <Column md={2}>
            <label htmlFor="due_date" className="margin-none">Due</label>
          </Column>
          <Column md={10}>
            <Field
              id="due_date"
              name="due_date"
              component={DatePickerField}
            />
          </Column>
        </Row>
        <Row className="margin-top-large line-height-32px">
          <Column md={2}>
            <label htmlFor="status" className="margin-none">Status</label>
          </Column>
          <Column md={10}>
            <Field
              id="status"
              name="status"
              component="select"
            >
              {OKR_STATUS_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Field>
          </Column>
        </Row>
        <Row>
          <Column md={12}>
            <button
              type="submit"
              className="btn middle orange margin-vertical-large pull-right"
              disabled={this.props.okr.get('actions').toJS().length < 1}
            >
              Save OKR
            </button>
          </Column>
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editOkrForm',
})(EditOkrForm);
