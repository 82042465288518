import React from 'react'; // eslint-disable-line
import { Field, reduxForm } from 'redux-form';
import Loading from '../Loading';


let DescriptionForm = props => {
  const { handleSubmit, isLoading } = props;
  return <form onSubmit={handleSubmit}>
    <div>
      <Field name="description" component="textarea" type="text" />
    </div>
    <br/>
    {isLoading ? <Loading /> : <button type="submit" className="btn middle orange">Save</button>}
  </form>;
};


export default reduxForm({
  form: 'descriptionForm'
})(DescriptionForm);
