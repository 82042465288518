import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';

import { openSimpleModal } from '^/actions/modals';
import SurveySelectModal from '^/components/custom-reports/SurveySelectModal';

export function CustomReportCard({
  description,
  name,
  image,
  openSurveySelectModal,
  disabled = false
}) {
  return (
    <div className="custom-report-card">
      <div className="custom-report-card-name margin-bottom-small font-size-14">
        <span>{name}</span>
        <div className="questionnaire-type-info">
          <i className="fas fa-info-circle text-pink"></i>
          <div>{description}</div>
        </div>
      </div>
      <img className="square-80 margin-bottom-large" src={image} alt="Create custom report" />
      <button
        onClick={openSurveySelectModal}
        className="custom-report-card-button pink-button"
        disabled={disabled}
      >
        Select
      </button>
    </div>
  );
}


function mapDispatchToProps(dispatch, props) {
  return {
    openSurveySelectModal: () => dispatch(openSimpleModal({
      title: props.name,
      body: (
        <SurveySelectModal reportType={props.reportType} />
      ),
    }))
  };
}

export default connect(undefined, mapDispatchToProps)(CustomReportCard);
