import React from 'react';
import Select from 'react-select';

class FieldSelect extends React.PureComponent {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(newValue) {
    if (this.props.multi) {
      this.props.onChange(newValue.map(option => option.value));
    } else {
      this.props.onChange(newValue ? newValue.value : '');
    }
  }

  render() {
    return (
      <Select
        options={this.props.options}
        onChange={this.onChange}
        value={this.props.value}
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        multi={this.props.multi}
        disabled={this.props.disabled}
      />
    );
  }
}

export default FieldSelect;
