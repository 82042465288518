import { anyPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { createOkrAndToastOrRedirect, updateOkrAndToast, CREATE_OKR, UPDATE_OKR } from './actions';
import { openSimpleModal } from '^/actions/modals';
import { OKR_STATUS_OPTIONS, OKR_TYPE, OKR_TYPE_DISPLAY } from './constants';
import DeleteOkrModal from './DeleteOkrModal';
import RemoteUserSelect from '^/components/forms/RemoteUserSelect';
import roles from '^/roles';
import { FORMAT_DATE_BACKEND, FORMAT_DATE_PICKER } from '^/utils';
import { userMeetsRequiredRole } from '^/utils/permissions';

const OWNER_FILTERS_MAPPED_TO_ROLE = {
  [OKR_TYPE.COMPANY]: {'company_okr_owners': true},
  [OKR_TYPE.MANAGER]: {'manager_okr_owners': true},
  [OKR_TYPE.EMPLOYEE]: {'bubble_users_only': true}
};

export const DatePickerField = (props) => {
  return (
    <div className="datepicker-with-icon-wrapper okr-small-input">
      <ReactDatePicker
        selected={props.input.value || moment().add(1, 'days')}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        fixedHeight
        required
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

export const ExtendDatePickerField = (minExtendDate) => (props) => {
  return (
    <div className="datepicker-with-icon-wrapper okr-small-input">
      <ReactDatePicker
        selected={props.input.value}
        onChange={props.input.onChange}
        dateFormat={FORMAT_DATE_PICKER}
        placeholderText="DD MM YYYY"
        minDate={minExtendDate}
        fixedHeight
      />
      <i className="far fa-calendar datepicker-icon"></i>
    </div>
  );
};

const OwnerSelectField = props => (
  <RemoteUserSelect
    initialValue={props.owner}
    onChange={props.input.onChange}
    selectId={props.selectId}
    optionFilters={props.ownerFilter}
    noResultsText={props.ownerNoResultsText}
    placeholder="Type to search..."
    disabled={props.disabled}
    required={props.required}
  />
);

const OkrField = ({
  name,
  label,
  children,
  isDisabled,
  extraClassNames,
  type,
  extraProps = {},
  component = 'input'
}) => {
  return (
    <div className={classNames('display-flex align-items-center', extraClassNames)}>
      <label className="margin-none font-weight-bold padding-right-small flex-shrink-0">
        {label}
      </label>
      <Field
        name={name}
        component={component}
        type={type}
        disabled={isDisabled}
        required
        {...extraProps}
      >
        {children}
      </Field>
    </div>
  );
};

export function CreateUpdateOkrForm({
  initialValues,
  handleSubmit,
  canSetOkrType,
  isCompanyGoal,
  linkedOkrOptions,
  isDisabled,
  readOnlyOwner,
  cancel,
  ownerFilter,
  ownerNoResultsText,
  largeContainer,
  okrId,
  ownerSelectId,
  openDeleteOkrModal,
  hideLinkedTo,
  minExtendDate,
  errors,
  parentOkrName,
  noAutoUpdateParentStatus,
  noAutoUpdateStatus
}) {
  return (
    <form
      className={classNames('okr-form', {'okr-container-large': largeContainer})}
      onSubmit={handleSubmit}
    >
      <div className="display-flex margin-bottom-xx-large">
        {isCompanyGoal ? (
          <div className="display-flex align-items-center">
            <i className="far font-size-18 fa-globe"></i>
            <span className="margin-left-base">
              Company Goal
            </span>
          </div>
        ) : (
          <div className="okr-flex-container">
            <Field
              id="type"
              name="type"
              component="select"
              disabled={isDisabled || !canSetOkrType}
              className="okr-small-input"
              required
            >
              <option value={OKR_TYPE.EMPLOYEE}>{OKR_TYPE_DISPLAY.EMPLOYEE}</option>
              <option value={OKR_TYPE.MANAGER}>{OKR_TYPE_DISPLAY.MANAGER}</option>
            </Field>
            {hideLinkedTo && parentOkrName && (
              <div className="display-flex align-items-center">
                <label className="margin-none font-weight-bold padding-right-small flex-shrink-0">
                  Linked to:
                </label>
                <select className="readonly-owner-select" disabled>
                  <option>{parentOkrName}</option>
                </select>
              </div>
            )}
            {!hideLinkedTo && (
              <OkrField
                name="parent_okr"
                label="Linked to:"
                component="select"
                isDisabled={isDisabled}
                extraClassNames="flex-shrink-0"
               >
                <option value="">Select OKR</option>
                {linkedOkrOptions.map(linkedOkrOption => (
                  <option key={linkedOkrOption.value} value={linkedOkrOption.value}>
                    {linkedOkrOption.label}
                  </option>
                ))}
              </OkrField>
            )}
            {noAutoUpdateStatus && (
              <p className="input-line-height margin-none">The status of this OKR has been updated manually.</p>
            )}
            {noAutoUpdateParentStatus && (
              <p className="input-line-height margin-none">
                The status of the Manager OKR this is linked to has been updated manually.
              </p>
            )}
          </div>
        )}
        {okrId && (
          <a
            href=""
            className="btn-icon text-gray margin-left-auto"
            onClick={openDeleteOkrModal}
          >
            <i className="far fa-trash"></i>
          </a>
        )}
      </div>
      <div className="okr-flex-container">
        <OkrField
          name="name"
          label={isCompanyGoal ? 'Title:' : 'OKR:'}
          isDisabled={isDisabled}
          type="text"
          extraClassNames="flex-grow"
        />
        <OkrField
          name="due_date"
          label="Due:"
          component={DatePickerField}
          isDisabled={isDisabled}
          extraClassNames="flex-shrink-0"
        />
        {okrId && isCompanyGoal && (
          <OkrField
            name="extended_date"
            label="Extend date:"
            component={ExtendDatePickerField(moment(minExtendDate).add(1, 'days'))}
            isDisabled={isDisabled}
            extraClassNames="flex-shrink-0"
          />
        )}
        {!isCompanyGoal && (
          <OkrField
            name="status"
            label="Status:"
            component="select"
            isDisabled={isDisabled}
            extraProps={{
              className: 'okr-small-input'
            }}
            extraClassNames="flex-shrink-0"
          >
            {OKR_STATUS_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </OkrField>
        )}
        {readOnlyOwner ? (
          <div className="display-flex align-items-center">
            <label className="margin-none font-weight-bold padding-right-small flex-shrink-0">
              Owner:
            </label>
            <select className="readonly-owner-select" disabled>
              <option>{readOnlyOwner}</option>
            </select>
          </div>
        ) : (
          <OkrField
            name="owner"
            label="Owner:"
            extraProps={{
              owner: initialValues.owner,
              ownerFilter,
              ownerNoResultsText,
              selectId: okrId || ownerSelectId,
              required: !isCompanyGoal
            }}
            component={OwnerSelectField}
            isDisabled={isDisabled}
          />
        )}
      </div>
      <Field
        id="comment"
        name="comment"
        component="input"
        type="text"
        placeholder="Enter comment"
        className="margin-vertical-xx-large"
        disabled={isDisabled}
      />
      {errors && (
        <p className="margin-bottom-large error">{errors}</p>
      )}
      <button
        className="btn middle orange margin-right-base"
        disabled={isDisabled}
      >
        { isCompanyGoal ? 'Save company goal' : 'Save OKR' }
      </button>
      <button
        className="btn middle orange hollow"
        disabled={isDisabled}
        onClick={cancel}
      >
        Cancel
      </button>
    </form>
  );
}

const OkrFormFormified = reduxForm({})(CreateUpdateOkrForm);

const getDefaultInitialValues = (owner, lockedOkrType, parentOkr, isManagerUser) => {
  const defaultType = isManagerUser ? OKR_TYPE.MANAGER : OKR_TYPE.EMPLOYEE;
  return {
    type: lockedOkrType || defaultType,
    due_date: moment().add(1, 'days'),
    owner,
    parent_okr: parentOkr,
  };
};

function getLinkedOkrOptions(state, okrType) {
  if (okrType === OKR_TYPE.MANAGER) {
    return state.okrList.map((companyGoal) => ({
      value: companyGoal.id,
      label: companyGoal.name
    }));
  }
  if (okrType === OKR_TYPE.EMPLOYEE) {
    return state.okrList.map((companyGoal) => ([
      {
        value: companyGoal.id,
        label: companyGoal.name
      },
      ...(companyGoal.child_okrs || []).filter(
        // TODO - this should only be managers who manage the employee
        employeeOrManagerOkr => employeeOrManagerOkr.type === OKR_TYPE.MANAGER
      ).map(managerOkr => ({
        value: managerOkr.id,
        label: managerOkr.name
      }))
    ])).flat();
  }
}

function mapStateToProps(state, props) {
  const isManagerUser = userMeetsRequiredRole(state.loggedInUser, roles.MANAGER_USER);
  const initialValues = props.initialValues || getDefaultInitialValues(
    !isManagerUser ? state.loggedInUser.get('id') : null,
    props.lockedOkrType,
    props.parentOkr,
    isManagerUser
  );
  const selectedType = formValueSelector(props.form)(state, 'type');
  const minExtendDate = formValueSelector(props.form)(state, 'due_date');
  const okrType = selectedType || props.lockedOkrType || initialValues.type;
  const isEmployeeBubbleUser = state.loggedInUser.get('type') === roles.EMPLOYEE_WITH_BUBBLE;
  return {
    initialValues,
    canSetOkrType: (
      isManagerUser &&
      !props.lockedOkrType
    ),
    linkedOkrOptions: !props.hideLinkedTo && getLinkedOkrOptions(state, okrType),
    readOnlyOwner: isEmployeeBubbleUser &&
      `${state.loggedInUser.get('first_name')} ${state.loggedInUser.get('last_name')}`,
    ownerFilter: props.ownerFilter || OWNER_FILTERS_MAPPED_TO_ROLE[okrType],
    isCompanyGoal: okrType === OKR_TYPE.COMPANY,
    isDisabled: anyPending(state.responses, [CREATE_OKR, UPDATE_OKR]),
    minExtendDate: minExtendDate,
    errors: state.createUpdateOkrErrors[props.form]
  };
}

export function mapDispatchToProps(dispatch, props) {
  return {
    onSubmit: (formData) => {
      const { due_date, extended_date, owner } = formData;
      const data = Object.assign(
        {},
        formData,
        {
          due_date: due_date.format(FORMAT_DATE_BACKEND),
          extended_date: extended_date && extended_date.format(FORMAT_DATE_BACKEND),
          owner: (owner && owner.value) ? owner.value : owner
        }
      );
      if (props.okrId) {
        return dispatch(updateOkrAndToast(props.okrId, props.initialValues.parent_okr, data, props.form));
      }
      return dispatch(createOkrAndToastOrRedirect(props.parentOkr, data, props.form));
    },
    openDeleteOkrModal: event => {
      event.preventDefault();
      dispatch(
        openSimpleModal({
          body: (
            <DeleteOkrModal
              okrType={props.initialValues.type}
              okrId={props.okrId}
            />
          ),
          title: 'Delete OKR',
        })
      );
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OkrFormFormified);
