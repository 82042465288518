import { List } from 'immutable';
import * as modalActions from '^/actions/modals';

export default function modals (state = List(), action) {
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      return state.push(action.payload);
    case modalActions.CLOSE_MODAL:
      return state.pop();
    default:
      return state;
  }
}
