import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { ModalRenderer } from '@dabapps/roe';
import { ToastContainer, toast } from 'react-toastify';

import { clearAllSelectedListItems, toggleListItemSelected } from '^/actions/actions';
import { openSimpleModal } from '^/actions/modals';
import roles, { organisationLevelRoleOptions } from '^/roles';
import { userMeetsRequiredRole } from '^/utils/permissions';
import ChadminList from '^/components/common/ChadminList';
import UriFilterSelect from '^/components/common/UriFilterSelect';
import { ETHNICITY, GENDER, CUSTOM } from '^/components/filters/constants';
import { usersListConfig } from '^/components/users/config';
import { USERS_PATH } from '^/components/users/const';
import AssignManagerModal from './AssignManagerModal';
import CreateEditUserModal from './CreateEditUserModal';
import QueueExportButton from './QueueExportButton';


function ExtraControlsLeft(props) {
  const {
    isManagerAdminUserOrHigher,
    assignManager,
    selectedUsers,
    reloadUsers,
    openAddUserModal,
  } = props;

  if (isManagerAdminUserOrHigher) {
    return (
      <div>
        <a className="btn orange hollow" href="/user-import/">Import</a>
        <a className="btn orange hollow" onClick={() => openAddUserModal(reloadUsers)}>Add user</a>
        <QueueExportButton />
        <button
          onClick={() => assignManager(reloadUsers)}
          className="btn orange hollow"
          disabled={selectedUsers.length === 0}
          title={
            selectedUsers.length === 0 ?
            'You must select some users before you are able to assign a manager to them' :
            'Assign manager'
          }
        >
          Assign manager&hellip;
        </button>
      </div>
    );
  }
  return null;
}

export class UserList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.assignManager = this.assignManager.bind(this);
  }

  componentDidMount() {
    this.props.clearUserSelection();
  }

  assignManager(reloadUsers) {
    this.props.openAssignManagerModal(this.props.selectedUsers, reloadUsers);
  }

  render() {
    const {
      filterOptions: { teams, managers },
      isManagerAdminUserOrHigher,
      selectedUsers,
      organisationFilterTerms,
      hasAdditionalFilters,
      ethnicityTerm,
      genderTerm,
      hasCustomFilters,
      isExecutive,
    } = this.props;
    return (
      <div className="user-list will">
        <ChadminList
          collectionPath={USERS_PATH}
          collectionName={USERS_PATH}
          defaultFilters={{ordering: 'first_name', 'is_active': true}}
          className="users-list"
          getColumns={reloadPage => usersListConfig(
            isExecutive,
            isManagerAdminUserOrHigher, // CREATE PERM TOO
            selectedUsers,
            this.props.toggleUserSelection,
            this.props.openCreateEditUserModal,
            reloadPage,
            organisationFilterTerms,
            hasAdditionalFilters,
            ethnicityTerm,
            genderTerm,
            hasCustomFilters
          )}
          ExtraControlsLeft={!isExecutive ? ({ reloadPage }) => (
            <ExtraControlsLeft
              isManagerAdminUserOrHigher={isManagerAdminUserOrHigher}
              assignManager={this.assignManager}
              selectedUsers={selectedUsers}
              reloadUsers={reloadPage}
              openAddUserModal={this.props.openCreateEditUserModal}
            />
          ) : undefined}
          ExtraControlsRight={() => (
            <div>
              <UriFilterSelect
                filterName="type"
                name="role"
                options={organisationLevelRoleOptions}
              />
              <UriFilterSelect
                filterName="team_member__team_id"
                name={organisationFilterTerms.team}
                options={teams}
              />
              <UriFilterSelect
                filterName="managers__id"
                name="manager"
                label="Reports To"
                options={managers}
              />
              <UriFilterSelect
                filterName="is_active"
                name="active"
                label="Hide Inactive"
                options={[{value: true, label: 'Yes'}, {value: null, label: 'No'}]}
                hideBlankOption
              />
            </div>
          )}
          searchable
        />
        <ModalRenderer modals={this.props.modals}/>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
      </div>
    );
  }
}

export function mapStateToProps({ loggedInUser, selectedListItems, modals, }, ownProps) {
  const ethnicityFilter = ownProps.editUserOptions.additional_filters.find(filter => filter.type === ETHNICITY);
  const genderFilter = ownProps.editUserOptions.additional_filters.find(filter => filter.type === GENDER);
  const hasAdditionalFilters = !!ownProps.editUserOptions.additional_filters.filter(
    filter => filter.type !== CUSTOM
  ).length;
  const hasCustomFilters = !!ownProps.editUserOptions.additional_filters.filter(
    filter => filter.type === CUSTOM
  ).length;
  return {
    isManagerAdminUserOrHigher: userMeetsRequiredRole(loggedInUser, roles.MANAGER_USER_WITH_ADMIN),
    selectedUsers: selectedListItems,
    modals,
    hasAdditionalFilters,
    ethnicityTerm: ethnicityFilter && ethnicityFilter.name,
    genderTerm: genderFilter && genderFilter.name,
    hasCustomFilters,
    isExecutive: loggedInUser.get('type') === roles.EXECUTIVE,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    clearUserSelection: () => dispatch(clearAllSelectedListItems()),
    toggleUserSelection: (userId) => dispatch(toggleListItemSelected(userId)),
    openAssignManagerModal: (selectedUsers, reloadUsers) => dispatch(
      openSimpleModal({
        body: <AssignManagerModal selectedUsers={selectedUsers} reloadUsers={reloadUsers} />,
        title: `Assign ${selectedUsers.length} ${selectedUsers.length === 1 ? 'person' : 'people'} to manager`,
      })
    ),
    openCreateEditUserModal: (reloadPage, user) => {
      const title = user ? `Edit ${user.name}` : 'Add user';
      return dispatch(
        openSimpleModal({
          title,
          body: (
            <CreateEditUserModal
              userId={user ? user.id : undefined}
              reloadPage={reloadPage}
              selectOptions={ownProps.editUserOptions}
              organisationFilterTerms={ownProps.organisationFilterTerms}
            />
          ),
          large: true,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
