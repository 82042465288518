import React from 'react'; // eslint-disable-line
import classnames from 'classnames';
import { Row, Column } from '@dabapps/roe';
import { connect } from 'react-redux';
import { updateAction } from '^/actions/actions';
import { updateActionAndDisplayToast } from '^/actions/actionSequences';
import ActionTypeIcon from '../actions/ActionTypeIcon';
import ReactDatePicker from 'react-datepicker';
import { FORMAT_DATE_BACKEND, FORMAT_DATE_PICKER } from '../../utils';
import moment from 'moment';
import {
  actionPickerEditAction,
  actionPickerExcludeAction,
  actionPickerReincludeAction,
} from './actions';
import ActionCardEditForm from './ActionCardEditForm';
import { ACTION_STATUS_OPTIONS, ACTION_TYPE } from '^/consts/actions';
import RemoteUserSelect from '^/components/forms/RemoteUserSelect';

function getActionExternalUrl(action) {

  if (action.get('type') === ACTION_TYPE.COURSE) {
    return action.get('course_hyperlink');
  }

  if (action.get('type') === ACTION_TYPE.RESOURCE) {
    return action.get('resource_hyperlink');
  }

  return null;
}

export const ActionCard = (props) => {
  const externalUrl = getActionExternalUrl(props.action);
  return (
    <div
      key={props.action.get('id')}
      className={classnames(
        'action-card',
        {
          excluded: props.disabled,
        }
      )}
    >
      {props.canEdit && props.canExclude && (
        <a
          className="toggle-inclusion-icon"
          onClick={props.excluded ? props.reincludeAction : props.excludeAction}
        >
          {props.excluded ? (
            <i className="far fa-plus"></i>
          ) : (
            <i className="far fa-times"></i>
          )}
        </a>
      )}
      <Row>
        <Column md={11}>
          <div className="text-gray margin-bottom-base">
            <ActionTypeIcon actionType={props.action.get('type')} />
          </div>
          {props.isEditing ? (
            <ActionCardEditForm
              initialValues={{
                title: props.action.get('title'),
                description: props.action.get('description'),
              }}
              onSubmit={props.updateAction}
              isLoading={props.isLoading}
              form={`actionCardEditForm-${props.action.get('id')}`}
            />
          ) : (
            <div>
              {externalUrl ? (
                <p className="font-size-14">
                  <strong>{props.action.get('title')} </strong>
                  <a href={externalUrl} target="_blank" className="link-orange font-weight-bold display-inline-block">
                    <i className="far fa-external-link"></i>
                  </a>
                </p>
              ) : (
                <strong className="font-size-14">{props.action.get('title')}</strong>
              )}
              {props.action.get('image') && (
                <img
                  src={props.action.get('image')}
                  className="display-block max-width-200 margin-bottom-x-large margin-top-x-large"
                />
              )}
              <p className="margin-top-base margin-bottom-none font-size-14">
                {props.action.get('description')}{' '}
              </p>
              {!props.disabled && (
                <a onClick={props.editAction} className="link-orange font-weight-bold font-size-14">Edit</a>
              )}
            </div>
          )}

          <Column md={2} className="padding-none margin-top-large">
            <RemoteUserSelect
              disabled={props.disabled || !props.canChangeUser}
              initialValue={props.action.get('owner').toJS()}
              onChange={props.updateOwnerField}
              selectId={props.action.get('id')}
              optionFilters={{'bubble_users_only': true}}
            />
          </Column>
          <Column md={2} className="padding-none margin-top-large md-margin-left-large">
            <div className="datepicker-with-icon-wrapper">
              <ReactDatePicker
                disabled={props.disabled}
                selected={moment(props.action.get('due_date'))}
                onChange={props.updateDueDate}
                dateFormat={FORMAT_DATE_PICKER}
                fixedHeight
              />
              <i className="far fa-calendar datepicker-icon"></i>
            </div>
          </Column>
          <Column md={2} className="padding-none margin-top-large md-margin-left-large">
            <select
              disabled={props.disabled}
              className="display-table"
              value={props.action.get('status')}
              onChange={props.updateStatusField}
            >
              {ACTION_STATUS_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Column>
        </Column>
      </Row>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const canEdit = [
    ownProps.action.get('creator'),
    ownProps.action.getIn(['owner', 'value'])
  ].indexOf(state.loggedInUser.get('id')) !== -1;
  const excluded = state.actionPickerExcludedActions.find(
    (actionId) => ownProps.action.get('id') === actionId
  );
  return {
    isEditing: state.goalPickerEditingAction === ownProps.action.get('id'),
    excluded,
    canEdit,
    disabled: !canEdit || !!excluded,
    canExclude: !ownProps.excludeActionsMaxReached || ownProps.index > 0
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  const actionId = ownProps.action.get('id');
  return {
    updateDueDate: (newDate) => {
      dispatch(updateActionAndDisplayToast(actionId, {due_date: newDate.format(FORMAT_DATE_BACKEND)}));
    },
    updateStatusField: (event) => {
      dispatch(updateActionAndDisplayToast(actionId, {status: event.target.value}));
    },
    updateOwnerField: (selectedOwner) => {
      dispatch(updateActionAndDisplayToast(actionId, {owner: selectedOwner.value}));
    },
    editAction: (event) => {
      event.preventDefault();
      dispatch(actionPickerEditAction(actionId));
    },
    updateAction: (data) => {
      dispatch(updateAction(actionId, data));
    },
    excludeAction: () => {
      dispatch(actionPickerExcludeAction(actionId));
    },
    reincludeAction: () => {
      dispatch(actionPickerReincludeAction(actionId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionCard);
