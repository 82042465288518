import { isPending } from '@dabapps/redux-requests';
import React from 'react'; // eslint-disable-line
import { ModalBody, ModalFooter, SpacedGroup, Button } from '@dabapps/roe';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import { CREATE_AGENDA_REVIEW_QUESTION } from '^/components/agendas/actions';
import AddQuestionToAgendaCard from './AddQuestionToAgendaCard';

export const AgendaPerformanceQuestionsModal = ({
  agendaId,
  selectableItems,
  isDisabled,
  close,
}) => {
  return (
    <div>
      <ModalBody>
        {selectableItems.length > 0 ? selectableItems.map((item) => (
          <AddQuestionToAgendaCard
            key={item.question_id}
            agendaId={agendaId}
            item={item}
            isDisabled={isDisabled}
            questionId={item.question_id}
            answerId={item.answer_id}
          />
        ))
        : <p>No questions available.</p>}
      </ModalBody>
      <ModalFooter>
        <SpacedGroup block className="margin-vertical-base">
          <Button onClick={close}>Close</Button>
        </SpacedGroup>
      </ModalFooter>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    agenda: state.editingAgenda,
    isDisabled: isPending(state.responses, CREATE_AGENDA_REVIEW_QUESTION) || props.isDisabled,
    selectableItems: props.items.filter(option =>
      !state.editingAgenda.review_questions.find(reviewQuestion => (
        reviewQuestion.question_id === option.question_id &&
        reviewQuestion.answer_id === option.answer_id
      ))
    )
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => {
      dispatch(closeModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaPerformanceQuestionsModal);
