import React from 'react'; // eslint-disable-line no-unused-vars
import { Well } from '@dabapps/roe';

import Empty from './Empty';
import AvailableItem from './AvailableItem';

export default ({
  disabled,
  questions,
  onClickAdd,
  readOnly,
  questionIdsCurrentlyCopying
}) => {
  return (
    <Well className="survey-question-list padding-top-large">
      {questions.map(question =>
        <AvailableItem
          key={question.get('id')}
          question={question}
          disabled={
            disabled || questionIdsCurrentlyCopying.count() > 0
          }
          onClickAdd={onClickAdd}
          readOnly={readOnly}
          recommended={question.get('recommended_for')}
        />
      )}
      {questions.count() === 0 && (
        <Empty>{readOnly ? '' : 'There are no questions to select...'}</Empty>
      )}
    </Well>
  );
};
