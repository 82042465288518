import moment from 'moment';
import { ACTION_TYPE } from '^/consts/actions';

const RESOURCE_ACTION_WEIGHT = 100;
const COURSE_ACTION_WEIGHT = RESOURCE_ACTION_WEIGHT;

export function getActionData(type, title, suggestionsMap, area, weight) {
  return {
    type,
    title,
    description: '',
    survey: suggestionsMap.get('survey'),
    area,
    due_date: moment().add(1, 'days').format('YYYY-MM-DD'),
    weight,
    owner: suggestionsMap.get('new_actions_owner_id'),
  };
}

export function getTaskActionData(actionName, suggestionsMap, area, weight) {
  return getActionData(ACTION_TYPE.TASK, actionName, suggestionsMap, area, weight);
}

export function getResourceActionData(resource, suggestionsMap, area) {
  return Object.assign(
    getActionData(
      ACTION_TYPE.RESOURCE,
      resource.get('display_name'),
      suggestionsMap,
      area,
      RESOURCE_ACTION_WEIGHT
    ),
    {
      resource_hyperlink: resource.get('id'),
    }
  );
}

export function getCourseActionData(course, suggestionsMap, area) {
  return Object.assign(
    getActionData(
      ACTION_TYPE.COURSE,
      course.get('display_name'),
      suggestionsMap,
      area,
      COURSE_ACTION_WEIGHT
    ),
    {
      course_hyperlink: course.get('id'),
    }
  );
}

export function getCreateGoalData(name, description, area, actions, survey) {
  return {
    due_date: moment().add(1, 'days').format('YYYY-MM-DD'),
    name,
    description,
    actions,
    area,
    survey
  };
}
