import React from 'react';
import { Alert, ModalBody, ModalFooter, SpacedGroup, Button, Row, Column } from '@dabapps/roe';
import { hasFailed, hasSucceeded, isPending } from '@dabapps/redux-requests';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { ASSIGN_MANAGER, getManagerOptions, GET_MANAGER_OPTIONS } from '^/actions/actions';
import { assignManagerNotiftyAndReloadUsers } from '^/actions/actionSequences';
import { closeModal } from '^/actions/modals';
import Loading from '^/components/Loading';
import FormError from '^/components/forms/FormError';
import { getManagers } from '^/components/users/utils';
import { getFormErrorData, getFormErrorString } from '^/utils';

const ASSIGN_MANAGER_DROPDOWN_ID = 'AssignManagerDropdown';

export class AssignManagerModal extends React.PureComponent {
  componentDidMount() {
    this.props.getManagerOptions();
  }

  render() {
    const {
      assignManagerError,
      cancel,
      handleSubmit,
      hasPendingRequests,
      managers,
      missingManagersMessage,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Column xs={12} md={4}>
              <label className="input-line-height" htmlFor={ASSIGN_MANAGER_DROPDOWN_ID}>Manager</label>
            </Column>
            <Column xs={12} md={8}>
              <Field id={ASSIGN_MANAGER_DROPDOWN_ID} name="manager" component="select" disabled={hasPendingRequests}>
                {managers.map(manager => (
                  <option key={manager.get('value')} value={manager.get('value')}>
                    {manager.get('label')}
                  </option>)
                )}
              </Field>
              <FormError error={assignManagerError} />
            </Column>
          </Row>
          {hasPendingRequests && (<Loading className="margin-vertical-large" />)}
          {missingManagersMessage && (<Alert className="error margin-vertical-large">{missingManagersMessage}</Alert>)}
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button className="btn middle orange" type="submit" disabled={hasPendingRequests}>Assign</Button>
            <Button className="btn middle orange hollow" onClick={cancel}>Cancel</Button>
          </SpacedGroup>
        </ModalFooter>
      </form>
    );
  }
}

export const FormifiedAssignManagerModal = reduxForm({
  form: 'AssignManagerModalForm',
  enableReinitialize: true,
})(AssignManagerModal);

function getMissingManagersMessage(responses, managers) {
  if (hasFailed(responses, GET_MANAGER_OPTIONS)) {
    return 'Failed to load managers';
  }
  if (hasSucceeded(responses, GET_MANAGER_OPTIONS) && !managers.size) {
    return 'You have no managers available for this selection of users';
  }
}

export function getAssignManagerError(responses) {
  const errorData = getFormErrorData(responses, ASSIGN_MANAGER);
  return (
    getFormErrorString(errorData, 'manager') ||
    getFormErrorString(errorData, 'users') ||
    getFormErrorString(errorData, 'non_field_errors')
  );
}

export function mapStateToProps({ managerOptions, responses}, { selectedUsers }) {
  const managers = getManagers(managerOptions, selectedUsers);
  return {
    assignManagerError: getAssignManagerError(responses),
    missingManagersMessage: getMissingManagersMessage(responses, managers),
    hasPendingRequests: isPending(responses, ASSIGN_MANAGER) || isPending(responses, GET_MANAGER_OPTIONS),
    initialValues: { manager: managers.getIn([0, 'value']) },
    managers,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    cancel: (event) => {
      event.preventDefault();
      dispatch(closeModal());
    },
    onSubmit: ({ manager }) => {
      dispatch(assignManagerNotiftyAndReloadUsers(manager, props.selectedUsers, props.reloadUsers));
    },
    getManagerOptions: () => dispatch(getManagerOptions()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormifiedAssignManagerModal);
