import { isPending } from '@dabapps/redux-requests';
import { List } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { clearSelectedListItems, addSelectedListItems } from '^/actions/actions';
import { SEND_PEER_FEEDBACK_REMINDERS } from '^/components/peer-feedback/actions';
import { canSendReminder } from '^/components/peer-feedback/utils';

export function PeerFeedbackListAllCheckbox({toggleSelected, isDisabled, isChecked}) {
  return (
    <input
      type="checkbox"
      className="checkbox-color-text"
      disabled={isDisabled}
      checked={isChecked}
      onChange={toggleSelected}
    />
  );
}

function mapStateToProps(state, props) {
  const items = state.collections.getIn([props.collectionName, 'items'], List());
  const availableItemIds = items.reduce((accumulator, item) =>
    canSendReminder(item) ? [...accumulator, item.get('id')] : accumulator
  , []);
  const selectedItemsCount = availableItemIds.reduce((count, itemId) =>
    state.selectedListItems.includes(itemId) ? count + 1 : count
  , 0);
  return {
    isDisabled: (
      availableItemIds.length === 0 ||
      isPending(state.responses, SEND_PEER_FEEDBACK_REMINDERS)
    ),
    isChecked: (
      availableItemIds.length &&
      availableItemIds.length === selectedItemsCount
    ),
    availableItemIds
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    toggleSelectedFunction: (availableItemIds) => (event) => {
      if (event.target.checked) {
        dispatch(addSelectedListItems(availableItemIds));
      } else {
        dispatch(clearSelectedListItems(availableItemIds));
      }
    }
  };
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    toggleSelected: dispatchProps.toggleSelectedFunction(
      stateProps.availableItemIds
    )
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PeerFeedbackListAllCheckbox);
