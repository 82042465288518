import { isPending } from '@dabapps/redux-requests';
import { List } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'underscore';

import { ASSIGN_MANAGER } from '^/actions/actions';
import { assignManagerNotiftyAndReloadUsers } from '^/actions/actionSequences';
import { getCollection } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import FormError from '^/components/forms/FormError';
import { getAssignManagerError } from '^/components/users/AssignManagerModal';
import { isPending as isLegacyPending } from '^/consts/responseStates';

export class AddNewUserToManager extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    const throttled = _.throttle(
      event => props.loadUsers(event.target.value),
      500,
      { trailing: true }
    );
    this.onInputKeyDown = event => {
      event.persist();
      return throttled(event);
    };
  }

  componentDidMount() {
    this.props.loadUsers();
  }

  onChange(option) {
    this.props.addUserToManager(option.value);
  }

  render() {
    const {
      assignManagerError,
      isLoading,
      isAddingUserToManager,
      userOptions,
    } = this.props;

    return (
      <div>
        <Select
          className="add-new-user-to-manager"
          options={userOptions}
          onInputKeyDown={this.onInputKeyDown}
          onChange={this.onChange}
          isLoading={isLoading}
          disabled={isAddingUserToManager}
          placeholder="Add person to manager"
        />
        <FormError error={assignManagerError} />
      </div>
    );
  }
}

export const mapStateToProps = ({ collections, legacyResponses, responses }) => ({
  assignManagerError: getAssignManagerError(responses),
  isAddingUserToManager: isPending(responses, ASSIGN_MANAGER),
  isLoading: isLegacyPending(legacyResponses.getIn(['getCollection', 'account/user-options'])),
  userOptions: collections.getIn(['account/user-options', 'items'], List()).toJS(),
});

const mapDispatchToProps = (dispatch, props) => ({
  loadUsers: (searchString) => dispatch(
    getCollection('account/user-options', { searchString, filters: { manageable_by: props.managerId }})
  ),
  addUserToManager: (userId) => dispatch(
    assignManagerNotiftyAndReloadUsers(props.managerId, [userId], props.reloadUsers)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserToManager);
