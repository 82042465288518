import { isPending } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';

import Loading from '^/components/Loading';
import {
  pollCustomReport,
  queueCustomReport,
  CREATE_FILTERED_CUSTOM_REPORT,
  updateSelectedSurveys,
  addSelectedBenchmarks,
  updateSelectedSurveyType,
} from '^/components/custom-reports/actions';
import CustomReportFilters from '^/components/custom-reports/CustomReportFilters';

import PublishConfirmModal from '^/components/custom-reports/PublishConfirmModal';
import UnpublishConfirmModal from '^/components/custom-reports/UnpublishConfirmModal';
import { openSimpleModal } from '^/actions/modals';
import ReportName from '^/components/custom-reports/ReportName';
import {
  CHART_TYPE_LABEL_MAP,
  SURVEY_TYPES_BUTTON_LABEL_MAP,
  REPORT_TYPES,
  SURVEY_TYPES
} from '^/components/custom-reports/constants';
import { renderChart, getHeatmapChartHeight } from '^/components/custom-reports/utils';
import ReportDeleteModal from '^/components/custom-reports/ReportDeleteModal';
import SurveySelectModal from '^/components/custom-reports/SurveySelectModal';
import USER_ROLES from '^/roles';
import {
  getPollReportTimoutDuration,
  THREE_SECONDS,
  POLL_MAX_RETRIES
} from '^/utils';
import { isEmployeeBubbleUser, userMeetsRequiredRole, isManagerUser } from '^/utils/permissions';

function buttonTitle(isAdminUser, isManager) {
  if (isAdminUser) {
    return "Choose to publish this report to individual, manager and/or executive dashboards. Update and re-publish at any time."; // eslint-disable-line
  }
  if (isManager) {
    return "Publish to your employees 'Me' dashboards and/or to your own 'My team' dashboard. Update and re-publish at any time."; // eslint-disable-line
  }
  return "Publish your report to your own homepage. Update and re-publish at any time. It is not visible to other users."; // eslint-disable-line
};

export class ReportSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.pollChartData = this.pollChartData.bind(this);
    this.state = {
      chartDataFailed: false
    };
  }

  componentDidMount() {
    const { report, isDashboardModal } = this.props;

    if (!report.cached_chart_data) {
      if (
        isDashboardModal &&
        !this.props.publishedVersionQueued &&
        !report.unfiltered_report_id
      ) {
        this.props.queueReport();
      }
      this.pollChartData();
    } else if (!this.props.isGettingFilteredReport) {
      renderChart(report, !isDashboardModal && this.props.editButtonOnclickHandler);
    }
  }

  componentDidUpdate(previousProps) {
    const { report, isDashboardModal } = this.props;

    if (!previousProps.report.cached_chart_data && report.cached_chart_data) {
      clearTimeout(this.timeout);
      renderChart(report, !isDashboardModal && this.props.editButtonOnclickHandler);
    }
    if (previousProps.report.cached_chart_data && !report.cached_chart_data) {
      this.pollChartData();
    }
    if (
      (
        report.cached_chart_data &&
        (previousProps.isGettingFilteredReport && !this.props.isGettingFilteredReport)
      ) || report.name !== previousProps.report.name
    ) {
      renderChart(report, !isDashboardModal && this.props.editButtonOnclickHandler);
    }
  }

  pollChartData(tryCount = 0, timeoutSeconds = THREE_SECONDS) {
    const timeoutWithBackoff = getPollReportTimoutDuration(tryCount, timeoutSeconds);

    if (tryCount === POLL_MAX_RETRIES) {
      this.setState({chartDataFailed: true});
    } else {
      this.timeout = setTimeout(
        () => {
          this.props.pollReport();
          this.pollChartData(tryCount + 1, timeoutWithBackoff);
        },
        timeoutWithBackoff
      );
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { report, isDashboardModal, organisationFilterTerms, hideFilters, isManager, isAdminUser } = this.props;
    const chartData = report.cached_chart_data ? JSON.parse(report.cached_chart_data) : null;
    const chartHeight = chartData && chartData.y_axis ? getHeatmapChartHeight(chartData.y_axis) : 0;
    return (
      <div
        className={classNames('background-white', {
          'margin-vertical-xx-large padding-large': !isDashboardModal,
          'margin-bottom-x-large padding-horizontal-base': isDashboardModal,
        })}
      >
        <div
          className={classNames('display-flex align-items-center', {
            'margin-bottom-large': !isDashboardModal,
            'margin-bottom-small': isDashboardModal,
          })}
        >
          <div
            className={classNames('margin-bottom-none width-15-percent', {
              'font-size-18': !isDashboardModal,
              'font-size-14': isDashboardModal,
            })}
          >
            Report type:
          </div>
          <div
            className={classNames('font-family-effra-bold margin-bottom-none width-85-percent', {
              'font-size-24': !isDashboardModal,
              'font-size-18': isDashboardModal,
            })}
          >
            {CHART_TYPE_LABEL_MAP[report.type]}
          </div>
          {!isDashboardModal && (
            <div
              className="btn-icon margin-left-auto cursor-pointer"
              onClick={this.props.openDeleteModal}
            >
              <i className="far fa-trash-alt color-text"></i>
            </div>
          )}
        </div>
        <div
          className={classNames('display-flex align-items-center', {
            'margin-bottom-large': !isDashboardModal,
            'margin-bottom-small': isDashboardModal,
          })}
        >
          <div
            className={classNames('margin-bottom-none width-15-percent', {
              'font-size-18': !isDashboardModal,
              'font-size-14': isDashboardModal,
            })}
          >
            Survey type:
          </div>
          <div
            className={classNames('font-family-effra-bold margin-bottom-none width-85-percent', {
              'font-size-24': !isDashboardModal,
              'font-size-18': isDashboardModal,
            })}
          >
            {report.survey_type_label || SURVEY_TYPES_BUTTON_LABEL_MAP[report.survey_type]}
          </div>
        </div>
        <ReportName report={report} isDashboardModal={isDashboardModal} />
        {!hideFilters && (
          <div>
            <div
              className={classNames('display-flex align-items-center', {
                'margin-bottom-xx-large': !isDashboardModal,
                'margin-bottom-large': isDashboardModal,
              })}
            >
              <div
                className={classNames('margin-bottom-none width-15-percent', {
                  'font-size-18': !isDashboardModal,
                  'font-size-14': isDashboardModal,
                })}
              >
                Selected filters:
              </div>
              <div
                className={classNames('margin-bottom-none width-85-percent', {
                  'font-size-18': !isDashboardModal,
                  'font-size-14': isDashboardModal,
                })}
              >
                {report.filter_names ? report.filter_names : 'No filters selected'}
              </div>
            </div>
            <CustomReportFilters report={report} organisationFilterTerms={organisationFilterTerms} />
          </div>
        )}
        {chartData ? (
          <div className={classNames({
            'margin-top-xx-large': hideFilters && !this.props.isDashboardModal
          })}>
            <div className="border-radius-base border-light-grey padding-large">
              {chartData.errors ? (
                <p className="text-align-center margin-top-x-large">{chartData.errors.non_field_errors}</p>
              ) : (
                <div
                  className={classNames('report-chart transition-half-second', {
                    'half-opacity': this.props.isGettingFilteredReport
                  })}
                  id={`chart-${report.id}`}
                  style={
                    report.type !== REPORT_TYPES.TREND_ANALYSIS_LINE ?
                    {height: chartHeight} : {}
                  }
                >
                </div>
              )}
            </div>
            {!isDashboardModal && (
              <div className="text-align-right margin-top-xx-large margin-bottom-large">
                <div className="display-flex align-items-center justify-content-end">
                  <button
                    title={buttonTitle(isAdminUser, isManager)}
                    className="pink-button"
                    onClick={this.props.openPublishConfirmModal}
                  >
                    Publish options
                    <i className="fas fa-info-circle font-color-white padding-left-base"></i>
                  </button>
                  {report.publishing_label && (
                    <button
                      title="Unpublish this report from all dashboards."
                      className="pink-border-button margin-left-large"
                      onClick={this.props.openUnpublishConfirmModal}
                    >
                      Unpublish
                      <i className="fas fa-info-circle font-color-pink padding-left-base"></i>
                    </button>
                  )}
                </div>
                {report.publishing_label && (
                  <p className="font-size-14 font-style-italic margin-top-large margin-bottom-none">
                    {report.publishing_label}
                  </p>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="border-radius-base border-light-grey text-align-center custom-report-loading">
            {this.state.chartDataFailed ? (
              <p>
                The report is taking longer than usual.{' '}
                Please try again shortly and if this problem persists contact support.
              </p>
            ) : <Loading />}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isGettingFilteredReport: isPending(state.responses, CREATE_FILTERED_CUSTOM_REPORT),
    hideFilters: isEmployeeBubbleUser(state.loggedInUser) || props.isMyDashboard,
    isManager: isManagerUser(state.loggedInUser),
    isAdminUser: userMeetsRequiredRole(state.loggedInUser, USER_ROLES.MANAGER_USER_WITH_ADMIN),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    pollReport: () => dispatch(pollCustomReport(props.report.id)),
    queueReport: () => dispatch(queueCustomReport(props.report.id)),
    openPublishConfirmModal: () => {
      const unFiltereredReportId = props.report.unfiltered_report_id || props.report.id;
      dispatch(
        openSimpleModal({
          body: (
            <PublishConfirmModal reportId={unFiltereredReportId} reportType={props.report.type} />
          )
        })
      );
    },
    openUnpublishConfirmModal: () => {
      const unFiltereredReportId = props.report.unfiltered_report_id || props.report.id;
      dispatch(
        openSimpleModal({
          body: (
            <UnpublishConfirmModal reportId={unFiltereredReportId} />
          )
        })
      );
    },
    openDeleteModal: () => {
      dispatch(
        openSimpleModal({
          body: (
            <ReportDeleteModal report={props.report} />
          ),
          title: 'Delete report',
        })
      );
    },
    editButtonOnclickHandler: () => {
      const filterAndUpdateSurveys = (surveyType) => {
        const surveys = props.report.surveys.reduce((arr, survey) => {
          if (survey.survey_type === surveyType) {
            arr.push({ value: survey.survey_id, label: survey.label, comment: survey.comment });
          }
          return arr;
        }, []);
        dispatch(updateSelectedSurveys(surveyType, surveys));
      };

      if (props.report.type === REPORT_TYPES.TREND_ANALYSIS_LINE) {
        filterAndUpdateSurveys(SURVEY_TYPES.ENGAGEMENT);
        filterAndUpdateSurveys(SURVEY_TYPES.MENTAL_HEALTH);
        filterAndUpdateSurveys(SURVEY_TYPES.CULTURE_AND_DIVERSITY);
      } else {
        dispatch(updateSelectedSurveyType(props.report.survey_type));
        if (props.report.type === REPORT_TYPES.TREND_ANALYSIS_HEATMAP) {
          const surveys = props.report.surveys.map(
            survey => ({ value: survey.survey_id, label: survey.label })
          );
          dispatch(updateSelectedSurveys(props.report.survey_type, surveys));
        } else {
          const survey = {value: props.report.surveys[0].survey_id, label: props.report.surveys[0].label, };
          dispatch(updateSelectedSurveys(props.report.survey_type, survey));
          if (props.report.type === REPORT_TYPES.EXTERNAL_BENCHMARKING_HEATMAP) {
            dispatch(addSelectedBenchmarks(props.report.survey_type, props.report.benchmarks));
          }
        }
      }

      const unFiltereredReportId = props.report.unfiltered_report_id || props.report.id;
      dispatch(openSimpleModal({
        title: CHART_TYPE_LABEL_MAP[props.report.type],
        body: (
          <SurveySelectModal
            reportType={props.report.type}
            reportId={props.report.id}
            unFiltereredReportId={unFiltereredReportId}
            isEditing
          />
        ),
      }));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSection);
