import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { toggleItemExpanded } from '^/actions/actions';
import { updatePeerFeedbackReadByUser } from '^/components/peer-feedback/actions';
import { CELL_WIDTH_CLASS_NAMES } from '^/components/peer-feedback/constants';
import PeerFeedbackListItemCheckbox from '^/components/peer-feedback/PeerFeedbackListItemCheckbox';

export function PeerFeedbackListItem(props) {
  const { item, fields, isExpanded, listName} = props;

  return (
    <div>
      <div className="
        border-base padding-large border-radius-10
        margin-top-base
      ">
        <div className="display-flex align-items-center">
          <div className="padding-horizontal-small width-15-percent">
            <PeerFeedbackListItemCheckbox item={item} />
          </div>
          {fields.map((field, index) => (
            <div
              key={`field-${field}-${item.get('id')}`}
              className={classNames(
                'padding-horizontal-small text-overflow-ellipsis',
                CELL_WIDTH_CLASS_NAMES[listName][index]
              )}
              title={item.get(field) || 'N/A'}
            >
              {item.get(field) || 'N/A'}
            </div>
          ))}
          <div className="padding-horizontal-small width-15-percent">
            <button
              onClick={props.toggleExpanded}
              className="
                background-none padding-none display-flex align-items-center
                font-family-varela-round color-text float-right"
            >
              View feedback
              <i className={
                classNames(
                  'far font-size-24 text-pink margin-left-base',
                  {
                    'fa-angle-down' : !isExpanded,
                    'fa-angle-up': isExpanded
                  }
                )
              }></i>
            </button>
          </div>
        </div>
        {isExpanded && (
          <div
            className="
              background-gray-lightest padding-x-large margin-top-large white-space-pre-line
              line-height-18px
            "
          >
            <p className="font-family-effra-bold font-size-18 margin-bottom-base">{item.get('title')}</p>
            {item.get('message') || 'Waiting for feedback.'}
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps({loggedInUser, expandedItems}, {item}) {
  return {
    isExpanded: expandedItems.includes(item.get('id')),
    userId: loggedInUser.get('id')
  };
}

function mapDispatchToProps(dispatch, {item}) {
  return {
    toggleExpandedFunction: (isExpanded, userId) => () => {
      if (
        userId === item.get('user_id') &&
        item.get('message') && !item.get('read_by_user') && !isExpanded
      ) {
        dispatch(updatePeerFeedbackReadByUser(item.get('id')));
      }
      dispatch(toggleItemExpanded(item.get('id')));
    }
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign(stateProps, dispatchProps, ownProps, {
    toggleExpanded: dispatchProps.toggleExpandedFunction(stateProps.isExpanded, stateProps.userId),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PeerFeedbackListItem);
