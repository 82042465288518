import Highcharts, { HIGHCHARTS_CONFIG_DEFAULTS } from './highcharts';

import { getChartExportButtons, chartExportTitle } from '^/components/custom-reports/utils';

export function renderLineChart(elementId, data, reportName, editButtonOnClick, isDashboardWidget) {
  const exporting = {
    filename: reportName,
    buttons: getChartExportButtons(editButtonOnClick, elementId),
    sourceWidth: data.x_axis.length > 5 ? 800 : 600,
    sourceHeight: data.x_axis.length > 5 ? 600 : 800,
    chartOptions: {
      chart: {
        marginTop: 80,
        marginLeft: 80,
        marginRight: 30,
      },
      title: chartExportTitle,
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.y}%`;
            },
          }
        }
      },
    },
  };
  const dashboardWidgetConfig = {
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },
    chart: {
      type: 'spline',
      marginTop: 10,
      height: 243,
      width: 640
    },
    yAxis: {
      allowDecimals: false,
      ceiling: 100,
      title: {
        text: 'Average score (%)'
      },
    },
    xAxis: {
      labels: {
        enabled: false
      },
      categories: data.x_axis
    }
  };
  Highcharts.chart(
    elementId,
    Object.assign(
      {},
      HIGHCHARTS_CONFIG_DEFAULTS,
      {
        chart: {
          type: 'spline',
          height: 600,
          marginTop: 100,
        },
        title: {
          text: reportName,
          widthAdjust: -200,
          style: {
            lineHeight: '26px'
          },
          y: 20
        },
        xAxis: {
          categories: data.x_axis
        },
        yAxis: {
          title: {
            text: 'Average score (%)'
          },
          allowDecimals: false,
          ceiling: 100
        },
        series: [
          {
            name: 'Engagement',
            color: '#FF4F7A',
            data: data.engagement_survey_series,
            visible: data.engagement_survey_series.some(value => value !== null)
          },
          {
            name: 'Mental Health',
            color: '#17193C',
            data: data.mental_health_survey_series,
            visible: data.mental_health_survey_series.some(value => value !== null)
          },
          {
            name: 'DEEI',
            color: '#F7A44B',
            data: data.diversity_survey_series,
            visible: data.diversity_survey_series.some(value => value !== null)
          }
        ],
        plotOptions: {
          series: {
            connectNulls: true,
            colorAxis: false,
            events: {
              legendItemClick: function(e) {
                if (!this.yData.some(value => value !== null)) {
                  e.preventDefault();
                }
              }
            }
          }
        },
        tooltip: {
          useHTML: true,
          formatter: function() {
            const identifier = (this.series.userOptions.name + '-' + this.key);
            const descriptionData = data.point_descriptions[identifier];
            if (descriptionData) {
              return (
                `<strong>${descriptionData.survey_name}</strong><br />${this.y}% - ${descriptionData.survey_date}<br />${descriptionData.comment}` // eslint-disable-line max-len
              );
            }
          }
        },
        colorAxis: {
          visible: false
        },
        credits: false,
        exporting: exporting,
        legend: {
          useHTML: true,
          labelFormatter: function() {
            const className = this.yData.some(value => value !== null) ? 'chart-label' : 'chart-label-disabled';
            return `<span class=${className}>${this.name}</span>`;
          }
        },
      },
      (isDashboardWidget ? dashboardWidgetConfig : {})
    )
  );
}
