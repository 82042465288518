import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars

import { QUESTION_TYPE_DISPLAY, QUESTION_TYPES } from '^/components/custom-questions/constants';


export default class AvailableItem extends React.PureComponent {

  constructor(props) {
    super(props);
    this.addItem = this.addItem.bind(this);
  }

  addItem() {
    if (!this.props.disabled) {
      this.props.onClickAdd(this.props.question.get('id'));
    }
  }

  render() {
    const { question, disabled, readOnly, recommended } = this.props;

    return (
      <div
        className={classNames('question-card available', { disabled, recommended })}
        title={question.get('name')}
      >
        <div className="margin-right-large">
          <h6 className="title">{question.get('name')}</h6>
          <p className="text-help margin-none">
            {QUESTION_TYPE_DISPLAY[question.get('type')]}
            {question.get('type') === QUESTION_TYPES.SLIDER && (
              <span>
                {' '}(
                  {question.get('slider_low_score_label')}/
                  {question.get('slider_mid_score_label')}/
                  {question.get('slider_high_score_label')}
                )
              </span>
            )}
          </p>
        </div>
        {!readOnly && (
          <button
            className={classNames('question-card-icon-wrapper margin-left-auto', { disabled })}
            onClick={this.addItem}
          >
            <i className="far fa-plus" />
          </button>
        )}
      </div>
    );
  }
}
