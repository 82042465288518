import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Column, Row } from '@dabapps/roe';
import { openSimpleModal } from '^/actions/modals';
import AdditionalFiltersForm from './AdditionalFiltersForm';

export function OrganisationAdditionalFilters(props) {
  return (
    <div>
      <Row className="margin-top-xx-large margin-bottom-base">
        <Column md={12} className="font-size-14">
          <strong>Additional Filters</strong>
        </Column>
      </Row>
      {props.additionalFiltersList.map((filter) => (
        <Row className="margin-top-large margin-bottom-base" key={filter.id}>
          <Column xs={4} md={2}>
            {filter.name}
          </Column>
          <Column xs={4} md={2}>
            {filter.active ? 'active' : 'inactive'}
          </Column>
          <Column xs={4} md={2}>
            <button
              className="btn middle blue margin-right-base"
              onClick={() => props.openEditOptionsModal(filter)}
            >
              Edit
            </button>
          </Column>
        </Row>
      ))}
      <Row className="margin-top-xx-large margin-bottom-base">
        <Column md={12} className="font-size-14">
          <button
            className="btn middle blue margin-right-base"
            onClick={props.openCreateCustomFilterModal}
            disabled={props.additionalFiltersList.length >= 7}
          >
            Add custom filter
          </button>
        </Column>
      </Row>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    additionalFiltersList: state.additionalFiltersList
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    openEditOptionsModal: (filter) =>
      dispatch(
        openSimpleModal({
          body: <AdditionalFiltersForm
            initialValues={{ name: filter.name, active: String(filter.active) }}
            filterId={filter.id}
          />,
          title: `Edit additional filter and options`,
        })
      ),
    openCreateCustomFilterModal: () =>
      dispatch(
        openSimpleModal({
          body: <AdditionalFiltersForm initialValues={{active: 'true'}}/>,
          title: `Create custom filter`,
        })
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationAdditionalFilters);
