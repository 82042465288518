import React from 'react'; // eslint-disable-line no-unused-vars
import PureComponent from '^/components/PureComponent';
import { AdminList } from '@dabapps/chadmin';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';
import {
  addInSurveyAndUpdate,
  removeFromSurveyAndUpdate,
} from '^/actions/actionSequences';
import { getCollectionFromUrl } from '^/actions/collections';
import { setPageSize, surveySelectAll, surveyRemoveAll } from '^/actions/actions';
import AdminListPagination from '^/components/admin/AdminListPagination';
import { fetchFieldConfig, setFilter, setFilterBulk } from '^/actions/actions';
import AdminListFilter from '../../admin/AdminListFilter';
import { ALL } from '^/consts/forms';
import { DEFAULT_PAGE_SIZE } from '^/consts/inputs';
import { Column, Row, SpacedGroup } from '@dabapps/roe';
import Loading from '^/components/Loading';
import DateRangeFilter from '^/components/common/DateRangeFilter';
import moment from 'moment/moment';
import PageSize from '^/components/common/PageSize';
import { FORMAT_DATE, FORMAT_TIME } from '../../../utils';
import { canAccessAdmin } from '^/utils/permissions';

export const SUPPORT_SURVEY_LIST = 'SUPPORT_SURVEY_LIST';

const CONFIG_NAME = 'support/surveys';

export class SurveyList extends PureComponent {

  constructor() {
    super();
    this.loadPage = this.loadPage.bind(this);
    this.getOrganisationDisplay = this.getOrganisationDisplay.bind(this);
  }

  getOrganisationDisplay(survey) {
    const { shouldLinkToAdmin } = this.props;
    if (!survey.organisation) {
      return (<span></span>);
    }
    return shouldLinkToAdmin ? (
        <a target="_blank" href={`/admin/organisations/organisation/${survey.organisation.id}/`}>
          {survey.organisation.name}
        </a>
      ) :
      survey.organisation.name;
  }

  getColumns() {
    const { shouldLinkToAdmin } = this.props;
    return fromJS([
      {
        name: 'name',
        display_name: 'Name',
        type: 'custom',
        customItemHandler: survey => shouldLinkToAdmin ? (
          <a target="_blank" href={`/admin/htmat/survey/${survey.id}/`}>{survey.name}</a>
        ) : survey.name,
        sortable: true
      },
      {
        name: 'organisation',
        display_name: 'Organisation',
        type: 'custom',
        customItemHandler: this.getOrganisationDisplay,
        sortable: true
      },
      {
        name: 'state',
        display_name: 'State',
        type: 'text',
        sortable: true
      },
      {
        name: 'auto_start_date',
        display_name: 'Start Date',
        type: 'custom',
        customItemHandler: survey => survey.auto_start_date ? moment(survey.auto_start_date).format(FORMAT_DATE) : null,
        sortable: true
      },
      {
        name: 'start_time',
        display_name: 'Start Time',
        type: 'custom',
        customItemHandler: survey => survey.start_time
          ? moment(survey.start_time, 'H:mm:ss').format(FORMAT_TIME)
          : null,
        sortable: true
      },
      {
        name: 'auto_close_date',
        display_name: 'End Date',
        type: 'custom',
        customItemHandler: survey =>
          survey.date_closed_or_will_close ?
            moment(survey.date_closed_or_will_close).format(FORMAT_DATE) :
            null,
        sortable: true
      },
      {
        name: 'sent_count',
        display_name: 'Sent',
        type: 'text',
        sortable: true
      },
      {
        name: 'completion_percent',
        display_name: 'Completed %',
        type: 'text',
        sortable: true
      },
    ]);
  }

  getFilters(overrideFilterSpec) {
    const filterSpec = overrideFilterSpec || this.props.filterSpec;
    return filterSpec.map((filterValue) => {
      const filter = filterValue !== ALL ? filterValue : null;
      return (filter instanceof moment) ? filter.format('YYYY-MM-DD') : filter;
    }).toJS();
  }

  loadCollection(pageSize) {
    this.props.getCollectionFromUrl(
      `/support/surveys/`,
      {pageSize: pageSize || this.props.pageSize, filters: this.getFilters()},
      SUPPORT_SURVEY_LIST
    );

  }

  componentWillReceiveProps(newProps) {
    if (newProps.filterSpec !== this.props.filterSpec) {
      this.loadPage(this.page, newProps.filterSpec);
    }
  }

  componentWillMount() {
    this.props.setFilterBulk(SUPPORT_SURVEY_LIST, this.props.queryParams.toJS());

    if (this.props.queryParams.isEmpty()) {
      this.loadCollection();
    }

    this.props.fetchFieldConfig(CONFIG_NAME);
  }

  loadPage(page, overrideFilterSpec = null) {
    this.props.getCollectionFromUrl(
      `/support/surveys/`,
      {page, pageSize: this.props.pageSize, filters: this.getFilters(overrideFilterSpec)},
      SUPPORT_SURVEY_LIST
    );
  }

  setPageSize(pageSize) {
    this.props.setPageSize(SUPPORT_SURVEY_LIST, pageSize);
    this.loadCollection(pageSize);
  }

  filterCollection(filterKey, filterValue) {
    this.props.setFilter(SUPPORT_SURVEY_LIST, filterKey, filterValue);
  }

  render() {
    if (!this.props.filters) {
      return <Loading />;
    }

    const filters = this.props.filters;
    const columns = this.getColumns();

    return (
      <div className="admin-container-content">
        <div className="admin-list-container">

          <Row className="admin-list-controls">
            <Column className="admin-list-survey-date-filter" xs={4}>
              <DateRangeFilter
                filterName="Date Opened"
                fromKey="date_opened__gte"
                toKey="date_opened__lte"
                fromValue={this.props.filterSpec.get('date_opened__gte')}
                toValue={this.props.filterSpec.get('date_opened__lte')}
                onChange={this.filterCollection.bind(this)}
              />
            </Column>
            <Column className="survey-team-member-filters" xs={8}>
              <SpacedGroup className="float-right">
                {filters.map(filter => (
                  <AdminListFilter
                    key={filter.get('filter_key') + '_filter'}
                    onChange={(filterKey, filterValue) => this.filterCollection(filterKey, filterValue)}
                    keyName={filter.get('filter_key') + '_filter'}
                    filter={filter}
                  />
                ))}
              </SpacedGroup>
            </Column>
          </Row>

          <Row className="admin-list-controls">
            <Column>
              <SpacedGroup className="float-right">
                <div className="stm-filter">
                  <PageSize
                    pluralItemName="Surveys"
                    pageSize={this.props.pageSize}
                    onChange={(newPageSize) => this.setPageSize(newPageSize)}
                  />
                </div>
              </SpacedGroup>
            </Column>
          </Row>

          <AdminList
            items={this.props.organisations.toJS()}
            columns={columns.toJS()}
            listName={SUPPORT_SURVEY_LIST}
            itemCount={this.props.organisationCount}
            filters={this.props.filters}
          />
          <AdminListPagination
            collection={this.props.organisationCollection}
            loadPage={this.loadPage}
            pageSize={this.props.pageSize}/>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    organisationCollection: state.collections.get(SUPPORT_SURVEY_LIST, Map()),
    organisations: state.collections.getIn([SUPPORT_SURVEY_LIST, 'items'], List()),
    organisationCount: state.collections.getIn([SUPPORT_SURVEY_LIST, 'count'], 0),
    pageSize: state.ui.getIn(['pageSize', SUPPORT_SURVEY_LIST]) || DEFAULT_PAGE_SIZE,
    filters: state.fieldConfigs.getIn([CONFIG_NAME, 'filters'], List()),
    filterSpec: state.filterSpec.get(SUPPORT_SURVEY_LIST, Map()),
    shouldLinkToAdmin: canAccessAdmin(state.loggedInUser),
  };
}

export default connect(
  mapStateToProps,
  {
    getCollectionFromUrl,
    addInSurveyAndUpdate,
    removeFromSurveyAndUpdate,
    setPageSize,
    surveySelectAll,
    surveyRemoveAll,
    fetchFieldConfig,
    setFilter,
    setFilterBulk
  }
)(SurveyList);
