import moment from 'moment';
import * as React from 'react';  // eslint-disable-line no-unused-vars
import { FORMAT_DATE } from '../../utils';
import ActionTypeIcon from '^/components/actions/ActionTypeIcon';
import CellContentOrEmpty from '^/components/common/CellContentOrEmpty';
import PrivateIcon from '^/components/common/PrivateIcon';
import { ACTION_STATUS_MAPPING } from '^/consts/actions';


export const actionsListConfig = [
  {
    name: 'title',
    display_name: 'Title/Owner',
    type: 'custom',
    customItemHandler: (action) => (
      <span>
        <span className="margin-none text-overflow-ellipsis display-block">
          <a href={`/goals/actions/${action.id}/`}>{action.title}</a>
        </span>
        <p className="margin-none text-gray">
          <ActionTypeIcon actionType={action.type} /> • {action.owner.label}{' '}
          {action.is_confidential && (
            <PrivateIcon itemName="action" />
          )}
        </p>
      </span>
    ),
    sortable: false,
  },
  {
    name: 'goal__name',
    display_name: 'Goal',
    type: 'custom',
    customItemHandler: (action) => (
      <CellContentOrEmpty condition={action.goal && action.goal.name}>
        {action.goal && action.goal.name}
      </CellContentOrEmpty>
    ),
    sortable: true,
  },
  {
    name: 'area',
    display_name: 'Area',
    type: 'custom',
    customItemHandler: (action) => (
      <span>
        {action.area}
      </span>
    ),
    sortable: false,
  },
  {
    name: 'filters',
    display_name: 'For',
    type: 'custom',
    customItemHandler: (action) => (
      <span>
        {action.filters}
      </span>
    ),
    sortable: false,
  },
  {
    name: 'created_date',
    display_name: 'Created',
    type: 'custom',
    customItemHandler: (action) => (
      <span>
        {moment(action.created_date).format(FORMAT_DATE)}
      </span>
    ),
    sortable: true,
  },
  {
    name: 'due_date',
    display_name: 'Due Date',
    type: 'custom',
    customItemHandler: (action) => (
      <span>
        {moment(action.due_date).format(FORMAT_DATE)}
      </span>
    ),
    sortable: true,
  },
  {
    name: 'status',
    display_name: 'Status',
    type: 'custom',
    customItemHandler: (action) => (
      <span>
        {ACTION_STATUS_MAPPING.get(action.status)}
      </span>
    ),
    sortable: true,
  },
];
