import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import PureComponent from '../PureComponent';


export default class AsyncCheckbox extends PureComponent {
  render() {
    return (
      <div className={classNames('async-checkbox', this.props.readOnly ? 'read-only' : '', this.props.className)}>
        {
          this.props.readOnly ? (
            <i
              title="1"
              className={classNames('far', this.props.checked ? 'fa-check ico-check' : 'fa-times ico-times')}
            />
          ) : (
            <input
              id={this.props.id}
              onChange={() => !this.props.disabled && this.props.handleClick(this.props.id, !this.props.checked)}
              type="checkbox"
              checked={this.props.checked}
              disabled={this.props.disabled}
            />
          )
        }
        <label
          className="label"
          htmlFor={this.props.readOnly ? null : this.props.id}
        >
          {this.props.label}
        </label>
      </div>
    );
  }
}

AsyncCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleClick: PropTypes.func
};
