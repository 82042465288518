import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { getCollectionFromUrl } from '^/actions/collections';
import { hasFailed, isPending } from '^/consts/responseStates';
import { formatDateTime, FORMAT_DATE } from '^/utils';
import { HOMEPAGE_SURVEYS_LIST } from '^/components/dashboards/constants';
import SurveysList from '^/components/dashboards/SurveysList';

const MAX_DISPLAYED_SURVEYS = 3;

export class SurveysWidgetIndividual extends React.PureComponent {
  componentDidMount() {
    this.props.getCollectionFromUrl(
      `/survey/homepage-surveys-list/`,
      {},
      HOMEPAGE_SURVEYS_LIST
    );
  }

  render() {
    const { surveys, isLoading, hasFailedLoading } = this.props;
    return (
      <SurveysList
        hasFailedLoading={hasFailedLoading}
        isLoading={isLoading}
        surveys={surveys}
        emptyText={
          `Once you have taken part in a survey, access your own results here.`
        }
        surveyButton={
          <a
            className="widget-view-all-button"
            href="/surveys/my-surveys/"
          >
            View all
          </a>
        }
      />
    );
  }
}

export function transformSurveys(surveys) {
  return surveys.map(
    survey => survey
    .set('auto_start_date', formatDateTime(survey.get('auto_start_date'), '', FORMAT_DATE))
    .set('date_closed_or_will_close', formatDateTime(survey.get('date_closed_or_will_close'), '', FORMAT_DATE))
  );
}

export function mapStateToProps(state) {
  return {
    surveys: transformSurveys(
      state.collections.getIn([HOMEPAGE_SURVEYS_LIST, 'items'], List())
    ).slice(
      0,
      MAX_DISPLAYED_SURVEYS
    ),
    isLoading: isPending(state.legacyResponses.getIn(['getCollection', HOMEPAGE_SURVEYS_LIST]), true),
    hasFailedLoading: hasFailed(state.legacyResponses.getIn(['getCollection', HOMEPAGE_SURVEYS_LIST]), false),
  };
}

export default connect(mapStateToProps, {
  getCollectionFromUrl
})(SurveysWidgetIndividual);
