import React from 'react'; // eslint-disable-line no-unused-vars
import CollapsableWell from '../common/CollapsibleWell';
import AddQuestionToAgendaCard from './AddQuestionToAgendaCard';
import { connect } from 'react-redux';
import { openSimpleModal } from '^/actions/modals';
import AgendaPerformanceQuestionsModal from './AgendaPerformanceQuestionsModal';

export const AgendaPerformanceReviewSelectItems = ({
  agendaId,
  latestPerformanceReviewName,
  latestPerformanceReviewComplete,
  openModal,
  isDisabled,
  isAgendaEmployee,
  selectableItems,
  performanceReviewTerm
}) => {
  const performanceReviewTermLowerCased = performanceReviewTerm && performanceReviewTerm.toLowerCase();
  const noPreviousReview = !(latestPerformanceReviewName && latestPerformanceReviewComplete);

  let noPrevReviewMsg = `This employee has no previous ${performanceReviewTermLowerCased} responses.`;
  if (isAgendaEmployee) {
    noPrevReviewMsg = `You have no previous ${performanceReviewTermLowerCased} responses.`;
  }
  const title = `Pre-${performanceReviewTermLowerCased} responses`;
  return (
    <CollapsableWell
      title={title}
      isOrange
      isCollapsed
    >
      {
        noPreviousReview ? <p className="margin-top-x-large fwb font-size-14">{noPrevReviewMsg}</p> :
        <div>
          <p className="margin-top-x-large font-weight-bold font-size-14">
            Most recent {performanceReviewTerm}
          </p>
          <div className="display-flex">
            <p className="fwb font-size-14 margin-right-large">{latestPerformanceReviewName}</p>
            <p className="fwb font-size-14 margin-left-auto">{latestPerformanceReviewComplete}</p>
          </div>
        </div>
      }
      <p
        className="fwb underline cursor-pointer fit-content font-size-14"
        onClick={openModal}
      >
        Full question list
      </p>
      {selectableItems.length ? selectableItems.map((item, idx) => {
        if (idx < 4) {
          return (
            <AddQuestionToAgendaCard
              key={item.question_id}
              agendaId={agendaId}
              item={item}
              isDisabled={isDisabled}
            />
          );
        }
      })
      : <p>No questions available.</p>}
    </CollapsableWell>
  );
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    openModal: () => {
      dispatch(
        openSimpleModal({
          body: (
            <AgendaPerformanceQuestionsModal
              agendaId={ownProps.agendaId}
              items={ownProps.items}
              isDisabled={ownProps.isDisabled}
            />
          ),
          title: `${ownProps.performanceReviewTerm} questions`
        })
      );
    },
  };
}

function mapStateToProps(state, props) {
  return {
    selectableItems: props.items.filter(option =>
      !state.editingAgenda.review_questions.find(reviewQuestion => (
        reviewQuestion.question_id === option.question_id &&
        reviewQuestion.answer_id === option.answer_id
      ))
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaPerformanceReviewSelectItems);
