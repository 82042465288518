import React from 'react';  // eslint-disable-line
import PureComponent from '^/components/PureComponent';
import { connect } from 'react-redux';
import { toggleShowCompareAnalysis } from '../../actions/actions';
import URI from 'urijs';
import { fromJS } from 'immutable';

const COMPARE_TO_QUERY_PARAM = 'compare_to';

export class AnalysisCompare extends PureComponent {
  constructor(props) {
    super(props);
    this.onChangeSurveyCompareDropdown = this.onChangeSurveyCompareDropdown.bind(this);
  }

  onChangeSurveyCompareDropdown(event) {
    const uri = new URI(window.location.href);
    const currentQueryParams = fromJS(uri.search(true));
    const updatedQueryParams = currentQueryParams.set(COMPARE_TO_QUERY_PARAM, event.target.value);

    uri.search(updatedQueryParams.toJS());
    window.location.assign(uri.resource());
  }

  render() {
    const { surveysBeforeCurrent } = this.props;

    return (
      <div>
        {this.props.showCompareAnalysis && (
          <div>
            <label htmlFor="survey-id">Select previous survey for comparison</label>
              <select name="survey-id" id="survey-id" onChange={this.onChangeSurveyCompareDropdown}>
                <option disabled selected value></option>
                {surveysBeforeCurrent.map(survey => (
                  <option value={survey.get('id')}>{survey.get('label')}</option>
                ))}
              </select>
          </div>
        )}

        <span className="open-filter-group-analysis">
          {!surveysBeforeCurrent.isEmpty() && (
            <button
              className="btn small orange"
              onClick={this.props.toggleShowCompareAnalysis}
            >
              Compare to previous survey
            </button>
          )}
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showCompareAnalysis: state.ui.get('showCompareAnalysis')
  };
}

export default connect(mapStateToProps, {
  toggleShowCompareAnalysis
})(AnalysisCompare);
