import { fromJS, List, Map, Set } from 'immutable';
import _ from 'underscore';

import {
  GET_CUSTOM_QUESTION_SETTINGS,
  UPDATE_CUSTOM_QUESTION_SETTINGS,
  CREATE_CUSTOM_QUESTION,
} from '^/actions/actions';
import {
  UPDATE_CUSTOM_QUESTION_OPTIONS,
  UPDATE_CUSTOM_QUESTION_OPTION,
  REMOVE_CUSTOM_QUESTION_OPTION,
  ADD_CUSTOM_QUESTION_OPTION,
  GET_CUSTOM_QUESTION_FOR_EDIT
} from '^/components/custom-questions/actions';
import { CUSTOM_QUESTION_SETTINGS } from '^/components/survey/custom-questions/constants';
import {
  COPY_CUSTOM_QUESTION_INTO_SURVEY,
  DELETE_SURVEY_CUSTOM_QUESTION,
  SET_CUSTOM_QUESTION_POSITION
} from '^/components/survey/custom-questions/question-picker/actions';

export function customQuestionForEdit(state = null, action) {
  switch (action.type) {
    case GET_CUSTOM_QUESTION_FOR_EDIT.SUCCESS:
      return action.payload.data;
    case GET_CUSTOM_QUESTION_FOR_EDIT.REQUEST:
    case GET_CUSTOM_QUESTION_FOR_EDIT.FAILURE:
      return null;
    default:
      return state;
  }
}

export function surveyCustomQuestionSettings(state = Map(), action) {
  function stopCopyingQuestion(currentState, questionId) {
    return currentState
      .set(
        CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_COPYING,
        currentState.get(CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_COPYING, Set()).delete(questionId)
      );
  }

  function startCopyingQuestion(currentState, questionId) {
    return currentState
      .set(
        CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_COPYING,
        currentState.get(CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_COPYING, Set()).add(questionId)
      );
  }

  function startDeletingQuestion(currentState, questionId) {
    return currentState
      .set(
        CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_DELETING,
        currentState.get(CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_DELETING, Set()).add(questionId)
      );
  }

  function stopDeletingQuestion(currentState, questionId) {
    return currentState
      .set(
        CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_DELETING,
        currentState.get(CUSTOM_QUESTION_SETTINGS.QUESTION_IDS_CURRENTLY_DELETING, Set()).delete(questionId)
      );
  }

  switch (action.type) {
    case GET_CUSTOM_QUESTION_SETTINGS.SUCCESS:
      return fromJS(action.payload);
    case UPDATE_CUSTOM_QUESTION_SETTINGS.SUCCESS: {
      const newSettings = fromJS(action.payload);
      const oldQuestionData = state.get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, List());
      const newQuestionData = newSettings.get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, List());
      const questionData = List(
        _.uniq(oldQuestionData.concat(newQuestionData).toArray(),
          false,
          question => question.get('id'))
      );
      return newSettings.set(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, questionData);

    }
    case CREATE_CUSTOM_QUESTION.SUCCESS: {
      const questionData = state
        .get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, List())
        .push(Map(action.payload));

      return state.merge(
        Map({
          [CUSTOM_QUESTION_SETTINGS.QUESTION_DATA]: questionData
        })
      );
    }
    case COPY_CUSTOM_QUESTION_INTO_SURVEY.REQUEST:
      return startCopyingQuestion(state, action.meta.questionBeingCopied.get('id'));
    case COPY_CUSTOM_QUESTION_INTO_SURVEY.SUCCESS:
      const serverSideQuestionCopyId = action.payload.data.id;

      const questionsData = state.get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, List());
      const copyOfOriginalQuestion = action.meta.questionBeingCopied
        .set('original_question_id', action.meta.questionBeingCopied.get('id'))
        .set('id', serverSideQuestionCopyId);
      const newQuestionsData = questionsData.push(copyOfOriginalQuestion);

      return stopCopyingQuestion(state, action.meta.questionBeingCopied.get('id'))
        .set(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, newQuestionsData);
    case COPY_CUSTOM_QUESTION_INTO_SURVEY.FAILURE:
      return stopCopyingQuestion(state, action.meta.questionBeingCopied.get('id'));

    case DELETE_SURVEY_CUSTOM_QUESTION.REQUEST:
      return startDeletingQuestion(state, action.meta.deleteQuestionId);
    case DELETE_SURVEY_CUSTOM_QUESTION.SUCCESS:
      const questionIndex = state
        .get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA)
        .findIndex(question => question.get('id') === action.meta.deleteQuestionId);
      return stopDeletingQuestion(state, action.meta.deleteQuestionId)
        .deleteIn([CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, questionIndex]);
    case DELETE_SURVEY_CUSTOM_QUESTION.FAILURE:
      return stopDeletingQuestion(state, action.meta.deleteQuestionId);
    case SET_CUSTOM_QUESTION_POSITION.FAILURE:
      return state
        .set(CUSTOM_QUESTION_SETTINGS.QUESTION_REORDERING_IN_PROGRESS, false);
    case SET_CUSTOM_QUESTION_POSITION.REQUEST:
      return state
        .set(CUSTOM_QUESTION_SETTINGS.QUESTION_REORDERING_IN_PROGRESS, true);
    case SET_CUSTOM_QUESTION_POSITION.SUCCESS:
      const questionToMoveIndex = state
        .get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA)
        .findIndex((question) => question.get('id') === action.meta.questionId);

      const reorderedQuestions = state
        .get(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA)
        .setIn([questionToMoveIndex, 'position'], action.meta.newPosition)
        .setIn([action.meta.newPosition, 'position'], questionToMoveIndex)
        .sortBy((question) => question.get('position'));

      return state
        .set(CUSTOM_QUESTION_SETTINGS.QUESTION_REORDERING_IN_PROGRESS, false)
        .set(CUSTOM_QUESTION_SETTINGS.QUESTION_DATA, reorderedQuestions);
    default:
      return state;
  }
}

export function customQuestionOptions(state = [], action) {
  switch (action.type) {
    case UPDATE_CUSTOM_QUESTION_OPTIONS:
      return action.options;
    case UPDATE_CUSTOM_QUESTION_OPTION:
      return fromJS(state).set(action.index, action.optionText).toJS();
    case REMOVE_CUSTOM_QUESTION_OPTION:
      if (state.length > action.minimumNumberOfOptions) {
        return state.filter((_optionText, index) => index !== action.index);
      }
      return state;
    case ADD_CUSTOM_QUESTION_OPTION:
      return state.concat(['']);
    default:
      return state;
  }
}
