import * as React from 'react';  // eslint-disable-line no-unused-vars
import classNames from 'classnames';
import { anyPending } from '@dabapps/redux-requests';
import { connect } from 'react-redux';
import Loading from '^/components/Loading';
import { Column, Row } from '@dabapps/roe';
import { ReadOnlyAgendaField } from './ReadOnlyAgendaField';
import { ModalRenderer } from '@dabapps/roe';
import { openSimpleModal } from '^/actions/modals';
import DeleteAgendaModal from './DeleteAgendaModal';
import { getAgendaReadOnly, GET_AGENDA_READ_ONLY, } from '^/components/agendas/actions';
import { getIsAgendaEmployee } from '^/components/agendas/utils';
import { AgendaSectionType } from './constants';
import { OKR_TYPE, OKR_TYPE_DISPLAY } from '../okrs/constants';
import { OkrStatus } from '../okrs/OkrStatus';
import PeerFeedbackModal from './PeerFeedbackModal';

const CommentsSection = ({comments}) => (
  <div className="agenda-read-only-field">
    <h5 className="font-size-14 font-weight-bold">Comments</h5>
    {comments.length ? (
      <ul>
        {comments.map((agendaItem) => (
          <li
            key={agendaItem.id}
            className="agenda-read-only-custom-item"
          >
            {agendaItem.text}
          </li>
        ))}
      </ul>
    ) : (
      <p className="white-space-pre-line">No comments.</p>
    )}
  </div>
);

const MainAgendaSection = ({
  agenda
}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18 fwb">
        1:1 Agenda
      </h4>
      <ReadOnlyAgendaField title="Title" value={agenda.title} />
      <ReadOnlyAgendaField title="Description" value={agenda.description} />
      <ReadOnlyAgendaField title="Employee" value={agenda.employee.label} />
      <ReadOnlyAgendaField title="Date" value={agenda.date} />
      <ReadOnlyAgendaField title="Time" value={agenda.time} />
    </div>
  );
};

const SharedNotesSection = ({sharedNotes}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18">
        Shared notes
      </h4>
      <p className="white-space-pre-line">{sharedNotes ? sharedNotes : 'No shared notes.'}</p>
    </div>
  );
};

const EmployeeGoalsSection = ({
  agenda
}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18">
        Employee goals
      </h4>
      {agenda.goals.map((agendaGoal) => {
        return (
          <div key={agendaGoal.id} className="agenda-read-only-goal">
            <a href={agendaGoal.goal.url}>{agendaGoal.goal.name}</a>
            <p>{agendaGoal.goal.percentage_complete}%</p>
          </div>
        );
      })}
      <CommentsSection comments={agenda.employee_goal_comments} />
    </div>
  );
};

const EngagementSurveySection = ({
  agenda
}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18">
        Engagement survey
      </h4>
      {agenda.engagement_survey_questions.map((question) => {
        return (
          <div key={question.question_text} className="agenda-read-only-goal">
            <h5 className="font-size-14 fwb margin-bottom-none">{question.question_text}</h5>
            {question.score && (
              <p>{question.score}%</p>
            )}
          </div>
        );
      })}
      <CommentsSection comments={agenda.engagement_survey_comments} />
    </div>
  );
};

const PerformanceReviewSection = ({
  agenda,
  performanceReviewTerm
}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18">
        {performanceReviewTerm}
      </h4>
      {agenda.review_questions.map((item) => {
        return (
          <div key={item.question_id} className="agenda-read-only-question">
            <h5 className="font-size-14 fwb margin-bottom-none">{item.question_text}</h5>
            {item.responses.map((response, idx) => (
              <p
                key={`${item.question_id}-${response}`}
                className={idx === 0 ? 'padding-top-base' : null}
              >
                {response}
              </p>
            ))}
          </div>
        );
      })}
      <CommentsSection comments={agenda.performance_review_comments} />
    </div>
  );
};

const OKRsSection = ({
  agenda
}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18">
        OKRs
      </h4>
      {agenda.okrs.map((okr) => {
        return (
          <div key={okr.id} className="agenda-read-only-okr">
            <div className="agenda-okr-title-type margin-bottom-base">
              <a href={`/okrs/${okr.okr_id}/`}>{okr.okr_name}</a>
              <span className="agenda-items-card-score okr-type-card flex-shrink-0">
                <i className={
                  classNames(
                    'far font-size-18 margin-right-base color-gray-dark',
                    {
                      'fa-globe': okr.okr_type === OKR_TYPE.COMPANY,
                      'fa-building': okr.okr_type === OKR_TYPE.MANAGER,
                      'fa-user': okr.okr_type === OKR_TYPE.EMPLOYEE
                    }
                  )
                }></i>
                {OKR_TYPE_DISPLAY[okr.okr_type]} OKR
              </span>
            </div>
            <div className="agenda-items-card-content">
              <OkrStatus status={okr.okr_status} completedPercentage={okr.okr_completed_percentage} />
            </div>
          </div>
        );
      })}
      <CommentsSection comments={agenda.okr_comments} />
    </div>
  );
};

const CustomAgendaItemsSection = ({
  agenda
}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18">
        Custom items / talking points
      </h4>
      <ul>
        {agenda.custom_items.map((agendaItem) => (
          <li
            key={agendaItem.id}
            className="agenda-read-only-custom-item"
          >
            {agendaItem.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ManagerGoalsSection = ({
  agenda
}) => {
  return (
    <div className="agenda-manager-only-read-only-subsection">
      <h4 className="font-size-14">
        Manager goals
      </h4>
      {agenda.manager_goals.map((goal, idx) => {
        let className = 'margin-top-large margin-bottom-xx-large';
        if (idx === agenda.manager_goals.length - 1) {
          className = 'margin-top-large margin-bottom-base';
        }
        return (
          <div key={`manager-${goal.id}`} className={className}>
            <h5 className="font-size-12 font-weight-bold margin-bottom-base">{goal.name}</h5>
            <p className="margin-top-small margin-bottom-large">{goal.description}</p>
            <a href={`/goals/${goal.id}/`}>
              with {goal.actions_count} {goal.actions_count === 1 ? 'action' : 'actions'}
            </a>
          </div>
        );
      })}
    </div>
  );
};

const TalentSnapshotSection = ({
  agenda
}) => {
  return (
    <div className="agenda-manager-only-read-only-subsection">
      <h4 className="font-size-14">
        Talent snapshot
      </h4>
      {agenda.talent_snapshot.map((response, idx) => {
        let className = 'margin-top-large margin-bottom-xx-large';
        if (idx === agenda.talent_snapshot.length - 1) {
          className = 'margin-top-large margin-bottom-base';
        }
        return (
          <div key={response.id} className={className}>
            <h5 className="font-size-12 font-weight-bold margin-bottom-base">{response.question}</h5>
            <p className="margin-top-small margin-bottom-large">{response.answer}</p>
          </div>
        );
      })}
    </div>
  );
};

const PeerFeedbackItem = ({
  item,
  openModal,
}) => {
  return (
    <div key={item.question_id} className="agenda-read-only-question">
      <div className="display-flex flex-space-between align-items-center">
        <h5 className="font-size-16 fwb margin-bottom-base">{item.feedback_title}</h5>
        {!item.feedback_visible_to_user && (
          <div
            className="font-size-28 text-orange flex-shrink-0"
            title="This feedback is not visible to the employee, even when added to the agenda."
          >
            <i className="far fa-lock"></i>
          </div>
        )}
      </div>
      <p className="font-size-14 fwb margin-bottom-base">
        {`Feedback from: ${item.feedback_created_by}`}
      </p>
      <p className="multi-line-ellipsis font-size-14 margin-bottom-base">
        {item.feedback_message}
      </p>
      <div className="flex-space-between">
        <p
          className="underline bold margin-none font-size-14 cursor-pointer"
          onClick={openModal}
        >
          Read in full
        </p>
        <p className="font-size-14 fwb">{item.feedback_created}</p>
      </div>
    </div>
  );
};

const peerFeedbackMapDispatchToProps = (dispatch, ownProps) => {
  return {
    openModal: () => {
      dispatch(
        openSimpleModal({
          body: (
            <PeerFeedbackModal
              agendaId={ownProps.agendaId}
              item={ownProps.item}
              isReadOnly
            />
          ),
          title: 'Peer feedback'
        })
      );
    }
  };
};

const ConnectedPeerFeedbackItem = connect(undefined, peerFeedbackMapDispatchToProps)(PeerFeedbackItem);

const PeerFeedbackSection = ({
  agenda,
}) => {
  return (
    <div className="section-agenda-read-only">
      <h4 className="font-size-18">
        Peer feedback
      </h4>
      {agenda.peer_feedback.map((item) => (
        <ConnectedPeerFeedbackItem item={item} />
      ))}
      <CommentsSection comments={agenda.peer_feedback_comments} />
    </div>
  );
};

const ManagerOnlySection = ({
  agenda
}) => {
  return (
    <div className="agenda-manager-only-read-only">
      <div className="agenda-manager-only-read-only-subsection">
        <h4 className="font-size-18">
            Manager only (hidden to employee)
        </h4>
        {agenda.manager_notes && (
          <ReadOnlyAgendaField
            title="Manager notes"
            value={agenda.manager_notes}
          />
        )}
      </div>
      {!!agenda.manager_goals.length && <ManagerGoalsSection agenda={agenda} />}
      {!!agenda.talent_snapshot.length && <TalentSnapshotSection agenda={agenda} />}
    </div>
  );
};

const ButtonSection = ({openDeleteModal, isAgendaEmployee}) => {
  return (
    <div className="background-gray-darker section-create-agenda-padding display-flex agenda-read-only-buttons">
      <button
        className="btn middle orange"
        onClick={() => window.location.href = '/agendas/list/'}
      >
        Return to agendas
      </button>
      {!isAgendaEmployee && <button
        className="delete-btn middle margin-left-x-large"
        onClick={openDeleteModal}
      >
        Delete agenda
      </button>}
    </div>
  );
};

export class ReadOnlyAgenda extends React.PureComponent {

  componentDidMount() {
    this.props.getAgendaReadOnly();
  }

  render() {
    const {
      isLoadingAgenda,
      agenda,
      isAgendaEmployee,
      openDeleteModal,
      modals,
      performanceReviewTerm
    } = this.props;
    return (
      <div>
        {isLoadingAgenda || !agenda ? (
          <Loading />
        ) : (
          <Row className="margin-bottom-x-large">
            <Column lg={12}>
              <MainAgendaSection agenda={agenda} />
              {agenda.sections.map((section_type) => {
                if (section_type === AgendaSectionType.CUSTOM_ITEMS) {
                  return (
                    !!agenda.custom_items.length && <CustomAgendaItemsSection key={section_type} agenda={agenda} />
                  );
                } else if (section_type === AgendaSectionType.SHARED_NOTES) {
                  return (
                    agenda.shared_notes && <SharedNotesSection key={section_type} sharedNotes={agenda.shared_notes} />
                  );
                } else if (section_type === AgendaSectionType.PERFORMANCE_REVIEW) {
                  return (
                    !!(agenda.review_questions.length || agenda.performance_review_comments.length) &&
                      <PerformanceReviewSection
                        key={section_type}
                        agenda={agenda}
                        performanceReviewTerm={performanceReviewTerm}
                      />
                  );
                } else if (section_type === AgendaSectionType.ENGAGEMENT_SURVEY) {
                  return (
                    !!(agenda.engagement_survey_questions.length || agenda.engagement_survey_comments.length) &&
                      <EngagementSurveySection key={section_type} agenda={agenda} />
                  );
                } else if (section_type === AgendaSectionType.EMPLOYEE_GOALS) {
                  return (
                    !!(agenda.goals.length || agenda.employee_goal_comments.length) &&
                      <EmployeeGoalsSection key={section_type} agenda={agenda} />
                  );
                } else if (section_type === AgendaSectionType.OKRS) {
                  return (
                    !!(agenda.okrs.length || agenda.okr_comments.length) &&
                      <OKRsSection key={section_type} agenda={agenda} />
                  );
                } else if (section_type === AgendaSectionType.PEER_FEEDBACK) {
                  return (
                    !!(agenda.peer_feedback.length || agenda.peer_feedback_comments.length) &&
                      <PeerFeedbackSection key={section_type} agenda={agenda} />
                  );
                }
              })}
              {(!isAgendaEmployee &&
              (agenda.manager_notes || !!agenda.manager_goals.length || !!agenda.talent_snapshot.length)) &&
                <ManagerOnlySection agenda={agenda} />
              }
              <ButtonSection openDeleteModal={openDeleteModal} isAgendaEmployee={isAgendaEmployee} />
            </Column>
          </Row>
        )}
        <ModalRenderer modals={modals} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isAgendaEmployee = getIsAgendaEmployee(state);
  return {
    isAgendaEmployee,
    agenda: state.editingAgenda,
    isLoadingAgenda: anyPending(state.responses, [GET_AGENDA_READ_ONLY]),
    modals: state.modals,
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getAgendaReadOnly: () => dispatch(getAgendaReadOnly(ownProps.agendaId)),
    openDeleteModal: () => {
      dispatch(
        openSimpleModal({
          body: <DeleteAgendaModal agendaId={ownProps.agendaId} />,
          title: 'Delete agenda',
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadOnlyAgenda);
