import * as React from 'react';
import { getCollection } from '^/actions/collections';
import { connect } from 'react-redux';
import { CUSTOM_QUESTIONS_LIST } from './constants';
import { customQuestionsConfig } from './config';
import { List, Map } from 'immutable';
import { hasFailed, isPending } from '^/consts/responseStates';
import { AdminList } from '@dabapps/chadmin';
import AdminListPagination from '^/components/admin/AdminListPagination';
import { DEFAULT_PAGE_SIZE } from '^/components/common/PageSize';

export class CustomQuestionsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.loadPage = this.loadPage.bind(this);
  }

  componentDidMount() {
    this.loadPage(1);
  }

  loadPage(page) {
    this.props.getCollection(
      'custom-questions',
      { page },
      CUSTOM_QUESTIONS_LIST
    );
  }

  render() {
    return (
      <div className="admin-container-content">
        <div className="admin-list-container">
          <AdminList
            items={this.props.questions.toJS()}
            columns={customQuestionsConfig}
            listName={CUSTOM_QUESTIONS_LIST}
            itemCount={this.props.questionsCount}
          />
          <AdminListPagination
            collection={this.props.questionsCollection}
            loadPage={this.loadPage}
            pageSize={parseInt(DEFAULT_PAGE_SIZE, 10)}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    questionsCollection: state.collections.get(CUSTOM_QUESTIONS_LIST, Map()),
    questions: state.collections.getIn(
      [CUSTOM_QUESTIONS_LIST, 'items'],
      List()
    ),
    isLoading: isPending(
      state.legacyResponses.getIn(['getCollection', CUSTOM_QUESTIONS_LIST])
    ),
    hasFailed: hasFailed(
      state.legacyResponses.getIn(['getCollection', CUSTOM_QUESTIONS_LIST])
    ),
    questionsCount: state.collections.getIn(
      [CUSTOM_QUESTIONS_LIST, 'count'],
      0
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(CustomQuestionsList);
