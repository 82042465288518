import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isPending } from '@dabapps/redux-requests';

import { stripFalsyValuesFromObject } from '^/utils';
import FilterSelect from '^/components/common/FilterSelect';
import { getAvailableFilters, GET_AVAILABLE_FILTERS, setAvailableFilters } from '^/components/settings/actions';

export class CustomQuestionExportFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateFilters = this.updateFilters.bind(this);
    this.formatAdditionalFilters = this.formatAdditionalFilters.bind(this);
    this.toggleShowAdditionalFilters = this.toggleShowAdditionalFilters.bind(this);
    this.formatDownloadUrl = this.formatDownloadUrl.bind(this);
    this.state = {
      showAdditionalFilters: false,
      selectedFilters: {
        group_by: 'TEAM',
        team: '',
        location: '',
        department: '',
        unit: '',
        manager: '',
        additional_filters: Object.assign(
          {}, ...props.filterOptions.additional_filters.map(filter => ({ [filter.id]: '' }))
        )
      }
    };
  }

  componentDidMount() {
    this.props.setAvailableFilters({
      filter_options: this.props.filterOptions,
      grouping_options: this.props.groupingOptions
    });
  }

  updateFilters(filter) {
    const updatedFilters = Object.assign({}, this.state.selectedFilters, filter);
    this.setState({
      selectedFilters: updatedFilters
    });
    this.props.getAvailableFilters(this.props.surveyId, updatedFilters);
  }

  formatAdditionalFilters(filterId, filterValue) {
    return {
      additional_filters: stripFalsyValuesFromObject(Object.assign(
        {},
        this.state.selectedFilters.additional_filters,
        { [filterId]: filterValue }
      ))
    };
  }

  toggleShowAdditionalFilters() {
    this.setState({showAdditionalFilters: !this.state.showAdditionalFilters});
  }

  formatDownloadUrl() {
    const { selectedFilters } = this.state;
    const { surveyId } = this.props;
    const filters = stripFalsyValuesFromObject(selectedFilters);
    filters.additional_filters = Object.values(
      selectedFilters.additional_filters
    ).filter(selectedOptionId => !!selectedOptionId).join(',');
    const searchParams = new URLSearchParams(filters);  // eslint-disable-line no-undef
    return `/export/${surveyId}/custom_questions_data/?${searchParams}`;
  }

  render() {
    const { organisationFilterTerms, loading, availableFilters: { filter_options, grouping_options } } = this.props;
    const { showAdditionalFilters, selectedFilters } = this.state;

    if (!filter_options || !grouping_options) {
      return null;
    }

    return (
      <div>
        <h4 className="margin-bottom-base">Custom Questions Export</h4>
        <p>
          Download your custom question results to Excel. Use the drop down options
          below (if available) to segment & filter the results before downloading.
        </p>
        <div className="margin-top-large margin-bottom-large width-150px">
          <FilterSelect
            filterKeyName="group_by"
            displayName="Group data by"
            options={grouping_options}
            setFilters={this.updateFilters}
            value={selectedFilters.group_by}
            boldLabel
            showAll={false}
            selectClassName="background-white"
            disabled={loading}
          />
        </div>
        <div className="display-flex flex-gap-large flex-wrap margin-top-large margin-bottom-large">
          <div className="width-150px">
            <FilterSelect
              filterKeyName="team"
              displayName={organisationFilterTerms.team}
              options={filter_options.teams}
              setFilters={this.updateFilters}
              value={selectedFilters.team_filter}
              boldLabel
              selectClassName="background-white"
              disabled={loading}
            />
          </div>
          <div className="width-150px">
            <FilterSelect
              filterKeyName="location"
              displayName={organisationFilterTerms.location}
              options={filter_options.locations}
              setFilters={this.updateFilters}
              value={selectedFilters.location_filter}
              boldLabel
              selectClassName="background-white"
              disabled={loading}
            />
          </div>
          <div className="width-150px">
            <FilterSelect
              filterKeyName="department"
              displayName={organisationFilterTerms.department}
              options={filter_options.departments}
              setFilters={this.updateFilters}
              value={selectedFilters.department_filter}
              boldLabel
              selectClassName="background-white"
              disabled={loading}
            />
          </div>
          <div className="width-150px">
            <FilterSelect
              filterKeyName="unit"
              displayName={organisationFilterTerms.unit}
              options={filter_options.units}
              setFilters={this.updateFilters}
              value={selectedFilters.unit_filter}
              boldLabel
              selectClassName="background-white"
              disabled={loading}
            />
          </div>
          <div className="width-150px">
            <FilterSelect
              filterKeyName="manager"
              displayName={organisationFilterTerms.manager}
              options={filter_options.managers}
              setFilters={this.updateFilters}
              value={selectedFilters.manager_filter}
              boldLabel
              selectClassName="background-white"
              disabled={loading}
            />
          </div>
        </div>
        <div className={classNames({
          'display-flex flex-gap-large flex-wrap margin-top-large margin-bottom-large': showAdditionalFilters,
          'collapsed-height': !showAdditionalFilters
        })}>
          {filter_options.additional_filters &&
            filter_options.additional_filters.map((additionalFilter, index) => (
            <div key={`additionalFilter-${additionalFilter.name}`} className={classNames('width-150px', {
              'margin-top-large': index > 5
            })}>
              <FilterSelect
                filterKeyName={additionalFilter.id}
                displayName={additionalFilter.name}
                options={additionalFilter.options}
                formatFilters={this.formatAdditionalFilters}
                setFilters={this.updateFilters}
                value={selectedFilters.additional_filters[additionalFilter.id]}
                boldLabel
                selectClassName="background-white"
                disabled={loading}
              />
            </div>
          ))}
        </div>
        {!!filter_options.additional_filters.length && (
          <button
            className="btn orange margin-bottom-large"
            onClick={this.toggleShowAdditionalFilters}
          >
            Show {showAdditionalFilters ? 'less' : 'more'} filters
          </button>
        )}
        <p>
          <img src="/static/images/document-excel-csv.png" />
          <a href={this.formatDownloadUrl()} target="_blank">
            Custom Questions Data Download
          </a> - Download your survey results to Excel
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    availableFilters: state.availableFilters,
    loading: isPending(state.responses, GET_AVAILABLE_FILTERS) || !state.availableFilters
  });
};

export default connect(mapStateToProps, {
  getAvailableFilters,
  setAvailableFilters
})(CustomQuestionExportFilters);
