import classNames from 'classnames';
import React from 'react';  // eslint-disable-line
import { connect } from 'react-redux';

import {
  capitalizeFirstLetter,
  changeQueryParamsWithoutPageLoad,
  stripFalsyValuesFromObject,
  getQueryParamsFromLocation
} from '^/utils';

export class QueryParamsFilterSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeFilter = this.onChangeFilter.bind(this);
  }

  onChangeFilter(event) {
    const newValue = this.props.formatFilterValue ?
      this.props.formatFilterValue(this.props.name, event.target.value)
      :
      event.target.value;
    const filterSelection = { [this.props.filterName]: newValue };
    changeQueryParamsWithoutPageLoad(stripFalsyValuesFromObject(Object.assign(
      {},
      getQueryParamsFromLocation(),
      filterSelection
    )));
    this.props.setFilters(filterSelection);
  }

  render() {
    const {
      name,
      label,
      options,
      disabled,
      labelClassName,
      selectClassName,
      value
     } = this.props;

    return (
      <div>
        <label
          htmlFor={`${name}-select`}
          className={classNames('text-overflow-ellipsis line-height-16px', labelClassName)}
        >
          {label || capitalizeFirstLetter(name)}
        </label>
        <select
          id={`${name}-select`}
          name={`${name}-select`}
          onChange={this.onChangeFilter}
          value={value}
          disabled={disabled}
          className={selectClassName}
        >
          <option value="">All</option>
          {options.map(option => (
            <option
              key={`filter-option-${name}-${option.value}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

function mapStateToProps (state, props) {
  return {
    value: props.value || getQueryParamsFromLocation()[props.filterName] || '',
  };
}

export default connect(mapStateToProps)(QueryParamsFilterSelect);
